import React from 'react'
import {
  chakra,
  CircularProgress,
  CircularProgressLabel,
  GridItem,
  GridItemProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { MajorStatsProps } from '..'
import { getISOWeek } from 'date-fns'

type HitPercentage = { currentWeekSelected: number } & Pick<
  MajorStatsProps,
  | 'percentCorrectAnswers'
  | 'percentCorrectQuestionsToReachTheGoal'
  | 'correct_answers_percentage'
> &
  GridItemProps

export default function HitPercentage(props: HitPercentage) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })
  const {
    percentCorrectAnswers,
    percentCorrectQuestionsToReachTheGoal,
    correct_answers_percentage,
    currentWeekSelected,
  } = props

  const currentWeek = getISOWeek(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (currentWeekSelected === currentWeek) {
    return (
      <GridItem p="16px" textAlign="center" backgroundColor="#302F37">
        <Text
          fontWeight={'700'}
          fontSize={{ base: '16px', sm: '18px' }}
          textAlign={'center'}
        >
          Percentual de acerto
        </Text>

        <CircularProgress
          size={isSm ? '84px' : '110px'}
          thickness="6px"
          capIsRound
          color={
            percentCorrectAnswers >= correct_answers_percentage
              ? '#0DDF15'
              : '#e5c009'
          }
          trackColor="#616167"
          my="16px"
          value={percentCorrectAnswers}
        >
          <CircularProgressLabel fontSize={'24px'}>
            <chakra.span>{percentCorrectAnswers.toFixed(0)}%</chakra.span>

            <Text fontWeight={400} fontSize={'13px'}>
              Acertos
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        {percentCorrectAnswers > 100 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            <chakra.span color={'#0ddf15'}>Sabe muuuito!! </chakra.span>
            Você ultrapassou a meta de{' '}
            <chakra.span color={'#0ddf15'}>
              {`${correct_answers_percentage}%}`}{' '}
            </chakra.span>
            de acerto dessa semana.
          </Text>
        )}

        {percentCorrectAnswers > 0 &&
          percentCorrectQuestionsToReachTheGoal === 0 && (
            <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
              <chakra.span color={'#0ddf15'}>Excelente! </chakra.span>
              Você atingiu a meta de Percentual dessa semana
            </Text>
          )}

        {percentCorrectAnswers === 0 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            Atinja{' '}
            <chakra.span color={'#E56000'}>
              {' '}
              {`${correct_answers_percentage}% `}{' '}
            </chakra.span>
            de acerto para completar a meta dessa semana.
          </Text>
        )}

        {percentCorrectQuestionsToReachTheGoal > 0 &&
          percentCorrectQuestionsToReachTheGoal < 100 && (
            <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
              Quase lá, atinja a meta de{' '}
              <chakra.span color={'#E56000'}>
                {' '}
                {`${correct_answers_percentage}% `}{' '}
              </chakra.span>
              de acertos
            </Text>
          )}
      </GridItem>
    )
  }

  return (
    <GridItem p="16px" textAlign="center" backgroundColor="#302F37">
      <Text
        fontWeight={'700'}
        fontSize={{ base: '16px', sm: '18px' }}
        textAlign={'center'}
      >
        Percentual de acerto
      </Text>

      <CircularProgress
        size={isSm ? '84px' : '110px'}
        thickness="6px"
        capIsRound
        color={
          percentCorrectAnswers >= correct_answers_percentage
            ? '#0DDF15'
            : '#e5c009'
        }
        trackColor="#616167"
        my="16px"
        value={percentCorrectAnswers}
      >
        <CircularProgressLabel fontSize={'24px'}>
          <chakra.span>{percentCorrectAnswers.toFixed(0)}%</chakra.span>

          <Text fontWeight={400} fontSize={'13px'}>
            Acertos
          </Text>
        </CircularProgressLabel>
      </CircularProgress>

      {percentCorrectAnswers > 100 && (
        <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
          <chakra.span color={'#0ddf15'}>Sabe muuuito!! </chakra.span>
          Você ultrapassou a meta de{' '}
          <chakra.span color={'#0ddf15'}>
            {`${correct_answers_percentage}%}`}{' '}
          </chakra.span>
          de acerto dessa semana.
        </Text>
      )}

      {percentCorrectAnswers > 0 &&
        percentCorrectQuestionsToReachTheGoal === 0 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            <chakra.span color={'#0ddf15'}>Excelente! </chakra.span>
            Você atingiu a meta de Percentual dessa semana
          </Text>
        )}

      {percentCorrectAnswers === 0 && (
        <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
          Atinja{' '}
          <chakra.span color={'#E56000'}>
            {' '}
            {`${correct_answers_percentage}% `}{' '}
          </chakra.span>
          de acerto para completar a meta dessa semana.
        </Text>
      )}

      {percentCorrectQuestionsToReachTheGoal > 0 &&
        percentCorrectQuestionsToReachTheGoal < 100 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            Faltou pouco, a meta para o período selecionado foi de{' '}
            <chakra.span color={'#E56000'}>
              {' '}
              {`${correct_answers_percentage}% `}{' '}
            </chakra.span>
            de acertos
          </Text>
        )}
    </GridItem>
  )
}
