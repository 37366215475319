import {
  AspectRatio,
  Box,
  Heading,
  HStack,
  Progress,
  Stack,
  Text,
  Image,
  VStack,
  chakra,
  Spinner,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'contexts/auth'
import { AxiosError } from 'axios'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldRadio } from 'components/Fields/FieldRadio'
import { RadioGroupController } from 'components/Fields/FieldRadioGroup/RadioGroupController'
import Pagination from 'components/Pagination'
import { useToastMessage } from 'components/Toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import ErrorPage from '../error-page'
import { useGetExams } from '../mutations/use-get-exams'
import { useGetExamsById } from '../mutations/use-get-exams-by-id'
import { useGetQuestionById } from '../mutations/use-get-question-by-id'
import { useAnswerQuestion } from '../mutations/use-mutate-create-question'
import { useMutateAnswer } from '../mutations/use-mutation-questions'
import { useGetAnswers } from '../mutations/use-get-answer-question'
import NoQuestionsPage from '../no-questions-page'
import { CompleteQuestionsForm } from '../types'
import ModalSimulationResult from './ModalSimulationResult'
import { CompleteQuestionsFormSchema } from './schema'
import { Icon } from '@iconify/react'
import ModalInstitutionTerms from './ModalInstitutionTerms'
import { useIsSimulationAlreadyDone } from '../mutations/use-get-simulation-already-dona'

export default function SimulationNew() {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [
    isResultSimulationModalOpen,
    setIsResultSimulationModalOpen,
  ] = useState(false)
  const [
    isInstitutionTermsModalOpen,
    setIsInstitutionTermsModalOpen,
  ] = useState(false)
  const [
    countAnsweredQuestionsPlusOne,
    setCountAnsweredQuestionsPlusOne,
  ] = useState<number>(1)
  const ref = useRef<HTMLDivElement | null>(null)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id } = useParams<{ id: string | undefined }>()

  const { push } = useHistory()
  const { user, userContract } = useAuth()

  const {
    data: exams,
    isLoading: isLoadingGetExams,
    error: errorGetExams,
  } = useGetExams({
    limit: 99999,
  })

  const {
    data: examById,
    error: errorGetExamsById,
    isLoading: isLoadingGetExamsById,
  } = useGetExamsById({
    id,
  })

  const {
    data: question,
    isLoading: isLoadingQuestion,
    error: errorQuestion,
  } = useGetQuestionById({
    id: countAnsweredQuestionsPlusOne
      ? examById?.questions?.[countAnsweredQuestionsPlusOne! - 1]?.id
      : undefined,
  })

  const {
    data: answers,
    error: errorAnswers,
    isLoading: isLoadingAnswers,
  } = useGetAnswers({
    limit: 99999999999,
  })

  const mutateAnswer = useMutateAnswer()

  const answerQuestionMutation = useAnswerQuestion()

  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<CompleteQuestionsForm>({
    resolver: yupResolver(CompleteQuestionsFormSchema),
    defaultValues: {
      chosenAlternative: '',
    },
  })

  // trial should do the exam only once
  const {
    data: hasTrialAlreadyTakenTheTest,
    isLoading: isLoadingTrialAlreadyTakenTheTest,
  } = useIsSimulationAlreadyDone({
    isTrial: userContract && userContract.type === 'TRIAL' ? true : false,
    mockExamId: id,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const submitForm = useCallback(
    async (data: CompleteQuestionsForm) => {
      if (!question || !data.chosenAlternative || !id) return

      // verify if the question was already answered and patch it

      const questionId =
        examById?.questions?.[countAnsweredQuestionsPlusOne! - 1]?.id

      const filterAnsweredQuestions = answers?.data.filter(item => {
        return item.mock_exam_question_id === questionId
      })

      if (filterAnsweredQuestions?.[0]?.chosen_alternative) {
        try {
          await mutateAnswer.mutateAsync({
            answerId: filterAnsweredQuestions?.[0]?.id,
            chosenAlternative: data.chosenAlternative,
            mockExamQuestionId:
              filterAnsweredQuestions?.[0]?.mock_exam_question_id,
          })

          showToast({
            title: 'Sucesso',
            description: 'Questão modificada com sucesso.',
            type: 'success',
            duration: 2000,
            mode: 'dark',
          })

          setCountAnsweredQuestionsPlusOne(prev => prev && prev + 1)
          return
        } catch (error: any) {
          const message: AxiosError =
            error?.response?.data?.errors
              ?.map((error: AxiosError) => error.message)
              ?.join(', ') ||
            error?.response?.data ||
            'Não foi possível modificar a questão.'

          showToast({
            title: 'Erro',
            description: message.message ? message.message : message,
            type: 'error',
            duration: 2000,
            mode: 'dark',
          })
        } finally {
          ref?.current?.scrollIntoView({ behavior: 'smooth' })
        }
      }

      // verify if all questions were answered

      const totalSimulationAnswered = exams?.data?.filter(
        item => item.id === Number(id),
      )

      const answeredQuestions =
        totalSimulationAnswered?.[0]?.countAnsweredQuestions

      if (
        countAnsweredQuestionsPlusOne === examById?.questions?.length &&
        answeredQuestions &&
        Number(answeredQuestions) < examById?.questions?.length - 1
      ) {
        return showToast({
          title: 'Erro',
          description: `Não é possível finalizar o simulado pois o número de questões respondidas é inferior ao número de questões do simulado. Você respondeu ${answeredQuestions} de ${examById?.questions?.length}.`,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }

      // check if is the last question

      if (countAnsweredQuestionsPlusOne === examById?.questions?.length) {
        return setIsResultSimulationModalOpen(true)
      }

      // post answer form

      try {
        await answerQuestionMutation.mutateAsync({
          chosenAlternative: data.chosenAlternative,
          mockExamQuestionId: question.id,
          mockExamId: Number(id),
        })

        reset()

        showToast({
          title: 'Sucesso',
          description: 'Questão cadastrada com sucesso.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        setCountAnsweredQuestionsPlusOne(prev => prev && prev + 1)
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível cadastrar a questão.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      } finally {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [
      answerQuestionMutation,
      answers?.data,
      countAnsweredQuestionsPlusOne,
      examById?.questions,
      exams?.data,
      id,
      mutateAnswer,
      question,
      reset,
      showToast,
    ],
  )

  const handlePageChange = (page: number) => {
    setCountAnsweredQuestionsPlusOne(page)
  }

  /*
  |-----------------------------------------------------------------------------
  | callBack.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const calcPercenterQuestions = useCallback(() => {
    if (!exams || !examById || !countAnsweredQuestionsPlusOne) return

    const total = examById.questions.length

    const currentQuestion = countAnsweredQuestionsPlusOne

    return Math.round((currentQuestion / total) * 100)
  }, [countAnsweredQuestionsPlusOne, examById, exams])

  const calcNumbers = useCallback(() => {
    if (!exams || !examById) return

    const result = `${countAnsweredQuestionsPlusOne}/${examById.questions.length}`

    return result
  }, [countAnsweredQuestionsPlusOne, examById, exams])

  /*
  |-----------------------------------------------------------------------------
  | UseEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!examById?.exam_product_code) return
    if (
      !!examById?.exam_product_code &&
      !user?.accepted_institution_terms
        .split(',')
        .includes(examById.institution)
    ) {
      setIsInstitutionTermsModalOpen(true)
    } else return
  }, [
    examById?.exam_product_code,
    examById?.institution,
    user?.accepted_institution_terms,
  ])

  useEffect(() => {
    if (!question || !answers) return

    const filterAnsweredQuestions = answers?.data.filter(item => {
      return item.mock_exam_question_id === question?.id
    })

    if (filterAnsweredQuestions.length > 0) {
      setValue(
        'chosenAlternative',
        filterAnsweredQuestions[0]?.chosen_alternative,
      )
    }

    if (filterAnsweredQuestions.length === 0) reset()
  }, [answers, question, reset, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!Number(id) || errorGetExamsById) {
    return <ErrorPage />
  }

  if (
    isLoadingGetExamsById ||
    isLoadingQuestion ||
    isLoadingGetExams ||
    isLoadingTrialAlreadyTakenTheTest
  ) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  if (examById?.questions?.length === 0) {
    return <NoQuestionsPage />
  }

  if (hasTrialAlreadyTakenTheTest?.hasTakenExam) {
    push('/simulation')
  }

  return (
    <div ref={ref}>
      <HStack
        w="fit-content"
        mt="1em"
        _hover={{ translateY: '1px', fontWeight: 600 }}
        as="button"
        onClick={() => push('/simulation')}
      >
        <Icon icon={'bi:arrow-left'} fontSize={'16px'} />
        <Text>Voltar para simulados</Text>
      </HStack>
      <HStack my="2rem" w="100%" maxW={'706px'}>
        <Progress
          colorScheme={'brand'}
          value={calcPercenterQuestions() ?? 0}
          h="5px"
          w="95%"
          borderRadius={'12px'}
          bg={'#2A2A30'}
        />

        <Text fontWeight={'400'} fontSize={'14px'}>
          {calcNumbers()}
        </Text>
      </HStack>

      <Box
        as="form"
        flexDir={'column'}
        w="full"
        maxW={'868px'}
        onSubmit={handleSubmit(submitForm)}
      >
        <VStack align={'flex-start'}>
          <Heading
            fontWeight={700}
            fontFamily={'Mulish, sans-serif'}
            fontSize={'22px'}
            as={'h2'}
          >
            {`${examById?.title} (${examById?.institution})`}
          </Heading>

          <HStack>
            <Text fontWeight={500} fontSize={'16px'}>
              {`${countAnsweredQuestionsPlusOne}.`}
            </Text>

            <Text fontWeight={500} fontSize={'16px'} lineHeight={'20px'}>
              {question?.statement}
            </Text>
          </HStack>
        </VStack>

        {question?.image_highlight && (
          <AspectRatio my="1.5rem" ratio={16 / 9} sx={{ objectFit: 'contain' }}>
            <Image
              src={question?.image_highlight}
              alt="img"
              sx={{ objectFit: 'contain !important' }}
            />
          </AspectRatio>
        )}

        <RadioGroupController
          name="chosenAlternative"
          control={control}
          error={errors.chosenAlternative}
          w="full"
        >
          <VStack
            alignItems={'flex-start'}
            mt="2rem"
            spacing={'16px'}
            position="relative"
          >
            <HStack
              w="full"
              data-qa="alternative_a"
              borderRadius={'8px'}
              background={'#2A2A30'}
              p="12px"
              spacing={'15px'}
            >
              <FieldRadio name="a" value={'a'} />

              <Text>
                <chakra.span>a)</chakra.span>
                <chakra.span ml="6px">{question?.alternative_a}</chakra.span>
              </Text>
            </HStack>

            <HStack
              w="full"
              data-qa="alternative_b"
              borderRadius={'8px'}
              background={'#2A2A30'}
              p="12px"
              spacing={'15px'}
            >
              <FieldRadio name="b" value={'b'} />

              <Text>
                <chakra.span>b)</chakra.span>
                <chakra.span ml="6px">{question?.alternative_b}</chakra.span>
              </Text>
            </HStack>

            <HStack
              w="full"
              data-qa="alternative_c"
              borderRadius={'8px'}
              background={'#2A2A30'}
              p="12px"
              spacing={'15px'}
            >
              <FieldRadio name="c" value={'c'} />

              <Text>
                <chakra.span>c)</chakra.span>
                <chakra.span ml="6px">{question?.alternative_c}</chakra.span>
              </Text>
            </HStack>

            <HStack
              w="full"
              data-qa="alternative_d"
              borderRadius={'8px'}
              background={'#2A2A30'}
              p="12px"
              spacing={'15px'}
            >
              <FieldRadio name="d" value={'d'} />

              <Text>
                <chakra.span>d)</chakra.span>
                <chakra.span ml="6px">{question?.alternative_d}</chakra.span>
              </Text>
            </HStack>

            {question?.alternative_e && (
              <HStack
                w="full"
                data-qa="alternative_e"
                borderRadius={'8px'}
                background={'#2A2A30'}
                p="12px"
                spacing={'15px'}
              >
                <FieldRadio name="e" value={'e'} />

                <Text>
                  <chakra.span>e)</chakra.span>
                  <chakra.span ml="6px">{question?.alternative_e}</chakra.span>
                </Text>
              </HStack>
            )}
          </VStack>
        </RadioGroupController>

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: '0.5rem', sm: '1rem' }}
          justify={'flex-end'}
          my={'24px'}
        >
          <DefaultButton
            w="full"
            label="Voltar"
            name="back"
            variant="ghost"
            disabled={countAnsweredQuestionsPlusOne === 1}
            maxW={{ base: 'unset', sm: '258px' }}
            onClick={() =>
              setCountAnsweredQuestionsPlusOne(prev => prev && prev - 1)
            }
          />

          <DefaultButton
            label="Salvar Resultado"
            name="save-result"
            type="submit"
            w="full"
            maxW={{ base: 'unset', sm: '278px' }}
          />
        </Stack>

        <Pagination
          totalCountOfRegisters={examById?.questions?.length! ?? 0}
          currentPage={countAnsweredQuestionsPlusOne ?? 0}
          onPageChange={handlePageChange}
          registersPerPage={1}
          w="full"
          pb={'1rem'}
        />
      </Box>

      <ModalInstitutionTerms
        institutionName={examById?.institution ?? ''}
        isOpen={isInstitutionTermsModalOpen}
        onClose={() => setIsInstitutionTermsModalOpen(false)}
      />
      <ModalSimulationResult
        isOpen={isResultSimulationModalOpen}
        onClose={() => setIsResultSimulationModalOpen(false)}
        calcNumbers={calcNumbers}
        calcPercenterQuestions={calcPercenterQuestions}
        totalQuestions={examById?.questions?.length ?? 0}
        mockExamId={Number(id)}
        chosenAlternative={watch('chosenAlternative')}
        mockExamQuestionId={question?.id ?? 0}
      />
    </div>
  )
}
