import React from 'react'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetListPdfLinkById } from '../mutations/use-get-download-pdf'
import ErrorPage from './ErrorPage'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FiDownload } from 'react-icons/fi'
import { BiArrowBack } from 'react-icons/bi'

export const ViewPage = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { id } = useParams<{ id: string }>()

  const { push } = useHistory()

  const { data, isLoading, isError } = useGetListPdfLinkById({
    id: +id,
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  if (!id || isError) {
    return <ErrorPage />
  }

  if (isLoading) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  return (
    <>
      <Flex gap={'16px'}>
        <DefaultButton
          mt="24px"
          label="Voltar"
          maxW={'150px'}
          leftIcon={<BiArrowBack />}
          onClick={() => push('/killer-concepts')}
        />

        <DefaultButton
          mt="24px"
          label="Download"
          maxW={'150px'}
          leftIcon={<FiDownload />}
          onClick={() => {
            if (!data) return
            const link = document.createElement('a')
            link.href = data
            link.setAttribute('target', '_blank')
            link.setAttribute('download', 'nome-arquivo.pdf')
            link.click()
          }}
        />
      </Flex>

      <div
        style={{
          padding: '24px 0px',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <iframe
          scrolling="auto"
          src={`${data}#&navpanes=0&scrollbar=0&view=FitH&embedded=true`}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
          }}
        />
      </div>
    </>
  )
}
