import React from 'react'
import { Circle, SquareProps, useBreakpointValue } from '@chakra-ui/react'
import { BsCheck } from 'react-icons/bs'

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean
  isActive: boolean
  isLastStep: boolean
}

export const StepCircle = (props: RadioCircleProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })
  const { isCompleted, isActive, isLastStep } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Circle
      size={{ base: '30px', sm: '32px' }}
      bg={isCompleted ? 'brand.primary' : 'inherit'}
      borderWidth={
        isMobile
          ? '1.94px'
          : !isActive && !isCompleted
          ? '4px'
          : isCompleted
          ? '0px'
          : '4px'
      }
      borderColor={
        isActive
          ? 'brand.primary'
          : isLastStep
          ? 'brand.secondary'
          : 'brand.secondary-brighter'
      }
      {...props}
    >
      {isCompleted ? (
        <BsCheck fontSize={'28px'} color="#202023" />
      ) : (
        <Circle
          bg={
            isActive
              ? 'brand.primary'
              : isLastStep
              ? 'brand.secondary'
              : 'brand.secondary-brighter'
          }
          size={{ base: '14px', sm: '14px' }}
        />
      )}
    </Circle>
  )
}
