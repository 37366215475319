import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { Step } from 'components/Step/Step'
import { Logo } from 'components/Sidebar/styles'
import logo from 'assets/images/new_logo.svg'
import { stepsOnboardingTrial } from '../data/data'

interface HeaderOnboardingTrial {
  currentStep: number
}

export default function HeaderOnboardingTrial({
  currentStep,
}: HeaderOnboardingTrial) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex flexDir={'column'} w="100%" gap="34px">
      {isMobile ? (
        <Text
          fontFamily="Mulish"
          fontSize={{ base: '24px', sm: '42px' }}
          lineHeight={{ base: '30px', sm: '60px' }}
          color="#FFFFFF"
          fontWeight={'bold'}
        >
          Mentoria Residência
        </Text>
      ) : (
        <Logo
          src={logo}
          width="226px"
          height="103px"
          alt="Logo da Mentoria Residência"
          draggable={false}
          shouldSidebarBeWide={true}
        />
      )}

      <Flex maxW="400px" w="auto">
        {stepsOnboardingTrial.map((step, id) => (
          <Step
            key={id}
            cursor="pointer"
            title={step.title}
            isActive={currentStep === id}
            isCompleted={currentStep > id}
            isFirstStep={id === 0}
            isLastStep={stepsOnboardingTrial.length === id + 1}
          />
        ))}
      </Flex>
    </Flex>
  )
}
