// Vendors
import React from 'react'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'

// Styles
import { InputProps } from '@chakra-ui/react'
import FieldInput from '../FieldInput'

// Interfaces
export type FieldMaskProps = InputProps &
  InputMaskProps & {
    name: string
  }

const FieldMaskBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldMaskProps
> = (props: FieldMaskProps, ref): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { name, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return <FieldInput as={InputMask} id={name} ref={ref} name={name} {...rest} />
}

export default React.forwardRef(FieldMaskBase)
