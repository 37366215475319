import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { get } from 'lodash'

// Services
import api from 'services/api'

// Schemas
import { NewUserSchema } from './schemas'

// Styles
import Layout from 'layouts/Unlogged'
import { Container, TrackSection } from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from 'hooks/useQuery'
import Step1 from './Step1'
import Step2 from './Step2'

interface FormProfile {
  name: string
  email: string
  mobilePhone: string
  password: string
  passwordConfirmation: string
  cpf: string
}

const TrialRegister: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()

  const query = useQuery()
  const utm_source = query.get('utm_source')
  const utm_medium = query.get('utm_medium')
  const utm_content = query.get('utm_content')
  const utm_campaign = query.get('utm_campaign')
  const utm_term = query.get('utm_term')

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isLoading, setIsLoading] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const methods = useForm<FormProfile>({
    resolver: yupResolver(NewUserSchema),
    mode: 'onChange',
  })

  const [step, setStep] = useState(1)

  const createNewUser = useCallback(
    async (formData: FormProfile) => {
      setIsLoading(true)
      api
        .post('/public/trial_register', {
          ...formData,
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmContent: utm_content,
          utmCampaign: utm_campaign,
          utmTerm: utm_term,
        })
        .then(() => {
          /* userDataFormRef.current?.reset() */
          Swal.fire({
            buttonsStyling: false,
            title: 'Deu tudo certo!',
            html: '<p style="text-align:center">Conta criada com sucesso.</p>',
            icon: 'success',
          }).then(() => history.replace('/'))
        })
        .catch(err => {
          console.trace(err)

          let errorMessage = 'O cadastro não pode ser concluído nesse momento.'

          if (err.response?.data?.errors) {
            errorMessage = err.response.data.errors
              .map((err: { [key: string]: string }) => err.message)
              .join('\n')
          }

          const apiErrorString = get(err, 'response.data')
          if (typeof apiErrorString === 'string') {
            errorMessage = apiErrorString
          }

          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            html: `<p style="text-align:center">${errorMessage}</p>`,
            icon: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [history, utm_campaign, utm_content, utm_medium, utm_source, utm_term],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <TrackSection>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(createNewUser)}>
              {step === 1 && <Step1 setStep={setStep} />}

              {step === 2 && <Step2 isLoading={isLoading} setStep={setStep} />}
            </form>
          </FormProvider>
        </TrackSection>
      </Container>
    </Layout>
  )
}

export default TrialRegister
