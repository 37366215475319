import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  width: 100%;
`

export const HeaderTitleContainer = styled.div``

export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;

  mix-blend-mode: normal;
  opacity: 0.9;

  margin-bottom: 4px;
`

export const HeaderSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #ff6b00;
`

export const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  height: 28px;

  padding: 4px 8px;
  background: #ff6b00;
  border-radius: 14px;

  @media (max-width: 992px) {
    height: 40px;
    border-radius: 999px;
    width: 100%;
  }

  svg {
    color: #ffffff;
    margin-right: 4px;
  }
`

export const NotificationText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  text-align: right;
  width: fit-content;

  @media (max-width: 992px) {
    text-align: center;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProfileAvatar = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 4px;

  margin-right: 8px;

  object-fit: cover;
`

export const ProfileName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;

  mix-blend-mode: normal;
`

export const LogOutButton = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;

  height: 28px;
  width: 28px;
  background: transparent;
  border-radius: 8px;
  border-color: rgba(255, 107, 0, 0.4);

  transition: all 0.4s;

  svg {
    color: #ffffff;

    transition: all 0.4s;
  }

  &:hover {
    border: 1px solid rgba(255, 107, 0, 0.4);

    svg {
      color: #ff6b00;
    }
  }
`
