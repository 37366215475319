import { validateFileIsRequired, validateFileSize } from 'helpers/validateFile'
import * as Yup from 'yup'

export const avatarSchema = Yup.object().shape({
  avatar: Yup.mixed()
    .test(
      'req',
      'Por favor, insira a imagem do seu avatar',
      validateFileIsRequired,
    )
    .test('fileSize', 'O arquivo deve ser menor que 1 MB', validateFileSize),
})
