import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  HStack,
  Flex,
  Table,
  Tbody,
  CircularProgress,
} from '@chakra-ui/react'
import Pagination from 'components/Pagination'
import { NoDataTableError } from 'components/Table/StepOneRecoveryPlanTable/NoDataTableError'
import { TheadTablePrevalence } from 'components/Table/StepTwoRecoveryPlanTable/Prevalence/TheadTablePrevalence'
import { TrTablePrevalence } from 'components/Table/StepTwoRecoveryPlanTable/Prevalence/TrTablePrevalence'
import { isEmpty } from 'lodash'
import { useDeletePendingStudies } from 'pages/CreateRecoveryPlan/queries/use-delete-pending-studies'
import { useGetListPendingStudies } from 'pages/CreateRecoveryPlan/queries/use-get-pending-studies'
import { useGetPrevalenceStudies } from 'pages/CreateRecoveryPlan/queries/use-get-prevalence'
import { useMutationTheoreticalStudies } from 'pages/CreateRecoveryPlan/queries/use-mutation-theorical-studies'
import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { useListFirstContact } from '../queries/use-get-first-contact'
import { CardDrawerPendingStudies } from './CardDrawerPendingStudies'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import './style.css'

type DrawerPendingStudiesProps = {
  isOpen: boolean
  onClose: () => void
}

export const DrawerPendingStudies: React.FC<DrawerPendingStudiesProps> = ({
  onClose,
  isOpen,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [pagePrevalence, setPagePrevalence] = useState(1)
  const [theoreticalStudiesPage, setTheoreticalStudiesPage] = useState(1)
  const [firstContactPage, setFirstContactPage] = useState(1)
  const [smartRevision, setSmartRevision] = useState(1)

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    data: listTheoreticalStudies,
    error: isErrorListTheoreticalStudies,
    isLoading: isLoadingListTheoreticalStudies,
  } = useGetListPendingStudies({
    isTheoretical: true,
    isProgrammedStudy: false,
    page: theoreticalStudiesPage,
    limit: 8,
  })

  const {
    data: listFirstContactStudies,
    error: isErrorListFirstContactStudies,
    isLoading: isLoadingListFirstContactStudies,
  } = useListFirstContact({
    page: firstContactPage,
    limit: 8,
  })

  const {
    data: listSmartRevisionsStudies,
    error: isErrorListSmartRevisionsStudies,
    isLoading: isLoadingListSmartRevisionsStudies,
  } = useListFirstContact({
    page: smartRevision,
    limit: 8,
    isRevision: true,
  })

  const removeTheoreticalStudies = useDeletePendingStudies()

  const addTheoreticalStudies = useMutationTheoreticalStudies()

  const {
    data: prevalenceData,
    isError: isPrevalenceError,
    isLoading: isPrevalenceLoading,
  } = useGetPrevalenceStudies({
    page: pagePrevalence,
    limit: 8,
  })

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Drawer
      id="drawer-pending-studies"
      onClose={onClose}
      isOpen={isOpen}
      size={'lg'}
    >
      <DrawerOverlay />
      <DrawerContent
        background={'linear-gradient(71.48deg, #1D1D1F 0%, #28272D 98.51%)'}
        p="24px 16px 24px 24px"
        h="fit-content"
        maxW="622px"
        w="100%"
      >
        <DrawerHeader p="0px">
          <HStack w="100%" justifyContent={'space-between'}>
            <Text
              fontFamily="Mulish"
              fontWeight="600"
              fontSize="24px"
              lineHeight="30px"
              color="#FFFFFF"
            >
              Estudos pendentes
            </Text>
            <IoMdClose
              onClick={onClose}
              size={'22px'}
              cursor="pointer"
              color="#FFFFFF"
            />
          </HStack>
        </DrawerHeader>

        <DrawerBody
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#D9D9D9',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#60606C',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'rgba(0,0,0,0)',
            },
          }}
          whiteSpace={'nowrap'}
          px="0px !important"
          h="fit-content"
          maxH={'100%'}
        >
          <Tabs
            style={{
              marginTop: '16px',
              width: '100%',
            }}
          >
            <TabList
              style={{
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'fit-content',
                marginBottom: '0px',
              }}
            >
              <Tab
                style={{ borderRadius: '8px 0px 0px 0px' }}
                selectedClassName="tab-selected"
                className={'react-tab'}
              >
                <Text>Teórico</Text>
              </Tab>

              <Tab selectedClassName="tab-selected" className={'react-tab'}>
                <Text>Primeiro contato</Text>
              </Tab>

              <Tab selectedClassName="tab-selected" className={'react-tab'}>
                <Text>Revisão Inteligente</Text>
              </Tab>

              <Tab
                style={{ borderRadius: '0px 8px 0px 0px' }}
                selectedClassName="tab-selected"
                className={'react-tab'}
              >
                <Text>%</Text>
              </Tab>
            </TabList>

            <TabPanel>
              <Flex
                gap="4px"
                h={'fit-content'}
                maxW={'581px'}
                w="100%"
                flexDir={'column'}
              >
                {!isEmpty(listTheoreticalStudies) &&
                  !isErrorListFirstContactStudies &&
                  listTheoreticalStudies?.data.map((item, key) => {
                    return (
                      <CardDrawerPendingStudies
                        key={`CardDrawerPendingStudies${key}`}
                        area={item.field.name}
                        subTheme={item.subjects}
                        theme={item.themes}
                        id={item.id}
                        updateDeleted={removeTheoreticalStudies}
                        updateAddStudy={addTheoreticalStudies}
                      />
                    )
                  })}

                {isErrorListFirstContactStudies &&
                  !isLoadingListTheoreticalStudies && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      minW={'460px'}
                      w="100%"
                      h="100%"
                      headerMessage="Erro ao carregar!"
                      message="Erro ao carregar estudos teóricos!"
                    />
                  )}

                {isEmpty(listTheoreticalStudies?.data) &&
                  !isLoadingListTheoreticalStudies &&
                  !isErrorListFirstContactStudies && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      w="100%"
                      minW={'460px'}
                      h="100%"
                      headerMessage="Nenhum estudo cadastrado!"
                      message="Conclua novos estudos na revisão inteligente!"
                    />
                  )}

                {isLoadingListTheoreticalStudies && (
                  <Flex
                    minH={{ base: 'unset', sm: '300px' }}
                    mt="2rem"
                    w="100%"
                    justifyContent={'center'}
                    alignItems="center"
                  >
                    <CircularProgress isIndeterminate color="brand.50" />
                  </Flex>
                )}

                <Pagination
                  px="2"
                  mt="2"
                  totalCountOfRegisters={
                    listTheoreticalStudies?.meta.total ?? 0
                  }
                  onPageChange={setTheoreticalStudiesPage}
                  currentPage={theoreticalStudiesPage}
                  registersPerPage={8}
                />
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex gap="4px" maxW={'581px'} w="100%" flexDir={'column'}>
                {!isEmpty(listFirstContactStudies) &&
                  !isErrorListTheoreticalStudies &&
                  !isLoadingListFirstContactStudies &&
                  listFirstContactStudies?.data.map((item, key) => {
                    return (
                      <CardDrawerPendingStudies
                        key={`CardDrawerPendingStudies${key}`}
                        area={item.field.name}
                        subTheme={item.subjects}
                        theme={item.themes}
                        id={item.id}
                        updateDeleted={removeTheoreticalStudies}
                        updateAddStudy={addTheoreticalStudies}
                      />
                    )
                  })}

                {isErrorListTheoreticalStudies &&
                  !isLoadingListFirstContactStudies && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      minW={'460px'}
                      w="100%"
                      h="100%"
                      headerMessage="Erro ao carregar!"
                      message="Erro ao carregar estudos teóricos!"
                    />
                  )}

                {isEmpty(listFirstContactStudies?.data) &&
                  !isLoadingListFirstContactStudies &&
                  !isErrorListTheoreticalStudies && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      w="100%"
                      minW={'460px'}
                      h="100%"
                      headerMessage="Nenhum estudo cadastrado!"
                      message="Conclua novos estudos na revisão inteligente!"
                    />
                  )}

                {isLoadingListFirstContactStudies && (
                  <Flex
                    minH={{ base: 'unset', sm: '300px' }}
                    mt="2rem"
                    w="100%"
                    justifyContent={'center'}
                    alignItems="center"
                  >
                    <CircularProgress isIndeterminate color="brand.50" />
                  </Flex>
                )}

                <Pagination
                  px="2"
                  mt="2"
                  totalCountOfRegisters={
                    listFirstContactStudies?.meta.total ?? 0
                  }
                  onPageChange={setFirstContactPage}
                  currentPage={firstContactPage}
                  registersPerPage={8}
                />
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex gap="4px" maxW={'581px'} w="100%" flexDir={'column'}>
                {!isEmpty(listSmartRevisionsStudies) &&
                  !isErrorListSmartRevisionsStudies &&
                  !isLoadingListSmartRevisionsStudies &&
                  listSmartRevisionsStudies?.data.map((item, key) => {
                    return (
                      <CardDrawerPendingStudies
                        key={`CardDrawerPendingStudies${key}`}
                        area={item.field.name}
                        subTheme={item.subjects}
                        theme={item.themes}
                        id={item.id}
                        updateDeleted={removeTheoreticalStudies}
                        updateAddStudy={addTheoreticalStudies}
                      />
                    )
                  })}

                {isErrorListSmartRevisionsStudies &&
                  !isLoadingListSmartRevisionsStudies && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      minW={'460px'}
                      w="100%"
                      h="100%"
                      headerMessage="Erro ao carregar!"
                      message="Erro ao carregar estudos teóricos!"
                    />
                  )}

                {isEmpty(listSmartRevisionsStudies?.data) &&
                  !isLoadingListSmartRevisionsStudies &&
                  !isErrorListSmartRevisionsStudies && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      w="100%"
                      minW={'460px'}
                      h="100%"
                      headerMessage="Nenhum estudo cadastrado!"
                      message="Conclua novos estudos na revisão inteligente!"
                    />
                  )}

                {isLoadingListSmartRevisionsStudies && (
                  <Flex
                    minH={{ base: 'unset', sm: '300px' }}
                    mt="2rem"
                    w="100%"
                    justifyContent={'center'}
                    alignItems="center"
                  >
                    <CircularProgress isIndeterminate color="brand.50" />
                  </Flex>
                )}

                <Pagination
                  px="2"
                  mt="2"
                  totalCountOfRegisters={
                    listSmartRevisionsStudies?.meta.total ?? 0
                  }
                  onPageChange={setSmartRevision}
                  currentPage={smartRevision}
                  registersPerPage={8}
                />
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex
                overflow={'auto'}
                maxH={'600px'}
                flexDir={'column'}
                minW={'460px'}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#D9D9D9',
                    borderRadius: '17px',
                    height: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#60606C',
                    borderRadius: '17px',
                    height: '8px',
                  },
                  '&::-webkit-scrollbar-corner': {
                    background: 'rgba(0,0,0,0)',
                  },
                }}
              >
                <Table background={'#302F37'}>
                  {!isPrevalenceLoading && <TheadTablePrevalence />}

                  <Tbody borderBottomColor="#302F37">
                    {!isEmpty(prevalenceData) &&
                      !isPrevalenceError &&
                      !isPrevalenceLoading &&
                      prevalenceData?.data.map((item, index) => {
                        return (
                          <TrTablePrevalence
                            key={`TablePrevalence${index}`}
                            backgroundTable={
                              index % 2 == 0
                                ? 'rgba(57, 57, 64, 0.64)'
                                : '#393940'
                            }
                            area={item.field}
                            prevalence={item.prevalence_percentage}
                            theme={item.theme}
                          />
                        )
                      })}
                  </Tbody>
                </Table>
                {isPrevalenceLoading && (
                  <Flex
                    minH={{ base: 'unset', sm: '300px' }}
                    mt="2rem"
                    w="100%"
                    justifyContent={'center'}
                    alignItems="center"
                  >
                    <CircularProgress isIndeterminate color="brand.50" />
                  </Flex>
                )}

                {isPrevalenceError && !isPrevalenceLoading && (
                  <NoDataTableError
                    minH={{ base: 'unset', sm: '300px' }}
                    minW={'460px'}
                    w="100%"
                    h="100%"
                    headerMessage="Erro ao carregar!"
                    message="Erro ao carregar estudos teóricos!"
                  />
                )}

                {isEmpty(prevalenceData?.data) &&
                  !isPrevalenceLoading &&
                  !isPrevalenceError && (
                    <NoDataTableError
                      minH={{ base: 'unset', sm: '300px' }}
                      w="100%"
                      minW={'460px'}
                      h="100%"
                      headerMessage="Nenhum estudo cadastrado!"
                      message="Conclua novos estudos na revisão inteligente!"
                    />
                  )}

                <Pagination
                  mt={'16px'}
                  currentPage={pagePrevalence}
                  totalCountOfRegisters={prevalenceData?.meta.total ?? 0}
                  onPageChange={setPagePrevalence}
                  registersPerPage={8}
                />
              </Flex>
            </TabPanel>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
