import {
  Box,
  Flex,
  FlexProps,
  useRadioGroup,
  UseRadioGroupProps,
  Wrap,
} from '@chakra-ui/react'
import { SelectType } from 'hooks/useSelectParser'
import React from 'react'
import { RadioCard } from './RadioCard'

export type RadioButtonCardGroupProps = UseRadioGroupProps & {
  options:
    | SelectType<any>[]
    | {
        label: string
        value: string
      }[]
  radioProps?: FlexProps
}

const RadioButtonCardGroupBase: React.ForwardRefRenderFunction<
  any,
  RadioButtonCardGroupProps
> = (props, ref) => {
  const { options, value, radioProps, ...rest } = props

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...rest,
    value: value && +value,
  })

  const group = getRootProps()

  return (
    <Flex {...group} flexWrap={'wrap'}>
      {options.map(item => {
        const radio = getRadioProps({ value: item.value })

        return (
          <RadioCard
            key={item.value}
            {...radio}
            ref={ref}
            radioProps={radioProps}
          >
            {item.label}
          </RadioCard>
        )
      })}
    </Flex>
  )
}

export const FieldRadioCard = React.forwardRef(RadioButtonCardGroupBase)
