import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputGroup,
} from '@chakra-ui/react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import {
  FieldInputComponent,
  FieldInputProps,
} from 'components/Fields/FieldInputRegister'

type FieldInputRegisterProps = {
  name: string
  label?: string
  error?: FieldError
  register: UseFormRegister<any>
  helperText?: string | React.ReactNode
  inputProps?: FieldInputProps
  labelProps?: FormLabelProps
}

export const FieldInputRegister: React.FC<FieldInputRegisterProps> = ({
  name,
  label,
  error,
  register,
  helperText,
  labelProps,
  inputProps,
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontWeight={600}
          fontFamily={'Mulish'}
          {...labelProps}
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}

      <InputGroup>
        <FieldInputComponent id={name} {...inputProps} {...register(name)} />
      </InputGroup>

      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
