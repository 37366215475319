import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface GetPendingThemesResponde {
  percentageOfCompletedThemes: number
  percentageOfThemesInProgress: number
  percentageOfPendingThemes: number
}

export async function getPendingThemesProgressBar() {
  const { data } = await api.get<GetPendingThemesResponde>(
    '/app/mentoria-exams/progress',
  )

  return data
}

export function useGetPendingThemesProgressBar() {
  return useQuery(
    ['get-pending-themes-progress-bar'],
    getPendingThemesProgressBar,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
