import React, { useRef } from 'react'
import { Flex, useBreakpointValue } from '@chakra-ui/react'

import ReactSelect, {
  OptionTypeBase,
  Props as ReactSelectProps,
} from 'react-select'

export type FieldSelectRegistryDashboardProps = ReactSelectProps<OptionTypeBase>

const MenuList = (props: any) => {
  const menuListRef = useRef<HTMLDivElement>(null)

  return (
    <Flex
      flexDir={'column'}
      sx={{
        maxHeight: '150px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
          boxShadow: 'inset 0 0 5px #60606c',
          borderRadius: '17px',
          height: '8px',
          borderLeft: '2px solid transparent',
          borderRight: '2px solid transparent',
          marginTop: '10px',
          marginBottom: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E56000',
          borderRadius: '17px',
          height: '8px',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
        },
      }}
    >
      <div ref={menuListRef}>{props.children}</div>
    </Flex>
  )
}

export const FieldSelectTelephone: React.ForwardRefRenderFunction<
  any,
  FieldSelectRegistryDashboardProps
> = props => {
  const isSm = useBreakpointValue({ base: true, sm: false })
  return (
    <ReactSelect
      components={{ MenuList }}
      captureMenuScroll={false}
      styles={{
        container: base => ({
          ...base,
          width: '50%',
          minHeight: isSm ? 44 : 52,
        }),
        control: base => ({
          ...base,
          width: '100%',
          display: 'flex',
          minHeight: 44,
          backgroundColor: '#393940',
          color: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 8,
          outline: 0,
          border: 0,
          paddingLeft: 12,
          paddingRight: 8,
          '&:hover': {
            borderColor: 'transparent',
          },
          boxShadow: 'none',
        }),
        multiValue: base => ({
          ...base,
          height: 'auto',
        }),
        input: base => ({
          ...base,
          color: '#FFF',
        }),
        indicatorSeparator: base => ({
          ...base,
          backgroundColor: 'transparent',
        }),
        dropdownIndicator: base => ({
          ...base,
          color: '#FF6B00',
          '&:hover': {
            color: '#FF6B00',
          },
        }),
        placeholder: base => ({
          ...base,
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
          color: '#FFFFFF',
        }),
        menuList: base => ({
          ...base,
          backgroundColor: 'transparent',
          zIndex: 2000,
          borderRadius: '0px 0px 16px 16px',
          paddingTop: 0,
          paddingBottom: 0,
        }),
        menu: base => ({
          ...base,
          maxWidth: 380,
          borderRadius: '0px 0px 16px 16px',
          color: '#FF6B00',
          backgroundColor: '#302F37',
          zIndex: 3000,
          marginTop: -4,
          height: '100%',
          maxHeight: 150,
        }),
        singleValue: base => ({
          ...base,
          color: 'rgba(255, 255, 255, 0.9)',
          fontFamily: 'Mulish',
          fontSize: '14px',
        }),
        option: (base, { isSelected }) => ({
          ...base,
          background: '#302F37',
          color: isSelected ? '#FFF' : '#BDBDC7',
          '&:hover': {
            background: '#2A2A30',
            borderLeft: '2px solid #E56000',
          },
        }),
      }}
      {...props}
    />
  )
}
