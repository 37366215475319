import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Flag from 'react-world-flags'
import { FieldSelectTelephone } from './SelectCountry'
import { countriesOptions } from 'helpers/countries'
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select'
import FieldInput, { FieldInputProps } from 'components/Fields/FieldInput'

interface FieldInputTelephoneProps extends FieldInputProps {
  valueTel: ValueType<OptionTypeBase, false>
  onChangeTel:
    | ((
        value: ValueType<OptionTypeBase, false>,
        action: ActionMeta<OptionTypeBase>,
      ) => void)
    | undefined
}

const FieldInputTelephone: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldInputTelephoneProps
> = (props, ref) => {
  return (
    <Flex w="100%" data-qa={props.name}>
      <FieldSelectTelephone
        options={countriesOptions}
        formatOptionLabel={country => (
          <Flex gap={'0.5rem'}>
            <Flag width={'15px'} height="15px" code={country.label.iso3} />
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              color="#FFFFFF"
            >{`${country.label.ddi}`}</Text>
          </Flex>
        )}
        value={props.valueTel}
        onChange={props.onChangeTel}
        /* code => props.setCode(code as SelectCountryProps) */
      />

      <FieldInput
        type="tel"
        ref={ref}
        {...props}
        placeholder="Phone number"
        ml="2px"
        w="80%"
        h={'44px'}
      />
    </Flex>
  )
}

export const FieldTelephone = React.forwardRef(FieldInputTelephone)
