import React, { useEffect, useState } from 'react'
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import api from 'services/api'
import { Track } from 'contexts/track/interfaces'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { SelectDragNDropController } from 'components/Fields/SelectDragNDrop'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldRadioCard } from 'components/Fields/FieldRadioCard'
import FieldRadioCardController from 'components/Fields/FieldRadioCard/FieldRadioCardController'
import { INSTITUTIONS } from 'constants/institutions'
import { PREP_SCHOOLS } from 'constants/prepSchools'
import { getYear } from 'date-fns'
import { useFormContext } from 'react-hook-form'
import { SPECIALTIES } from 'constants/specialties'

type FirstStepProps = BoxProps & {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export default function SecondStep({ ...props }: FirstStepProps) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [track, setTrack] = useState<Track[] | undefined>(undefined)
  const [R3selected, setR3Selected] = useState<number | undefined>(undefined)
  const [shouldShowR3Options, setShouldShowR3Options] = useState(false)
  const currentYear = getYear(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    async function fetchData() {
      api
        .get<{ data: Track[] }>('/app/tracks')
        .then(({ data }) => {
          if (!data) return

          setTrack(data.data)
        })
        .catch(err => {
          console.error('Failed on fetching available tracks. ', err)
        })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (Number(R3selected) === 99) {
      setValue('trackId', undefined)
      setShouldShowR3Options(true)
    }

    if (Number(R3selected) !== 99) {
      setShouldShowR3Options(false)
    }

    const trackEqualsR1PNARevalida = track?.filter(track => {
      return (
        track.name === 'R1' ||
        track.name === 'Revalida' ||
        track.name === 'PNA PORTUGAL'
      )
    })

    const watchTrackId = watch(data => {
      if (
        trackEqualsR1PNARevalida?.find(
          item => Number(item.id) === Number(data.trackId),
        )
      ) {
        setR3Selected(undefined)
        setShouldShowR3Options(false)
      }
    })

    return () => {
      watchTrackId.unsubscribe()
    }
  }, [setValue, R3selected, watch, track])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const validateSchema = async () => {
    const [
      intendedInstitutions,
      prepSchool,
      trackId,
      trialYear,
      intendedSpecialties,
    ] = await Promise.all([
      trigger('intendedInstitutions'),
      trigger('prepSchool'),
      trigger('trackId'),
      trigger('trialYear'),
      trigger('intendedSpecialties'),
    ])

    if (
      intendedInstitutions &&
      prepSchool &&
      trackId &&
      trialYear &&
      intendedSpecialties
    ) {
      return props.setCurrentStep(2)
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack alignItems={'flex-start'} {...props} w="100%" gap="1rem">
      <Text fontWeight={'500'} fontSize={'24px'}>
        Escolha a sua{' '}
        <chakra.span fontWeight={'bold'} color="#FF6B00">
          trilha de estudo
        </chakra.span>
      </Text>

      <Stack>
        <Flex gap="8px" flexWrap={'wrap'}>
          <FieldRadioCardController
            name="trackId"
            control={control}
            options={
              track
                ?.filter(
                  track =>
                    track.name === 'R1' ||
                    track.name === 'Revalida' ||
                    track.name === 'PNA PORTUGAL',
                )
                .map(item => {
                  return {
                    label: item.name,
                    value: item.id,
                  }
                }) || []
            }
            error={errors?.trackId}
          />

          {track && (
            <FieldRadioCard
              options={[{ label: 'R3', value: 99 }]}
              onChange={value => {
                setR3Selected(+value)
              }}
              value={R3selected}
            />
          )}
        </Flex>
      </Stack>

      {shouldShowR3Options && (
        <Stack>
          <Flex w="full" flexDir={'column'}>
            <Text mb="1rem" fontWeight={'500'} fontSize={'24px'}>
              Escolha a sua{' '}
              <chakra.span fontWeight={'bold'} color="#FF6B00">
                trilha de estudo 2
              </chakra.span>
            </Text>

            <HStack w="auto" flexWrap={'wrap'}>
              {track
                ?.filter(
                  track =>
                    track.name !== 'R1' &&
                    track.name !== 'Revalida' &&
                    track.name !== 'PNA PORTUGAL',
                )
                .map(item => {
                  return (
                    <FieldRadioCard
                      key={`Field-Card-${item.id}`}
                      options={
                        item ? [{ label: item.name, value: item.id }] : []
                      }
                      radioProps={{
                        onClick: () => {
                          setValue('trackId', item.id)
                        },
                      }}
                      value={watch('trackId')}
                    />
                  )
                })}
            </HStack>
          </Flex>
        </Stack>
      )}

      <VStack
        alignItems={'flex-start'}
        spacing={'1rem'}
        fontFamily="Mulish"
        maxW="434px"
        w="100%"
      >
        <NewFieldSelectRegistryController
          options={PREP_SCHOOLS.map(prepSchool => ({
            value: prepSchool,
            label: prepSchool,
          }))}
          control={control}
          name="prepSchool"
          placeholder="Selecione uma opção"
          label="Faz cursinho de residência?"
          error={errors.prepSchool}
        />

        <NewFieldSelectRegistryController
          isClearable
          label="Especialidades pretendidas"
          isMulti={true as any}
          options={SPECIALTIES.map(specialty => ({
            value: specialty,
            label: specialty,
          }))}
          placeholder="Selecione uma especialidade"
          name="intendedSpecialties"
          control={control}
          error={errors.intendedSpecialties as any}
        />

        <SelectDragNDropController
          label="Onde você vai passar?"
          options={INSTITUTIONS.map(institution => ({
            value: institution,
            label: institution,
          }))}
          control={control}
          name="intendedInstitutions"
          error={errors.intendedInstitutions as any}
        />

        <NewFieldSelectRegistryController
          label="Quando você faz a prova?"
          options={[
            {
              label: `Presto prova final de ${currentYear} - R1/R+ em ${
                currentYear + 1
              }`,
              value: currentYear,
            },
            {
              label: `Presto prova final de ${currentYear + 1} - R1/R+ em ${
                currentYear + 2
              }`,
              value: currentYear + 1,
            },
            {
              label: `Presto prova final de ${currentYear + 2} - R1/R+ em ${
                currentYear + 3
              }`,
              value: currentYear + 2,
            },
          ]}
          isClearable
          control={control}
          name="trialYear"
          placeholder="Selecione uma opção"
          error={errors.trialYear}
        />

        <DefaultButton
          label="Continuar"
          w="100%"
          mt="1.5rem !important"
          h="55px"
          onClick={async () => {
            await validateSchema()
          }}
        />
      </VStack>
    </VStack>
  )
}
