import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function deleteDailyRecord({ id }: { id: number }) {
  return await api.delete(`/app/daily_records/${id}`)
}

export function useDeleteDailyRecord() {
  return useMutation(deleteDailyRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries(['daily-records'])
      queryClient.invalidateQueries(['get-dashboard-info'])
    },
  })
}
