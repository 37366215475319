import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'

import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { FieldRadioCard, RadioButtonCardGroupProps } from '.'

type FieldRadioCardControllerProps = RadioButtonCardGroupProps & {
  name: string
  label?: string
  control: Control<any>
  error?: FieldError
}

export const RadioCardController: React.FC<FieldRadioCardControllerProps> = ({
  name,
  label,
  error,
  control,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FieldRadioCard {...rest} options={rest.options} {...field} />
        )}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
