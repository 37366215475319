import React, { useMemo, useState } from 'react'
import { Container } from './styles'
import Layout from 'layouts/Logged'
import '../../pages/RecoveryPlan/components/style.css'
import {
  Flex,
  useBreakpointValue,
  Text,
  useDisclosure,
  Table,
  Tbody,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'
import Header from 'components/Header'
import { Icon } from '@iconify/react'
import { DrawerPendingStudies } from './components/DrawerPendingStudies'
import { TrTableHeadProgramedStudies } from 'components/Table/DiaryPlanTable/ProgramedStudies/TrTableHeadProgramedStudies'
import { TrTableProgramedStudies } from 'components/Table/DiaryPlanTable/ProgramedStudies/TrTableProgramedStudies'
import Pagination from 'components/Pagination'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { TrTableStudiesDone } from 'components/Table/DiaryPlanTable/StudiesDone/TrTableStudiesDone'
import { TrTableHeadStudiesDone } from 'components/Table/DiaryPlanTable/StudiesDone/TrTableHeadStudiesDone'
import { ProgressBar } from './components/ProgressBar'
import { AxiosError } from 'axios'
import {
  ListPendingStudiesData,
  useGetListPendingStudies,
} from 'pages/CreateRecoveryPlan/queries/use-get-pending-studies'
import { NoDataTableError } from 'components/Table/StepOneRecoveryPlanTable/NoDataTableError'
import { isEmpty } from 'lodash'
import { useListProgrammedStudies } from './queries/use-get-completed-pending-studies'
import { useListPerformanceStudies } from './queries/use-get-list-performance'
import { useMutationTheoreticalStudies } from 'pages/CreateRecoveryPlan/queries/use-mutation-theorical-studies'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useToastMessage } from 'components/Toast'

export const RecoveryPlanActivated: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | State
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMd = useBreakpointValue({ base: false, md: true })

  const [
    selectedUser,
    setSelectedUser,
  ] = useState<ListPendingStudiesData | null>(null)
  const [weekFilter, setWeekFilter] = useState(true)
  const [filterAllProgramedStudies, setFilterAllProgrammedStudies] = useState(
    false,
  )
  const [modalConfirmRemoveStudy, setModalConfirmRemoveStudy] = useState(false)
  const [page, setPage] = useState(1)
  const [programmedPage, setProgrammedPage] = useState(1)

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, onOpen } = useDisclosure()

  const showToast = useToastMessage()

  const {
    data: listPendingStudies,
    error: listPendingStudiesError,
    isLoading: isListPendingStudiesLoading,
  } = useGetListPendingStudies({
    isProgrammedStudy: true,
    page: page,
    limit: 8,
  })

  const {
    data: listProgrammedStudies,
    error: listProgrammedStudiesError,
    isLoading: listProgrammedStudiesLoading,
  } = useListProgrammedStudies({
    isWeek: weekFilter ? weekFilter : undefined,
    page: programmedPage,
    limit: 8,
  })

  const {
    data: performanceData,
    isLoading: performanceDateIsLoading,
  } = useListPerformanceStudies()

  const { data: activePR } = useGetPrInfo()

  const mutationTheoreticalStudies = useMutationTheoreticalStudies()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleTheoreticalStudies = async (id?: number) => {
    if (!id) return

    try {
      if (activePR?.pendingStudiesData.id) {
        await mutationTheoreticalStudies.mutateAsync({
          studyRecoveryPlanId: activePR?.pendingStudiesData.id,
          pendingStudyId: id,
          isProgrammedStudy: false,
        })

        showToast({
          title: 'Sucesso',
          description: 'Estudo removido.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      }
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar o registro.'
      showToast({
        title: 'Erro ao adicionar estudo',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 3000,
        mode: 'dark',
      })
    } finally {
      setModalConfirmRemoveStudy(false)
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Diário de recuperação"
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://www.youtube.com/watch?v=dKfgeYNbI6g"
        />
        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://www.youtube.com/watch?v=dKfgeYNbI6g"
              mt="8px"
            />
          </Flex>
        )}

        <Text
          mt="12px"
          fontFamily="Mulish"
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          color="#FFFFFF"
        >
          Organize seus estudos em dia com nossa ferramenta de registro e
          acompanhamento de estudos atrasados
        </Text>

        <ProgressBar
          title="Desempenho geral da recuperação de estudo"
          maxW={'536px'}
          w="100%"
          concluded={performanceData?.completedStudiesPercentage ?? 0}
          inProgress={performanceData?.programmedStudiesPercentage ?? 0}
          rest={performanceData?.pendingStudiesPercentage ?? 0}
          total={100}
          mt="2rem"
          isLoading={performanceDateIsLoading}
        />

        <Flex
          flexDir={{ base: 'column', sm: 'row' }}
          mt="32px"
          justifyContent={'space-between'}
          gap="1rem"
        >
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
            color="#FFFFFF"
          >
            Estudos da semana
          </Text>

          <DefaultButton
            variant="primary"
            label="Estudos Pendentes"
            leftIcon={
              <Icon fontSize={'16px'} color="#E56000" icon={'eva:list-fill'} />
            }
            iconSpacing="8px"
            onClick={onOpen}
          />
        </Flex>

        <Flex mt="16px" gap={'8px'} flexDir={'column'}>
          <Text
            fontFamily="Mulish"
            fontWeight="600"
            fontSize="16px"
            lineHeight="20px"
            color="#FFFFFF"
          >
            Estudos programados
          </Text>

          <Box
            maxH="364px"
            h="100%"
            borderRadius={'8px 8px 8px 8px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
            overflow="auto"
          >
            <Table>
              <TrTableHeadProgramedStudies borderLeft={'6px solid #393940'} />
              <Tbody borderBottomColor="#302F37">
                {!isEmpty(listPendingStudies?.data) &&
                  !isListPendingStudiesLoading &&
                  !listPendingStudiesError &&
                  listPendingStudies?.data?.map((item, index) => {
                    return (
                      <TrTableProgramedStudies
                        key={`TableSectionData${index}`}
                        backgroundTable={
                          index % 2 == 0 ? 'rgba(57, 57, 64, 0.64)' : '#393940'
                        }
                        onClick={() => {
                          setSelectedUser(item)
                        }}
                        dateCompleted={item.finishes_at}
                        trId={item.id}
                        area={item.field.name}
                        studyType={item.is_theoretical}
                        subTheme={item.subjects}
                        theme={item.themes}
                        onClickOpenRemove={() =>
                          setModalConfirmRemoveStudy(true)
                        }
                      />
                    )
                  })}
              </Tbody>
            </Table>
          </Box>

          {listPendingStudiesError && !isListPendingStudiesLoading && (
            <NoDataTableError
              minH={{ base: 'unset', sm: '300px' }}
              w="100%"
              h="100%"
              headerMessage="Erro ao carregar!"
              message="Erro ao carregar estudos pendentes."
            />
          )}

          {isEmpty(listPendingStudies?.data) &&
            !isListPendingStudiesLoading &&
            !listPendingStudiesError && (
              <NoDataTableError
                minH={{ base: 'unset', sm: '300px' }}
                w="100%"
                h="100%"
                headerMessage="Nenhum estudo cadastrado!"
                message="Adicione novos estudos pendentes."
              />
            )}

          <Pagination
            currentPage={page}
            totalCountOfRegisters={listPendingStudies?.meta.total ?? 0}
            onPageChange={setPage}
            registersPerPage={8}
          />

          <ModalDefaultTwoButton
            isOpen={modalConfirmRemoveStudy}
            confirmButtonIsLoading={mutationTheoreticalStudies.isLoading}
            onClose={() => setModalConfirmRemoveStudy(false)}
            subTitle={
              ' Se continuar, o estudo será encaminhado para os estudos pendentes.'
            }
            title="Tem certeza que deseja remover o estudo?"
            onConfirmClick={() => handleTheoreticalStudies(selectedUser?.id)}
          />
        </Flex>

        <Flex mt="2rem" gap={'8px'} flexDir={'column'}>
          <Flex
            flexDir={{ base: 'column', sm: 'row' }}
            w="100%"
            justifyContent={'space-between'}
            alignItems={{ base: 'unset', sm: 'center' }}
            gap="8px"
          >
            <Text
              fontFamily="Mulish"
              fontWeight="600"
              fontSize="16px"
              lineHeight="20px"
              color="#FFFFFF"
            >
              Estudos concluídos dessa semana
            </Text>

            <HStack>
              <Button
                background={weekFilter ? '#E56000' : '#393940'}
                _hover={
                  weekFilter
                    ? { backgroundColor: '#FF6B00' }
                    : { backgroundColor: '#2c2c31' }
                }
                _active={
                  weekFilter
                    ? { backgroundColor: '#FF6B00' }
                    : { backgroundColor: '#2c2c31' }
                }
                borderRadius="8px"
                fontFamily="Mulish"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
                color="#FFFFFF"
                p="6px 16px"
                h="auto"
                onClick={() => {
                  setFilterAllProgrammedStudies(false)
                  setWeekFilter(true)
                }}
              >
                Essa semana
              </Button>

              <Button
                background={filterAllProgramedStudies ? '#E56000' : '#393940'}
                _hover={
                  filterAllProgramedStudies
                    ? { backgroundColor: '#FF6B00' }
                    : { backgroundColor: '#2c2c31' }
                }
                _active={
                  filterAllProgramedStudies
                    ? { backgroundColor: '#FF6B00' }
                    : { backgroundColor: '#2c2c31' }
                }
                borderRadius="8px"
                fontFamily="Mulish"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
                color="#FFFFFF"
                p="6px 16px"
                h="auto"
                onClick={() => {
                  setFilterAllProgrammedStudies(true)
                  setWeekFilter(false)
                }}
              >
                Todos
              </Button>
            </HStack>
          </Flex>

          <Box
            maxH="364px"
            h="100%"
            borderRadius={'8px 8px 8px 8px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
            overflow="auto"
          >
            <Table>
              <TrTableHeadStudiesDone borderLeft={'6px solid #393940'} />
              <Tbody borderBottomColor="#302F37">
                {!isEmpty(listProgrammedStudies?.data) &&
                  !listProgrammedStudiesLoading &&
                  !listProgrammedStudiesError &&
                  listProgrammedStudies?.data?.map((item, index) => {
                    return (
                      <TrTableStudiesDone
                        key={`TableSectionData${index}`}
                        backgroundTable={
                          index % 2 == 0 ? 'rgba(57, 57, 64, 0.64)' : '#393940'
                        }
                        area={item.field.name}
                        date={item.finishes_at}
                        studyType={item.is_theoretical}
                        subTheme={item.subjects}
                        theme={item.themes}
                        onClickOpenRemove={() => console.log('as')}
                      />
                    )
                  })}
              </Tbody>
            </Table>
          </Box>

          {listProgrammedStudiesError && !listProgrammedStudiesLoading && (
            <NoDataTableError
              minH={{ base: 'unset', sm: '300px' }}
              w="100%"
              h="100%"
              headerMessage="Erro ao carregar!"
              message="Erro ao carregar estudos pendentes!"
            />
          )}

          {isEmpty(listProgrammedStudies?.data) &&
            !listProgrammedStudiesLoading &&
            !listProgrammedStudiesError && (
              <NoDataTableError
                minH={{ base: 'unset', sm: '300px' }}
                w="100%"
                h="100%"
                headerMessage="Nenhum estudo cadastrado!"
                message="Conclua novos estudos programados na revisão inteligente!"
              />
            )}

          <Pagination
            currentPage={programmedPage}
            totalCountOfRegisters={listProgrammedStudies?.meta.total ?? 0}
            onPageChange={setProgrammedPage}
            registersPerPage={8}
          />
        </Flex>

        <DrawerPendingStudies isOpen={isOpen} onClose={onClose} />
      </Container>
    </Layout>
  )
}
