import * as Yup from 'yup'

export const NewUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, 'O nome deve ter de 5 até 80 caracteres.')
    .max(80)
    .required('O nome deve ter de 5 até 80 caracteres.'),
  mobilePhone: Yup.string()
    .required('O número de telefone é obrigatório.')
    .matches(/\+[0-9][0-9]?[0-9]\s?\(?\d{2}\)?\s?\d{8,9}/gm, {
      message: 'Formato inválido. Use +55 (00) 000000000',
    }),
  email: Yup.string()
    .email('Por favor informe um e-mail válido.')
    .required('O email é obrigatório.'),
  password: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres.')
    .required('A senha é obrigatória.'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não batem.')
    .required('A confirmação da senha é obrigatória.'),
  cpf: Yup.string()
    .required('CPF obrigatório')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
      {
        message: 'Formato inválido. Use um número de 11 dígitos.',
      },
    ),
})
