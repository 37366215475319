import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export interface GetUnviewedNotificationsCountResponse {
  total_notifications: number
}

export async function getUnviewedNotificationsCount(): Promise<GetUnviewedNotificationsCountResponse> {
  const { data } = await api.get<GetUnviewedNotificationsCountResponse>(
    '/app/notifications/unviewed',
  )

  return data
}

export function useGetUnviewedNotificationsCount() {
  return useQuery(
    ['get-unviewed-notifications-count'],
    () => getUnviewedNotificationsCount(),
    { refetchOnWindowFocus: false, retry: false },
  )
}
