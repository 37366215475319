import React, { useCallback, useState } from 'react'
import {
  Flex,
  Heading,
  AspectRatio,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import VideoSection from '../components/VideoSection'
import './Video.css'
import './VideoMobile.css'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useFormContext } from 'react-hook-form'
import { useMutateSetCurrentStep } from '../mutations/mutate-steps'

interface ThirdStep {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export default function ThirdStep({ setCurrentStep }: ThirdStep) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [videoTimeLineNumber, setVideoTimeLineNumber] = useState(0)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })
  const { watch } = useFormContext()
  const mutateCurrentStep = useMutateSetCurrentStep()

  /*
  |-----------------------------------------------------------------------------
  | Callback.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const IndexMatchesisActiveColorNumber = useCallback(() => {
    if (videoTimeLineNumber === 0) {
      return 'https://www.youtube.com/embed/gKRNmCbHFO4?si=OxemrsspSzpk8myH'
    }
    if (videoTimeLineNumber === 1) {
      return 'https://www.youtube.com/embed/JU3p1ZzLDs4?si=LQxRo80YYX-2BRl_'
    }
    if (videoTimeLineNumber === 2) {
      return 'https://www.youtube.com/embed/_na91MUyO3A?si=Yx8oxyMYb-EgifuR'
    }
    if (videoTimeLineNumber === 3) {
      return 'https://www.youtube.com/embed/f5SDdbx35X8?si=ya3jICmuiOCezldE'
    }
    if (videoTimeLineNumber === 4) {
      return 'https://www.youtube.com/embed/OjbF_dB1sV0?si=abU9Nm0GySa6mmyU'
    }
  }, [videoTimeLineNumber])

  /*   const setMonitor = useCallback(async () => {
    try {
      const response = await mutateSetMonitor.mutateAsync({
        trackId: getValues('trackId').value,
        trialYear: getValues('trialYear').value,
      })

      return response?.data?.studentMonitor?.[0]?.monitorDetail
        ?.monitor_video_links?.[0]
    } catch (error) {
      console.error(error)
    }
  }, [getValues, mutateSetMonitor]) */

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*   useEffect(() => {
    setMonitor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) */

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex w="100%" flexDir={{ base: 'column' }}>
      <Flex mb={{ base: '12px', md: '24px' }}>
        <Heading
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ base: '18px', sm: '32px' }}
          lineHeight={{ base: '23px', sm: '40px' }}
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Bem Vindo à Plataforma de Métricas
        </Heading>
      </Flex>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '1rem', sm: '2rem' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '516px' }}
          h={'auto'}
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            {isMobile ? (
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" maxW="480px">
                  <iframe
                    src={IndexMatchesisActiveColorNumber()}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    height="100%"
                    width={'100%'}
                    className="videoEssencialSkillMobile"
                  />
                </AspectRatio>
              </VStack>
            ) : (
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                  <iframe
                    src={IndexMatchesisActiveColorNumber()}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    height="100%"
                    width="100%"
                    style={
                      !isMobile
                        ? {
                            borderRadius: '8px',
                            border: '1px solid #e56000',
                          }
                        : {}
                    }
                  />
                </AspectRatio>
              </VStack>
            )}
          </Flex>
        </Flex>

        {isMobile ? (
          <>
            <Flex
              h="-moz-max-content"
              w="100%"
              flexDir={'column'}
              gap="23px"
              borderRadius="8px"
              m="0px !important"
            >
              <Flex flexDir={'column'} gap={{ base: '8px', sm: '15px' }}>
                <VideoSection
                  key={`VideoSection${0}`}
                  number={1}
                  onClick={() => setVideoTimeLineNumber(0)}
                  videoText={'Boas vindas'}
                  videoTime={'01:52'}
                />

                <VideoSection
                  key={`VideoSection${1}`}
                  number={2}
                  onClick={() => setVideoTimeLineNumber(1)}
                  videoText={'Jornada Mentoria e Residência'}
                  videoTime={'16:14'}
                />

                <VideoSection
                  key={`VideoSection${2}`}
                  number={3}
                  onClick={() => setVideoTimeLineNumber(2)}
                  videoText={'Plataforma de Métricas'}
                  videoTime={'19:04'}
                />

                <VideoSection
                  key={`VideoSection${3}`}
                  number={4}
                  onClick={() => setVideoTimeLineNumber(3)}
                  videoText={'Equipe Mentoria'}
                  videoTime={'06:47'}
                />

                <VideoSection
                  key={`VideoSection${4}`}
                  number={5}
                  onClick={() => setVideoTimeLineNumber(4)}
                  videoText={'Reuniões mentoria'}
                  videoTime={'07:57'}
                />
              </Flex>

              <Flex mt="auto" justifyContent={'space-between'} gap="12px">
                <DefaultButton
                  maxW="144px"
                  label="Voltar"
                  w="100%"
                  height="38px"
                  variant="ghost"
                  onClick={() => setCurrentStep(2)}
                />

                <DefaultButton
                  label="Continuar"
                  maxW="144px"
                  height="38px"
                  w="100%"
                  onClick={() => setCurrentStep(3)}
                />
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex
            maxW="368px"
            h="516px"
            minW="176px"
            w="100%"
            p={'24px'}
            flexDir={'column'}
            gap="23px"
            background={'#302F37'}
            borderRadius="8px"
            m="0px !important"
          >
            <Flex>
              <Heading
                fontFamily="Mulish"
                fontStyle=" normal"
                fontWeight=" 700"
                fontSize=" 24px"
                lineHeight=" 30px"
                color="#FFFFFF"
              >
                Linha do tempo
              </Heading>
            </Flex>

            <Flex flexDir={'column'} gap="15px">
              <VideoSection
                key={`VideoSection${0}`}
                number={1}
                onClick={() => setVideoTimeLineNumber(0)}
                videoText={'Boas vindas'}
                videoTime={'01:52'}
              />

              <VideoSection
                key={`VideoSection${1}`}
                number={2}
                onClick={() => setVideoTimeLineNumber(1)}
                videoText={'Jornada Mentoria e Residência'}
                videoTime={'16:14'}
              />

              <VideoSection
                key={`VideoSection${2}`}
                number={3}
                onClick={() => setVideoTimeLineNumber(2)}
                videoText={'Plataforma de Métricas'}
                videoTime={'19:04'}
              />

              <VideoSection
                key={`VideoSection${3}`}
                number={4}
                onClick={() => setVideoTimeLineNumber(3)}
                videoText={'Equipe Mentoria'}
                videoTime={'06:47'}
              />

              <VideoSection
                key={`VideoSection${4}`}
                number={5}
                onClick={() => setVideoTimeLineNumber(4)}
                videoText={'Reuniões mentoria'}
                videoTime={'07:57'}
              />
            </Flex>

            <Flex mt="auto" justifyContent={'center'} gap="12px">
              <DefaultButton
                label="Voltar"
                variant="ghost"
                w="100%"
                onClick={() => setCurrentStep(1)}
              />

              {watch('trackId')?.label === 'PNA PORTUGAL' ||
              watch('trackId')?.label === 'Revalida' ? (
                <DefaultButton
                  w="100%"
                  label="Continuar"
                  type="submit"
                  onClick={() => {
                    // should pass to next step even if there is an error on this mutation
                    mutateCurrentStep.mutate({
                      onboardCurrentStep: 5,
                    })
                  }}
                />
              ) : (
                <DefaultButton
                  label="Continuar"
                  w="100%"
                  onClick={async () => {
                    try {
                      // should pass to next step even if there is an error on this mutation
                      mutateCurrentStep.mutate({
                        onboardCurrentStep: 3,
                      })

                      setCurrentStep(3)
                    } catch (error) {
                      console.trace(error)
                    }
                  }}
                />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
