import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

// Assets
import logo from 'assets/images/logo.png'
import bernardo from 'assets/images/bernardo.png'

// Contexts
import { useAuth } from 'contexts/auth'

// Schemas
import { LogInSchema } from './schemas'

// Styles
import { Container, FormContainer } from './styles'
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, VStack } from '@chakra-ui/react'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

interface LoginForm {
  email: string
  password: string
}

const LogIn: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logIn, isLoading } = useAuth()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(LogInSchema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(
    async (formData: LoginForm) => {
      try {
        logIn(formData)
      } catch (err) {
        Swal.fire({
          title: 'Erro',
          text: 'Não foi possível efetuar o login. Tente novamente mais tarde',
          icon: 'error',
        })
      }
    },
    [logIn],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <img src={logo} alt="mentoria logo" id="logo" />
      <div>
        <FormContainer onSubmit={handleSubmit(submitHandler)}>
          <p>
            Acesse <span>sua conta</span>
          </p>

          <VStack spacing={4} px={{ base: '1rem', sm: 'none' }}>
            <Box w="100%" maxW={'380px'}>
              <FieldInputController
                control={control}
                placeholder="E-mail"
                type="email"
                name="email"
                error={errors?.email}
              />
            </Box>

            <Box w="100%" maxW={'380px'}>
              <FieldInputController
                control={control}
                placeholder="Senha"
                type="password"
                name="password"
                error={errors?.password}
              />
            </Box>
          </VStack>

          <div>
            <DefaultButton
              isLoading={isLoading}
              type="submit"
              minW={'176px'}
              label="ENTRAR"
            />
            <Link to="/forgot_password/new">esqueci minha senha</Link>
          </div>
        </FormContainer>
        <img src={bernardo} alt="bernardo" id="bernardo-welcome" />
      </div>
      <p>Além da Medicina | Todos os direitos reservados</p>
    </Container>
  )
}

export default LogIn
