import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  HStack,
  Text,
  Link as ChakraLink,
  Tooltip,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { IconType } from 'react-icons/lib'
import { useSideBar } from 'contexts/sidebar'
import { useWindowSize } from 'hooks/useWindowSize'

export interface Options {
  isExternal: boolean
  label: string
  path?: string
  icon: any
  hasSubItem?: boolean
  subitens?: Options[]
  shouldShowTrialMenus?: boolean
  shouldRender?: boolean
}

interface MenuItemProps {
  data: Options
  subItemsPath: (string | undefined)[] | undefined
  isActive: boolean
  pathname: string
  icon: IconType
  isSidebarSetToWide?: boolean
  executeBeforeOnClick?: () => void
  shouldShowTrialMenus?: boolean
}

export const MenuItem = (props: MenuItemProps) => {
  const {
    data,
    icon: IconType,
    isSidebarSetToWide,
    subItemsPath,
    isActive,
    pathname,
    executeBeforeOnClick,
  } = props

  const [open, setOpen] = useState(false)
  const [hoverItem, setHoverItem] = useState(false)
  const { setIsSidebarSetToWide } = useSideBar()
  const { width } = useWindowSize()

  useEffect(() => {
    if (subItemsPath?.includes(pathname)) {
      setOpen(true)
    }
  }, [pathname, subItemsPath])

  if (!data.shouldRender && !data.shouldShowTrialMenus) {
    return null
  }

  /*
  |-----------------------------------------------------------------------------
  | shouldShowTrialMenus, is not mobile, trial menus shouldn't route the user
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (data.shouldShowTrialMenus && isSidebarSetToWide) {
    return (
      <Tooltip label="Disponível somente para alunos Mentoria Residência. Garanta já seu acesso completo à plataforma!">
        <Box
          disabled
          textDecoration={'none !important'}
          onMouseEnter={() => setHoverItem(true)}
          onMouseLeave={() => setHoverItem(false)}
          cursor={'pointer'}
          _disabled={{
            opacity: '0.4',
          }}
        >
          <HStack spacing={'1rem'} h="56px">
            <Icon
              icon={'fontisto:locked'}
              fontSize={14}
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            />
            <Text
              fontFamily="Mulish"
              fontWeight="400"
              fontSize="15px"
              lineHeight="20px"
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            >
              {data.label}
            </Text>
          </HStack>
        </Box>
      </Tooltip>
    )
  }

  /*
  |-----------------------------------------------------------------------------
  | is shouldShowTrialMenus and mobile - trial menus shouldn't route the user
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (data.shouldShowTrialMenus && !isSidebarSetToWide) {
    return (
      <Tooltip
        w="full"
        label="Disponível somente para alunos Mentoria Residência. Garanta já seu acesso completo à plataforma!"
      >
        <Box
          disabled
          textDecoration={'none !important'}
          onMouseEnter={() => setHoverItem(true)}
          onMouseLeave={() => setHoverItem(false)}
          cursor={'pointer'}
          _disabled={{
            opacity: '0.4',
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <HStack spacing={'1rem'} h="56px">
            <Icon
              icon={'fontisto:locked'}
              fontSize={14}
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            />
          </HStack>
        </Box>
      </Tooltip>
    )
  }

  /*
  |-----------------------------------------------------------------------------
  | is not shouldShowTrialMenus, is mobile, and does not have subitem
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!data.hasSubItem && isSidebarSetToWide) {
    if (data.isExternal) {
      return (
        <ChakraLink
          data-qa={data.label}
          href={data.path}
          target="_blank"
          rel="noopener noreferrer"
          key={data.path}
          textDecoration={'none !important'}
          onMouseEnter={() => setHoverItem(true)}
          onMouseLeave={() => setHoverItem(false)}
          cursor={'pointer'}
          onClick={() => {
            executeBeforeOnClick && executeBeforeOnClick()
            width && width <= 768 && setIsSidebarSetToWide(false)
          }}
        >
          <HStack spacing={'1rem'} h="56px">
            <IconType
              size={14}
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            />
            <Text
              fontFamily="Mulish"
              fontWeight="400"
              fontSize="15px"
              lineHeight="20px"
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            >
              {data.label}
            </Text>
          </HStack>
        </ChakraLink>
      )
    } else if (!data.isExternal && data.path) {
      return (
        <Link
          data-qa={data.label}
          to={data.path}
          key={data.path}
          onMouseEnter={() => setHoverItem(true)}
          onMouseLeave={() => setHoverItem(false)}
          onClick={() => width && width <= 768 && setIsSidebarSetToWide(false)}
        >
          <HStack spacing={'1rem'} h="56px">
            <IconType
              size={14}
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            />
            <Text
              fontFamily="Mulish"
              fontWeight="400"
              fontSize="15px"
              lineHeight="20px"
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            >
              {data.label}
            </Text>
          </HStack>
        </Link>
      )
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | is not shouldShowTrialMenus, is mobile, and doesn't have subitem
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!data.hasSubItem && !isSidebarSetToWide) {
    if (data.isExternal) {
      return (
        <a
          data-qa={data.label}
          href={data.path}
          target="_blank"
          style={{
            cursor: 'pointer',
          }}
          rel="noopener noreferrer"
          key={data.path}
          onMouseEnter={() => setHoverItem(true)}
          onMouseLeave={() => setHoverItem(false)}
          onClick={() => executeBeforeOnClick && executeBeforeOnClick()}
        >
          <HStack spacing={'1rem'} h="56px" w="100%" justifyContent={'center'}>
            <IconType
              size={14}
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            />
          </HStack>
        </a>
      )
    } else if (!data.isExternal && data.path) {
      return (
        <Link
          data-qa={data.label}
          className="TextElements"
          to={data.path}
          key={data.path}
        >
          <HStack spacing={'1rem'} h="56px" w="100%" justifyContent={'center'}>
            <IconType
              size={14}
              color={isActive ? '#E56000' : hoverItem ? '#E56000' : '#F5F5FA'}
            />
          </HStack>
        </Link>
      )
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | is not shouldShowTrialMenus, hasSubItems, and sideBar is not set to wide
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (data.hasSubItem && isSidebarSetToWide) {
    return (
      <>
        <Flex
          data-qa={data.label}
          justifyContent="space-between"
          onClick={() => setOpen(prev => !prev)}
          alignItems="center"
          cursor={'pointer'}
          onMouseEnter={() => setHoverItem(true)}
          onMouseLeave={() => setHoverItem(false)}
        >
          <HStack spacing={'1rem'} h="56px">
            <IconType
              size={14}
              color={
                subItemsPath?.includes(pathname) && open
                  ? '#E56000'
                  : hoverItem
                  ? '#E56000'
                  : '#F5F5FA'
              }
            />
            <Text
              fontFamily="Mulish"
              fontWeight="400"
              fontSize="15px"
              lineHeight="20px"
              color={
                subItemsPath?.includes(pathname) && open
                  ? '#E56000'
                  : hoverItem
                  ? '#E56000'
                  : '#F5F5FA'
              }
            >
              {data.label}
            </Text>
          </HStack>

          {open ? (
            <Icon
              color={
                subItemsPath?.includes(pathname) && open
                  ? '#E56000'
                  : hoverItem
                  ? '#E56000'
                  : '#F5F5FA'
              }
              icon={'eva:arrow-ios-upward-fill'}
              fontSize="17px"
            />
          ) : (
            <Icon
              color="#F5F5FA"
              icon={'eva:arrow-ios-downward-fill'}
              fontSize="17px"
            />
          )}
        </Flex>

        <Flex flexDir={'column'} display={open ? 'flex' : 'none'}>
          {open &&
            data?.subitens?.map((item, index) => {
              if (!item.shouldRender) {
                return null
              }

              if (item.path) {
                return (
                  <Link
                    data-qa={item.label}
                    className="TextElements"
                    to={item.path}
                    key={`subitensActive${index}`}
                    style={{ marginLeft: '5px' }}
                    onClick={() =>
                      width && width <= 768 && setIsSidebarSetToWide(false)
                    }
                  >
                    <HStack
                      background={
                        pathname === item.path ? '#393940' : 'inherit'
                      }
                      borderRadius="4px 4px 4px 0px"
                      w="fit-content"
                      p="0px 16px 0px 0px"
                      h="36px"
                      position={'relative'}
                      spacing=""
                    >
                      {pathname === item.path && (
                        <Box
                          borderRadius={'full'}
                          position={'absolute'}
                          h="100%"
                          border={'2px solid #E56000'}
                          backgroundColor={'#E56000'}
                        />
                      )}

                      {pathname !== item.path && (
                        <Box
                          position={'absolute'}
                          borderRadius={'full'}
                          h="100%"
                          border={'2px solid #60606C'}
                          backgroundColor={'#60606C'}
                        />
                      )}

                      <Flex ml="20px" alignItems={'center'} gap="10px">
                        {item.icon}
                        <Text color="#F5F5FA">{item.label}</Text>
                      </Flex>
                    </HStack>
                  </Link>
                )
              }
            })}
        </Flex>
      </>
    )
  }

  /*
  |-----------------------------------------------------------------------------
  | is not shouldShowTrialMenus, hasSubItems, and sideBar is set to wide
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (data.hasSubItem && !isSidebarSetToWide) {
    return (
      <>
        <Flex
          data-qa={data.label}
          justifyContent="space-between"
          alignItems="center"
          cursor={'pointer'}
          onClick={() => setIsSidebarSetToWide(true)}
        >
          <HStack spacing={'1rem'} h="56px" w="100%" justifyContent={'center'}>
            <IconType
              size={14}
              color={
                subItemsPath?.includes(pathname) && open
                  ? '#E56000'
                  : hoverItem
                  ? '#E56000'
                  : '#F5F5FA'
              }
            />
          </HStack>
        </Flex>
      </>
    )
  }

  return null
}
