import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from './Route'

import LogIn from 'pages/LogIn'
import ValidateAccount from 'pages/ValidateAccount'

import TrailRegister from 'pages/TrialRegister'

import ForgotPasswordNew from 'pages/ForgotPasswordNew'
import ForgotPasswordReset from 'pages/ForgotPasswordReset'
import DailyRecords from 'pages/DailyRecords'

import Account from 'pages/Account'
import Contact from 'pages/NewContact'
import Reports from 'pages/Reports'
import Calendar from 'pages/Calendar'
import TopThemes from 'pages/TopThemes'
import Incidences from 'pages/Incidences'
import StudentReport from 'pages/StudentReport'
import TrashPage from 'pages/Trash'
import { CreateRecoveryPlan } from 'pages/CreateRecoveryPlan'
import { RecoveryPlan } from 'pages/RecoveryPlan'
import { useAuth } from 'contexts/auth'
import CircleCommunity from 'pages/CircleCommunity'
import { ProjectX } from 'pages/Project-X'
import Simulation from 'pages/Simulation'
import SimulationNewPage from 'pages/SimulationNew'
import SimulationReviewPage from 'pages/SimulationReview'
import { ConceptsKiller } from 'pages/KillerConcepts'
import { useTrack } from 'contexts/track'
import { ConceptsKillerView } from 'pages/KillerConceptsView'
import { DashboardPage } from 'pages/Dashboard'
import { PreOnboarding } from 'pages/PreOnboarding'
import { MyVersion2 } from 'pages/MyVersion2'
import { OnboardingPage } from 'pages/Onboarding'
import { VideoOnboarding } from 'pages/VideoOnboarding'
import Home from 'pages/Home'
import TimeLineMR from 'pages/TimelineMR'

const App = () => {
  const { userContract, user, isFocusLearningActivated } = useAuth()

  const { track } = useTrack()

  return (
    <React.Fragment>
      <Switch>
        {/* Public routes */}
        <Route path="/" exact component={LogIn} />
        <Route path="/validate_account" exact component={ValidateAccount} />
        <Route path="/forgot_password/reset" component={ForgotPasswordReset} />
        <Route path="/forgot_password/new" component={ForgotPasswordNew} />
        <Route path="/welcome" component={TrailRegister} />

        {/* Private routes */}
        <Route
          path="/student/report"
          exact
          component={StudentReport}
          isPrivate
        />
        <Route path="/onboarding" exact component={OnboardingPage} isPrivate />
        <Route
          path="/pre-onboarding"
          exact
          component={PreOnboarding}
          isPrivate
        />
        <Route path="/home" exact component={Home} isPrivate />
        <Route path="/dashboard" exact component={DashboardPage} isPrivate />
        <Route path="/daily-records" exact component={DailyRecords} isPrivate />
        <Route
          path="/topthemes"
          exact
          component={TopThemes}
          isPrivate
          /* notAllowed={userContract?.type !== 'TRIAL'} */
          notAllowed={
            userContract?.type !== 'PURCHASE' ||
            user?.contracts?.[0]?.final_date !== '2058-12-08'
          }
        />
        <Route path="/calendar" exact component={Calendar} isPrivate />
        <Route path="/account" exact component={Account} isPrivate />
        <Route
          path="/community"
          exact
          component={CircleCommunity}
          isPrivate
          /* notAllowed={userContract?.type === 'TRIAL'} */
          notAllowed={
            userContract?.type !== 'PURCHASE' ||
            user?.contracts?.[0]?.final_date !== '2058-12-08'
          }
        />
        <Route path="/incidences" exact component={Incidences} isPrivate />
        <Route path="/contact" exact component={Contact} isPrivate />
        <Route
          path="/reports"
          exact
          component={Reports}
          isPrivate
          notAllowed={userContract?.type === 'TRIAL'}
        />
        <Route path="/trash" exact component={TrashPage} isPrivate />
        <Route
          path="/create-plan"
          exact
          component={CreateRecoveryPlan}
          isPrivate
          /* notAllowed={
            userContract?.type === 'TRIAL' || isFocusLearningActivated
          } */
          notAllowed={
            userContract?.type !== 'PURCHASE' || isFocusLearningActivated
          }
        />
        <Route
          path="/recovery-plan"
          /* notAllowed={
            userContract?.type === 'TRIAL' || isFocusLearningActivated
          } */
          notAllowed={
            userContract?.type !== 'PURCHASE' || isFocusLearningActivated
          }
          exact
          component={RecoveryPlan}
          isPrivate
        />
        <Route
          path="/project-x"
          /* notAllowed={
            userContract?.type === 'TRIAL' ||
            track?.name === 'PNA' ||
            user?.profile?.trial_year !== 2023
          } */
          notAllowed={
            userContract?.type !== 'PURCHASE' ||
            track?.name === 'PNA PORTUGAL' ||
            user?.profile?.trial_year !== 2024
          }
          exact
          component={ProjectX}
          isPrivate
        />
        <Route
          path="/killer-concepts"
          exact
          component={ConceptsKiller}
          isPrivate
        />

        <Route
          path="/view-killer-concept/:id"
          exact
          component={ConceptsKillerView}
          isPrivate
        />

        <Route
          path="/myversion2"
          notAllowed={!user?.canAccessSeren || userContract?.type === 'TRIAL'}
          exact
          component={MyVersion2}
          isPrivate
        />

        <Route
          path="/simulation"
          /* notAllowed={userContract?.type !== 'TRIAL'} */
          notAllowed={userContract?.type !== 'PURCHASE' && track?.name !== 'R1'}
          exact
          component={Simulation}
          isPrivate
        />

        <Route
          path="/simulation/new/:id"
          exact
          /* notAllowed={userContract?.type !== 'TRIAL'} */
          notAllowed={userContract?.type !== 'PURCHASE' && track?.name !== 'R1'}
          component={SimulationNewPage}
          isPrivate
        />

        <Route
          path="/simulation/review/:id"
          exact
          /* notAllowed={userContract?.type !== 'TRIAL'} */
          notAllowed={userContract?.type !== 'PURCHASE' && track?.name !== 'R1'}
          component={SimulationReviewPage}
          isPrivate
        />

        <Route
          path="/videos-onboarding"
          exact
          component={VideoOnboarding}
          isPrivate
          notAllowed={userContract?.type === 'TRIAL'}
        />

        <Route
          path="/timeline"
          exact
          component={TimeLineMR}
          isPrivate
          notAllowed={
            userContract?.type === 'TRIAL' ||
            (track?.name !== 'R1' &&
              track?.name !== 'R+ CGE' &&
              track?.name !== 'R+ CM')
          }
        />

        {/* On not found */}
        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

export default App
