import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'

import {
  FieldRadioCard,
  RadioButtonCardGroupProps,
} from 'components/Fields/FieldRadioCard'

import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'

type FieldRadioCardControllerProps = RadioButtonCardGroupProps & {
  name: string
  label?: string
  control: Control<any>
  error?: FieldError
}

const FieldRadioCardController: React.FC<FieldRadioCardControllerProps> = ({
  name,
  label,
  error,
  control,

  ...rest
}) => {
  return (
    <FormControl w="auto" isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FieldRadioCard {...rest} options={rest.options} {...field} />
        )}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export default FieldRadioCardController
