import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponseApi } from 'types/response'

interface Prevalence {
  id: number
  theme_id: number
  prevalence_percentage: string
  created_at: string
  updated_at: string
  theme: string
  field: string
}

interface Params {
  fieldId?: number
  limit?: number
  page?: number
}

interface PrevalenceDataResponse {
  meta: MetaResponseApi
  data: Prevalence[]
}

export async function GetPrevalenceStudies(
  params?: Params,
): Promise<PrevalenceDataResponse> {
  const { data: PrevalenceData } = await api.get<{
    meta: MetaResponseApi
    data: Prevalence[]
  }>('/app/pending_studies/themes/prevalence', {
    params: {
      limit: params?.limit,
      page: params?.page,
      fieldId: params?.fieldId,
    },
  })

  return PrevalenceData
}

export function useGetPrevalenceStudies(params?: Params) {
  return useQuery(
    ['list-prevalence', params],
    () => GetPrevalenceStudies(params),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
