import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponse } from 'types/response'

export interface KillerConcepts {
  id: number
  title: string
  track_id: number
  field_id: number
  year: string[]
  file_key: string
  created_at: string
  updated_at: string
}

interface ResponseKillerConcepts {
  meta: MetaResponse
  data: KillerConcepts[]
}

interface Params {
  limit: number
  page: number
  trackId?: number | undefined
  fieldId?: number | undefined
  year?: number | undefined
}
export async function getListKillerConcepts(
  params: Params,
): Promise<ResponseKillerConcepts> {
  const { data } = await api.get<ResponseKillerConcepts>(
    '/app/essential-concepts',
    {
      params: {
        limit: params.limit,
        page: params.page,
        trackId: params.trackId ? params.trackId : undefined,
        fieldId: params.fieldId ? params.fieldId : undefined,
        year: params.year ? params.year : undefined,
      },
    },
  )

  return data
}

export function useGetListKillerConcepts(params: Params) {
  return useQuery(['fetch-killer-concepts', params], () =>
    getListKillerConcepts(params),
  )
}
