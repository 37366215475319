import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'contexts/auth'
import api from 'services/api'

export interface UpdateProfileParams {
  intendedInstitutions?: string[]
  intendedSpecialties?: string[]
  prepSchool?: string[]
  trialYear?: number
  goalType?: 'VETERAN' | 'NEWBIE'
}

interface Params {
  id: number
  data: UpdateProfileParams
}

async function updateProfile(params: Params) {
  await api.patch(`/app/profiles/${params.id}`, {
    ...params.data,
  })
}

export function useUpdateProfile() {
  const { updateUserData } = useAuth()

  return useMutation(updateProfile, {
    onSuccess: () => {
      updateUserData()
    },
  })
}
