import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

interface Query {
  trackId?: number
  fieldId?: number
}

interface Params {
  limit: number
}

interface GetThemeResponseApi {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface GetTheme {
  id: number
  name: string
}

interface GetThemeResponse {
  themes: GetTheme[]
  meta: MetaResponse
}

async function getThemes(
  query: Query,
  params?: Params,
): Promise<GetThemeResponse | undefined> {
  const { fieldId, trackId } = query

  if (!trackId || !fieldId) return

  const { data } = await api.get<{
    data: GetThemeResponseApi[]
    meta: MetaResponseApi
  }>(`/app/tracks/${trackId}/fields/${fieldId}/themes`, {
    params,
  })

  const themes = data.data.map(theme => ({
    id: theme.id,
    name: theme.name,
  }))

  return { themes, meta: formatMeta(data.meta) }
}

export function useGetThemes(query: Query, params?: Params) {
  return useQuery(['themes', query], () => getThemes(query, params))
}
