import React from 'react'
import { Container, Content } from './styles'
import Layout from 'layouts/Logged'
import Header from 'components/Header'
import { ViewPage } from 'features/killer-concept/view-pdf'

export const ConceptsKillerView: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Materiais Complementares"
          subTitle="Acesse os principais materiais complementares do mentoria residencia, como: conceitos matadores e DNA das provas."
          hasVideoOrientation={false}
        />

        <Content>
          <ViewPage />
        </Content>
      </Container>
    </Layout>
  )
}
