import React, { useCallback, useEffect } from 'react'
import {
  Heading,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  ModalCloseButton,
  ModalBody,
  VStack,
  Text,
  Stack,
  HStack,
  Box,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldDateController } from 'components/Fields/FieldDateNew/FieldDateController'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { useForm } from 'react-hook-form'
import { EditTestTable, Test } from '../../../type'
import { schema } from './schema'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { pt } from 'date-fns/locale'
import { RadioCardController } from 'components/Fields/FieldCard/RadioCardController'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { formatDateAddTimeZoned } from 'helpers/formatTimer'
import { useEditTest } from '../queries/use-mutation-edit-test'
import { format } from 'date-fns'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'

interface ModalEditTestsProps extends Omit<ModalProps, 'children'> {
  dataTest: Test | null
}

export default function ModalEditTests(props: ModalEditTestsProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { dataTest, ...rest } = props
  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<EditTestTable>({
    resolver: yupResolver(schema),
  })

  const RESET_FORM = {
    date: null,
    institutionName: '',
    numberOfQuestions: 0,
    numberOfCorrectAnswers: 0,
    effortPerception: 0,
    studiedMinutes: 0,
  }

  const editTest = useEditTest()

  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleEffortHelperText = (value: number) => {
    switch (value) {
      case 1:
      case 2:
        return 'Nenhum esforço'
      case 3:
      case 4:
        return 'Pouco esforço'
      case 5:
      case 6:
        return 'Médio esforço'
      case 7:
      case 8:
        return 'Alto esforço'
      case 9:
      case 10:
        return 'Máximo esforço'
      default:
        return ''
    }
  }

  const onSubmit = useCallback(
    async (data: EditTestTable) => {
      if (!dataTest) return
      try {
        await editTest.mutateAsync({
          date: format(data.date!, 'yyyy-MM-dd'),
          effortPerception: data.effortPerception,
          id: dataTest.id,
          numberOfQuestions: data.numberOfQuestions,
          numberOfCorrectAnswers: data.numberOfCorrectAnswers,
          studiedMinutes: data.studiedMinutes,
          institutionName: data.institutionName,
          isDeleted: dataTest.is_deleted,
          score: dataTest.score,
          user_id: dataTest.user_id,
          year: dataTest.year,
        })

        showToast({
          title: 'Sucesso',
          description: 'Estudo modificado com sucesso.',
          type: 'success',
          duration: 5000,
          mode: 'dark',
        })
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível modificar o estudo.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      } finally {
        props.onClose()
      }
    },
    [dataTest, editTest, props, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!dataTest) return

    clearErrors('effortPerception')
    clearErrors('institutionName')
    clearErrors('date')
    clearErrors('numberOfQuestions')
    clearErrors('numberOfCorrectAnswers')
    clearErrors('studiedMinutes')

    setValue('institutionName', dataTest?.institution_name)
    setValue('date', formatDateAddTimeZoned(dataTest?.date))
    setValue('studiedMinutes', dataTest?.studied_minutes)
    setValue('numberOfQuestions', dataTest?.number_of_questions)
    setValue('numberOfCorrectAnswers', dataTest?.number_of_correct_answers)
    setValue('effortPerception', dataTest?.effort_perception)
  }, [dataTest, setValue, props.isOpen, clearErrors])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent
        p="0px"
        background={'#302F37'}
        borderRadius={'8px'}
        maxW="406px"
        w="100%"
        overflow={'hidden'}
        transition={'all 0.2s ease-in-out'}
      >
        <ModalHeader background={'#302F37'} color={'#FFFF'} p="12px 16px">
          <HStack
            mb="8px"
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Heading as="h1" fontSize={'24px'} fontWeight={700}>
              Provas e simulados
            </Heading>

            <ModalCloseButton
              right={'unset'}
              top={'unset'}
              color={'#FFFF'}
              position={'relative'}
              _focus={{ outline: 'none' }}
            />
          </HStack>

          <Text fontWeight={400} fontSize={'16px'} color={'#FFFF'}>
            Registre suas métricas diariamente.
          </Text>
        </ModalHeader>

        <ModalBody color={'#FFFF'} background={'#302F37'} w="100%" p="0px">
          <VStack
            as="form"
            align={'flex-start'}
            onSubmit={handleSubmit(onSubmit)}
            flexDir={'column'}
            p="16px 16px 24px 16px"
            spacing={'1rem'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
          >
            <FieldInputController
              label="Nome da instituição"
              name="institutionName"
              control={control}
              placeholder="Nome da instituição"
              error={errors.institutionName}
            />

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent={'space-between'}
              gap={{ base: '16px', sm: '40px' }}
              w="full"
            >
              <Box className="NewDatePicker">
                <FieldDateController
                  label="Data"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                  control={control}
                  maxDate={new Date()}
                  inputProps={{ _placeholder: { color: 'gray.400' } }}
                  error={errors?.date}
                  name={'date'}
                  dropdownMode="select"
                  placeholderText={'00/00/0000'}
                />
              </Box>

              <FieldInputController
                label="Tempo de estudo"
                name="studiedMinutes"
                type="number"
                control={control}
                placeholder="Tempo em minutos"
                onWheel={e => e.currentTarget.blur()}
                error={errors.studiedMinutes}
              />
            </Stack>

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent={'space-between'}
              gap={{ base: '16px', sm: '40px' }}
              w="full"
            >
              <FieldInputController
                label="Questões realizadas"
                name="numberOfQuestions"
                control={control}
                placeholder="00"
                type="number"
                onWheel={e => e.currentTarget.blur()}
                error={errors.numberOfQuestions}
              />

              <FieldInputController
                label="Questões corretas"
                name="numberOfCorrectAnswers"
                control={control}
                placeholder="00"
                type="number"
                onWheel={e => e.currentTarget.blur()}
                error={errors.numberOfCorrectAnswers}
              />
            </Stack>

            <RadioCardController
              name="effortPerception"
              label="Percepção de esforço?"
              control={control}
              options={Array.from({ length: 10 }).map((_, index) => ({
                label: index + 1,
                value: index + 1,
              }))}
              error={errors.effortPerception}
            />

            <Text color={'#E56000'} fontWeight={500} fontSize={'14px'}>
              {handleEffortHelperText(Number(watch('effortPerception')))}
            </Text>

            <Stack w="full" direction={{ base: 'column', sm: 'row' }}>
              <DefaultButton
                onClick={() => reset(RESET_FORM)}
                w="full"
                variant="ghost"
                label="Limpar"
              />

              <DefaultButton
                w="full"
                isLoading={isSubmitting}
                type="submit"
                label="Atualizar"
              />
            </Stack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
