import styled from 'styled-components'
import { Scroll, ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  ${ResponsiveContainer};

  overflow-y: auto;

  ${Scroll}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 64px;
`
