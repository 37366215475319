import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function updateDeleted({
  id,
  isDeleted,
}: {
  id: number
  isDeleted: boolean
}) {
  return await api.put(`/app/daily_records/${id}`, { isDeleted })
}

export function useUpdateDeleted() {
  return useMutation(updateDeleted, {
    onSuccess: () => {
      queryClient.invalidateQueries(['daily-records', 'deleted'])
    },
  })
}
