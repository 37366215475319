import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface PendingStudies {
  id: number
  user_id: number
  track_id: number
  field_id: number
  theme_id: number | null
  subject_id: number | null
  attended_class: boolean
  created_at: string
  updated_at: string
  is_deleted: boolean
  is_theoretical: boolean
  is_programmed_study: boolean
  study_time_in_minutes: number
  starts_at: string | null
  finishes_at: string | null
  study_recovery_plan_id: number
}

export interface GetPendingStudies {
  id: number
  available_study_minutes: number | null
  status: 'PENDING' | 'ACTIVE' | 'FINISHED'
  starts_at: string
  finishes_at: string
  activated_at: string
  user_id: number
  created_at: string
  updated_at: string
  pendingStudies: PendingStudies[]
}
interface GetPendingStudiesResponse {
  pendingStudiesData: GetPendingStudies
  statusCode: number
}

export async function activateGetPrInfo(): Promise<GetPendingStudiesResponse> {
  const { data: recoveryPlanData, status } = await api.get(
    '/app/recovery_plan/active',
  )

  return { pendingStudiesData: recoveryPlanData, statusCode: status }
}

export function useGetPrInfo() {
  return useQuery(['activated-recovery-plan'], activateGetPrInfo, {
    retry: false,
    refetchOnWindowFocus: false,
  })
}
