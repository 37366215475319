import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Link,
} from '@chakra-ui/react'
import { LabelStepVideo } from './LabelStepVideo'

export type VideoData = {
  moduleName?: string
  videos: {
    videoTitle: string
    videoUrl: string
  }[]
  docs: {
    docTitle: string
    docUrl: string
  }[]
  externalUrls: {
    title: string
    url: string
  }[]
}[]

interface ModulesProps {
  videoData: VideoData
  getVideos: (videos: string) => void
  activeVideo: string
}

export const Modules = ({
  videoData,
  getVideos,
  activeVideo,
}: ModulesProps) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple experimental_spaceY={'8px'}>
      {videoData.map((module, index) => (
        <AccordionItem key={module.moduleName} border="none">
          <h2>
            <AccordionButton
              borderRadius="8px"
              background={'#484851'}
              mb="8px"
              h="46px"
              w="100%"
              textAlign="left"
              _focus={{ border: '1px solid #C2D1D9' }}
              _expanded={{ bg: '#393940' }}
            >
              <Text
                fontFamily="Mulish"
                fontWeight="700"
                fontSize="14px"
                lineHeight="18px"
                w="100%"
                pl="42px"
                color="#FFFFFF"
              >
                {module.moduleName ? module.moduleName : `Módulo ${index + 1}`}
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel p="0 0 8px 4px" w="full" experimental_spaceY={'8px'}>
            {module.videos.map((video, index) => (
              <LabelStepVideo
                key={video.videoTitle}
                videoText={video.videoTitle}
                number={index + 1}
                onClick={() => getVideos(video.videoUrl)}
                isActiveVideo={activeVideo === video.videoUrl}
              />
            ))}

            {module.docs.length > 0 &&
              module.docs.map((doc, index) => (
                <Link
                  key={doc.docTitle}
                  href={doc.docUrl}
                  target="_blank"
                  rel="noopener,noreferrer"
                  display="flex"
                  textDecoration={'none !important'}
                >
                  <LabelStepVideo
                    key={doc.docTitle}
                    videoText={doc.docTitle}
                    number={module.videos.length + 1 + index}
                    icon={'material-symbols:download-rounded'}
                  />
                </Link>
              ))}

            {module.externalUrls.length > 0 &&
              module.externalUrls.map((externalUrl, index) => (
                <Link
                  key={externalUrl.title}
                  href={externalUrl.url}
                  target="_blank"
                  rel="noopener,noreferrer"
                  display="flex"
                  textDecoration={'none !important'}
                >
                  <LabelStepVideo
                    key={externalUrl.url}
                    videoText={externalUrl.title}
                    number={
                      module.videos.length + module.docs.length + 1 + index
                    }
                    icon={'prime:arrow-up-right'}
                  />
                </Link>
              ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
