import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control'
import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { FieldRadioButton, ButtonPropsElement } from './FieldCard'

type RadioControllerProps<T extends object> = {
  name: string
  label?: string
  error?: FieldError
  control: Control<any>
  defaultValue?: any
} & ButtonPropsElement<T>

export function RadioGroupController(props: RadioControllerProps<any>) {
  const { control, defaultValue, name, error, label, ...rest } = props

  return (
    <FormControl w="auto" isInvalid={!!error}>
      {label && (
        <FormLabel
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          lineHeight={'20px'}
          color="#F5F5FA"
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <FieldRadioButton {...rest} {...field} />}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
