import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

export interface PayloadCreateRegistry {
  trackId: number
  fieldId: number
  themeIds: number[]
  subjectIds?: number[]
  isTheoretical: boolean
  studyTimeInMinutes: number
}

interface Params {
  id: number
  payload: PayloadCreateRegistry
}

async function RegistryRecoveryPlan(params: Params) {
  await api.post(`/app/recovery_plan/${params.id}/pending_studies`, {
    ...params.payload,
  })
}

export function useMutationRegistryNewRecoveryPlan() {
  return useMutation(RegistryRecoveryPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-recovery-plan-studies'])
    },
  })
}
