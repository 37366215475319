import React from 'react'
import { Grid, GridItem, Progress, Text, Tooltip } from '@chakra-ui/react'

interface FieldProgressProps {
  fieldName: string
  progress: number
}

export const FieldProgress = ({ fieldName, progress }: FieldProgressProps) => {
  return (
    <Grid
      templateColumns="repeat(4,1fr)"
      gap={'8px'}
      w="100%"
      maxW="600px"
      alignItems="center"
    >
      <GridItem placeContent={'start'}>
        <Tooltip label={fieldName}>
          <Text
            noOfLines={1}
            fontWeight={'500'}
            fontSize={{ base: '12px', sm: '14px' }}
            textAlign={'start'}
          >
            {fieldName}
          </Text>
        </Tooltip>
      </GridItem>

      <GridItem colSpan={2} alignContent={'center'} justifyContent={'center'}>
        <Progress
          colorScheme={
            progress >= 70 ? 'greenProgressBar' : 'yellowProgressBar'
          }
          value={progress || 0}
          max={100}
          h="5px"
          w="100%"
          // maxW="200px"
          borderRadius={'12px'}
          bg={'#2A2A30'}
          placeSelf={'flex-start'}
        />
      </GridItem>

      <GridItem textAlign="center">
        <Text fontWeight={'400'} fontSize={{ base: '12px', sm: '14px' }}>
          {progress || 0}%
        </Text>
      </GridItem>
    </Grid>
  )
}
