import { useQuery } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { formatMeta } from 'helpers/formatMeta'
import { kebabCase } from 'lodash'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

interface GetAgendaParams {
  year: number
  limit: number
  month: number
}

export interface GetAgenda {
  id: number
  end: Date
  description?: string
  start: Date
  font: string
  type: string
  title: string
  notes?: string
  themes: Theme[] | null
  isDone: boolean
  theme_id: number
  url_link: string
  metadata: Metadata
  subject_id: number
  subjects: Subject[]
  relatedThemes: any[]
  pending_study_id: number | null
  studyTimeInMinutes?: number
  hostUser?: {
    id: number
    name: string
    email: string
    canAccessSeren: boolean
  }
  recorded_meeting: boolean
}

interface GetAgendaResponseApi {
  id: number
  user_id: number
  starts_at: string
  finishes_at: string
  title: string
  created_at: string
  updated_at: string
  daily_record_id?: number
  pending_study_id: number | null
  notes?: string
  is_done: boolean
  theme_id: number
  subject_id: number
  type?: string
  font: string
  is_deleted: boolean
  previous_agenda_id?: number
  metadata: Metadata
  themes: Theme[]
  subjects: Subject[]
  themeX?: string
  relatedThemes?: any[]
  studyTimeInMinutes?: number
  description: string
  hostUser?: {
    id: number
    name: string
    email: string
    canAccessSeren: boolean
  }
  recorded_meeting: boolean
  url_link: string
}

interface Metadata {
  theme: string
  field: string
  subject?: string
  revisionNumber: string
  themeProjectX?: string
}

interface Theme {
  id: number
  name: string
  field_id: string
  created_at: string
  updated_at: string
  medcel_exam: string
}

interface Subject {
  id: number
  name: string
  fieldId: number
  createdAt: string
  updatedAt: string
  is_already_done: boolean
}

interface GetAgendaResponse {
  agendas: GetAgenda[]
  meta: MetaResponse
}

async function getAgendas(params: GetAgendaParams): Promise<GetAgendaResponse> {
  const { data: agendas } = await api.get<{
    data: GetAgendaResponseApi[]
    meta: MetaResponseApi
  }>('/app/agendas', { params })

  const output: GetAgenda[] = agendas.data.map(agenda => ({
    id: agenda.id,
    title: agenda.title,
    notes: agenda.notes,
    themes: agenda.themes,
    isDone: agenda.is_done,
    theme_id: agenda.theme_id,
    metadata: agenda?.metadata,
    subjects: agenda?.subjects,
    font: kebabCase(agenda.font),
    type: kebabCase(agenda.type),
    subject_id: agenda.subject_id,
    end: parseISO(agenda.finishes_at),
    start: parseISO(agenda.starts_at),
    relatedThemes: agenda.relatedThemes || [],
    pending_study_id: agenda.pending_study_id,
    studyTimeInMinutes: agenda.studyTimeInMinutes,
    //event feat
    description: agenda?.description ?? undefined,
    hostUser: agenda?.hostUser ?? undefined,
    recorded_meeting: agenda?.recorded_meeting ?? undefined,
    url_link: agenda?.url_link ?? undefined,
  }))

  return {
    agendas: output,
    meta: formatMeta(agendas.meta),
  }
}

export function useGetAgendas(params: GetAgendaParams) {
  return useQuery(['agendas', 'list', params], () => getAgendas(params), {
    refetchOnWindowFocus: false,
  })
}
