import React, { useCallback, useState } from 'react'
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import Swal from 'sweetalert2'
import { ArrowRight, Power } from 'react-feather'
import { LogOutButton } from 'components/Header/styles'
import Info from '../components/Info'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Modules } from '../components/Modules'
// Contexts
import { useAuth } from 'contexts/auth'
import ModalFeedback from '../components/ModalFeedback'

interface Version2Props {
  currentStep: number
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export function Version2(props: Version2Props) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const [activeVideo, setActiveVideo] = useState(
    'https://www.youtube.com/embed/Z_7qBHtfVls?si=mG6h9VPUCiqP3bzn',
  )

  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })
  const { setStep } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { logOut } = useAuth()
  const getVideos = (videos: string) => {
    setActiveVideo(videos)
  }

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      html:
        '<p style="text-align:center">Você realmente deseja fazer logout?</p>',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box mx={{ base: '16px', md: '64px', xl: '84px' }} pb={'24px'}>
      <Flex
        w="full"
        mb="16px"
        justifyContent={'end'}
        pr={{ base: '16px', md: '0px' }}
      >
        <LogOutButton onClick={confirmLogOut}>
          <Power size={20} strokeWidth={1} />
        </LogOutButton>
      </Flex>
      <Info currentStep={props.currentStep} />

      <Flex mt={{ base: '44px', sm: '84px' }} mb={{ base: '12px', md: '24px' }}>
        <Heading
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ base: '18px', sm: '32px' }}
          lineHeight={{ base: '23px', sm: '40px' }}
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Minha Versão 2.0
        </Heading>
      </Flex>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '0px', sm: '34px' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '516px' }}
          h={{ base: 'fit-content', sm: '100%' }}
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            <VStack flex={'1 1 auto'}>
              <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                <iframe
                  src={activeVideo}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="100%"
                  width="100%"
                  style={
                    !isMobile
                      ? {
                          borderRadius: '8px',
                          border: '1px solid #e56000',
                        }
                      : {}
                  }
                />
              </AspectRatio>
            </VStack>
          </Flex>
        </Flex>

        <Flex
          maxW={{ md: 'full', lg: '368px' }}
          maxH={'504px'}
          justifyContent={'space-between'}
          minW="176px"
          w="100%"
          p={'24px'}
          pr="16px"
          flexDir={'column'}
          gap="23px"
          background={isMobile ? 'inherit' : '#302F37'}
          borderRadius="8px"
          m="0px !important"
        >
          <Flex
            flexDir={'column'}
            gap="22px"
            overflowY={'auto'}
            pr="12px"
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#E56000',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
          >
            <Modules activeVideo={activeVideo} getVideos={getVideos} />
            <Flex h="full">
              <DefaultButton
                mt="auto"
                label="Deixe seu Feedback para o Minha Versão 2.0"
                size="md"
                variant="gradient"
                whiteSpace={'normal'}
                fontWeight="700"
                fontSize={{ base: '12px', md: '14px' }}
                w="100%"
                onClick={() => setIsFeedbackModalOpen(true)}
              />
            </Flex>
          </Flex>

          <Flex
            h="fit-content"
            gap="1rem"
            flexDir={{ base: 'column', sm: 'row' }}
          >
            <DefaultButton
              mt="auto"
              label="Voltar"
              size="md"
              variant="ghost"
              w="100%"
              onClick={() => setStep(0)}
            />

            <DefaultButton
              mt="auto"
              label="Conceito Matador"
              size="md"
              variant="gradient"
              w="100%"
              onClick={() => setStep(2)}
            />
          </Flex>
        </Flex>
      </Flex>
      <ModalFeedback
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </Box>
  )
}
