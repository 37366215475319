import styled from 'styled-components'

export const FormLabel = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 24px;
  margin-bottom: 12px;

  > span {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  padding-left: 20px;

  button {
    margin-top: 16px;
  }
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 20px;
  padding-top: 16px;

  button {
    margin-top: 16px;
  }
`

export const AvatarImage = styled.img`
  display: flex;
  border-radius: 24px;

  object-fit: cover;
  object-position: center;
`
