import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function updateDeleted({
  id,
  isDeleted,
}: {
  id: number
  isDeleted: boolean
}) {
  return await api.patch(`/app/agendas/${id}/retrieve`, { isDeleted })
}

export function usePatchAgenda() {
  return useMutation(updateDeleted, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'deleted'])
    },
  })
}
