import React from 'react'
import { Circle, VStack, Text, Flex, FlexProps } from '@chakra-ui/react'

interface SingleStepProps {
  title: string
  description: string
  number: number
  lastStep: boolean
}

export type StepProps = Omit<SingleStepProps, 'number' | 'lastStep'>

interface StepsProps extends FlexProps {
  data: StepProps[]
}

const SingleStep = ({
  description,
  title,
  number,
  lastStep,
}: SingleStepProps) => {
  return (
    <Flex gap={'10px'} h="max-content" align={'flex-start'}>
      <VStack my="10px" align="center" h="100%" spacing={'0'}>
        <Circle
          background={'linear-gradient(90deg, #FF8A00 -38.46%, #FA5B01 100%);'}
          size={'2rem'}
        >
          {number}
        </Circle>

        <Flex
          display={lastStep ? 'none' : 'flex'}
          flexGrow={1}
          w={'2px'}
          background={'#FF8A00'}
        />
      </VStack>

      <VStack my="10px" align={'flex-start'} spacing={'3px'}>
        <Text
          fontFamily="Mulish"
          fontWeight="700"
          fontSize="1rem"
          color="#E56000"
        >
          {title}
        </Text>

        <Text>{description}</Text>
      </VStack>
    </Flex>
  )
}

export const Steps = (props: StepsProps) => {
  const { data, ...rest } = props

  return (
    <Flex flexDir={'column'} {...rest}>
      {data.map((item, index) => {
        return (
          <SingleStep
            key={index}
            title={item.title}
            number={index + 1}
            description={item.description}
            lastStep={index === data.length - 1}
          />
        )
      })}
    </Flex>
  )
}
