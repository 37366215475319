import React from 'react'
import { Flex } from '@chakra-ui/react'
import Layout from 'layouts/UnloggedOnboarding'
import { Version2 } from './MyVersion2/Version2'
import { SpecialtyDiagnostic } from './SpecialtyDiagnostic/SpecialtyDiagnostic'
import { useStep } from 'components/Step/useStep'
import { KillerConceptStep } from './KillerConceptsStep'

export default function Template() {
  /*
  |-----------------------------------------------------------------------------
  | Consts.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const numberOfSteps = 3

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [currentStep, { setStep }] = useStep({
    maxStep: numberOfSteps,
    initialStep: 0,
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Flex w="100%" h="full" mt="50px" flexDir={'column'}>
        {currentStep === 0 && (
          <SpecialtyDiagnostic currentStep={currentStep} setStep={setStep} />
        )}

        {currentStep === 1 && (
          <Version2 currentStep={currentStep} setStep={setStep} />
        )}

        {currentStep === 2 && (
          <KillerConceptStep currentStep={currentStep} setStep={setStep} />
        )}
      </Flex>
    </Layout>
  )
}
