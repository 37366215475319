import { get, uniqueId } from 'lodash'
import React, { useCallback } from 'react'

// Styles
import {
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

// Features
import { ListEmpty, ListLoading } from 'features/ui'
import compose from 'helpers/compose'
import { withEmptyFeedback } from 'hocs/withEmptyFeedback'
import { withLoadingFeedback } from 'hocs/withLoadingFeedback'

export type TableHeader<T extends Object> = {
  label: string | React.ReactNode
  accessor: keyof T
  fn?: (value: any, data: T) => any
}

interface Props<T extends Object> extends TableProps {
  data?: T[]
  isLoading?: boolean
  headers: TableHeader<T>[]
  tableCellProps?: TableCellProps
  tableCellPropsHeader?: TableColumnHeaderProps
}

function BaseListTable<T extends Object>({
  headers,
  data,
  isLoading,
  tableCellProps,
  tableCellPropsHeader,
  ...rest
}: Props<T>) {
  const getCellValue = useCallback(
    (row: T, accessor: keyof T, fn?: (value: any, data: T) => any) => {
      const value = get(row, accessor)

      return fn ? fn(value, row) : value
    },
    [],
  )

  return (
    <Table colorScheme="whiteAlpha" {...rest}>
      <Thead>
        <Tr>
          {headers.map(({ label }) => (
            <Th key={`headers-table-${uniqueId()}`} {...tableCellPropsHeader}>
              {label}
            </Th>
          ))}
        </Tr>
      </Thead>

      <Tbody>
        {isLoading && <ListLoading columns={headers.length} />}

        {!isLoading &&
          data?.map((row, index) => (
            <Tr key={get(row, 'id', index)}>
              {headers?.map(({ accessor, fn }) => (
                <Td {...tableCellProps} key={String(accessor)}>
                  {getCellValue(row, accessor, fn)}
                </Td>
              ))}
            </Tr>
          ))}

        {!isLoading && data?.length === 0 && (
          <ListEmpty columns={headers.length} />
        )}
      </Tbody>
    </Table>
  )
}

const ListTable = compose(withEmptyFeedback, withLoadingFeedback)(BaseListTable)

export { ListTable }
