import React, { useCallback, useState } from 'react'
import { get } from 'lodash'

// Components
import Header from 'components/Header'

// Styles
import Layout from 'layouts/Logged'
import { Container } from './styles'

// Hooks
import { useAuth } from 'contexts/auth'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  Circle,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ModalSmartReview } from 'features/agendas/modal-smart-review'
import {
  GetAgenda,
  useGetAgendas,
} from 'features/agendas/queries/use-get-agendas'
import { useUpdateScheduleAgenda } from 'features/agendas/mutations/use-update-schedule-agenda'
import { useGenerateFocusLearning } from 'features/agendas/mutations/use-generate-focus-learning'
import { Calendar } from 'components/Calendar'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { ModalDefault } from 'components/Modal/ModalDefault'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { formatAgendaTooltip } from 'helpers/formatAgendaTooltip'
import { HiOutlineBookOpen, HiOutlineDocumentText } from 'react-icons/hi'
import ModalRecords from 'features/dashboard/Records'
import { useTrack } from 'contexts/track'

const Dashboard: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user, isFocusLearningActivated } = useAuth()
  const showToast = useToastMessage()
  const disclosureSmartReview = useDisclosure()
  const updateScheduleAgenda = useUpdateScheduleAgenda()
  const generateFocusLearning = useGenerateFocusLearning()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [selectedAgenda, setSelectedAgenda] = useState<GetAgenda | undefined>(
    undefined,
  )
  const [modalLimitProjectX, setModalLimitProjectX] = useState(false)
  const [
    errorAPIGenerateProjectXStudies,
    setErrorAPIGenerateProjectXStudies,
  ] = useState('')
  const [modalRecordTest, setModalRecordTest] = useState(false)
  const [modalRecordActiveIndex, setModalRecordActiveIndex] = useState(0)
  const [viewDate, setViewDate] = useState(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getAgendas = useGetAgendas({
    limit: 2000,
    year: viewDate.getFullYear(),
    month: viewDate.getMonth() + 1,
  })
  const { track } = useTrack()
  const { userContract } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleUpdateScheduleAgenda = useCallback(
    async (onEventDropParams: {
      end: Date
      start: Date
      allDay: boolean
      event: GetAgenda
    }) => {
      const { event, start, end } = onEventDropParams

      try {
        await updateScheduleAgenda.mutateAsync({
          query: { agendaId: event.id },
          data: {
            finishesAt: end,
            startAt: start,
          },
        })

        showToast({
          title: 'Sucesso',
          description: 'Estudo reagendado.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possívevel reagendar o registro.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [showToast, updateScheduleAgenda],
  )

  const alertCalendarEvent = useCallback(
    (event: GetAgenda) => {
      if (!event) return

      setSelectedAgenda(event)
      disclosureSmartReview.onOpen()
    },
    [disclosureSmartReview],
  )

  const handleGenerateFocusLearning = useCallback(async () => {
    try {
      await generateFocusLearning.mutateAsync()
    } catch (error) {
      let errorMessage =
        'Não foi possível gerar as suas revisões. Por favor, tente novamente mais tarde'

      const apiErrorMessage = get(error, 'response.data')

      if (typeof apiErrorMessage === 'string') errorMessage = apiErrorMessage

      setErrorAPIGenerateProjectXStudies(errorMessage)
      setModalLimitProjectX(true)
    }
  }, [generateFocusLearning])

  const handleNavigate = useCallback((newDate: Date) => {
    setViewDate(newDate)
  }, [])

  const eventPropGetter = useCallback(
    (event: any) => {
      // is done
      if (event?.isDone) {
        return {
          className: 'is-done',
        }
      }

      // trilha de simulado
      if (
        event?.themes?.[0]?.medcel_exam &&
        event?.font === 'estudo-diario' &&
        user?.email === 'joaoarthur66@gmail.com'
      ) {
        return {
          className: 'is-pending-study',
        }
      }

      if (event?.themes?.[0]?.medcel_exam && event?.font === 'estudo-diario') {
        return {
          className: 'is-medcel',
        }
      }

      // evento
      if (event?.type === 'evento') {
        return {
          className: 'is-event',
        }
      }

      // primeiro contato
      if (event?.font === 'estudo-pendente') {
        return {
          className: 'is-pending-study',
        }
      }

      // project-x
      if (event?.font === 'projeto-x' && event?.type === 'primeiro-contato') {
        return {
          className: 'project-x-first-contact',
        }
      }

      if (
        event?.font === 'projeto-x' &&
        user?.email === 'joaoarthur66@gmail.com'
      ) {
        return {
          className: 'is-pending-study',
        }
      }

      if (event?.font === 'projeto-x') {
        return {
          className: 'is-project-x',
        }
      }
    },
    [user?.email],
  )

  const eventTitleFormat = useCallback(({ event }: { event: GetAgenda }) => {
    const agenda = event

    if (!agenda) return null

    const { metadata, font, type, title } = event

    const revisionNumber = get(agenda.metadata, 'revisionNumber', '')
    const parserRevisionNumber =
      revisionNumber !== '' ? `${revisionNumber}` : ''

    let HeaderTitle = <></>

    if (type === 'evento') {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >
          {title}
        </Text>
      )
    }

    if (agenda.font === 'estudo-diario' && !!agenda?.themes?.[0]?.medcel_exam) {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >
          {`C-TS - ${
            parserRevisionNumber ? ` ${parserRevisionNumber}` : ''
          } | ${event?.themes?.[0]?.medcel_exam.toLocaleUpperCase()}`}
        </Text>
      )
    }

    if (metadata && type === 'primeiro-contato' && font === 'estudo-pendente') {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >{`PC ${Number(revisionNumber) >= 1 ? ` - ${revisionNumber}` : ''} | ${
          metadata.field
        }`}</Text>
      )
    }

    if (
      metadata &&
      type === 'primeiro-contato' &&
      font === 'estudo-diario' &&
      !agenda?.themes?.[0]?.medcel_exam
    ) {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >{`RI - ${parserRevisionNumber} | ${metadata.field}`}</Text>
      )
    }

    if (metadata && type === 'estudo-teorico') {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >{`ET - ${parserRevisionNumber} | ${metadata.field}`}</Text>
      )
    }

    if (metadata && font === 'projeto-x') {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >{`RX - ${parserRevisionNumber} | ${metadata.field}`}</Text>
      )
    }

    if (
      metadata &&
      agenda.font === 'projeto-x' &&
      agenda.type === 'primeiro-contato'
    ) {
      HeaderTitle = (
        <Text
          fontSize={'12px'}
          fontWeight={500}
          lineHeight={'16px'}
          fontFamily={'Mulish'}
          textOverflow="ellipsis"
          whiteSpace={'nowrap'}
          maxW={'142px'}
          w="100%"
          overflow={'hidden'}
        >{`PCX | ${metadata.field}`}</Text>
      )
    }

    return HeaderTitle
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Calendário MR"
          subTitle="O seu guia customizado de estudos"
        />

        <ModalSmartReview
          agenda={selectedAgenda}
          isOpen={disclosureSmartReview.isOpen}
          onClose={disclosureSmartReview.onClose}
          backOnClickFunc={disclosureSmartReview.onOpen}
        />

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          spacing={2}
          alignItems={{ sm: 'flex-end' }}
          mt={{ base: '1rem', lg: '12px' }}
        >
          {isFocusLearningActivated && user?.profile?.trial_year === 2024 && (
            <DefaultButton
              label="Gerar revisões X"
              variant="primary"
              leftIcon={
                <Icon
                  fontSize={'19px'}
                  color="#E56000"
                  icon={'eva:layers-outline'}
                />
              }
              iconSpacing="8px"
              loadingText="Aguarde ..."
              onClick={handleGenerateFocusLearning}
              isLoading={generateFocusLearning.isLoading}
            />
          )}

          <DefaultButton
            label="Diário"
            maxW="full"
            h="100%"
            fontWeight="500"
            leftIcon={<HiOutlineBookOpen />}
            onClick={() => {
              setModalRecordActiveIndex(0)
              setModalRecordTest(true)
            }}
          />

          <DefaultButton
            label="Provas e Simulados"
            maxW="full"
            h="100%"
            fontWeight="500"
            leftIcon={<HiOutlineDocumentText />}
            onClick={() => {
              setModalRecordActiveIndex(1)
              setModalRecordTest(true)
            }}
          />
        </Stack>

        <ModalRecords
          isOpen={modalRecordTest}
          onClose={() => setModalRecordTest(false)}
          activeIndex={modalRecordActiveIndex}
          setActiveIndex={setModalRecordActiveIndex}
        />

        {/* {!user?.is_revision_reset_executed && (
            <DefaultButton
            label="Resetar revisões"
              leftIcon={
                <Icon
                  fontSize={'16px'}
                  color="#E56000"
                  icon={'eva:refresh-fill'}
                />
              }
              iconSpacing="8px"
              onClick={() => setModalRevision(true)}
            />
          )} */}

        <Flex
          gap={{ base: '0.5em', md: '1.5rem' }}
          mb="1rem"
          mt="40px"
          flexDir={{ base: 'column', sm: 'row' }}
        >
          <HStack>
            <Circle size="10px" bg="#E56000" />
            <Text fontWeight={400} color="#D3D3D8" fontSize={'12px'}>
              Revisões Inteligentes
            </Text>
          </HStack>

          {user?.focus_learning_activated_at && (
            <HStack>
              <Circle
                size="10px"
                bg={
                  user?.email === 'joaoarthur66@gmail.com'
                    ? '#E296FD'
                    : '#E5C009'
                }
              />
              <Text fontWeight={400} color="#D3D3D8" fontSize={'12px'}>
                Revisões X
              </Text>
            </HStack>
          )}

          {(track?.name === 'R1' ||
            track?.name === 'R+ CGE' ||
            track?.name === 'R+ CM') &&
            userContract?.type === 'PURCHASE' && (
              <HStack>
                <Circle
                  size="10px"
                  bg={
                    user?.email === 'joaoarthur66@gmail.com'
                      ? '#E296FD'
                      : '#E5C009'
                  }
                />
                <Text fontWeight={400} color="#D3D3D8" fontSize={'12px'}>
                  Trilha de Simulados
                </Text>
              </HStack>
            )}

          <HStack>
            <Circle size="10px" bg="#E296FD" />
            <Text fontWeight={400} color="#D3D3D8" fontSize={'12px'}>
              Primeiro Contato
            </Text>
          </HStack>

          <HStack>
            <Circle size="10px" bg="#0DDF15" />
            <Text fontWeight={400} color="#D3D3D8" fontSize={'12px'}>
              Estudos concluídos
            </Text>
          </HStack>
          <HStack>
            <Circle size="10px" bg="#2985F0" />
            <Text fontWeight={400} color="#D3D3D8" fontSize={'12px'}>
              Eventos
            </Text>
          </HStack>
        </Flex>

        <Calendar
          onNavigate={handleNavigate}
          eventPropGetter={eventPropGetter}
          onSelectEvent={alertCalendarEvent}
          events={getAgendas.data?.agendas || []}
          onEventDrop={handleUpdateScheduleAgenda}
          eventTitleFormat={eventTitleFormat}
          tooltipAccessor={(agenda: GetAgenda) => {
            return formatAgendaTooltip(agenda)
          }}
          draggableAccessor={(agenda: GetAgenda) => {
            if (agenda.type === 'evento') {
              return false
            }
            return true
          }}
        />

        {/* <ModalRevisionReset
          isOpen={modalRevision}
          onClose={() => setModalRevision(false)}
          onCloseIcon={() => setModalRevision(false)}
        /> */}

        <ModalDefault
          isOpen={modalLimitProjectX}
          size="sm"
          onClose={() => setModalLimitProjectX(false)}
          title={
            errorAPIGenerateProjectXStudies ===
            'Sua agenda para hoje está na capacidade máxima. Bons estudos e volte amanhã.'
              ? 'Limite de estudos X atingido'
              : 'Atenção'
          }
          subTitle={
            errorAPIGenerateProjectXStudies ===
            'Sua agenda para hoje está na capacidade máxima. Bons estudos e volte amanhã.'
              ? 'Você pode realizar até 15 estudos pendentes por dia. Caso queira adicionar mais, lembre-se de concluir ou excluir os estudos atuais para liberar espaço.'
              : errorAPIGenerateProjectXStudies
          }
          onConfirmClick={() => setModalLimitProjectX(false)}
        />
      </Container>

      <ModalDefault
        isOpen={modalLimitProjectX}
        size="sm"
        onClose={() => setModalLimitProjectX(false)}
        title={
          errorAPIGenerateProjectXStudies ===
          'Sua agenda para hoje está na capacidade máxima. Bons estudos e volte amanhã.'
            ? 'Limite de estudos X atingido'
            : 'Atenção'
        }
        subTitle={
          errorAPIGenerateProjectXStudies ===
          'Sua agenda para hoje está na capacidade máxima. Bons estudos e volte amanhã.'
            ? 'Você pode realizar até 15 estudos pendentes por dia. Caso queira adicionar mais, lembre-se de concluir ou excluir os estudos atuais para liberar espaço.'
            : errorAPIGenerateProjectXStudies
        }
        onConfirmClick={() => setModalLimitProjectX(false)}
      />
    </Layout>
  )
}

export default Dashboard
