import React, { createContext, useContext, useEffect, useState } from 'react'

// Contexts
import { useAuth } from 'contexts/auth'

// Types
import { Track, TrackProviderValue } from './interfaces'

// Services
import api from 'services/api'

const TrackContext = createContext({} as TrackProviderValue)

export const TrackContextProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { userContract } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [track, setTrack] = useState<Track>()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!userContract) return
    api
      .get<Track>(`/app/tracks/${userContract.track_id}`)
      .then(({ data }) => {
        setTrack(data)
      })
      .catch(err => {
        console.error('Error on fetching track: ', err)
      })
  }, [userContract])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <TrackContext.Provider value={{ track }}>{children}</TrackContext.Provider>
  )
}

export function useTrack() {
  const context = useContext(TrackContext)
  return context
}
