import { useMutation } from '@tanstack/react-query'
import { Test } from 'features/dashboard/type'
import { queryClient } from 'index'
import api from 'services/api'

interface TestApi {
  id: number
  year: number
  date: string
  score: number
  user_id: number
  isDeleted: boolean
  studiedMinutes: number
  institutionName: string
  effortPerception: number
  numberOfQuestions: number
  numberOfCorrectAnswers: number
}

const updateTest = (test: TestApi) => {
  return api.put<Test>(`/app/tests/${test.id}`, test)
}

export function useEditTest() {
  return useMutation(updateTest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardTests'])
      queryClient.invalidateQueries(['get-dashboard-info'])
    },
  })
}
