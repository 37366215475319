import React, { useCallback } from 'react'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'
import { RecoveryPlanInactivated } from './RecoveryPlanInactivated'
import { RecoveryPlanActivated } from './RecoveryPlanActivated'
import { IsLoadingPage } from './IsLoadingPage'
import { isBefore } from 'date-fns'
import { formatDateAddTimeZoned } from 'helpers/formatTimer'

export const RecoveryPlan: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hook
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { data, isLoading } = useGetPrInfo()

  /*
  |-----------------------------------------------------------------------------
  | Callback
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isBeforePlan = useCallback(() => {
    if (data?.pendingStudiesData?.finishes_at) {
      const date = formatDateAddTimeZoned(data?.pendingStudiesData?.finishes_at)
      const currentDate = new Date()
      if (isBefore(currentDate, date)) {
        return true
      }

      return false
    }
  }, [data?.pendingStudiesData?.finishes_at])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (isLoading) {
    return <IsLoadingPage />
  }

  if (
    data?.pendingStudiesData.status === 'ACTIVE' &&
    !isLoading &&
    isBeforePlan()
  ) {
    return <RecoveryPlanActivated />
  }

  return <RecoveryPlanInactivated />
}
