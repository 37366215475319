import React from 'react'
import {
  Circle,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { IoMdNotifications } from 'react-icons/io'
import NotificationsSwipper from './Notification'
import { useGetNotifications } from 'features/notifications/mutations/use-get-notifications'

interface PopovoverNotificationsProps {
  unviewedNotificationCount: number
  popoverContentProps?: PopoverContentProps
}

export default function PopovoverNotifications({
  unviewedNotificationCount,
  popoverContentProps,
}: PopovoverNotificationsProps) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    data: notificationData,
    isLoading: isNotificationLoading,
    refetch,
  } = useGetNotifications()

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      sx={{
        '& .chakra-popover__popper': {
          'min-width': 'auto !important',
        },
      }}
    >
      <Popover
        boundary={'clippingParents'}
        placement={'auto'}
        isLazy
        flip
        preventOverflow
        strategy="fixed"
      >
        <PopoverTrigger>
          <button style={{ position: 'relative' }} onClick={() => refetch()}>
            <IoMdNotifications size={'24px'} />
            {unviewedNotificationCount > 0 && (
              <Circle
                position={'absolute'}
                top={0}
                right={0}
                bg={'#E56000'}
                size={'11px'}
              >
                <Text fontSize={'8px'}>{unviewedNotificationCount}</Text>
              </Circle>
            )}
          </button>
        </PopoverTrigger>

        <PopoverContent
          _focus={{ boxShadow: 'none' }}
          mr={{ base: '0px', sm: '10px' }}
          ml={{ base: '10px', sm: '0px' }}
          bg="#302F37"
          minH="160px"
          border="1px solid #E56000"
          borderColor={'#E56000'}
          {...popoverContentProps}
        >
          <PopoverCloseButton
            position={'absolute'}
            zIndex={'999'}
            top={'10px'}
            right={'5px'}
          />

          <PopoverBody p="0px">
            {isNotificationLoading ? (
              <Flex
                justifyContent={'center'}
                w="full"
                minH="150px"
                h="full"
                alignItems={'center'}
              >
                <Spinner />
              </Flex>
            ) : (
              <NotificationsSwipper data={notificationData?.data || []} />
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}
