import { yupResolver } from '@hookform/resolvers/yup'
import { INSTITUTIONS } from 'constants/institutions'
import { PREP_SCHOOLS } from 'constants/prepSchools'
import { SPECIALTIES } from 'constants/specialties'
import { useAuth } from 'contexts/auth'
import { camelCaseKeys } from 'helpers/camelCaseKeys'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { ProfileSchema } from './schema'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Flex } from '@chakra-ui/react'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { SelectDragNDropController } from 'components/Fields/SelectDragNDrop'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { useResetProjectX } from 'features/project-x/queries/use-mutation-reset-px'
import {
  UpdateProfileParams,
  useUpdateProfile,
} from './mutations/mutate-profile'
import { getYear } from 'date-fns'
import { useTrack } from 'contexts/track'

export interface ProfileApiResponse {
  profile: {
    id: number
    prep_school: string[]
    attended_college: string
    intended_specialties: string[]
    intended_institutions: string[]
    is_already_our_student: string[]
    trial_year: number
  }
}

interface FormProfile {
  trial_year: {
    label: string
    value: number
  }
  intended_specialties: Array<{
    label: string
    value: string
  }>
  intended_institutions: string[]
  prep_school: {
    label: string
    value: string
  }
}

interface FormProfileOutput {
  trial_year: number
  intended_specialties: string[]
  intended_institutions: string[]
  prep_school: string[]
}

export const ProfileTab = () => {
  /*
  |-----------------------------------------------------------------------------
  | Const
  |-----------------------------------------------------------------------------
  |
  |
  */

  const currentYear = getYear(new Date())

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isModalProjectXOpen, setIsModalProjectXOpen] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hook.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control: controlProfile,
    handleSubmit: handleSubmitProfile,
    setValue: setValueProfile,
    getValues: getValuesProfile,
    formState: { errors: profileErrors, isSubmitting: isSubmittingProfile },
  } = useForm<FormProfile>({
    resolver: yupResolver(ProfileSchema),
  })

  const showToast = useToastMessage()

  const { track } = useTrack()

  const resetProjectXMutation = useResetProjectX()

  const updateProfileMutation = useUpdateProfile()

  const { user, isFocusLearningActivated } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | UseCallBack.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleResetProfile = useCallback(
    async (data: UpdateProfileParams) => {
      if (!user?.profile?.id) return

      await updateProfileMutation.mutateAsync({
        id: user?.profile?.id,
        data: data,
      })
    },
    [updateProfileMutation, user?.profile?.id],
  )

  const profileSubmitHandler = useCallback(
    async (formData: FormProfileOutput) => {
      const parsedKeysFormData = camelCaseKeys(formData) as UpdateProfileParams

      if (
        formData?.prep_school?.[0] !== user?.profile?.prep_school?.[0] &&
        isFocusLearningActivated
      ) {
        return setIsModalProjectXOpen(true)
      }

      try {
        await handleResetProfile(parsedKeysFormData as UpdateProfileParams)
        showToast({
          title: 'Sucesso',
          description: 'Perfil atualizado.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível atualizar o perfil.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [
      handleResetProfile,
      isFocusLearningActivated,
      showToast,
      user?.profile?.prep_school,
    ],
  )

  const handleResetProjectXAndProfile = useCallback(async () => {
    try {
      await resetProjectXMutation.mutateAsync()

      const profileSchema = await ProfileSchema.validate(getValuesProfile())

      handleResetProfile(camelCaseKeys(profileSchema) as UpdateProfileParams)

      showToast({
        title: 'Sucesso',
        description: 'Projeto-X Resetado e perfil salvo com sucesso',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível resetar o projeto-x e salvar o perfil.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    } finally {
      setIsModalProjectXOpen(false)
    }
  }, [getValuesProfile, handleResetProfile, resetProjectXMutation, showToast])

  const patternTrialYear = useCallback(
    (year: number) => {
      const yearPatter: Record<number, string> = {
        [currentYear]: `Presto prova final de ${currentYear} - R1/R+ em ${
          currentYear + 1
        }`,
        [currentYear + 1]: `Presto prova final de ${
          currentYear + 1
        } - R1/R+ em ${currentYear + 2}`,
        [currentYear + 2]: `Presto prova final de ${
          currentYear + 2
        } - R1/R+ em ${currentYear + 3}`,
      }

      if (yearPatter[year]) {
        return yearPatter[year]
      }

      return year
    },
    [currentYear],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    /**
     * Fetch user profile data
     */
    api
      .get<ProfileApiResponse>('/app/profiles')
      .then(({ data }) => {
        setValueProfile(
          'intended_institutions',
          data?.profile?.intended_institutions,
        )

        setValueProfile(
          'intended_specialties',
          data?.profile?.intended_specialties?.map(is => ({
            label: is,
            value: is,
          })),
        )

        setValueProfile('prep_school', {
          label: data?.profile?.prep_school[0],
          value: data?.profile?.prep_school[0],
        })

        setValueProfile('trial_year', {
          label: String(patternTrialYear(data?.profile?.trial_year)),
          value: data?.profile?.trial_year,
        })
      })
      .catch(e => {
        showToast({
          title: 'Erro',
          description: 'Falha ao buscar dados de perfil',
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
        console.trace(e)
      })
  }, [patternTrialYear, setValueProfile, showToast])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const prepSchoolsFilter = useMemo(() => {
    if (track?.name === 'R+ CGE') {
      return PREP_SCHOOLS.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }

    return PREP_SCHOOLS.filter(
      item => item !== 'MEDCOF 2024' && item !== 'RMED 2024',
    ).map(item => {
      return {
        label: item,
        value: item,
      }
    })
  }, [track?.name])

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      flexDir="column"
      maxW={'380px'}
      w="full"
      gap={'24px'}
      pl="20px"
      onSubmit={handleSubmitProfile(profileSubmitHandler)}
    >
      <NewFieldSelectRegistryController
        control={controlProfile}
        label="Ano de prova"
        options={[
          {
            label: `Presto prova final de ${currentYear} - R1/R+ em ${
              currentYear + 1
            }`,
            value: currentYear,
          },
          {
            label: `Presto prova final de ${currentYear + 1} - R1/R+ em ${
              currentYear + 2
            }`,
            value: currentYear + 1,
          },
          {
            label: `Presto prova final de ${currentYear + 2} - R1/R+ em ${
              currentYear + 3
            }`,
            value: currentYear + 2,
          },
        ]}
        name="trial_year"
        placeholder="Escolha um ano de prova"
        error={profileErrors.trial_year as any}
      />

      <NewFieldSelectRegistryController
        isClearable
        label="Especialidades pretendidas"
        isMulti={true as any}
        options={SPECIALTIES.map(specialty => ({
          value: specialty,
          label: specialty,
        }))}
        placeholder="Selecione uma especialidade"
        name="intended_specialties"
        control={controlProfile}
        error={profileErrors.intended_specialties as any}
      />

      <SelectDragNDropController
        label="Instituições pretendidas"
        options={INSTITUTIONS.map(institution => ({
          value: institution,
          label: institution,
        }))}
        name="intended_institutions"
        control={controlProfile}
        error={profileErrors.intended_institutions as any}
      />

      <NewFieldSelectRegistryController
        label="Cursinho"
        options={prepSchoolsFilter}
        name="prep_school"
        placeholder="Selecione um cursinho"
        control={controlProfile}
        error={profileErrors.prep_school as any}
      />

      <ModalDefaultTwoButton
        isOpen={isModalProjectXOpen}
        onClose={() => setIsModalProjectXOpen(false)}
        title="Atenção!"
        subTitle="Ao mudar o cursinho enquanto o projeto-x está ativo, ocorrerá o reset dos dados do Projeto-X. Deseja continuar?"
        onConfirmClick={handleResetProjectXAndProfile}
      />

      <DefaultButton
        label={'Salvar'}
        isLoading={isSubmittingProfile}
        type="submit"
      />
    </Flex>
  )
}
