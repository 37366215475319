import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Params {
  id: number
}

async function deleteTest(params: Params) {
  return await api.delete(`/app/tests/${params.id}`)
}

export function useDeleteTest() {
  return useMutation(deleteTest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardTests'])
      queryClient.invalidateQueries(['get-dashboard-info'])
    },
  })
}
