import { HStack, IconButton, VStack, Text, Tooltip } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import Pagination from 'components/Pagination'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OptionTypeBase, ValueType } from 'react-select'
import Filter from './Filter'
import {
  KillerConcepts,
  useGetListKillerConcepts,
} from './mutations/use-get-list-killer-concepts'

export default function KillerConcept() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [areaFilter, setAreaFilter] = useState<
    ValueType<OptionTypeBase, false>
  >(undefined)
  const [page, setPage] = useState(1)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    data: dataKillerConcepts,
    isLoading: isLoadingKillerConcepts,
  } = useGetListKillerConcepts({
    limit: 10,
    page,
    fieldId: areaFilter?.value,
  })
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: <Text>Título do Material Complementar</Text>,
        accessor: 'title',
        fn(value) {
          return (
            <Tooltip maxW="487px" w="100%" label={value} hasArrow>
              <Text noOfLines={1} maxW="487px" w="100%">
                {value}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: (
          <HStack justifyContent="center">
            <Text>Download</Text>
          </HStack>
        ),
        accessor: 'id',
        fn(value) {
          return (
            <HStack justifyContent="center">
              <IconButton
                aria-label={'download pdf'}
                backgroundColor="#393940"
                borderRadius={'4px'}
                _hover={{
                  backgroundColor: '#393940c3',
                }}
                _active={{
                  backgroundColor: '#393940',
                }}
                w="24px"
                h="24px"
                minW={'24px !important'}
                onClick={async () => {
                  push(`/view-killer-concept/${value}`)
                }}
              >
                <Icon
                  fontSize="14px"
                  color="#E56000"
                  icon="ic:baseline-search"
                />
              </IconButton>
            </HStack>
          )
        },
      },
    ] as TableHeader<KillerConcepts>[]
  }, [push])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack align="flex-start" spacing={'20px'} py="2rem">
      {/* <Filter
        areaValue={areaFilter}
        onChangeAreaValue={e => {
          setPage(1)
          setAreaFilter(e)
        }}
      /> */}

      <List
        maxW="719px"
        w="100%"
        background="#302F37"
        px="20px"
        py="10px"
        my="0px"
      >
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={false}
          isFetching={false}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={dataKillerConcepts?.data}
          isLoading={isLoadingKillerConcepts}
          tableCellProps={{
            border: 'none !important',
            p: '8px',
            _notFirst: {
              paddingLeft: '1.5rem',
            },
            borderRight: '1px solid rgba(255, 255, 255, 0.06) !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '16px',
            textTransform: 'none !important',
            p: '8px',
            _notFirst: { paddingLeft: '1.5rem' },
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={dataKillerConcepts?.meta?.total ?? 0}
          registersPerPage={10}
          fontSize="12px"
          mt="16px !important"
          px="8px"
          buttonProps={{
            fontSize: '12px',
            fontWeight: '600',
            minH: '6',
            h: '6',
            w: '6',
            minW: '6',
          }}
        />
      </List>
    </VStack>
  )
}
