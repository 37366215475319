export const PREP_SCHOOLS = [
  'ACADEMIA DA ESPECIALIDADE',
  'EMED',
  'EMR',
  'HARDWORK',
  'ARISTO',
  'MED',
  'MEDCEL',
  'MEDCOF',
  'MEDCURSO',
  'MEDWAY',
  'NENHUM',
  'OUTRO',
  'RMED',
  'MEDCOF 2024',
  'SANAR',
  'QBANK',
  'RMED 2024',
  'SJT',
]
