import React from 'react'
import {
  Flex,
  Heading,
  AspectRatio,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import VideoSection from '../components/VideoSection'
import './Video.css'
import './VideoMobile.css'
import { DefaultButton } from 'components/Buttons/DefaultButton'

interface ThirdStep {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export function ThirdStep({ setCurrentStep }: ThirdStep) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex w="100%" flexDir={{ base: 'column' }} gap="24px">
      <Heading
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="700"
        fontSize={{ base: '18px', sm: '32px' }}
        lineHeight={{ base: '23px', sm: '40px' }}
        letterSpacing="0.04em"
        color="#FFFFFF"
      >
        Bem Vindo à Plataforma de Métricas
      </Heading>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '0px', sm: '34px' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '516px' }}
          h="auto"
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            {isMobile ? (
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" maxW="480px">
                  <iframe
                    src="https://www.youtube.com/embed/HbGpRB68kMs"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    height="100%"
                    width={'100%'}
                    className="videoEssencialSkillMobile"
                  />
                </AspectRatio>
              </VStack>
            ) : (
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                  <iframe
                    src="https://www.youtube.com/embed/HbGpRB68kMs"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    height="100%"
                    width="100%"
                    className="videoEssencialSkill"
                  />
                </AspectRatio>
              </VStack>
            )}
          </Flex>
        </Flex>

        {isMobile ? (
          <>
            <Flex
              h="-moz-max-content"
              w="100%"
              flexDir={'column'}
              gap="23px"
              borderRadius="8px"
              m="0px !important"
            >
              <Flex flexDir={'column'} gap={{ base: '8px', sm: '15px' }}>
                <VideoSection
                  key={`VideoSection${0}`}
                  number={1}
                  videoText={'Introdução'}
                  videoTime={'02:09'}
                />
              </Flex>

              <Flex mt="auto" justifyContent={'space-between'} gap="12px">
                <DefaultButton
                  maxW="144px"
                  label="Voltar"
                  w="100%"
                  height="38px"
                  variant="ghost"
                  onClick={() => setCurrentStep(0)}
                />

                <DefaultButton
                  label="Continuar"
                  maxW="144px"
                  height="38px"
                  w="100%"
                  type="submit"
                />
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex
            maxW="368px"
            h="516px"
            minW="176px"
            w="100%"
            p={'24px'}
            flexDir={'column'}
            gap="23px"
            background={'#302F37'}
            borderRadius="8px"
            m="0px !important"
          >
            <Flex>
              <Heading
                fontFamily="Mulish"
                fontStyle=" normal"
                fontWeight=" 700"
                fontSize=" 24px"
                lineHeight=" 30px"
                color="#FFFFFF"
              >
                Linha do tempo
              </Heading>
            </Flex>

            <Flex flexDir={'column'} gap="15px">
              <VideoSection
                key={`VideoSection${0}`}
                number={1}
                videoText={'Introdução'}
                videoTime={'02:09'}
              />
            </Flex>

            <Flex mt="auto" justifyContent={'center'} gap="12px">
              <DefaultButton
                label="Voltar"
                variant="ghost"
                w="100%"
                onClick={() => setCurrentStep(1)}
              />

              <DefaultButton label="Continuar" w="100%" type="submit" />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
