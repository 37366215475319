import { map } from 'lodash'
import * as yup from 'yup'

export const schema = yup.object().shape({
  intendedInstitutions: yup
    .array()
    .min(1, 'Por favor, selecione uma instituição')
    .required('Por favor, selecione uma instituição'),

  prepSchool: yup
    .array()
    .transform((_, value: { label: string; value: string }) => {
      if (value) {
        return [value?.value]
      }

      return []
    })
    .min(1, 'Por favor, selecione um cursinho')
    .required('Por favor, selecione um cursinho'),

  trackId: yup
    .object()
    .shape({
      label: yup.string().required('Por favor, selecione uma trilha'),
      value: yup.string().required('Por favor, selecione uma trilha'),
    })
    .nullable()
    .required('Por favor, selecione uma trilha'),

  trialYear: yup
    .string()
    .transform((_, value: { label: string; value: string }) => {
      if (value?.value) {
        return String(value?.value)
      }

      return ''
    })
    .required('Por favor, selecione um ano de prova'),

  intendedSpecialties: yup
    .array()
    .min(1, 'Escolha pelo menos 1 especialidade.')
    .max(5, 'Escolha no máximo 5 especialidades.')
    .transform((_, value: Array<{ label: string; value: string }>) => {
      if (value && value?.length > 0) {
        return map(value, 'value')
      }

      return null
    })
    .required('O nome da especialidade é obrigatório.')
    .typeError('O nome da especialidade é obrigatório.'),
})
