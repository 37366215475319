import React, { useEffect, useMemo } from 'react'
import { Menu, MenuButton, MenuList, Text, Box } from '@chakra-ui/react'
import { TbAdjustmentsHorizontal } from 'react-icons/tb'

// Components
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { NewFieldSelect } from 'components/Fields/FieldDashboard'

// Contexts
import { useTrack } from 'contexts/track'

import { capitalize } from 'lodash'
import { Option } from '../type'
import { getISOWeek } from 'date-fns'

interface FiltersProps {
  selectedFieldInfo: Option | null
  setSelectedFieldInfo: React.Dispatch<React.SetStateAction<Option | null>>
  selectedThemeId: Option | null
  setSelectedThemeId: React.Dispatch<React.SetStateAction<Option | null>>
  selectedSubjectId: Option | null
  setSelectedSubjectId: React.Dispatch<React.SetStateAction<Option | null>>
  setCurrentWeek: React.Dispatch<React.SetStateAction<number>>
  setCurrentMonth: React.Dispatch<React.SetStateAction<number>>
  setCurrentYear: React.Dispatch<React.SetStateAction<number>>
  rangeOption: {
    label: string
    value: 'WEEKLY' | 'MONTHLY' | 'YEARLY'
  }
  setRangeOption: React.Dispatch<
    React.SetStateAction<{
      label: string
      value: 'WEEKLY' | 'MONTHLY' | 'YEARLY'
    }>
  >
}

export default function Filters(props: FiltersProps) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { track } = useTrack()

  const {
    selectedFieldInfo,
    selectedSubjectId,
    selectedThemeId,
    setSelectedFieldInfo,
    setSelectedSubjectId,
    setSelectedThemeId,
    rangeOption,
    setRangeOption,
    setCurrentMonth,
    setCurrentWeek,
    setCurrentYear,
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const rangeOptions = useMemo(() => {
    return [
      { value: 'YEARLY', label: 'Ano' },
      { value: 'MONTHLY', label: 'Mês' },
      { value: 'WEEKLY', label: 'Semana' },
    ]
  }, [])

  const filteredAreaRecords = useMemo(() => {
    return track?.name === 'R+ CM'
      ? track?.fields
          .filter(field => field.name !== 'GASTRO')
          .map(field => {
            return {
              label: capitalize(field.name),
              value: field.id,
            }
          })
          .concat([{ label: 'Todos', value: undefined as any }]) || []
      : track?.name === 'R+ CGE'
      ? track?.fields
          .filter(field => field.id !== 129)
          .map(field => ({
            label: capitalize(field.name),
            value: field.id,
          }))
          .concat([{ label: 'Todos', value: undefined as any }]) || []
      : track?.fields
          .map(field => ({
            label: capitalize(field.name),
            value: field.id,
          }))
          .concat([{ label: 'Todos', value: undefined as any }]) || []
  }, [track])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectedField = useMemo(() => {
    const selectedField = track?.fields.find(
      field => field.id === selectedFieldInfo?.value,
    )

    return selectedField
  }, [selectedFieldInfo?.value, track?.fields])

  useEffect(() => {
    if (!track) return
    if (selectedField || selectedFieldInfo?.value === undefined) {
      setSelectedThemeId(null)
      setSelectedSubjectId(null)
    }
  }, [
    selectedField,
    selectedFieldInfo?.value,
    setSelectedSubjectId,
    setSelectedThemeId,
    track,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Menu isLazy size={'lg'}>
      <DefaultButton
        variant="primary"
        bg=""
        as={MenuButton}
        label="Filtros"
        maxW="full"
        h="100%"
        leftIcon={<TbAdjustmentsHorizontal color="#ff6b00" />}
      />

      <MenuList
        bg={'#393940'}
        color={'#fff'}
        p={'8px'}
        outline={0}
        border={0}
        zIndex={5}
        display={'flex'}
        flexDir={'column'}
        gap={'8px'}
        minW={{ base: '300px', sm: '380px' }}
      >
        <Box maxW={'380px'}>
          <NewFieldSelect
            name="range"
            variant="secondary"
            onChange={(option: {
              value: 'WEEKLY' | 'MONTHLY' | 'YEARLY'
              label: string
            }) => {
              setRangeOption({ value: option?.value, label: option?.label })
              setSelectedFieldInfo(null)
              setSelectedThemeId(null)
              setSelectedSubjectId(null)

              if (option?.value === 'WEEKLY') {
                setCurrentWeek(getISOWeek(new Date()))
                setCurrentYear(new Date().getFullYear())
              } else if (option?.value === 'MONTHLY') {
                setCurrentMonth(new Date().getMonth() + 1)
                setCurrentYear(new Date().getFullYear())
              } else if (option?.value === 'YEARLY') {
                setCurrentYear(new Date().getFullYear())
                setCurrentYear(new Date().getFullYear())
              }
            }}
            value={rangeOption}
            placeholder="Escolha o período"
            options={rangeOptions.map(({ label, value }) => ({
              label,
              value,
            }))}
          />
        </Box>

        <Box maxW={'380px'}>
          <NewFieldSelect
            name="area"
            variant="secondary"
            onChange={(option: { label: string; value: number }) => {
              setSelectedFieldInfo({
                label: option?.label,
                value: option?.value,
              })
            }}
            value={selectedFieldInfo}
            placeholder="Selecione a grande área"
            options={filteredAreaRecords}
          />
        </Box>

        <Box maxW={'380px'}>
          <NewFieldSelect
            name="themeId"
            variant="secondary"
            onChange={(value: { label: string; value: number }) => {
              setSelectedThemeId({
                label: value?.label,
                value: value?.value,
              })
            }}
            value={selectedThemeId}
            placeholder="Selecione o tema (opcional)"
            options={selectedField?.themes?.map(theme => ({
              label: capitalize(theme.name),
              value: theme.id,
            }))}
          />
        </Box>

        <Box maxW={'380px'}>
          <NewFieldSelect
            name="subjectId"
            variant="secondary"
            onChange={(value: { label: string; value: number }) => {
              setSelectedSubjectId({
                label: value?.label,
                value: value?.value,
              })
            }}
            value={selectedSubjectId}
            isClearable
            placeholder="Selecione o subtema (opcional)"
            options={selectedField?.subjects?.map(theme => ({
              label: capitalize(theme.name),
              value: theme.id,
            }))}
          />
        </Box>

        <Text
          cursor={'pointer'}
          textAlign={'end'}
          color={'#E56000'}
          m={'8px 8px 16px 0px'}
          fontWeight={'600'}
          onClick={() => {
            setSelectedFieldInfo(null)
            setSelectedThemeId(null)
            setSelectedSubjectId(null)
          }}
        >
          limpar filtros
        </Text>
      </MenuList>
    </Menu>
  )
}
