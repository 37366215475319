import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface UpdateNotesAgendaQuery {
  agendaId: number
}

interface UpdateNotesAgendaData {
  notes: string
}

interface UpdateNotesAgenda {
  query: UpdateNotesAgendaQuery
  data: UpdateNotesAgendaData
}

async function updateNotesAgenda({ query, data }: UpdateNotesAgenda) {
  await api.put(`/app/agendas/${query.agendaId}`, { notes: data.notes })
}

export function useUpdateNotesAgenda() {
  return useMutation(updateNotesAgenda, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'list'])
    },
  })
}
