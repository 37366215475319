import { Progress, ProgressProps } from '@chakra-ui/react'
import React from 'react'

interface Props {
  data: {
    isLoading: boolean
    isFetching: boolean
  }
  progressProps?: ProgressProps
}

export function ListFetching({ data, progressProps }: Props) {
  return (
    <>
      {!data.isLoading && data.isFetching && (
        <Progress
          mb="4"
          top="4"
          left="8"
          size="xs"
          width="95%"
          isIndeterminate
          bg="whiteAlpha.200"
          position="absolute"
          {...progressProps}
        />
      )}
    </>
  )
}
