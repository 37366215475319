import { Flex, Box } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import Layout from 'layouts/UnloggedOnboarding'
import SecondStep from './second-step/SecondStep'
import ThirdStep from './third-step/ThirdStep'
import { FirstStep } from './first-step/FirstStep'
import { LogOutButton } from 'components/Header/styles'
import { Power } from 'react-feather'
import { useAuth } from 'contexts/auth'
import Swal from 'sweetalert2'
import HeaderOnboardingPurchase from './components/HeaderOnboardingPurchase'
import { FormProvider, useForm } from 'react-hook-form'
import {
  FormOnboardingPurchaseInput,
  FormOnboardingPurchaseOutput,
} from './type'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './second-step/schema'
import { AxiosError } from 'axios'
import api from 'services/api'
import FourthStep from './fourth-step'
import { FifthStep } from './fifth-step'

export function PurchaseOnboardTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [currentStep, setCurrentStep] = React.useState(0)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { logOut, updateUserData } = useAuth()
  const methods = useForm<FormOnboardingPurchaseInput>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      intendedSpecialties: [],
      intendedInstitutions: [],
      prepSchool: null,
      trackId: null,
      trialYear: null,
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      html:
        '<p style="text-align:center">Você realmente deseja fazer logout?</p>',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  const submitHandler = useCallback(
    async (data: FormOnboardingPurchaseOutput) => {
      await api
        .post('/app/onboardings', {
          intendedInstitutions: data.intendedInstitutions,
          trackId: data.trackId.value,
          trialYear: data.trialYear,
          prepSchool: data.prepSchool,
          intendedSpecialties: data.intendedSpecialties,
        })
        .then(() => {
          Swal.fire({
            title: 'Trilha definida com sucesso!',
            html:
              '<p style="text-align:center">Agora está tudo pronto. Te desejamos bons estudos.</p>',
            buttonsStyling: false,
            confirmButtonText: 'continuar',
            timer: 5000,
            timerProgressBar: true,
          })
        })
        .catch(error => {
          const message: AxiosError =
            error?.response?.data?.errors
              ?.map((error: AxiosError) => error.message)
              ?.join(', ') ||
            error?.response?.data ||
            'Não foi possível realizar o onboarding'

          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            html: `<p style="text-align:center">${
              message
                ? JSON.stringify(message)
                : 'Não foi possível realizar o onboarding'
            }</p>`,
          })
          console.trace(error)
        })
        .finally(() => {
          updateUserData()
        })
    },
    [updateUserData],
  )

  const renderThirdStep = useCallback(() => {
    const isPNATrackSelected =
      methods?.watch('trackId')?.label === 'PNA PORTUGAL'
    const isRevalidationTrackSelected =
      methods?.watch('trackId')?.label === 'Revalida'

    if (
      currentStep === 3 &&
      (isPNATrackSelected || isRevalidationTrackSelected)
    ) {
      return <FifthStep setCurrentStep={setCurrentStep} />
    }

    if (currentStep === 3) {
      return <FourthStep setCurrentStep={setCurrentStep} />
    }
  }, [currentStep, methods])

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Flex
        w="100%"
        h="100%"
        mt="3rem"
        gap="3rem"
        flexDir={'column'}
        px={{ base: '16px', md: '65px' }}
      >
        <Flex w="full" flexDir={'column'} gap="1rem" justifyContent={'end'}>
          <LogOutButton onClick={confirmLogOut}>
            <Power size={20} strokeWidth={1} />
          </LogOutButton>

          <HeaderOnboardingPurchase
            currentStep={currentStep}
            trackSelected={methods.watch('trackId')}
          />
        </Flex>

        <Flex w="100%" h="100%" pb="16px">
          {currentStep === 0 && <FirstStep setCurrentStep={setCurrentStep} />}
          <FormProvider {...methods}>
            <Box
              as="form"
              w="full"
              onSubmit={methods.handleSubmit(submitHandler)}
            >
              {currentStep === 1 && (
                <SecondStep setCurrentStep={setCurrentStep} />
              )}
              {currentStep === 2 && (
                <ThirdStep setCurrentStep={setCurrentStep} />
              )}

              {/* {renderThirdStep()} */}

              {currentStep === 3 && (
                <FifthStep setCurrentStep={setCurrentStep} />
              )}
            </Box>
          </FormProvider>
        </Flex>
      </Flex>
    </Layout>
  )
}
