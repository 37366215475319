import { format } from 'date-fns'
import { map } from 'lodash'
import * as yup from 'yup'

export const schema = yup.object().shape({
  fieldId: yup
    .number()
    .transform(
      (value, originalValue) =>
        originalValue?.value && Number(originalValue?.value),
    )
    .typeError('Área obrigatória')
    .required('Área obrigatória'),

  themeIds: yup
    .number()
    .transform(
      (value, originalValue) =>
        originalValue?.value && Number(originalValue?.value),
    )
    .required('Por favor preencha o tema'),

  subjectIds: yup
    .array()
    .of(yup.number())
    .optional()
    .transform(
      (value: any, originalValue: Array<{ label: string; value: number }>) => {
        if (originalValue) {
          const result = map(originalValue, 'value')
          return result
        }
      },
    ),

  isTheoreticalOnly: yup
    .boolean()
    .transform(value => (value === 'theoretical' ? true : false))
    .required('Por favor preencha o tipo de estudo'),

  date: yup
    .string()
    .transform(item => item && format(new Date(item), 'dd/MM/yyyy'))
    .required('Por favor preencha a data que foi estudado o tema')
    .typeError('Por favor preencha a data que foi estudado o tema'),

  hours: yup
    .number()
    .transform((value, originalValue) => Number(originalValue)),

  minutes: yup
    .number()
    .transform((value, originalValue) => Number(originalValue))
    .when('hours', {
      is: (value: number) =>
        value === 0 || value === undefined || value === null,
      then: sch => sch.min(5, 'Deve ser maior que 5 minutos'),
    }),

  numberOfQuestions: yup
    .number()
    .transform((value, originalValue) => Number(originalValue))
    .when('isTheoreticalOnly', {
      is: false,
      then: sch =>
        sch
          .required('Por favor preencha o numero de questões')
          .min(1, 'Número de questões deve ser maior que 0'),
    }),

  numberOfCorrectAnswers: yup
    .number()
    .transform((value, originalValue) => Number(originalValue))
    .max(
      yup.ref('numberOfQuestions'),
      'O número de questões corretas deve ser inferior ou igual ao numero de questões realizadas.',
    )
    .when('isTheoreticalOnly', {
      is: false,
      then: sch =>
        sch
          .required(
            'O número de questões corretas deve ser inferior ao numero de questões realizadas.',
          )
          .min(1, 'O numero de questões certas deve ser maior que 0'),
    }),

  isCreateAgenda: yup.boolean().when('isTheoreticalOnly', {
    is: false,
    then: yup
      .boolean()
      .nullable()
      .required('Por favor selecione se deseja criar revisão inteligente'),
  }),

  effortPerception: yup
    .number()
    .required('Por favor selecione a percepção de esforço')
    .min(1, 'A percepção de esforço deve ser maior ou igual a 1')
    .max(10, 'A percepção de esforço deve ser menor ou igual a 10'),
})
