import { get } from 'lodash'

interface Accessor<T> {
  label: keyof T
  value: keyof T
}

export function parserOptions<T>(
  options: T[] | undefined,
  accessor: Accessor<T>,
) {
  if (!options) return []

  const { label, value } = accessor

  return options.map(option => ({
    label: get(option, label),
    value: get(option, value),
  }))
}
