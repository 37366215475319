import { Flex, Text, FlexProps, useBreakpointValue } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React, { ReactNode } from 'react'

interface NoDataTableErrorProps extends FlexProps {
  headerMessage?: string
  message?: string
  messageElement?: ReactNode
  icon?: React.ReactNode
}

export const NoDataTableError = (props: NoDataTableErrorProps) => {
  const { message, headerMessage, messageElement, icon, ...propsData } = props

  const isSm = useBreakpointValue({ base: true, sm: false })
  return (
    <Flex
      alignItems={'center'}
      justifyContent="center"
      backgroundColor={'#393940'}
      flexDir="column"
      p="1rem"
      {...propsData}
    >
      <Flex
        justifyContent={'center'}
        alignItems="center"
        w={isSm ? '64px' : '124px'}
        h={isSm ? '64px' : '124px'}
        borderRadius={'26px'}
        backgroundColor="#60606C"
      >
        {icon ? (
          icon
        ) : (
          <Icon
            fontSize={isSm ? '36px' : '64px'}
            color="#FFFF"
            icon="eva:book-outline"
          />
        )}
      </Flex>

      <Text
        mt="26px"
        fontWeight="600"
        fontSize={isSm ? '1.5rem' : '2rem'}
        lineHeight="150%"
        color="#FFFFFF"
        textAlign={'center'}
        fontFamily="Mulish"
      >
        {headerMessage ? headerMessage : 'Dado não encontrado'}
      </Text>

      <Text
        textAlign={'center'}
        color="#BDBDC7"
        fontWeight="600"
        fontSize={isSm ? '0.8rem' : '1rem'}
        lineHeight="150%"
        fontFamily="Mulish"
      >
        {message && message}
      </Text>

      {messageElement && messageElement}
    </Flex>
  )
}
