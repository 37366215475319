import {
  chakra,
  Flex,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { Icon } from '@iconify/react'
import { FieldDateController } from 'components/Fields/FieldDateNew/FieldDateController'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useFormContext } from 'react-hook-form'

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'

export type ModalWelcomeWhatsGroupProps = {
  isOpen: boolean
  onClose: () => void
  size?: Size
  children?: ReactNode
  onCloseIcon: () => void
  onConfirmClick: () => void
} & ReactModalProps

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '292px',
    sm: '417px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

/*
|-----------------------------------------------------------------------------
| Memos
|-----------------------------------------------------------------------------
|
|
*/

export const ModalRevisionReset = (props: ModalWelcomeWhatsGroupProps) => {
  const {
    isOpen,
    onClose,
    size = 'sm',
    children,
    onCloseIcon,
    onConfirmClick,
    ...reactModalProps
  } = props

  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext()

  const isSm = useBreakpointValue({ base: true, sm: false })

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          padding: isSm ? '24px' : '40px',
          position: 'absolute',
          width: '100%',
          maxWidth: getWidthBySize('sm'),
          height: 'fit-content',
          left: '50%',
          top: '54%',
          overflow: 'unset',
          transform: 'translate(-50%, -50%)',
          background: '#302F37',
          border: '0px',
        },
        overlay: {
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.4)',
          height: '100%',
          overflow: 'auto',
        },
      }}
      {...reactModalProps}
    >
      <Stack>
        <Flex w="100%" flexDir="column">
          <HStack justifyContent={'space-between'}>
            <Text
              fontWeight={'700'}
              fontSize="24px"
              lineHeight={'30px'}
              fontFamily="Mulish"
              color={'#E56000'}
            >
              Resetar revisões
            </Text>

            <Icon
              fontSize={isSm ? '24px' : '28px'}
              onClick={onCloseIcon}
              icon="eva:close-fill"
              color="#FFFFFF"
              cursor={'pointer'}
            />
          </HStack>

          <Text
            fontStyle="normal"
            fontWeight="400"
            fontSize={'16px'}
            lineHeight={'20px'}
            color="#BDBDC7"
            mt="8px"
          >
            Ao der um click em confirmar resete, todas revisões serão{' '}
            <chakra.span fontWeight={'500'} color={'#FFFFFF'}>
              excluídas a partir das datas selecionadas.
            </chakra.span>{' '}
          </Text>

          <Flex gap={'25px'} flexDir={{ base: 'column', sm: 'row' }} mt="24px">
            <Flex className="NewDatePicker">
              <FieldDateController
                label="Data Início"
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                control={control}
                name={'inicialDate'}
                dropdownMode="select"
                placeholderText={'00/00/0000'}
                error={errors.inicialDate}
              />
            </Flex>

            <Flex className="NewDatePicker">
              <FieldDateController
                label="Data Final"
                autoComplete="off"
                dateFormat="dd/MM/yyyy"
                formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                control={control}
                name={'finalDate'}
                dropdownMode="select"
                placeholderText={'00/00/0000'}
                error={errors.finalDate}
              />
            </Flex>
          </Flex>

          <DefaultButton
            label="Confirmar resete"
            w="100%"
            isLoading={isSubmitting}
            color="#FFFFFF"
            mt="32px"
            onClick={onConfirmClick}
            type="submit"
          />
        </Flex>
      </Stack>
    </ReactModal>
  )
}
