import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface Params {
  id: number | undefined
}
export async function getListPdfLinkById(
  params: Params,
): Promise<string | undefined> {
  if (!params.id) return

  const { data } = await api.get<string>(
    `/app/essential-concepts/${params.id}/download`,
  )

  return data
}

export function useGetListPdfLinkById(params: Params) {
  return useQuery(
    ['get-killer-concepts-pdf-download', params],
    () => getListPdfLinkById(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
