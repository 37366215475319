import * as Yup from 'yup'

import { emptyStringToNull } from 'helpers/emptyStringToNull'

export const schema = Yup.object().shape({
  name: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .min(5, 'Favor, inserir pelo menos 5 caracteres')
    .max(60, 'Máximo de 60 caracteres atingido.')
    .required('Nome obrigatório.'),

  CPF: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
      {
        message: 'Formato inválido. Use um número de 11 dígitos.',
      },
    )
    .required('CPF obrigatório.'),

  email: Yup.string()
    .email()
    .nullable()
    .transform(emptyStringToNull)
    .required('Tempo obrigatório.'),

  tel: Yup.string()
    .required('Campo obrigatório')
    .matches(
      /^\(\d{2}\)\d{5}-\d{4}$/,
      'Deve obedecer ao formato (99)99999-9999',
    ),
})
