import React, { useCallback } from 'react'

import {
  Button,
  Popover,
  ButtonGroup,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Text,
} from '@chakra-ui/react'
import { UseMutationResult } from '@tanstack/react-query'
import { Icon } from '@iconify/react'
import { get } from 'lodash'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'
import { useToastMessage } from 'components/Toast'

interface Props {
  id: number
  updateAddRecoveryPlan: UseMutationResult<void, unknown, any, unknown>
}

export function PopoverDrawerAddLimitedHoursPendingStudies({
  id,
  updateAddRecoveryPlan,
}: Props) {
  /*
  |-----------------------------------------------------------------------------
  | Hook
  |-----------------------------------------------------------------------------
  |
  |
  */
  const showToast = useToastMessage()
  const { data: PrActivatedInfo } = useGetPrInfo()

  /*
  |-----------------------------------------------------------------------------
  | useCallBack
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddRecovery = useCallback(
    async (onClose: () => void) => {
      try {
        await updateAddRecoveryPlan.mutateAsync({
          studyRecoveryPlanId: PrActivatedInfo?.pendingStudiesData.id!,
          pendingStudyId: id,
          isProgrammedStudy: true,
        })
        showToast({
          title: 'Estudo adicionado.',
          description: 'Estudo adicionado com sucesso.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        onClose()
      } catch (err: any) {
        let errorMessage = 'Não foi possível efetuar a adição.'

        if (err.response?.data?.errors) {
          errorMessage = err.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        const apiErrorString = get(err, 'response.data')
        if (typeof apiErrorString === 'string') {
          errorMessage = apiErrorString
        }
        showToast({
          title: 'Erro',
          description: errorMessage,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })

        onClose()
      }
    },
    [
      PrActivatedInfo?.pendingStudiesData.id,
      id,
      showToast,
      updateAddRecoveryPlan,
    ],
  )

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Icon
              cursor={'pointer'}
              icon={'eva:plus-square-outline'}
              fontSize="24px"
              color="#FFFF"
            />
          </PopoverTrigger>
          <PopoverContent
            _focus={{ outline: 'none' }}
            border="0px"
            backgroundColor={'#2A2A30'}
          >
            <PopoverHeader border={'0px'} px="16px" pt="16px">
              <Text
                fontFamily="Mulish"
                fontWeight="700"
                fontSize="16px"
                lineHeight="20px"
                color="#FFFFFF"
              >
                Limite de tempo disponível
              </Text>
            </PopoverHeader>

            <PopoverCloseButton mt="8px" color={'#FFF'} />

            <PopoverBody py="0px" px="16px" border={'0px'}>
              <Text
                fontFamily="Mulish"
                fontWeight="400"
                fontSize="12px"
                lineHeight="150%"
                color="#BDBDC7"
                whiteSpace={'break-spaces'}
              >
                Você já alocou 100% do tempo disponível para estudos pendentes
                nesta semana, deseja estudar mais assuntos?
              </Text>
            </PopoverBody>

            <PopoverFooter
              border="0px"
              display="flex"
              justifyContent="flex-end"
              px="16px"
              pb="16px"
            >
              <ButtonGroup w="100%">
                <Button
                  w="50%"
                  maxH={'34px'}
                  variant="outline"
                  fontFamily="Mulish"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="114%"
                  color="#FFFFFF"
                  onClick={onClose}
                  _hover={{ backgroundColor: '#202024' }}
                >
                  Não
                </Button>

                <Button
                  w="50%"
                  maxH={'34px'}
                  fontFamily="Mulish"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="114%"
                  color="#FFFFFF"
                  backgroundColor={'#E56000'}
                  _hover={{ backgroundColor: '#ff6b00' }}
                  isLoading={updateAddRecoveryPlan.isLoading}
                  onClick={() => handleAddRecovery(onClose)}
                >
                  Sim
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
