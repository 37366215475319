import React, { useState } from 'react'
import { Box, chakra, Text, useBreakpointValue } from '@chakra-ui/react'

export const AdditionalFirstStepText = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })
  const [showText, setShowText] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleClick = () => {
    setShowText(!showText)
  }

  const completeText =
    'Adicione todos os estudos atrasados não registrados na tabela e que deseja incluir no plano de recuperação. A tabela exibe tanto estudos atrasados na aba de revisão inteligente quanto os adicionados nesta etapa. Aqui, você não irá adicionar os Primeiros contatos e Revisões atrasados presentes na Agenda de Revisão.'

  const shortText =
    'Adicione todos os estudos atrasados não registrados na tabela e que deseja incluir no plano de recuperação.'

  if (isSm) {
    return (
      <Box display={'block'}>
        <chakra.span
          fontFamily="Mulish"
          fontWeight="400"
          fontSize="14px"
          lineHeight="160%"
          color="#BDBDC7"
        >
          {showText ? completeText : shortText}
        </chakra.span>
        <chakra.button
          fontFamily="Mulish"
          fontWeight="400"
          fontSize="14px"
          lineHeight="160%"
          color="#FFFF"
          onClick={handleClick}
        >
          {showText ? 'Ver menos...' : 'Ver mais...'}
        </chakra.button>
      </Box>
    )
  }

  return (
    <Text
      fontFamily="Mulish"
      fontWeight="400"
      fontSize="14px"
      lineHeight="160%"
      color="#BDBDC7"
    >
      {completeText}
    </Text>
  )
}
