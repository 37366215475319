import React, { useMemo, useState } from 'react'
import {
  AspectRatio,
  Flex,
  Heading,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useFormContext } from 'react-hook-form'
import { fifthStepData } from '../data/fifthStepData'
import VideoSection from '../components/VideoSection'
import { useMutateSetCurrentStep } from '../mutations/mutate-steps'

interface FifthStepProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export function FifthStep({ setCurrentStep }: FifthStepProps) {
  /*
 |-----------------------------------------------------------------------------
 | Hooks.
 |-----------------------------------------------------------------------------
 |
 |
 */

  const isMobile = useBreakpointValue({ base: true, sm: false })
  const { watch } = useFormContext()
  const mutateCurrentStep = useMutateSetCurrentStep()

  /*
 |-----------------------------------------------------------------------------
 | Memo.
 |-----------------------------------------------------------------------------
 |
 |
 */

  const getVideoByTrack = useMemo(() => {
    const trackId = watch('trackId').label
    const selectedYear = watch('trialYear').value

    const videosByTrackAndSelectedYear = fifthStepData(trackId).filter(item =>
      item.year.includes(selectedYear),
    )

    return videosByTrackAndSelectedYear?.[0]?.videos
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('trackId').label, watch('trialYear').value])

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [activeVideo, setActiveVideo] = useState(
    getVideoByTrack?.[0]?.url ?? '',
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex w="100%" flexDir={{ base: 'column' }}>
      <Flex mb={{ base: '12px', md: '24px' }}>
        <Heading
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ base: '18px', sm: '32px' }}
          lineHeight={{ base: '23px', sm: '40px' }}
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Metodologia de Estudo
        </Heading>
      </Flex>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '0px', sm: '34px' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '516px' }}
          h={'auto'}
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            <VStack flex={'1 1 auto'}>
              <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                <iframe
                  src={activeVideo}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="100%"
                  width="100%"
                  style={
                    !isMobile
                      ? {
                          borderRadius: '8px',
                          border: '1px solid #e56000',
                        }
                      : {}
                  }
                />
              </AspectRatio>
            </VStack>
          </Flex>
        </Flex>

        <Flex
          maxW={{ md: 'full', lg: '368px' }}
          maxH={'504px'}
          justifyContent={'space-between'}
          minW="176px"
          w="100%"
          p={'24px'}
          pr="16px"
          flexDir={'column'}
          gap="23px"
          background={isMobile ? 'inherit' : '#302F37'}
          borderRadius="8px"
          m="0px !important"
        >
          <Flex
            flexDir={'column'}
            gap="22px"
            overflowY={'auto'}
            pr="12px"
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#E56000',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
          >
            <Flex flexDir={'column'} gap="15px">
              {getVideoByTrack?.map((video, index) => (
                <VideoSection
                  key={`VideoSection${index}`}
                  number={index + 1}
                  onClick={() => setActiveVideo(video.url)}
                  videoText={video.title}
                  videoTime={video.time}
                />
              ))}
            </Flex>
          </Flex>

          <Flex
            h="fit-content"
            gap="1rem"
            flexDir={{ base: 'column', sm: 'row' }}
          >
            <DefaultButton
              mt="auto"
              label="Voltar"
              size="md"
              variant="ghost"
              w="100%"
              onClick={() => {
                return setCurrentStep(2)
              }}
            />

            <DefaultButton
              mt="auto"
              label="Finalizar"
              type="submit"
              size="md"
              onClick={() => {
                try {
                  // should pass to next step even if there is an error on this mutation
                  mutateCurrentStep.mutate({
                    onboardCurrentStep: 5,
                  })
                } catch (error) {
                  console.trace(error)
                }
              }}
              variant="gradient"
              w="100%"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
