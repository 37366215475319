import { Box, Button, Image, Link, useBreakpointValue } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { AxiosError } from 'axios'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import React, { useMemo, useState } from 'react'
import { useActivateRecoveryPlan } from '../queries/use-mutation-active-pr'
import BannerRecoveryPlan from '../../../assets/images/banner-pr.png'
import BannerRecoveryPlanMobile from '../../../assets/images/bannermobile.jpg'
import { useGetPrInfo } from '../queries/use-get-activated-pr'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useToastMessage } from 'components/Toast'

export const InitialPagePlan = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [
    modalConfirmedPendingStudies,
    setModalConfirmedPendingStudies,
  ] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()

  const mutate = useActivateRecoveryPlan()

  const isSm = useBreakpointValue({ base: true, sm: false })

  const { data } = useGetPrInfo()
  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleActivatePlan = async () => {
    try {
      await mutate.mutateAsync()
      showToast({
        title: 'Sucesso',
        description: 'Ativação efetuada.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar a ativação.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Memo
  |-----------------------------------------------------------------------------
  |
  |
  */

  const hasPendingStudies = useMemo(() => {
    if (
      data?.pendingStudiesData?.pendingStudies &&
      data?.pendingStudiesData?.pendingStudies.length > 0
    ) {
      return true
    }

    return false
  }, [data?.pendingStudiesData?.pendingStudies])

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      {!isSm && (
        <Box position={'relative'}>
          <Image src={BannerRecoveryPlan} />
          <Link
            target="_blank"
            href="https://www.youtube.com/watch?v=dKfgeYNbI6g"
          >
            <DefaultButton
              label="Ver vídeo explicativo"
              variant="third"
              leftIcon={
                <Icon
                  fontSize={'18px'}
                  color="#E56000"
                  icon="material-symbols:play-circle"
                />
              }
              position={'absolute'}
              bottom={'0.6rem'}
              h="2.4rem"
              right={'1rem'}
            />
          </Link>
        </Box>
      )}

      {isSm && (
        <Box>
          <Image src={BannerRecoveryPlanMobile} />

          <Link
            target="_blank"
            href="https://www.youtube.com/watch?v=dKfgeYNbI6g"
          >
            <DefaultButton
              w="full"
              label="Ver vídeo explicativo"
              variant="third"
              leftIcon={
                <Icon
                  fontSize={'18px'}
                  color="#E56000"
                  icon="material-symbols:play-circle"
                />
              }
              h="2.4rem"
              mt="8px"
            />
          </Link>
        </Box>
      )}

      <Button
        fontFamily="Mulish"
        mt="1rem"
        alignSelf={{ base: 'unset', sm: 'flex-end' }}
        minH={'40px'}
        w={{ base: '100%', sm: 'fit-content' }}
        whiteSpace={'break-spaces'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        color="#FFFFFF"
        background={'linear-gradient(90deg, #FF8A00 -38.46%, #FA5B01 100%)'}
        borderRadius="8px"
        _hover={{
          background:
            'linear-gradient(90deg, #ff8800df -38.46%, #fa5c01e8 100%)',
        }}
        _active={{
          background:
            'linear-gradient(90deg, #ff8800df -38.46%, #fa5c01e8 100%)',
        }}
        onClick={() => {
          if (hasPendingStudies) {
            return setModalConfirmedPendingStudies(true)
          }

          return handleActivatePlan()
        }}
        isLoading={modalConfirmedPendingStudies === false && mutate.isLoading}
      >
        Criar Plano de Recuperação
      </Button>

      <ModalDefaultTwoButton
        isOpen={modalConfirmedPendingStudies}
        title="Você não finalizou alguns estudos na ultima semana!"
        subTitle="Ao iniciar um novo Plano de Recuperação esses conteúdos voltam para estudos pendentes. Caso tenha estudado basta incluí-los nesse novo plano e realizar o registro do estudo na data correta."
        onClose={() => setModalConfirmedPendingStudies(false)}
        onConfirmClick={handleActivatePlan}
        confirmButtonIsLoading={mutate.isLoading}
        icon={
          <Icon
            color="#E5C009"
            fontSize={'84px'}
            icon={'mdi:book-clock-outline'}
          />
        }
      />
    </>
  )
}
