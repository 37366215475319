import { map } from 'lodash'
import * as Yup from 'yup'

export const ProfileSchema = Yup.object().shape({
  trial_year: Yup.number()
    .transform((_, value) => {
      if (value) {
        return value.value
      }
    })
    .required('O ano de prova é obrigatório.'),
  intended_specialties: Yup.array()
    .min(1, 'Escolha pelo menos 1 especialidade.')
    .max(5, 'Escolha no máximo 5 especialidades.')
    .transform((_, value) => {
      if (value) {
        return map(value, 'value')
      }
    })
    .required('O nome da especialidade é obrigatório.')
    .typeError('O nome da especialidade é obrigatório.'),

  intended_institutions: Yup.array()
    .of(Yup.string().required())
    .min(1, 'O campo é obrigatório')
    .required('O campo é obrigatório'),

  prep_school: Yup.array()
    .min(1, 'Escolha pelo menos 1 escola.')
    .required('O nome da escola é obrigatório.')
    .transform(
      (
        _: { label: string; value: string },
        value: { label: string; value: string },
      ) => {
        if (value) {
          return [value.value]
        }
      },
    ),
})
