import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface FocusLearning {
  themeId: number
  theme: string
  fieldId: number
  field: string
  weight: number
}

interface Params {
  fieldId?: number
  limit?: number
  page?: number
}

export async function getFocusLearning(
  params: Params,
): Promise<FocusLearning[]> {
  const { data } = await api.get<FocusLearning[]>('/app/focus_learning', {
    params: {
      fieldId: params.fieldId,
    },
  })

  return data
}

export function useGetFocusLearning(params: Params) {
  return useQuery(
    ['get-focus-learning', params],
    () => getFocusLearning(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
