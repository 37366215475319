import React from 'react'
import Chart from 'react-apexcharts'

interface VerticalBarChartProps {
  title: string
  width: string
  height?: string
  series: Array<{ name: string; data: number[] }>
  categories: string[]
  colors?: string[]
}

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({
  categories,
  series,
  title,
  width,
  height,
  colors = ['#ffa05c', '#ff6b00'],
}) => (
  <Chart
    type="bar"
    width={width}
    height={height}
    series={series}
    options={{
      title: {
        text: title,
      },
      chart: {
        id: 'basic-bar',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors,
    }}
  />
)
