import { useMutation } from '@tanstack/react-query'
import api from 'services/api'

export interface SendFeedbackParams {
  messageFeedBack: string
}

async function sendFeedback(params: SendFeedbackParams) {
  await api.post('/app/feedback', params)
}

export function useSendFeedback() {
  return useMutation(sendFeedback)
}
