import {
  Flex,
  Input,
  TableRowProps,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React, { useCallback, useState } from 'react'
import { Icon } from '@iconify/react'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { startOfWeek, endOfWeek } from 'date-fns'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import pt from 'date-fns/locale/pt'
import { AxiosError } from 'axios'
import { capitalize, isEmpty } from 'lodash'
import { useAddHoursProgrammedStudies } from 'pages/RecoveryPlan/queries/use-mutation-add-hours-register'
import DatePicker from 'react-datepicker'
import { formatDateAddTimeZoned, formatDateUTC } from 'helpers/formatTimer'
import { useToastMessage } from 'components/Toast'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'

interface SubTheme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Theme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}
interface TrTableProgramedStudiesProps extends TableRowProps {
  area: string
  theme: Theme[]
  subTheme: SubTheme[]
  studyType: boolean
  backgroundTable?: string
  trId: number
  dateCompleted: string
  onClickOpenRemove: () => void
}

export function TrTableProgramedStudies(props: TrTableProgramedStudiesProps) {
  const {
    area,
    backgroundTable,
    studyType,
    dateCompleted,
    onClickOpenRemove,
    subTheme,
    trId,
    theme,
    ...rest
  } = props

  const [fieldDate, setFieldDate] = useState<any>()
  const showToast = useToastMessage()
  registerLocale('pt', pt)
  setDefaultLocale('pt')
  const addHoursProgrammedStudies = useAddHoursProgrammedStudies()
  const { data: PrDate } = useGetPrInfo()

  const handleData = async (e: Date | [Date, Date] | null) => {
    setFieldDate(e)
    try {
      await addHoursProgrammedStudies.mutateAsync({
        date: e,
        id: trId,
      })

      showToast({
        title: 'Sucesso',
        description: 'Estudo adicionado.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar o registro.'

      showToast({
        title: 'Erro ao adicionar estudo',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const GenerateDate = useCallback(() => {
    const today = new Date()
    const start = startOfWeek(today, { weekStartsOn: 1 })
    const end = endOfWeek(today, { weekStartsOn: 1 })

    if (
      !PrDate?.pendingStudiesData.finishes_at ||
      !PrDate?.pendingStudiesData.starts_at
    )
      return {
        startDate: start,
        finishedDate: end,
      }

    return {
      startDate: today,
      finishedDate: formatDateAddTimeZoned(
        PrDate.pendingStudiesData.finishes_at,
      ),
    }
  }, [
    PrDate?.pendingStudiesData?.finishes_at,
    PrDate?.pendingStudiesData?.starts_at,
  ])

  return (
    <>
      <Tr
        pb="1rem"
        backgroundColor={backgroundTable}
        verticalAlign={'baseline'}
        borderLeft={dateCompleted ? '6px solid #E5C009' : '6px solid #FF6363'}
        lineHeight="40px"
        {...rest}
      >
        <Td
          h="-webkit-fit-content"
          py="10px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Flex h="20px" className="NewDatePicker">
            <DatePicker
              dateFormat="dd/MM/yyyy'T'HH:mm:ss"
              timeFormat="HH:mm:ss"
              autoComplete="off"
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
              name={'inicialDate'}
              dropdownMode="select"
              disabled={!!dateCompleted}
              placeholderText={'00/00/0000'}
              onChange={handleData}
              selected={fieldDate}
              minDate={GenerateDate().startDate}
              maxDate={GenerateDate().finishedDate}
              customInput={
                <Flex width="104px">
                  <Input
                    height={'20px'}
                    _focus={{ outline: 'none' }}
                    px="0px"
                    fontFamily="Mulish"
                    fontSize="14px"
                    border="none"
                    borderRadius="8px"
                    lineHeight={'18px'}
                    backgroundColor="inherit"
                    color="#FFFFFF"
                    _placeholder={{
                      fontWeight: '400',
                      fontSize: '14px',
                      fontFamily: 'Mulish',
                      lineHeight: '18px',
                      color: '#D7D7D9',
                    }}
                    placeholder={'00/00/0000'}
                    value={dateCompleted ? formatDateUTC(dateCompleted) : ''}
                  />
                  <RiArrowDropDownLine
                    style={{
                      position: 'absolute',
                      right: '5',
                    }}
                    size={'20px'}
                    color="#E56000"
                  />
                </Flex>
              }
            />
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="10px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={!area ? '#BDBDC7' : '#FFFFFF'}
          >
            {area?.length > 20
              ? area && (
                  <Tooltip label={capitalize(area)}>
                    {letterCounter(capitalize(area), 20)}
                  </Tooltip>
                )
              : area && capitalize(area)}
          </Text>

          {(isEmpty(area) || !area) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="10px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {theme &&
            theme.map(item => capitalize(item.name)).join(' | ').length > 11 ? (
              <Tooltip
                label={theme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  theme?.map(item => capitalize(item.name)).join(' | '),
                  11,
                )}
              </Tooltip>
            ) : (
              theme?.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(theme) || !theme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="10px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {subTheme &&
            subTheme.map(item => capitalize(item.name)).join(' | ').length >
              11 ? (
              <Tooltip
                label={subTheme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  subTheme.map(item => capitalize(item.name)).join(' | '),
                  11,
                )}
              </Tooltip>
            ) : (
              subTheme.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(subTheme) || !subTheme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="10px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {studyType ? 'Teórico' : 'Prático'}
          </Text>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="10px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
        >
          <Icon
            fontFamily="Mulish"
            onClick={onClickOpenRemove}
            icon={'eva:minus-square-outline'}
            fontSize="18px"
            color={'#FFFF'}
            cursor="pointer"
          />
        </Td>
      </Tr>
    </>
  )
}
