import React from 'react'
import {
  chakra,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  GridItem,
  GridItemProps,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { MajorStatsProps } from '..'

type TheoreticalPracticalProps = Pick<
  MajorStatsProps,
  | 'percentStudiedMinutesPractical'
  | 'percentStudiedMinutesTheorycal'
  | 'totalStudiedHoursPratical'
  | 'totalStudiedHoursTheorycal'
> &
  GridItemProps

export default function TheoreticalPractical(props: TheoreticalPracticalProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })

  const {
    percentStudiedMinutesPractical,
    percentStudiedMinutesTheorycal,
    totalStudiedHoursPratical,
    totalStudiedHoursTheorycal,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <GridItem textAlign="center" backgroundColor="#302F37" p="16px" {...rest}>
      <Text
        fontWeight={'700'}
        textAlign={'center'}
        fontSize={{ base: '16px', sm: '18px' }}
      >
        Teórico x Prática
      </Text>

      <CircularProgress
        size={isSm ? '84px' : '110px'}
        thickness="6px"
        capIsRound
        color={percentStudiedMinutesPractical >= 70 ? '#0DDF15' : '#e5c009'}
        trackColor="#616167"
        my="16px"
        value={percentStudiedMinutesPractical}
      >
        <CircularProgressLabel>
          <chakra.span
            fontWeight={600}
            fontSize={'24px'}
          >{`${percentStudiedMinutesPractical.toFixed(0)}%`}</chakra.span>

          <Text fontWeight={400} fontSize={'13px'}>
            Prática
          </Text>
        </CircularProgressLabel>
      </CircularProgress>

      <VStack>
        <Flex alignItems="center" gap="8px">
          <Circle
            bg={percentStudiedMinutesPractical >= 70 ? '#0DDF15' : '#e5c009'}
            fill={percentStudiedMinutesPractical >= 70 ? '#0DDF15' : '#e5c009'}
            size="8px"
          />

          <Flex gap={'4px'}>
            <Text
              fontWeight="600"
              fontSize={{ base: '12px', sm: '13px' }}
              color="#FFFFFF"
            >
              {totalStudiedHoursPratical}h
            </Text>

            <Text
              fontWeight="400"
              fontSize={{ base: '12px', sm: '13px' }}
              color="#BDBDC7"
            >
              prática
            </Text>
          </Flex>
        </Flex>

        <Flex alignItems="center" justifyContent={'flex-start'} gap="8px">
          <Circle bg="#BDBDC7" fill="#BDBDC7" size="8px" />

          <Flex gap={'4px'}>
            <Text
              fontWeight="600"
              fontSize={{ base: '12px', sm: '13px' }}
              color="#FFFFFF"
            >
              {totalStudiedHoursTheorycal}h
            </Text>

            <Text
              fontWeight="400"
              fontSize={{ base: '12px', sm: '13px' }}
              color="#BDBDC7"
            >
              teórica
            </Text>
          </Flex>
        </Flex>
      </VStack>
    </GridItem>
  )
}
