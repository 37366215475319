export const steps = [
  {
    title: 'Diagnósticos de Especialidades',
  },
  {
    title: 'Minha Versão 2.0',
  },
  {
    title: 'Conceitos Matadores',
  },
  {
    title: 'Mentoria Residência',
  },
]
