import { useWindowSize } from 'hooks/useWindowSize'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface SideBarProps {
  isSidebarSetToWide: boolean
  setIsSidebarSetToWide: React.Dispatch<React.SetStateAction<boolean>>
}

const SidebarContext = createContext({} as SideBarProps)

export const SidebarProvider: React.FC = ({ children }) => {
  const [isSidebarSetToWide, setIsSidebarSetToWide] = useState(false)
  const { width } = useWindowSize()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!width) return undefined
    if (width >= 800) {
      setIsSidebarSetToWide(true)
    }
    if (width < 800) {
      setIsSidebarSetToWide(false)
    }
  }, [width])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <SidebarContext.Provider
      value={{ isSidebarSetToWide, setIsSidebarSetToWide }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export function useSideBar() {
  const context = useContext(SidebarContext)
  return context
}
