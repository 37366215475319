import React from 'react'
import { Loading } from 'features/ui'

export function withLoadingFeedback(Component: any) {
  return function WithLoadingFeedback(props: any) {
    const { isLoading } = props

    return isLoading ? <Loading /> : <Component {...props} />
  }
}
