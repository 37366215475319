import { GetAgenda } from 'features/agendas/queries/use-get-agendas'
import { get } from 'lodash'

export const formatAgendaTooltip = (agenda: GetAgenda) => {
  if (!agenda) return ''

  const revisionNumber = get(agenda.metadata, 'revisionNumber', '')
  const parserRevisionNumber = revisionNumber !== '' ? `${revisionNumber}` : ''

  if (agenda.type === 'evento') {
    return agenda.title
  }

  if (
    agenda.metadata &&
    agenda.type === 'primeiro-contato' &&
    agenda.font === 'estudo-pendente'
  ) {
    return `Primeiro Contato ${agenda.metadata.field} ${
      agenda?.themes && agenda?.themes?.length > 0
        ? `- Tema: ${agenda?.themes?.map(theme => theme.name).join(', ')}`
        : ''
    } ${
      agenda?.subjects && agenda?.subjects.length > 0
        ? `- Subtema: ${agenda.subjects
            ?.map(subjects => subjects.name)
            .join(', ')}`
        : ''
    }`
  }

  if (
    agenda.metadata &&
    agenda.type === 'primeiro-contato' &&
    agenda.font === 'estudo-diario' &&
    !agenda?.themes?.[0]?.medcel_exam
  ) {
    return `Revisão (#${parserRevisionNumber}) ${agenda.metadata.field} ${
      agenda?.themes && agenda?.themes?.length > 0
        ? `- Tema: ${agenda?.themes?.map(theme => theme.name).join(', ')}`
        : ''
    } ${
      agenda?.subjects && agenda?.subjects.length > 0
        ? `- Subtema: ${agenda.subjects
            ?.map(subjects => subjects.name)
            .join(', ')}`
        : ''
    }`
  }

  if (agenda.metadata && agenda.type === 'estudo-teorico') {
    return `Estudo Teórico ${agenda.metadata.field} ${
      agenda?.themes && agenda?.themes?.length > 0
        ? `- Tema: ${agenda?.themes?.map(theme => theme.name).join(', ')}`
        : ''
    } ${
      agenda?.subjects && agenda?.subjects.length > 0
        ? `- Subtema: ${agenda.subjects
            ?.map(subjects => subjects.name)
            .join(', ')}`
        : ''
    }`
  }

  if (
    agenda.metadata &&
    agenda.font === 'projeto-x' &&
    agenda.type !== 'primeiro-contato'
  ) {
    return `Revisão X (#${parserRevisionNumber}) ${
      agenda.metadata.field ? `- Área: ${agenda.metadata.field}` : ''
    } ${
      agenda?.themes && agenda?.themes?.length > 0
        ? `- Tema: ${agenda?.themes?.map(theme => theme.name).join(', ')}`
        : ''
    } ${
      agenda?.subjects && agenda?.subjects.length > 0
        ? `- Subtema: ${agenda.subjects
            ?.map(subjects => subjects.name)
            .join(', ')}`
        : ''
    }`
  }

  if (
    agenda.metadata &&
    agenda.font === 'projeto-x' &&
    agenda.type === 'primeiro-contato'
  ) {
    return `Primeiro Contato X ${
      agenda.metadata.field ? `- Área: ${agenda.metadata.field}` : ''
    } ${
      agenda?.themes && agenda?.themes?.length > 0
        ? `- Tema: ${agenda?.themes?.map(theme => theme.name).join(', ')}`
        : ''
    } ${
      agenda?.subjects && agenda?.subjects.length > 0
        ? `- Subtema: ${agenda.subjects
            ?.map(subjects => subjects.name)
            .join(', ')}`
        : ''
    }`
  }

  if (!!agenda?.themes?.[0]?.medcel_exam && agenda.font === 'estudo-diario') {
    return `Trilha de Simulados - Grande Área: ${agenda?.metadata?.field} ${
      agenda?.themes && agenda?.themes?.length > 0
        ? `- Tema: ${agenda?.themes?.map(theme => theme.name).join(', ')}`
        : ''
    } ${
      agenda?.subjects && agenda?.subjects.length > 0
        ? `- Subtema: ${agenda.subjects
            ?.map(subjects => subjects.name)
            .join(', ')}`
        : ''
    }`
  }

  return ''
}
