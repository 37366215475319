import React, { useMemo } from 'react'

// UI
import { List, ListHeader, ListPagination, ListTable } from 'features/ui'
import { TableHeader } from 'features/ui/list/list-table'

// Agendas
import { GetDeletedAgendas, useGetDeletedAgendas } from 'features/agendas'

// Styles
import { Text, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import { usePagination } from 'hooks/usePagination'
import { usePatchAgenda } from 'features/agendas/mutations/use-patch-agenda'
import { PopoverDeletedRecovery } from 'features/agendas/popover-deleted-recovery'

export function ListDeleteProjectX() {
  const { page, onChangePage } = usePagination()
  const updateDeleted = usePatchAgenda()

  const { data, isLoading, isFetching } = useGetDeletedAgendas({
    page,
    font: 'Projeto X',
  })

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'startsAt',
        fn(value) {
          return format(new Date(value), 'dd/MM/yyyy')
        },
      },
      {
        label: 'Titulo',
        accessor: 'title',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={1}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: '',
        fn(_, data) {
          return (
            <PopoverDeletedRecovery
              id={data.id}
              updateDeleted={updateDeleted}
            />
          )
        },
      },
    ] as TableHeader<GetDeletedAgendas>[]
  }, [updateDeleted])

  return (
    <List>
      <ListHeader
        title="Projeto-X"
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <ListTable headers={headers} data={data?.items} isLoading={isLoading} />

      <ListPagination
        currentPage={page}
        onChangePage={onChangePage}
        totalCountOfRegisters={data?.meta.total}
      />
    </List>
  )
}
