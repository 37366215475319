import styled from 'styled-components'
import { ModalHeader, ModalContent, ModalBody } from '@chakra-ui/react'

interface StylePropTypes {
  bgstyle?: number
  success?: number
  center?: boolean
  active?: boolean
}

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;

  table {
    width: 100%;
    background-color: #393940;
    border-radius: 8px;
  }

  th {
    text-align: left;
    padding: 18px 0 18px 20px;
    font-family: 'Mulish';
    font-style: 'normal';
    font-weight: 500;
    font-size: 14px;
    user-select: none;

    @media (max-width: 480px) {
      padding: 12px 0 12px 15px;
    }
  }

  thead {
    border-bottom: 1px solid #88898d;
  }

  tr:last-of-type {
    td:first-of-type {
      border-radius: 0 0 0 8px;
    }

    td:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }
`

export const StyledTr = styled.tr`
  background-color: ${(props: StylePropTypes) =>
    (props.bgstyle && props.bgstyle % 2 === 0) || props.bgstyle === 0
      ? '#303036'
      : '#393940'};
`

export const StyledTd = styled.td`
  padding: 12px 10px 12px 20px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Mulish';
  font-style: 'normal';
  font-weight: 400;
  font-size: 16px;
  color: ${(props: StylePropTypes) =>
    props.success && props.success > 70 ? '#0DDF15' : '#fff'};
  text-align: ${(props: StylePropTypes) => (props.center ? 'center' : 'left')};

  @media (max-width: 480px) {
    padding: 12px 0 12px 15px;
    padding-left: ${(props: StylePropTypes) => props.center && '0px'};
    max-width: 150px;
    margin-top: 10px;
  }
`

export const TableActionButton = styled.button`
  background-color: #e56000;
  font-size: 14px;
  border-radius: 4px;
  padding: 2px 8px;
`

export const TableFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`

export const StyledModalContent = styled(ModalContent)`
  background-color: #302f37 !important;
  border-radius: 5px;
`

export const StyledModalHeader = styled(ModalHeader)`
  color: #fff;
  padding: 10px 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e56000;
  background-color: #2a2a30;
  font-size: 24px;
  border-radius: 5px 5px 0 0;

  div {
    font-size: 22px;
  }
`

export const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  color: #fff;
  padding: 10px 15px !important;
`

export const ModalRank = styled.div`
  padding-right: 20px;
  border-right: 1px solid #60606c;
`

export const ModalCloseButton = styled.div`
  font-size: 24px !important;
  cursor: pointer;
`

export const ModalSectionWrapper = styled.div`
  width: 50%;
  padding: 10px 0;
  color: ${(props: StylePropTypes) =>
    props.success && props.success > 70 ? '#0DDF15' : '#fff'};
`

export const ModalDisplayLabel = styled.div`
  color: #bdbdc7;
  font-size: 14px;
  padding-bottom: 5px;
`

export const ModalButton = styled.button`
  width: 100%;
  margin: 10px 0;
  background-color: #393940;
  padding: 12px 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: 0.3s background-color;

  &:hover {
    background-color: #e56000;
  }

  &:disabled {
    cursor: default;

    &:hover {
      background-color: #393940;
    }
  }

  svg {
    font-size: 16px;
    margin-right: 10px;
  }
`
