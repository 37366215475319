import { Flex } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import FieldInputMaskController from 'components/Fields/FieldMask/FieldInputMaskController'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormTitle } from './styles'

interface Step1Form {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export default function Step1({ setStep }: Step1Form) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const RESET_FORM = {
    name: '',
    email: '',
    mobilePhone: '',
    password: '',
    passwordConfirmation: '',
  }

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const validateFirstStep = async () => {
    const [
      nameValid,
      emailValid,
      mobilePhoneValid,
      cpfValid,
    ] = await Promise.all([
      trigger('name'),
      trigger('email'),
      trigger('mobilePhone'),
      trigger('cpf'),
    ])

    if (nameValid && emailValid && mobilePhoneValid && cpfValid) {
      setStep(2)
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setValue('mobilePhone', '+55')
  }, [setValue])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <FormTitle>
        Complete o cadastro abaixo e ative <span>o seu trial</span>
      </FormTitle>
      <Flex
        flexDir={'column'}
        maxW={'350px'}
        margin="0 auto"
        justifyContent={'center'}
        gap={'16px'}
      >
        <FieldInputController
          control={control}
          name="name"
          type="text"
          placeholder="Nome completo"
          error={errors.name}
        />

        <FieldInputController
          control={control}
          name="email"
          type="email"
          placeholder="E-mail"
          error={errors.email}
        />
        <FieldInputController
          control={control}
          name="mobilePhone"
          placeholder="Telefone"
          error={errors.mobilePhone}
        />

        <FieldInputMaskController
          control={control}
          name="cpf"
          placeholder="CPF"
          error={errors.cpf}
          mask={'999.999.999-99'}
          autoComplete="off"
          maxW={'380px'}
          w="100%"
        />

        <Flex
          w="100%"
          gap={'1rem'}
          mt={'32px !important'}
          flexDir={{ base: 'column', sm: 'row' }}
          justifyContent={{ base: 'center', sm: 'space-between' }}
        >
          <DefaultButton
            label="Limpar"
            w="100%"
            variant="ghost"
            type="button"
            onClick={() => reset(RESET_FORM)}
          />

          <DefaultButton
            label="Próximo passo"
            w="100%"
            onClick={validateFirstStep}
          />
        </Flex>
      </Flex>
    </>
  )
}
