import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponseApi } from 'types/response'

interface Params {
  sort?: string
  limit?: number
  page?: number
  isRevision?: boolean
}

interface Subjects {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Themes {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

export interface ListPendingStudiesCompletedData {
  id: number
  starts_at: string
  finishes_at: string
  is_theoretical: boolean
  is_programmed_study: boolean
  study_time_in_minutes: number
  created_at: string
  theme_id: number | null
  field_id: number
  user_id: number
  subject_id: number | null
  subject: null
  theme: null
  field: {
    name: string
    id: number
  }
  themes: Themes[]
  subjects: Subjects[]
}

interface ListProgramedResponse {
  meta: MetaResponseApi
  data: ListPendingStudiesCompletedData[]
}

async function listFirstContact(params?: Params) {
  const { data } = await api.get<ListProgramedResponse>(
    'app/pending_studies/first_contacts',
    {
      params: {
        sort: params?.sort && params.sort,
        limit: params?.limit,
        page: params?.page,
        isRevision: params?.isRevision,
      },
    },
  )

  return data
}

export function useListFirstContact(params?: Params) {
  return useQuery(
    ['first-contact-studies-recovery-plan', params],
    () => listFirstContact(params),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
