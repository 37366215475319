import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import Swal from 'sweetalert2'
import React, { useEffect, useState, useCallback } from 'react'
import Info from '../components/Info'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FirstStepVideo } from 'features/pre-onboarding/components/FirstStepVideo'
import { PiArrowUpRightBold } from 'react-icons/pi'
import { LogOutButton } from 'components/Header/styles'
import api from 'services/api'
// Contexts
import { useAuth } from 'contexts/auth'
import { Power } from 'react-feather'

interface SpecialtyDiagnosticProps {
  currentStep: number
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export function SpecialtyDiagnostic(props: SpecialtyDiagnosticProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { setStep } = props

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [
    specialtyDiagnosticLink,
    setSpecialtyDiagnosticLink,
  ] = useState<string>('/')

  /*
  |-----------------------------------------------------------------------------
  | useEffect
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const getSpecialtyDiagnosticLink = async () => {
      const { data } = await api.post<{ url: string }>(
        '/providers/medcel_experience',
      )

      if (!data?.url) {
        setSpecialtyDiagnosticLink('/')
      }

      setSpecialtyDiagnosticLink(data.url)
    }

    getSpecialtyDiagnosticLink()
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logOut } = useAuth()
  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      html:
        '<p style="text-align:center">Você realmente deseja fazer logout?</p>',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box mx={{ base: '16px', md: '64px', xl: '84px' }} pb={'24px'}>
      <Flex
        w="full"
        mb="16px"
        justifyContent={'end'}
        pr={{ base: '16px', md: '0px' }}
      >
        <LogOutButton onClick={confirmLogOut}>
          <Power size={20} strokeWidth={1} />
        </LogOutButton>
      </Flex>
      <Info currentStep={props.currentStep} />

      <Flex mt={{ base: '44px', sm: '84px' }} mb={{ base: '12px', md: '24px' }}>
        <Heading
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ base: '18px', sm: '32px' }}
          lineHeight={{ base: '23px', sm: '40px' }}
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Diagnóstico de Especialidade
        </Heading>
      </Flex>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '0px', sm: '34px' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '516px' }}
          h={{ base: 'fit-content', sm: '100%' }}
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            <VStack flex={'1 1 auto'}>
              <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                <iframe
                  src={
                    'https://www.youtube.com/embed/GSSK8nbAHmM?si=eZUmsOxYjXUJlJ9L'
                  }
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="100%"
                  width="100%"
                  style={
                    !isMobile
                      ? {
                          borderRadius: '8px',
                          border: '1px solid #e56000',
                        }
                      : {}
                  }
                />
              </AspectRatio>
            </VStack>
          </Flex>
        </Flex>

        <Flex
          maxW={{ md: 'full', lg: '368px' }}
          flex={'1 1 auto'}
          minW="176px"
          w="100%"
          p={{ base: '24px 0px 24px 0px', sm: '24px' }}
          flexDir={'column'}
          gap="23px"
          background={isMobile ? 'inherit' : '#302F37'}
          borderRadius="8px"
          m="0px !important"
        >
          <Flex flexDir={'column'} gap="15px">
            <FirstStepVideo
              key={`VideoSection${1}`}
              number={1}
              videoText={'Onboarding Mentoria 2024 - Júlia Brighenti'}
              videoTime={''}
              activeVideo={1}
            />
          </Flex>

          <a href={specialtyDiagnosticLink} target="_blank" rel="noreferrer">
            <DefaultButton
              label="Diagnóstico de Especialidade"
              rightIcon={<PiArrowUpRightBold />}
              variant="gradient"
              size={'md'}
              w="100%"
            />
          </a>

          <DefaultButton
            mt="auto"
            label="Continuar"
            w="100%"
            onClick={() => {
              setStep(1)
            }}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
