export const stepsOnboardingPurchase = [
  {
    title: 'Atualização de dados',
  },
  {
    title: 'Trilha',
  },
  {
    title: 'Boas-vindas',
  },
  /* {
    title: 'Conheça seu monitor',
  }, */
  {
    title: 'Metodologia de Estudo',
  },
]
