import { map } from 'lodash'
import * as yup from 'yup'

export const StepOneSchema = yup
  .object()
  .shape({
    area: yup
      .number()
      .transform(
        (value, originalValue) => originalValue && Number(originalValue.value),
      )
      .typeError('Área obrigatória')
      .required('Área obrigatória'),

    themeIds: yup
      .array()
      .transform(
        (value, originalValue) => originalValue && [originalValue.value],
      )
      .typeError('Tema obrigatório')
      .required('Tema obrigatório'),

    subjectIds: yup
      .array()
      .nullable()
      .transform(item => item && map(item, 'value')),

    isTheoreticalOnly: yup
      .boolean()
      .transform(value => (value === 'theoretical' ? true : false)),
    hours: yup.string(),
    minutes: yup.string(),
  })
  .test('notEmpty', 'É necessário preencher esse campo', function (value) {
    const { hours, minutes, isTheoreticalOnly } = value || {}

    if (!isTheoreticalOnly) {
      return true
    }
    if ((!hours && !minutes) || (hours === '0' && minutes === '0')) {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (!hours && minutes === '0') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '00' && minutes === '00') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '0' && minutes === '00') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '00' && minutes === '0') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '0' && !minutes) {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (!hours && minutes) {
      return true
    }

    if (hours && !minutes) {
      return true
    }
    return true
  })
