/* eslint-disable react/display-name */
import { Button, ButtonProps } from '@chakra-ui/react'
import { forwardRef, useMemo } from 'react'
import React from 'react'

type Variants =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'third'
  | 'gradient'
  | 'rainbow'
type Sizes = 'lg' | 'md' | 'mds' | 'sm'
export interface DefaultButtonProps extends Omit<ButtonProps, Variants> {
  label: string
  variant?: Variants
  size?: Sizes
}

export const DefaultButton = forwardRef<HTMLButtonElement, DefaultButtonProps>(
  (props, ref) => {
    const { label, variant = 'secondary', size = 'md', ...rest } = props

    const sizesBySize = useMemo((): Record<Sizes, ButtonProps> => {
      return {
        sm: {
          height: '30px',
        },
        md: {
          height: '42px',
        },
        mds: {
          height: '38px',
        },
        lg: {
          height: '48px',
        },
      }
    }, [])

    const stylesByVariant = useMemo((): Record<Variants, ButtonProps> => {
      return {
        primary: {
          p: '12px 16px',
          backgroundColor: '#302F37',
          _hover: { border: '1px solid #E56000' },
          _active: { backgroundColor: 'inherit' },
          borderRadius: '8px',
          fontFamily: 'Mulish',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '20px',
          color: '#FFFFFF',
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        secondary: {
          p: '12px 16px',
          backgroundColor: '#E56000',
          color: '#FFF',
          _hover: {
            background: '#ff6b00',
          },
          _active: {
            background: '#ff6b00',
          },
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '20px',
          borderRadius: '8px',
          fontFamily: 'Mulish',
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        ghost: {
          backgroundColor: 'inherit',
          borderRadius: '8px',
          height: '42px',
          fontFamily: 'Mulish',
          border: '1px solid #FFFFFF',
          color: '#FFFF',
          lineHeight: '20px',
          _hover: {
            background: 'inherit',
            border: '1px solid #E56000',
          },
          _active: {
            background: 'inherit',
          },
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        third: {
          background: 'linear-gradient(71.48deg, #1D1D1F 0%, #28272D 98.51%)',
          color: '#FFFF',
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: '600',
          borderRadius: '14px',
          border: '1px solid #FF8A00',
          _hover: {
            background: 'linear-gradient(71.48deg, #18181a 0%, #1e1e22 98.51%)',
          },
          _active: {
            background: 'linear-gradient(71.48deg, #1D1D1F 0%, #28272D 98.51%)',
          },
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        gradient: {
          background: 'linear-gradient(90deg, #FF8A00 0%, #FA5B01 98.51%)',
          color: '#FFFF',
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: '600',
          borderRadius: '8px',

          _hover: {
            filter: 'brightness(1.05)',
          },
          _active: {
            filter: 'brightness(1.05)',
          },
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        rainbow: {
          w: 'full',
          background: '#28272d',
          borderRadius: '8px',
          fontFamily: 'Mulish',
          color: '#FFF',
          lineHeight: '20px',
          transition: 'none',
          _hover: {
            background:
              'linear-gradient(120deg, #F23171 20%, #A743E7 45%, #9747FF 50%, #9F4AE4 55%, #E56000 80%)',
          },
          _active: {
            background: 'inherit',
          },
          _focus: {
            outline: 'none',
          },
        },
      }
    }, [])

    return (
      <Button
        ref={ref}
        data-qa={props.name}
        {...stylesByVariant[variant]}
        {...sizesBySize[size]}
        {...rest}
      >
        {label}
      </Button>
    )
  },
)
