import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Payload {
  date: Date | [Date, Date] | null
}

interface Params extends Payload {
  id: number
}

async function addHoursProgrammedStudies({ id, date }: Params) {
  await api.patch(`app/pending_studies/${id}`, { date })
}

export function useAddHoursProgrammedStudies() {
  return useMutation(addHoursProgrammedStudies, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-recovery-plan-studies'])
    },
  })
}
