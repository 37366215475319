import { useQuery } from '@tanstack/react-query'
import { Test } from 'features/dashboard/type'
import api from 'services/api'

interface Params {
  page: number
  limit: number
}

export interface TestsApiResponse {
  data: Test[]
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}

const getFetchTests = async ({
  page,
  limit,
}: Params): Promise<TestsApiResponse> => {
  const { data } = await api.get<TestsApiResponse>(
    `/app/tests?page=${page}&limit=${limit}`,
  )
  return data
}
export function useFetchTests(Params: Params) {
  return useQuery(['dashboardTests', Params], () => getFetchTests(Params), {
    refetchOnWindowFocus: false,
  })
}
