import { Flex, TableRowProps, Td, Text, Tooltip, Tr } from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import { capitalize, isEmpty } from 'lodash'
import React from 'react'
interface TrTablePrevalenceProps extends TableRowProps {
  prevalence: string | null
  area: string
  theme: string
  backgroundTable?: string
}

export function TrTablePrevalence(props: TrTablePrevalenceProps) {
  const { prevalence, area, backgroundTable, theme, ...rest } = props

  return (
    <>
      <Tr
        pb="1rem"
        backgroundColor={backgroundTable}
        verticalAlign={'baseline'}
        {...rest}
      >
        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
        >
          <Flex alignSelf={'center'}>
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              {prevalence && `${Number(prevalence).toFixed(2)}%`}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {area?.length > 11
              ? area && (
                  <Tooltip label={capitalize(area)}>
                    {letterCounter(capitalize(area), 11)}
                  </Tooltip>
                )
              : area && capitalize(area)}
          </Text>

          {(isEmpty(area) || !area) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            <Tooltip label={capitalize(theme)} hasArrow>
              <Text>{letterCounter(capitalize(theme), 16)}</Text>
            </Tooltip>
          </Text>

          {(isEmpty(theme) || !theme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>
      </Tr>
    </>
  )
}
