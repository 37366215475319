import { Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useAuth } from 'contexts/auth'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import Swal from 'sweetalert2'
import { avatarSchema } from './schema'
import { AvatarContainer, AvatarImage, Form } from './styles'

interface AvatarForm {
  avatar: File
}

export const AvatarTab = () => {
  /*
  |-----------------------------------------------------------------------------
  | Contexts.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user, updateUserData } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AvatarForm>({
    resolver: yupResolver(avatarSchema),
  })
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isPostingNewAvatar, setIsPostingNewAvatar] = useState(false)
  const [isUpdateAvatarEnabled, setIsUpdateAvatarEnabled] = useState(() =>
    user?.avatar?.url ? false : true,
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fileSubmitHandler = useCallback(
    async (filePayload: any) => {
      const fileMultipartFormData = new FormData()
      fileMultipartFormData.append('avatar', filePayload?.avatar[0])

      setIsPostingNewAvatar(true)

      await api
        .post('app/avatars', fileMultipartFormData)
        .then(() => {
          Swal.fire({
            title: 'Sucesso',
            html:
              '<p style="text-align:center">Foto de perfil atualizada com sucesso!</p>',
            icon: 'success',
            buttonsStyling: false,
            timer: 2000,
          }).then(() => {
            updateUserData()
            setIsUpdateAvatarEnabled(false)
          })
        })
        .catch(err => {
          console.trace('Error uploading avatar image. ', err)
          Swal.fire({
            title: 'Aviso',
            html:
              '<p style="text-align:center">Não foi possível atualizar a sua foto de perfil agora.</p>',
            icon: 'error',
            buttonsStyling: false,
          })
        })
        .finally(() => setIsPostingNewAvatar(false))
    },
    [updateUserData],
  )

  return (
    <>
      {user?.avatar?.url && !isUpdateAvatarEnabled && (
        <AvatarContainer>
          <AvatarImage width="256" height="256" src={user?.avatar?.url} />
          <DefaultButton
            maxW={'150px'}
            label="Alterar"
            type="button"
            onClick={() => setIsUpdateAvatarEnabled(true)}
          />
        </AvatarContainer>
      )}
      {isUpdateAvatarEnabled && (
        <Form onSubmit={handleSubmit(fileSubmitHandler)}>
          <input
            type="file"
            {...register('avatar', {
              required: 'Selecione um arquivo de imagem',
            })}
            onChange={e => {
              const file = e?.target?.files?.[0]
              return file
            }}
          />

          {errors?.avatar && (
            <Text color={'red.500'}>{errors?.avatar?.message}</Text>
          )}

          <DefaultButton
            maxW={'150px'}
            label="Salvar"
            type="submit"
            isLoading={isPostingNewAvatar}
          />
        </Form>
      )}
    </>
  )
}
