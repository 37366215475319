import { format } from 'date-fns'
import { map } from 'lodash'
import * as yup from 'yup'

export const schema = yup.object().shape({
  fieldId: yup
    .number()
    .transform(
      (value, originalValue) =>
        originalValue?.value && Number(originalValue?.value),
    )
    .typeError('Área obrigatória.')
    .required('Área obrigatória.'),

  themeId: yup
    .number()
    .nullable()
    .transform(
      (value, originalValue) =>
        originalValue?.value && Number(originalValue?.value),
    )
    .required('Por favor preencha o tema.'),

  subjectIds: yup
    .array()
    .nullable()
    .transform(item => item && map(item, 'value')),

  date: yup
    .string()
    .transform(item => item && format(new Date(item), 'dd/MM/yyyy'))
    .required('Por favor preencha a data que foi estudado o tema.')
    .typeError('Por favor preencha a data que foi estudado o tema.'),

  studiedMinutes: yup
    .number()
    .typeError('O tempo de estudo diário deve ser maior que 5 minutos.')
    .positive()
    .max(
      60 * 24,
      `O tempo de estudo diário deve ser menor que ${60 * 24} minutos.`,
    )
    .min(5, 'O tempo de estudo diário deve ser maior que 5 minutos.')
    .required('Por favor preencha o tempo de estudo.'),

  isTheoreticalOnly: yup
    .boolean()
    .transform(value => (value === 'theoretical' ? true : false))
    .required('Por favor preencha o tipo de estudo.'),

  numberOfQuestions: yup.number().when('isTheoreticalOnly', {
    is: false,
    then: sch =>
      sch
        .required('Por favor preencha o numero de questões.')
        .typeError('O numero de questões deve ser maior que 1 questão.')
        .positive('O numero de questões deve ser maior que 1 questão.'),
  }),

  numberOfCorrectAnswers: yup.number().when('isTheoreticalOnly', {
    is: false,
    then: sch =>
      sch
        .required('Por favor preencha o numero de questões corretas.')
        .min(1, 'O numero de questões certas deve ser maior que 0.')
        .typeError('O numero de questões certas deve ser maior ou igual a 0.')
        .max(
          yup.ref('numberOfQuestions'),
          'O número de questões certas deve ser inferior ao numero de questões.',
        ),
  }),

  isCreateAgenda: yup
    .boolean()
    .nullable()
    .when('isTheoreticalOnly', {
      is: false,
      then: yup
        .boolean()
        .nullable()
        .required('Por favor selecione se deseja criar revisão inteligente'),
    }),

  effortPerception: yup
    .number()
    .required('Por favor selecione a percepção de esforço')
    .min(1, 'A percepção de esforço deve ser maior ou igual a 1')
    .max(10, 'A percepção de esforço deve ser menor ou igual a 10'),
})
