import React from 'react'
import { Grid, useBreakpointValue } from '@chakra-ui/react'

import HitPercentageMobile from './HitPercentage/HitPercentageMobile'
import HitPercentage from './HitPercentage/HitPercentage'
import HoursStudy from './HoursStudy/HoursStudy'
import HoursStudyMobile from './HoursStudy/HoursStudyMobile'
import TheoreticalPracticalMobile from './ TheoreticalPractical/TheoreticalPracticalMobile'
import TheoreticalPractical from './ TheoreticalPractical/TheoreticalPractical'

export interface MajorStatsProps {
  percentCorrectAnswers: number
  percentCorrectQuestionsToReachTheGoal: number
  percentStudiedHoursReached: number
  percentRemainingHoursToReachTheGoal: number
  percentStudiedMinutesPractical: number
  percentStudiedMinutesTheorycal: number
  totalStudiedHoursPratical: number
  totalStudiedHoursTheorycal: number
  correct_answers_percentage: number
  study_minutes: number
  currentWeekSelected: number
  totalMinutesToReachTheGoal: number
}

export default function MajorStats({
  percentCorrectAnswers,
  percentCorrectQuestionsToReachTheGoal,
  percentStudiedHoursReached,
  percentRemainingHoursToReachTheGoal,
  percentStudiedMinutesPractical,
  percentStudiedMinutesTheorycal,
  totalStudiedHoursPratical,
  totalStudiedHoursTheorycal,
  correct_answers_percentage,
  study_minutes,
  currentWeekSelected,
  totalMinutesToReachTheGoal,
}: MajorStatsProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      alignSelf="center"
      w="100%"
      maxW="430px"
      templateColumns={{ base: '1fr', sm: 'repeat(3,1fr)' }}
      borderRadius="4px"
      gap={{ base: '8px', sm: 'unset' }}
    >
      {isSm ? (
        <HitPercentageMobile
          percentCorrectAnswers={percentCorrectAnswers}
          percentCorrectQuestionsToReachTheGoal={
            percentCorrectQuestionsToReachTheGoal
          }
          correct_answers_percentage={correct_answers_percentage}
          currentWeekSelected={currentWeekSelected}
        />
      ) : (
        <HitPercentage
          percentCorrectAnswers={percentCorrectAnswers}
          percentCorrectQuestionsToReachTheGoal={
            percentCorrectQuestionsToReachTheGoal
          }
          correct_answers_percentage={correct_answers_percentage}
          currentWeekSelected={currentWeekSelected}
        />
      )}

      {isSm ? (
        <HoursStudyMobile
          percentRemainingHoursToReachTheGoal={
            percentRemainingHoursToReachTheGoal
          }
          percentStudiedHoursReached={percentStudiedHoursReached}
          study_minutes={study_minutes}
          totalMinutesToReachTheGoal={totalMinutesToReachTheGoal}
          currentWeekSelected={currentWeekSelected}
        />
      ) : (
        <HoursStudy
          percentRemainingHoursToReachTheGoal={
            percentRemainingHoursToReachTheGoal
          }
          percentStudiedHoursReached={percentStudiedHoursReached}
          study_minutes={study_minutes}
          totalMinutesToReachTheGoal={totalMinutesToReachTheGoal}
          currentWeekSelected={currentWeekSelected}
        />
      )}

      {isSm ? (
        <TheoreticalPracticalMobile
          percentStudiedMinutesPractical={percentStudiedMinutesPractical}
          percentStudiedMinutesTheorycal={percentStudiedMinutesTheorycal}
          totalStudiedHoursPratical={totalStudiedHoursPratical}
          totalStudiedHoursTheorycal={totalStudiedHoursTheorycal}
        />
      ) : (
        <TheoreticalPractical
          percentStudiedMinutesPractical={percentStudiedMinutesPractical}
          percentStudiedMinutesTheorycal={percentStudiedMinutesTheorycal}
          totalStudiedHoursPratical={totalStudiedHoursPratical}
          totalStudiedHoursTheorycal={totalStudiedHoursTheorycal}
        />
      )}
    </Grid>
  )
}
