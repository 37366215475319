import React, { useCallback } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/auth'

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean
  notAllowed?: boolean
  component: React.ComponentType
}

const CustomRoute: React.FC<CustomRouteProps> = ({
  isPrivate = false,
  component: Component,
  notAllowed,
  ...rest
}) => {
  const {
    isLoggedIn,
    userContract,
    userIsOnboardingRequired,
    isLoading,
    isPreOnboardingRequired,
  } = useAuth()

  const renderRule = useCallback(() => {
    // Auth Loading
    if (isLoading) return <p>Carregando ...</p>

    // pre-onboarding Seren 2024 users
    if (
      isLoggedIn &&
      isPreOnboardingRequired() &&
      !userContract &&
      userIsOnboardingRequired &&
      rest.path !== '/pre-onboarding'
    ) {
      return <Redirect to={{ pathname: '/pre-onboarding' }} />
    }

    // Enforce user contract reset to do onboarding
    if (
      isLoggedIn &&
      !isPreOnboardingRequired() &&
      !userContract &&
      userIsOnboardingRequired &&
      rest.path !== '/onboarding'
    )
      return <Redirect to={{ pathname: '/onboarding' }} />

    // Enforce user dont access onboarding page when having contract
    if (
      isLoggedIn &&
      !userIsOnboardingRequired &&
      (rest.path === '/onboarding' || rest.path === '/pre-onboarding')
    )
      return <Redirect to={{ pathname: '/' }} />

    // not allowed
    if (isLoggedIn && notAllowed) {
      return <Redirect to={{ pathname: '/home' }} />
    }

    // Logged and accessing private page
    if (isPrivate === isLoggedIn) return <Component />

    // Redirect if none condition above is satisfied
    return <Redirect to={{ pathname: isPrivate ? '/' : '/home' }} />
  }, [
    isLoading,
    isLoggedIn,
    isPreOnboardingRequired,
    userContract,
    userIsOnboardingRequired,
    rest.path,
    notAllowed,
    isPrivate,
    Component,
  ])

  return <Route {...rest} render={renderRule} />
}

export default CustomRoute
