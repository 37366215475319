import { useMutation } from '@tanstack/react-query'
import api from 'services/api'

interface PayloadGeneratePlan {
  availableStudyMinutes: number
  theoreticalPendingStudiesIds: number[]
}

interface Params {
  id: number
  payload: PayloadGeneratePlan
}

async function GeneratePlan({ id, payload }: Params) {
  await api.post(`/app/recovery_plan/${id}/active`, {
    ...payload,
  })
}

export function useMutationGeneratePlan() {
  return useMutation(GeneratePlan)
}
