import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  chakra,
  Circle,
  HStack,
  Text,
  Spinner,
  useDisclosure,
  VStack,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { ProgressBar } from 'pages/RecoveryPlan/components/ProgressBar'
import { ModalDrawerRevisions } from './Drawer/DrawerRevisions'
import { DrawerCard } from './Drawer/DrawerCard'
import CustomSearchSelect from './components/CustomSearchSelect'
import { useProjectXProgressBar } from './queries/use-get-progress-bar'
import { useGetFocusLearning } from './queries/use-get-focus-learning'
import Pagination from 'components/Pagination'
import { chunk, isEmpty } from 'lodash'
import { NoDataTableError } from 'components/Table/StepOneRecoveryPlanTable/NoDataTableError'
import { useToastMessage } from 'components/Toast'
import { useAddFieldIdFocusLearning } from './queries/use-mutation-focus-lerarning-field-id'
import { AxiosError } from 'axios'

interface Option {
  label: string
  value: number
}
export const Activated = () => {
  /*
  |-----------------------------------------------------------------------------
  | State
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [page, setPage] = useState(1)
  const [fieldId, setFieldId] = useState<Option>()

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    data: progressBarData,
    isLoading: isProgressBarLoading,
    isError: progressBarError,
  } = useProjectXProgressBar()

  const showToast = useToastMessage()

  const { data, isLoading, isError } = useGetFocusLearning({
    fieldId: fieldId?.value !== -1 ? fieldId?.value : undefined,
  })

  const addFieldIdFocusLearningMutate = useAddFieldIdFocusLearning()

  /*
  |-----------------------------------------------------------------------------
  | Memo
  |-----------------------------------------------------------------------------
  |
  |
  */

  const focusLearningData = useMemo(() => {
    return chunk(data, 8)
  }, [data])

  /*
  |-----------------------------------------------------------------------------
  | Callbacks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const addFieldIdFocusLearning = useCallback(
    async (value: number) => {
      try {
        await addFieldIdFocusLearningMutate.mutateAsync({
          themeId: value,
        })

        showToast({
          title: 'Sucesso',
          description: 'Estudo adicionado.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível adicionar o estudo.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [addFieldIdFocusLearningMutate, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <VStack spacing={'0px'} align={'flex-start'}>
        <HStack my="8px">
          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            color="#BDBDC7"
          >
            Status:
          </Text>

          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            color="#FFFFF"
          >
            Ativado
          </Text>

          <Circle size={'8px'} bg={'#0DDF15'} />
        </HStack>

        <Text
          fontFamily="Mulish"
          fontWeight="400"
          fontSize="16px"
          color="#D3D3D8"
        >
          Para gerar as sugestões de estudos, acesse a página{' '}
          <chakra.span color="#FFFF" fontWeight={600}>
            Revisões Inteligentes
          </chakra.span>{' '}
          e clique no botão{' '}
          <chakra.span color="#FFFF" fontWeight={600}>
            “gerar revisões”
          </chakra.span>
          .
        </Text>
      </VStack>

      <Stack
        spacing={'24px'}
        my="24px"
        direction={{ base: 'column', sm: 'row' }}
      >
        <DefaultButton
          label="Próximas revisões X"
          onClick={onOpen}
          variant="primary"
          maxW={{ base: 'unset', sm: '220px' }}
          w="100%"
          iconSpacing={'10px'}
          leftIcon={
            <Icon color="#E56000" fontSize={'16px'} icon={'eva:list-fill'} />
          }
        />
      </Stack>

      <Box maxW={'550px'} w="100%">
        {isProgressBarLoading && <Spinner size="xl" color="brand.500" />}

        {progressBarError && !isProgressBarLoading && (
          <Text>Erro ao carregar Projeto-X</Text>
        )}

        {progressBarData && !progressBarError && !isProgressBarLoading && (
          <ProgressBar
            title="Progressão revisão X"
            concluded={progressBarData.percentageOfCompletedThemes}
            inProgress={progressBarData.percentageOfThemesInProgress}
            rest={progressBarData.percentageOfPendingThemes}
            total={100}
            isLoading={false}
          />
        )}
      </Box>

      <ModalDrawerRevisions isOpen={isOpen} onClose={onClose}>
        <CustomSearchSelect
          onChange={selected => {
            setPage(1)
            setFieldId(selected as Option)
          }}
          value={fieldId}
        />

        <VStack
          mt="1rem"
          spacing={'4px'}
          w={'100%'}
          minW={'300px'}
          maxW={'610px'}
        >
          {!isError &&
            !isLoading &&
            focusLearningData?.[page - 1]?.map((item, key) => (
              <DrawerCard
                key={`Drawer-project-x-${key}`}
                area={item.field}
                theme={item.theme}
                onClickAddButton={() => addFieldIdFocusLearning(item.themeId)}
              />
            ))}

          {isError && !isLoading && (
            <Flex maxH="350px" h="100%" w="100%" justifyContent={'center'}>
              <NoDataTableError
                w="100%"
                h="100%"
                headerMessage="Nenhum estudo cadastrado!"
                message="Erro ao carregar os estudos."
              />
            </Flex>
          )}

          {isEmpty(data) && !isError && !isLoading && (
            <Flex maxH="350px" h="100%" w="100%" justifyContent={'center'}>
              <NoDataTableError
                w="100%"
                h="100%"
                headerMessage="Nenhum estudo cadastrado!"
                message="Não há revisões da grande área selecionada."
              />
            </Flex>
          )}

          {isLoading && (
            <Flex
              alignItems={'center'}
              minH="350px"
              h="100%"
              w="100%"
              justifyContent={'center'}
            >
              <Spinner
                size="xl"
                color="brand.500"
                thickness="4px"
                speed="0.65s"
              />
            </Flex>
          )}
        </VStack>

        <Pagination
          w="100%"
          my="15px"
          currentPage={page}
          totalCountOfRegisters={focusLearningData?.reduce(
            (a, b) => a + b.length,
            0,
          )}
          onPageChange={setPage}
          registersPerPage={8}
        />
      </ModalDrawerRevisions>
    </>
  )
}
