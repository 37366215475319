import React from 'react'
import { IconType } from 'react-icons'

export const KillerConceptIcon: IconType = ({ size, color }) => (
  <svg
    width={'16px'}
    height={'16px'}
    fill={color}
    color={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4425 9.7355C10.8051 9.22943 11 8.62253 11 8C11 7.37747 10.8051 6.77057 10.4425 6.2645L11.5 5.207L12.1465 5.8535C12.2403 5.94724 12.3674 5.99989 12.5 5.99989C12.6326 5.99989 12.7597 5.94724 12.8535 5.8535L14.8535 3.8535C14.9472 3.75974 14.9999 3.63258 14.9999 3.5C14.9999 3.36742 14.9472 3.24026 14.8535 3.1465L12.8535 1.1465C12.7597 1.05276 12.6326 1.00011 12.5 1.00011C12.3674 1.00011 12.2403 1.05276 12.1465 1.1465L10.1465 3.1465C10.0528 3.24026 10.0001 3.36742 10.0001 3.5C10.0001 3.63258 10.0528 3.75974 10.1465 3.8535L10.793 4.5L9.7355 5.5575C9.22943 5.19495 8.62253 4.99999 8 4.99999C7.37747 4.99999 6.77057 5.19495 6.2645 5.5575L5 4.2925V1H1V5H4.293L5.5575 6.2645C5.19495 6.77057 4.99999 7.37747 4.99999 8C4.99999 8.62253 5.19495 9.22943 5.5575 9.7355L4.5 10.793L3.8535 10.1465C3.75974 10.0528 3.63258 10.0001 3.5 10.0001C3.36742 10.0001 3.24026 10.0528 3.1465 10.1465L1.1465 12.1465C1.05276 12.2403 1.00011 12.3674 1.00011 12.5C1.00011 12.6326 1.05276 12.7597 1.1465 12.8535L3.1465 14.8535C3.24026 14.9472 3.36742 14.9999 3.5 14.9999C3.63258 14.9999 3.75974 14.9472 3.8535 14.8535L5.8535 12.8535C5.94724 12.7597 5.99989 12.6326 5.99989 12.5C5.99989 12.3674 5.94724 12.2403 5.8535 12.1465L5.207 11.5L6.2645 10.4425C6.77057 10.8051 7.37747 11 8 11C8.62253 11 9.22943 10.8051 9.7355 10.4425L11 11.7075V15H15V11H11.707L10.4425 9.7355ZM12.5 2.207L13.793 3.5L12.5 4.793L11.207 3.5L12.5 2.207ZM3.5 13.793L2.207 12.5L3.5 11.207L4.793 12.5L3.5 13.793ZM4 4H2V2H4V4ZM6 8C6 7.60444 6.1173 7.21776 6.33706 6.88886C6.55682 6.55996 6.86918 6.30362 7.23463 6.15224C7.60009 6.00087 8.00222 5.96126 8.39018 6.03843C8.77814 6.1156 9.13451 6.30608 9.41421 6.58579C9.69392 6.86549 9.8844 7.22186 9.96157 7.60982C10.0387 7.99778 9.99913 8.39991 9.84776 8.76537C9.69638 9.13082 9.44004 9.44318 9.11114 9.66294C8.78224 9.8827 8.39556 10 8 10C7.46977 9.99934 6.96145 9.78841 6.58652 9.41348C6.21159 9.03855 6.00066 8.53023 6 8ZM12 12H14V14H12V12Z"
      fill={color}
    />
  </svg>
)
