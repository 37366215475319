import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
  VStack,
  Stack,
  chakra,
  Box,
  HStack,
} from '@chakra-ui/react'
import { capitalize, get, map } from 'lodash'
import { GetAgenda } from '../queries/use-get-agendas'
import { useToastMessage } from 'components/Toast'
import { useTrack } from 'contexts/track'
import { FieldError, useForm } from 'react-hook-form'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { RadioGroupController } from 'components/Fields/FieldRadioGroup/RadioGroupController'
import FieldInputMaskController from 'components/Fields/FieldMask/FieldInputMaskController'
import { FieldSwitchController } from 'components/Fields/FieldSwitch/FieldSwitchController'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { isBefore } from 'date-fns'
import {
  CreateDailyRecordRequest,
  findTheme,
  useCreateDailyRecord,
} from 'features/daily-records'
import { formatDateWithTimeZonedHours } from 'helpers/formatTimer'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'contexts/auth'
import Swal from 'sweetalert2'
import { FieldDateController } from 'components/Fields/FieldDateNew/FieldDateController'
import { FieldInputRegister } from 'components/Fields/FieldInputRegister/FieldInputRegister'
import { FieldRadio } from 'components/Fields/FieldRadio'

interface ModalConcludeStudiesProps {
  isOpen: boolean
  onClose: () => void
  agenda?: GetAgenda
  backOnClickFunc: () => void
}

interface FormConcludeInputStudies {
  fieldId: {
    label: string
    value: number
  } | null
  themeIds: {
    label: string
    value: number
  } | null
  subjectIds: Array<{
    label: string
    value: number
  }> | null
  isTheoreticalOnly: string
  date: Date | null
  hours: string
  minutes: string
  numberOfQuestions?: string
  numberOfCorrectAnswers?: string
  effortPerception: number
  isCreateAgenda?: boolean
}

interface FormConcludeOutputStudies {
  fieldId: number
  themeIds: number
  subjectIds: number[]
  isTheoreticalOnly: boolean
  date: string
  hours: number
  minutes: number
  studiedMinutes: number
  numberOfQuestions?: number
  numberOfCorrectAnswers?: number
  effortPerception: number
  isCreateAgenda?: boolean
}

export function ModalConcludeStudies({
  isOpen,
  agenda,
  backOnClickFunc,
  onClose,
}: ModalConcludeStudiesProps) {
  // HOOKS:
  const { track } = useTrack()
  const showToast = useToastMessage()
  const { userContract } = useAuth()

  const {
    watch,
    control,
    setValue,
    unregister,
    clearErrors,
    setFocus,
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormConcludeInputStudies>({
    defaultValues: {
      minutes: '0',
      hours: '0',
      numberOfCorrectAnswers: undefined,
      numberOfQuestions: undefined,
      effortPerception: 5,
    },
    resolver: yupResolver(schema),
  })

  // COST

  const shouldCreateSmartRevisionWatch = watch('isCreateAgenda')
  const isTheoreticalOnlyWatch = watch('isTheoreticalOnly')

  // MEMO

  const ClearForm = useMemo(() => {
    return {
      fieldId: null,
      themeIds: null,
      subjectIds: null,
      isTheoreticalOnly: undefined,
      date: null,
      hours: '0',
      minutes: '0',
      effortPerception: 5,
      numberOfCorrectAnswers: undefined,
      numberOfQuestions: undefined,
      isCreateAgenda: undefined,
    }
  }, [])

  // EFFECTS:

  useEffect(() => {
    if (!agenda?.subjects) return

    setValue(
      'subjectIds',
      agenda?.subjects?.map(subject => ({
        label: subject?.name,
        value: subject?.id,
      })),
    )
  }, [agenda, setValue, isOpen])

  useEffect(() => {
    if (!agenda) return

    const themeIds = map(agenda?.themes, 'id')

    if (themeIds) {
      themeIds.map(themeId => {
        findTheme({ themeId: themeId.toString() }).then(response => {
          if (!response) return
          const { field } = response

          setValue('fieldId', {
            label: field.name,
            value: field.id,
          })
        })
      })
    }
  }, [agenda, setValue, isOpen])

  useEffect(() => {
    if (!agenda) return

    if (agenda.type) {
      setValue(
        'isTheoreticalOnly',
        agenda.type === 'estudo-teorico' ? 'theoretical' : 'practical',
      )

      setValue(
        'isCreateAgenda',
        agenda.type === 'estudo-teorico' ? undefined : true,
      )
    }

    if (
      agenda?.type === '' &&
      Number(agenda?.metadata?.revisionNumber) >= 1 &&
      agenda.font === 'projeto-x'
    ) {
      setValue('isTheoreticalOnly', 'practical')
    }

    if (!agenda.type) {
      setValue('isCreateAgenda', true)
      setValue('isTheoreticalOnly', 'practical')
    }

    if (agenda.themes) {
      const theme = agenda?.themes[0]

      theme &&
        setValue('themeIds', {
          label: theme?.name,
          value: theme?.id,
        })
    }

    const { start } = agenda

    if (start) {
      const today = new Date()
      if (isBefore(start, formatDateWithTimeZonedHours(today))) {
        setValue('date', start)
      } else {
        setValue('date', formatDateWithTimeZonedHours(new Date()))
      }
    }

    if (agenda.studyTimeInMinutes && agenda.pending_study_id) {
      const studyTimeInMinutes = agenda.studyTimeInMinutes

      if (studyTimeInMinutes >= 60) {
        const hours = Math.floor(studyTimeInMinutes / 60)

        const minutes = studyTimeInMinutes % 60

        hours && setValue('hours', String(hours))
        minutes && setValue('minutes', String(minutes))
      }

      if (studyTimeInMinutes < 60 && studyTimeInMinutes > 0) {
        setValue('minutes', String(studyTimeInMinutes))
      }
    }
  }, [agenda, setValue, isOpen])

  useEffect(() => {
    if (isOpen === false) {
      reset(ClearForm)
      setValue('hours', '0')
      setCountWarningStudiedTime(0)
      setStudieTimeError('')
    }
  }, [ClearForm, isOpen, reset, setValue])

  useEffect(() => {
    if (isTheoreticalOnlyWatch !== 'practical') {
      unregister('isCreateAgenda')
      unregister('numberOfQuestions')
      unregister('numberOfCorrectAnswers')

      clearErrors('isCreateAgenda')
      clearErrors('numberOfQuestions')
      clearErrors('numberOfCorrectAnswers')
    }
  }, [isTheoreticalOnlyWatch, unregister, isOpen, clearErrors])

  // STATE
  const [studiedTimeError, setStudieTimeError] = useState('')
  const [countWarningStudiedTime, setCountWarningStudiedTime] = useState(0)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  // MUTATIONS:
  const createDailyRecord = useCreateDailyRecord()

  // CALLBACKS:

  const handleClose = useCallback(() => {
    reset(ClearForm)
    setCountWarningStudiedTime(0)
    setStudieTimeError('')
    onClose()
  }, [ClearForm, onClose, reset])

  const handleBackOnClick = () => {
    onClose()
    backOnClickFunc()
  }

  const onSubmit = useCallback(
    async (data: FormConcludeOutputStudies) => {
      if (
        data.hours * 60 + data.minutes > 300 &&
        countWarningStudiedTime === 0
      ) {
        setStudieTimeError(
          `Você colocou ${data.hours} horas e ${data.minutes} minutos, está correto? Se sim basta registrar o estudo.`,
        )
        setCountWarningStudiedTime(prev => prev + 1)
        return
      }

      if (!track || !userContract) return

      const request: CreateDailyRecordRequest = {
        date: data.date,
        trackId: track.id,
        fieldId: data.fieldId,
        themeIds: [data.themeIds],
        subjectIds:
          data.subjectIds && data.subjectIds.length > 0
            ? data.subjectIds
            : undefined,
        agendaId: Number(agenda?.id),
        contractId: userContract.id,
        isCreateAgenda: data.isCreateAgenda,
        studiedMinutes: data.hours * 60 + data.minutes,
        effortPerception: data.effortPerception,
        numberOfQuestions: data.numberOfQuestions,
        isTheoreticalOnly: data.isTheoreticalOnly,
        numberOfCorrectAnswers: data.numberOfCorrectAnswers,
      }

      if (data.isTheoreticalOnly) {
        let firstContactTime = 0

        const result = await Swal.fire({
          title: 'Primeiro contato',
          html:
            '<p style="text-align:center">Deseja ter o primeiro contato desse estudo em:</p>',
          showDenyButton: true,
          denyButtonColor: '#64b450',

          showCancelButton: true,
          cancelButtonText: 'Cancelar',

          confirmButtonText: '24 horas',
          denyButtonText: '48 horas',
        })

        if (result.isConfirmed) {
          firstContactTime = 24
        } else if (result.isDenied) {
          firstContactTime = 48
        }

        request.firstContactTime = firstContactTime
      }

      try {
        await createDailyRecord.mutateAsync(request)

        showToast({
          title: 'Sucesso',
          description: 'Estudo concluído.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (error) {
        showToast({
          title: 'Aviso',
          description: 'Não foi possível concluir o estudo.',
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      } finally {
        handleClose()
      }
    },
    [
      agenda?.id,
      countWarningStudiedTime,
      createDailyRecord,
      handleClose,
      showToast,
      track,
      userContract,
    ],
  )

  //RENDERS:
  if (!agenda) return null

  const { metadata } = agenda

  const revisionNumber = get(agenda.metadata, 'revisionNumber', '')
  const parserRevisionNumber = revisionNumber !== '' ? `${revisionNumber}` : ''

  let HeaderTitle = <></>

  if (
    metadata &&
    agenda.type === 'primeiro-contato' &&
    agenda.font === 'estudo-pendente'
  ) {
    HeaderTitle = (
      <Text>{`Primeiro Contato ${
        Number(revisionNumber) >= 1 ? ` - ${revisionNumber}` : ''
      }`}</Text>
    )
  }

  if (agenda.font === 'estudo-diario' && !!agenda?.themes?.[0]?.medcel_exam) {
    HeaderTitle = (
      <Text>{`C-TS - ${capitalize(agenda?.metadata?.field)} ${
        parserRevisionNumber ? ` - ${parserRevisionNumber}` : ''
      }`}</Text>
    )
  }

  if (
    metadata &&
    agenda.type === 'primeiro-contato' &&
    agenda.font === 'estudo-diario' &&
    !agenda?.themes?.[0]?.medcel_exam
  ) {
    HeaderTitle = <Text>{`Revisão Inteligente - ${parserRevisionNumber}`}</Text>
  }

  if (metadata && agenda.type === 'estudo-teorico') {
    HeaderTitle = <Text>{`Estudo Teórico - ${parserRevisionNumber}`}</Text>
  }

  if (metadata && agenda.font === 'projeto-x') {
    HeaderTitle = <Text>{`Revisão X - ${parserRevisionNumber}`}</Text>
  }

  if (
    metadata &&
    agenda.font === 'projeto-x' &&
    agenda.type === 'primeiro-contato'
  ) {
    HeaderTitle = <Text>{'Primeiro Contato X'}</Text>
  }

  // estudo - teorico
  // primeiro - contato

  return (
    <>
      <Modal id="conclude-studies" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          p="0px"
          background={'#302F37'}
          maxW={'375px'}
          w="100%"
          borderRadius={'8px'}
          overflow={'hidden'}
          transition={'all 0.2s ease-in-out'}
        >
          <ModalHeader
            background={'#2A2A30'}
            borderBottom={
              agenda.isDone ? '2px solid #0DDF15' : '2px solid #E5C009'
            }
            color={'#FFFF'}
            p="12px 16px"
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {HeaderTitle}
            <ModalCloseButton
              right={'unset'}
              top={'unset'}
              color={'#FFFF'}
              position={'relative'}
              _focus={{ outline: 'none' }}
            />
          </ModalHeader>

          <ModalBody
            color={'#FFFF'}
            background={'#302F37'}
            maxW={'375px'}
            w="100%"
            p="0px"
          >
            <VStack
              as="form"
              align={'flex-start'}
              onSubmit={handleSubmit(onSubmit)}
              flexDir={'column'}
              p="16px 16px 24px 16px"
              spacing={'1rem'}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '6px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#D9D9D9',
                  borderRadius: '17px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#60606C',
                  borderRadius: '17px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-corner': {
                  background: 'rgba(0,0,0,0)',
                },
              }}
            >
              <NewFieldSelectRegistryController
                control={control}
                options={
                  track?.fields?.map(field => {
                    return {
                      label: field?.name,
                      value: field?.id,
                    }
                  }) || []
                }
                label="Grande área"
                name="fieldId"
                placeholder="Selecione..."
                error={errors?.fieldId as FieldError}
                isDisabled
              />

              <NewFieldSelectRegistryController
                control={control}
                options={[]}
                label="Tema"
                name="themeIds"
                placeholder="Selecione..."
                error={errors?.themeIds as any}
                isDisabled
              />

              {agenda?.subjects && agenda?.subjects?.length > 0 && (
                <NewFieldSelectRegistryController
                  control={control}
                  options={[]}
                  label="Subtema"
                  isMulti={(agenda?.subjects?.length > 1) as any}
                  name="subjectIds"
                  placeholder="Selecione..."
                  isDisabled
                />
              )}

              <RadioGroupController
                name="isTheoreticalOnly"
                label="Tipo de estudo"
                placeholder="Selecione uma opção"
                defaultValue={'theoretical'}
                isDisabled={
                  !!agenda.type ||
                  (agenda?.type === '' &&
                    Number(agenda?.metadata?.revisionNumber) >= 1 &&
                    agenda.font === 'projeto-x')
                }
                control={control}
                display="flex"
                gap="24px"
                error={errors.isTheoreticalOnly}
                minW={'226px'}
              >
                <FieldRadio name="thereoretical" value={'theoretical'}>
                  Teórico
                </FieldRadio>
                <FieldRadio name="practical" value={'practical'}>
                  Prático
                </FieldRadio>
              </RadioGroupController>

              <Stack className="NewDatePicker">
                <FieldDateController
                  label="Data Início"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                  control={control}
                  maxDate={new Date()}
                  error={errors?.date}
                  name={'date'}
                  onCalendarOpen={() => setIsCalendarOpen(true)}
                  onCalendarClose={() => setIsCalendarOpen(false)}
                  dropdownMode="select"
                  placeholderText={'00/00/0000'}
                />
              </Stack>

              <Stack spacing={'8px'}>
                <Text
                  fontWeight="600"
                  fontSize="16px"
                  fontFamily={'Mulish'}
                  color={studiedTimeError ? '#E5C009' : '#FFFF'}
                >
                  Tempo de Estudo
                </Text>
                <HStack spacing="8px">
                  <Box
                    w={'100%'}
                    onClick={() => setFocus('hours')}
                    maxW="100px"
                    position={'relative'}
                  >
                    <chakra.span
                      style={{
                        position: 'absolute',
                        display: isCalendarOpen ? 'none' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        zIndex: 3,
                        right: 7,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      hora(s)
                    </chakra.span>
                    <FieldInputMaskController
                      mask={'99'}
                      maskPlaceholder=""
                      name="hours"
                      control={control}
                      placeholder={'00'}
                    />
                  </Box>

                  <Box
                    w={'100%'}
                    onClick={() => setFocus('minutes')}
                    maxW="115px"
                    position={'relative'}
                  >
                    <chakra.span
                      style={{
                        position: 'absolute',
                        display: isCalendarOpen ? 'none' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        zIndex: 3,
                        right: 7,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      minuto(s)
                    </chakra.span>
                    <FieldInputMaskController
                      mask={'99'}
                      maskPlaceholder=""
                      name="minutes"
                      control={control}
                      placeholder="00"
                    />
                  </Box>
                </HStack>

                {errors?.minutes?.message && (
                  <Text fontSize={'14px'} color={'red.500'}>
                    {errors?.minutes?.message}
                  </Text>
                )}

                {studiedTimeError && (
                  <Text fontSize={'14px'} color={'#E5C009'}>
                    {studiedTimeError}
                  </Text>
                )}
              </Stack>

              {isTheoreticalOnlyWatch === 'practical' && (
                <>
                  <HStack w="100%" align={'center'}>
                    <Stack w="54%">
                      <FieldInputMaskController
                        control={control}
                        name="numberOfQuestions"
                        label="Questões realizadas"
                        labelProps={{ letterSpacing: '0.005em' }}
                        maskPlaceholder=""
                        maxW={'68px'}
                        mask={'999'}
                        placeholder="00"
                        // error={errors.numberOfQuestions}
                      />
                    </Stack>

                    <Stack w="46%">
                      <FieldInputMaskController
                        control={control}
                        name="numberOfCorrectAnswers"
                        label="Questões corretas"
                        labelProps={{ letterSpacing: '0.005em' }}
                        maskPlaceholder=""
                        maxW={'68px'}
                        mask={'999'}
                        placeholder="00"
                        // error={errors.numberOfCorrectAnswers}
                      />
                    </Stack>
                  </HStack>
                  {errors?.numberOfCorrectAnswers?.message &&
                    !errors?.numberOfQuestions?.message && (
                      <Text fontSize={'14px'} color={'red.500'}>
                        {errors?.numberOfCorrectAnswers?.message}
                      </Text>
                    )}

                  {errors?.numberOfQuestions?.message && (
                    <Text fontSize={'14px'} color={'red.500'}>
                      {errors?.numberOfQuestions?.message}
                    </Text>
                  )}
                </>
              )}

              {isTheoreticalOnlyWatch === 'practical' && (
                <FieldSwitchController
                  control={control}
                  name="isCreateAgenda"
                  label="Criar revisão inteligente?"
                  error={errors.isCreateAgenda}
                  defaultChecked={true}
                  defaultValue={isTheoreticalOnlyWatch === 'practical' && true}
                >
                  {shouldCreateSmartRevisionWatch === true ? 'Sim' : 'Não'}
                </FieldSwitchController>
              )}

              <FieldInputRegister
                name="effortPerception"
                label={`Percepção de esforço: ${watch('effortPerception')}`}
                register={register}
                inputProps={{
                  min: 1,
                  max: 10,
                  type: 'range',
                  border: 'none',
                  maxW: '23.75rem',
                  _focus: {
                    boxShadow: 'none',
                  },
                }}
              />

              <VStack spacing={'10px'} w="full">
                <DefaultButton
                  label="Registrar estudo"
                  w="100%"
                  type="submit"
                  isLoading={isSubmitting}
                />
                <DefaultButton
                  onClick={handleBackOnClick}
                  label="Voltar"
                  variant="ghost"
                  w="100%"
                />
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
