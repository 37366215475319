import { useToken } from '@chakra-ui/react'
import React from 'react'
import ReactApexChart from 'react-apexcharts'

type LineAreaChartProps = {
  xaxis?: ApexXAxis
  yaxis?: ApexYAxis
  dataLabels?: ApexDataLabels
  options?: ApexCharts.ApexOptions
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries
}

export const LineAreaChart: React.FC<LineAreaChartProps> = ({
  xaxis,
  yaxis,
  series,
  options,
  dataLabels,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const gray200 = useToken('colors', 'gray.200')

  if (!series) return null

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <ReactApexChart
      series={series}
      type={'line'}
      height={'100%'}
      options={{
        chart: {
          foreColor: gray200,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            tools: {
              download: false,
            },
          },
          fontFamily: 'Mulish',
        },
        grid: {
          strokeDashArray: 2,
          padding: {
            bottom: 25,
            left: 25,
            right: 25,
            top: 25,
          },
        },
        legend: {
          show: false,
        },
        markers: {
          size: 1,
        },
        tooltip: {
          theme: 'dark',
          enabled: true,
          x: {
            formatter: (_, opts) => {
              const seriesIndex = opts.seriesIndex
              const dataPointIndex = opts.dataPointIndex
              const title =
                opts.w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .tooltipTitle

              return title
            },
          },
          custom: function ({ dataPointIndex, w }) {
            return (
              '<div class="custom-tooltip" style="display: flex; flex-direction: column; gap: 1px; background-color:#1D1D1F; padding:10px">' +
              '<div class="first-element">' +
              //
              '<span class="title-1" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px">' +
              w?.globals?.seriesNames?.[0] +
              '</span>' +
              //
              '<span class="result-1" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px; margin-left:10px">' +
              w?.globals?.initialSeries?.[0]?.data?.[dataPointIndex] +
              '</span>' +
              //
              '</div>' +
              '<div class="second-element">' +
              //
              '<span class="title-2" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px">' +
              w?.globals?.seriesNames?.[1]?.split(' ')?.[0] +
              '</span>' +
              //
              '<span class="title-2-helper" style="font-family:Mulish; font-weight:600; font-size:16px; margin-left:3px; color:#E56000">' +
              w?.globals?.seriesNames?.[1]?.split(' ')?.[1] +
              '</span>' +
              //
              '<span class="result-2" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px; margin-left:10px">' +
              w?.globals?.initialSeries?.[1]?.data?.[dataPointIndex] +
              '</span>' +
              //
              '</div>' +
              '</div>' +
              '</div>' +
              '<div class="flex-rectangle"/>'
            )
          },
        },
        colors: ['#ffffff', '#FF6B00'],
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            colorStops: [
              [
                {
                  offset: 0,
                  color: '#FFF',
                  opacity: 1,
                },

                {
                  offset: 100,
                  color: '#FFF',
                  opacity: 1,
                },
              ],
              [
                {
                  offset: 0,
                  color: 'rgba(255, 195, 152, 0.6)',
                },
                {
                  offset: 100,
                  color: 'rgba(181, 76, 0, 0.6)',
                },
              ],
            ],
          },
        },
        dataLabels: {
          enabled: false,
          ...dataLabels,
        },
        yaxis: {
          ...yaxis,
        },
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },

          ...xaxis,
        },
        ...options,
      }}
    />
  )
}
