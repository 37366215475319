import { useMutation } from '@tanstack/react-query'
import { User } from 'contexts/auth/interfaces'
import api from 'services/api'

async function mutateSetCurrentStep({
  onboardCurrentStep,
}: {
  onboardCurrentStep: number
}) {
  if (!onboardCurrentStep) return

  return await api.put<User>('/app/users', {
    onboardCurrentStep,
  })
}

export function useMutateSetCurrentStep() {
  return useMutation(mutateSetCurrentStep)
}
