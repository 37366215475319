import React, { useCallback, useEffect, useState } from 'react'
import { upperCase, groupBy, orderBy } from 'lodash'
import Swal from 'sweetalert2'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Contexts
import { useAuth } from 'contexts/auth'

// Services
import api from 'services/api'

// Types
import { ReportAvailability, ReportAvailabilityGroup } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { Container, ChipsContainer, Chip, ChipText } from './styles'
import { useHistory } from 'react-router-dom'
import VideoOrientation from 'components/VideoOrientation'
import { Flex, useBreakpointValue } from '@chakra-ui/react'

const Reports: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user } = useAuth()
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [availableGroups, setAvailableGroups] = useState<
    ReportAvailabilityGroup[]
  >([])
  const [isLoading, setIsLoading] = useState(true)
  const isMd = useBreakpointValue({ base: false, lg: true })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const generateStudentReport = useCallback(
    (trackGoal: ReportAvailability) => {
      if (!user) return

      const { name, id } = user
      const { id: trackGoalId } = trackGoal

      history.push({
        pathname: '/student/report',
        search: `?userName=${name}&userId=${id}&trackGoalId=${trackGoalId}`,
      })
    },
    [history, user],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    setIsLoading(true)
    api
      .get<ReportAvailability[]>('/app/reports/availability')
      .then(({ data }) => {
        const groupedData = groupBy(data, 'year')

        const orderedData = orderBy(
          Object.entries(groupedData).map(([key, value]) => ({
            year: key,
            availableReports: value,
          })),
          'year',
          'desc',
        )

        setAvailableGroups(orderedData)
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível buscar as incidências nesse momento',
          icon: 'error',
          buttonsStyling: false,
        })
        console.trace('Error on fetching incidences', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Relatórios"
          subTitle="Os seus relatórios de progresso"
          hasVideoOrientation={isMd}
          linkVideo="https://www.youtube.com/watch?v=6KIbuvaV02c"
        />
        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://www.youtube.com/watch?v=6KIbuvaV02c"
              mt="8px"
            />
          </Flex>
        )}

        {!isLoading &&
          availableGroups.map(({ year, availableReports }) => (
            <React.Fragment key={`group-key-${year}`}>
              <SectionTitle>{upperCase(year)}</SectionTitle>
              <ChipsContainer>
                {availableReports.map(trackGoal => (
                  <Chip
                    key={`month-key-${trackGoal.month}-${trackGoal.year}`}
                    onClick={() => generateStudentReport(trackGoal)}
                  >
                    <ChipText>{trackGoal.month}</ChipText>
                  </Chip>
                ))}
              </ChipsContainer>
            </React.Fragment>
          ))}
        {!availableGroups.length && !isLoading && (
          <>
            <SectionTitle>Relatórios</SectionTitle>
            <p>Nenhum arquivo disponível por enquanto. Volte mais tarde.</p>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default Reports
