import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  overflow: auto;

  max-width: 1440px;
  width: 100vw;

  max-height: 1024px;
  height: 100vh;

  background: #1a202c;
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);

  &::-webkit-scrollbar {
    background: #2a2a2d;
    width: 8px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(223, 224, 235, 0.06);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(223, 224, 235, 0.6);
    border-radius: 16px;
  }

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;

    color: #a8a8a8;
  }

  /* Mobile */
  @media (max-width: 1100px) {
  }

  /* Latpot */
  @media (min-width: 1100px) {
  }

  /* Extra Large */
  @media (min-width: 1440px) {
    border-radius: 16px;
    max-height: min(calc(100vh - 64px), 1024px);
  }
`
