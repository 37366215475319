import React from 'react'
import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { Step } from 'features/pre-onboarding/components/Step/Step'
import { steps } from 'features/pre-onboarding/components/Step/data'
import logo from 'assets/images/new_logo.svg'
import { Logo } from 'components/Sidebar/styles'

interface InfoProps {
  currentStep: number
}

export default function Info({ currentStep }: InfoProps) {
  /*
  |-----------------------------------------------------------------------------
  | Hook.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      justify={'space-between'}
      flexDir={{ base: 'column', md: 'row' }}
      w="100%"
      alignItems={{ base: 'start', md: 'center' }}
      gap="34px"
    >
      {isMobile ? (
        <Text
          fontFamily="Mulish"
          fontSize={{ base: '24px', sm: '42px' }}
          lineHeight={{ base: '30px', sm: '60px' }}
          color="#FFFFFF"
          fontWeight={'bold'}
        >
          Mentoria Residência
        </Text>
      ) : (
        <Logo
          src={logo}
          width="226px"
          height="103px"
          alt="Logo da Mentoria Residência"
          draggable={false}
          shouldSidebarBeWide={true}
        />
      )}

      <Flex maxW={'530px'} alignItems={'center'}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            isActive={currentStep === index}
            isCompleted={currentStep > index}
            isFirstStep={index === 0}
            isLastStep={steps.length === index + 1}
          />
        ))}
      </Flex>
    </Flex>
  )
}
