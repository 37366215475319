import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputProps,
} from '@chakra-ui/react'
import { Control, Controller, FieldError } from 'react-hook-form'
import FieldInput, { FieldInputProps } from 'components/Fields/FieldInput'

type FieldInputRegisterProps = {
  name: string
  label?: string
  labelProps?: FormLabelProps
  error?: FieldError
  helperText?: string | React.ReactNode
  shouldLabelRed?: boolean
  inputProps?: InputProps
  control: Control<any>
  defaultValue?: any
} & FieldInputProps

export const FieldInputController: React.FC<FieldInputRegisterProps> = ({
  name,
  label,
  labelProps,
  error,
  helperText,
  defaultValue,
  shouldLabelRed,
  control,
  ...inputProps
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          textAlign="left"
          mr={'0px'}
          letterSpacing="0.01em"
          color={shouldLabelRed && error ? '#FF6363' : '#FFFFFF'}
          htmlFor={name}
          {...labelProps}
        >
          {shouldLabelRed && error ? `${label}*` : label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FieldInput id={name} {...inputProps} {...field} />
        )}
      />

      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
