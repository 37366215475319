export function minutesToHoursAndMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const hoursStr = hours < 10 ? '0' + hours : hours
  const minutesStr =
    remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes

  return `${hoursStr}h ${minutesStr}m`
}
