import React, { useCallback } from 'react'

import {
  Button,
  Popover,
  ButtonGroup,
  PopoverBody,
  PopoverArrow,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { UseMutationResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'

interface Props {
  id: number
  updateDeleted: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    {
      id: number
    },
    unknown
  >
}

export function PopoverDeletedRecoveryPlan({ id, updateDeleted }: Props) {
  const handleDeletedRecovery = useCallback(async () => {
    try {
      await updateDeleted.mutateAsync({ id })
    } catch (error: any) {
      const message =
        error?.response?.data?.errors
          ?.map((error: any) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar a recuperação'

      Swal.fire('Aviso', `<p style="text-align:center">${message}</p>`, 'error')
    }
  }, [id, updateDeleted])

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button size="sm" colorScheme="brand">
              Recuperar
            </Button>
          </PopoverTrigger>
          <PopoverContent color="blackAlpha.800">
            <PopoverHeader fontWeight="semibold">Confirmação</PopoverHeader>

            <PopoverArrow />

            <PopoverCloseButton />

            <PopoverBody>
              Ao continuar esse estudo voltará para listagem de estudos
              pendentes.
            </PopoverBody>

            <PopoverFooter d="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button variant="outline" onClick={onClose}>
                  Cancelar
                </Button>

                <Button
                  colorScheme="brand"
                  isLoading={updateDeleted.isLoading}
                  onClick={handleDeletedRecovery}
                >
                  Confirmar
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
