import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3333',
  headers: {
    cache: 'no-cache, no-store, must-revalidate',
    pragma: 'no-cache',
    Expires: '0',
  },
})

export default api
