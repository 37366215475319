import {
  Button,
  Flex,
  TableRowProps,
  Td,
  Text,
  Tooltip,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React from 'react'
import { Icon } from '@iconify/react'
import { capitalize, isEmpty } from 'lodash'
interface SubTheme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Theme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface TrTableFirstStepRecordProps extends TableRowProps {
  area: string
  theme: Theme[]
  subTheme: SubTheme[]
  studyType: boolean
  backgroundTable?: string
  onClickOpenRemove: () => void
}

export function TrTableFirstStepRecord(props: TrTableFirstStepRecordProps) {
  const {
    area,
    backgroundTable,
    studyType,
    onClickOpenRemove,
    subTheme,
    theme,
    ...rest
  } = props

  const isSm = useBreakpointValue({ base: true, sm: false })

  return (
    <>
      <Tr
        pb="1rem"
        backgroundColor={backgroundTable}
        verticalAlign={'baseline'}
        whiteSpace="nowrap"
        {...rest}
      >
        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          py="8px"
          lineHeight={'28px'}
          borderBottom="none"
        >
          <Flex alignSelf={'center'}>
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={!area ? '#BDBDC7' : '#FFFFFF'}
            >
              {area.length > 15 ? (
                <Tooltip label={capitalize(area)}>
                  {capitalize(letterCounter(area, 15))}
                </Tooltip>
              ) : (
                capitalize(area)
              )}
            </Text>

            {(isEmpty(area) || !area) && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
                lineHeight={'18px'}
                color={'#FFFFFF'}
              >
                -
              </Text>
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          py="8px"
          lineHeight={'28px'}
          borderBottom="none"
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {theme &&
            theme.map(item => capitalize(item.name)).join(' | ').length > 15 ? (
              <Tooltip
                label={theme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  theme.map(item => capitalize(item.name)).join(' | '),
                  15,
                )}
              </Tooltip>
            ) : (
              theme.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(theme) || !theme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="8px"
          lineHeight={'28px'}
          borderBottom="none"
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {subTheme &&
            subTheme.map(item => capitalize(item.name)).join(' | ').length >
              15 ? (
              <Tooltip
                label={subTheme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  subTheme.map(item => capitalize(item.name)).join(' | '),
                  15,
                )}
              </Tooltip>
            ) : (
              subTheme.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(subTheme) || !subTheme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          py="8px"
          lineHeight={'28px'}
          borderBottom="none"
        >
          <Flex justifyContent="center">
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              textAlign={'center'}
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              {studyType ? 'Teórico' : 'Prático'}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          py="8px"
          lineHeight={'28px'}
          borderBottom="none"
          onClick={isSm ? onClickOpenRemove : undefined}
        >
          <Flex justifyContent={'center'}>
            <Icon
              fontFamily="Mulish"
              onClick={onClickOpenRemove}
              icon={'tabler:trash'}
              fontSize="16px"
              color={'#FFFF'}
              cursor="pointer"
            />
          </Flex>
        </Td>
      </Tr>
    </>
  )
}
