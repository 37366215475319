import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

export interface Params {
  page: number
  font?: string
}

export interface GetDeletedAgendasResponseApi {
  id: number
  type: string
  font: string
  title: string
  user_id: number
  is_done: boolean
  theme_id: number
  starts_at: string
  finishes_at: string
  created_at: string
  updated_at: string
  is_deleted: boolean
  notes: string | null
  daily_record_id: number
  subject_id: string | null
  previous_agenda_id: string | null
  metadata: {
    field?: string
    theme?: string
    subject?: string
    themeProjectX?: string
    revisionNumber?: string
  } | null
  dailyRecord: DailyRecord
}

export interface DailyRecord {
  id: number
  date: string
  user_id: number
  field_id: number
  theme_id: number
  track_id: number
  created_at: string
  updated_at: string
  is_deleted: boolean
  contract_id: number
  studied_minutes: number
  subject_id: string | null
  effort_perception: number
  number_of_questions: number
  is_theoretical_only: boolean
  number_of_correct_answers: number
}

export interface GetDeletedAgendas {
  id: number
  startsAt: string
  title: string
}

export interface GetDeletedAgendasResponse {
  items: Array<GetDeletedAgendas>
  meta: MetaResponse
}

export async function getDeletedAgendas(
  params: Params,
): Promise<GetDeletedAgendasResponse> {
  const { data: agendas } = await api.get<{
    data: GetDeletedAgendasResponseApi[]
    meta: MetaResponseApi
  }>('/app/agendas/deleteds', { params: { limit: 10, ...params } })

  const output = agendas.data.map(agenda => ({
    id: agenda.id,
    title: agenda.title,
    startsAt: agenda.starts_at,
  }))

  return {
    items: output,
    meta: formatMeta(agendas.meta),
  }
}

export function useGetDeletedAgendas(params: Params) {
  return useQuery(['agendas', 'deleted', params], () =>
    getDeletedAgendas(params),
  )
}
