import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function updateDeleted({
  id,
  isDeleted,
}: {
  id: number
  isDeleted: boolean
}) {
  return await api.patch(`/app/tests/${id}/un_deleted`)
}

export function useUpdateTestsDeleted() {
  return useMutation(updateDeleted, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tests', 'deleted'])
    },
  })
}
