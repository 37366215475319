import { useQuery } from '@tanstack/react-query'
import { orderBy } from 'lodash'
import api from 'services/api'

type Question = {
  id: number
  statement: string
  alternative_a: string
  alternative_b: string
  alternative_c: string
  alternative_d: string
  alternative_e?: string
  correct_alternative: string
  comment: string
  mock_exam_id: number
  comment_image: string
  image_highlight: string
  created_at: string
  updated_at: string
}

export type GetExamById = {
  id: number
  title: string
  institution: string
  published: boolean
  created_at: string
  updated_at: string
  track_id: number
  exam_product_code: string
  questions: Question[]
}

type Params = {
  id: string | undefined
}

export async function getExamsById(
  params: Params,
): Promise<GetExamById | null> {
  const { id } = params

  if (!id) {
    return null
  }

  const { data } = await api.get<GetExamById>(`/app/mock-exams/${params.id}`)

  return {
    ...data,
    questions: orderBy(data.questions, ['id'], ['asc']),
  }
}

export function useGetExamsById(params: Params) {
  return useQuery(
    ['get-simulation-exams-by-id', params],
    () => getExamsById(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
