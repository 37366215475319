/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Flex,
  Box,
} from '@chakra-ui/react'

import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import FieldInputMaskController from 'components/Fields/FieldMask/FieldInputMaskController'
import { RadioGroupController } from 'components/Fields/FieldRadioGroup/RadioGroupController'
import { FieldRadio } from 'components/Fields/FieldRadio'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { PREP_SCHOOLS } from 'constants/prepSchools'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { useAuth } from 'contexts/auth'
import { INSTITUTIONS } from 'constants/institutions'
import { SelectDragNDropController } from 'components/Fields/SelectDragNDrop'
import { useGetFocusLearningInstitutions } from '../queries/use-get-no-data-institutions'
import { Icon } from '@iconify/react'
import { CustomTooltip } from 'components/Tooltip'
import { FormTestProfile } from '../types'
import { useTrack } from 'contexts/track'

interface ModalProjectXProfileProps {
  isOpen: boolean
  onClose: () => void
  closeModalProjectXAndOpenConfirmInstitutions: (
    selectedInstitutions: string[],
  ) => void
}

const ModalProjectXProfile = (props: ModalProjectXProfileProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useFormContext<FormTestProfile>()

  const { user } = useAuth()

  const { track } = useTrack()

  const {
    data: dataFocusLearningInstitutions,
    isError: isErrorFocusLearningInstitutions,
  } = useGetFocusLearningInstitutions()

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    isOpen,
    closeModalProjectXAndOpenConfirmInstitutions,
    onClose,
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Effect
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (user?.name) {
      const formatted = user?.name.split(' ')
      const firstName = formatted?.[0]
      const lastName = formatted?.slice(1).join(' ')

      if (firstName) {
        setValue('name', firstName)
      }

      if (lastName) {
        setValue('lastName', lastName)
      }
    }

    /* if (user?.profile?.prep_school?.[0]) {
      setValue('prepSchool', {
        label: user?.profile?.prep_school?.[0],
        value: user?.profile?.prep_school?.[0],
      })
    } */

    if (user?.cpf) {
      setValue('documentNumber', user?.cpf)
    }

    if (user?.profile?.intended_institutions) {
      setValue('intendedInstitutions', user?.profile?.intended_institutions)
    }
  }, [setValue, user])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isInsitutionSelectedHasntData = useMemo(() => {
    if (isErrorFocusLearningInstitutions || !dataFocusLearningInstitutions)
      return []

    const institutions = watch('intendedInstitutions')

    const selectedInstitutionsHasData = institutions?.filter(
      value => !dataFocusLearningInstitutions.includes(value),
    )

    return selectedInstitutionsHasData
  }, [
    dataFocusLearningInstitutions,
    isErrorFocusLearningInstitutions,
    watch('intendedInstitutions'),
  ])

  const hasOnlyInstitutionsNoData = useMemo(() => {
    if (isErrorFocusLearningInstitutions || !dataFocusLearningInstitutions)
      return []

    const institutions = watch('intendedInstitutions')

    const selectedInstitutionsHasData = institutions?.every(
      value => !dataFocusLearningInstitutions.includes(value),
    )

    return selectedInstitutionsHasData
  }, [
    dataFocusLearningInstitutions,
    isErrorFocusLearningInstitutions,
    watch('intendedInstitutions'),
  ])

  const validateFirstStep = async () => {
    const [
      nameValid,
      documentValid,
      intendedInstitutionsValid,
      alreadyGraduatedValid,
      lastNameValid,
      prepSchoolValid,
    ] = await Promise.all([
      trigger('name'),
      trigger('documentNumber'),
      trigger('intendedInstitutions'),
      trigger('alreadyGraduated'),
      trigger('lastName'),
      trigger('prepSchool'),
    ])

    if (
      nameValid &&
      documentValid &&
      intendedInstitutionsValid &&
      alreadyGraduatedValid &&
      lastNameValid &&
      prepSchoolValid
    ) {
      return true
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const prepSchoolsFilter = useMemo(() => {
    if (track?.name === 'R+ CGE') {
      return PREP_SCHOOLS.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }

    return PREP_SCHOOLS.filter(
      item => item !== 'MEDCOF 2024' && item !== 'RMED 2024',
    ).map(item => {
      return {
        label: item,
        value: item,
      }
    })
  }, [track?.name])

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isCentered isOpen={isOpen} size={'md'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w="100%"
        maxW={'510px'}
        style={{ backgroundColor: '#28272c' }}
        transition={'all 0.2s ease-in-out'}
      >
        <ModalHeader
          style={{
            color: '#fff',
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            paddingTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Atualização dos dados
          <BsXLg
            onClick={onClose}
            color="#FFFFF"
            cursor={'pointer'}
            fontSize={'18px'}
          />
        </ModalHeader>

        <ModalBody>
          <Flex gap={'16px'} as={'form'} pb="16px" flexDir={'column'}>
            <Stack columnGap={'16px'} direction={{ base: 'column', sm: 'row' }}>
              <FieldInputController
                h={'42px'}
                name="name"
                control={control}
                label="Nome"
                placeholder="Digite seu nome"
                error={errors.name}
                shouldLabelRed
              />

              <FieldInputController
                h={'42px'}
                name="lastName"
                control={control}
                label="Sobrenome"
                placeholder="Digite seu sobrenome"
                error={errors.lastName}
                shouldLabelRed
              />
            </Stack>

            <Stack columnGap={'16px'} direction={{ base: 'column', sm: 'row' }}>
              <FieldInputMaskController
                h={'42px'}
                control={control}
                shouldLabelRed
                label="CPF"
                name="documentNumber"
                placeholder="000.000.000-00"
                mask="999.999.999-99"
                maskPlaceholder={null}
                error={errors.documentNumber}
              />

              <NewFieldSelectRegistryController
                h={'42px'}
                control={control}
                label="Cursinho"
                options={prepSchoolsFilter}
                shouldLabelRed
                name="prepSchool"
                placeholder="Digite seu curso"
                error={errors.prepSchool as any}
              />
            </Stack>

            <RadioGroupController
              name="alreadyGraduated"
              label="Já sou formado?"
              placeholder="Selecione uma opção"
              control={control}
              display="flex"
              gap="24px"
              minW={'226px'}
              error={errors?.alreadyGraduated && errors?.alreadyGraduated}
            >
              <FieldRadio name="sim" value={'true'}>
                Sim
              </FieldRadio>
              <FieldRadio name="não" value={'false'}>
                Não
              </FieldRadio>
            </RadioGroupController>

            <SelectDragNDropController
              error={errors.intendedInstitutions as any}
              shouldLabelRed
              label="Onde você irá passar?"
              adicionalLabel={
                <Box>
                  <CustomTooltip
                    label={
                      'Escolha em quais instituições você irá passar, de acordo com a sua ordem de prioridade.'
                    }
                    backgroundColor={'#2A2A30'}
                    p="16px"
                    borderRadius={'4px'}
                    fontWeight={'600'}
                    fontSize={'14px'}
                  >
                    <Icon
                      icon="codicon:question"
                      fontSize={'18px'}
                      color="#E56000"
                    />
                  </CustomTooltip>
                </Box>
              }
              control={control}
              name="intendedInstitutions"
              options={INSTITUTIONS.map(institution => ({
                value: institution,
                label: institution,
              }))}
              filterInstitutions={isInsitutionSelectedHasntData}
              adicionalNode={
                <Box>
                  <CustomTooltip
                    label={
                      'Esta instituição ainda não dispõe de dados cadastrados, portanto, informações genéricas serão utilizadas.'
                    }
                    backgroundColor={'#2A2A30'}
                    p="16px"
                    borderRadius={'4px'}
                    fontWeight={'600'}
                    fontSize={'14px'}
                  >
                    <Icon
                      icon={'jam:alert'}
                      fontSize={'22px'}
                      color="#E56000"
                    />
                  </CustomTooltip>
                </Box>
              }
            />

            <Flex
              gap={'10px'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {isInsitutionSelectedHasntData?.length > 0 &&
              hasOnlyInstitutionsNoData ? (
                <DefaultButton
                  width={'100%'}
                  onClick={async () => {
                    if (
                      isInsitutionSelectedHasntData?.length > 0 &&
                      hasOnlyInstitutionsNoData &&
                      (await validateFirstStep())
                    ) {
                      return closeModalProjectXAndOpenConfirmInstitutions(
                        isInsitutionSelectedHasntData,
                      )
                    }
                  }}
                  label="Ativar Projeto X"
                  isLoading={isSubmitting}
                />
              ) : (
                <DefaultButton
                  width={'100%'}
                  type="submit"
                  label="Ativar Projeto X"
                  isLoading={isSubmitting}
                />
              )}

              <DefaultButton
                width={'100%'}
                label="Cancelar"
                variant="ghost"
                onClick={onClose}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalProjectXProfile
