import {
  HStack,
  InputGroup,
  InputRightElement,
  Tooltip,
  Text,
  IconButton,
  VStack,
  Stack,
} from '@chakra-ui/react'
import FieldInput from 'components/Fields/FieldInput'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import Pagination from 'components/Pagination'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import ModalSimulationReview from './ModalSimulationReview'
import {
  GetExams,
  useGetExams,
} from 'features/simulation/mutations/use-get-exams'
import { format } from 'date-fns'
import { useDebounce } from 'hooks/use-debounce'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { useDeleteExam } from 'features/simulation/mutations/use-delete-exam-by-id'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { useAuth } from 'contexts/auth'
import ModalTestTrial from './ModalSimulationTrial'

export default function SimulationTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [search, setSearch] = useState('')
  const ref = useRef<HTMLInputElement>(null)
  const [page, setPage] = useState(1)
  const [modalDeleteExams, setModalDeleteExams] = useState(false)
  const [modalConfirmDeleteExams, setModalConfirmDeleteExams] = useState(false)
  const [modalTestTrial, setModalTestTrial] = useState(false)
  const [orderBy, setOrderBy] = useState<'-created_at' | '+created_at'>(
    '-created_at',
  )
  const [simulationId, setSimulationId] = useState(0)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push } = useHistory()

  const debouncedSearch = useDebounce(search, 800)

  const { data: examsData, isLoading: examsLoading } = useGetExams({
    limit: 6,
    page: page,
    title: debouncedSearch ? debouncedSearch : undefined,
    sort: orderBy,
  })

  const deleteExamMutate = useDeleteExam()

  const showToast = useToastMessage()

  const { userContract, user } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Callbacks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDeleteExam = useCallback(
    async (id: number) => {
      try {
        await deleteExamMutate.mutateAsync({
          mockExamId: id,
        })

        showToast({
          title: 'Sucesso!',
          description: 'Deleção realizada com simulado.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        push(`/simulation/new/${id}`)
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível efetuar a exclusão simulado.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [deleteExamMutate, push, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: (
          <HStack>
            <Text>Ações</Text>
          </HStack>
        ),
        fn(_, value) {
          return (
            <HStack>
              <IconButton
                data-qa={
                  value.isFinished
                    ? `btc-finished-${value.id}`
                    : `btc-not-finished-${value.id}`
                }
                aria-label={'download pdf'}
                backgroundColor="#393940"
                borderRadius={'4px'}
                _hover={{
                  backgroundColor: '#393940c3',
                }}
                _active={{
                  backgroundColor: '#393940',
                }}
                w="26px"
                h="26px"
                minW={'26px !important'}
                onClick={() => {
                  if (value.isFinished) {
                    setModalDeleteExams(true)
                    setSimulationId(value.id)
                    return
                  }

                  setSimulationId(value.id)

                  return push(`/simulation/new/${value.id}`)
                }}
                p="0px"
                icon={
                  value.isFinished ? (
                    <Icon fontSize="16px" color="#0DDF15" icon="ps:check-box" />
                  ) : (
                    <Icon fontSize="16px" color="#E56000" icon="bx:file" />
                  )
                }
              />

              <ModalSimulationReview
                title={'Selecione um opção'}
                isOpen={modalDeleteExams}
                onClose={() => setModalDeleteExams(false)}
                onFirstClick={() => {
                  setModalConfirmDeleteExams(true)
                  setModalDeleteExams(false)
                }}
                onSecondClick={() => push(`/simulation/review/${simulationId}`)}
              />

              <ModalDefaultTwoButton
                subTitle="Essa ação irá acarretar na exclusão do simulado feito e início de um novo. Tem certeza que deseja escolher essa opção?"
                title="Confirmar exclusão"
                isOpen={modalConfirmDeleteExams}
                onClose={() => setModalConfirmDeleteExams(false)}
                onConfirmClick={async () => {
                  await handleDeleteExam(simulationId)
                }}
                modalOverlayProps={{
                  background: 'rgba(0, 0, 0, 0.1)',
                }}
                confirmButtonIsLoading={deleteExamMutate.isLoading}
              />
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign={'center'}>Título do simulado</Text>,
        fn(_, value) {
          return (
            <HStack
              textAlign={'center'}
              minW={'150px'}
              noOfLines={1}
              maxW={'380px'}
              w="100%"
            >
              <Tooltip
                w="100%"
                label={`${value?.title} - (${value?.institution})`}
                hasArrow
              >
                <Text
                  noOfLines={1}
                >{`${value?.title} - (${value?.institution})`}</Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">% de acertos</Text>,
        accessor: 'percentCorrectAnswers',
        fn(value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip w="100%" label={value.toFixed(2) ?? 0} hasArrow>
                <Text noOfLines={1}>{value.toFixed(2) ?? 0}</Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Questões</Text>,
        accessor: 'countQuestions',
        fn(value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip w="100%" label={value} hasArrow>
                <Text noOfLines={1}>{value}</Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify={'center'}>
            <Text textAlign="center">Data</Text>
            {orderBy === '-created_at' ? (
              <Icon
                cursor={'pointer'}
                onClick={() => setOrderBy('+created_at')}
                icon={'uil:sort-amount-down'}
              />
            ) : (
              <Icon
                cursor={'pointer'}
                onClick={() => setOrderBy('-created_at')}
                icon={'uil:sort-amount-up'}
              />
            )}
          </HStack>
        ),
        accessor: 'createdAt',
        fn(value) {
          return (
            <HStack w="100%" justifyContent={'center'}>
              <Tooltip
                label={!value ? '-' : format(new Date(value), 'dd-MM-yyyy')}
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value ? '-' : format(new Date(value), 'dd-MM-yyyy')}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
    ] as TableHeader<GetExams>[]
  }, [
    deleteExamMutate.isLoading,
    handleDeleteExam,
    modalConfirmDeleteExams,
    modalDeleteExams,
    orderBy,
    push,
    simulationId,
  ])

  useEffect(() => {
    if (
      user &&
      userContract?.type === 'TRIAL' &&
      user?.trial_modal_views <= 3
    ) {
      setModalTestTrial(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <VStack alignItems={'start'} py="2rem" w="100%" spacing={'2rem'}>
      <Stack w="100%">
        <InputGroup
          maxW={{ base: '100%', sm: '300px' }}
          w="100%"
          position={'relative'}
        >
          <FieldInput
            value={search}
            name="simulationTitle"
            paddingInlineEnd={'40px'}
            onChange={event => {
              setPage(1)
              setSearch(event.target.value)
            }}
            backgroundColor={'#302F37'}
            ref={ref}
            placeholder="Pesquisar por título do simulado"
          />

          <InputRightElement>
            <BiSearch color="#E56000" onClick={() => ref?.current?.focus()} />
          </InputRightElement>
        </InputGroup>
      </Stack>

      <List
        w="100%"
        background="#302F37"
        maxW={'911px'}
        px="20px"
        py="10px"
        my="0px"
      >
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={false}
          isFetching={false}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={examsData?.data}
          isLoading={examsLoading}
          tableCellProps={{
            border: 'none !important',
            p: '8px 12px',
            _notFirst: {
              paddingLeft: '12px',
            },
            borderRight: '1px solid #60606C !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '16px',
            textTransform: 'none !important',
            p: '8px',
            _notFirst: { paddingLeft: '12px' },
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={examsData?.meta?.total ?? 0}
          registersPerPage={6}
          fontSize="12px"
          mt="16px !important"
          px="8px"
          buttonProps={{
            fontSize: '12px',
            fontWeight: '600',
            minH: '6',
            h: '6',
            w: '6',
            minW: '6',
          }}
        />
      </List>

      <ModalTestTrial
        isOpen={modalTestTrial}
        onClose={() => setModalTestTrial(false)}
      />
    </VStack>
  )
}
