// Vendors
import React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import './styles.css'

// Components

// Styles
import { FormControlProps } from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'
import FieldInput, { FieldInputProps } from '../FieldInput'

// Interfaces
export type FieldDateProps = ReactDatePickerProps & {
  name: string
  containerProps?: FormControlProps
  inputProps?: FieldInputProps
  isRequired?: boolean
}

export const FieldDate: React.FC<FieldDateProps> = (
  props: FieldDateProps,
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    placeholderText,
    selected,
    containerProps,
    inputProps,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <DatePicker
      id={name}
      name={name}
      dateFormat="dd/MM/yyyy"
      customInput={<FieldInput transition={'none'} {...inputProps} />}
      selected={selected}
      placeholderText={placeholderText || 'Selecione a data...'}
      {...rest}
    />
  )
}
