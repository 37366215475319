import * as Yup from 'yup'

export const ValidateAccountSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres.')
    .required('A senha é obrigatória.'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas não batem.',
  ),
})
