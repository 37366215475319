import * as yup from 'yup'
export const schema = yup.object().shape({
  name: yup.string().required('O campo nome é obrigatório'),

  lastName: yup.string().required('O campo sobrenome é obrigatório'),

  prepSchool: yup
    .array()
    .of(yup.string().required('O campo escola de preparo é obrigatório'))
    .transform((value, originalValue) => {
      if (originalValue.value) {
        const formatted = [originalValue.value]
        return formatted
      }
    })
    .required('O campo cursinho é obrigatório'),

  documentNumber: yup
    .string()
    .transform((value, originalValue) => {
      if (originalValue) {
        const formatted = originalValue.replace(/\D/g, '').replace('-', '')
        return formatted
      }
    })
    .min(11, 'O campo deve conter 11 caracteres')
    .required('O campo CPF é obrigatório'),

  alreadyGraduated: yup.mixed().transform((value, originalValue) => {
    if (originalValue === 'true') {
      return true
    }
    return false
  }),

  intendedInstitutions: yup
    .array()
    .of(yup.string().required())
    .min(1, 'O campo é obrigatório')
    .required('O campo é obrigatório'),
})
