import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { IoMdClose } from 'react-icons/io'

interface DrawerRevisionsProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const ModalDrawerRevisions = (props: DrawerRevisionsProps) => {
  const { isOpen, onClose, children, ...rest } = props

  return (
    <Modal
      id="revision-project-x"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="scale"
    >
      <ModalOverlay h={'100%'} background={'rgba(0, 0, 0, 0.48)'} />

      <ModalContent
        background={'#302F37'}
        p="24px 16px 24px 24px"
        maxW="650px"
        w="100%"
      >
        <ModalHeader p="0px">
          <HStack w="100%" justifyContent={'space-between'}>
            <Text
              fontFamily="Mulish"
              fontWeight="600"
              fontSize="24px"
              lineHeight="30px"
              color="#FFFFFF"
            >
              Próximas Revisões X
            </Text>

            <IoMdClose
              onClick={onClose}
              size={'22px'}
              cursor="pointer"
              color="#FFFFFF"
            />
          </HStack>
        </ModalHeader>

        <ModalBody
          whiteSpace={'nowrap'}
          px="0px !important"
          fontFamily={'Mulish'}
          h="auto"
          maxH={'100%'}
          overflow={'auto'}
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#D9D9D9',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#E56000',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'rgba(0,0,0,0)',
            },
          }}
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
