import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export async function getFocusLearningInstitutions(): Promise<string[]> {
  const { data } = await api.get<string[]>('/app/focus_learning/institutions')

  return data
}

export function useGetFocusLearningInstitutions() {
  return useQuery(
    ['get-focus-learning-institutions'],
    () => getFocusLearningInstitutions(),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
