import { Flex, Text, Image, Button, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import RecoveryPlan from '../../assets/images/recovery-plan.png'
import { Container } from './styles'
import Layout from 'layouts/Logged'

export const RecoveryPlanInactivated = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hook
  |-----------------------------------------------------------------------------
  |
  |
  */
  const isSm = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Flex
          gap={{ base: '2rem', xl: '80px' }}
          m="0 auto"
          h="full"
          alignItems={'center'}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            flexDir="column"
            w="100%"
            h={{ base: 'unset', lg: '317px' }}
            maxW={'360px'}
          >
            <Text
              fontFamily="Mulish"
              fontWeight="700"
              fontSize="2rem"
              lineHeight="125%"
              color="#FFFFFF"
            >
              Plano de recuperação não está ativo!
            </Text>

            <Text
              fontFamily="Mulish"
              fontWeight="400"
              fontSize="1rem"
              lineHeight="150%"
              color="#BDBDC7"
              mt="1rem"
            >
              Ative o plano de recuperação e crie seu plano.
            </Text>

            <Link to={'/create-plan'}>
              <Button
                display={{ base: 'none', sm: 'flex' }}
                p="12px 24px"
                color="#FFF"
                borderRadius={'8px'}
                background="#E56000"
                _hover={{ backgroundColor: '#ff6b00' }}
                _active={{ backgroundColor: '#E56000' }}
                w="fit-content"
                mt="30px"
              >
                Voltar
              </Button>
            </Link>
          </Flex>

          <Flex maxH="360px" maxW="437px">
            <Image
              minW={{ base: 'unset', xl: '360px' }}
              w="full"
              h="full"
              objectFit={'cover'}
              src={RecoveryPlan}
            />
          </Flex>

          {isSm && (
            <Flex justifyContent={'flex-start'} w="100%">
              <Link to={'/recovery-plan'}>
                <Button
                  display={{ base: 'flex', sm: 'none' }}
                  p="12px 24px"
                  color="#FFF"
                  borderRadius={'8px'}
                  background="#E56000"
                  _hover={{ backgroundColor: '#ff6b00' }}
                  _active={{ backgroundColor: '#E56000' }}
                  w="fit-content"
                  mt="30px"
                >
                  Voltar
                </Button>
              </Link>
            </Flex>
          )}
        </Flex>
      </Container>
    </Layout>
  )
}
