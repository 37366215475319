import { Flex, Th, Thead, Text } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React from 'react'

interface TheadTableHeadTheoricalStudieProps {
  onStatusClickOrderButton: () => void
  statusOrder: '+isProgrammedStudy' | '-isProgrammedStudy'
}

export const TheadTableHeadTheoricalStudie = (
  props: TheadTableHeadTheoricalStudieProps,
) => {
  const { onStatusClickOrderButton, statusOrder } = props
  return (
    <Thead textAlign={'center'} backgroundColor="#393940">
      <Th
        textTransform={'none'}
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="15px"
        color="#FFFFFF"
        py="12px"
        px="14px"
        lineHeight={'20px'}
        whiteSpace={'nowrap'}
        borderLeft="6px solid #393940"
      >
        <Flex w="100%" justifyContent={'flex-start'} gap="10px">
          <Text>Status</Text>

          {statusOrder === '+isProgrammedStudy' && (
            <Icon
              icon={'bi:sort-up-alt'}
              fontSize={'18px'}
              color="#FF6363"
              cursor={'pointer'}
              onClick={onStatusClickOrderButton}
            />
          )}

          {statusOrder === '-isProgrammedStudy' && (
            <Icon
              icon={'bi:sort-up-alt'}
              fontSize={'18px'}
              color="#E5C009"
              cursor={'pointer'}
              onClick={onStatusClickOrderButton}
            />
          )}
        </Flex>
      </Th>

      <Th
        textTransform={'none'}
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="15px"
        color="#FFFFFF"
        py="12px"
        px="14px"
        lineHeight={'20px'}
        whiteSpace={'nowrap'}
      >
        <Text>Grande área</Text>
      </Th>

      <Th
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="15px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="14px"
        lineHeight={'20px'}
        whiteSpace={'nowrap'}
      >
        <Text textAlign={'start'}>Tema</Text>
      </Th>

      <Th
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="15px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="14px"
        lineHeight={'20px'}
        whiteSpace={'nowrap'}
      >
        <Text>Subtema</Text>
      </Th>

      <Th
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="15px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="14px"
        lineHeight={'20px'}
        whiteSpace={'nowrap'}
      >
        <Flex m="0 auto" justifyContent={'center'}>
          Horas
        </Flex>
      </Th>

      <Th
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="15px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="14px"
        lineHeight={'20px'}
        whiteSpace={'nowrap'}
      >
        Ação
      </Th>
    </Thead>
  )
}
