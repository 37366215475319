import React, { useCallback, useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { truncate } from 'lodash'
import Swal from 'sweetalert2'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Contexts
import { useTrack } from 'contexts/track'

// Services
import api from 'services/api'

// Interfaces
import { DailyRecordsGraphMostFrequentsApiResponse } from './interfaces'

// Styles
import {
  Container,
  Content,
  ChipsContainer,
  Chip,
  ChipText,
  ChartContainer,
} from './styles'
import { theme } from 'styles/nivo'
import Layout from 'layouts/Logged'

const TopThemes: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | COnstants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { track } = useTrack()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isFetchingData, setisFetchingData] = useState(false)

  const [selectedFieldId, setSelectedFieldId] = useState<number>()

  const [range, setRange] = useState('week')

  const [
    numberOfQuestionsRaking,
    setNumberOfQuestionsRaking,
  ] = useState<DailyRecordsGraphMostFrequentsApiResponse>([])
  const [
    scorePercentRanking,
    setScorePercentRanking,
  ] = useState<DailyRecordsGraphMostFrequentsApiResponse>([])
  const [
    hoursStudiedRanking,
    setHoursStudiedRanking,
  ] = useState<DailyRecordsGraphMostFrequentsApiResponse>([])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchDailyRecordsGraphData = useCallback(
    async (range: string, fieldId?: number) => {
      setisFetchingData(true)

      try {
        const [
          numberOfQuestionsResponse,
          scorePercentResponse,
          totalTimeResponse,
        ] = await Promise.all([
          api.get<DailyRecordsGraphMostFrequentsApiResponse>(
            '/app/daily_records/graph_most_frequents',
            {
              params: { fieldId, orderBy: 'number_of_questions', range },
            },
          ),
          api.get<DailyRecordsGraphMostFrequentsApiResponse>(
            '/app/daily_records/graph_most_frequents',
            {
              params: { fieldId, orderBy: 'score_percent', range },
            },
          ),
          api.get<DailyRecordsGraphMostFrequentsApiResponse>(
            '/app/daily_records/graph_most_frequents',
            {
              params: { fieldId, orderBy: 'total_time', range },
            },
          ),
        ])

        setNumberOfQuestionsRaking(
          numberOfQuestionsResponse.data.map(d => ({
            ...d,
            name: truncate(d.name, { length: 36 }),
          })),
        )
        setScorePercentRanking(
          scorePercentResponse.data.map(d => ({
            ...d,
            name: truncate(d.name, { length: 36 }),
          })),
        )
        setHoursStudiedRanking(
          totalTimeResponse.data.map(d => ({
            ...d,
            name: truncate(d.name, { length: 36 }),
            total_time: Number((Number(d.total_time) / 60).toPrecision(2)),
          })),
        )

        setisFetchingData(false)
      } catch (error) {
        Swal.fire({
          title: 'Aviso',
          html:
            '<p style="text-align:center">Não foi possível carregar o seu painel geral nesse momento.</p>',
          buttonsStyling: false,
          icon: 'error',
        })
        console.trace('Error on fetching graph data: ', error)
      }
    },
    [],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    fetchDailyRecordsGraphData(range, selectedFieldId)
  }, [fetchDailyRecordsGraphData, range, selectedFieldId])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Top Temas"
          subTitle="Ranking dos seus temas mais estudados"
        />
        <Content>
          <SectionTitle>Matérias</SectionTitle>
          <ChipsContainer>
            {/* duplicated fields - filter by front-end */}
            {track?.name === 'R+ CM'
              ? track.fields
                  .filter(field => field.name !== 'GASTRO')
                  .map(field => (
                    <Chip
                      onClick={() => setSelectedFieldId(field.id)}
                      isActive={selectedFieldId === field.id}
                      key={`fields-id-${field.id}`}
                    >
                      <ChipText>{field.name}</ChipText>
                    </Chip>
                  ))
              : track?.name === 'R+ CGE'
              ? track.fields
                  .filter(field => field.id !== 129)
                  .map(field => (
                    <Chip
                      onClick={() => setSelectedFieldId(field.id)}
                      isActive={selectedFieldId === field.id}
                      key={`fields-id-${field.id}`}
                    >
                      <ChipText>{field.name}</ChipText>
                    </Chip>
                  ))
              : track?.fields.map(field => (
                  <Chip
                    onClick={() => setSelectedFieldId(field.id)}
                    isActive={selectedFieldId === field.id}
                    key={`fields-id-${field.id}`}
                  >
                    <ChipText>{field.name}</ChipText>
                  </Chip>
                ))}
            <Chip
              onClick={() => setSelectedFieldId(undefined)}
              isActive={selectedFieldId === undefined}
              key={'fields-id-undefined'}
            >
              <ChipText>GERAL</ChipText>
            </Chip>
          </ChipsContainer>

          <SectionTitle>Período</SectionTitle>
          <ChipsContainer>
            {[
              { value: 'week', label: 'SEMANA' },
              { value: 'month', label: 'MÊS' },
              { value: 'always', label: 'GERAL' },
            ].map(rangeOption => (
              <Chip
                onClick={() => setRange(rangeOption.value)}
                isActive={range === rangeOption.value}
                key={`fields-id-${rangeOption.value}`}
              >
                <ChipText>{rangeOption.label}</ChipText>
              </Chip>
            ))}
          </ChipsContainer>

          {isFetchingData && <p>Carregando ...</p>}
          {!isFetchingData && (
            <>
              <SectionTitle>Top Temas por Tempo de Estudo</SectionTitle>
              <ChartContainer>
                <ResponsiveBar
                  data={hoursStudiedRanking}
                  theme={theme}
                  isInteractive={false}
                  keys={['total_time']}
                  indexBy="name"
                  margin={{ top: 50, right: 130, bottom: 50, left: 280 }}
                  padding={0.3}
                  layout="horizontal"
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={['#FF6B00']}
                  enableLabel
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'horas estudadas',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={'#FFFFFF'}
                  legends={[]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </ChartContainer>
              <SectionTitle>Top Temas por Quantidade de Questões</SectionTitle>
              <ChartContainer>
                <ResponsiveBar
                  data={numberOfQuestionsRaking}
                  theme={theme}
                  isInteractive={false}
                  keys={['number_of_questions']}
                  indexBy="name"
                  margin={{ top: 50, right: 130, bottom: 50, left: 280 }}
                  padding={0.3}
                  layout="horizontal"
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={['#FF6B00']}
                  enableLabel
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'quantidade de questões',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={'#FFFFFF'}
                  legends={[]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </ChartContainer>
              <SectionTitle>
                Top Temas por Percentual Médio de Acertos (%)
              </SectionTitle>
              <ChartContainer>
                <ResponsiveBar
                  data={scorePercentRanking}
                  theme={theme}
                  keys={['score_percent']}
                  indexBy="name"
                  margin={{ top: 50, right: 130, bottom: 50, left: 280 }}
                  padding={0.3}
                  isInteractive={false}
                  layout="horizontal"
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={['#FF6B00']}
                  enableLabel
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'percentual de acerto (%)',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={'#FFFFFF'}
                  legends={[]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </ChartContainer>
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default TopThemes
