import React from 'react'
import Layout from 'layouts/Logged'
import { Flex, useBreakpointValue, Box } from '@chakra-ui/react'
import Header from 'components/Header'
import VideoOrientation from 'components/VideoOrientation'
import { HomeTemplate } from 'features/home'
import { PageContent } from 'components/PageContent'

const Home: React.FC = () => {
  const isMd = useBreakpointValue({ base: false, lg: true })

  return (
    <Layout>
      <PageContent>
        <Box>
          <Header
            title="Home"
            hasVideoOrientation={isMd ? true : false}
            linkVideo="https://www.youtube.com/watch?v=lLNinALZzfc"
          />

          {!isMd && (
            <Flex justifyContent={'flex-start'}>
              <VideoOrientation
                linkVideo="https://www.youtube.com/watch?v=lLNinALZzfc"
                mt="8px"
              />
            </Flex>
          )}
        </Box>
        <HomeTemplate />
      </PageContent>
    </Layout>
  )
}

export default Home
