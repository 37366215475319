export const pdfData = [
  {
    id: 'pdf-1',
    title: 'Caderno 1',
    value:
      'https://drive.google.com/file/d/1c1hCYEJnzi1IMiQZK0Zthno4SGp_tHSn/preview',
  },
  {
    id: 'pdf-2',
    title: 'Caderno 2',
    value:
      'https://drive.google.com/file/d/1O4_DXb9hl58rg8NpifLurTtsrMXTUCwX/preview',
  },
  {
    id: 'pdf-3',
    title: 'Caderno 3',
    value:
      'https://drive.google.com/file/d/14EfIefwUG8iOxFMUrmWWamb7IJRUsET8/preview',
  },
  {
    id: 'pdf-4',
    title: 'Caderno 4',
    value:
      'https://drive.google.com/file/d/1E3S_nBGIB9iCn7Auqj6jIlAtagbdkCdI/preview',
  },
  {
    id: 'pdf-5',
    title: 'Caderno 5',
    value:
      'https://drive.google.com/file/d/1v4NR0n1j9kuPDSr6PFprD_64zBV9iz6T/preview',
  },
  {
    id: 'pdf-6',
    title: 'Caderno 6',
    value:
      'https://drive.google.com/file/d/1P9r3phewfeUCL7qxPSjjEcQZ-bO3Vhq6/preview',
  },
  {
    id: 'pdf-7',
    title: 'Caderno 7',
    value:
      'https://drive.google.com/file/d/1M-3hiuTy-Z8UfGbife8mpl18zqaaGx-t/preview',
  },
  {
    id: 'pdf-8',
    title: 'Caderno 8',
    value:
      'https://drive.google.com/file/d/1Yc0Xbrv7nWyaOLlaTvOemtSoowRfAoVl/preview',
  },
  {
    id: 'pdf-9',
    title: 'Caderno 9',
    value:
      'https://drive.google.com/file/d/1H6PZQddrgBqWQk_8T9bHqe3bKUOVsFb3/preview',
  },
  {
    id: 'pdf-10',
    title: 'Caderno 10',
    value:
      'https://drive.google.com/file/d/1jYZ8MLVoW3O9DD4YeItTZIAhvwe0-Y7P/preview',
  },
  {
    id: 'pdf-11',
    title: 'Caderno 11',
    value:
      'https://drive.google.com/file/d/1inCpbtcwY4GSvYjglw5xCBe57JTizRnx/preview',
  },
]
