export const stepsOnboardingTrial = [
  {
    title: 'Atualização',
  },
  {
    title: 'Trilha',
  },
  {
    title: 'Mentoria plataforma',
  },
]
