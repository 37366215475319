import React from 'react'
import { CircularProgress, Flex } from '@chakra-ui/react'
import { Container } from './styles'
import Layout from 'layouts/Logged'

export function IsLoadingPage() {
  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Flex w="full" h="full" justifyContent={'center'} alignItems="center">
          <CircularProgress isIndeterminate color="brand.50" />
        </Flex>
      </Container>
    </Layout>
  )
}
