import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponseApi } from 'types/response'

interface Subjects {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Themes {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

export interface ListPendingStudiesData {
  id: number
  starts_at: string
  finishes_at: string
  is_theoretical: boolean
  is_programmed_study: boolean
  study_time_in_minutes: number
  created_at: string
  field_id: number
  theme_id: number | null
  subject_id: number | null
  field: {
    name: string
    id: number
  }
  theme: null
  subject: null
  subjects: Subjects[]
  themes: Themes[]
}

export interface ListPendingStudiesDataResponse {
  meta: MetaResponseApi
  data: ListPendingStudiesData[]
}

interface Params {
  //Theoretical and practical
  isTheoretical?: boolean
  fieldId?: number
  sort?: string
  limit?: number
  page?: number
  isProgrammedStudy?: boolean
}

export async function listPendingStudies(
  params?: Params,
): Promise<ListPendingStudiesDataResponse> {
  const { data: listPendingStudies } = await api.get<{
    meta: MetaResponseApi
    data: ListPendingStudiesData[]
  }>('/app/pending_studies', {
    params: {
      sort: params?.sort && params.sort,
      limit: params?.limit,
      page: params?.page,
      isTheoretical: params?.isTheoretical,
      fieldId: params?.fieldId,
      isProgrammedStudy: params?.isProgrammedStudy,
    },
  })

  return listPendingStudies
}

export function useGetListPendingStudies(params?: Params) {
  return useQuery(
    ['list-recovery-plan-studies', params],
    () => listPendingStudies(params),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
