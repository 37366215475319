import styled from 'styled-components'

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  margin-top: 32px;
  margin-bottom: 16px;
`
