import React from 'react'

// Styles
import { Container, Content } from './styles'

import Layout from 'layouts/Logged'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'
import { Dashboard } from 'features/dashboard'
import Header from 'components/Header'

export const DashboardPage: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMd = useBreakpointValue({ base: false, md: true })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Painel Principal"
          subTitle="Principais informações do seu desempenho"
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://www.youtube.com/watch?v=AB_setLIkq8"
        />
        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://www.youtube.com/watch?v=AB_setLIkq8"
              mt="8px"
            />
          </Flex>
        )}

        <Content>
          <Dashboard />
        </Content>
      </Container>
    </Layout>
  )
}

export default Dashboard
