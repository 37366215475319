import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface FindThemeQuery {
  themeId: string
}

interface FindThemeResponseApi {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
  field: Field
  subthemes: any[]
}

interface Field {
  id: number
  name: string
  track_id: number
  created_at: string
  updated_at: string
}

interface FindThemeResponse {
  id: number
  name: string
  field: {
    id: number
    name: string
  }
}

export async function findTheme({
  themeId,
}: FindThemeQuery): Promise<FindThemeResponse> {
  const { data } = await api.get<FindThemeResponseApi>(`/app/themes/${themeId}`)

  return {
    id: data.id,
    name: data.name,
    field: {
      id: data.field.id,
      name: data.field.name,
    },
  }
}

export function useFindTheme(query: FindThemeQuery) {
  return useQuery(['theme', query], () => findTheme(query))
}
