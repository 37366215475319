import {
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React, { ReactNode } from 'react'

export type ModalConfirmProjectXProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  onConfirmClick: () => void
} & Omit<ModalProps, 'children'>

export const ModalConfirmProjectX = (props: ModalConfirmProjectXProps) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    onConfirmClick,
    ...reactModalProps
  } = props

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} {...reactModalProps}>
      <ModalOverlay />
      <ModalContent
        maxW={'348px'}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalBody p={'1.5rem'} borderRadius={'8px'}>
          <Flex w="100%" justifyContent={'center'} flexDir="column">
            <VStack spacing={'16px'}>
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="700"
                fontSize={'24px'}
                color={'#FFFFFF'}
                textAlign={'center'}
              >
                Antes de continuar leia a mensagem abaixo
              </Text>

              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                textAlign={'center'}
                fontSize="16px"
                lineHeight="20px"
                color="#BDBDC7"
              >
                Ao ativar o modo de estudo dirigido, os estudos de{' '}
                <chakra.span color="white">revisões futuras serão</chakra.span>{' '}
                <chakra.span color="#FF6363">excluídos</chakra.span>, dando
                espaço as sugestões personalizadas.
              </Text>
            </VStack>

            <Flex mt="24px" gap="12px" flexDir={'column'}>
              <DefaultButton
                onClick={onConfirmClick}
                label={'Quero continuar'}
              />

              <DefaultButton onClick={onClose} label="Voltar" variant="ghost" />
            </Flex>
          </Flex>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
