import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Params {
  studyRecoveryPlanId: number
  pendingStudyId: number
  isProgrammedStudy: boolean
}

async function TheoreticalStudies({
  studyRecoveryPlanId,
  pendingStudyId,
  isProgrammedStudy,
}: Params) {
  await api.patch(
    `/app/recovery_plan/${studyRecoveryPlanId}/pending_studies/${pendingStudyId}`,
    { isProgrammedStudy },
  )
}

export function useMutationTheoreticalStudies() {
  return useMutation(TheoreticalStudies, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-recovery-plan-studies'])
      queryClient.invalidateQueries(['first-contact-studies-recovery-plan'])
      queryClient.invalidateQueries(['list-performance-studies'])
    },
  })
}
