import React from 'react'
import { IconType } from 'react-icons'

export const SimulationtIcon: IconType = ({ size, color }) => (
  <svg
    width={'16px'}
    height={'16px'}
    fill={color}
    color={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33325 4.25004C6.33325 4.13953 6.37715 4.03355 6.45529 3.95541C6.53343 3.87727 6.63941 3.83337 6.74992 3.83337H10.0833C10.1938 3.83337 10.2997 3.87727 10.3779 3.95541C10.456 4.03355 10.4999 4.13953 10.4999 4.25004C10.4999 4.36055 10.456 4.46653 10.3779 4.54467C10.2997 4.62281 10.1938 4.66671 10.0833 4.66671H6.74992C6.63941 4.66671 6.53343 4.62281 6.45529 4.54467C6.37715 4.46653 6.33325 4.36055 6.33325 4.25004ZM6.74992 5.50004C6.63941 5.50004 6.53343 5.54394 6.45529 5.62208C6.37715 5.70022 6.33325 5.8062 6.33325 5.91671C6.33325 6.02721 6.37715 6.1332 6.45529 6.21134C6.53343 6.28948 6.63941 6.33337 6.74992 6.33337H10.0833C10.1938 6.33337 10.2997 6.28948 10.3779 6.21134C10.456 6.1332 10.4999 6.02721 10.4999 5.91671C10.4999 5.8062 10.456 5.70022 10.3779 5.62208C10.2997 5.54394 10.1938 5.50004 10.0833 5.50004H6.74992ZM6.33325 9.66671C6.33325 9.5562 6.37715 9.45022 6.45529 9.37208C6.53343 9.29394 6.63941 9.25004 6.74992 9.25004H10.0833C10.1938 9.25004 10.2997 9.29394 10.3779 9.37208C10.456 9.45022 10.4999 9.5562 10.4999 9.66671C10.4999 9.77721 10.456 9.8832 10.3779 9.96134C10.2997 10.0395 10.1938 10.0834 10.0833 10.0834H6.74992C6.63941 10.0834 6.53343 10.0395 6.45529 9.96134C6.37715 9.8832 6.33325 9.77721 6.33325 9.66671ZM6.74992 10.9167C6.63941 10.9167 6.53343 10.9606 6.45529 11.0387C6.37715 11.1169 6.33325 11.2229 6.33325 11.3334C6.33325 11.4439 6.37715 11.5499 6.45529 11.628C6.53343 11.7061 6.63941 11.75 6.74992 11.75H10.0833C10.1938 11.75 10.2997 11.7061 10.3779 11.628C10.456 11.5499 10.4999 11.4439 10.4999 11.3334C10.4999 11.2229 10.456 11.1169 10.3779 11.0387C10.2997 10.9606 10.1938 10.9167 10.0833 10.9167H6.74992Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16675 9.25004C2.16675 9.13953 2.21065 9.03355 2.28879 8.95541C2.36693 8.87727 2.47291 8.83337 2.58341 8.83337H4.66675C4.77726 8.83337 4.88324 8.87727 4.96138 8.95541C5.03952 9.03355 5.08341 9.13953 5.08341 9.25004V11.3334C5.08341 11.4439 5.03952 11.5499 4.96138 11.628C4.88324 11.7061 4.77726 11.75 4.66675 11.75H2.58341C2.47291 11.75 2.36693 11.7061 2.28879 11.628C2.21065 11.5499 2.16675 11.4439 2.16675 11.3334V9.25004ZM3.00008 9.66671V10.9167H4.25008V9.66671H3.00008Z"
      fill={color}
    />
    <path
      d="M5.37801 4.54463C5.4539 4.46605 5.4959 4.3608 5.49495 4.25155C5.494 4.1423 5.45018 4.03779 5.37293 3.96054C5.29568 3.88329 5.19117 3.83947 5.08192 3.83852C4.97267 3.83757 4.86742 3.87957 4.78884 3.95546L3.41675 5.32755L2.878 4.7888C2.79942 4.7129 2.69417 4.6709 2.58492 4.67185C2.47567 4.6728 2.37117 4.71662 2.29391 4.79387C2.21666 4.87113 2.17284 4.97563 2.17189 5.08488C2.17094 5.19413 2.21294 5.29938 2.28884 5.37796L3.41675 6.50588L5.37801 4.54463Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16667 0.5C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H10.5C10.942 15.5 11.3659 15.3244 11.6785 15.0118C11.9911 14.6993 12.1667 14.2754 12.1667 13.8333V2.16667C12.1667 1.72464 11.9911 1.30072 11.6785 0.988155C11.3659 0.675595 10.942 0.5 10.5 0.5H2.16667ZM1.33333 2.16667C1.33333 1.94565 1.42113 1.73369 1.57741 1.57741C1.73369 1.42113 1.94565 1.33333 2.16667 1.33333H10.5C10.721 1.33333 10.933 1.42113 11.0893 1.57741C11.2455 1.73369 11.3333 1.94565 11.3333 2.16667V13.8333C11.3333 14.0543 11.2455 14.2663 11.0893 14.4226C10.933 14.5789 10.721 14.6667 10.5 14.6667H2.16667C1.94565 14.6667 1.73369 14.5789 1.57741 14.4226C1.42113 14.2663 1.33333 14.0543 1.33333 13.8333V2.16667Z"
      fill={color}
    />
  </svg>
)
