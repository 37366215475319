import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalProps,
  Heading,
  Box,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import {
  SendFeedbackParams,
  useSendFeedback,
} from '../../mutations/use-mutate-send-feedback'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TextAreaController } from 'components/Fields/TextArea/TextAreaController'
import { yupResolver } from '@hookform/resolvers/yup'
import { SendFeedbackSchema } from './schema'

interface ModalFeedbackProps extends Omit<ModalProps, 'children'> {}

export default function ModalFeedback(props: ModalFeedbackProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sendFeedback = useSendFeedback()

  const showToast = useToastMessage()
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<SendFeedbackParams>({
    resolver: yupResolver(SendFeedbackSchema),
    defaultValues: {
      messageFeedBack: '',
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (formData: SendFeedbackParams) => {
    if (!formData.messageFeedBack) return

    try {
      await sendFeedback.mutateAsync({
        messageFeedBack: formData.messageFeedBack,
      })

      showToast({
        title: 'Sucesso!',
        description:
          'O seu feedback foi enviado com sucesso. Agradecemos o seu tempo.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
      onClose()
      reset()
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível enviar um feedback no momento.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        as="form"
        p="0px"
        background={'#302F37'}
        maxW={{ base: '377px', lg: '756px' }}
        w="100%"
        borderRadius={'8px'}
        overflow={'hidden'}
        transition={'all 0.2s ease-in-out'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalHeader
          color={'#FFFF'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Heading
            fontFamily={'Mulish'}
            fontSize={{ base: '20px', md: '22px' }}
            fontWeight={700}
          >
            Deixe seu Feedback para o Minha Versão 2.0
          </Heading>

          <ModalCloseButton
            right={'unset'}
            top={'unset'}
            color={'#FFFF'}
            position={'relative'}
            _focus={{ outline: 'none' }}
          />
        </ModalHeader>

        <ModalBody color={'#FFFF'} background={'#302F37'} w="100%" mb="32px">
          <TextAreaController
            control={control}
            name="messageFeedBack"
            label="Mensagem"
            placeholder="O que achou do curso? Escreva aqui sua mensagem de feedback para o time Mentoria Residência"
            minH={'227px'}
            error={errors.messageFeedBack}
          />
        </ModalBody>

        <ModalFooter
          flexDir={{ base: 'column', sm: 'row' }}
          gap={'20px'}
          borderTop={'1px solid #43424C'}
        >
          <DefaultButton
            w={{ base: 'full', sm: '50%' }}
            label="Cancelar"
            onClick={() => onClose()}
            variant="ghost"
          />

          <DefaultButton
            w={{ base: 'full', sm: '50%' }}
            type="submit"
            isLoading={isSubmitting}
            label="Enviar"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
