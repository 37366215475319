import { Textarea, TextareaProps } from '@chakra-ui/react'
import React from 'react'

const TextArea: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextareaProps
> = (props, ref) => {
  return (
    <Textarea
      {...props}
      ref={ref}
      mt="0px"
      background="#393940"
      borderRadius={'8px'}
      data-qa={props.name}
      position={'relative'}
      transition={'none'}
      border={'0px'}
      _focus={{
        boxShadow: 'none',
        border: '1px solid #E56000',
      }}
      maxBlockSize={'md'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
          boxShadow: 'inset 0 0 5px #60606c',
          borderRadius: '17px',
          height: '8px',
          borderLeft: '2px solid transparent',
          borderRight: '2px solid transparent',
          marginTop: '10px',
          marginBottom: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E56000',
          borderRadius: '17px',
          height: '8px',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
        },
      }}
    />
  )
}

export const TextAreaField = React.forwardRef(TextArea)
