import * as React from 'react'
import {
  BoxProps,
  Divider,
  chakra,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { StepCircle } from './StepCircle'

interface StepProps extends BoxProps {
  title: string
  description?: string
  isCompleted: boolean
  isActive: boolean
  isLastStep: boolean
  isFirstStep: boolean
}

export const Step = (props: StepProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    isActive,
    isCompleted,
    isLastStep,
    isFirstStep,
    title,
    description,
    ...stackProps
  } = props

  const isMobile = useBreakpointValue({ base: true, xx: false })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack
      spacing="5"
      direction={{ base: 'column', md: 'column' }}
      {...stackProps}
      fontFamily="Mulish"
      w="auto"
      flex="1"
    >
      <Stack spacing="0" direction={{ base: 'row', sm: 'row' }}>
        <StepCircle
          isActive={isActive}
          isCompleted={isCompleted}
          isLastStep={isLastStep}
        />

        <Divider
          display={
            isLastStep
              ? 'none'
              : isMobile && isLastStep
              ? 'none'
              : isMobile
              ? 'initial'
              : 'initial'
          }
          orientation="horizontal"
          borderWidth="2px"
          w={'100%'}
          alignSelf={'center'}
          borderColor={
            isCompleted || isActive
              ? 'brand.primary'
              : 'brand.secondary-brighter'
          }
        />
      </Stack>

      <Stack spacing="0.5" align={{ base: 'flex-start', md: 'flex-start' }}>
        <Text
          fontFamily="Mulish"
          fontStyle="normal"
          noOfLines={2}
          overflow={'hidden'}
          fontWeight="600"
          wordBreak={'break-word'}
          mr="10px"
          fontSize={{ base: '12px', sm: '16px' }}
          lineHeight={{ base: '18px', sm: '20px' }}
          color={
            isLastStep
              ? 'white-60'
              : !isActive && !isCompleted
              ? 'white-60'
              : 'white'
          }
        >
          {title}
        </Text>
      </Stack>
    </Stack>
  )
}
