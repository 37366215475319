import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

export interface CreateDailyRecordRequest {
  date: string
  contractId: number
  trackId: number
  themeIds: number[]
  fieldId: number
  subjectIds?: number[]
  studiedMinutes: number
  effortPerception: number
  numberOfQuestions?: number
  numberOfCorrectAnswers?: number
  isTheoreticalOnly: boolean
  firstContactTime?: number
  isCreateAgenda?: boolean
  agendaId?: number | null
}

export async function createDailyRecord(data: CreateDailyRecordRequest) {
  await api.post('/app/daily_records', data)
}

export function useCreateDailyRecord() {
  return useMutation(createDailyRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas'])
    },
  })
}
