import { TableRowProps, Td, Text, Tooltip, Tr } from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React from 'react'
import { capitalize, isEmpty } from 'lodash'
import { formatDateUTC } from 'helpers/formatTimer'

interface SubTheme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Theme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}
interface TrTableStudiesDoneProps extends TableRowProps {
  date: string
  area: string
  theme: Theme[]
  subTheme: SubTheme[]
  studyType: boolean
  backgroundTable?: string
  onClickOpenRemove: () => void
}

export function TrTableStudiesDone(props: TrTableStudiesDoneProps) {
  const {
    date,
    area,
    backgroundTable,
    studyType,
    onClickOpenRemove,
    subTheme,
    theme,
    ...rest
  } = props

  return (
    <>
      <Tr
        pb="1rem"
        backgroundColor={backgroundTable}
        verticalAlign={'baseline'}
        borderLeft="6px solid #0DDF15"
        {...rest}
      >
        <Td
          h="-webkit-fit-content"
          py="11px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {date && formatDateUTC(date, 'dd/MM/yyyy')}
          </Text>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={!area ? '#BDBDC7' : '#FFFFFF'}
          >
            {area?.length > 20
              ? area && (
                  <Tooltip label={capitalize(area)}>
                    {letterCounter(capitalize(area), 20)}
                  </Tooltip>
                )
              : area && capitalize(area)}
          </Text>

          {(isEmpty(area) || !area) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {theme &&
            theme.map(item => capitalize(item.name)).join(' | ').length > 11 ? (
              <Tooltip
                label={theme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  theme?.map(item => capitalize(item.name)).join(' | '),
                  11,
                )}
              </Tooltip>
            ) : (
              theme?.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(theme) || !theme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {subTheme &&
            subTheme.map(item => capitalize(item.name)).join(' | ').length >
              11 ? (
              <Tooltip
                label={subTheme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  subTheme.map(item => capitalize(item.name)).join(' | '),
                  11,
                )}
              </Tooltip>
            ) : (
              subTheme.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(subTheme) || !subTheme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="16px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {studyType ? 'Teórico' : 'Prático'}
          </Text>
        </Td>
      </Tr>
    </>
  )
}
