import {
  CircularProgress,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useRecoveryDaily } from 'contexts/recoveryDaily'
import { queryClient } from 'index'
import React, { ReactNode, useEffect, useState } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { useHistory } from 'react-router-dom'
type Size = 'sm' | 'md' | 'xmm' | 'lg' | 'xl' | '2xl' | 'xsm'
import './style.css'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: Size
  children?: ReactNode
  isError: boolean
  errorMessageFromAPI: string
} & ReactModalProps

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '260px',
    xmm: '290px',
    sm: '360px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}
const contentStyles = () => {
  return {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  }
}

export const ModalRecoveryPlanLoading = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    errorMessageFromAPI,
    isError,
    ...reactModalProps
  } = props
  const { setStep } = useRecoveryDaily()
  const isSm = useBreakpointValue({ base: true, sm: false })
  const [isLoading, setIsLoading] = useState(false)
  const { push } = useHistory()

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setIsLoading(true)
    }, 3000)

    setIsLoading(false)
    return () => window.clearTimeout(timeoutID)
  }, [isOpen])

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={() => {
        if (!isError) {
          queryClient.invalidateQueries(['activated-recovery-plan'])
          setIsLoading(false)
          setStep(0)
          push('/recovery-plan')
        }

        if (isError) {
          setIsLoading(false)
        }
      }}
      shouldCloseOnOverlayClick={isLoading}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          ...contentStyles(),
          width: isSm ? getWidthBySize('xmm') : getWidthBySize('sm'),
          padding: isSm ? '24px' : '32px',
          background: '#302F37',
          border: '0px',
        },
        overlay: {
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.40)',
        },
      }}
      {...reactModalProps}
    >
      <Flex w="100%" justifyContent={'center'} flexDir="column">
        {!isLoading && (
          <Flex gap={'24px'} flexDir="column">
            <HStack justifyContent={'center'}>
              <CircularProgress
                size="90px"
                isIndeterminate
                color="brand.50"
                trackColor={'#BB7121'}
              />
            </HStack>

            <Text
              fontFamily="Mulish"
              fontWeight="700"
              fontSize="24px"
              lineHeight="30px"
              textAlign="center"
              color="#FFFFFF"
            >
              Registrando plano de recuperação
            </Text>
          </Flex>
        )}

        {isLoading && !isError && (
          <>
            <Flex gap={'24px'} flexDir="column">
              <Flex w="100%" justifyContent={'center'}>
                <Icon
                  fontSize={'80px'}
                  color="#0DDF15"
                  icon="material-symbols:check-circle"
                />
              </Flex>

              <Text
                fontFamily="Mulish"
                fontWeight="700"
                fontSize="24px"
                lineHeight="30px"
                textAlign="center"
                color="#FFFFFF"
              >
                Plano de recuperação registrado!
              </Text>
            </Flex>
            <Flex justifyContent={'center'}>
              <DefaultButton
                w={'100%'}
                mt="24px"
                onClick={onClose}
                label="Ok"
              />
            </Flex>
          </>
        )}

        {isLoading && isError && (
          <>
            <Flex gap={'24px'} flexDir="column">
              <Flex w="100%" justifyContent={'center'}>
                <Icon
                  fontSize={'80px'}
                  color="red"
                  icon="ic:round-error-outline"
                />
              </Flex>

              <Text
                fontFamily="Mulish"
                fontWeight="700"
                fontSize="24px"
                lineHeight="30px"
                textAlign="center"
                color="#FFFFFF"
              >
                {errorMessageFromAPI}
              </Text>
            </Flex>
            <Flex justifyContent={'center'}>
              <DefaultButton
                w={'100%'}
                mt="24px"
                label="Ok"
                onClick={onClose}
              />
            </Flex>
          </>
        )}
      </Flex>
      {children}
    </ReactModal>
  )
}
