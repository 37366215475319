import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Params {
  id: number
}

async function deletePendingStudies({ id }: Params) {
  await api.delete(`/app/pending_studies/${id}`)
}

export function useDeletePendingStudies() {
  return useMutation(deletePendingStudies, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-recovery-plan-studies'])
      queryClient.invalidateQueries(['first-contact-studies-recovery-plan'])
      queryClient.invalidateQueries(['list-performance-studies'])
    },
  })
}
