import React, { useMemo } from 'react'
import { Input, InputProps } from '@chakra-ui/react'

type Sizes = 'lg' | 'md' | 'sm'
export interface FieldInputProps extends Omit<InputProps, 'size'> {
  size?: Sizes
}
const FieldInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldInputProps
> = ({ size = 'md', ...props }, ref) => {
  const sizesBySize = useMemo((): Record<Sizes, FieldInputProps> => {
    return {
      sm: {
        height: '32px',
      },
      md: {
        height: '42px',
      },
      lg: {
        height: '48px',
      },
    }
  }, [])

  return (
    <Input
      ref={ref}
      data-qa={props.name}
      placeholder="Digite ..."
      {...sizesBySize[size]}
      {...props}
    />
  )
}

export const FieldInputComponent = React.forwardRef(FieldInput)
