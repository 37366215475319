import {
  Text,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Box,
} from '@chakra-ui/react'
import React from 'react'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { PendingTheme } from '../queries/use-get-pending-themes'
import { useAddThemeRevision } from '../queries/mutate-add-theme-revision'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'

interface CardTimeLineMRProps {
  data: PendingTheme
}

export const CardTimeLineMR = ({ data }: CardTimeLineMRProps & {}) => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const addThemeRevisionMutate = useAddThemeRevision()
  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      p="8px 16px 8px 16px"
      borderRadius="4px"
      backgroundColor={'#393940'}
      gap="1rem"
      w="100%"
    >
      <Flex gap={'4px'} flexDir={'column'} w="100%" maxW={'200px'}>
        <Text
          fontWeight="600"
          fontSize="14px"
          lineHeight="18px"
          color="#BDBDC7"
        >
          Grande área
        </Text>

        <Text
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          color="#FFFFFF"
          noOfLines={1}
        >
          {data?.field?.name ? data?.field?.name : '-'}
        </Text>
      </Flex>

      <Flex
        width={'100%'}
        gap={'8px'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex gap={'4px'} flexDir={'column'}>
          <Text
            fontWeight="600"
            fontSize="14px"
            lineHeight="18px"
            color="#BDBDC7"
          >
            Tema
          </Text>

          <Text
            fontWeight="600"
            fontSize="16px"
            lineHeight="20px"
            color="#FFFFFF"
            noOfLines={1}
          >
            {data?.name ? data?.name : '-'}
          </Text>
        </Flex>

        <Popover isLazy>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Box minW={'16px'}>
                  <Icon
                    cursor={'pointer'}
                    icon={'charm:forward'}
                    fontSize="16px"
                    color="#FFFF"
                  />
                </Box>
              </PopoverTrigger>
              <Portal>
                <Box
                  sx={{
                    '& .chakra-popover__popper': {
                      zIndex: 'popover',
                    },
                  }}
                >
                  <PopoverContent
                    _focus={{ outline: 'none' }}
                    border="0px"
                    backgroundColor={'#2A2A30'}
                  >
                    <PopoverHeader border={'0px'} px="16px" pt="16px">
                      <Text
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#FFFFFF"
                      >
                        Deseja adicionar o estudo?
                      </Text>
                    </PopoverHeader>

                    <PopoverCloseButton mt="8px" color={'#FFF'} />

                    <PopoverBody py="0px" px="16px" border={'0px'}>
                      <Text
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="150%"
                        color="#BDBDC7"
                        whiteSpace={'break-spaces'}
                      >
                        Se continuar, seu estudo será encaminhado para o
                        calendário.
                      </Text>
                    </PopoverBody>

                    <PopoverFooter
                      border="0px"
                      display="flex"
                      gap={'1rem'}
                      px="16px"
                      pb="16px"
                    >
                      <DefaultButton
                        size="mds"
                        w="100%"
                        label="Adicionar"
                        isLoading={addThemeRevisionMutate.isLoading}
                        onClick={async () => {
                          try {
                            await addThemeRevisionMutate.mutateAsync({
                              themeId: data.id,
                            })

                            showToast({
                              title: 'Sucesso',
                              description: 'Estudo adicionado com sucesso.',
                              type: 'success',
                              duration: 5000,
                              mode: 'dark',
                            })
                          } catch (error: any) {
                            const message: AxiosError =
                              error?.response?.data?.errors
                                ?.map((error: AxiosError) => error.message)
                                ?.join(', ') ||
                              error?.response?.data ||
                              'Não foi possível modificar o estudo.'

                            showToast({
                              title: 'Erro',
                              description:
                                typeof message.message === 'string'
                                  ? message.message
                                  : message,
                              type: 'error',
                              duration: 2000,
                              mode: 'dark',
                            })
                          }
                        }}
                      />

                      <DefaultButton
                        size="mds"
                        variant="ghost"
                        w="100%"
                        label="Cancelar"
                        onClick={onClose}
                      />
                    </PopoverFooter>
                  </PopoverContent>
                </Box>
              </Portal>
            </>
          )}
        </Popover>
      </Flex>
    </Flex>
  )
}
