import { Flex, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'

export interface VideoSectionProps {
  number: number
  onClick?: any
  videoText: string
  videoTime?: string
}

export default function VideoSection({
  videoText,
  videoTime,
  number,
  onClick,
}: VideoSectionProps) {
  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      borderRadius="8px"
      background={'#393940'}
      minH="53px"
      maxH="61px"
      w="100%"
      h="100%"
      cursor={'pointer'}
      onClick={onClick}
      padding={'10px 16px 10px 12px'}
      justifyContent="space-between"
      align={'center'}
    >
      <Tooltip label={videoText} p="0.5rem">
        <Flex w="100%" gap="24px" justifyContent={'space-between'}>
          <Flex
            maxW="20px"
            w="full"
            ml="10px"
            justifyContent={'center'}
            alignItems="center"
          >
            <Text>{number}</Text>
          </Flex>

          <Flex w="100%" flexDir={'column'}>
            <Text
              noOfLines={2}
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="600"
              fontSize="14px"
              lineHeight="18px"
              w="100%"
              color="#FFFFFF"
            >
              {videoText}
            </Text>

            {videoTime && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="12px"
                lineHeight="15px"
                color="#D1D5D8"
              >
                {videoTime}
              </Text>
            )}
          </Flex>
        </Flex>
      </Tooltip>
    </Flex>
  )
}
