import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

interface Query {
  trackId?: number
  fieldId?: number
}

interface Params {
  limit: number
}

interface GetSubjectResponseApi {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface GetSubject {
  id: number
  name: string
}

interface GetSubjectResponse {
  subjects: GetSubject[]
  meta: MetaResponse
}

async function getSubjects(
  query: Query,
  params?: Params,
): Promise<GetSubjectResponse | undefined> {
  const { fieldId, trackId } = query

  if (!trackId || !fieldId) return

  const { data } = await api.get<{
    data: GetSubjectResponseApi[]
    meta: MetaResponseApi
  }>(`/app/tracks/${trackId}/fields/${fieldId}/subjects`, {
    params,
  })

  const subjects = data.data.map(subject => ({
    id: subject.id,
    name: subject.name,
  }))

  return { subjects, meta: formatMeta(data.meta) }
}

export function useGetSubjects(query: Query, params?: Params) {
  return useQuery(['subjects', query], () => getSubjects(query, params))
}
