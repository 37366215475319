import { Tooltip, TooltipProps, useBreakpointValue } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface CustomTooltipProps extends TooltipProps {
  children: ReactNode
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { children, ...rest } = props

  return (
    <Tooltip
      fontFamily="Mulish"
      fontWeight="400"
      fontSize="14px"
      lineHeight="18px"
      color="#FFFFFF"
      hasArrow
      w="100%"
      {...rest}
    >
      {props.children}
    </Tooltip>
  )
}
