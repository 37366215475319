import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

export interface GetDeletedDailyRecordResponseApi {
  id: number
  date: string
  studied_minutes: number
  effort_perception: number
  number_of_questions: number
  number_of_correct_answers: number
  created_at: string
  field_id: number
  theme_id: number
  subject_id?: number
  field: Field
  theme: Theme
  subject?: Subject
}

export interface Field {
  name: string
  id: number
}

export interface Theme {
  name: string
  id: number
}

export interface Subject {
  name: string
  id: number
}

export interface GetDeletedDailyRecord {
  id: number
  field: Field
  date: string
  theme: Theme
  subject?: Subject
  studiedMinutes: number
  numberOfQuestions: number
  numberOfCorrectAnswers: number
}

export interface GetDeletedDailyRecordsResponse {
  items: GetDeletedDailyRecord[]
  meta: MetaResponse
}

interface Params {
  page: number
}

export async function getDeletedDailyRecords(params: Params) {
  const { data: dailyRecords } = await api.get<{
    data: GetDeletedDailyRecordResponseApi[]
    meta: MetaResponseApi
  }>('/app/daily_records/deleteds', { params: { limit: 10, ...params } })

  const items = dailyRecords.data.map(dailyRecord => ({
    id: dailyRecord.id,
    field: dailyRecord.field,
    date: dailyRecord.date,
    theme: dailyRecord.theme,
    subject: dailyRecord.subject,
    studiedMinutes: dailyRecord.studied_minutes,
    numberOfQuestions: dailyRecord.number_of_questions,
    numberOfCorrectAnswers: dailyRecord.number_of_correct_answers,
  }))

  return {
    items,
    meta: formatMeta(dailyRecords.meta),
  }
}

export function useGetDeletedDailyRecords(params: Params) {
  return useQuery(['daily-records', 'deleted', params], () =>
    getDeletedDailyRecords(params),
  )
}
