import React, { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'

export const PageContent = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      flexDir="column"
      flex={1}
      h="full"
      overflowY="auto"
      gap="32px"
      p="24px"
      px={{ base: '16px 8px' }}
      sx={{
        '&::-webkit-scrollbar': {
          backgroundColor: '#2a2a2d',
          width: '8px',
        },

        '&::-webkit-scrollbar-track': {
          backgroundColor: 'rgba(223, 224, 235, 0.06)',
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(223, 224, 235, 0.6)',
          borderRadius: '16px',
        },
      }}
    >
      {children}
    </Flex>
  )
}
