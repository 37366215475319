import styled from 'styled-components'
import { BiSearch } from 'react-icons/bi'
import { ModalHeader, ModalContent, ModalBody } from '@chakra-ui/react'

import { ResponsiveContainer, Scroll } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  ${ResponsiveContainer};

  overflow-y: auto;

  ${Scroll}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 16px;

  flex: 1 0 auto;
`

export const Chip = styled.button<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 166px;
  height: 42px;

  margin-right: 16px;
  margin-bottom: 16px;

  border-radius: 10px;
  border-width: 1px;
  border-color: red;

  background: transparent;

  border: 1px solid rgba(255, 255, 255, 0.2);

  transition: all 0.4s;

  &:hover {
    border: 1px solid rgba(255, 107, 0, 0.4);
    background: rgba(255, 107, 0, 0.05);

    p {
      color: rgba(255, 255, 255, 0.45);
    }
  }
`

export const ChipText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);

  transition: all 0.4s;

  user-select: none;

  padding: 8px;
`

export const StyledInputWrapper = styled.div`
  width: 240px;
  color: white;
  position: relative;

  &::placeholder {
    color: #bdbdc7;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const TableHeaderWrapper = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;

  @media (max-width: 780px) {
    padding-top: 20px;
    flex-direction: column;
  }
`

export const TableHeaderSelectWrapper = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 780px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const TableHeaderSelect = styled.div`
  width: 48%;

  @media (max-width: 780px) {
    width: 100%;
  }
`

export const TableHeaderSearch = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  color: #e56000;
  font-size: 22px;
  position: relative;
  user-select: none;

  @media (max-width: 780px) {
    width: 100%;
    padding-top: 20px;
    justify-content: flex-start;
  }
`

export const StyledSearchForm = styled.form`
  @media (max-width: 780px) {
    width: 100%;
  }
`

export const StyledBiSearch = styled(BiSearch)`
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  color: #e56000;
  font-size: 20px;

  /* @media (max-width: 780px) {
    top: 28px;
  } */
`

export const StyledModalContent = styled(ModalContent)`
  background-color: #302f37 !important;
  border-radius: 8px;
  min-width: 450px;
  padding: 30px;

  @media (max-width: 480px) {
    min-width: 280px;
    padding: 15px;
  }
`

export const StyledModalHeader = styled(ModalHeader)`
  padding: 10px 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff6363;
  font-size: 24px;
  border-radius: 5px 5px 0 0;
`

export const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 10px 15px !important;
  color: #fff;

  p {
    color: #d3d3d8;
    padding-bottom: 20px;
  }
`

export const ModalButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    height: 100px;
  }
`
