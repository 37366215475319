import React, { useCallback } from 'react'

import {
  Button,
  Popover,
  ButtonGroup,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Text,
} from '@chakra-ui/react'
import { UseMutationResult } from '@tanstack/react-query'
import { Icon } from '@iconify/react'
import { get } from 'lodash'
import { useToastMessage } from 'components/Toast'

interface Props {
  id: number
  updateDeleted: UseMutationResult<void, unknown, any, unknown>
}

export function PopoverDrawerRemovePendingStudies({
  id,
  updateDeleted,
}: Props) {
  /*
  |-----------------------------------------------------------------------------
  | Hook
  |-----------------------------------------------------------------------------
  |
  |
  */
  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | UseCallBack
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDeletedRecovery = useCallback(
    async (onClose: () => void) => {
      try {
        await updateDeleted.mutateAsync({ id })

        showToast({
          title: 'Registro removido.',
          description: 'Registro removido com sucesso.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        onClose()
      } catch (err: any) {
        let errorMessage = 'Não foi possível efetuar a deleção.'

        if (err.response?.data?.errors) {
          errorMessage = err.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        const apiErrorString = get(err, 'response.data')
        if (typeof apiErrorString === 'string') {
          errorMessage = apiErrorString
        }

        showToast({
          title: 'Erro',
          description: errorMessage,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })

        onClose()
      }
    },
    [id, showToast, updateDeleted],
  )

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Icon
              cursor={'pointer'}
              icon={'eva:trash-2-outline'}
              fontSize="24px"
              color="#FFFF"
            />
          </PopoverTrigger>
          <PopoverContent
            _focus={{ outline: 'none' }}
            border="0px"
            backgroundColor={'#2A2A30'}
          >
            <PopoverHeader border={'0px'} px="16px" pt="16px">
              <Text
                fontFamily="Mulish"
                fontWeight="700"
                fontSize="16px"
                lineHeight="20px"
                color="#FFFFFF"
              >
                Deseja excluir o estudo?
              </Text>
            </PopoverHeader>

            <PopoverCloseButton mt="8px" color={'#FFF'} />

            <PopoverBody py="0px" px="16px" border={'0px'}>
              <Text
                fontFamily="Mulish"
                fontWeight="400"
                fontSize="12px"
                lineHeight="150%"
                color="#BDBDC7"
                whiteSpace={'break-spaces'}
              >
                Se continuar, seu estudo será encaminhado para a lixeira.
              </Text>
            </PopoverBody>

            <PopoverFooter
              border="0px"
              display="flex"
              justifyContent="flex-end"
              px="16px"
              pb="16px"
            >
              <ButtonGroup w="100%">
                <Button
                  w="50%"
                  maxH={'34px'}
                  variant="outline"
                  fontFamily="Mulish"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="114%"
                  color="#FFFFFF"
                  onClick={onClose}
                  _hover={{ backgroundColor: '#202024' }}
                >
                  Não
                </Button>

                <Button
                  w="50%"
                  maxH={'34px'}
                  fontFamily="Mulish"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="114%"
                  color="#FFFFFF"
                  backgroundColor={'#E56000'}
                  _hover={{ backgroundColor: '#ff6b00' }}
                  isLoading={updateDeleted.isLoading}
                  onClick={() => handleDeletedRecovery(onClose)}
                >
                  Sim
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
