import {
  AspectRatio,
  Box,
  Heading,
  Image,
  HStack,
  Progress,
  Text,
  VStack,
  Stack,
  chakra,
  Spinner,
  RadioGroup,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldRadio } from 'components/Fields/FieldRadio'
import { useAuth } from 'contexts/auth'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ErrorPage from '../error-page'
import { useGetAnswers } from '../mutations/use-get-answer-question'
import { useGetExamsById } from '../mutations/use-get-exams-by-id'
import { useGetQuestionById } from '../mutations/use-get-question-by-id'
import { useIsSimulationAlreadyDone } from '../mutations/use-get-simulation-already-dona'

export default function SimulationReview() {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [activeQuestion, setActiveQuestion] = useState(1)
  const [chosenAlternative, setChosenAlternative] = useState<string | null>(
    null,
  )

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()
  const { userContract } = useAuth()
  const { data: examById, isLoading: isLoadingGetExamsById } = useGetExamsById({
    id,
  })

  const { data: question, isLoading: isLoadingQuestion } = useGetQuestionById({
    id: activeQuestion
      ? examById?.questions?.[activeQuestion! - 1]?.id
      : undefined,
  })

  const {
    data: answers,
    error: errorAnswers,
    isLoading: isLoadingAnswers,
  } = useGetAnswers({
    limit: 99999999999,
  })

  // trial should do the exam only once
  const {
    data: hasTrialAlreadyTakenTheTest,
    isLoading: isLoadingTrialAlreadyTakenTheTest,
  } = useIsSimulationAlreadyDone({
    isTrial: userContract && userContract.type === 'TRIAL' ? true : false,
    mockExamId: id,
  })

  /*
  |-----------------------------------------------------------------------------
  | useEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const filteredAnswers = answers?.data?.filter(item => {
      return item.mockExamQuestion.id === question?.id
    })

    if (filteredAnswers && filteredAnswers?.length > 0) {
      setChosenAlternative(filteredAnswers?.[0]?.chosen_alternative)
    }
  }, [activeQuestion, answers?.data, question?.id])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!Number(id) || errorAnswers) {
    return <ErrorPage />
  }

  if (
    isLoadingAnswers ||
    isLoadingGetExamsById ||
    isLoadingQuestion ||
    isLoadingTrialAlreadyTakenTheTest
  ) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  if (hasTrialAlreadyTakenTheTest?.hasTakenExam) {
    push('/simulation')
  }

  return (
    <>
      <HStack
        w="fit-content"
        mt="1em"
        _hover={{ translateY: '1px', fontWeight: 600 }}
        as="button"
        onClick={() => push('/simulation')}
      >
        <Icon icon={'bi:arrow-left'} fontSize={'16px'} />
        <Text>Voltar para simulados</Text>
      </HStack>
      <HStack my="2rem" w="100%" maxW={'706px'}>
        <Progress
          colorScheme={'brand'}
          value={
            (examById?.questions?.length &&
              Math.round(
                (activeQuestion / examById?.questions?.length) * 100,
              )) ??
            0
          }
          h="5px"
          w="95%"
          borderRadius={'12px'}
          bg={'#2A2A30'}
        />

        <Text
          fontWeight={'400'}
          fontSize={'14px'}
        >{`${activeQuestion}/${examById?.questions?.length}`}</Text>
      </HStack>

      <Box flexDir={'column'} w="full" maxW={'868px'}>
        <VStack align={'flex-start'}>
          <Heading
            fontWeight={700}
            fontFamily={'Mulish, sans-serif'}
            fontSize={'22px'}
            as={'h2'}
          >
            {`${examById?.title} (${examById?.institution})`}
          </Heading>

          <HStack>
            <Text fontWeight={500} fontSize={'16px'}>
              {activeQuestion}. {question?.statement}
            </Text>
          </HStack>

          <HStack>
            <chakra.span
              display="flex"
              fontWeight={500}
              fontSize={'16px'}
              lineHeight={'20px'}
              mb="1rem"
            >
              Sua resposta: {`Alternativa ${chosenAlternative}) -`}
              <chakra.span display="flex" alignItems="center" gap="8px">
                {question?.correct_alternative === chosenAlternative ? (
                  <>
                    &nbsp;Correta
                    <Icon
                      icon={'akar-icons:circle-check-fill'}
                      fontSize={'16px'}
                      color="#0DDF15"
                    />
                  </>
                ) : (
                  <>
                    &nbsp;Errada
                    <Icon
                      icon={'akar-icons:circle-x-fill'}
                      fontSize={'16px'}
                      color="#D91717"
                    />
                  </>
                )}
              </chakra.span>
            </chakra.span>

            <Icon icon="circle-check-fill" />
          </HStack>
        </VStack>

        {question?.image_highlight && (
          <AspectRatio my="1rem" ratio={16 / 9} sx={{ objectFit: 'contain' }}>
            <Image
              src={question?.image_highlight}
              alt="img"
              sx={{ objectFit: 'contain !important' }}
            />
          </AspectRatio>
        )}

        <RadioGroup position="relative">
          <HStack
            _first={{ mt: '0px' }}
            data-qa={`${
              question?.correct_alternative === 'a'
                ? 'correct_alternative_a'
                : chosenAlternative === 'a' &&
                  question?.correct_alternative !== 'a'
                ? 'incorrect_alternative_a'
                : 'alternative_a'
            }`}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'15px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'a'
                ? '#0DDF15'
                : chosenAlternative === 'a' &&
                  question?.correct_alternative !== 'a'
                ? '#D91717'
                : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio isDisabled value="a" />
            <Text>
              <chakra.span>a)</chakra.span>

              <chakra.span ml="6px">{question?.alternative_a}</chakra.span>
            </Text>
          </HStack>

          <HStack
            _first={{ mt: '0px' }}
            data-qa={`${
              question?.correct_alternative === 'b'
                ? 'correct_alternative_b'
                : chosenAlternative === 'b' &&
                  question?.correct_alternative !== 'b'
                ? 'incorrect_alternative_b'
                : 'alternative_b'
            }`}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'15px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'b'
                ? '#0DDF15'
                : chosenAlternative === 'b' &&
                  question?.correct_alternative !== 'b'
                ? '#D91717'
                : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio isDisabled value="b" />
            <Text>
              <chakra.span>b)</chakra.span>

              <chakra.span ml="6px">{question?.alternative_b}</chakra.span>
            </Text>
          </HStack>

          <HStack
            _first={{ mt: '0px' }}
            data-qa={`${
              question?.correct_alternative === 'c'
                ? 'correct_alternative_c'
                : chosenAlternative === 'c' &&
                  question?.correct_alternative !== 'c'
                ? 'incorrect_alternative_c'
                : 'alternative_c'
            }`}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'15px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'c'
                ? '#0DDF15'
                : chosenAlternative === 'c' &&
                  question?.correct_alternative !== 'c'
                ? '#D91717'
                : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio isDisabled value="c" />
            <Text>
              <chakra.span>c)</chakra.span>

              <chakra.span ml="6px">{question?.alternative_c}</chakra.span>
            </Text>
          </HStack>

          <HStack
            _first={{ mt: '0px' }}
            data-qa={`${
              question?.correct_alternative === 'd'
                ? 'correct_alternative_d'
                : chosenAlternative === 'd' &&
                  question?.correct_alternative !== 'd'
                ? 'incorrect_alternative_d'
                : 'alternative_d'
            }`}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'15px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'd'
                ? '#0DDF15'
                : chosenAlternative === 'd' &&
                  question?.correct_alternative !== 'd'
                ? '#D91717'
                : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio isDisabled value="d" />
            <Text>
              <chakra.span>d)</chakra.span>

              <chakra.span ml="6px">{question?.alternative_d}</chakra.span>
            </Text>
          </HStack>

          {question?.alternative_e && (
            <HStack
              _first={{ mt: '0px' }}
              data-qa={`${
                question?.correct_alternative === 'e'
                  ? 'correct_alternative_e'
                  : chosenAlternative === 'e' &&
                    question?.correct_alternative !== 'e'
                  ? 'incorrect_alternative_e'
                  : 'alternative_e'
              }`}
              mt={'1rem'}
              bg={'#2A2A30'}
              borderRadius={'8px'}
              p={'8px'}
              spacing={'15px'}
              border={'1px solid'}
              borderColor={
                question?.correct_alternative === 'e'
                  ? '#0DDF15'
                  : chosenAlternative === 'e' &&
                    question?.correct_alternative !== 'e'
                  ? '#D91717'
                  : 'transparent'
              }
              cursor={'pointer'}
            >
              <FieldRadio isDisabled value="e" />
              <Text>
                <chakra.span>e)</chakra.span>

                <chakra.span ml="6px">{question?.alternative_e}</chakra.span>
              </Text>
            </HStack>
          )}
        </RadioGroup>

        {(question?.comment || question?.comment_image) && (
          <Box mt="2rem">
            <Heading
              fontWeight={700}
              fontFamily={'Mulish, sans-serif'}
              fontSize={'22px'}
            >
              Comentário da questão
            </Heading>

            {question?.comment && (
              <>
                <Text
                  mt="1rem"
                  fontWeight={500}
                  fontSize={'16px'}
                  lineHeight={'20px'}
                  whiteSpace={'pre-line'}
                >
                  {question?.comment}
                </Text>
                <chakra.span color={'rgb(13, 223, 21)'}>
                  {' '}
                  Resposta correta: {question?.correct_alternative})
                </chakra.span>
              </>
            )}

            {question?.comment_image && (
              <AspectRatio
                my="1rem"
                ratio={16 / 9}
                sx={{ objectFit: 'contain' }}
              >
                <Image
                  src={question?.comment_image}
                  alt="img"
                  sx={{ objectFit: 'contain !important' }}
                />
              </AspectRatio>
            )}
          </Box>
        )}

        {(question?.essential_concept || question?.image_essential_concept) && (
          <Box my="1.5rem" border={'1px solid #FFFFFF'} borderRadius={'4px'}>
            <Box p={'15px'}>
              <Heading
                fontWeight={700}
                fontFamily={'Mulish, sans-serif'}
                fontSize={'22px'}
              >
                Conceito Matador
              </Heading>

              {question?.essential_concept && (
                <Text
                  mt="24px"
                  fontWeight={500}
                  fontSize={'16px'}
                  lineHeight={'20px'}
                >
                  {question?.essential_concept}
                </Text>
              )}
            </Box>

            {question?.image_essential_concept && (
              <AspectRatio
                my="1rem"
                ratio={16 / 9}
                sx={{
                  objectFit: 'contain',
                }}
              >
                <Image
                  src={question?.image_essential_concept}
                  alt="img"
                  sx={{ objectFit: 'contain !important' }}
                />
              </AspectRatio>
            )}
          </Box>
        )}

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: '0.5rem', sm: '1rem' }}
          justify={'flex-end'}
          my={'16px'}
        >
          <DefaultButton
            w="full"
            name="previous_question"
            label="Voltar"
            variant="ghost"
            maxW={{ base: 'unset', sm: '258px' }}
            disabled={activeQuestion === 1}
            onClick={() => setActiveQuestion(prevState => prevState - 1)}
          />
          <DefaultButton
            label="Avançar"
            name="next_question"
            w="full"
            maxW={{ base: 'unset', sm: '278px' }}
            disabled={activeQuestion === examById?.questions?.length}
            onClick={() => setActiveQuestion(prevState => prevState + 1)}
          />
        </Stack>
      </Box>
    </>
  )
}
