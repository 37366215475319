import React from 'react'

import Layout from 'layouts/Logged'

import Header from 'components/Header'

import { Container } from './styles'
import { Box, Link, Text } from '@chakra-ui/react'
import { useAuth } from 'contexts/auth'
import { Icon } from '@iconify/react'
import { isEmpty } from 'lodash'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const Contact: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user, userContract } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Contato"
          subTitle="Formas de contatos e redes sociais."
          /* hasVideoOrientation={isMd ? true : false} */
        />
        {/* {!isMd ? <VideoOrientation mt="8px" /> : null} */}
        <Box mt="24px">
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="600"
            fontSize="16px"
            lineHeight="20px"
          >
            Grupo de estudo whatsapp
          </Text>

          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
            color={'#BDBDC7'}
            mt="2px"
          >
            Entre no nosso grupo do whatsapp, onde você pode consultar seu
            monitor e acompanhar as mensagens.
          </Text>

          {user?.studentMonitor &&
            userContract?.type === 'PURCHASE' &&
            user?.studentMonitor?.[0]?.mentoriaGroup?.[0]
              ?.mentoria_group_link && (
              <Link
                href={
                  user?.studentMonitor?.[0]?.mentoriaGroup?.[0]
                    ?.mentoria_group_link
                }
                target="_blank"
              >
                <DefaultButton
                  mt="16px"
                  leftIcon={
                    <Icon
                      color="#FFFF"
                      fontSize={'14px'}
                      icon="ic:outline-whatsapp"
                    />
                  }
                  w="176px"
                  label="Entrar no grupo"
                />
              </Link>
            )}

          {(!user?.studentMonitor || isEmpty(user.studentMonitor)) &&
            userContract?.type === 'PURCHASE' && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="12px"
                lineHeight="15px"
                color={'#BDBDC7'}
                mt="6px"
              >
                Você ainda não possui um monitor associado. Favor entrar em
                contato com o suporte.
              </Text>
            )}

          {userContract?.type === 'TRIAL' && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight="15px"
              color={'#BDBDC7'}
              mt="6px"
            >
              Disponível somente para alunos Mentoria Residência. Garanta já seu
              acesso completo à plataforma.
            </Text>
          )}
        </Box>
      </Container>
    </Layout>
  )
}

export default Contact
