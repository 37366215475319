import React, { useCallback, useState } from 'react'

export function usePagination() {
  const [page, setPage] = useState(1)

  const onChangePage = useCallback(newPage => {
    setPage(newPage)
  }, [])

  return { page, onChangePage }
}
