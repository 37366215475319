import { Flex } from '@chakra-ui/react'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { useAuth } from 'contexts/auth'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

interface FormRegister {
  name: string
  email: string
  mobilePhone: string
}

export const RegisterTab = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hook.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { control, setValue } = useForm<FormRegister>()
  const { user } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!user) return

    setValue('name', user.name)
    setValue('email', user.email)
    setValue('mobilePhone', user.mobile_phone)
  }, [setValue, user])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      flexDir="column"
      gap="24px"
      maxW={'380px'}
      pl={'20px'}
      w="full"
      onSubmit={d => console.log(d)}
    >
      <FieldInputController
        label="Nome"
        name={'name'}
        control={control}
        placeholder="Nome"
      />

      <FieldInputController
        label="E-mail"
        name={'email'}
        control={control}
        isDisabled
        placeholder="E-mail"
        type="email"
      />

      <FieldInputController
        label="Telefone"
        name={'mobilePhone'}
        control={control}
        type="email"
        placeholder="Telefone"
      />
    </Flex>
  )
}
