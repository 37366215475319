import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

export interface ParamsQuestion {
  chosenAlternative: 'a' | 'b' | 'c' | 'd' | 'e'
  mockExamQuestionId: number
  mockExamId: number
  studiedMinutes?: number
  effortPerception?: number
}

async function answerQuestion(params: ParamsQuestion) {
  await api.post('/app/mock-exam-answers', params)
}

export function useAnswerQuestion() {
  return useMutation(answerQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams-by-id'])
      queryClient.invalidateQueries(['get-simulation-exams'])
      queryClient.invalidateQueries(['get-simulation-exams-answers'])
      queryClient.invalidateQueries(['get-question-by-id'])
    },
  })
}
