import React, { useEffect, useState } from 'react'
import {
  Flex,
  Heading,
  AspectRatio,
  useBreakpointValue,
  VStack,
  Text,
  Spinner,
} from '@chakra-ui/react'
import VideoSection from '../components/VideoSection'
import './Video.css'
import './VideoMobile.css'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useFormContext } from 'react-hook-form'
import { useMutateSetMonitor } from '../mutations/use-mutate-set-monitor'
import { useMutateSetCurrentStep } from '../mutations/mutate-steps'

interface FourthStep {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export default function FourthStep({ setCurrentStep }: FourthStep) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [videoLink, setVideoLink] = useState<string | undefined>(undefined)
  const [monitorName, setMonitorName] = useState<string | undefined>('')
  const [isLoading, setIsLoading] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })
  const { getValues } = useFormContext()
  const mutateSetMonitor = useMutateSetMonitor()
  const mutateCurrentStep = useMutateSetCurrentStep()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  async function setMonitor() {
    try {
      setIsLoading(true)
      const response = await mutateSetMonitor.mutateAsync({
        trackId: getValues('trackId').value,
        trialYear: getValues('trialYear').value,
      })

      /* if (
        !response?.data?.studentMonitor?.[0]?.monitorDetail
          ?.monitor_video_links?.[0].length > 0
      ) {
        setCurrentStep(4)
      } */

      setMonitorName(response?.data?.studentMonitor?.[0]?.name)

      return response?.data?.studentMonitor?.[0]?.monitorDetail
        ?.monitor_video_links?.[0]
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | useEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setMonitor().then(link => setVideoLink(link))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (isLoading) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  return (
    <Flex w="100%" flexDir={{ base: 'column' }}>
      <Flex mb={{ base: '12px', md: '24px' }}>
        <Heading
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ base: '18px', sm: '32px' }}
          lineHeight={{ base: '23px', sm: '40px' }}
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Conheça seu monitor
        </Heading>
      </Flex>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '1rem', sm: '2rem' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '516px' }}
          h={'auto'}
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            {isMobile ? (
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" maxW="480px">
                  {!videoLink ? (
                    <Text p="10px">
                      Associado ao monitor {monitorName}, porém não há vídeo
                      cadastrado. Favor pular para o próximo passo.
                    </Text>
                  ) : (
                    <iframe
                      src={videoLink}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      height="100%"
                      width={'100%'}
                      className="videoEssencialSkillMobile"
                    />
                  )}
                </AspectRatio>
              </VStack>
            ) : (
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                  {!videoLink ? (
                    <Text p="10px">
                      Associado ao monitor {monitorName}, porém não há vídeo
                      cadastrado. Favor pular para o próximo passo.
                    </Text>
                  ) : (
                    <iframe
                      src={videoLink}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      height="100%"
                      width="100%"
                      style={
                        !isMobile
                          ? {
                              borderRadius: '8px',
                              border: '1px solid #e56000',
                            }
                          : {}
                      }
                    />
                  )}
                </AspectRatio>
              </VStack>
            )}
          </Flex>
        </Flex>

        {isMobile ? (
          <>
            <Flex
              h="-moz-max-content"
              w="100%"
              flexDir={'column'}
              gap="23px"
              borderRadius="8px"
              m="0px !important"
            >
              <Flex flexDir={'column'} gap={{ base: '8px', sm: '15px' }}>
                <VideoSection
                  key={`VideoSection${0}`}
                  number={1}
                  videoText={monitorName || ''}
                />
              </Flex>

              <Flex mt="auto" justifyContent={'space-between'} gap="12px">
                <DefaultButton
                  maxW="144px"
                  label="Voltar"
                  w="100%"
                  height="38px"
                  variant="ghost"
                  onClick={() => setCurrentStep(2)}
                />

                <DefaultButton
                  label="Continuar"
                  maxW="144px"
                  height="38px"
                  w="100%"
                  onClick={() => setCurrentStep(4)}
                />
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex
            maxW="368px"
            h="516px"
            minW="176px"
            w="100%"
            p={'24px'}
            flexDir={'column'}
            gap="23px"
            background={'#302F37'}
            borderRadius="8px"
            m="0px !important"
          >
            <Flex flexDir={'column'} gap="15px">
              <VideoSection
                key={`VideoSection${0}`}
                number={1}
                videoText={monitorName || ''}
              />
            </Flex>

            <Flex mt="auto" justifyContent={'center'} gap="12px">
              <DefaultButton
                label="Voltar"
                variant="ghost"
                w="100%"
                onClick={() => setCurrentStep(2)}
              />

              <DefaultButton
                label="Continuar"
                w="100%"
                onClick={() => {
                  try {
                    // should pass to next step even if there is an error on this mutation
                    mutateCurrentStep.mutate({
                      onboardCurrentStep: 4,
                    })
                    setCurrentStep(4)
                  } catch (error) {
                    console.trace(error)
                  }
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
