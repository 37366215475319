import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Link,
  Flex,
} from '@chakra-ui/react'
import { Plus, Minus } from 'react-feather'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { BsWhatsapp } from 'react-icons/bs'

export const FAQAccordion: React.FC = () => {
  const FAQData = [
    {
      question: 'Onde acesso as lives que já aconteceram?',
      answer: (
        <>
          <Text>
            As Lives são gravadas e caso você não possa assistir, deve acessar a
            plataforma MEMBERKIT. Para acessar a memberkit, basta acessar:
            <Link
              textDecoration={'underline'}
              color={'#FFFFFF'}
              href="https://mentoria-residencia.memberkit.com.br/"
            >
              https://mentoria-residencia.memberkit.com.br/
            </Link>
          </Text>
        </>
      ),
    },
    {
      question: 'Qual o número do suporte técnico e financeiro?',
      answer: (
        <Flex flexDir={'column'} gap={4}>
          <Text>
            Para falar com nosso suporte oficial, acione o nosso número: +55 11
            5026-2846.
          </Text>
          <Link
            href="https://api.whatsapp.com/send?phone=551150262846"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DefaultButton
              maxW={'350px'}
              label="Chame o suporte aqui"
              rightIcon={<BsWhatsapp color='color="#40c351"' fill="#40c351" />}
              variant="third"
            />
          </Link>
        </Flex>
      ),
    },
    {
      question: 'Qual a diferença entre monitores e orientadores?',
      answer: (
        <Text>
          O Orientador te dará auxílio na organização do cronograma de estudo e
          planejamento do estudo na semana, já o Monitor é um médico, e hoje
          residente, que já passou por todo o processo que você está passando, o
          monitor será o suporte de dúvidas sobre a melhoria do desempenho,
          melhores estratégias de estudo teórico e correção de questões,
          experiência com período de início de provas e reuniões específicas
          para análise de suas métricas de estudo.
        </Text>
      ),
    },
    {
      question: 'Como funciona o agendamento de reuniões com meu Monitor?',
      answer: (
        <Text>
          Além de acompanhar o aluno no grupo do Whatsapp, o monitor realiza
          reuniões de desempenho e oficinas sobre diversos temas coerentes com a
          preparação para as provas. Você irá receber o link para realizar o
          agendamento pelo Whatsapp ou pela aba de Notificação, aqui na
          Plataforma de Métricas. O Link de agendamento da reunião será enviado
          pelo Whasapp próximo ao horário da reunião.
        </Text>
      ),
    },
    {
      question:
        'Como funciona o agendamento de reuniões com meu Orientador de estudos?',
      answer: (
        <Text>
          O Link de agendamento é enviado mensalmente no seu grupo de Whastapp e
          após agendar o seu horário, seu link da reunião será enviado para o
          mesmo email que você usou para se cadastrar na Plataforma de Métricas.
        </Text>
      ),
    },
    {
      question: 'O que é o Essential Skills?',
      answer: (
        <Text>
          O Essential Skills é composto por 4 psicólogos e 2 coaches. O
          atendimento com o time é individual mediante agendamento, através do
          link que é enviado no grupo do Whatsapp. Nos atendimentos individuais
          serão trabalhados questões como: performance nos estudos, mentalidade
          do aprovado, planejamento, produtividade e questões emocionais.
        </Text>
      ),
    },
    {
      question: 'Quais são as metas, quem vai passar, e quando?',
      answer: (
        <Text>
          As metas serão apresentadas em todas as mentorias magnas, pelo
          Bernardo e Júlia, que acontecem mensalmente no início de todo mês.
          Elas também estão presentes na Dashboard, aqui na Plataforma de
          Métricas.
        </Text>
      ),
    },
    {
      question:
        'Como fazer registro na plataforma de métricas (diário e revisões inteligentes)?',
      answer: (
        <Text>
          Diário é um botão na Plataforma de Métricas, responsável por realizar
          os registros de estudo do aluno. Para utilizar, basta clicar no botão
          diário presente na aba Calendário MR na Plataforma de Métricas e
          preencher com os dados do seu estudo. Ao registrar o seu estudo,
          imediatamente você terá uma nova revisão agendada de acordo com seu %
          de acerto.
        </Text>
      ),
    },
    {
      question: 'Apaguei um registro ou um registro sumiu, o que fazer?',
      answer: (
        <Flex flexDir={'column'} gap={4}>
          <Text>
            Primeiro procure o registro na aba de Registros de estudo, presente
            na aba lateral, aqui da Plataforma de Métricas, caso não encontre,
            procure nosso suporte para auxiliar nessa questão. Para falar com
            nosso suporte oficial, acione o nosso número: +55 11 5026-2846.
          </Text>
          <Link
            href="https://api.whatsapp.com/send?phone=551150262846"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DefaultButton
              maxW={'350px'}
              label="Chame o suporte aqui"
              rightIcon={<BsWhatsapp color='color="#40c351"' fill="#40c351" />}
              variant="third"
            />
          </Link>
        </Flex>
      ),
    },
    {
      question: 'Como acessar o banco de questões da Mentoria?',
      answer: (
        <Text>
          {`O banco de questões oficial da mentoria é o Banco de Questões da
          Medcel. Para acessar o banco de questões da MEDCEL é so clicar na
          opção “banco de questões”, que fica no canto esquerdo da plataforma de
          métricas. Ao acessar é só clicar no banco de questões de interesse e
          logo em seguida na aba "simulados", que fica no canto esquerdo. Caso
          esteja acessando por um celular ou tablet, baixe o aplicativo da
          Medcel, clique em esqueci minha senha e crie uma nova senha enviada ao
          seu email.`}
        </Text>
      ),
    },
  ]

  return (
    <Accordion allowToggle flex="1" color="#D3D3D8">
      {FAQData.map((faq, index) => (
        <AccordionItem key={index} borderColor="#C2D1D9">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  pt="12px"
                  pb="16px"
                  _focus={{ border: '1px solid #C2D1D9' }}
                >
                  {isExpanded ? (
                    <Minus fontSize="12px" style={{ marginRight: '26px' }} />
                  ) : (
                    <Plus fontSize="12px" style={{ marginRight: '26px' }} />
                  )}
                  <Box as="span" flex="1" textAlign="left">
                    {faq.question}
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel pb="30px">{faq.answer}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  )
}
