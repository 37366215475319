import React from 'react'
import {
  Button,
  Popover,
  IconButton,
  ButtonGroup,
  PopoverBody,
  PopoverArrow,
  PopoverFooter,
  PopoverHeader,
  PopoverContent,
  PopoverTrigger,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface PopoverDeleteConfirmationProps {
  icon: IconType
  isLoading?: boolean
  onConfirm: () => void
}

export function PopoverDeleteConfirmation({
  onConfirm,
  icon: Icon,
  isLoading = false,
}: PopoverDeleteConfirmationProps) {
  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              size="sm"
              icon={<Icon />}
              aria-label="label"
              colorScheme="brand"
            />
          </PopoverTrigger>
          <PopoverContent color="blackAlpha.800">
            <PopoverHeader fontWeight="semibold">Confirmação</PopoverHeader>

            <PopoverArrow />

            <PopoverCloseButton />

            <PopoverBody>Deseja realmente realizar essa ação</PopoverBody>

            <PopoverFooter d="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button variant="outline" onClick={onClose}>
                  Cancelar
                </Button>

                <Button
                  onClick={onConfirm}
                  colorScheme="brand"
                  isLoading={isLoading}
                >
                  Confirmar
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
