import React, { useCallback, useState } from 'react'
import {
  chakra,
  Circle,
  Flex,
  FlexProps,
  Img,
  Link,
  Stack,
} from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'

const ChakraSwiper = chakra(Swiper)

type SwiperManager = {
  swiper: SwiperCore
  activeIndex: number
}

interface SwipperComponentProps extends FlexProps {
  imageArraySrc: Array<{
    src: string
    alt: string
    link: string
  }>
}

export const Carousel = ({ imageArraySrc, ...rest }: SwipperComponentProps) => {
  const [manager, setManager] = useState<SwiperManager>({} as SwiperManager)

  const handleChangeSlide = useCallback(
    (index: number) => {
      manager.swiper.slideTo(index)

      setManager({
        ...manager,
        activeIndex: index,
      })
    },
    [manager],
  )

  SwiperCore.use([Autoplay])

  return (
    <Flex
      {...rest}
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius="8px"
      position={'relative'}
    >
      <ChakraSwiper
        spaceBetween={120}
        slidesPerView={1}
        pagination={true}
        className="mySwiper"
        slidesPerGroup={1}
        onAfterInit={s =>
          setManager({
            swiper: s,
            activeIndex: 0,
          })
        }
        onSlideChange={s =>
          setManager({ ...manager, activeIndex: s.realIndex })
        }
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        loop
        width="100%"
      >
        {imageArraySrc.map((image, index) => {
          return (
            <SwiperSlide
              style={{ display: 'flex', justifyContent: 'center' }}
              key={index}
            >
              <Link
                w="full"
                h="full"
                href={image.link}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  objectPosition="center"
                  objectFit="cover"
                  w="100%"
                  h="100%"
                  src={image.src}
                  alt={image.alt}
                />
              </Link>
            </SwiperSlide>
          )
        })}

        <Stack
          py={'15px'}
          position={'absolute'}
          bottom="0"
          left="5"
          direction={{
            base: 'row',
            md: 'row',
          }}
          zIndex={99999}
          className="custom-swiper-pagination"
        >
          {imageArraySrc.map((_, index) => {
            const isActive = manager.activeIndex === index

            return (
              <Circle
                size={{ base: '13px', md: '11px', lg: '13px' }}
                key={index}
                cursor="pointer"
                onClick={() => handleChangeSlide(index)}
                bgColor={isActive ? '#D9D9D9' : 'gray.600'}
                opacity={isActive ? 1 : 0.5}
              />
            )
          })}
        </Stack>
      </ChakraSwiper>
    </Flex>
  )
}
