import React from 'react'

import { AuthContextProvider } from './auth'
import { TrackContextProvider } from './track'
import { InterceptorProvider } from './interceptor'
import { StyledComponentsThemeProvider } from 'styles/ThemeProvider'
import { RecoveryDailyProvider } from './recoveryDaily'

export const AppContextProvider: React.FC = ({ children }) => {
  return (
    <StyledComponentsThemeProvider>
      <AuthContextProvider>
        <InterceptorProvider>
          <RecoveryDailyProvider>
            <TrackContextProvider>{children}</TrackContextProvider>
          </RecoveryDailyProvider>
        </InterceptorProvider>
      </AuthContextProvider>
    </StyledComponentsThemeProvider>
  )
}

export default AppContextProvider
