import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Heading,
  Text,
  HStack,
  ModalCloseButton,
  VStack,
  ModalProps,
  Stack,
  Box,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useForm } from 'react-hook-form'
import { EditTestTable, TestForm } from 'features/dashboard/type'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { RadioCardController } from 'components/Fields/FieldCard/RadioCardController'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { pt } from 'date-fns/locale'
import { FieldDateController } from 'components/Fields/FieldDateNew/FieldDateController'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { useMutateTest } from 'features/dashboard/DashTable/TestTable/queries/use-mutation-create-test'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'

interface ModalTestProps extends Omit<ModalProps, 'children'> {}

export function ModalRecordTest(props: ModalTestProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<EditTestTable>({
    resolver: yupResolver(schema),
  })

  const showToast = useToastMessage()

  const mutateTest = useMutateTest()

  const resetForm = useMemo(() => {
    return {
      date: null,
      institutionName: '',
      numberOfQuestions: 0,
      numberOfCorrectAnswers: 0,
      effortPerception: 5,
      studiedMinutes: 0,
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleEffortHelperText = (value: number) => {
    switch (value) {
      case 1:
      case 2:
        return 'Nenhum esforço'
      case 3:
      case 4:
        return 'Pouco esforço'
      case 5:
      case 6:
        return 'Médio esforço'
      case 7:
      case 8:
        return 'Alto esforço'
      case 9:
      case 10:
        return 'Máximo esforço'
      default:
        return ''
    }
  }

  const submitHandler = useCallback(
    async (formData: TestForm) => {
      try {
        await mutateTest.mutateAsync({
          data: formData,
        })

        showToast({
          title: 'Sucesso',
          description: 'Registro de prova criado com sucesso!',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        props.onClose()
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Infelizmente, não foi possível criar o registro de prova.'

        showToast({
          title: 'Aviso',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [mutateTest, props, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    reset(resetForm)
  }, [resetForm, reset, props?.isOpen])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <Box background={'#302F37'} color={'#FFFF'} p="12px 0px">
        <HStack mb="8px" justifyContent={'space-between'} alignItems={'center'}>
          <Heading as="h1" fontSize={'24px'} fontWeight={700}>
            Provas e simulados
          </Heading>

          <ModalCloseButton
            right={'unset'}
            top={'unset'}
            color={'#FFFF'}
            position={'relative'}
            _focus={{ outline: 'none' }}
          />
        </HStack>

        <Text fontWeight={400} fontSize={'16px'} color={'#FFFF'}>
          Registre suas métricas diariamente.
        </Text>
      </Box>

      <VStack
        as={'form'}
        gap={'8px'}
        flexDir={'column'}
        p="16px 8px 24px 0px"
        color={'#FFFF'}
        background={'#302F37'}
        w="100%"
        spacing={'1rem'}
        onSubmit={handleSubmit(submitHandler)}
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#D9D9D9',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#60606C',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'rgba(0,0,0,0)',
          },
        }}
      >
        <FieldInputController
          name="institutionName"
          control={control}
          label="Nome da instituição"
          placeholder="Insira uma insituição"
          error={errors.institutionName}
        />

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          gap={{ base: '16px', sm: '40px' }}
          w="full"
        >
          <Box className="NewDatePicker">
            <FieldDateController
              label="Data"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
              control={control}
              maxDate={new Date()}
              inputProps={{ _placeholder: { color: 'gray.400' } }}
              error={errors?.date}
              name={'date'}
              dropdownMode="select"
              placeholderText={'00/00/0000'}
            />
          </Box>

          <FieldInputController
            label="Tempo de estudo"
            name="studiedMinutes"
            type="number"
            control={control}
            placeholder="Tempo em minutos"
            onWheel={e => e.currentTarget.blur()}
            error={errors.studiedMinutes}
          />
        </Stack>

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          gap={{ base: '16px', sm: '40px' }}
          w="full"
        >
          <FieldInputController
            label="Questões realizadas"
            name="numberOfQuestions"
            control={control}
            placeholder="00"
            type="number"
            onWheel={e => e.currentTarget.blur()}
            error={errors.numberOfQuestions}
          />

          <FieldInputController
            label="Questões corretas"
            name="numberOfCorrectAnswers"
            control={control}
            placeholder="00"
            type="number"
            onWheel={e => e.currentTarget.blur()}
            error={errors.numberOfCorrectAnswers}
          />
        </Stack>

        <RadioCardController
          name="effortPerception"
          label="Percepção de esforço?"
          control={control}
          options={Array.from({ length: 10 }).map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))}
          error={errors.effortPerception}
        />

        <Text w="100%" color={'#E56000'} fontWeight={500} fontSize={'14px'}>
          {handleEffortHelperText(Number(watch('effortPerception')))}
        </Text>

        <Stack w="full" direction={{ base: 'column', sm: 'row' }}>
          <DefaultButton
            onClick={() => reset(resetForm)}
            w="full"
            variant="ghost"
            label="Limpar"
          />

          <DefaultButton
            w="full"
            isLoading={isSubmitting}
            type="submit"
            label="Registrar"
          />
        </Stack>
      </VStack>
    </>
  )
}
