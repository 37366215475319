import {
  Box,
  chakra,
  Flex,
  HStack,
  Progress,
  Table,
  Tbody,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { TrTableTheoricalStudie } from 'components/Table/StepTwoRecoveryPlanTable/TheoricalStudie/TrTableTheoricalStudie'
import { TheadTableHeadTheoricalStudie } from 'components/Table/StepTwoRecoveryPlanTable/TheoricalStudie/TheadTableHeadTheoricalStudie'
import { CustomTooltip } from 'components/Tooltip'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TheadTablePrevalence } from 'components/Table/StepTwoRecoveryPlanTable/Prevalence/TheadTablePrevalence'
import { TrTablePrevalence } from 'components/Table/StepTwoRecoveryPlanTable/Prevalence/TrTablePrevalence'
import { NewFieldSelect } from 'components/Fields/FieldDashboard'
import { useTrack } from 'contexts/track'
import ReactGA from 'react-ga4'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { ModalRecoveryPlanLoading } from 'components/Modal/ModalRecoveryPlanLoading'
import { yupResolver } from '@hookform/resolvers/yup'
import { StepTwoSchema } from './StepTwoSchema'
import { NoDataTableError } from 'components/Table/StepOneRecoveryPlanTable/NoDataTableError'
import { useMutationGeneratePlan } from 'pages/CreateRecoveryPlan/queries/use-mutation-generate-plan'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'
import {
  ListPendingStudiesData,
  useGetListPendingStudies,
} from 'pages/CreateRecoveryPlan/queries/use-get-pending-studies'
import { capitalize, get, isEmpty, orderBy } from 'lodash'
import FieldInputMaskController from 'components/Fields/FieldMask/FieldInputMaskController'
import { useGetPrevalenceStudies } from 'pages/CreateRecoveryPlan/queries/use-get-prevalence'
import Pagination from 'components/Pagination'
import { useRecoveryDaily } from 'contexts/recoveryDaily'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'

interface FormSecondStep {
  hours: string
  minutes: string
}

type CompletedRecords = ListPendingStudiesData & {
  isCompleted: boolean
}

ReactGA.initialize('G-1X61WSVDPN')

export const StepTwo = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [theoreticalHoursModal, setTheoreticalHoursModal] = useState(false)
  const [theoreticalHoursModalMax, setTheoreticalHoursModalMax] = useState(
    false,
  )
  const [generatePlanLoading, setGeneratePlanLoading] = useState(false)
  const [areaFilter, setAreaFilter] = useState<{
    label: string
    value: number
  } | null>({
    label: 'Todos',
    value: 9999,
  })
  const [statusFilter, setStatusFilter] = useState<
    '+isProgrammedStudy' | '-isProgrammedStudy'
  >('-isProgrammedStudy')
  const [
    selectedUser,
    setSelectedUser,
  ] = useState<ListPendingStudiesData | null>(null)
  const [schemaError, setSchemaError] = useState<any>(null)
  const [pagePrevalence, setPagePrevalence] = useState(1)
  const [programmedStudies, setProgrammedStudies] = useState<
    CompletedRecords[]
  >([])
  const [theoreticalEmptyStudies, setTheoreticalEmptyStudies] = useState(false)
  const isSm = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    setFocus,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormSecondStep>({
    resolver: yupResolver(StepTwoSchema),
    mode: 'all',
    defaultValues: {
      hours: '0',
      minutes: '0',
    },
  })

  const { setStep } = useRecoveryDaily()
  const showToast = useToastMessage()
  const watchHours = watch('hours')
  const watchMinutes = watch('minutes')
  const { track } = useTrack()
  const generatePlan = useMutationGeneratePlan()
  const { data: PrActivatedInfo } = useGetPrInfo()
  const {
    data: listPendingStudiesData,
    isError: isErrorPendingStudies,
    isLoading: isLoadingPendingStudies,
  } = useGetListPendingStudies({
    limit: 999999,
    isTheoretical: true,
    fieldId: areaFilter?.value === 9999 ? undefined : areaFilter?.value,
  })

  const {
    data: prevalenceData,
    isError: isPrevalenceError,
    isLoading: isPrevalenceLoading,
  } = useGetPrevalenceStudies({
    page: pagePrevalence,
    limit: 8,
    fieldId: areaFilter?.value === 9999 ? undefined : areaFilter?.value,
  })

  /*
  |-----------------------------------------------------------------------------
  | Memo
  |-----------------------------------------------------------------------------
  |
  |
  */

  const totalHoursStatusAdded = useMemo(() => {
    if (programmedStudies) {
      return programmedStudies?.reduce((acc, currentValue) => {
        if (currentValue.isCompleted) {
          return acc + Number(currentValue.study_time_in_minutes)
        }
        return acc
      }, 0)
    }
    return 0
  }, [programmedStudies])

  const hoursAvailableOnWeek = useMemo(() => {
    const hours = watchHours
    const minutes = watchMinutes

    if (hours || minutes) {
      return Number(hours) * 60 + Number(minutes)
    }

    return 0
  }, [watchHours, watchMinutes])

  /*
  |-----------------------------------------------------------------------------
  | useCallBack
  |-----------------------------------------------------------------------------
  |
  |
  */

  const calcAvailablePercentage = useCallback(
    (hoursAvailableOnWeek: number, totalHoursStatusAdded: number) => {
      // 60% of the total hours represents 100% of the bar- should be available
      // only that percent for theoretical studies
      const totalBar = hoursAvailableOnWeek * 0.6

      // avoid infinity
      if (!totalBar) {
        return 0
      }

      const result = (totalHoursStatusAdded / totalBar) * 100

      return Number(result.toFixed(2))
    },
    [],
  )

  /*
  |-----------------------------------------------------------------------------
  | useEffect
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (errors) {
      setSchemaError(errors)
    }

    if (!errors) {
      clearErrors('hours')
      clearErrors('minutes')
      setSchemaError('')
    }
  }, [clearErrors, errors, schemaError])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const submitForm = async (data: FormSecondStep) => {
    if (programmedStudies.length === 0) {
      setTheoreticalEmptyStudies(true)
      return
    }

    setGeneratePlanLoading(true)

    try {
      await generatePlan.mutateAsync({
        id: PrActivatedInfo?.pendingStudiesData.id!,
        payload: {
          availableStudyMinutes: Number(data.hours) * 60 + Number(data.minutes),
          theoreticalPendingStudiesIds: programmedStudies.map(item => item.id),
        },
      })
    } catch (err: any) {
      let errorMessage = 'Não foi possível registrar o plano.'

      if (err.response?.data?.errors) {
        errorMessage = err.response.data.errors
          .map((err: { [key: string]: string }) => err.message)
          .join('\n')
      }

      const apiErrorString = get(err, 'response.data')
      if (typeof apiErrorString === 'string') {
        errorMessage = apiErrorString
      }

      setErrorMessage(errorMessage)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const handleAddTheoricalStudies = async (
    selectedUser: ListPendingStudiesData,
    isAlreadyAdded: boolean,
  ) => {
    if (
      calcAvailablePercentage(hoursAvailableOnWeek, totalHoursStatusAdded) < 100
    ) {
      if (isAlreadyAdded) {
        setProgrammedStudies(prev => [
          ...prev,
          { ...selectedUser, isCompleted: true },
        ])

        showToast({
          title: 'Sucesso',
          description: 'Estudo adicionado.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      }

      if (!isAlreadyAdded) {
        setProgrammedStudies(prev =>
          prev.filter(item => item.id !== selectedUser.id),
        )

        showToast({
          title: 'Removido',
          description: 'Estudo removido.',
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    }

    if (
      calcAvailablePercentage(hoursAvailableOnWeek, totalHoursStatusAdded) >=
        100 &&
      totalHoursStatusAdded < hoursAvailableOnWeek
    ) {
      const isAlreadyAdded = programmedStudies.find(
        stateItem => stateItem.id === selectedUser.id,
      )
      if (!isAlreadyAdded) {
        setTheoreticalHoursModal(true)
      }

      if (isAlreadyAdded) {
        setProgrammedStudies(prev =>
          prev.filter(item => item.id !== selectedUser.id),
        )

        showToast({
          title: 'Removido',
          description: 'Estudo removido.',
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    }

    if (
      totalHoursStatusAdded >= hoursAvailableOnWeek &&
      !!totalHoursStatusAdded &&
      hoursAvailableOnWeek
    ) {
      const isAlreadyAdded = programmedStudies.find(
        stateItem => stateItem.id === selectedUser.id,
      )
      if (!isAlreadyAdded) {
        setTheoreticalHoursModalMax(true)
      }

      if (isAlreadyAdded) {
        setProgrammedStudies(prev =>
          prev.filter(item => item.id !== selectedUser.id),
        )

        showToast({
          title: 'Removido',
          description: 'Estudo removido.',
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    }
  }

  const previousStepTrigger = () => {
    setStep(0)
    ReactGA.event({
      category: 'recovery_plan',
      action: 'recovery_plan_return_press',
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Flex gap={'10px'} flexDir="column">
        <Flex>
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize="20px"
            lineHeight="25px"
            color="#FFFFFF"
          >
            Disponibilidade
          </Text>
        </Flex>

        <Text
          fontFamily="Mulish"
          fontWeight="400"
          fontSize="14px"
          lineHeight="160%"
          color="#BDBDC7"
        >
          Insira o <chakra.span color={'#FFFF'}>total de horas</chakra.span>{' '}
          disponíveis para recuperar estudos atrasados{' '}
          <chakra.span color={'#FFFF'}>nesta semana.</chakra.span> Lembre-se de
          desconsiderar o tempo que irá estudar para as aulas desta semana.
        </Text>
      </Flex>

      <Flex flexDir={'column'} mt="24px" gap="8px">
        <Text
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          textAlign="left"
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Tempo disponível na semana
        </Text>

        <Flex flexDir={'column'} gap="8px">
          <Flex gap="8px">
            <Box
              w={'100%'}
              onClick={() => setFocus('hours')}
              maxW="100px"
              position={'relative'}
            >
              <chakra.span
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  zIndex: 9,
                  right: 7,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                hora(s)
              </chakra.span>
              <FieldInputMaskController
                mask={'99'}
                maskPlaceholder=""
                name="hours"
                control={control}
                placeholder={'00'}
                onKeyPress={handleKeyPress}
                // error={errors.hours}
              />
            </Box>

            <Box
              w={'100%'}
              onClick={() => setFocus('minutes')}
              maxW="115px"
              position={'relative'}
            >
              <chakra.span
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  zIndex: 9,
                  right: 7,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                minuto(s)
              </chakra.span>
              <FieldInputMaskController
                mask={'99'}
                maskPlaceholder=""
                name="minutes"
                control={control}
                placeholder="00"
                onKeyPress={handleKeyPress}
                /* error={errors.name} */
              />
            </Box>
          </Flex>
          {!!schemaError?.schemaError?.message && (
            <Text
              color="#FF6363"
              fontWeight="500"
              fontSize="10px"
              lineHeight="13px"
            >
              {schemaError?.schemaError?.message}
            </Text>
          )}
        </Flex>
      </Flex>

      <VStack align={'flex-start'} mt="32px" spacing={'8px'}>
        <HStack
          w="100%"
          maxW={'672px'}
          spacing={'8px'}
          justifyContent="space-between"
        >
          <Flex gap={'8px'} alignItems="center" justifyContent={'center'}>
            <Text
              fontFamily="Mulish"
              fontWeight="600"
              fontSize="18px"
              lineHeight="23px"
              color="#FFFFFF"
            >
              Tempo de estudo teórico
            </Text>

            <CustomTooltip
              label={
                'Registramos a porcentagem de tempo alocado para a realização exclusiva de estudo teórico pendente, que você já selecionou para esta semana.'
              }
              backgroundColor={'#302F37'}
              p="16px"
              borderRadius={'4px'}
            >
              <Icon icon={'ri:question-line'} color="#E56000" />
            </CustomTooltip>
          </Flex>

          <Text
            fontFamily="Mulish"
            fontWeight="500"
            fontSize="16px"
            lineHeight="20px"
          >
            {`${calcAvailablePercentage(
              hoursAvailableOnWeek,
              totalHoursStatusAdded,
            )}%`}
          </Text>
        </HStack>

        <Progress
          colorScheme={
            calcAvailablePercentage(
              hoursAvailableOnWeek,
              totalHoursStatusAdded,
            ) > 60
              ? 'greenProgressBar'
              : 'yellowProgressBar'
          }
          borderRadius={'4px'}
          value={calcAvailablePercentage(
            hoursAvailableOnWeek,
            totalHoursStatusAdded,
          )}
          maxWidth="672px"
          w="100%"
        />

        {calcAvailablePercentage(hoursAvailableOnWeek, totalHoursStatusAdded) >
          100 && (
          <Text
            fontFamily="Mulish"
            fontWeight="500"
            fontSize="10px"
            lineHeight="13px"
            color={'#BDBDC7'}
          >
            Estudos adicionados superam a disponibilidade de horas alocadas para
            o plano de recuperação semanal.
          </Text>
        )}
      </VStack>

      <Flex
        w="100%"
        gap={'1rem'}
        mt="24px"
        flexDir={{ base: 'column', xl: 'row' }}
      >
        <Flex flexDir={'column'} w={{ base: '100%', xl: '65%' }} gap="1rem">
          <Flex
            justifyContent={'space-between'}
            flexDir={{ base: 'column', xl: 'row' }}
            gap={{ base: '10px', xl: '0px' }}
          >
            <HStack spacing={'10px'}>
              <Text
                fontFamily="Mulish"
                fontWeight="600"
                fontSize="18px"
                lineHeight="23px"
                color="#FFFFFF"
              >
                Estudos teóricos pendentes
              </Text>

              <CustomTooltip
                label={`Adicione os estudos teóricos para criar o seu Plano de Recuperação desta semana. A barra acima representa somente o tempo reservado para estudos teóricos pendentes.

Os estudos práticos (Primeiro contato e Revisão) serão agendados automaticamente de acordo com nosso algoritmo.`}
                backgroundColor={'#302F37'}
                p="16px"
                maxW={'496px'}
                w="100%"
                borderRadius={'4px'}
                whiteSpace="break-spaces"
              >
                <Icon icon={'ri:question-line'} color="#E56000" />
              </CustomTooltip>
            </HStack>

            <Box maxW={'280px'} w="100%">
              <NewFieldSelect
                placeholder="Selecione uma grande área"
                options={[
                  {
                    label: 'Todos',
                    value: 9999,
                  },
                  ...(track?.fields?.map(field => ({
                    label: capitalize(field.name),
                    value: field.id,
                  })) ?? []),
                ]}
                onChange={(e: { label: string; value: number }) =>
                  setAreaFilter(e as { label: string; value: number })
                }
                value={areaFilter}
              />
            </Box>
          </Flex>

          <Box
            maxH="364px"
            minH="364px"
            background="#393940"
            borderRadius={'8px 8px 8px 8px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
            overflow="auto"
          >
            <Table background={'#302F37'}>
              <TheadTableHeadTheoricalStudie
                onStatusClickOrderButton={() =>
                  setStatusFilter(prev =>
                    prev === '-isProgrammedStudy'
                      ? '+isProgrammedStudy'
                      : '-isProgrammedStudy',
                  )
                }
                statusOrder={statusFilter}
              />
              <Tbody borderBottomColor="#302F37">
                {!isEmpty(listPendingStudiesData?.data) &&
                  !isErrorPendingStudies &&
                  !isLoadingPendingStudies &&
                  orderBy(
                    listPendingStudiesData?.data,
                    element =>
                      programmedStudies.findIndex(ps => ps.id === element.id),
                    statusFilter === '+isProgrammedStudy' ? 'asc' : 'desc',
                  ).map((item, index) => {
                    return (
                      <TrTableTheoricalStudie
                        key={`TableSectionData${index}`}
                        backgroundTable={
                          index % 2 == 0 ? 'rgba(57, 57, 64, 0.64)' : '#393940'
                        }
                        area={item.field.name}
                        status={
                          programmedStudies.find(
                            stateItem => stateItem.id === item.id,
                          )?.isCompleted
                            ? true
                            : false
                        }
                        subTheme={item.subjects}
                        onClick={() => {
                          setSelectedUser(item)
                        }}
                        theme={item.themes}
                        hours={item.study_time_in_minutes}
                        borderLeft={
                          programmedStudies?.find(
                            stateItem => stateItem.id === item.id,
                          )?.isCompleted
                            ? '6px solid #E5C009'
                            : '6px solid #FF6363'
                        }
                        onClickActionPlus={() => {
                          const isAlreadyAdded = programmedStudies.find(
                            stateItem => stateItem.id === item.id,
                          )

                          handleAddTheoricalStudies(item, !isAlreadyAdded)
                        }}
                        onClickActionMinus={async () => {
                          const isAlreadyAdded = programmedStudies.find(
                            stateItem => stateItem.id === item.id,
                          )

                          handleAddTheoricalStudies(
                            item,
                            !isAlreadyAdded?.isCompleted,
                          )
                        }}
                      />
                    )
                  })}
              </Tbody>
            </Table>
            {listPendingStudiesData?.data.length === 0 &&
              !isErrorPendingStudies &&
              !isLoadingPendingStudies && (
                <Box w="100%">
                  <NoDataTableError
                    headerMessage="Nenhum estudo teórico cadastro."
                    messageElement={
                      <Text
                        textAlign={'center'}
                        color="#BDBDC7"
                        fontWeight="600"
                        fontSize={isSm ? '0.8rem' : '1rem'}
                        lineHeight="150%"
                        fontFamily="Mulish"
                      >
                        Para adicionar estudos teóricos retorne para a página
                        anterior dando um{' '}
                        <chakra.span
                          onClick={() => setStep(0)}
                          textDecoration={'underline'}
                          cursor={'pointer'}
                        >
                          {' '}
                          click aqui.
                        </chakra.span>
                      </Text>
                    }
                    icon={
                      <Icon
                        fontSize={isSm ? '32px' : '64px'}
                        color="#FFFF"
                        icon="ri:file-text-line"
                      />
                    }
                    h={'100%'}
                    borderRadius={'8px'}
                  />
                </Box>
              )}

            {isErrorPendingStudies && !isLoadingPendingStudies && (
              <Box maxH="364px" w="100%">
                <NoDataTableError
                  headerMessage="Nenhum estudo cadastrado!"
                  message="Adicione novos estudos no lado esquerdo."
                  icon={
                    <Icon
                      fontSize={isSm ? '32px' : '64px'}
                      color="#FFFF"
                      icon="ri:file-text-line"
                    />
                  }
                  h={'100%'}
                  borderRadius={'8px'}
                />
              </Box>
            )}
          </Box>
        </Flex>

        <Flex w={{ base: '100%', xl: '40%' }} gap={'1rem'} flexDir="column">
          <Text
            fontFamily="Mulish"
            fontWeight="600"
            fontSize="18px"
            lineHeight={'23px'}
            color={'#FFFFFF'}
          >
            Prevalência
          </Text>

          <Box
            maxH="365px"
            mt="17px"
            minH="364px"
            background="#393940"
            borderRadius={'8px 8px 8px 8px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
            overflow="auto"
          >
            <Table background={'#302F37'}>
              <TheadTablePrevalence />
              <Tbody borderBottomColor="#302F37">
                {!isEmpty(prevalenceData) &&
                  !isPrevalenceError &&
                  !isPrevalenceLoading &&
                  prevalenceData?.data.map((item, index) => {
                    return (
                      <TrTablePrevalence
                        key={`TablePrevalence${index}`}
                        backgroundTable={
                          index % 2 == 0 ? 'rgba(57, 57, 64, 0.64)' : '#393940'
                        }
                        area={item.field}
                        prevalence={item.prevalence_percentage}
                        theme={item.theme}
                      />
                    )
                  })}
              </Tbody>
            </Table>

            {isPrevalenceError && !isPrevalenceLoading && (
              <NoDataTableError
                w="100%"
                headerMessage="Erro ao carregar!"
                message="Erro ao carregar tabela de prevalência!"
              />
            )}

            {isEmpty(prevalenceData?.data) &&
              !isPrevalenceLoading &&
              !isPrevalenceError && (
                <NoDataTableError
                  w="100%"
                  headerMessage="Sem dados!"
                  message="Dado não encontrado"
                />
              )}
          </Box>

          <Pagination
            currentPage={pagePrevalence}
            totalCountOfRegisters={prevalenceData?.meta.total ?? 0}
            onPageChange={setPagePrevalence}
            registersPerPage={8}
          />
        </Flex>
      </Flex>
      <Flex
        flexDir={{ base: 'column', sm: 'row' }}
        justifyContent={'flex-end'}
        gap="1rem"
        mt="24px"
        w="100%"
      >
        <DefaultButton
          label="Voltar"
          variant="ghost"
          fontWeight="400"
          borderRadius="8px"
          maxW={{ base: '100%', sm: '134px' }}
          w={'100%'}
          onClick={() => previousStepTrigger()}
        />

        <DefaultButton
          maxW={{ base: '100%', sm: '152px' }}
          w={'100%'}
          isLoading={isSubmitting}
          type="submit"
          label="Gerar plano"
        />
      </Flex>

      <ModalDefaultTwoButton
        isOpen={theoreticalHoursModal}
        icon={
          <Icon
            color="#E5C009"
            fontSize={'84px'}
            icon={'fluent:clock-dismiss-24-regular'}
          />
        }
        titleNode={
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize={'20px'}
            color={'#FFFFFF'}
            mb={isSm ? '8px' : '16px'}
            textAlign={'center'}
          >
            Limite de aula teórica atingido, deseja continuar?
          </Text>
        }
        subTitle=" Se continuar, poderá perder horas destinadas aos estudos práticos. "
        onClose={() => setTheoreticalHoursModal(false)}
        onConfirmClick={() => {
          if (selectedUser) {
            const isAlreadyAdded = programmedStudies?.find(
              stateItem => stateItem.id === selectedUser.id,
            )

            if (!isAlreadyAdded) {
              setProgrammedStudies(prev => [
                ...prev,
                { ...selectedUser, isCompleted: true },
              ])

              setTheoreticalHoursModal(false)
            }
          }
        }}
      />

      <ModalDefaultTwoButton
        isOpen={theoreticalHoursModalMax}
        icon={
          <Icon
            color="#FF6363"
            fontSize={'84px'}
            icon={'fluent:clock-dismiss-24-regular'}
          />
        }
        subTitle="Se continuar, você não terá estudos práticos adicionados automaticamente no PR. Caso desejar adicionar estudos práticos, terá que realizar manualmente."
        onClose={() => setTheoreticalHoursModalMax(false)}
        onConfirmClick={() => {
          if (selectedUser) {
            const isAlreadyAdded = programmedStudies.find(
              stateItem => stateItem.id === selectedUser.id,
            )

            if (!isAlreadyAdded) {
              setProgrammedStudies(prev => [
                ...prev,
                { ...selectedUser, isCompleted: true },
              ])

              setTheoreticalHoursModalMax(false)
              showToast({
                title: 'Sucesso',
                description: 'Estudo adicionado.',
                type: 'success',
                duration: 2000,
                mode: 'dark',
              })
            }
          }
        }}
        titleNode={
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize={'20px'}
            color={'#FFFFFF'}
            mb={isSm ? '8px' : '16px'}
            textAlign={'center'}
          >
            Limite de aula teórica{' '}
            <chakra.span color="#FF6363">máximo </chakra.span>atingido, deseja
            continuar?
          </Text>
        }
      />

      <ModalRecoveryPlanLoading
        isOpen={generatePlanLoading}
        onClose={() => setGeneratePlanLoading(false)}
        isError={generatePlan.isError}
        errorMessageFromAPI={errorMessage}
      />

      <ModalDefaultTwoButton
        isOpen={theoreticalEmptyStudies}
        icon={
          <Icon
            color="#FF6363"
            fontSize={'84px'}
            icon={'fluent:clock-dismiss-24-regular'}
          />
        }
        subTitle="Você não possui estudos teóricos selecionados. Mesmo assim, é possível gerar o plano apenas se estiver estudos práticos. Deseja continuar mesmo assim?"
        onClose={() => setTheoreticalEmptyStudies(false)}
        onConfirmClick={async () => {
          setTheoreticalEmptyStudies(false)
          setGeneratePlanLoading(true)
          try {
            await generatePlan.mutateAsync({
              id: PrActivatedInfo?.pendingStudiesData.id!,
              payload: {
                availableStudyMinutes:
                  Number(getValues('hours')) * 60 +
                  Number(getValues('minutes')),
                theoreticalPendingStudiesIds: programmedStudies.map(
                  item => item.id,
                ),
              },
            })
            ReactGA.event({
              category: 'recovery_plan',
              action: 'recovery_plan_created',
            })
          } catch (err: any) {
            let errorMessage = 'Não foi possível registrar o plano.'

            if (err.response?.data?.errors) {
              errorMessage = err.response.data.errors
                .map((err: { [key: string]: string }) => err.message)
                .join('\n')
            }

            const apiErrorString = get(err, 'response.data')
            if (typeof apiErrorString === 'string') {
              errorMessage = apiErrorString
            }

            setErrorMessage(errorMessage)
          }
        }}
      />
    </form>
  )
}
