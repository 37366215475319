import React, { useCallback, useEffect, useState } from 'react'
import {
  BoxProps,
  Text,
  Flex,
  HStack,
  VStack,
  Stack,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react'
import api from 'services/api'
import { Track } from 'contexts/track/interfaces'
import { useFormContext } from 'react-hook-form'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { PREP_SCHOOLS } from 'constants/prepSchools'
import { SelectDragNDropController } from 'components/Fields/SelectDragNDrop'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { INSTITUTIONS } from 'constants/institutions'
import { getYear } from 'date-fns'
import CardSecondStepTrack from '../components/CardSecondStepTrack'
import { RadioGroupController } from 'components/Fields/FieldCard/FieldCardController'
import { FieldRadioButton } from 'components/Fields/FieldCard/FieldCard'
import { useMutateSetCurrentStep } from '../mutations/mutate-steps'
import { SPECIALTIES } from 'constants/specialties'
import { error } from 'console'

type FirstStepProps = BoxProps & {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export default function SecondStep({ ...props }: FirstStepProps) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [track, setTrack] = useState<Track[] | undefined>(undefined)
  const [R3selected, setR3Selected] = useState<
    | {
        label: string
        value: number
      }
    | undefined
  >(undefined)
  const [shouldShowR3Options, setShouldShowR3Options] = useState(false)
  const currentYear = getYear(new Date())
  const mutateCurrentStep = useMutateSetCurrentStep()

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext()
  const isMd = useBreakpointValue({ base: false, md: true })

  /*
  |-----------------------------------------------------------------------------
  | Effect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    async function fetchData() {
      api
        .get<{ data: Track[] }>('/app/tracks')
        .then(({ data }) => {
          if (!data) return

          setTrack(data.data)
        })
        .catch(err => {
          console.error('Failed on fetching available tracks. ', err)
        })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (
      watch('trackId')?.label === 'R+ CGE' ||
      watch('trackId')?.label === 'R+ GO-MASTOLOGIA' ||
      watch('trackId')?.label === 'R+ CM' ||
      watch('trackId')?.label === 'R+ PED'
    ) {
      setR3Selected({
        label: 'R+',
        value: 99,
      })
      setShouldShowR3Options(true)
    }

    if (Number(R3selected?.value) !== 99) {
      setShouldShowR3Options(false)
    }

    const trackEqualsR1PNARevalida = track?.filter(track => {
      return (
        track.name === 'R1' ||
        track.name === 'Revalida' ||
        track.name === 'PNA PORTUGAL'
      )
    })

    const watchTrackId = watch(data => {
      if (
        trackEqualsR1PNARevalida?.find(
          item => Number(item.id) === Number(data?.trackId?.value),
        )
      ) {
        setR3Selected(undefined)
        setShouldShowR3Options(false)
      }
    })

    return () => {
      watchTrackId.unsubscribe()
    }
  }, [setValue, R3selected, watch, track])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const validateSchema = async () => {
    const [
      intendedInstitutions,
      prepSchool,
      trackId,
      trialYear,
      intendedSpecialties,
    ] = await Promise.all([
      trigger('intendedInstitutions'),
      trigger('prepSchool'),
      trigger('trackId'),
      trigger('trialYear'),
      trigger('intendedSpecialties'),
    ])

    return {
      intendedInstitutions,
      prepSchool,
      trackId,
      trialYear,
      intendedSpecialties,
    }
  }

  const getDescriptionByTrack = useCallback((track: string) => {
    const tracks: Record<string, string> = {
      'R+':
        'Trilha de estudos exclusiva para médicos que já realizaram a primeira residência e irão prestar sub-especialização.',
      Revalida:
        'Trilha de estudos para o Processo para Revalidação de diploma para médicos formados fora do Brasil.',
      'PNA PORTUGAL':
        'Trilha de estudos para a Prova Nacional de Acesso para Residência Médica em Portugal.',
      R1:
        'Trilha de estudos para médicos e acadêmicos de medicina que irão cursar a sua primeira Residência Médica',
    }

    return tracks[track]
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack
      direction={'row'}
      alignItems={'flex-start'}
      {...props}
      w="100%"
      gap="1rem"
      justifyContent={'space-between'}
    >
      <VStack spacing={4} alignItems={'flex-start'}>
        <Text fontWeight={'500'} fontSize={'24px'}>
          Escolha a sua{' '}
          <chakra.span fontWeight={'bold'} color="#FF6B00">
            trilha de estudo
          </chakra.span>
        </Text>

        <Stack w="full">
          <Flex gap="8px" flexWrap={'wrap'}>
            <RadioGroupController
              name="trackId"
              labelExtractor={item => item.label}
              control={control}
              options={
                track
                  ?.filter(
                    track =>
                      track.name === 'R1' ||
                      track.name === 'Revalida' ||
                      track.name === 'PNA PORTUGAL',
                  )
                  .map(item => {
                    return {
                      label: item.name,
                      value: item.id,
                    }
                  }) || []
              }
            />

            {track && (
              <FieldRadioButton
                labelExtractor={item => item.label}
                options={[{ label: 'R+', value: 99 }]}
                onChange={value => {
                  setR3Selected(value)
                }}
                value={R3selected}
                onClick={() => {
                  setR3Selected({
                    label: 'R+',
                    value: 99,
                  })
                  setValue('trackId', null)
                  setShouldShowR3Options(true)
                }}
              />
            )}
          </Flex>

          {errors.trackId && (
            <Text color={'red.500'} fontSize={'14px'}>
              {JSON.stringify(errors?.trackId?.message)}
            </Text>
          )}
        </Stack>

        {shouldShowR3Options && (
          <Flex w="full" flexDir={'column'}>
            <Text mb="1rem" fontWeight={'500'} fontSize={'24px'}>
              Escolha a sua{' '}
              <chakra.span fontWeight={'bold'} color="#FF6B00">
                trilha de estudo 2
              </chakra.span>
            </Text>

            <HStack w="auto" flexWrap={'wrap'}>
              {track
                ?.filter(
                  track =>
                    track.name !== 'R1' &&
                    track.name !== 'Revalida' &&
                    track.name !== 'PNA PORTUGAL',
                )
                .map(item => {
                  return (
                    <FieldRadioButton
                      key={`Field-Card-${item.id}`}
                      options={
                        item ? [{ label: item.name, value: item.id }] : []
                      }
                      onClick={() => {
                        setValue('trackId', {
                          label: item.name,
                          value: item.id,
                        })
                      }}
                      value={watch('trackId')}
                      labelExtractor={item => item.label}
                      background={
                        watch('trackId')?.value === item.id
                          ? '#FF6B00'
                          : '#1a202c'
                      }
                    />
                  )
                })}
            </HStack>
          </Flex>
        )}

        <VStack
          alignItems={'flex-start'}
          spacing={'1rem'}
          fontFamily="Mulish"
          maxW="434px"
          minW={{ base: 'unset', md: '434px' }}
          w="100%"
        >
          <NewFieldSelectRegistryController
            options={PREP_SCHOOLS.map(prepSchool => ({
              value: prepSchool,
              label: prepSchool,
            }))}
            control={control}
            name="prepSchool"
            placeholder="Selecione uma opção"
            label="Faz cursinho de residência?"
            error={errors.prepSchool}
          />

          <NewFieldSelectRegistryController
            isClearable
            label="Especialidades pretendidas"
            isMulti={true as any}
            options={SPECIALTIES.map(specialty => ({
              value: specialty,
              label: specialty,
            }))}
            placeholder="Selecione uma especialidade"
            name="intendedSpecialties"
            control={control}
            error={errors.intendedSpecialties as any}
          />

          <SelectDragNDropController
            label="Onde você vai passar?"
            options={INSTITUTIONS.map(institution => ({
              value: institution,
              label: institution,
            }))}
            control={control}
            name="intendedInstitutions"
            error={errors.intendedInstitutions as any}
          />

          <NewFieldSelectRegistryController
            label="Quando você faz a prova?"
            options={[
              {
                label: `Presto prova final de ${currentYear} - R1/R+ em ${
                  currentYear + 1
                }`,
                value: currentYear,
              },
              {
                label: `Presto prova final de ${currentYear + 1} - R1/R+ em ${
                  currentYear + 2
                }`,
                value: currentYear + 1,
              },
              {
                label: `Presto prova final de ${currentYear + 2} - R1/R+ em ${
                  currentYear + 3
                }`,
                value: currentYear + 2,
              },
            ]}
            isClearable
            control={control}
            name="trialYear"
            placeholder="Selecione uma opção"
            error={errors.trialYear}
          />

          <DefaultButton
            label="Continuar"
            w="100%"
            mt="1.5rem !important"
            h="55px"
            isLoading={mutateCurrentStep.isLoading}
            onClick={async () => {
              try {
                const {
                  intendedInstitutions,
                  prepSchool,
                  trackId,
                  trialYear,
                  intendedSpecialties,
                } = await validateSchema()

                if (
                  !intendedInstitutions ||
                  !prepSchool ||
                  !trackId ||
                  !trialYear ||
                  !intendedSpecialties
                ) {
                  return
                }

                // should pass to next step even if there is an error on this mutation
                mutateCurrentStep.mutate({
                  onboardCurrentStep: 2,
                })

                if (
                  intendedInstitutions &&
                  prepSchool &&
                  trackId &&
                  trialYear
                ) {
                  return props.setCurrentStep(2)
                }
              } catch (error) {
                console.trace(error)
              }
            }}
          />
        </VStack>
      </VStack>

      {isMd && (
        <VStack spacing={'1rem'} maxW="500px">
          {track
            ?.filter(
              track =>
                track.name === 'R1' ||
                track.name === 'Revalida' ||
                track.name === 'PNA PORTUGAL',
            )
            .map(item => {
              return (
                <CardSecondStepTrack
                  key={`card-track-${item.id}`}
                  matchSelectedTrack={
                    Number(watch('trackId')?.value) === item.id
                  }
                  trackTitle={item.name}
                  trackDescription={getDescriptionByTrack(item.name)}
                  onClick={() =>
                    setValue('trackId', {
                      label: item.name,
                      value: item.id,
                    })
                  }
                />
              )
            })}

          {track && (
            <CardSecondStepTrack
              key={`card-track-${99}`}
              matchSelectedTrack={!!R3selected?.value}
              trackTitle={'R+'}
              trackDescription={getDescriptionByTrack('R+')}
              onClick={() => {
                setR3Selected({
                  label: 'R+',
                  value: 99,
                })
                setValue('trackId', null)
                setShouldShowR3Options(true)
              }}
            />
          )}
        </VStack>
      )}
    </Stack>
  )
}
