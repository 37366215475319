import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

export interface ParamsExam {
  mockExamId: number
}

async function deleteExam(params: ParamsExam) {
  await api.delete(`/app/mock-exams/${params.mockExamId}`)
}

export function useDeleteExam() {
  return useMutation(deleteExam, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams-by-id'])
      queryClient.invalidateQueries(['get-simulation-exams'])
      queryClient.invalidateQueries(['get-simulation-exams-answers'])
    },
  })
}
