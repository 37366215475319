import React from 'react'
import { Heading, HStack, ProgressProps, Spinner } from '@chakra-ui/react'
import { ListFetching } from './list-fetching'

interface Props {
  title?: string
  isLoading: boolean
  isFetching: boolean
  progressProps?: ProgressProps
}

export function ListHeader({
  title,
  progressProps,
  isLoading,
  isFetching,
}: Props) {
  const isUpdating = !isLoading && isFetching

  return (
    <HStack>
      {title && (
        <Heading fontSize="3xl">
          {title} {isUpdating && <Spinner />}
        </Heading>
      )}

      {!title && (
        <ListFetching
          progressProps={progressProps}
          data={{ isLoading, isFetching }}
        />
      )}
    </HStack>
  )
}
