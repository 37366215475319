import { useMutation } from '@tanstack/react-query'
import api from 'services/api'

interface FocusLearningActivated {
  name: string
  documentNumber: string
  prepSchool: string[]
  intendedInstitutions: string[]
  alreadyGraduated: boolean
}

async function activatedFocusLearning(params: FocusLearningActivated) {
  await api.post('/app/focus_learning/activate', { ...params })
}

export function useActivatedFocusLearning() {
  return useMutation(activatedFocusLearning)
}
