import { format } from 'date-fns'
import React, { useMemo } from 'react'

// Hooks
import { usePagination } from 'hooks/usePagination'

// UI
import { List, ListHeader, ListPagination, ListTable } from 'features/ui'
import { TableHeader } from 'features/ui/list/list-table'

// Tests
import { GetDeletedTests, useGetDeletedTests } from 'features/tests'

// Styles
import { Text, Tooltip } from '@chakra-ui/react'
import { useUpdateTestsDeleted } from '../mutations/use-update-tests-deleted'
import { PopoverDeletedRecovery } from 'features/agendas/popover-deleted-recovery'

export function ListDeletedTests() {
  const { page, onChangePage } = usePagination()

  const updateTestsDeleted = useUpdateTestsDeleted()

  const { data, isLoading, isFetching } = useGetDeletedTests({
    page,
  })

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'date',
        fn(value) {
          return format(new Date(value), 'dd/MM/yyyy')
        },
      },

      {
        label: 'Nome da instituição',
        accessor: 'institutionName',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={2}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Nro° de questões',
        accessor: 'numberOfQuestions',
      },
      {
        label: 'Nro° de acertos',
        accessor: 'numberOfCorrectAnswers',
      },
      {
        label: 'Porcentagem de acerto',
        accessor: 'score',
      },
      {
        label: 'Percepção de esforço',
        accessor: 'effortPerception',
      },
      {
        label: 'Minutos de estudo',
        accessor: 'studiedMinutes',
      },
      {
        label: '',
        fn(_, data) {
          return (
            <PopoverDeletedRecovery
              id={data.id}
              updateDeleted={updateTestsDeleted}
            />
          )
        },
      },
    ] as TableHeader<GetDeletedTests>[]
  }, [updateTestsDeleted])

  return (
    <List>
      <ListHeader
        title="Provas e simulados"
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <ListTable headers={headers} data={data?.items} isLoading={isLoading} />

      <ListPagination
        currentPage={page}
        onChangePage={onChangePage}
        totalCountOfRegisters={data?.meta.total}
      />
    </List>
  )
}
