import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Text,
  ModalProps,
  Heading,
  VStack,
  HStack,
  Link,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Icon } from '@iconify/react'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import { useHistory } from 'react-router'
import { useAcceptInstitutionTerms } from 'features/simulation/mutations/use-mutate-accept-intitution-terms'
import { useGetTermsByInstitutionName } from 'features/simulation/mutations/use-get-terms-by-institution-name'

interface ModalInstitutionTermsProps extends Omit<ModalProps, 'children'> {
  institutionName: string
}

export default function ModalInstitutionTerms(
  props: ModalInstitutionTermsProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, institutionName } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    data: terms,
    isLoading: isLoadingGetTerms,
    error: errorGetTerms,
  } = useGetTermsByInstitutionName({
    institutionName,
  })
  const acceptInstitutionTerms = useAcceptInstitutionTerms()

  const showToast = useToastMessage()
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const acceptTerms = async () => {
    if (!institutionName) return

    try {
      await acceptInstitutionTerms.mutateAsync({
        institutionName,
      })

      showToast({
        title: 'Sucesso!',
        description:
          'Os Termos de Uso para esta instituição foram aceitos com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
      onClose()
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível aceitar os termos de uso no momento.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        p="0px"
        background={'#302F37'}
        maxW={'520px'}
        w="100%"
        borderRadius={'8px'}
        overflow={'hidden'}
        transition={'all 0.2s ease-in-out'}
        as="form"
      >
        <ModalHeader
          color={'#FFFF'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Heading fontFamily={'Mulish'} fontSize={'22px'} fontWeight={700}>
            Termos de Uso
          </Heading>
        </ModalHeader>

        <ModalBody color={'#FFFF'} background={'#302F37'} w="100%">
          <VStack w="full" spacing={'30px'} pb="30px">
            <Text>
              Para poder utilizar esta funcionalidade é preciso aceitar os
              termos de uso relacionados a esta instituição.
            </Text>
            <Link
              href={terms ?? '#'}
              target="_blank"
              rel="noopener noreferrer"
              fontWeight={'400'}
              fontSize={'24px'}
              w={{ base: 'full', sm: '50%' }}
            >
              <DefaultButton
                w="full"
                name="read_ters"
                variant="third"
                isLoading={isLoadingGetTerms}
                label="Ler Termos de Uso"
              />
            </Link>
          </VStack>
        </ModalBody>

        <ModalFooter
          flexDir={{ base: 'column', sm: 'row' }}
          gap={'20px'}
          borderTop={'1px solid #43424C'}
        >
          <DefaultButton
            w={{ base: 'full', sm: '50%' }}
            label="Cancelar"
            name="cancel"
            onClick={() => push('/simulation')}
            variant="ghost"
          />

          <DefaultButton
            w={{ base: 'full', sm: '50%' }}
            name="conclude_exam"
            onClick={acceptTerms}
            isLoading={isLoadingGetTerms}
            label="Aceitar"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
