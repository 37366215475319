import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

// Styles
import Layout from 'layouts/Unlogged'
import { FormContainer } from './styles'

// Schemas
import { ForgotPasswordNewSchema } from './schemas'

// Services
import api from 'services/api'

// Interfaces
import { ForgotPasswordPostPayload } from './interfaces'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Flex, VStack } from '@chakra-ui/react'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const ForgotPasswordNew: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<ForgotPasswordPostPayload>({
    resolver: yupResolver(ForgotPasswordNewSchema),
  })

  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(
    async (formData: ForgotPasswordPostPayload) => {
      try {
        await api.post('/public/forgot_password', formData)

        Swal.fire({
          title: 'Sucesso',
          html:
            '<p style="text-align:center">Seu pedido de redefinição de senha foi processado com sucesso. Acesse o seu e-mail para concluir.</p>',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (err) {
        Swal.fire({
          title: 'Aviso',
          html:
            '<p style="text-align:center">Falha ao solicitar redefinição de senha. Por favor tente novamente.</p>',
          buttonsStyling: false,
        })
      }
    },
    [history],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <p>
          Informe o <span>seu email</span>
        </p>

        <VStack w="100%" px={{ base: '1rem', sm: 'none' }}>
          <Box maxW={'380px'} w="100%">
            <FieldInputController
              control={control}
              placeholder="E-mail"
              type="email"
              name="email"
              error={errors.email}
            />
          </Box>
        </VStack>

        <Flex
          w="100%"
          flexDir={{ base: 'column', sm: 'row' }}
          gap={'1rem'}
          px="1rem"
          justifyContent={{ base: 'center', sm: 'space-between' }}
        >
          <DefaultButton
            label="Enviar"
            type="submit"
            w={{ base: '100%', sm: '50%' }}
            isLoading={isSubmitting}
          />

          <DefaultButton
            label="Voltar"
            variant="ghost"
            type="button"
            w={{ base: '100%', sm: '50%' }}
            onClick={() => push('/')}
          />
        </Flex>
      </FormContainer>
    </Layout>
  )
}

export default ForgotPasswordNew
