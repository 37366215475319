import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import Swal from 'sweetalert2'

// Types
import { ForgotPasswordPostPayload } from './interfaces'

// Schemas
import { ValidateAccountSchema } from './schemas'

// Services
import api from 'services/api'

// Styles
import Layout from 'layouts/Unlogged'
import { FormContainer } from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { Box, VStack } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const ValidateAccount: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const location = useLocation<{ token: string }>()
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<ForgotPasswordPostPayload>({
    resolver: yupResolver(ValidateAccountSchema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(
    async formData => {
      try {
        const query = new URLSearchParams(location.search)
        const validationEmailToken = query.get('token')
        if (!validationEmailToken) return

        await api.put('/public/validate_account', {
          ...formData,
          validationEmailToken,
        })

        Swal.fire({
          title: 'Sucesso',
          html:
            '<p style="text-align:center">Conta validada com sucesso. Você já pode fazer o seu login. Seja bem vindo!</p>',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (error) {
        const errorMessage = get(error, 'response.data')
        const isErrorString = typeof errorMessage === 'string'

        Swal.fire({
          title: 'Aviso',
          text: isErrorString
            ? errorMessage
            : 'Falha ao validar a sua conta. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history, location.search],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Redirect user to login if no token is presented as query param.
     */
    const query = new URLSearchParams(location.search)
    const validationEmailToken = query.get('token')
    if (!validationEmailToken) return history.replace('/')
  }, [history, location])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <p>
          Defina a <span>sua senha</span>
        </p>

        <VStack spacing={4}>
          <Box maxW={'380px'} w="100%">
            <FieldInputController
              control={control}
              placeholder="Senha"
              type="password"
              name="password"
              error={errors.password}
            />
          </Box>

          <Box maxW={'380px'} w="100%">
            <FieldInputController
              control={control}
              placeholder="Confirme a senha"
              type="password"
              name="passwordConfirmation"
              error={errors.password_confirmation}
            />
          </Box>
        </VStack>

        <div>
          <DefaultButton
            minW={'176px'}
            label={isSubmitting ? 'Aguarde ...' : 'DEFINIR'}
            disabled={isSubmitting}
            type="submit"
          />
        </div>
      </FormContainer>
    </Layout>
  )
}

export default ValidateAccount
