import { AspectRatio, Flex, useBreakpointValue, VStack } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import ModalFeedback from 'features/pre-onboarding/components/ModalFeedback'
import { Modules } from 'features/pre-onboarding/components/Modules'
import React, { useState } from 'react'

export function MyVersion2Template() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const [activeVideo, setActiveVideo] = useState(
    'https://www.youtube.com/embed/Z_7qBHtfVls?si=mG6h9VPUCiqP3bzn',
  )

  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getVideos = (videos: string) => {
    setActiveVideo(videos)
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      justifyContent={{ base: 'center', lg: 'unset' }}
      my="auto"
      align={{ base: 'center', lg: 'unset' }}
      gap={{ base: '0px', sm: '34px' }}
      flexDir={{ base: 'column', lg: 'row' }}
    >
      <Flex
        maxW={'870px'}
        w="100%"
        maxH={{ base: 'fit-content', sm: '516px' }}
        h={{ base: 'fit-content', sm: '100%' }}
        p={{ base: '0px', sm: '24px' }}
        background="#302F37"
        borderRadius="8px"
      >
        <Flex w={'100%'} h="auto" maxW={'870px'}>
          <VStack flex={'1 1 auto'}>
            <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
              <iframe
                src={activeVideo}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                height="100%"
                width="100%"
                style={
                  !isMobile
                    ? {
                        borderRadius: '8px',
                        border: '1px solid #e56000',
                      }
                    : {}
                }
              />
            </AspectRatio>
          </VStack>
        </Flex>
      </Flex>

      <Flex
        maxW={{ md: 'full', lg: '368px' }}
        maxH={'438px'}
        justifyContent={'space-between'}
        minW="176px"
        w="100%"
        p={'24px'}
        pr="16px"
        flexDir={'column'}
        gap="23px"
        background={isMobile ? 'inherit' : '#302F37'}
        borderRadius="8px"
        m="0px !important"
      >
        <Flex
          flexDir={'column'}
          gap="22px"
          overflowY={'auto'}
          pr="12px"
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#D9D9D9',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#E56000',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'rgba(0,0,0,0)',
            },
          }}
        >
          <Modules activeVideo={activeVideo} getVideos={getVideos} />
        </Flex>

        <DefaultButton
          mt="auto"
          minH={'42px'}
          label="Deixe seu Feedback para o Minha Versão 2.0"
          size="md"
          variant="gradient"
          whiteSpace={'normal'}
          fontWeight="700"
          fontSize={{ base: '12px', md: '14px' }}
          w="100%"
          onClick={() => setIsFeedbackModalOpen(true)}
        />
      </Flex>

      <ModalFeedback
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </Flex>
  )
}
