import { emptyStringToNull } from 'helpers/emptyStringToNull'
import * as yup from 'yup'

export const schema = yup.object({
  institutionName: yup
    .string()
    .nullable()
    .required('Nome da instituição é obrigatório'),

  date: yup
    .date()
    .nullable()
    .required('A data é obrigatória.')
    .max(
      new Date(),
      `A data deve ser inferior a ${new Date().toLocaleString()}`,
    ),

  studiedMinutes: yup
    .number()
    .nullable()
    .transform(emptyStringToNull)
    .required('O tempo é obrigatório.')
    .positive()
    .integer()
    .max(60 * 24, `O tempo de estudo deve ser menor que ${60 * 24}minutos`)
    .min(5, 'O tempo de estudo deve ser maior que 5 minutos.'),

  numberOfQuestions: yup
    .number()
    .typeError('O número de questões é obrigatório.')
    .nullable()
    .positive('O número de questões é obrigatório.')
    .integer('O número de questões é obrigatório.')
    .required('O número de questões é obrigatório.'),

  numberOfCorrectAnswers: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .positive('O número de respostas corretas é obrigatório')
    .max(
      yup.ref('numberOfQuestions'),
      'O número de questões corretas deve ser menor ou igual às questões feitas.',
    )
    .integer('O número de respostas corretas é obrigatório.')
    .required(
      'O número de questões é obrigatória e deve ser menor ou igual às feitas.',
    ),

  effortPerception: yup
    .number()
    .required('Por favor selecione a percepção de esforço')
    .min(1, 'A percepção de esforço deve ser maior ou igual a 1')
    .max(10, 'A percepção de esforço deve ser menor ou igual a 10'),
})
