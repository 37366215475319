import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Params {
  themeId: number
}

async function addFieldIdFocusLearning(params: Params) {
  await api.post('/app/focus_learning/add_revision', {
    themeId: params.themeId,
  })
}

export function useAddFieldIdFocusLearning() {
  return useMutation(addFieldIdFocusLearning, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-focus-learning'])
      queryClient.invalidateQueries(['project-x-progress'])
    },
  })
}
