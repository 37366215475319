import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface Params {
  isTrial: boolean
  mockExamId?: string
}

interface Response {
  hasTakenExam: boolean
}

export async function isSimulationAlreadyDone({
  isTrial,
  mockExamId,
}: Params): Promise<Response | undefined> {
  if (!isTrial || !mockExamId) return
  const { data } = await api.get(
    `/app/mock-exams/already-did-exam/${mockExamId}`,
  )

  return data
}

export function useIsSimulationAlreadyDone(params: Params) {
  return useQuery(
    ['get-question-by-id', params],
    () => isSimulationAlreadyDone(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
