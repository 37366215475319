import styled from 'styled-components'

const breakpointInPixels = 800

interface ResponsiveProps {
  shouldSidebarBeWide: boolean
  hasHeaderResponsiveLayout?: boolean
}

export const Container = styled.aside<ResponsiveProps>`
  display: flex;

  flex-direction: column;

  position: relative;

  align-items: center;

  padding-bottom: 0.5rem;

  height: 100%;
  width: ${({ shouldSidebarBeWide }) =>
    shouldSidebarBeWide ? '287px' : '48px'};

  background: linear-gradient(71.48deg, #1d1d1f 0%, #28272d 98.51%);

  /* @media (max-width: 475px) {
    display: ${({ hasHeaderResponsiveLayout }) =>
    hasHeaderResponsiveLayout ? 'none' : 'flex'};
  } */
`

export const Logo = styled.img<ResponsiveProps>`
  display: ${({ shouldSidebarBeWide }) =>
    !shouldSidebarBeWide ? 'none' : 'flex'};
`

export const MenuButton = styled.button`
  display: flex;
  justify-items: center;
  width: 100%;
  @media (max-width: ${breakpointInPixels}px) {
    display: flex;
    justify-items: center;
  }
`
