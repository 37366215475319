import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React, { ReactNode } from 'react'

export type ModalConfirmInstitutionsProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  onConfirmClick?: () => void
  isConfirmClickLoading?: boolean
  onBackClick: () => void
  selectedInstitutions: string[]
} & Omit<ModalProps, 'children'>

export const ModalConfirmInstitutions = (
  props: ModalConfirmInstitutionsProps,
) => {
  const {
    isOpen,
    onClose,
    onBackClick,
    isConfirmClickLoading,
    size,
    children,
    onConfirmClick,
    selectedInstitutions,
    ...reactModalProps
  } = props

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} {...reactModalProps}>
      <ModalOverlay />
      <ModalContent
        maxW={'348px'}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalBody p={'1.5rem'} borderRadius={'8px'}>
          <Flex as="form" w="100%" justifyContent={'center'} flexDir="column">
            <VStack spacing={'16px'}>
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="700"
                fontSize={'22px'}
                color={'#FFFFFF'}
                textAlign={'center'}
              >
                {selectedInstitutions?.length > 1
                  ? 'As instituições selecionadas ainda não possuem dados cadastrados'
                  : 'A instituição selecionada ainda não possui dados cadastrados'}
              </Text>

              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                textAlign={'center'}
                fontSize="16px"
                lineHeight="20px"
                color="#BDBDC7"
              >
                {selectedInstitutions?.length > 1
                  ? `${selectedInstitutions?.join(
                      ', ',
                    )} irão utilizar dados genéricos, deseja prosseguir assim mesmo? Em caso de dúvida entre em contato nosso suporte.`
                  : `${selectedInstitutions?.[0]} irá utilizar dados genéricos, deseja prosseguir assim mesmo? Em caso de dúvida entre em contato nosso suporte.`}
              </Text>
            </VStack>

            <Flex mt="24px" gap="12px" flexDir={'column'}>
              <DefaultButton
                onClick={onConfirmClick}
                type="submit"
                label={'Quero continuar'}
                isLoading={isConfirmClickLoading}
              />

              <DefaultButton
                onClick={onBackClick}
                label="Voltar"
                variant="ghost"
              />
            </Flex>
          </Flex>

          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
