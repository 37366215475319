import { Box, Spinner, Text } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { ProgressBar } from 'pages/RecoveryPlan/components/ProgressBar'
import React, { useState } from 'react'
import ModalNextStudentsTimelineMR from './components/ModalNextStudentsTimelineMR'
import { useGetPendingThemesProgressBar } from './queries/use-get-themes-progress'

export default function TimeLineMRTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [
    modalNextStudiesTimeLineOpen,
    setModalNextStudiesTimeLineOpen,
  ] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    data: progressBarData,
    isLoading: isProgressBarLoading,
    isError: progressBarError,
  } = useGetPendingThemesProgressBar()

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box maxW={'450px'} w="100%" my={'1rem'}>
      {isProgressBarLoading && <Spinner size="xl" color="brand.500" />}

      {progressBarError && !isProgressBarLoading && (
        <Text>Erro ao carregar barra de progresso</Text>
      )}

      {progressBarData && (
        <ProgressBar
          title="Progressão da Trilha de Simulados"
          concluded={progressBarData.percentageOfCompletedThemes || 0}
          inProgress={progressBarData.percentageOfThemesInProgress || 0}
          rest={progressBarData.percentageOfPendingThemes}
          total={100}
          isLoading={false}
        />
      )}

      <DefaultButton
        onClick={() => setModalNextStudiesTimeLineOpen(true)}
        maxW="286px"
        w="100%"
        mt="1rem"
        label="Próximos Estudos"
      />

      <ModalNextStudentsTimelineMR
        isOpen={modalNextStudiesTimeLineOpen}
        onClose={() => setModalNextStudiesTimeLineOpen(false)}
      />
    </Box>
  )
}
