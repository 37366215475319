import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  useBreakpointValue,
  Flex,
  chakra,
} from '@chakra-ui/react'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Contexts
import { useAuth } from 'contexts/auth'

// Styles
import { Container, Content } from './styles'
import Layout from 'layouts/Logged'

// Options
import VideoOrientation from 'components/VideoOrientation'
import { ProfileTab } from 'features/account/ProfileTab/index'
import { ApprovedTab } from 'features/account/ApprovedTab'
import { RegisterTab } from 'features/account/RegisterTab'
import { AvatarTab } from 'features/account/AvatarTab'
import ResetsTab from 'features/account/ResetsTab'

const Account: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const location = new URLSearchParams(useLocation().search)
  const tab = location.get('tab') || 'profile'
  const isMd = useBreakpointValue({ base: false, lg: true })

  /*
  |-----------------------------------------------------------------------------
  | Contexts.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Minha conta"
          subTitle="Imagem de perfil e dados pessoais"
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://www.youtube.com/watch?v=H4q8iq1saZA"
        />
        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://www.youtube.com/watch?v=H4q8iq1saZA"
              mt="8px"
            />
          </Flex>
        )}

        <Content>
          <SectionTitle>Versão da Plataforma</SectionTitle>
          <Text opacity={0.6}>Versão {process.env.REACT_APP_VERSION}</Text>

          <Tabs
            variant="solid-rounded"
            colorScheme="brand"
            mt={4}
            defaultIndex={tab === 'approval' ? 3 : 0}
          >
            <TabList width={'max-content'}>
              <Tab>Perfil</Tab>
              <Tab>Foto</Tab>
              <Tab>Cadastro</Tab>
              <Tab>Aprovação</Tab>
              <Tab>Opções</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <SectionTitle>Perfil</SectionTitle>
                <ProfileTab />
              </TabPanel>

              <TabPanel>
                <SectionTitle>Foto de perfil</SectionTitle>
                <AvatarTab />
              </TabPanel>

              <TabPanel>
                <SectionTitle>Cadastro</SectionTitle>
                <RegisterTab />
              </TabPanel>

              <TabPanel>
                <SectionTitle>Aprovação</SectionTitle>
                <ApprovedTab />
              </TabPanel>

              <TabPanel>
                <ResetsTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Content>
      </Container>
    </Layout>
  )
}

export default Account
