import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SwitchProps,
} from '@chakra-ui/react'
import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { FieldSwitch } from '.'

interface FieldSwitchControllerProps extends Omit<SwitchProps, 'defaultValue'> {
  name: string
  label?: string
  control: Control<any>
  error?: FieldError
  defaultValue?: boolean
}

export const FieldSwitchController = ({
  control,
  error,
  name,
  defaultValue,
  label,
  ...rest
}: FieldSwitchControllerProps) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel
          mb="8px"
          fontWeight={'600'}
          fontSize="16px"
          lineHeight={'20px'}
          color="#FFFFFF"
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <FieldSwitch {...rest} {...field} />}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
