import React from 'react'
import {
  chakra,
  CircularProgress,
  CircularProgressLabel,
  GridItem,
  GridItemProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { MajorStatsProps } from '..'
import { hoursAndMinutesConversion } from 'helpers/hoursAndMinutesConversion'
import { getISOWeek } from 'date-fns'

type HoursStudyProps = { currentWeekSelected: number } & Pick<
  MajorStatsProps,
  | 'percentStudiedHoursReached'
  | 'percentRemainingHoursToReachTheGoal'
  | 'study_minutes'
  | 'totalMinutesToReachTheGoal'
> &
  GridItemProps

export default function HoursStudy(props: HoursStudyProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })
  const {
    percentStudiedHoursReached,
    percentRemainingHoursToReachTheGoal,
    currentWeekSelected,
    study_minutes,
    totalMinutesToReachTheGoal,
    ...rest
  } = props

  const currentWeek = getISOWeek(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (currentWeekSelected === currentWeek) {
    return (
      <GridItem
        backgroundColor="#302F37"
        p="16px"
        textAlign={'center'}
        {...rest}
      >
        <Text
          textAlign={'center'}
          fontWeight={'700'}
          fontSize={{ base: '16px', sm: '18px' }}
        >
          Horas de estudo
        </Text>

        <CircularProgress
          size={isSm ? '84px' : '110px'}
          thickness="6px"
          capIsRound
          color={
            percentRemainingHoursToReachTheGoal <= 0 ? '#0DDF15' : '#e5c009'
          }
          trackColor="#616167"
          my="16px"
          value={percentStudiedHoursReached}
        >
          <CircularProgressLabel fontSize={'24px'}>
            <chakra.span>{percentStudiedHoursReached.toFixed(0)}%</chakra.span>

            <Text fontWeight={400} fontSize={'13px'}>
              Atingida
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        {percentStudiedHoursReached === 100 &&
          percentRemainingHoursToReachTheGoal <= 0 && (
            <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
              <chakra.span color="#0DDF15">Na risca! </chakra.span>
              Você atingiu a meta de horas de estudo dessa semana
            </Text>
          )}

        {percentStudiedHoursReached === 0 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            Estude{' '}
            <chakra.span color="#E56000">{`${hoursAndMinutesConversion(
              study_minutes,
            )} `}</chakra.span>
            para complementar a meta dessa semana.
          </Text>
        )}

        {totalMinutesToReachTheGoal > 0 &&
          totalMinutesToReachTheGoal < study_minutes && (
            <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
              <chakra.span>Quase lá, estude </chakra.span>
              <chakra.span color={'#E56000'}>
                {`${hoursAndMinutesConversion(totalMinutesToReachTheGoal)} `}
              </chakra.span>
              para atingir a meta.
            </Text>
          )}

        {percentStudiedHoursReached > 100 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            <chakra.span color={'#0ddf15'}>Uouu, que progresso! </chakra.span>
            Você ultrapassou a meta de
            <chakra.span color={'#0ddf15'}>
              {` ${hoursAndMinutesConversion(study_minutes)} `}
            </chakra.span>
            dessa semana.
          </Text>
        )}
      </GridItem>
    )
  }

  return (
    <GridItem backgroundColor="#302F37" p="16px" textAlign={'center'} {...rest}>
      <Text
        textAlign={'center'}
        fontWeight={'700'}
        fontSize={{ base: '16px', sm: '18px' }}
      >
        Horas de estudo
      </Text>

      <CircularProgress
        size={isSm ? '84px' : '110px'}
        thickness="6px"
        capIsRound
        color={percentRemainingHoursToReachTheGoal <= 0 ? '#0DDF15' : '#e5c009'}
        trackColor="#616167"
        my="16px"
        value={percentStudiedHoursReached}
      >
        <CircularProgressLabel fontSize={'24px'}>
          <chakra.span>{percentStudiedHoursReached.toFixed(0)}%</chakra.span>

          <Text fontWeight={400} fontSize={'13px'}>
            Atingida
          </Text>
        </CircularProgressLabel>
      </CircularProgress>

      {percentStudiedHoursReached === 100 &&
        percentRemainingHoursToReachTheGoal <= 0 && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            <chakra.span color="#0DDF15">Na risca! </chakra.span>
            Você atingiu a meta de horas de estudo dessa semana
          </Text>
        )}

      {percentStudiedHoursReached === 0 && (
        <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
          Estude{' '}
          <chakra.span color="#E56000">{`${hoursAndMinutesConversion(
            study_minutes,
          )} `}</chakra.span>
          para complementar a meta dessa semana.
        </Text>
      )}

      {totalMinutesToReachTheGoal > 0 &&
        totalMinutesToReachTheGoal < study_minutes && (
          <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
            <chakra.span>Quaase! Restaram </chakra.span>
            <chakra.span color={'#E56000'}>
              {`${hoursAndMinutesConversion(totalMinutesToReachTheGoal)} `}
            </chakra.span>
            para atingir a meta no período selecionado.
          </Text>
        )}

      {percentStudiedHoursReached > 100 && (
        <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
          <chakra.span color={'#0ddf15'}>Uouu, que progresso! </chakra.span>
          Você superou a meta em
          <chakra.span color={'#0ddf15'}>
            {` ${+percentStudiedHoursReached.toFixed(1) - 100}%`}
          </chakra.span>
        </Text>
      )}
    </GridItem>
  )
}
