import React, { useMemo } from 'react'
import { Box, Text, VStack } from '@chakra-ui/react'
import { FieldProgress } from './FieldProgress/FieldProgress'
import { CorrectAnswersByField } from 'features/dashboard/mutations/use-get-dash-info'
import { capitalize, sortBy } from 'lodash'

interface ByFieldProps {
  data: CorrectAnswersByField[]
}

export default function ByField({ data }: ByFieldProps) {
  /*
  |-----------------------------------------------------------------------------
  | Memo
  |-----------------------------------------------------------------------------
  |
  |
  */

  const byFieldMemo = useMemo(() => {
    const orderedData = sortBy(data, sort => {
      return -Math.floor(
        (sort.number_of_correct_answers / sort.number_of_questions) * 100,
      )
    })

    return orderedData
  }, [data])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box
      pl="16px"
      maxH={'200px'}
      borderRadius={'4px'}
      py="14px"
      pr="8px"
      w="100%"
      background="#302F37"
    >
      <VStack
        h="100%"
        gap="8px"
        alignItems={'flex-start'}
        overflowY={'auto'}
        sx={{
          position: 'relative',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 5px #60606c',
            borderRadius: '17px',
            height: '8px',
            borderLeft: '2px solid transparent',
            borderRight: '2px solid transparent',
            marginTop: '10px',
            marginBottom: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#E56000',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'rgba(0,0,0,0)',
          },
        }}
      >
        <Text
          textAlign={'left'}
          fontWeight={600}
          fontSize={{ base: '16px', sm: '18px' }}
          lineHeight={'20px'}
        >
          % de acerto por grande área
        </Text>

        <VStack w="100%" maxH="100%" justifyContent={'flex-start'}>
          {byFieldMemo?.map(field => (
            <FieldProgress
              key={`fields-id-${field.field_name}`}
              fieldName={capitalize(field.field_name)}
              progress={Math.floor(
                (field.number_of_correct_answers / field.number_of_questions) *
                  100,
              )}
            />
          ))}

          {data && data.length === 0 && (
            <Text w="full" textAlign={'left'}>
              Nenhum dado encontrado
            </Text>
          )}
        </VStack>
      </VStack>
    </Box>
  )
}
