import { useMutation } from '@tanstack/react-query'
import { User } from 'contexts/auth/interfaces'
import api from 'services/api'

async function mutateSetMonitor({
  trackId,
  trialYear,
}: {
  trackId: number
  trialYear: number
}) {
  if (!trackId || !trialYear) return

  return await api.patch<User>('/app/onboardings/set_monitor', {
    trackId,
    trialYear,
  })
}

export function useMutateSetMonitor() {
  return useMutation(mutateSetMonitor)
}
