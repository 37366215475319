// All countries

interface Country {
  name: string
  iso: string
  iso3: string
  ddi: string
}
export interface SelectCountryProps {
  label: Country
  value: string
}

export const countries: Country[] = [
  { name: 'Afghanistão', iso: 'AF', iso3: 'AFG', ddi: '+93' },
  { name: 'África do Sul', iso: 'ZA', iso3: 'ZAF', ddi: '+27' },
  { name: 'Albânia', iso: 'AL', iso3: 'ALB', ddi: '+355' },
  { name: 'Alemanha', iso: 'DE', iso3: 'DEU', ddi: '+49' },
  { name: 'Andora', iso: 'AD', iso3: 'AND', ddi: '+376' },
  { name: 'Angola', iso: 'AO', iso3: 'AGO', ddi: '+244' },
  { name: 'Anguilla', iso: 'AI', iso3: 'AIA', ddi: '+1 264' },
  { name: 'Antarctica', iso: 'AQ', iso3: 'ATA', ddi: '+672' },
  { name: 'Antígua e Barbuda', iso: 'AG', iso3: 'ATG', ddi: '+1 268' },
  { name: 'Argentina', iso: 'AR', iso3: 'ARG', ddi: '+54' },
  { name: 'Argélia', iso: 'DZ', iso3: 'DZA', ddi: '+213' },
  { name: 'Arménia', iso: 'AM', iso3: 'ARM', ddi: '+374' },
  { name: 'Aruba', iso: 'AW', iso3: 'ABW', ddi: '+297' },
  { name: 'Arábia Saudita', iso: 'SA', iso3: 'SAU', ddi: '+966' },
  { name: 'Austrália', iso: 'AU', iso3: 'AUS', ddi: '+61' },
  { name: 'Áustria', iso: 'AT', iso3: 'AUT', ddi: '+43' },
  { name: 'Azerbaijão', iso: 'AZ', iso3: 'AZE', ddi: '+994' },
  { name: 'Bahamas', iso: 'BS', iso3: 'BHS', ddi: '+1 242' },
  { name: 'Bahrein', iso: 'BH', iso3: 'BHR', ddi: '+973' },
  { name: 'Bangladesh', iso: 'BD', iso3: 'BGD', ddi: '+880' },
  { name: 'Barbados', iso: 'BB', iso3: 'BRB', ddi: '+1 246' },
  { name: 'Bélgica', iso: 'BE', iso3: 'BEL', ddi: '+32' },
  { name: 'Belize', iso: 'BZ', iso3: 'BLZ', ddi: '+501' },
  { name: 'Benim', iso: 'BJ', iso3: 'BEN', ddi: '+229' },
  { name: 'Bermuda', iso: 'BM', iso3: 'BMU', ddi: '+1 441' },
  { name: 'Butão', iso: 'BT', iso3: 'BTN', ddi: '+975' },
  { name: 'Bielorrússia', iso: 'BY', iso3: 'BLR', ddi: '+375' },
  { name: 'Bolívia', iso: 'BO', iso3: 'BOL', ddi: '+591' },
  { name: 'Bósnia e Herzegovina', iso: 'BA', iso3: 'BIH', ddi: '+387' },
  { name: 'Botswana', iso: 'BW', iso3: 'BWA', ddi: '+267' },
  { name: 'Brasil', iso: 'BR', iso3: 'BRA', ddi: '+55' },
  { name: 'Brunei', iso: 'BN', iso3: 'BRN', ddi: '+673' },
  { name: 'Bulgária', iso: 'BG', iso3: 'BGR', ddi: '+359' },
  { name: 'Burkina Faso', iso: 'BF', iso3: 'BFA', ddi: '+226' },
  { name: 'Myanmar (Burma)', iso: 'MM', iso3: 'MMR', ddi: '+95' },
  { name: 'Burundi', iso: 'BI', iso3: 'BDI', ddi: '+257' },
  { name: 'Camarões', iso: 'CM', iso3: 'CMR', ddi: '+237' },
  { name: 'Cabo Verde', iso: 'CV', iso3: 'CPV', ddi: '+238' },
  { name: 'Camboja', iso: 'KH', iso3: 'KHM', ddi: '+855' },
  { name: 'Canadá', iso: 'CA', iso3: 'CAN', ddi: '+1' },
  { name: 'Catar', iso: 'QA', iso3: 'QAT', ddi: '+974' },
  { name: 'Chad', iso: 'TD', iso3: 'TCD', ddi: '+235' },
  { name: 'Chile', iso: 'CL', iso3: 'CHL', ddi: '+56' },
  { name: 'China', iso: 'CN', iso3: 'CHN', ddi: '+86' },
  { name: 'Chipre', iso: 'CY', iso3: 'CYP', ddi: '+357' },
  { name: 'Christmas Island', iso: 'CX', iso3: 'CXR', ddi: '+61' },
  { name: 'Cingapura', iso: 'SG', iso3: 'SGP', ddi: '+65' },
  { name: 'Colômbia', iso: 'CO', iso3: 'COL', ddi: '+57' },
  { name: 'Comores', iso: 'KM', iso3: 'COM', ddi: '+269' },
  { name: 'Coreia do Norte', iso: 'KP', iso3: 'PRK', ddi: '+850' },
  { name: 'Coreia do Sul', iso: 'KR', iso3: 'KOR', ddi: '+82' },
  { name: 'Costa do Marfim', iso: 'CI', iso3: 'CIV', ddi: '+225' },
  { name: 'Costa Rica', iso: 'CR', iso3: 'CRI', ddi: '+506' },
  { name: 'Croácia', iso: 'HR', iso3: 'HRV', ddi: '+385' },
  { name: 'Cuba', iso: 'CU', iso3: 'CUB', ddi: '+53' },
  { name: 'Dinamarca', iso: 'DK', iso3: 'DNK', ddi: '+45' },
  { name: 'Djibouti', iso: 'DJ', iso3: 'DJI', ddi: '+253' },
  { name: 'Dominica', iso: 'DM', iso3: 'DMA', ddi: '+1 767' },
  { name: 'Egito', iso: 'EG', iso3: 'EGY', ddi: '+20' },
  { name: 'El Salvador', iso: 'SV', iso3: 'SLV', ddi: '+503' },
  { name: 'Emirados Árabes Unidos', iso: 'AE', iso3: 'ARE', ddi: '+971' },
  { name: 'Equador', iso: 'EC', iso3: 'ECU', ddi: '+593' },
  { name: 'Eritreia', iso: 'ER', iso3: 'ERI', ddi: '+291' },
  { name: 'Estónia', iso: 'EE', iso3: 'EST', ddi: '+372' },
  { name: 'Espanha', iso: 'ES', iso3: 'ESP', ddi: '+34' },
  { name: 'Etiópia', iso: 'ET', iso3: 'ETH', ddi: '+251' },
  { name: 'Fiji', iso: 'FJ', iso3: 'FJI', ddi: '+679' },
  { name: 'Finlândia', iso: 'FI', iso3: 'FIN', ddi: '+358' },
  { name: 'França', iso: 'FR', iso3: 'FRA', ddi: '+33' },
  { name: 'Gabão', iso: 'GA', iso3: 'GAB', ddi: '+241' },
  { name: 'Gâmbia', iso: 'GM', iso3: 'GMB', ddi: '+220' },
  { name: 'Geórgia', iso: 'GE', iso3: 'GEO', ddi: '+995' },
  { name: 'Gana', iso: 'GH', iso3: 'GHA', ddi: '+233' },
  { name: 'Gibraltar', iso: 'GI', iso3: 'GIB', ddi: '+350' },
  { name: 'Grécia', iso: 'GR', iso3: 'GRC', ddi: '+30' },
  { name: 'Groelândia', iso: 'GL', iso3: 'GRL', ddi: '+299' },
  { name: 'Granada', iso: 'GD', iso3: 'GRD', ddi: '+1 473' },
  { name: 'Guam', iso: 'GU', iso3: 'GUM', ddi: '+1 671' },
  { name: 'Guatemala', iso: 'GT', iso3: 'GTM', ddi: '+502' },
  { name: 'Guiana', iso: 'GY', iso3: 'GUY', ddi: '+592' },
  { name: 'Guiné', iso: 'GN', iso3: 'GIN', ddi: '+224' },
  { name: 'Guiné Equatorial', iso: 'GQ', iso3: 'GNQ', ddi: '+240' },
  { name: 'Guiné-Bissau', iso: 'GW', iso3: 'GNB', ddi: '+245' },
  { name: 'Haiti', iso: 'HT', iso3: 'HTI', ddi: '+509' },
  { name: 'Honduras', iso: 'HN', iso3: 'HND', ddi: '+504' },
  { name: 'Hong Kong', iso: 'HK', iso3: 'HKG', ddi: '+852' },
  { name: 'Hungria', iso: 'HU', iso3: 'HUN', ddi: '+36' },
  { name: 'Islândia', iso: 'IS', iso3: 'IS', ddi: '+354' },
  { name: 'Ilhas Cayman', iso: 'KY', iso3: 'CYM', ddi: '+1 345' },
  { name: 'Ilhas Cocos (Keeling)', iso: 'CC', iso3: 'CCK', ddi: '+61' },
  { name: 'Ilha de Man', iso: 'IM', iso3: 'IMN', ddi: '+44' },
  { name: 'Ilhas Cook', iso: 'CK', iso3: 'COK', ddi: '+682' },
  { name: 'Ilhas Falkland (Malvinas)', iso: 'FK', iso3: 'FLK', ddi: '+500' },
  { name: 'Ilhas Feroé', iso: 'FO', iso3: 'FRO', ddi: '+298' },
  { name: 'Ilhas Mariana do Norte', iso: 'MP', iso3: 'MNP', ddi: '+1 670' },
  { name: 'Ilhas Marshall', iso: 'MH', iso3: 'MHL', ddi: '+692' },
  { name: 'Ilhas Pitcairn', iso: 'PN', iso3: 'PCN', ddi: '+870' },
  { name: 'Ilhas Salomão', iso: 'SB', iso3: 'SLB', ddi: '+677' },
  { name: 'Ilhas Turcas e Caicos', iso: 'TC', iso3: 'TCA', ddi: '+1 649' },
  { name: 'Ilhas Virgens Americanas', iso: 'VI', iso3: 'VIR', ddi: '+1 340' },
  { name: 'Ilhas Virgens Britânicas', iso: 'VG', iso3: 'VGB', ddi: '+1 284' },
  { name: 'India', iso: 'IN', iso3: 'IND', ddi: '+91' },
  { name: 'Indonésia', iso: 'ID', iso3: 'IDN', ddi: '+62' },
  { name: 'Inglaterra (Reino Unido)', iso: 'GB', iso3: 'GBR', ddi: '+44' },
  { name: 'Irã', iso: 'IR', iso3: 'IRN', ddi: '+98' },
  { name: 'Iraque', iso: 'IQ', iso3: 'IRQ', ddi: '+964' },
  { name: 'Irlanda', iso: 'IE', iso3: 'IRL', ddi: '+353' },
  { name: 'Israel', iso: 'IL', iso3: 'ISR', ddi: '+972' },
  { name: 'Itália', iso: 'IT', iso3: 'ITA', ddi: '+39' },
  { name: 'Jamaica', iso: 'JM', iso3: 'JAM', ddi: '+1 876' },
  { name: 'Japão', iso: 'JP', iso3: 'JPN', ddi: '+81' },
  { name: 'Jordânia', iso: 'JO', iso3: 'JOR', ddi: '+962' },
  { name: 'Cazaquistão', iso: 'KZ', iso3: 'KAZ', ddi: '+7' },
  { name: 'Quénia (Kenya)', iso: 'KE', iso3: 'KEN', ddi: '+254' },
  { name: 'Kiribati', iso: 'KI', iso3: 'KIR', ddi: '+686' },
  { name: 'Kuwait', iso: 'KW', iso3: 'KWT', ddi: '+965' },
  { name: 'Quirguistão', iso: 'KG', iso3: 'KGZ', ddi: '+996' },
  { name: 'Laos', iso: 'LA', iso3: 'LAO', ddi: '+856' },
  { name: 'Letônia', iso: 'LV', iso3: 'LVA', ddi: '+371' },
  { name: 'Líbano', iso: 'LB', iso3: 'LBN', ddi: '+961' },
  { name: 'Lesoto', iso: 'LS', iso3: 'LSO', ddi: '+266' },
  { name: 'Libéria', iso: 'LR', iso3: 'LBR', ddi: '+231' },
  { name: 'Líbia', iso: 'LY', iso3: 'LBY', ddi: '+218' },
  { name: 'Liechtenstein', iso: 'LI', iso3: 'LIE', ddi: '+423' },
  { name: 'Lituânia', iso: 'LT', iso3: 'LTU', ddi: '+370' },
  { name: 'Luxemburgo', iso: 'LU', iso3: 'LUX', ddi: '+352' },
  { name: 'Macau', iso: 'MO', iso3: 'MAC', ddi: '+853' },
  { name: 'Macedónia', iso: 'MK', iso3: 'MKD', ddi: '+389' },
  { name: 'Madagáscar', iso: 'MG', iso3: 'MDG', ddi: '+261' },
  { name: 'Malawi', iso: 'MW', iso3: 'MWI', ddi: '+265' },
  { name: 'Malásia', iso: 'MY', iso3: 'MYS', ddi: '+60' },
  { name: 'Maldivas', iso: 'MV', iso3: 'MDV', ddi: '+960' },
  { name: 'Mali', iso: 'ML', iso3: 'MLI', ddi: '+223' },
  { name: 'Malta', iso: 'MT', iso3: 'MLT', ddi: '+356' },
  { name: 'Mauritânia', iso: 'MR', iso3: 'MRT', ddi: '+222' },
  { name: 'Maurícia', iso: 'MU', iso3: 'MUS', ddi: '+230' },
  { name: 'Mayotte', iso: 'YT', iso3: 'MYT', ddi: '+262' },
  { name: 'México', iso: 'MX', iso3: 'MEX', ddi: '+52' },
  { name: 'Micronésia', iso: 'FM', iso3: 'FSM', ddi: '+691' },
  { name: 'Moldávia', iso: 'MD', iso3: 'MDA', ddi: '+373' },
  { name: 'Monaco', iso: 'MC', iso3: 'MCO', ddi: '+377' },
  { name: 'Mongólia', iso: 'MN', iso3: 'MNG', ddi: '+976' },
  { name: 'Montenegro', iso: 'ME', iso3: 'MNE', ddi: '+382' },
  { name: 'Montserrat', iso: 'MS', iso3: 'MSR', ddi: '+1 664' },
  { name: 'Marrocos', iso: 'MA', iso3: 'MAR', ddi: '+212' },
  { name: 'Moçambique', iso: 'MZ', iso3: 'MOZ', ddi: '+258' },
  { name: 'Namíbia', iso: 'NA', iso3: 'NAM', ddi: '+264' },
  { name: 'Nauru', iso: 'NR', iso3: 'NRU', ddi: '+674' },
  { name: 'Nepal', iso: 'NP', iso3: 'NPL', ddi: '+977' },
  { name: 'Nova Caledônia', iso: 'NC', iso3: 'NCL', ddi: '+687' },
  { name: 'Nova Zelândia', iso: 'NZ', iso3: 'NZL', ddi: '+64' },
  { name: 'Nicaragua', iso: 'NI', iso3: 'NIC', ddi: '+505' },
  { name: 'Níger', iso: 'NE', iso3: 'NER', ddi: '+227' },
  { name: 'Nigéria', iso: 'NG', iso3: 'NGA', ddi: '+234' },
  { name: 'Niue', iso: 'NU', iso3: 'NIU', ddi: '+683' },
  { name: 'Noruega', iso: 'NO', iso3: 'NOR', ddi: '+47' },
  { name: 'Omã', iso: 'OM', iso3: 'OMN', ddi: '+968' },
  { name: 'Países Baixos', iso: 'NL', iso3: 'NLD', ddi: '+31' },
  { name: 'Paquistão', iso: 'PK', iso3: 'PAK', ddi: '+92' },
  { name: 'Palau', iso: 'PW', iso3: 'PLW', ddi: '+680' },
  { name: 'Panamá', iso: 'PA', iso3: 'PAN', ddi: '+507' },
  { name: 'Papua-Nova Guiné', iso: 'PG', iso3: 'PNG', ddi: '+675' },
  { name: 'Paraguai', iso: 'PY', iso3: 'PRY', ddi: '+595' },
  { name: 'Peru', iso: 'PE', iso3: 'PER', ddi: '+51' },
  { name: 'Filipinas', iso: 'PH', iso3: 'PHL', ddi: '+63' },
  { name: 'Polónia', iso: 'PL', iso3: 'POL', ddi: '+48' },
  { name: 'Polinésia Francesa', iso: 'PF', iso3: 'PYF', ddi: '+689' },
  { name: 'Portugal', iso: 'PT', iso3: 'PRT', ddi: '+351' },
  { name: 'Porto Rico', iso: 'PR', iso3: 'PRI', ddi: '+1' },
  { name: 'República do Congo', iso: 'CG', iso3: 'COG', ddi: '+242' },
  {
    name: 'República Democrática do Congo',
    iso: 'CD',
    iso3: 'COD',
    ddi: '+243',
  },
  { name: 'República Centro-Africana', iso: 'CF', iso3: 'CAF', ddi: '+236' },
  { name: 'República Checa', iso: 'CZ', iso3: 'CZE', ddi: '+420' },
  { name: 'República Dominicana', iso: 'DO', iso3: 'DOM', ddi: '+1 809' },
  { name: 'Roménia', iso: 'RO', iso3: 'ROU', ddi: '+40' },
  { name: 'Ruanda', iso: 'RW', iso3: 'RWA', ddi: '+250' },
  { name: 'Rússia', iso: 'RU', iso3: 'RUS', ddi: '+7' },
  { name: 'Saint Barthelemy', iso: 'BL', iso3: 'BLM', ddi: '+590' },
  { name: 'Samoa', iso: 'WS', iso3: 'WSM', ddi: '+685' },
  { name: 'Samoa Americana', iso: 'AS', iso3: 'ASM', ddi: '+1 684' },
  { name: 'San Marino', iso: 'SM', iso3: 'SMR', ddi: '+378' },
  { name: 'Sao Tome e Principe', iso: 'ST', iso3: 'STP', ddi: '+239' },
  { name: 'Senegal', iso: 'SN', iso3: 'SEN', ddi: '+221' },
  { name: 'Serbia', iso: 'RS', iso3: 'SRB', ddi: '+381' },
  { name: 'Serra Leoa', iso: 'SL', iso3: 'SLE', ddi: '+232' },
  { name: 'Seychelles', iso: 'SC', iso3: 'SYC', ddi: '+248' },
  { name: 'Slovakia', iso: 'SK', iso3: 'SVK', ddi: '+421' },
  { name: 'Slovenia', iso: 'SI', iso3: 'SVN', ddi: '+386' },
  { name: 'Somalia', iso: 'SO', iso3: 'SOM', ddi: '+252' },
  { name: 'Sri Lanka', iso: 'LK', iso3: 'LKA', ddi: '+94' },
  { name: 'Saint Helena', iso: 'SH', iso3: 'SHN', ddi: '+290' },
  { name: 'Saint Kitts and Nevis', iso: 'KN', iso3: 'KNA', ddi: '+1 869' },
  { name: 'Saint Lucia', iso: 'LC', iso3: 'LCA', ddi: '+1 758' },
  { name: 'Saint Martin', iso: 'MF', iso3: 'MAF', ddi: '+1 599' },
  { name: 'Saint Pierre and Miquelon', iso: 'PM', iso3: 'SPM', ddi: '+508' },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: 'VC',
    iso3: 'VCT',
    ddi: '+1 784',
  },
  { name: 'Sudão', iso: 'SD', iso3: 'SDN', ddi: '+249' },
  { name: 'Suriname', iso: 'SR', iso3: 'SUR', ddi: '+597' },
  { name: 'Suazilândia', iso: 'SZ', iso3: 'SWZ', ddi: '+268' },
  { name: 'Suécia', iso: 'SE', iso3: 'SWE', ddi: '+46' },
  { name: 'Suiça', iso: 'CH', iso3: 'CHE', ddi: '+41' },
  { name: 'Syria', iso: 'SY', iso3: 'SYR', ddi: '+963' },
  { name: 'Taiwan', iso: 'TW', iso3: 'TWN', ddi: '+886' },
  { name: 'Tajiquistão', iso: 'TJ', iso3: 'TJK', ddi: '+992' },
  { name: 'Tanzânia', iso: 'TZ', iso3: 'TZA', ddi: '+255' },
  { name: 'Timor-Leste', iso: 'TL', iso3: 'TLS', ddi: '+670' },
  { name: 'Tailândia', iso: 'TH', iso3: 'THA', ddi: '+66' },
  { name: 'Togo', iso: 'TG', iso3: 'TGO', ddi: '+228' },
  { name: 'Tokelau', iso: 'TK', iso3: 'TKL', ddi: '+690' },
  { name: 'Tonga', iso: 'TO', iso3: 'TON', ddi: '+676' },
  { name: 'Trinidad e Tobago', iso: 'TT', iso3: 'TTO', ddi: '+1 868' },
  { name: 'Tunísia', iso: 'TN', iso3: 'TUN', ddi: '+216' },
  { name: 'Turquemenistão', iso: 'TM', iso3: 'TKM', ddi: '+993' },
  { name: 'Turquia', iso: 'TR', iso3: 'TUR', ddi: '+90' },
  { name: 'Tuvalu', iso: 'TV', iso3: 'TUV', ddi: '+688' },
  { name: 'Uganda', iso: 'UG', iso3: 'UGA', ddi: '+256' },
  { name: 'Ucrânia', iso: 'UA', iso3: 'UKR', ddi: '+380' },
  { name: 'Uruguai', iso: 'UY', iso3: 'URY', ddi: '+598' },
  { name: 'Estados Unidos (EUA)', iso: 'US', iso3: 'USA', ddi: '+1' },
  { name: 'Uzbequistão', iso: 'UZ', iso3: 'UZB', ddi: '+998' },
  { name: 'Vanuatu', iso: 'VU', iso3: 'VUT', ddi: '+678' },
  { name: 'Vaticano', iso: 'VA', iso3: 'VAT', ddi: '+39' },
  { name: 'Venezuela', iso: 'VE', iso3: 'VEN', ddi: '+58' },
  { name: 'Vietnã (Vietname)', iso: 'VN', iso3: 'VNM', ddi: '+84' },
  { name: 'Wallis e Futuna', iso: 'WF', iso3: 'WLF', ddi: '+681' },
  { name: 'Iémen (Iémen, Yemen)', iso: 'YE', iso3: 'YEM', ddi: '+967' },
  { name: 'Zimbabwe(Zimbabué)', iso: 'ZW', iso3: 'ZWE', ddi: '+263' },
  { name: 'Zâmbia', iso: 'ZM', iso3: 'ZMB', ddi: '+260' },
]

export const countriesOptions: SelectCountryProps[] = countries.map(country => {
  return {
    value: country.ddi,
    label: country,
  }
})
