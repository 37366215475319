import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

export interface GetDailyRecordResponseApi {
  id: number
  date: string
  studied_minutes: number
  effort_perception: number
  number_of_questions: number
  number_of_correct_answers: number
  created_at: string
  field_id: number
  theme_id: number
  subject_id: number
  field: Field
  theme: Theme | null
  themes: Theme[] | null
  subject: Subject | null
  subjects: Subject[] | null
}

interface Field {
  name: string
  id: number
}

interface Theme {
  name: string
  id: number
}

interface Subject {
  name: string
  id: number
}

export interface GetDailyRecord {
  id: number
  field: Field
  date: string
  theme: Theme | null
  themes: Theme[]
  subject?: Subject | null
  subjects?: Subject[]
  studiedMinutes: number
  numberOfQuestions: number
  numberOfCorrectAnswers: number
}

export interface GetDailyRecordsResponse {
  items: GetDailyRecord[]
  meta: MetaResponse
}

interface Params {
  page: number
  limit: number
}

export async function getDailyRecords(params: Params) {
  const { data: dailyRecords } = await api.get<{
    data: GetDailyRecordResponseApi[]
    meta: MetaResponseApi
  }>('/app/daily_records', { params: { ...params } })

  const items = dailyRecords.data.map(dailyRecord => ({
    id: dailyRecord.id,
    field: dailyRecord.field,
    date: dailyRecord.date,
    theme: dailyRecord.theme,
    themes: dailyRecord.themes,
    subject: dailyRecord.subject,
    subjects: dailyRecord.subjects,
    studiedMinutes: dailyRecord.studied_minutes,
    numberOfQuestions: dailyRecord.number_of_questions,
    numberOfCorrectAnswers: dailyRecord.number_of_correct_answers,
  }))

  return {
    items,
    meta: formatMeta(dailyRecords.meta),
  }
}

export function useGetDailyRecords(params: Params) {
  return useQuery(['daily-records', params], () => getDailyRecords(params), {
    refetchOnWindowFocus: false,
  })
}
