import { useToken } from '@chakra-ui/react'
import React from 'react'
import ReactApexChart from 'react-apexcharts'

type AreaChartProps = {
  xaxis?: ApexXAxis
  yaxis?: ApexYAxis
  dataLabels?: ApexDataLabels
  options?: ApexCharts.ApexOptions
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries
}

export const AreaChart: React.FC<AreaChartProps> = ({
  xaxis,
  yaxis,
  series,
  options,
  dataLabels,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const gray200 = useToken('colors', 'gray.200')

  if (!series) return null

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <ReactApexChart
      series={series}
      type={'area'}
      height={'100%'}
      options={{
        chart: {
          foreColor: gray200,
          type: 'area',
          zoom: {
            enabled: false,
          },
          toolbar: {
            tools: {
              download: false,
            },
          },
          fontFamily: 'Mulish',
        },
        grid: {
          strokeDashArray: 2,
          padding: {
            bottom: 25,
            left: 25,
            right: 25,
            top: 25,
          },
        },
        legend: {
          show: false,
        },
        markers: {
          size: 1,
        },
        tooltip: {
          theme: 'dark',
          enabled: true,
          x: {
            formatter: (_, opts) => {
              const seriesIndex = opts.seriesIndex
              const dataPointIndex = opts.dataPointIndex
              const title =
                opts.w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .tooltipTitle

              return title
            },
          },
        },
        colors: ['#FF6B00'],
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            colorStops: [
              [
                {
                  offset: 0,
                  color: 'rgba(255, 195, 152, 0.6)',
                },
                {
                  offset: 100,
                  color: 'rgba(181, 76, 0, 0.6)',
                },
              ],
            ],
          },
        },
        dataLabels: {
          enabled: false,
          ...dataLabels,
        },
        yaxis: {
          ...yaxis,
        },
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },

          ...xaxis,
        },
        ...options,
      }}
    />
  )
}
