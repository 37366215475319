import React from 'react'

import { Tr, Td } from '@chakra-ui/react'

interface Props {
  columns: number
}

export function ListEmpty({ columns }: Props) {
  return (
    <Tr>
      <Td colSpan={columns}>Nenhum registro encontrado</Td>
    </Tr>
  )
}
