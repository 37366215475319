import React, { useEffect, useState } from 'react'
import { LineAreaChart } from 'components/ApexChart/LineAreaChart'
import { useAuth } from 'contexts/auth'
import {
  Flex,
  Heading,
  HStack,
  VStack,
  Box,
  chakra,
  useBreakpointValue,
  Alert,
  AlertIcon,
  IconButton,
  Spinner,
} from '@chakra-ui/react'
import Filters from 'features/dashboard/Filters'
import { DashboardTable } from 'features/dashboard/DashTable'
import ByField from 'features/dashboard/ByField'
import MajorStats from 'features/dashboard/WeeklyGoals/MajorStats'
import RadialQuestions from 'features/dashboard/WeeklyGoals/RadialQuestions'
import { Icon } from '@iconify/react'
import { AreaChart } from 'components/ApexChart/AreaChart'
import { useGetDashboardInfo } from './mutations/use-get-dash-info'
import {
  formatIntervalByAPIFilters,
  getFirstAndLastDayOfDateRange,
} from 'helpers/getFirstAndLastDay'
import { hoursAndMinutesConversion } from 'helpers/hoursAndMinutesConversion'
import { formatDateUTC } from 'helpers/formatTimer'
import { ptBR } from 'date-fns/locale'
import { Option } from './type'
import { getISOWeek } from 'date-fns'

export const Dashboard: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
  const [chart, setChart] = useState<
    'questionsChart' | 'studyHoursChart' | 'percentualChart'
  >('questionsChart')
  const [rangeOption, setRangeOption] = useState<{
    label: string
    value: 'WEEKLY' | 'MONTHLY' | 'YEARLY'
  }>({
    value: 'WEEKLY',
    label: 'Semana',
  })
  const [currentWeek, setCurrentWeek] = useState<number>(getISOWeek(new Date()))
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth() + 1,
  )
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear(),
  )
  const [selectedFieldInfo, setSelectedFieldInfo] = useState<Option | null>(
    null,
  )
  const [selectedThemeId, setSelectedThemeId] = useState<Option | null>(null)

  const [selectedSubjectId, setSelectedSubjectId] = useState<Option | null>(
    null,
  )

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user } = useAuth()
  const { data, isLoading, error } = useGetDashboardInfo({
    periodType: rangeOption.value,
    fieldId: selectedFieldInfo?.value,
    subjectId: selectedSubjectId?.value,
    themeId: selectedThemeId?.value,
    weekNumber: rangeOption.value === 'WEEKLY' ? currentWeek : undefined,
    monthNumber: rangeOption.value === 'MONTHLY' ? currentMonth : undefined,
    yearNumber: currentYear,
  })

  const tickAmount = useBreakpointValue([13, 20, 30, 30])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if ((window as any)?.createSoluCXWidget) {
      setScriptLoaded(true)
    } else {
      window.addEventListener('myScriptLoaded', () => {
        setScriptLoaded(true)
      })
    }
  }, [])

  useEffect(() => {
    if (
      user?.profile?.active_nps_transaction_id &&
      scriptLoaded &&
      process.env.REACT_APP_SOLU_CX
    ) {
      createSoluCXWidget(
        process.env.REACT_APP_SOLU_CX,
        'bottomBox',
        {
          transaction_id: user?.profile.active_nps_transaction_id,
          // name: user?.name,
          // client_id: user?.id,
          // journey: 'mr_nps',
          // cpf: user?.cpf,
          // email: user?.email,
        },
        {
          retryAttempts: 10,
          retryInterval: 3,
          collectInterval: 30,
        },
      )
    }
  }, [scriptLoaded, user?.profile?.active_nps_transaction_id])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (isLoading) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  return (
    <VStack spacing={'2rem'} py="1rem">
      <Flex
        flexDir={{ base: 'column', xl: 'row' }}
        gap={{ base: 8 }}
        w={'100%'}
        justifyContent={'space-between'}
      >
        <Filters
          rangeOption={rangeOption}
          setRangeOption={setRangeOption}
          selectedFieldInfo={selectedFieldInfo}
          setSelectedFieldInfo={setSelectedFieldInfo}
          selectedSubjectId={selectedSubjectId}
          setSelectedSubjectId={setSelectedSubjectId}
          selectedThemeId={selectedThemeId}
          setSelectedThemeId={setSelectedThemeId}
          setCurrentMonth={setCurrentMonth}
          setCurrentWeek={setCurrentWeek}
          setCurrentYear={setCurrentYear}
        />
      </Flex>

      {data?.isFragmentedWeek && (
        <Alert
          w="auto"
          alignSelf={'flex-start'}
          status="warning"
          borderRadius={'5px'}
          background="#855605"
        >
          <AlertIcon />
          Atenção! Devido à semana fracionada entre dois meses, será utilizada a
          meta da semana anterior.
        </Alert>
      )}

      {typeof error?.response?.data === 'string' &&
        error?.response?.data?.includes(
          'A Meta para o período não foi cadastrada.',
        ) && (
          <Alert
            w="auto"
            alignSelf={'flex-start'}
            status="warning"
            borderRadius={'5px'}
            background="#855605"
          >
            <AlertIcon />
            {error?.response?.data}
          </Alert>
        )}

      <Flex
        flexDir={{ base: 'column', xl: 'row' }}
        gap={2}
        justifyContent={'space-between'}
        w={'full'}
      >
        <Flex
          justifyContent={{ base: 'start', xl: 'space-between' }}
          flexGrow={1}
          flexDir={'column'}
          gap={2}
          w="full"
        >
          <Heading fontSize={{ base: '18px', sm: '24px' }} fontWeight="700">
            {chart === 'questionsChart'
              ? 'Questões realizadas e corretas'
              : chart === 'studyHoursChart'
              ? 'Horas estudadas'
              : chart === 'percentualChart'
              ? 'Percentual médio'
              : ''}
          </Heading>

          <Box
            w="full"
            h="full"
            minH={'400px'}
            borderRadius={'4px'}
            backgroundColor={'#302F37'}
            position={'relative'}
          >
            <HStack
              position={'absolute'}
              zIndex={2}
              spacing={3}
              top={4}
              left={3}
            >
              <Icon
                fontSize={'13px'}
                cursor={'pointer'}
                icon={'bxs:left-arrow'}
                onClick={() => {
                  setChart(
                    chart === 'questionsChart'
                      ? 'studyHoursChart'
                      : chart === 'studyHoursChart'
                      ? 'percentualChart'
                      : chart === 'percentualChart'
                      ? 'questionsChart'
                      : 'questionsChart',
                  )
                }}
              />

              <Icon
                fontSize={'13px'}
                cursor={'pointer'}
                icon={'bxs:right-arrow'}
                onClick={() => {
                  setChart(
                    chart === 'questionsChart'
                      ? 'percentualChart'
                      : chart === 'percentualChart'
                      ? 'studyHoursChart'
                      : chart === 'studyHoursChart'
                      ? 'questionsChart'
                      : 'questionsChart',
                  )
                }}
              />
            </HStack>

            {chart === 'questionsChart' && (
              <LineAreaChart
                series={[
                  {
                    name: 'Questões realizadas',
                    type: 'line',
                    data:
                      data?.mainChartData?.map(item =>
                        Number(item?.number_of_questions),
                      ) || [],
                  },
                  {
                    name: 'Questões corretas',
                    type: 'area',
                    data:
                      data?.mainChartData?.map(item => {
                        return Number(item?.number_of_correct_answers)
                      }) || [],
                  },
                ]}
                xaxis={{
                  tickAmount: tickAmount,
                  categories:
                    rangeOption.value === 'WEEKLY'
                      ? data?.mainChartData?.map(item =>
                          formatDateUTC(item.date_slice, 'EEEE', ptBR),
                        ) || []
                      : rangeOption.value === 'MONTHLY'
                      ? data?.mainChartData?.map(item =>
                          formatDateUTC(item.date_slice, 'dd'),
                        ) || []
                      : data?.mainChartData?.map(item => item.date_slice) || [],
                }}
              />
            )}

            {chart === 'studyHoursChart' && (
              <AreaChart
                series={[
                  {
                    name: 'Total de Horas de Estudo',
                    data:
                      data?.mainChartData?.map(item =>
                        Number(item.studied_minutes),
                      ) || [],
                  },
                ]}
                xaxis={{
                  tickAmount: tickAmount,
                  categories:
                    rangeOption.value === 'WEEKLY'
                      ? data?.mainChartData?.map(item =>
                          formatDateUTC(item.date_slice, 'EEEE', ptBR),
                        ) || []
                      : rangeOption.value === 'MONTHLY'
                      ? data?.mainChartData?.map(item =>
                          formatDateUTC(item.date_slice, 'dd'),
                        ) || []
                      : data?.mainChartData?.map(item => item.date_slice) || [],
                }}
                options={{
                  tooltip: {
                    custom: function ({ dataPointIndex, w }) {
                      return (
                        '<div class="custom-tooltip" style="display: flex; flex-direction: column; gap: 1px; background-color:#1D1D1F; padding:10px">' +
                        '<div class="first-element">' +
                        //
                        '<span class="title-1" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px">' +
                        'Total de ' +
                        '</span>' +
                        //
                        '<span class="title-2" style="color:#E56000; font-family:Mulish; font-weight:600; font-size:16px">' +
                        'horas de estudo:' +
                        '</span>' +
                        //
                        '<span class="result-1" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px; margin-left:10px">' +
                        `${hoursAndMinutesConversion(
                          w?.globals?.initialSeries?.[0]?.data?.[
                            dataPointIndex
                          ],
                        )}` +
                        '</span>' +
                        //
                        '</div>' +
                        '</div>' +
                        '<div class="flex-rectangle"/>'
                      )
                    },
                  },
                }}
              />
            )}

            {chart === 'percentualChart' && (
              <AreaChart
                series={[
                  {
                    name: 'Percentual de acertos',
                    data:
                      data?.mainChartData.map(item => {
                        const calcPercentage =
                          (Number(item.number_of_correct_answers) /
                            Number(item.number_of_questions)) *
                          100
                        return Number(calcPercentage.toFixed(1))
                      }) || [],
                  },
                ]}
                xaxis={{
                  tickAmount: tickAmount,
                  categories:
                    rangeOption.value === 'WEEKLY'
                      ? data?.mainChartData?.map(item =>
                          formatDateUTC(item.date_slice, 'EEEE', ptBR),
                        ) || []
                      : rangeOption.value === 'MONTHLY'
                      ? data?.mainChartData?.map(item =>
                          formatDateUTC(item.date_slice, 'dd'),
                        ) || []
                      : data?.mainChartData?.map(item => item.date_slice) || [],
                }}
                options={{
                  tooltip: {
                    custom: function ({ dataPointIndex, w }) {
                      return (
                        '<div class="custom-tooltip" style="display: flex; flex-direction: column; gap: 1px; background-color:#1D1D1F; padding:10px">' +
                        '<div class="first-element">' +
                        //
                        '<span class="title-1" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px">' +
                        'Seu ' +
                        '</span>' +
                        //
                        '<span class="title-2" style="color:#E56000; font-family:Mulish; font-weight:600; font-size:16px">' +
                        'percentual de acertos:' +
                        '</span>' +
                        //
                        '<span class="result-1" style="color:#fff; font-family:Mulish; font-weight:600; font-size:16px; margin-left:10px">' +
                        `${w?.globals?.initialSeries?.[0]?.data?.[dataPointIndex]}%` +
                        '</span>' +
                        //
                        '</div>' +
                        '</div>' +
                        '<div class="flex-rectangle"/>'
                      )
                    },
                  },
                }}
              />
            )}

            {rangeOption && (
              <HStack
                position={'absolute'}
                zIndex={3}
                spacing={3}
                bottom={3}
                left={3}
                maxH={'20px'}
              >
                <chakra.span
                  fontWeight={400}
                  color={'#BDBDC7'}
                  fontSize={'14px'}
                >
                  Referente a
                </chakra.span>

                <chakra.span fontWeight={500} fontSize={'14px'}>
                  {` ${
                    getFirstAndLastDayOfDateRange({
                      monthNumber: currentMonth,
                      type: rangeOption.value,
                      weekNumber: currentWeek,
                      yearNumber: currentYear,
                    }).firstDay
                  } a ${
                    getFirstAndLastDayOfDateRange({
                      monthNumber: currentMonth,
                      type: rangeOption.value,
                      weekNumber: currentWeek,
                      yearNumber: currentYear,
                    }).lastDay
                  }`}
                </chakra.span>

                <HStack spacing={'10px'}>
                  <Icon
                    fontSize={'13px'}
                    cursor={'pointer'}
                    icon={'bxs:left-arrow'}
                    onClick={() => {
                      formatIntervalByAPIFilters({
                        currentMonth,
                        currentWeek,
                        currentYear,
                        setCurrentMonth,
                        setCurrentWeek,
                        setCurrentYear,
                        dateType: rangeOption.value,
                        type: 'previous',
                      })
                    }}
                  />

                  <IconButton
                    aria-label="next"
                    bg={'inherit'}
                    border={'none'}
                    _selected={{ backgroundColor: 'inherit' }}
                    minW={'0px'}
                    disabled={
                      rangeOption.value === 'WEEKLY' &&
                      currentWeek === getISOWeek(new Date())
                        ? true
                        : rangeOption.value === 'MONTHLY' &&
                          currentMonth === new Date().getMonth() + 1
                        ? true
                        : rangeOption.value === 'YEARLY' &&
                          currentYear === new Date().getFullYear()
                        ? true
                        : false
                    }
                    _hover={{ backgroundColor: 'inherit' }}
                    onClick={() => {
                      formatIntervalByAPIFilters({
                        currentMonth,
                        currentWeek,
                        currentYear,
                        setCurrentMonth,
                        setCurrentWeek,
                        setCurrentYear,
                        dateType: rangeOption.value,
                        type: 'next',
                      })
                    }}
                  >
                    <Icon
                      fontSize={'13px'}
                      cursor={'pointer'}
                      icon={'bxs:right-arrow'}
                    />
                  </IconButton>
                </HStack>
              </HStack>
            )}
          </Box>
        </Flex>

        {rangeOption.value !== 'YEARLY' && (
          <VStack
            align={{ base: 'flex-start', sm: 'center', xl: 'flex-start' }}
            spacing={2}
            maxW={{ base: 'full', xl: '429px' }}
          >
            <Heading fontSize={{ base: '18px', sm: '24px' }} fontWeight="700">
              Metas semanais
            </Heading>

            <RadialQuestions
              totalQuestionGoal={data?.indicatorsData?.totalQuestionGoal || 0}
              totalQuestions={data?.indicatorsData?.totalQuestions || 0}
              percentTotalQuestions={
                data?.indicatorsData?.percentTotalQuestions || 0
              }
              currentWeekSelected={currentWeek}
            />

            <MajorStats
              percentCorrectAnswers={
                data?.indicatorsData?.percentCorrectAnswers || 0
              }
              percentCorrectQuestionsToReachTheGoal={
                data?.indicatorsData?.percentCorrectQuestionsToReachTheGoal || 0
              }
              percentStudiedHoursReached={
                data?.indicatorsData?.percentStudiedHoursReached || 0
              }
              percentRemainingHoursToReachTheGoal={
                data?.indicatorsData?.percentRemainingHoursToReachTheGoal || 0
              }
              percentStudiedMinutesPractical={
                data?.indicatorsData?.percentStudiedMinutesPractical || 0
              }
              percentStudiedMinutesTheorycal={
                data?.indicatorsData?.percentStudiedMinutesTheorycal || 0
              }
              totalStudiedHoursPratical={
                data?.indicatorsData?.totalStudiedHoursPratical || 0
              }
              totalStudiedHoursTheorycal={
                data?.indicatorsData?.totalStudiedHoursTheorycal || 0
              }
              correct_answers_percentage={Number(
                data?.trackGoal?.correct_answers_percentage || 0,
              )}
              study_minutes={data?.trackGoal?.study_minutes || 0}
              totalMinutesToReachTheGoal={
                data?.indicatorsData?.totalMinutesToReachTheGoal || 0
              }
              currentWeekSelected={currentWeek}
            />

            <ByField data={data?.indicatorsData?.correctAnswersByField || []} />
          </VStack>
        )}
      </Flex>

      <HStack w="full">
        <DashboardTable />
      </HStack>
    </VStack>
  )
}
