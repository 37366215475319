import { INSTITUTIONS } from 'constants/institutions'
import { useAuth } from 'contexts/auth'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { map } from 'lodash'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { TextAreaController } from 'components/Fields/TextArea/TextAreaController'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Flex } from '@chakra-ui/react'
import { useToastMessage } from 'components/Toast'

interface FormApproved {
  approvedComment: string
  approvedInstitutions: Array<{
    label: string
    value: string
  }>
}

export const ApprovedTab = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hook.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user, updateUserData } = useAuth()

  const showToast = useToastMessage()

  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormApproved>()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const approvalSubmitHandler = useCallback(
    async (formData: FormApproved) => {
      if (!formData) return

      await api
        .put('/app/profiles/approval', {
          approvedComment: formData.approvedComment,
          approvedInstitutions: map(
            formData.approvedInstitutions,
            institution => institution.value,
          ),
        })
        .then(async () => {
          updateUserData()

          showToast({
            title: 'Sucesso',
            description: 'Dados de aprovação atualizados!',
            type: 'success',
            duration: 2000,
            mode: 'dark',
          })
        })
        .catch(e => {
          showToast({
            title: 'Erro',
            description: 'Não foi possível efetuar atualização',
            type: 'error',
            duration: 2000,
            mode: 'dark',
          })

          console.trace(e)
        })
    },
    [showToast, updateUserData],
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const institutionOptions = React.useMemo(() => {
    return INSTITUTIONS.map(institution => ({
      label: institution,
      value: institution,
    }))
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | UseEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!user?.profile) return

    if (user.profile.approved_institutions) {
      setValue(
        'approvedInstitutions',
        user?.profile?.approved_institutions?.map(is => ({
          label: is,
          value: is,
        })),
      )
    }

    if (user.profile.approved_comment) {
      setValue('approvedComment', user.profile.approved_comment)
    }
  }, [getValues, setValue, user])

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      flexDir="column"
      gap="24px"
      maxW={'380px'}
      pl={'20px'}
      w="full"
      onSubmit={handleSubmit(approvalSubmitHandler)}
    >
      <NewFieldSelectRegistryController
        label="Onde você passou?"
        control={control}
        name="approvedInstitutions"
        isMulti={true as any}
        options={institutionOptions}
      />

      <TextAreaController
        label="Depoimento (opcional)"
        placeholder="Escreva aqui o seu depoimento para nós :)"
        control={control}
        name="approvedComment"
        minH={'100px'}
      />

      <DefaultButton label={'Salvar'} isLoading={isSubmitting} type="submit" />
    </Flex>
  )
}
