import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface QueryParams {
  notificationId: number
}

async function mutateViewedNotification(params: QueryParams) {
  await api.patch(
    `/app/notifications/${params.notificationId.toString()}/set_viewed`,
  )
}

export function useMutateViewedNotification() {
  return useMutation(mutateViewedNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-notifications'])
      queryClient.invalidateQueries(['get-unviewed-notifications-count'])
    },
  })
}
