import React from 'react'
import OnboardingTemplate from 'features/onboard'

export const OnboardingPage: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return <OnboardingTemplate />
}
