import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  max-width: 1440px;
  width: 100vw;

  max-height: 1024px;
  height: 100vh;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    ${({ theme: { colors } }) => colors.darkGray} 0%,
    ${({ theme: { colors } }) => colors.darkerGray} 100%,
    ${({ theme: { colors } }) => colors.darkerGray} 100%
  );

  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;

    color: ${({ theme: { colors } }) => colors.lightGray};
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 442px;
      margin-left: 32px;
    }
  }

  #bernardo-welcome {
    margin-left: 64px;
  }

  /* Mobile */
  @media (max-width: 1100px) {
    #bernardo-welcome {
      display: none;
    }
  }

  /* Latpot */
  @media (min-width: 1100px) {
    #logo {
      display: none;
    }

    > p {
      position: absolute;
      bottom: 56px;
    }
  }

  /* Extra Large */
  @media (min-width: 1440px) {
    border-radius: 16px;
    max-height: min(calc(100vh - 64px), 1024px);
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  width: 514px;
  max-width: 90vw;
  height: 434px;
  min-height: 350px;
  max-height: calc(0.92 * 90vw);

  background: ${({ theme: { colors } }) => colors.darkGray};

  box-shadow: 0px 0px 61px 13px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  padding-top: 24px;
  padding-bottom: 24px;

  > p {
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.2px;

    span {
      font-style: normal;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: ${({ theme: { colors } }) => colors.orange};
    }
  }

  > div {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
  }

  > div:last-child {
    a {
      color: rgba(255, 255, 255, 0.6);
      margin-top: 16px;
      font-size: 13px;
    }
  }

  button {
    margin-top: 16px;
  }
`
