import React, { createContext } from 'react'
import Swal from 'sweetalert2'
import * as Sentry from '@sentry/browser'
import api from 'services/api'

import { useAuth } from 'contexts/auth'

const InterceptorContext = createContext({})

export const InterceptorProvider: React.FC = ({ children }) => {
  const { logOut } = useAuth()

  api.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // eslint-disable-next-line no-console
      console.trace(error)

      if (error.response?.status > 500) {
        Sentry.captureException(error)
      }

      if (error?.response?.status === 401) {
        Swal.fire({
          buttonsStyling: false,
          title: 'Sua sessão expirou.',
          html:
            'Você será direcionado para a tela de login. Por favor inicie uma nova sessão para continuar usando a plataforma.',
        }).then(() => {
          logOut()
          window.location.reload()
        })
      }

      if (error?.response?.status === 402) {
        Swal.fire({
          buttonsStyling: false,
          title: 'O seu acesso expirou',
          html:
            '<p style="text-align:center">Mas você pode garantir uma vaga no mentoria residência agora mesmo com uma condição especial</p>',
          showConfirmButton: true,
          confirmButtonText: 'Vamos lá!',
        }).then(({ isConfirmed }) => {
          logOut()
          if (isConfirmed) {
            window.open(
              'https://mentoriaresidencia.com.br/',
              '_blank',
              'noopener,noreferrer',
            )
          }
        })
      }

      return Promise.reject(error)
    },
  )

  return (
    <InterceptorContext.Provider value={{}}>
      {children}
    </InterceptorContext.Provider>
  )
}
