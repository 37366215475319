import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'contexts/auth'
import { queryClient } from 'index'

import api from 'services/api'

type Params = {
  institutionName: string
}

async function tcceptInstitutionTerms(params: Params) {
  await api.patch(
    `/app/users/institutions/${params.institutionName}/accept-institution-terms`,
  )
}

export function useAcceptInstitutionTerms() {
  const { updateUserData } = useAuth()
  return useMutation(tcceptInstitutionTerms, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-terms-by-institution-name'])
      updateUserData()
    },
  })
}
