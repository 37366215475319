import React from 'react'
import {
  chakra,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { getISOWeek } from 'date-fns'

interface RadialQuestionsProps {
  totalQuestionGoal: number
  totalQuestions: number
  percentTotalQuestions: number
  currentWeekSelected: number
}

export default function RadialQuestions(props: RadialQuestionsProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })
  const {
    percentTotalQuestions,
    currentWeekSelected,
    totalQuestions,
    totalQuestionGoal,
  } = props

  const currentWeek = getISOWeek(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (currentWeekSelected === currentWeek) {
    return (
      <HStack
        w="100%"
        maxW="430px"
        maxH={{ base: 'auto', sm: '145px' }}
        borderRadius="4px"
        backgroundColor="#302F37"
        p={4}
        spacing={'16px'}
      >
        <CircularProgress
          size={isSm ? '84px' : '110px'}
          thickness="6px"
          capIsRound
          color={percentTotalQuestions >= 100 ? '#0DDF15' : '#E5C009'}
          trackColor="#616167"
          my={isSm ? '0px' : '16px'}
          value={percentTotalQuestions}
        >
          <CircularProgressLabel fontSize={{ base: '18px', sm: '24px' }}>
            <chakra.span>{percentTotalQuestions.toFixed(0)}%</chakra.span>

            <Text fontWeight={400} fontSize={{ base: '12px', sm: '14px' }}>
              Atingida
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        <VStack
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          w="full"
        >
          <Text fontWeight={'700'} fontSize={{ base: '16px', sm: '18px' }}>
            Questões realizadas
          </Text>

          {percentTotalQuestions <= 0 ? (
            <Flex flexDir={'column'}>
              <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
                Faça{' '}
                <chakra.span
                  fontSize={{ base: '12px', sm: '14px' }}
                  color={'#E56000'}
                >
                  {`${Math.abs(totalQuestionGoal - totalQuestions)} questões`}
                </chakra.span>{' '}
                para atingir a meta dessa semana.
              </Text>
            </Flex>
          ) : percentTotalQuestions === 100 ? (
            <Flex flexDir={'column'}>
              <Text fontSize={{ base: '12px', sm: '14px' }} color="#0DDF15">
                Parabéns!
              </Text>
              <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
                Você atingiu a meta de questões.
              </Text>
            </Flex>
          ) : percentTotalQuestions > 100 ? (
            <Flex flexDir={'column'}>
              <Text fontSize={{ base: '12px', sm: '14px' }} color="#0DDF15">
                Uouu, Parabéns!
              </Text>
              <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
                Você está acima da meta.
              </Text>
            </Flex>
          ) : (
            <Flex flexDir={'column'}>
              <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
                Faltam{' '}
                <chakra.span
                  fontSize={{ base: '12px', sm: '14px' }}
                  color={'#E56000'}
                >
                  {`${Math.abs(totalQuestionGoal - totalQuestions)} questões`}
                </chakra.span>{' '}
                para atinjir a meta dessa semana.
              </Text>
            </Flex>
          )}

          <HStack justifyContent={'space-between'} w="full">
            <Flex flexDir={'column'} w="full">
              <Text fontSize={{ base: '12px', sm: '14px' }}>Meta</Text>
              <Text noOfLines={1} fontSize={{ base: '12px', sm: '14px' }}>
                Questões realizadas
              </Text>
            </Flex>

            <Flex flexDir={'column'}>
              <Text
                noOfLines={1}
                fontSize={{ base: '12px', sm: '14px' }}
                w="max-content"
              >
                {totalQuestionGoal} questões
              </Text>

              <Text
                color={percentTotalQuestions >= 100 ? '#0DDF15' : '#E5C009'}
                fontSize={{ base: '12px', sm: '14px' }}
                noOfLines={1}
                w="max-content"
              >
                {totalQuestions} questões
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </HStack>
    )
  }

  return (
    <HStack
      w="100%"
      maxW="430px"
      maxH={{ base: 'auto', sm: '145px' }}
      borderRadius="4px"
      backgroundColor="#302F37"
      p={4}
      spacing={'16px'}
    >
      <CircularProgress
        size={isSm ? '84px' : '110px'}
        thickness="6px"
        capIsRound
        color={percentTotalQuestions >= 100 ? '#0DDF15' : '#E5C009'}
        trackColor="#616167"
        my={isSm ? '0px' : '16px'}
        value={percentTotalQuestions}
      >
        <CircularProgressLabel fontSize={{ base: '18px', sm: '24px' }}>
          <chakra.span>{percentTotalQuestions.toFixed(0)}%</chakra.span>

          <Text fontWeight={400} fontSize={{ base: '12px', sm: '14px' }}>
            Atingida
          </Text>
        </CircularProgressLabel>
      </CircularProgress>

      <VStack
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        w="full"
      >
        <Text fontWeight={'700'} fontSize={{ base: '16px', sm: '18px' }}>
          Questões realizadas
        </Text>

        {percentTotalQuestions <= 0 ? (
          <Flex flexDir={'column'}>
            <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
              Faça{' '}
              <chakra.span
                fontSize={{ base: '12px', sm: '14px' }}
                color={'#E56000'}
              >
                {`${Math.abs(totalQuestionGoal - totalQuestions)} questões`}
              </chakra.span>{' '}
              para atingir a meta dessa semana.
            </Text>
          </Flex>
        ) : percentTotalQuestions === 100 ? (
          <Flex flexDir={'column'}>
            <Text fontSize={{ base: '12px', sm: '14px' }} color="#0DDF15">
              Parabéns!
            </Text>
            <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
              Você atingiu a meta de questões.
            </Text>
          </Flex>
        ) : percentTotalQuestions > 100 ? (
          <Flex flexDir={'column'}>
            <Text fontSize={{ base: '12px', sm: '14px' }} color="#0DDF15">
              Uouu, Parabéns!
            </Text>
            <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
              Você está acima da meta.
            </Text>
          </Flex>
        ) : (
          <Flex flexDir={'column'}>
            <Text fontSize={{ base: '12px', sm: '14px' }} color="#BDBDC7">
              Faltou pouco para atingir a meta no período selecionado.
            </Text>
          </Flex>
        )}

        <HStack justifyContent={'space-between'} w="full">
          <Flex flexDir={'column'} w="full">
            <Text fontSize={{ base: '12px', sm: '14px' }}>Meta</Text>
            <Text noOfLines={1} fontSize={{ base: '12px', sm: '14px' }}>
              Questões realizadas
            </Text>
          </Flex>

          <Flex flexDir={'column'}>
            <Text
              noOfLines={1}
              fontSize={{ base: '12px', sm: '14px' }}
              w="max-content"
            >
              {totalQuestionGoal} questões
            </Text>

            <Text
              color={percentTotalQuestions >= 100 ? '#0DDF15' : '#E5C009'}
              fontSize={{ base: '12px', sm: '14px' }}
              noOfLines={1}
              w="max-content"
            >
              {totalQuestions} questões
            </Text>
          </Flex>
        </HStack>
      </VStack>
    </HStack>
  )
}
