import { VStack, Text, StackProps } from '@chakra-ui/react'
import React from 'react'

type CardSecondStepTrackProps = {
  matchSelectedTrack: boolean
  trackTitle: string
  trackDescription: string
} & StackProps

export default function CardSecondStepTrack(props: CardSecondStepTrackProps) {
  const { matchSelectedTrack, trackDescription, trackTitle, ...rest } = props

  return (
    <VStack
      p="20px"
      spacing={'24px'}
      border={matchSelectedTrack ? 'unset' : '1px solid #2B2B2B'}
      boxShadow={matchSelectedTrack ? 'unset' : '1px 1px 6px 0px #110F0F66'}
      bg={matchSelectedTrack ? '#E56000' : '#1a202c'}
      maxH="175px"
      role="group"
      h="full"
      borderRadius="8px"
      cursor="pointer"
      _hover={
        matchSelectedTrack
          ? {
              bg: '#FF6B00',
              color: 'white',
              transform: 'scale(1.05)',
              transition: 'all 0.8s',
            }
          : {
              bg: '#E56000',
              color: 'white',
              transform: 'scale(1.05)',
              transition: 'all 0.8s',
            }
      }
      {...rest}
    >
      <Text
        _groupHover={{
          color: 'white',
          transition: 'color 0.8s',
        }}
        color={matchSelectedTrack ? 'white' : '#E56000'}
        fontWeight="700"
        fontSize="1.5rem"
        lineHeight="30px"
        justifySelf={'flex-end'}
        alignSelf={'flex-end'}
      >
        {trackTitle}
      </Text>

      <Text>{trackDescription}</Text>
    </VStack>
  )
}
