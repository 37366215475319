import { useTrack } from 'contexts/track'
import React from 'react'
import { components } from 'react-select'
import { capitalize } from 'lodash'
import { chakra, Text } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import {
  FieldSelectDashboardCustomProps,
  NewFieldSelect,
} from 'components/Fields/FieldDashboard'

interface CustomSearchSelectProps extends FieldSelectDashboardCustomProps {}

const CustomSearchSelect = (props: CustomSearchSelectProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { track } = useTrack()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <BiSearch color="#FFFFFF" size={'13px'} />
      </components.DropdownIndicator>
    )
  }

  const PlaceHolderSelect = (props: any) => {
    return (
      <Text
        fontWeight={400}
        color={'#BDBDC7'}
        fontSize={'16px'}
        lineHeight={'20px'}
        {...props}
      >
        Digite ou escolha a{' '}
        <chakra.span color={'#FFFFFF'}>grande área</chakra.span>
      </Text>
    )
  }

  return (
    <NewFieldSelect
      options={
        track?.fields
          .map(field => ({
            label: capitalize(field.name),
            value: field.id,
          }))
          .concat([{ label: 'Todos', value: -1 }]) || []
      }
      defaultValue={{ label: 'Todos', value: -1 }}
      classNamePrefix="custom-select"
      components={{ DropdownIndicator }}
      placeholder={<PlaceHolderSelect />}
      {...props}
    />
  )
}

export default CustomSearchSelect
