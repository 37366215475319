import { emptyStringToNull } from 'helpers/emptyStringToNull'
import * as yup from 'yup'

export const schemaConcludeSimulationForm = yup.object().shape({
  estimatedTime: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .required('Tempo estimado é obrigatório'),

  effortPerception: yup
    .number()
    .required('Por favor selecione a percepção de esforço')
    .min(1, 'A percepção de esforço deve ser maior ou igual a 1')
    .max(10, 'A percepção de esforço deve ser menor ou igual a 10'),
})
