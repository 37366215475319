import React from 'react'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Styles
import Layout from 'layouts/Logged'
import { Container, TableContainer } from './styles'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'
import { ListDailyRecords } from 'features/daily-records/list-daily-records'

const DailyRecords: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMd = useBreakpointValue({ base: false, lg: true })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Registros diários"
          subTitle="Veja todos os seus registros diários aqui"
          hasVideoOrientation={isMd}
          linkVideo="https://www.youtube.com/watch?v=rA0Ll2yhoqU"
        />
        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://www.youtube.com/watch?v=rA0Ll2yhoqU"
              mt="8px"
            />
          </Flex>
        )}
        <SectionTitle>REGISTROS DIÁRIOS</SectionTitle>

        <TableContainer>
          <ListDailyRecords />
        </TableContainer>
      </Container>
    </Layout>
  )
}

export default DailyRecords
