import React from 'react'

import Logged from 'layouts/Logged'

import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react'
import Header from 'components/Header'
import { ListDeletedAgendas } from 'features/agendas'
import {
  ListDeletedDailyRecords,
  ListDeletedPendingStudies,
} from 'features/daily-records'
import { ListDeletedTests } from 'features/tests'
import VideoOrientation from 'components/VideoOrientation'
import { ListDeleteProjectX } from 'features/project-x/ListDeletedProjectX'

function TrashPage() {
  const isMd = useBreakpointValue({ base: false, lg: true })

  return (
    <Logged>
      <Box
        flex="1"
        height="100%"
        p="24px"
        overflowY="scroll"
        flex-direction="column"
        sx={{
          '&::-webkit-scrollbar': {
            width: '8px',
            background: '#2a2a2d',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(223, 224, 235,0.06)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(223, 224, 235,0.6)',
            borderRadius: '16px',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'rgba(0,0,0,0)',
          },
        }}
      >
        <Header
          title="Lixeira"
          subTitle="Nessa página você pode ver os registros que foram deletados."
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://youtu.be/EeHkneFJxbE"
        />
        {!isMd && (
          <VideoOrientation linkVideo="https://youtu.be/EeHkneFJxbE" mt="8px" />
        )}

        <Tabs w="full" mt="8" colorScheme="brand">
          <TabList>
            <Tab>Agendas</Tab>
            <Tab>Registros</Tab>
            <Tab>Provas e simulados</Tab>
            <Tab>Estudos pendentes</Tab>
            <Tab>Projeto X</Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0">
              <ListDeletedAgendas />
            </TabPanel>

            <TabPanel px="0">
              <ListDeletedDailyRecords />
            </TabPanel>

            <TabPanel px="0">
              <ListDeletedTests />
            </TabPanel>

            <TabPanel px="0">
              <ListDeletedPendingStudies />
            </TabPanel>

            <TabPanel px="0">
              <ListDeleteProjectX />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Logged>
  )
}

export default TrashPage
