import React from 'react'

import { Center, Text } from '@chakra-ui/react'

interface Props {
  message?: string
}

export function Empty({ message = 'Nenhum registro encontrado' }: Props) {
  return (
    <Center>
      <Text>{message}</Text>
    </Center>
  )
}
