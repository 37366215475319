import { MetaResponse, MetaResponseApi } from 'types/response'

export function formatMeta(meta: MetaResponseApi): MetaResponse {
  return {
    total: meta.total,
    perPage: meta.per_page,
    currentPage: meta.current_page,
    lastPage: meta.last_page,
    firstPage: meta.first_page,
    firstPageUrl: meta.first_page_url,
    lastPageUrl: meta.last_page_url,
    nextPageUrl: meta.next_page_url,
    previousPageUrl: meta.previous_page_url,
  }
}
