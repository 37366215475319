import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponseApi } from 'types/response'

interface Params {
  isWeek?: boolean
  isTheoretical?: boolean
  sort?: string
  limit?: number
  page?: number
}

interface Subjects {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Themes {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

export interface ListPendingStudiesCompletedData {
  created_at: string
  field: {
    name: string
    id: number
  }
  field_id: number
  finishes_at: string
  id: number
  is_programmed_study: boolean
  is_theoretical: boolean
  starts_at: string
  study_time_in_minutes: number
  subject: null
  subject_id: number | null
  subjects: Subjects[]
  theme: null
  theme_id: number | null
  themes: Themes[]
}

interface ListProgramedResponse {
  meta: MetaResponseApi
  data: ListPendingStudiesCompletedData[]
}

async function listProgramedStudies(params?: Params) {
  const { data } = await api.get<ListProgramedResponse>(
    '/app/pending_studies/completed',
    {
      params: {
        sort: params?.sort && params.sort,
        limit: params?.limit,
        page: params?.page,
        isWeek: params?.isWeek,
        isTheoretical: params?.isTheoretical,
      },
    },
  )

  return data
}

export function useListProgrammedStudies(params?: Params) {
  return useQuery(
    ['pending-studies-completed', params],
    () => listProgramedStudies(params),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
