export const videoData = [
  {
    moduleName: 'Módulo 01',
    videos: [
      {
        videoTitle: 'Aula Introdutória',
        videoUrl:
          'https://www.youtube.com/embed/Z_7qBHtfVls?si=mG6h9VPUCiqP3bzn',
      },
      {
        videoTitle: 'O poder e a importância do auto-conhecimento',
        videoUrl:
          'https://www.youtube.com/embed/REdligt6Xuc?si=NsOIO3NY3SyV7-FI',
      },
      {
        videoTitle: 'Calibrando Percepções',
        videoUrl:
          'https://www.youtube.com/embed/6AY8bw3K-dA?si=3my37Fmsyol5LtRW',
      },
      {
        videoTitle: 'Teste VIA (Forças e Virtudes)',
        videoUrl:
          'https://www.youtube.com/embed/tVqGLJXZaoU?si=hHiTqyijvuILQ4F0',
      },
      {
        videoTitle: 'Modelo PERMA',
        videoUrl:
          'https://www.youtube.com/embed/D-6l4uSMBxw?si=NKNUvyfNvwzx0M6m',
      },
      {
        videoTitle: 'Roda da Abundância',
        videoUrl:
          'https://www.youtube.com/embed/CkIk2r5z7kg?si=K201EN_Kr1gpp0tT',
      },
      {
        videoTitle: 'Roda da Vida',
        videoUrl:
          'https://www.youtube.com/embed/w9v8I2KBkX0?si=cXNZPMzJpBlscoGt',
      },
    ],
    docs: [
      {
        docTitle: 'Ferramentas Calibrando Percepções',
        docUrl:
          'https://drive.google.com/file/d/18vnN5BOXXoTRUB5nQqKexrWSBjXAFJ_p/view',
      },
      {
        docTitle: 'Quadro Forças e Virtudes',
        docUrl:
          'https://drive.google.com/file/d/1gr6PIZVMmxqVdf3Eb7PujGGKLUCR0bWj/view',
      },
      {
        docTitle: 'Roda da Vida MV 2.0',
        docUrl:
          'https://drive.google.com/file/d/1Ou22VxuXhMZ8p7heyUHU9wnLqNOyJbEd/view',
      },
      {
        docTitle: 'Roda da Abundância',
        docUrl:
          'https://drive.google.com/file/d/1VJfO98spdDp4gWs_5moXnHay_HCtd1Jx/view',
      },
    ],
    externalUrls: [
      {
        title: 'Minha Versão 2.0',
        url: 'https://minhaversao20.pro.viasurvey.org/',
      },
    ],
  },
  {
    moduleName: 'Módulo 02',
    videos: [
      {
        videoTitle: 'Decisões',
        videoUrl:
          'https://www.youtube.com/embed/opCyOCB3sEI?si=Hb4pzgwHTbqBlayS',
      },
      {
        videoTitle: 'Propósito Maior de Vida',
        videoUrl:
          'https://www.youtube.com/embed/1uHM-T-krUA?si=FckTGg-STaazVR_Q',
      },
      {
        videoTitle: 'Tríade do Tempo',
        videoUrl:
          'https://www.youtube.com/embed/eR5WjiFRabU?si=jc_c7NjkaLJL_Ajf',
      },
      {
        videoTitle: 'Constância e Perseverança',
        videoUrl:
          'https://www.youtube.com/embed/X3YRIqn-VXw?si=qCdnOFrzMtn894_2',
      },
    ],
    docs: [],
    externalUrls: [
      {
        title: 'Teste Tríade do Tempo',
        url: 'https://teste.triadps.com/',
      },
    ],
  },
  {
    moduleName: 'Módulo 03',
    videos: [
      {
        videoTitle: 'Consciência Emocional',
        videoUrl:
          'https://www.youtube.com/embed/bweSk3D5Buk?si=7kvT7SkEu3lolfk2',
      },
      {
        videoTitle: 'Regra 3x1',
        videoUrl:
          'https://www.youtube.com/embed/xw1bv4cgmQM?si=jqGhT2T6J7dxYFSk',
      },
      {
        videoTitle: 'Perdoar',
        videoUrl:
          'https://www.youtube.com/embed/FTdWr0G74rw?si=ogctWE5GjNOXbrLy',
      },
      {
        videoTitle: 'Autofeedback',
        videoUrl:
          'https://www.youtube.com/embed/OKhDy3wO5vw?si=R4RILIGNAp2SHI3r',
      },
      {
        videoTitle: 'Meditação',
        videoUrl:
          'https://www.youtube.com/embed/0yXOmxfl76w?si=fqUmTSaGHK7BD4Bb',
      },
      {
        videoTitle: 'Gratidão',
        videoUrl:
          'https://www.youtube.com/embed/Ij7oTdn63AU?si=FQ1LLQC5OHAWdviG',
      },
    ],
    docs: [],
    externalUrls: [
      {
        title: 'Teste Regra 3x1',
        url: 'https://www.companhiadasletras.com.br/testeinteligenciapositiva/',
      },
    ],
  },
  {
    moduleName: 'Módulo 04',
    videos: [
      {
        videoTitle: 'Liderança',
        videoUrl:
          'https://www.youtube.com/embed/4_Tk2Gs0bhU?si=gp0Owu_adm2jr0jf',
      },
      {
        videoTitle: 'Confiança e Atitude Positiva',
        videoUrl:
          'https://www.youtube.com/embed/zMZCllvobmY?si=TFW9YhEvKKAH1gap',
      },
      {
        videoTitle: 'Comunicação Assertiva',
        videoUrl:
          'https://www.youtube.com/embed/wwaH9b_M9ac?si=hCbCBJPNJbUCpwHf',
      },
      {
        videoTitle: 'Trabalho em Equipe',
        videoUrl:
          'https://www.youtube.com/embed/7kxZGn1TUY8?si=3MrjRNPslNLL6v_C',
      },
      {
        videoTitle: 'Resiliência',
        videoUrl:
          'https://www.youtube.com/embed/VlD-CnYK_zU?si=LsI7YHPs1tH_Mzdn',
      },
      {
        videoTitle: 'Flexibilidade e Adaptabilidade',
        videoUrl:
          'https://www.youtube.com/embed/VTr-9oFFfnU?si=4M69xq8Do5jcogPZ',
      },
      {
        videoTitle: 'Reação sob Pressão',
        videoUrl:
          'https://www.youtube.com/embed/4W8w385I9iI?si=y8fFuWHhmyCW4QQ1',
      },
      {
        videoTitle: 'Encerramento',
        videoUrl:
          'https://www.youtube.com/embed/sIR5vCPFmyA?si=YSFzBj0q26_36OpU',
      },
    ],
    docs: [],
    externalUrls: [],
  },
]
