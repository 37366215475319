import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Text,
  ModalProps,
  Heading,
  VStack,
  HStack,
  Progress,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Icon } from '@iconify/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ConcludedSimulationForm } from 'features/simulation/types'
import { schemaConcludeSimulationForm } from './schema'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import { useHistory } from 'react-router'
import { FieldInputRegister } from 'components/Fields/FieldInputRegister/FieldInputRegister'
import { useAnswerQuestion } from 'features/simulation/mutations/use-mutate-create-question'

interface ModalSimulationResultProps extends Omit<ModalProps, 'children'> {
  calcPercenterQuestions: () => number | undefined
  calcNumbers: () => string | undefined
  totalQuestions: number
  mockExamId?: number
  chosenAlternative?: 'a' | 'b' | 'c' | 'd' | 'e' | ''
  mockExamQuestionId?: number
}

export default function ModalSimulationResult(
  props: ModalSimulationResultProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<ConcludedSimulationForm>({
    resolver: yupResolver(schemaConcludeSimulationForm),
    defaultValues: {
      effortPerception: 5,
    },
  })

  const showToast = useToastMessage()

  const { push } = useHistory()

  const answerQuestion = useAnswerQuestion()

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    isOpen,
    onClose,
    calcPercenterQuestions: calcPercentualQuestions,
    totalQuestions,
    calcNumbers,
    mockExamId,
    chosenAlternative,
    mockExamQuestionId,
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmitRevisionExam = async (data: ConcludedSimulationForm) => {
    if (!chosenAlternative || !mockExamQuestionId || !mockExamId) return

    try {
      await answerQuestion.mutateAsync({
        chosenAlternative,
        effortPerception: data.effortPerception,
        studiedMinutes: data.estimatedTime,
        mockExamId: mockExamId,
        mockExamQuestionId,
      })

      showToast({
        title: 'Sucesso!',
        description: 'Você concluiu o simulado.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      push(`/simulation/review/${mockExamId}`)
      onClose()
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível concluir o simulado.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const onSubmitConcludeExam = async (data: ConcludedSimulationForm) => {
    if (!chosenAlternative || !mockExamQuestionId || !mockExamId) return

    try {
      await answerQuestion.mutateAsync({
        chosenAlternative,
        effortPerception: data.effortPerception,
        studiedMinutes: data.estimatedTime,
        mockExamId: mockExamId,
        mockExamQuestionId,
      })

      showToast({
        title: 'Sucesso!',
        description: 'Você concluiu o simulado.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      push('/simulation')
      onClose()
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível concluir o simulado.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        p="0px"
        background={'#302F37'}
        maxW={'774px'}
        w="100%"
        borderRadius={'8px'}
        overflow={'hidden'}
        transition={'all 0.2s ease-in-out'}
        as="form"
      >
        <ModalHeader
          color={'#FFFF'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Heading fontFamily={'Mulish'} fontSize={'22px'} fontWeight={700}>
            Simulado
          </Heading>
        </ModalHeader>

        <ModalBody color={'#FFFF'} background={'#302F37'} w="100%">
          <Text mb="30px">Confira a quantidade de questões</Text>

          <VStack w="full" spacing={'24px'}>
            <Icon
              fontSize={'84px'}
              icon={'icon-park-solid:check-one'}
              fill="#0DDF15"
              color="#0DDF15"
            />

            <Text fontWeight={'400'} fontSize={'24px'}>
              {`Você realizou ${totalQuestions}/${totalQuestions} questões`}
            </Text>
            <HStack w="100%">
              <Progress
                colorScheme={'brand'}
                value={calcPercentualQuestions() ?? 0}
                h="5px"
                w="95%"
                borderRadius={'12px'}
              />

              <Text fontWeight={'400'} fontSize={'14px'}>
                {calcNumbers()}
              </Text>
            </HStack>
          </VStack>

          <VStack mt="24px" spacing={'1rem'}>
            <FieldInputController
              label="Tempo para execução do simulado"
              name="estimatedTime"
              error={errors.estimatedTime}
              control={control}
              type="number"
              placeholder="Preencha o tempo estimado em minutos"
            />

            <FieldInputRegister
              name="effortPerception"
              label={`Percepção de esforço: ${watch('effortPerception')}`}
              register={register}
              inputProps={{
                min: 1,
                max: 10,
                type: 'range',
                border: 'none',
                _focus: {
                  boxShadow: 'none',
                },
              }}
              error={errors.effortPerception}
            />
          </VStack>
        </ModalBody>

        <ModalFooter
          flexDir={{ base: 'column', sm: 'row' }}
          gap={'20px'}
          borderTop={'1px solid #43424C'}
        >
          <DefaultButton
            w={{ base: 'full', sm: '50%' }}
            label="Confira o gabarito"
            name="check_feedback"
            isLoading={answerQuestion.isLoading}
            onClick={handleSubmit(onSubmitRevisionExam)}
            variant="ghost"
          />

          <DefaultButton
            w={{ base: 'full', sm: '50%' }}
            name="conclude_exam"
            onClick={handleSubmit(onSubmitConcludeExam)}
            isLoading={answerQuestion.isLoading}
            label="Concluir simulado"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
