import {
  Tabs,
  TabList,
  Text,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React from 'react'
import { FiCheckSquare } from 'react-icons/fi'
import DiaryTable from './DiaryTable'
import TestTable from './TestTable'

export const DashboardTable = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const isSm = useBreakpointValue({ base: true, sm: false })

  return (
    <Tabs onChange={setSelectedIndex} variant="enclosed" w="full">
      <TabList borderBottom={'0px !important'}>
        <Tab
          h="44px"
          justifyContent={'flex-start'}
          w={isSm && selectedIndex !== 0 ? '56px' : '180px'}
          fontFamily={'Mulish'}
          fontWeight="400"
          fontSize={'16px'}
          borderRadius="8px 0px 0px 0px"
          lineHeight="20px"
          backgroundColor={'#393940'}
          gap={'10px'}
          aria-selected={selectedIndex === 0}
          border="0px !important"
          boxShadow={'none !important'}
          margin="0px !important"
          color="#BDBDC7 !important"
          _selected={{
            fontWeight: 600,
            borderBottom: '4px solid #E56000 !important',
            color: '#FFFF !important',
          }}
        >
          <FiCheckSquare />
          <Text>{isSm && selectedIndex !== 0 ? '' : 'Estudos diários'}</Text>
        </Tab>
        <Tab
          borderRadius="0px 8px 0px 0px"
          aria-selected={selectedIndex === 1}
          h="44px"
          justifyContent={'flex-start'}
          w={isSm && selectedIndex !== 1 ? '56px' : '210px'}
          fontFamily={'Mulish'}
          fontWeight="400"
          fontSize={'16px'}
          lineHeight="20px"
          backgroundColor={'#393940'}
          boxShadow={'none !important'}
          gap={'10px'}
          color="#BDBDC7"
          _selected={{
            fontWeight: 600,
            borderBottom: '4px solid #E56000 !important',
            color: '#FFFF !important',
          }}
        >
          <Icon fontSize={'16px'} color="#FFF" icon="eva:plus-square-outline" />
          <Text>{isSm && selectedIndex !== 1 ? '' : 'Provas e simulados'}</Text>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p="0px !important">
          <DiaryTable />
        </TabPanel>
        <TabPanel p="0px !important">
          <TestTable />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
