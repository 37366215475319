import { Box, Flex, useRadio, UseRadioProps } from '@chakra-ui/react'
import React, { ForwardRefRenderFunction } from 'react'

export type RadioCardProps = UseRadioProps & {
  children: React.ReactNode
}

const RadioCardBase: ForwardRefRenderFunction<any, RadioCardProps> = (
  props,
  ref,
) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input ref={ref} {...input} />

      <Flex
        {...checkbox}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        height="42px"
        p="11px 14.5px"
        fontSize="14px"
        fontWeight={500}
        lineHeight={'17px'}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="2px"
        transition="all 0.4s"
        color="rgba(255,255,255,0.3)"
        borderColor="rgba(255,255,255,0.2)"
        borderStyle="solid"
        _checked={{
          bg: '#E56000',
          color: '#ffffff',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _hover={{
          borderColor: 'rgba(229, 95, 0,0.4)',
          bg: 'rgba(255, 107, 0, 0.05)',
          color: 'rgba(255, 255, 255, 0.45)',
        }}
      >
        {props.children}
      </Flex>
    </Box>
  )
}

export const RadioCard = React.forwardRef(RadioCardBase)
