import {
  addDays,
  addWeeks,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getISOWeeksInYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React from 'react'

const getFirstAndLastDayOfWeek = (weekNumber: number, yearNumber: number) => {
  const firstDayOfYear = new Date(yearNumber, 0, 1)

  const firstDayOfFirstISOWeek = startOfWeek(firstDayOfYear)

  const firstDayOfDesiredWeek = addDays(
    firstDayOfFirstISOWeek,
    (weekNumber - 1) * 7,
  )

  const lastDayOfDesiredWeek = endOfWeek(firstDayOfDesiredWeek)

  return {
    firstDayOfWeek: addDays(firstDayOfDesiredWeek, 1).toLocaleDateString(
      'pt-BR',
    ),
    lastDayOfWeek: addDays(lastDayOfDesiredWeek, 1).toLocaleDateString('pt-BR'),
  }
}

const getFirstAndLastDayOfMonth = (month: number, year: number) => {
  const firstDayOfMonth = startOfMonth(new Date(year, month - 1))

  const lastDayOfMonth = endOfMonth(new Date(year, month - 1))

  return {
    firstDayOfMonth: format(firstDayOfMonth, 'dd/MM/yyyy', { locale: ptBR }),
    lastDayOfMonth: format(lastDayOfMonth, 'dd/MM/yyyy', { locale: ptBR }),
  }
}

const getFirstAndLastDayOfYear = (year: number) => {
  const firstDayOfYear = startOfYear(new Date(year, 11, 1))

  const lastDayOfYear = endOfYear(new Date(year, 11, 31))

  return {
    firstDayOfYear: format(firstDayOfYear, 'dd/MM/yyyy', { locale: ptBR }),
    lastDayOfYear: format(lastDayOfYear, 'dd/MM/yyyy', { locale: ptBR }),
  }
}

type DateRangeType = 'WEEKLY' | 'MONTHLY' | 'YEARLY'

export const getFirstAndLastDayOfDateRange = ({
  weekNumber,
  monthNumber,
  yearNumber,
  type,
}: {
  weekNumber: number
  monthNumber: number
  yearNumber: number
  type: DateRangeType
}) => {
  switch (type) {
    case 'WEEKLY':
      return {
        firstDay: getFirstAndLastDayOfWeek(weekNumber, yearNumber)
          .firstDayOfWeek,
        lastDay: getFirstAndLastDayOfWeek(weekNumber, yearNumber).lastDayOfWeek,
      }

    case 'MONTHLY':
      return {
        firstDay: getFirstAndLastDayOfMonth(monthNumber, yearNumber)
          .firstDayOfMonth,
        lastDay: getFirstAndLastDayOfMonth(monthNumber, yearNumber)
          .lastDayOfMonth,
      }

    case 'YEARLY':
      return {
        firstDay: getFirstAndLastDayOfYear(yearNumber).firstDayOfYear,
        lastDay: getFirstAndLastDayOfYear(yearNumber).lastDayOfYear,
      }

    default:
      throw new Error('Tipo de intervalo de datas inválido')
  }
}

type FormatIntervalByAPIFiltersType = {
  dateType: DateRangeType
  setCurrentWeek: React.Dispatch<React.SetStateAction<number>>
  setCurrentMonth: React.Dispatch<React.SetStateAction<number>>
  setCurrentYear: React.Dispatch<React.SetStateAction<number>>
  currentWeek: number
  currentMonth: number
  currentYear: number
  type: 'previous' | 'next'
}

export const formatIntervalByAPIFilters = (
  data: FormatIntervalByAPIFiltersType,
) => {
  const {
    currentMonth,
    currentWeek,
    currentYear,
    type,
    setCurrentMonth,
    setCurrentWeek,
    setCurrentYear,
  } = data

  switch (data.dateType) {
    case 'WEEKLY':
      if (type === 'previous') {
        const lastPreviousYearDay = endOfYear(new Date(currentYear - 1, 11, 31))
        const lastPreviousYearWeek = getISOWeeksInYear(lastPreviousYearDay)

        if (currentWeek > 1) {
          setCurrentWeek(week => week - 1)
        }

        if (currentWeek === 1) {
          setCurrentYear(year => year - 1)
          setCurrentWeek(lastPreviousYearWeek)
        }
      }

      if (type === 'next') {
        const currentYearWeeks = getISOWeeksInYear(
          new Date(currentYear, 11, 31),
        )

        if (currentWeek < currentYearWeeks) {
          setCurrentWeek(currentWeek + 1)
        }

        if (currentWeek === currentYearWeeks) {
          setCurrentYear(year => year + 1)
          setCurrentWeek(1)
        }
      }

      break
    case 'MONTHLY':
      if (type === 'previous') {
        if (currentMonth > 1) {
          setCurrentMonth(month => month - 1)
        }

        if (currentMonth === 1) {
          setCurrentYear(currentYear => currentYear - 1)
          setCurrentMonth(12)
        }
      }

      if (type === 'next') {
        if (currentMonth < 12) {
          setCurrentMonth(month => month + 1)
        }

        if (currentMonth === 12) {
          setCurrentYear(currentYear => currentYear + 1)
          setCurrentMonth(1)
        }
      }
      break
    case 'YEARLY':
      if (type === 'previous') {
        if (currentYear > 1) {
          setCurrentYear(year => year - 1)
        }
      }

      if (type === 'next') {
        if (currentYear < new Date().getFullYear()) {
          setCurrentYear(year => year + 1)
        }
      }
      break
    default:
      throw new Error('Tipo de intervalo de datas inválido')
  }
}
