import { Box, Flex, Link, Text, useBreakpointValue } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import './style.css'
import { Icon } from '@iconify/react'
import { useAuth } from 'contexts/auth'
import { DefaultButton } from 'components/Buttons/DefaultButton'

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'

export type ModalWelcomeWhatsGroupProps = {
  isOpen: boolean
  onClose: () => void
  size?: Size
  children?: ReactNode
  onCloseIcon: () => void
  whatsLink: string
} & ReactModalProps

const contentStyles = () => {
  return {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    border: '0px',
  }
}

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '300px',
    sm: '385px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalWelcomeWhatsGroup = (props: ModalWelcomeWhatsGroupProps) => {
  const {
    isOpen,
    onClose,
    size = 'sm',
    children,
    onCloseIcon,
    whatsLink,
    ...reactModalProps
  } = props

  const isSM = useBreakpointValue({ base: true, sm: false })
  const { user } = useAuth()

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          ...contentStyles(),
          width: isSM ? getWidthBySize('xsm') : getWidthBySize('sm'),
          padding: '0px',
          background: 'linear-gradient(71.48deg, #1D1D1F 0%, #28272D 98.51%)',
        },
        overlay: {
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.4)',
        },
      }}
      {...reactModalProps}
    >
      <Flex w="100%" flexDir="column">
        <Flex
          w="100%"
          pt="12px"
          pr="12px"
          mb={isSM ? '21px' : '12px'}
          justifyContent={'flex-end'}
        >
          <Icon
            fontSize={isSM ? '16px' : '24px'}
            onClick={onCloseIcon}
            icon="eva:close-fill"
            color="#FFFFFF"
          />
        </Flex>

        <Flex flexDir={'column'} px="48px" gap="16px">
          <Text
            fontStyle="normal"
            fontWeight="700"
            fontSize={isSM ? '24px' : '2rem'}
            textAlign={'center'}
            lineHeight="130%"
            color="#FFFFFF"
          >
            {`Bem-vindo, ${user?.name.split(' ')[0]}!`}
          </Text>

          <Text
            fontStyle="normal"
            fontWeight="400"
            fontSize={isSM ? '12px' : '16px'}
            lineHeight={isSM ? '15px' : '20px'}
            color="#BDBDC7"
            letterSpacing="0.04em"
            textAlign={isSM ? 'center' : 'unset'}
          >
            Entre no nosso grupo do whatsapp, onde você pode consultar seu
            monitor e acompanhar as mensagens.
          </Text>
        </Flex>

        <Box p={isSM ? '32px 48px 48px 48px' : '24px 48px 48px 48px'}>
          <Link href={whatsLink} target="_blank">
            <DefaultButton
              label="Entrar no grupo"
              leftIcon={
                <Icon
                  color="#FFFF"
                  fontSize={isSM ? '15px' : '18px'}
                  icon="ic:outline-whatsapp"
                />
              }
              w="100%"
            />
          </Link>
        </Box>
      </Flex>
    </ReactModal>
  )
}
