import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface ListProgramedResponse {
  completedStudiesPercentage: number
  programmedStudiesPercentage: number
  pendingStudiesPercentage: number
}

async function listPerformanceStudies() {
  const { data } = await api.get<ListProgramedResponse>(
    '/app/recovery_plan/progress_bar',
  )

  return data
}

export function useListPerformanceStudies() {
  return useQuery(
    ['list-performance-studies'],
    () => listPerformanceStudies(),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
