import { Flex, Box } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import Layout from 'layouts/UnloggedOnboarding'
import SecondStep from './second-step/SecondStep'
import { LogOutButton } from 'components/Header/styles'
import { Power } from 'react-feather'
import { useAuth } from 'contexts/auth'
import Swal from 'sweetalert2'
import { FirstStep } from './first-step/FirstStep'
import { ThirdStep } from './third-step/ThridStep'
import HeaderOnboardingTrial from './components/HeaderOnboardingTrial'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormOnboardingTrialInput, FormOnboardingTrialOutput } from './type'
import { schema } from './second-step/schema'
import api from 'services/api'
import { AxiosError } from 'axios'

export function TrialOnboardTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [currentStep, setCurrentStep] = React.useState(0)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { logOut, updateUserData } = useAuth()
  const methods = useForm<FormOnboardingTrialInput>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      html:
        '<p style="text-align:center">Você realmente deseja fazer logout?</p>',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  const submitHandler = useCallback(
    async (data: FormOnboardingTrialOutput) => {
      await api
        .post('/app/onboardings', {
          ...data,
        })
        .then(() => {
          Swal.fire({
            title: 'Trilha definida com sucesso!',
            html:
              '<p style="text-align:center">Agora está tudo pronto. Te desejamos bons estudos.</p>',
            buttonsStyling: false,
            confirmButtonText: 'continuar',
            timer: 5000,
            timerProgressBar: true,
          })
        })
        .catch(error => {
          const message: AxiosError =
            error?.response?.data?.errors
              ?.map((error: AxiosError) => error.message)
              ?.join(', ') ||
            error?.response?.data ||
            'Não foi possível realizar o onboarding'

          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            html: `<p style="text-align:center">${
              message
                ? JSON.stringify(message)
                : 'Não foi possível realizar o onboarding'
            }</p>`,
          })
          console.trace(error)
        })
        .finally(() => {
          updateUserData()
        })
    },
    [updateUserData],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Flex
        w="100%"
        h="100%"
        mt="3rem"
        gap="3rem"
        flexDir={'column'}
        px={{ base: '16px', md: '65px' }}
      >
        <Flex w="full" flexDir={'column'} gap="1rem" justifyContent={'end'}>
          <LogOutButton onClick={confirmLogOut}>
            <Power size={20} strokeWidth={1} />
          </LogOutButton>

          <HeaderOnboardingTrial currentStep={currentStep} />
        </Flex>

        <Flex w="100%" h="100%" pb="16px">
          {currentStep === 0 && <FirstStep setCurrentStep={setCurrentStep} />}
          <FormProvider {...methods}>
            <Box
              as="form"
              w="full"
              onSubmit={methods.handleSubmit(submitHandler)}
            >
              {currentStep === 1 && (
                <SecondStep setCurrentStep={setCurrentStep} />
              )}

              {currentStep === 2 && (
                <ThirdStep setCurrentStep={setCurrentStep} />
              )}
            </Box>
          </FormProvider>
        </Flex>
      </Flex>
    </Layout>
  )
}
