import { useAuth } from 'contexts/auth'
import React from 'react'
import { PurchaseOnboardTemplate } from './Purchase/TemplatePurchase'
import { TrialOnboardTemplate } from './Trial/TemplateTrial'

export default function OnboardingTemplate() {
  const { user } = useAuth()

  if (user?.has_trial_available) {
    return <TrialOnboardTemplate />
  }

  return <PurchaseOnboardTemplate />
}
