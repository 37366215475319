import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

type Question = {
  id: number
  statement: string
  alternative_a: string
  alternative_b: string
  alternative_c: string
  alternative_d: string
  alternative_e: string
  correct_alternative: string
  comment: string
  comment_image: string
  image_highlight: string
  essential_concept: string
  image_essential_concept: string
  mock_exam_id: number
  created_at: string
  updated_at: string
}

type Params = {
  id: number | undefined | null
}

export async function getQuestionById(
  params: Params,
): Promise<Question | undefined> {
  if (!params.id) return

  const { data } = await api.get<Question>(
    `/app/mock-exam-questions/${params.id}`,
  )

  return data
}

export function useGetQuestionById(params: Params) {
  return useQuery(
    ['get-question-by-id', params],
    () => getQuestionById(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
