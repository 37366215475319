import { Flex } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { SecondStepDiv } from './styles'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface Step2Form {
  isLoading: boolean
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export default function Step2({ setStep, isLoading }: Step2Form) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    control,
    formState: { errors },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <SecondStepDiv>
      <p>
        Defina a <span>sua senha</span>
      </p>
      <Flex
        flexDir={'column'}
        maxW={'350px'}
        w="100%"
        margin="0 auto"
        justifyContent={'center'}
        gap={'16px'}
      >
        <FieldInputController
          placeholder="Senha"
          control={control}
          type="password"
          name="password"
          error={errors.password}
        />
        <FieldInputController
          placeholder="Confirme a senha"
          control={control}
          type="password"
          name="passwordConfirmation"
          error={errors.passwordConfirmation}
        />

        <Flex
          w="100%"
          gap={'1rem'}
          mt={'32px !important'}
          flexDir={{ base: 'column', sm: 'row' }}
          justifyContent={{ base: 'center', sm: 'space-between' }}
        >
          <DefaultButton
            label={'Voltar'}
            w="100%"
            variant="ghost"
            onClick={() => setStep(1)}
          />

          <DefaultButton
            w="100%"
            type="submit"
            label="Definir"
            isLoading={isLoading}
          />
        </Flex>
      </Flex>
    </SecondStepDiv>
  )
}
