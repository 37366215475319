import React, { forwardRef } from 'react'
import { Radio, RadioProps } from '@chakra-ui/react'

export interface RadioComponentProps extends RadioProps {}

const RadioComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioComponentProps
> = (props, ref) => {
  return (
    <Radio
      _focus={{ boxShadow: 'none' }}
      colorScheme={'brand'}
      bgColor="inherit"
      data-qa={props.name}
      {...props}
      ref={ref}
    />
  )
}

export const FieldRadio = forwardRef(RadioComponent)
