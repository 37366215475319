import React from 'react'
import {
  chakra,
  CircularProgress,
  CircularProgressLabel,
  GridItem,
  GridItemProps,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { MajorStatsProps } from '..'
import { hoursAndMinutesConversion } from 'helpers/hoursAndMinutesConversion'
import { getISOWeek } from 'date-fns'

type HoursStudyProps = { currentWeekSelected: number } & Pick<
  MajorStatsProps,
  | 'percentStudiedHoursReached'
  | 'percentRemainingHoursToReachTheGoal'
  | 'study_minutes'
  | 'totalMinutesToReachTheGoal'
> &
  GridItemProps

export default function HoursStudyMobile(props: HoursStudyProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    percentStudiedHoursReached,
    percentRemainingHoursToReachTheGoal,
    currentWeekSelected,
    study_minutes,
    totalMinutesToReachTheGoal,
    ...rest
  } = props

  const currentWeek = getISOWeek(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (currentWeekSelected === currentWeek) {
    return (
      <GridItem
        display={'flex'}
        maxH="135px"
        backgroundColor="#302F37"
        p="16px"
        {...rest}
      >
        <HStack spacing={'1rem'}>
          <CircularProgress
            size={'84px'}
            thickness="6px"
            capIsRound
            color={
              percentRemainingHoursToReachTheGoal <= 0 ? '#0DDF15' : '#e5c009'
            }
            trackColor="#616167"
            my={{ base: '0px', sm: '16px' }}
            value={percentStudiedHoursReached}
          >
            <CircularProgressLabel fontSize={'18px'}>
              <chakra.span>
                {percentStudiedHoursReached.toFixed(0)}%
              </chakra.span>

              <Text fontWeight={400} fontSize={'12px'}>
                Atingida
              </Text>
            </CircularProgressLabel>
          </CircularProgress>

          <VStack align={'flex-start'}>
            <Text fontWeight={'700'} fontSize={'16px'}>
              Horas de estudo
            </Text>

            {percentStudiedHoursReached === 100 &&
              percentRemainingHoursToReachTheGoal <= 0 && (
                <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
                  <chakra.span color="#0DDF15">Na risca! </chakra.span>
                  Você atingiu a meta de horas de estudo dessa semana
                </Text>
              )}

            {percentStudiedHoursReached === 0 && (
              <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
                Estude{' '}
                <chakra.span color="#E56000">{`${hoursAndMinutesConversion(
                  study_minutes,
                )} `}</chakra.span>
                para complementar a meta dessa semana.
              </Text>
            )}

            {totalMinutesToReachTheGoal > 0 &&
              totalMinutesToReachTheGoal < study_minutes && (
                <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
                  <chakra.span>Quase lá, estude </chakra.span>
                  <chakra.span color={'#E56000'}>
                    {`${hoursAndMinutesConversion(
                      totalMinutesToReachTheGoal,
                    )} `}
                  </chakra.span>
                  para atingir a meta.
                </Text>
              )}

            {percentStudiedHoursReached > 100 && (
              <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
                <chakra.span color={'#0ddf15'}>
                  Uouu, que progresso!{' '}
                </chakra.span>
                Você ultrapassou a meta de
                <chakra.span color={'#0ddf15'}>
                  {` ${hoursAndMinutesConversion(study_minutes)} `}
                </chakra.span>
                dessa semana.
              </Text>
            )}
          </VStack>
        </HStack>
      </GridItem>
    )
  }

  return (
    <GridItem
      display={'flex'}
      maxH="135px"
      backgroundColor="#302F37"
      p="16px"
      {...rest}
    >
      <HStack spacing={'1rem'}>
        <CircularProgress
          size={'84px'}
          thickness="6px"
          capIsRound
          color={
            percentRemainingHoursToReachTheGoal <= 0 ? '#0DDF15' : '#e5c009'
          }
          trackColor="#616167"
          my={{ base: '0px', sm: '16px' }}
          value={percentStudiedHoursReached}
        >
          <CircularProgressLabel fontSize={'18px'}>
            <chakra.span>{percentStudiedHoursReached.toFixed(0)}%</chakra.span>

            <Text fontWeight={400} fontSize={'12px'}>
              Atingida
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        <VStack align={'flex-start'}>
          <Text fontWeight={'700'} fontSize={'16px'}>
            Horas de estudo
          </Text>

          {percentStudiedHoursReached === 100 &&
            percentRemainingHoursToReachTheGoal <= 0 && (
              <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
                <chakra.span color="#0DDF15">Na risca! </chakra.span>
                Você atingiu a meta de horas de estudo dessa semana
              </Text>
            )}

          {percentStudiedHoursReached === 0 && (
            <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
              Estude{' '}
              <chakra.span color="#E56000">{`${hoursAndMinutesConversion(
                study_minutes,
              )} `}</chakra.span>
              para complementar a meta dessa semana.
            </Text>
          )}

          {totalMinutesToReachTheGoal > 0 &&
            totalMinutesToReachTheGoal < study_minutes && (
              <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                <chakra.span>Quaase! Restaram </chakra.span>
                <chakra.span color={'#E56000'}>
                  {`${hoursAndMinutesConversion(totalMinutesToReachTheGoal)} `}
                </chakra.span>
                para atingir a meta no período selecionado.
              </Text>
            )}

          {percentStudiedHoursReached > 100 && (
            <Text fontWeight={'600'} fontSize={'14px'} color="#BDBDC7">
              <chakra.span color={'#0ddf15'}>Uouu, que progresso! </chakra.span>
              Você superou a meta em
              <chakra.span color={'#0ddf15'}>
                {` ${+percentStudiedHoursReached.toFixed(1) - 100}%`}
              </chakra.span>
            </Text>
          )}
        </VStack>
      </HStack>
    </GridItem>
  )
}
