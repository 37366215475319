import {
  Flex,
  TableRowProps,
  Td,
  Text,
  Tooltip,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React from 'react'
import { Icon } from '@iconify/react'
import { capitalize, isEmpty } from 'lodash'
import { minutesToHoursAndMinutes } from 'helpers/minutesToHoursAndMinutes'

interface SubTheme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Theme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface TrTableTheoricalStudieProps extends TableRowProps {
  status: boolean
  area: string
  theme: Theme[]
  subTheme: SubTheme[]
  hours: number
  backgroundTable?: string
  onClickActionPlus: () => void
  onClickActionMinus: () => void
}

export function TrTableTheoricalStudie(props: TrTableTheoricalStudieProps) {
  const {
    status,
    area,
    hours,
    onClickActionPlus,
    onClickActionMinus,
    backgroundTable,
    subTheme,
    theme,
    ...rest
  } = props

  const isSm = useBreakpointValue({ base: true, sm: false })

  return (
    <>
      <Tr
        pb="1rem"
        backgroundColor={backgroundTable}
        verticalAlign={'baseline'}
        {...rest}
      >
        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Flex alignSelf={'center'}>
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              {status ? 'Adicionado' : 'Não adicionado'}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {area.length > 11 ? (
              <Tooltip label={capitalize(area)}>
                {letterCounter(capitalize(area), 11)}
              </Tooltip>
            ) : (
              capitalize(area)
            )}
          </Text>

          {(isEmpty(area) || !area) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            {theme &&
            theme.map(item => capitalize(item.name)).join(' | ').length > 11 ? (
              <Tooltip
                label={theme.map(item => capitalize(item.name)).join(' | ')}
              >
                {letterCounter(
                  theme.map(item => capitalize(item.name)).join(' | '),
                  11,
                )}
              </Tooltip>
            ) : (
              theme.map(item => capitalize(item.name))
            )}
          </Text>

          {(isEmpty(theme) || !theme) && (
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              -
            </Text>
          )}
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Flex>
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              textAlign={'center'}
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              {subTheme &&
              subTheme.map(item => capitalize(item.name)).join(' | ').length >
                11 ? (
                <Tooltip
                  label={subTheme
                    .map(item => capitalize(item.name))
                    .join(' | ')}
                >
                  {letterCounter(
                    subTheme.map(item => capitalize(item.name)).join(' | '),
                    11,
                  )}
                </Tooltip>
              ) : (
                subTheme.map(item => capitalize(item.name))
              )}
            </Text>

            {(isEmpty(subTheme) || !subTheme) && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
                lineHeight={'18px'}
                color={'#FFFFFF'}
              >
                -
              </Text>
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
          verticalAlign={'top'}
        >
          <Flex justifyContent="center">
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              textAlign={'center'}
              fontSize="14px"
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              {minutesToHoursAndMinutes(hours)}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          py="11px"
          px="14px"
          borderBottom="none"
          whiteSpace="nowrap"
          onClick={isSm ? onClickActionPlus : undefined}
        >
          <Flex justifyContent={'center'}>
            {status ? (
              <Icon
                fontFamily="Mulish"
                onClick={onClickActionMinus}
                icon={'mdi:minus-box-outline'}
                fontSize="18px"
                color={'#FFFF'}
                cursor="pointer"
              />
            ) : (
              <Icon
                fontFamily="Mulish"
                onClick={onClickActionPlus}
                icon={'eva:plus-square-outline'}
                fontSize="18px"
                color={'#FFFF'}
                cursor="pointer"
              />
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  )
}
