import { HStack, VStack, Text, Tooltip, IconButton } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { AxiosError } from 'axios'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import Pagination from 'components/Pagination'
import { useToastMessage } from 'components/Toast'
import { Test } from 'features/dashboard/type'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import { letterCounter } from 'helpers/charactersCount'
import React, { useCallback, useMemo, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import ModalEditTests from './Modal/EditTests'
import { useFetchTests } from './queries/use-fetch-test'
import { useDeleteTest } from './queries/use-mutation-delete-test'

export default function TestTable() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [modalEditTests, setModalEditTests] = useState(false)
  const [page, setPage] = useState(1)
  const [rowSelected, setRowSelected] = useState<Test | null>(null)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    data: dataTest,
    isFetched: isTestFetched,
    isFetching: isTestFetching,
    isLoading: isTestLoading,
  } = useFetchTests({
    page: page,
    limit: 6,
  })

  const deleteTest = useDeleteTest()

  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDeleteDailyRecord = useCallback(
    async (id: number | null | undefined) => {
      try {
        if (!id) return

        await deleteTest.mutateAsync({ id })

        showToast({
          title: 'Sucesso',
          description: 'Registro de prova removido com sucesso!',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        setModalConfirmDelete(false)
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possívevel efetuar a remoção.'

        showToast({
          title: 'Erro ao remover estudo',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [deleteTest, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'date',
        fn(value) {
          return (
            <HStack minW={'80px'}>
              <Tooltip label={value} hasArrow>
                <Text textAlign={'center'} noOfLines={1}>
                  {value}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: 'Instituição',
        accessor: 'institution_name',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value ? letterCounter(value, 12) : <p>&ndash;</p>}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Tempo de estudo',
        accessor: 'studied_minutes',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Questões realizadas',
        accessor: 'number_of_questions',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Questões corretas',
        accessor: 'number_of_correct_answers',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: '% Acerto',
        accessor: 'score',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Percepção de esforço',
        accessor: 'effort_perception',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Ações',
        fn(_, data) {
          return (
            <HStack justifyContent={'center'}>
              <IconButton
                aria-label={'edit-open-modal'}
                data-qa="test-edit-open-modal"
                w="24px"
                h="24px"
                minW={'24px'}
                borderRadius={'4px'}
                backgroundColor={'#393940'}
                _active={{ backgroundColor: '#313136' }}
                _hover={{ backgroundColor: '#313136' }}
                onClick={() => {
                  setModalEditTests(true)
                  setRowSelected(data)
                }}
                icon={
                  <Icon
                    icon="eva:edit-outline"
                    fontSize={'14px'}
                    color="#FFFFFF"
                  />
                }
              />

              <IconButton
                aria-label={'delete-open-modal'}
                data-qa="test-delete-open-modal"
                w="24px"
                h="24px"
                minW={'24px'}
                borderRadius={'4px'}
                backgroundColor={'#393940'}
                _active={{ backgroundColor: '#313136' }}
                _hover={{ backgroundColor: '#313136' }}
                onClick={() => {
                  setRowSelected(data)
                  setModalConfirmDelete(true)
                }}
                icon={<BiTrash size={'14px'} color="#FFFFFF" />}
              />
            </HStack>
          )
        },
      },
    ] as TableHeader<Test>[]
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack align="flex-start" maxW={'100%'}>
      <List
        borderRadius={'0px 4px 4px 4px'}
        w="100%"
        background="#302F37"
        p="0px 16px 16px 16px"
        my="0px"
      >
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={isTestLoading}
          isFetching={isTestFetching}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={dataTest?.data}
          isLoading={isTestLoading}
          tableCellProps={{
            border: 'none !important',
            p: '4px',
            _notFirst: {
              paddingLeft: '12px',
            },
            borderRight: '1px solid #60606C !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '14px',
            lineHeight: '15px',
            textTransform: 'none !important',
            p: '16px 8px',
            textAlign: 'center',
            _first: {
              textAlign: 'unset',
            },
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={dataTest?.meta.total ?? 0}
          registersPerPage={6}
          fontSize="12px"
          mt="16px !important"
          px="8px"
        />

        <ModalDefaultTwoButton
          isOpen={modalConfirmDelete}
          onClose={() => setModalConfirmDelete(false)}
          title="Tem certeza que deseja apagar?"
          subTitle="Se continuar, o registro será apagado permanentemente."
          confirmButtonIsLoading={deleteTest.isLoading}
          onConfirmClick={() => handleDeleteDailyRecord(rowSelected?.id)}
        />

        <ModalEditTests
          isOpen={modalEditTests}
          onClose={() => setModalEditTests(false)}
          dataTest={rowSelected ?? null}
        />
      </List>
    </VStack>
  )
}
