import { format } from 'date-fns'
import React, { useMemo } from 'react'

// Hooks
import { usePagination } from 'hooks/usePagination'

// UI
import { List, ListHeader, ListPagination, ListTable } from 'features/ui'
import { TableHeader } from 'features/ui/list/list-table'

// Agendas
import {
  useUpdateDeleted,
  GetDeletedDailyRecord,
  useGetDeletedDailyRecords,
} from 'features/daily-records'

// Styles
import { Text, Tooltip } from '@chakra-ui/react'
import { PopoverDeletedRecovery } from 'features/agendas/popover-deleted-recovery'

export function ListDeletedDailyRecords() {
  const { page, onChangePage } = usePagination()
  const updateDeleted = useUpdateDeleted()
  const { data, isLoading, isFetching } = useGetDeletedDailyRecords({
    page,
  })

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'date',
        fn(value) {
          return format(new Date(value), 'dd/MM/yyyy')
        },
      },
      {
        label: 'Área',
        accessor: 'field.name',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={2}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Tema',
        accessor: 'theme.name',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={2}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Subtema',
        accessor: 'subject.name',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={2}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Minutos de estudo',
        accessor: 'studiedMinutes',
      },
      {
        label: 'Nro° de questões',
        accessor: 'numberOfQuestions',
      },
      {
        label: 'Nro° de acertos',
        accessor: 'numberOfCorrectAnswers',
      },
      {
        label: '',
        fn(_, data) {
          return (
            <PopoverDeletedRecovery
              id={data.id}
              updateDeleted={updateDeleted}
            />
          )
        },
      },
    ] as TableHeader<GetDeletedDailyRecord>[]
  }, [updateDeleted])

  return (
    <List>
      <ListHeader
        title="Registros diários"
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <ListTable headers={headers} data={data?.items} isLoading={isLoading} />

      <ListPagination
        currentPage={page}
        onChangePage={onChangePage}
        totalCountOfRegisters={data?.meta.total}
      />
    </List>
  )
}
