import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import FieldMaskBase, { FieldMaskProps } from './index'

import {
  FormLabel,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabelProps,
} from '@chakra-ui/react'

type FieldMaskController = FieldMaskProps & {
  name: string
  label?: string
  control: Control<any>
  error?: FieldError
  helperText?: string | React.ReactNode
  labelProps?: FormLabelProps
  shouldLabelRed?: boolean
}

const FieldInputMaskController: React.FC<FieldMaskController> = ({
  name,
  label,
  error,
  control,
  helperText,
  shouldLabelRed,
  defaultValue,
  labelProps,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          textAlign="left"
          letterSpacing="0.04em"
          color={shouldLabelRed && error ? '#FF6363' : '#FFFFFF'}
          htmlFor={name}
          {...labelProps}
        >
          {shouldLabelRed && error ? `${label}*` : label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <FieldMaskBase {...rest} {...field} />}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export default FieldInputMaskController
