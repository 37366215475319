import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ModalProps,
  VStack,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useMutateTrialModal } from 'features/simulation/mutations/use-mutate-trial-modal'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

export type ModalTestTrialProps = {} & Omit<ModalProps, 'children'>

export default function ModalTestTrial(props: ModalTestTrialProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constant.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const patchTrialModal = useMutateTrialModal()

  /*
  |-----------------------------------------------------------------------------
  | Callback.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const closeModal = useCallback(async () => {
    try {
      await patchTrialModal.mutateAsync()
    } catch (error) {
      console.trace(error)
    } finally {
      props.onClose()
    }
  }, [patchTrialModal, props])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Modal {...rest} isCentered onClose={closeModal}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.2)'} />
      <ModalContent
        maxW={'462px'}
        w="full"
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalHeader p="0px">
          <ModalCloseButton
            right={'8px'}
            top={'8px'}
            color={'#FFFF'}
            position={'absolute'}
            _focus={{ outline: 'none' }}
          />
        </ModalHeader>

        <ModalBody maxW={'462px'} w="full" p={'24px'} borderRadius={'8px'}>
          <VStack alignItems={'start'} spacing={'24px'}>
            <Text
              fontWeight={700}
              fontSize={'1rem'}
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              Olá
            </Text>

            <Text
              fontWeight={700}
              fontSize={'1rem'}
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              Para realizar o simulado, siga os passos abaixo:
            </Text>

            <Text
              fontWeight={700}
              fontSize={'1rem'}
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              1- Na aba lateral, cliem em{' '}
              <Link
                to={'/simulation'}
                style={{
                  color: '#E56000',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                Simulados
              </Link>
            </Text>

            <Text
              fontWeight={700}
              fontSize={'1rem'}
              lineHeight={'18px'}
              color={'#FFFFFF'}
            >
              2- Realize o simulado e pronto, nossa equipe entrará em contato
              para informar o prêmio de acordo com a sua colocação.{' '}
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
