import { Flex } from '@chakra-ui/react'
import React, { CSSProperties, useMemo, useRef } from 'react'

import ReactSelect, {
  OptionTypeBase,
  Props as ReactSelectProps,
} from 'react-select'

export type FieldSelectDashboardProps = ReactSelectProps<OptionTypeBase>

type Sizes = 'lg' | 'md' | 'sm'
type Variants = 'primary' | 'secondary'

export type FieldSelectDashboardCustomProps = {
  hideIcon?: boolean
  size?: Sizes
  backgroundControlColor?: string
  variant?: Variants
} & FieldSelectDashboardProps

const MenuList = (props: any) => {
  const menuListRef = useRef<HTMLDivElement>(null)

  return (
    <Flex
      backgroundColor={'#302F37'}
      borderRadius={'0px 0px 8px 8px'}
      flexDir={'column'}
      w="full"
      paddingInlineEnd={'8px'}
    >
      <Flex
        flexDir={'column'}
        sx={{
          maxHeight: '264px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 5px #60606c',
            borderRadius: '17px',
            height: '8px',
            borderLeft: '2px solid transparent',
            borderRight: '2px solid transparent',
            marginTop: '10px',
            marginBottom: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#E56000',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'rgba(0,0,0,0)',
          },
        }}
      >
        <div ref={menuListRef}>{props.children}</div>
      </Flex>
    </Flex>
  )
}

export const NewFieldSelectComponent: React.ForwardRefRenderFunction<
  any,
  FieldSelectDashboardCustomProps
> = (
  { size = 'md', variant = 'primary', backgroundControlColor, ...props },
  ref,
) => {
  const sizesBySize = useMemo((): Record<
    Sizes,
    FieldSelectDashboardCustomProps
  > => {
    return {
      sm: {
        height: props.isMulti ? 'auto' : '32px',
      },
      md: {
        height: props.isMulti ? 'auto' : '42px',
      },
      lg: {
        height: props.isMulti ? 'auto' : '48px',
      },
    }
  }, [props.isMulti])

  const stylesByVariant = useMemo((): Record<Variants, CSSProperties> => {
    return {
      primary: {
        backgroundColor: '#393940',
      },
      secondary: {
        backgroundColor: '#302F37',
      },
    }
  }, [])

  return (
    <ReactSelect
      components={{ MenuList }}
      captureMenuScroll={false}
      id={props.name}
      ref={ref}
      styles={{
        valueContainer: (base, props) => ({
          ...base,
          paddingTop: props.isMulti ? '7px' : '8px',
          paddingBottom: props.isMulti ? '7px' : '8px',
          ...sizesBySize[size],
        }),
        container: base => ({
          ...base,
          width: '100%',
          ...sizesBySize[size],
          borderRadius: '8px',
        }),
        control: base => ({
          ...base,
          width: '100%',
          fontFamily: 'Mulish',
          display: 'flex',
          height: '100%',
          ...stylesByVariant?.[variant],
          color: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          outline: 0,
          border: 0,
          paddingLeft: 12,
          paddingRight: 8,
          boxShadow: 'none',
        }),
        input: base => ({
          ...base,
          color: '#FFF',
        }),
        indicatorSeparator: base => ({
          ...base,
          backgroundColor: 'transparent',
        }),
        dropdownIndicator: base => ({
          ...base,
          color: '#FF6B00',
          '&:hover': {
            color: '#FF6B00',
          },
          display: props.hideIcon ? 'none' : 'block',
        }),
        placeholder: base => ({
          ...base,
          fontFamily: 'Mulish',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
          color: '#BDBDC7',
        }),
        menuList: base => ({
          ...base,
          fontFamily: 'Mulish',
          zIndex: 2000,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        menu: base => ({
          ...base,
          fontFamily: 'Mulish',
          borderRadius: '0px 0px 8px 8px',
          color: '#FF6B00',
          backgroundColor: '#302F37',
          zIndex: 3000,
          marginTop: -1,
          height: '100%',
          maxHeight: 264,
        }),
        singleValue: base => ({
          ...base,
          color: 'rgba(255, 255, 255, 0.9)',
          fontFamily: 'Mulish',
          fontSize: '14px',
        }),
        option: (base, { isSelected }) => ({
          ...base,
          background: '#302F37',
          color: isSelected ? '#FFF' : '#BDBDC7',
          '&:hover': {
            backgroundColor: '#2A2A30',
            borderLeft: '2px solid #E56000',
          },
        }),
        multiValue: base => ({
          ...base,
          backgroundColor: '#302F37',
        }),
        multiValueLabel: (base, props) => ({
          ...base,
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '15px',
          color: '#FFFFFF',
        }),
        multiValueRemove: (base, props) => ({
          ...base,
          backgroundColor: '#302F37',
          '&:hover': {
            backgroundColor: '#222127',
          },
          color: '#E56000',
        }),
      }}
      {...props}
    />
  )
}

export const NewFieldSelect = React.forwardRef(NewFieldSelectComponent)
