import React from 'react'
import Template from 'features/pre-onboarding/Template'

export const PreOnboarding: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return <Template />
}
