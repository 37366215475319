import React, { useMemo } from 'react'
import Layout from 'layouts/Logged'
import { Container } from './styles'
import Header from 'components/Header'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'
import { Inactivated } from 'features/project-x/Inactivated'
import { Activated } from 'features/project-x/Activated'
import { useAuth } from 'contexts/auth'

export const ProjectX: React.FC = () => {
  const isMd = useBreakpointValue({ base: false, lg: true })

  const { isFocusLearningActivated } = useAuth()

  const isFocusLearningActivatedMemo = useMemo(() => {
    if (isFocusLearningActivated) {
      return <Activated />
    }

    return <Inactivated />
  }, [isFocusLearningActivated])

  return (
    <Layout>
      <Container>
        <Header
          title="Projeto X"
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://youtu.be/D72uz_HlHk4"
        />
        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://youtu.be/D72uz_HlHk4"
              mt="8px"
            />
          </Flex>
        )}

        {isFocusLearningActivatedMemo}
      </Container>
    </Layout>
  )
}
