import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'contexts/auth'
import api from 'services/api'

async function mutateTrialModal() {
  await api.patch('/app/mock-exams/update-modal-views')
}

export function useMutateTrialModal() {
  const { updateUserData } = useAuth()

  return useMutation(mutateTrialModal, {
    onSuccess: () => {
      updateUserData()
    },
  })
}
