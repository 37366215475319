import React from 'react'
import Layout from 'layouts/Logged'
import { Container } from './styles'
import Header from 'components/Header'
import TimeLineMRTemplate from 'features/timeline'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'

export default function TimeLineMR() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMd = useBreakpointValue({ base: false, lg: true })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Trilha de Simulados"
          subTitle="Estudos programados para você!"
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://youtu.be/3jEobhlOSwE"
        />

        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://youtu.be/3jEobhlOSwE"
              mt="8px"
            />
          </Flex>
        )}

        <TimeLineMRTemplate />
      </Container>
    </Layout>
  )
}
