import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
} from '@chakra-ui/react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { FieldTelephone } from './FieldInputTelephone'
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select'
import { FieldInputProps } from '../FieldInput'

type FieldInputRegisterProps = {
  name: string
  label?: string
  error?: FieldError
  helperText?: string | React.ReactNode
  inputProps?: InputProps
  control: Control<any>
  defaultValue?: any
  valueTel: ValueType<OptionTypeBase, false>
  onChangeTel:
    | ((
        value: ValueType<OptionTypeBase, false>,
        action: ActionMeta<OptionTypeBase>,
      ) => void)
    | undefined
} & FieldInputProps

export const FieldInputTelephoneController: React.FC<FieldInputRegisterProps> = ({
  name,
  label,
  error,
  helperText,
  defaultValue,
  control,
  onChangeTel,
  valueTel,
  ...inputProps
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          textAlign="left"
          letterSpacing="0.04em"
          color="#FFFFFF"
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FieldTelephone
            onChangeTel={onChangeTel}
            valueTel={valueTel}
            border="0px"
            color="#FFFF"
            backgroundColor={'#393940'}
            borderRadius="8px"
            autoComplete="off"
            id={name}
            {...inputProps}
            {...field}
          />
        )}
      />

      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
