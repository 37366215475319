export type Tracks =
  | 'PNA PORTUGAL'
  | 'Revalida'
  | 'R+ CGE'
  | 'R+ CM'
  | 'R+ GO-MASTOLOGIA'
  | 'R+ ENDOSCOPIA'
  | 'R+ PED'
  | 'R1'

type Video = {
  title: string
  url: string
  time: string
}

type VideoByYear = {
  videos: Video[]
  year: number[]
}

const getPossiblesYears = () => {
  const currentYear = new Date().getFullYear()

  return Array.from({ length: 3 }, (_, i) => currentYear + i)
}

export const fifthStepData = (track: Tracks) => {
  const videosTracks: Record<Tracks, VideoByYear[]> = {
    'R+ CGE': [
      {
        year: getPossiblesYears(),
        videos: [
          {
            title: 'Aula 1: Seja Bem Vindo ao Mentoria R+',
            url:
              'https://www.youtube.com/embed/EfqIkZfcy_Y?si=T1CYffHiR5R1KBYL',
            time: '04:45',
          },
          {
            url:
              'https://www.youtube.com/embed/RR9D3xMn2D0?si=554iT_jz4fMrrgm9',
            title:
              'Aula 2: As fases do ano e as melhores estratégias de estudo para cada uma delas',
            time: '06:13',
          },
          {
            url:
              'https://www.youtube.com/embed/mJBZ6m3nHRE?si=84ywfdBHnV3wdZjk',
            title: 'Aula 3: Como ter um estudo eficiente',
            time: '04:44',
          },
          {
            url:
              'https://www.youtube.com/embed/fYPw08rAkM0?si=Pso55u2lxw3EAhGC',
            title: 'Aula 4: Como criar mais horas no seu dia para estudar',
            time: '02:46',
          },
          {
            url:
              'https://www.youtube.com/embed/819RQD71mv0?si=q3c-sEevleaPTNww',
            title: 'Aula 5: O Poder do Microlearning',
            time: '02:49',
          },
          {
            url:
              'https://www.youtube.com/embed/UvrUnoJIFXA?si=h8ICT79v12SGT1HV',
            title: 'Aula 6: Sobre Estudar em Dias Difíceis',
            time: '01:51',
          },
          {
            url:
              'https://www.youtube.com/embed/kagbVDOb8to?si=rtMpnqj3-GQ_yyEq',
            title:
              'Aula 7: A importância de uma rotina sustentável e dicas de alta performance',
            time: '03:38',
          },
          {
            url:
              'https://www.youtube.com/embed/fQiZzC_8ZMY?si=iavtlvnRz2ar8pFG',
            title:
              'Aula 8: Aqui aqui você está no melhor local que poderia estar',
            time: '01:40',
          },
        ],
      },
    ],
    'R+ CM': [
      {
        year: getPossiblesYears(),
        videos: [
          {
            title: 'Aula 1: Seja Bem Vindo ao Mentoria R+',
            url:
              'https://www.youtube.com/embed/EfqIkZfcy_Y?si=T1CYffHiR5R1KBYL',
            time: '04:45',
          },
          {
            url:
              'https://www.youtube.com/embed/RR9D3xMn2D0?si=554iT_jz4fMrrgm9',
            title:
              'Aula 2: As fases do ano e as melhores estratégias de estudo para cada uma delas',
            time: '06:13',
          },
          {
            url:
              'https://www.youtube.com/embed/mJBZ6m3nHRE?si=84ywfdBHnV3wdZjk',
            title: 'Aula 3: Como ter um estudo eficiente',
            time: '04:44',
          },
          {
            url:
              'https://www.youtube.com/embed/fYPw08rAkM0?si=Pso55u2lxw3EAhGC',
            title: 'Aula 4: Como criar mais horas no seu dia para estudar',
            time: '02:46',
          },
          {
            url:
              'https://www.youtube.com/embed/819RQD71mv0?si=q3c-sEevleaPTNww',
            title: 'Aula 5: O Poder do Microlearning',
            time: '02:49',
          },
          {
            url:
              'https://www.youtube.com/embed/UvrUnoJIFXA?si=h8ICT79v12SGT1HV',
            title: 'Aula 6: Sobre Estudar em Dias Difíceis',
            time: '01:51',
          },
          {
            url:
              'https://www.youtube.com/embed/kagbVDOb8to?si=rtMpnqj3-GQ_yyEq',
            title:
              'Aula 7: A importância de uma rotina sustentável e dicas de alta performance',
            time: '03:38',
          },
          {
            url:
              'https://www.youtube.com/embed/fQiZzC_8ZMY?si=iavtlvnRz2ar8pFG',
            title:
              'Aula 8: Aqui aqui você está no melhor local que poderia estar',
            time: '01:40',
          },
        ],
      },
    ],
    'R+ GO-MASTOLOGIA': [
      {
        year: getPossiblesYears(),
        videos: [
          {
            title: 'Aula 1: Seja Bem Vindo ao Mentoria R+',
            url:
              'https://www.youtube.com/embed/EfqIkZfcy_Y?si=T1CYffHiR5R1KBYL',
            time: '04:45',
          },
          {
            url:
              'https://www.youtube.com/embed/RR9D3xMn2D0?si=554iT_jz4fMrrgm9',
            title:
              'Aula 2: As fases do ano e as melhores estratégias de estudo para cada uma delas',
            time: '06:13',
          },
          {
            url:
              'https://www.youtube.com/embed/mJBZ6m3nHRE?si=84ywfdBHnV3wdZjk',
            title: 'Aula 3: Como ter um estudo eficiente',
            time: '04:44',
          },
          {
            url:
              'https://www.youtube.com/embed/fYPw08rAkM0?si=Pso55u2lxw3EAhGC',
            title: 'Aula 4: Como criar mais horas no seu dia para estudar',
            time: '02:46',
          },
          {
            url:
              'https://www.youtube.com/embed/819RQD71mv0?si=q3c-sEevleaPTNww',
            title: 'Aula 5: O Poder do Microlearning',
            time: '02:49',
          },
          {
            url:
              'https://www.youtube.com/embed/UvrUnoJIFXA?si=h8ICT79v12SGT1HV',
            title: 'Aula 6: Sobre Estudar em Dias Difíceis',
            time: '01:51',
          },
          {
            url:
              'https://www.youtube.com/embed/kagbVDOb8to?si=rtMpnqj3-GQ_yyEq',
            title:
              'Aula 7: A importância de uma rotina sustentável e dicas de alta performance',
            time: '03:38',
          },
          {
            url:
              'https://www.youtube.com/embed/fQiZzC_8ZMY?si=iavtlvnRz2ar8pFG',
            title:
              'Aula 8: Aqui aqui você está no melhor local que poderia estar',
            time: '01:40',
          },
        ],
      },
    ],
    'R+ PED': [
      {
        year: getPossiblesYears(),
        videos: [
          {
            title: 'Aula 1: Seja Bem Vindo ao Mentoria R+',
            url:
              'https://www.youtube.com/embed/EfqIkZfcy_Y?si=T1CYffHiR5R1KBYL',
            time: '04:45',
          },
          {
            url:
              'https://www.youtube.com/embed/RR9D3xMn2D0?si=554iT_jz4fMrrgm9',
            title:
              'Aula 2: As fases do ano e as melhores estratégias de estudo para cada uma delas',
            time: '06:13',
          },
          {
            url:
              'https://www.youtube.com/embed/mJBZ6m3nHRE?si=84ywfdBHnV3wdZjk',
            title: 'Aula 3: Como ter um estudo eficiente',
            time: '04:44',
          },
          {
            url:
              'https://www.youtube.com/embed/fYPw08rAkM0?si=Pso55u2lxw3EAhGC',
            title: 'Aula 4: Como criar mais horas no seu dia para estudar',
            time: '02:46',
          },
          {
            url:
              'https://www.youtube.com/embed/819RQD71mv0?si=q3c-sEevleaPTNww',
            title: 'Aula 5: O Poder do Microlearning',
            time: '02:49',
          },
          {
            url:
              'https://www.youtube.com/embed/UvrUnoJIFXA?si=h8ICT79v12SGT1HV',
            title: 'Aula 6: Sobre Estudar em Dias Difíceis',
            time: '01:51',
          },
          {
            url:
              'https://www.youtube.com/embed/kagbVDOb8to?si=rtMpnqj3-GQ_yyEq',
            title:
              'Aula 7: A importância de uma rotina sustentável e dicas de alta performance',
            time: '03:38',
          },
          {
            url:
              'https://www.youtube.com/embed/fQiZzC_8ZMY?si=iavtlvnRz2ar8pFG',
            title:
              'Aula 8: Aqui aqui você está no melhor local que poderia estar',
            time: '01:40',
          },
        ],
      },
    ],
    'PNA PORTUGAL': [{ year: getPossiblesYears(), videos: [] }],
    Revalida: [{ year: getPossiblesYears(), videos: [] }],
    R1: [
      {
        year: [getPossiblesYears()[0]],
        videos: [
          {
            title: 'Jornada 6 ano A trilha do R1 em 2024',
            url:
              'https://www.youtube.com/embed/e_dxjRPgwrI?si=KyRRmWnKsuOOPU4F',
            time: '15:44',
          },
          {
            title: 'Método ERAS a metodologia que vai te aprovar na residência',
            url:
              'https://www.youtube.com/embed/Owdo0xTOyOw?si=gn84N7tGGR3d4Bob',
            time: '13:08',
          },
          {
            title: 'Esforço inteligente',
            url:
              'https://www.youtube.com/embed/DBw5U11EZwY?si=vu7aGTsaXMWDOtnE',
            time: '05:35',
          },
          {
            title: 'Pomodoro modificado para você',
            url:
              'https://www.youtube.com/embed/VhWo65LAzqc?si=YP-Z1CrLNTa9KgOy',
            time: '02:39',
          },
          {
            title: 'Sobre pausas',
            url:
              'https://www.youtube.com/embed/ztHoSASq8lc?si=KJ12FLUEWiSxFzws',
            time: '02:41',
          },
          {
            title: 'Dinamize a sua rotina',
            url:
              'https://www.youtube.com/embed/QzsOBcrrk1E?si=0AdSLfiePrK0ocP0',
            time: '01:30',
          },
          {
            title: 'As tais das oscilações',
            url:
              'https://www.youtube.com/embed/jMjVPvBNSfs?si=sLaaesV8PN8oQuCs',
            time: '01:49',
          },
          {
            title: 'A importância de trocar o ambiente',
            url:
              'https://www.youtube.com/embed/ie5nPaBomE4?si=QBvVgNFhaDksnyFG',
            time: '02:26',
          },
          {
            title: 'Ciclo de Estudos',
            url:
              'https://www.youtube.com/embed/rzyFJEUFSxU?si=qGLOJhWRUxfFO1Wz',
            time: '04:35',
          },
          {
            title: 'Como corrigir questões',
            url:
              'https://www.youtube.com/embed/xokRVC1lN_8?si=H36POPYq-nTNZFvy',
            time: '05:15',
          },
          {
            title: 'Resumos e revisões entenda de uma vez por todas',
            url:
              'https://www.youtube.com/embed/ndnyqakLfP0?si=BlZpmBktvyQs-pVl',
            time: '05:13',
          },
          {
            title: 'Mindset para período de provas',
            url:
              'https://www.youtube.com/embed/d6ZGBBZG2eY?si=fUW4rT3JilpWRF9D',
            time: '06:32',
          },
        ],
      },
      {
        year: [getPossiblesYears()[1]],
        videos: [
          {
            title: 'Por que começar no quinto ano',
            url:
              'https://www.youtube.com/embed/j5OwM7nC-SU?si=gxS9LLXeT7tCYt6Q',
            time: '08:26',
          },
          {
            title: 'A trilha do quinto ano',
            url:
              'https://www.youtube.com/embed/q6RZ9upKtew?si=FWU_wpdmqz7RdbY9',
            time: '13:39',
          },
          {
            title: 'Método ERAS a metodologia que vai te aprovar na residência',
            url:
              'https://www.youtube.com/embed/Owdo0xTOyOw?si=gn84N7tGGR3d4Bob',
            time: '13:08',
          },
          {
            title: 'Esforço inteligente',
            url:
              'https://www.youtube.com/embed/DBw5U11EZwY?si=vu7aGTsaXMWDOtnE',
            time: '05:35',
          },
          {
            title: 'Pomodoro modificado para você',
            url:
              'https://www.youtube.com/embed/VhWo65LAzqc?si=YP-Z1CrLNTa9KgOy',
            time: '02:39',
          },
          {
            title: 'Sobre pausas',
            url:
              'https://www.youtube.com/embed/ztHoSASq8lc?si=KJ12FLUEWiSxFzws',
            time: '02:41',
          },
          {
            title: 'Dinamize a sua rotina',
            url:
              'https://www.youtube.com/embed/QzsOBcrrk1E?si=0AdSLfiePrK0ocP0',
            time: '01:30',
          },
          {
            title: 'As tais das oscilações',
            url:
              'https://www.youtube.com/embed/jMjVPvBNSfs?si=sLaaesV8PN8oQuCs',
            time: '01:49',
          },
          {
            title: 'A importância de trocar o ambiente',
            url:
              'https://www.youtube.com/embed/ie5nPaBomE4?si=QBvVgNFhaDksnyFG',
            time: '02:26',
          },
          {
            title: 'Ciclo de Estudos',
            url:
              'https://www.youtube.com/embed/rzyFJEUFSxU?si=qGLOJhWRUxfFO1Wz',
            time: '04:35',
          },
          {
            title: 'Como corrigir questões',
            url:
              'https://www.youtube.com/embed/xokRVC1lN_8?si=H36POPYq-nTNZFvy',
            time: '05:15',
          },
          {
            title: 'Resumos e revisões entenda de uma vez por todas',
            url:
              'https://www.youtube.com/embed/ndnyqakLfP0?si=BlZpmBktvyQs-pVl',
            time: '05:13',
          },
          {
            title: 'Mindset para período de provas',
            url:
              'https://www.youtube.com/embed/d6ZGBBZG2eY?si=fUW4rT3JilpWRF9D',
            time: '06:32',
          },
        ],
      },
      {
        year: [getPossiblesYears()[2]],
        videos: [
          {
            title: 'Por que começar no quinto ano',
            url:
              'https://www.youtube.com/embed/j5OwM7nC-SU?si=gxS9LLXeT7tCYt6Q',
            time: '08:26',
          },
          {
            title: 'A trilha do quinto ano',
            url:
              'https://www.youtube.com/embed/q6RZ9upKtew?si=FWU_wpdmqz7RdbY9',
            time: '13:39',
          },
          {
            title: 'Método ERAS a metodologia que vai te aprovar na residência',
            url:
              'https://www.youtube.com/embed/Owdo0xTOyOw?si=gn84N7tGGR3d4Bob',
            time: '13:08',
          },
          {
            title: 'Esforço inteligente',
            url:
              'https://www.youtube.com/embed/DBw5U11EZwY?si=vu7aGTsaXMWDOtnE',
            time: '05:35',
          },
          {
            title: 'Pomodoro modificado para você',
            url:
              'https://www.youtube.com/embed/VhWo65LAzqc?si=YP-Z1CrLNTa9KgOy',
            time: '02:39',
          },
          {
            title: 'Sobre pausas',
            url:
              'https://www.youtube.com/embed/ztHoSASq8lc?si=KJ12FLUEWiSxFzws',
            time: '02:41',
          },
          {
            title: 'Dinamize a sua rotina',
            url:
              'https://www.youtube.com/embed/QzsOBcrrk1E?si=0AdSLfiePrK0ocP0',
            time: '01:30',
          },
          {
            title: 'As tais das oscilações',
            url:
              'https://www.youtube.com/embed/jMjVPvBNSfs?si=sLaaesV8PN8oQuCs',
            time: '01:49',
          },
          {
            title: 'A importância de trocar o ambiente',
            url:
              'https://www.youtube.com/embed/ie5nPaBomE4?si=QBvVgNFhaDksnyFG',
            time: '02:26',
          },
          {
            title: 'Ciclo de Estudos',
            url:
              'https://www.youtube.com/embed/rzyFJEUFSxU?si=qGLOJhWRUxfFO1Wz',
            time: '04:35',
          },
          {
            title: 'Como corrigir questões',
            url:
              'https://www.youtube.com/embed/xokRVC1lN_8?si=H36POPYq-nTNZFvy',
            time: '05:15',
          },
          {
            title: 'Resumos e revisões entenda de uma vez por todas',
            url:
              'https://www.youtube.com/embed/ndnyqakLfP0?si=BlZpmBktvyQs-pVl',
            time: '05:13',
          },
          {
            title: 'Mindset para período de provas',
            url:
              'https://www.youtube.com/embed/d6ZGBBZG2eY?si=fUW4rT3JilpWRF9D',
            time: '06:32',
          },
        ],
      },
    ],
    'R+ ENDOSCOPIA': [
      {
        year: getPossiblesYears(),
        videos: [
          {
            title: 'Aula 1: Seja Bem Vindo ao Mentoria R+',
            url:
              'https://www.youtube.com/embed/EfqIkZfcy_Y?si=T1CYffHiR5R1KBYL',
            time: '04:45',
          },
          {
            url:
              'https://www.youtube.com/embed/RR9D3xMn2D0?si=554iT_jz4fMrrgm9',
            title:
              'Aula 2: As fases do ano e as melhores estratégias de estudo para cada uma delas',
            time: '06:13',
          },
          {
            url:
              'https://www.youtube.com/embed/mJBZ6m3nHRE?si=84ywfdBHnV3wdZjk',
            title: 'Aula 3: Como ter um estudo eficiente',
            time: '04:44',
          },
          {
            url:
              'https://www.youtube.com/embed/fYPw08rAkM0?si=Pso55u2lxw3EAhGC',
            title: 'Aula 4: Como criar mais horas no seu dia para estudar',
            time: '02:46',
          },
          {
            url:
              'https://www.youtube.com/embed/819RQD71mv0?si=q3c-sEevleaPTNww',
            title: 'Aula 5: O Poder do Microlearning',
            time: '02:49',
          },
          {
            url:
              'https://www.youtube.com/embed/UvrUnoJIFXA?si=h8ICT79v12SGT1HV',
            title: 'Aula 6: Sobre Estudar em Dias Difíceis',
            time: '01:51',
          },
          {
            url:
              'https://www.youtube.com/embed/kagbVDOb8to?si=rtMpnqj3-GQ_yyEq',
            title:
              'Aula 7: A importância de uma rotina sustentável e dicas de alta performance',
            time: '03:38',
          },
          {
            url:
              'https://www.youtube.com/embed/fQiZzC_8ZMY?si=iavtlvnRz2ar8pFG',
            title:
              'Aula 8: Aqui aqui você está no melhor local que poderia estar',
            time: '01:40',
          },
        ],
      },
    ],
  }

  return videosTracks[track]
}
