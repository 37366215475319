import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spinner,
  VStack,
} from '@chakra-ui/react'
import Pagination from 'components/Pagination'
import { useGetPendingThemes } from 'features/timeline/queries/use-get-pending-themes'
import React from 'react'
import { CardTimeLineMR } from '../CardDrawer'

interface ModalFeedbackProps extends Omit<ModalProps, 'children'> {}

export default function ModalNextStudentsTimelineMR(props: ModalFeedbackProps) {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [page, setPage] = React.useState(1)

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { data, isLoading, isError } = useGetPendingThemes({
    limit: 10,
    page,
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (isLoading && props.isOpen) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  return (
    <Modal {...props} trapFocus={false} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        p="0px"
        background={'#302F37'}
        maxW={'800px'}
        w="100%"
        borderRadius={'8px'}
        overflow={'hidden'}
        transition={'all 0.2s ease-in-out'}
      >
        <ModalHeader
          color={'#FFFF'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Heading
            fontFamily={'Mulish'}
            fontSize={{ base: '20px', md: '22px' }}
            fontWeight={700}
          >
            Estudos disponíveis
          </Heading>

          <ModalCloseButton
            right={'unset'}
            top={'unset'}
            color={'#FFFF'}
            position={'relative'}
            _focus={{ outline: 'none' }}
          />
        </ModalHeader>

        <ModalBody color={'#FFFF'} background={'#302F37'} w="100%">
          <VStack>
            {isError && <p>Erro ao carregar os estudos</p>}

            {!data?.data?.length && !isError && <p>Nenhum estudo disponível</p>}

            {data?.data?.map(item => {
              return (
                <CardTimeLineMR
                  key={`card-available-themes-${item.id}`}
                  data={item}
                />
              )
            })}
          </VStack>
        </ModalBody>

        <ModalFooter flexDir={{ base: 'column', sm: 'row' }} gap={'20px'}>
          <Pagination
            w={'100%'}
            currentPage={page}
            totalCountOfRegisters={data?.meta?.total || 0}
            onPageChange={setPage}
            registersPerPage={10}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
