import { HStack, Text, Flex, Tag, Tooltip } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { letterCounter } from 'helpers/charactersCount'
import { PopoverDrawerRemovePendingStudies } from './PopoverDrawerRemovePendingStudies'
import { PopoverDrawerAddLimitedHoursPendingStudies } from './PopoverDrawerAddLimitedHoursPendingStudies'
import { capitalize, get, isEmpty } from 'lodash'
import { UseMutationResult } from '@tanstack/react-query'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'
import { useMutationTheoreticalStudies } from 'pages/CreateRecoveryPlan/queries/use-mutation-theorical-studies'
import { Icon } from '@iconify/react'
import { useGetListPendingStudies } from 'pages/CreateRecoveryPlan/queries/use-get-pending-studies'
import { useListProgrammedStudies } from '../queries/use-get-completed-pending-studies'
import { useToastMessage } from 'components/Toast'

interface SubTheme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface Theme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
}

interface CardDrawerPendingStudiesProps {
  area: string
  theme: Theme[]
  subTheme: SubTheme[]
  id: number
  updateDeleted: UseMutationResult<void, unknown, any, unknown>
  updateAddStudy: UseMutationResult<void, unknown, any, unknown>
}

export const CardDrawerPendingStudies = (
  props: CardDrawerPendingStudiesProps,
) => {
  const { area, subTheme, theme, id, updateDeleted, updateAddStudy } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { data: PrActivatedInfo } = useGetPrInfo()

  const showToast = useToastMessage()

  const updateAddRecoveryPlan = useMutationTheoreticalStudies()

  const { data: listPendingStudies } = useGetListPendingStudies({
    isProgrammedStudy: true,
    limit: 9999999,
  })

  const { data: listProgrammedStudies } = useListProgrammedStudies({
    limit: 9999999,
  })

  /*
  |-----------------------------------------------------------------------------
  | Memo
  |-----------------------------------------------------------------------------
  |
  |
  */

  const totalHoursConclude = useMemo(() => {
    if (listPendingStudies?.data) {
      return listPendingStudies?.data.reduce((acc, currentValue) => {
        if (currentValue.is_programmed_study) {
          return acc + Number(currentValue.study_time_in_minutes)
        }
        return acc
      }, 0)
    }

    return 0
  }, [listPendingStudies?.data])

  const totalHoursProgrammed = useMemo(() => {
    if (listProgrammedStudies?.data) {
      return listProgrammedStudies?.data.reduce((acc, currentValue) => {
        if (currentValue.is_programmed_study) {
          return acc + Number(currentValue.study_time_in_minutes)
        }
        return acc
      }, 0)
    }

    return 0
  }, [listProgrammedStudies?.data])

  /*
  |-----------------------------------------------------------------------------
  | CallBack
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddRecovery = useCallback(
    async (id: number) => {
      try {
        await updateAddRecoveryPlan.mutateAsync({
          studyRecoveryPlanId: PrActivatedInfo?.pendingStudiesData.id!,
          pendingStudyId: id,
          isProgrammedStudy: true,
        })

        showToast({
          title: 'Estudo adicionado.',
          description: 'Estudo adicionado com sucesso.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (err: any) {
        let errorMessage = 'Não foi possível efetuar a adição.'

        if (err.response?.data?.errors) {
          errorMessage = err.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        const apiErrorString = get(err, 'response.data')
        if (typeof apiErrorString === 'string') {
          errorMessage = apiErrorString
        }

        showToast({
          title: 'Erro',
          description: errorMessage,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [PrActivatedInfo?.pendingStudiesData.id, showToast, updateAddRecoveryPlan],
  )

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      p="12px 16px"
      justifyContent={'space-between'}
      borderRadius="4px"
      backgroundColor={'#393940'}
      gap="1rem"
      maxW="581px"
      minW={'545px'}
      w="100%"
    >
      <Flex maxW="130px" minW="130px" gap={'4px'} flexDir={'column'}>
        <Text
          fontFamily="Mulish"
          fontWeight="600"
          fontSize="14px"
          lineHeight="18px"
          color="#BDBDC7"
        >
          Grande área
        </Text>

        <Tag
          backgroundColor={'#E56000'}
          w="fit-content"
          minW={'30px !important'}
          maxH={'20px'}
          color="#FFFF"
        >
          {area.length > 15 ? (
            <Tooltip label={capitalize(area)}>
              {capitalize(letterCounter(area, 15))}
            </Tooltip>
          ) : (
            capitalize(area)
          )}
        </Tag>
      </Flex>

      <Flex maxW="130px" minW="130px" gap={'4px'} flexDir={'column'}>
        <Text
          fontFamily="Mulish"
          fontWeight="600"
          fontSize="14px"
          lineHeight="18px"
          color="#BDBDC7"
        >
          Tema
        </Text>

        <Text
          fontFamily="Mulish"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          color="#FFFFFF"
        >
          {theme &&
          theme.map(item => capitalize(item.name)).join(' | ').length > 15 ? (
            <Tooltip
              label={theme.map(item => capitalize(item.name)).join(' | ')}
            >
              {letterCounter(
                theme.map(item => capitalize(item.name)).join(' | '),
                15,
              )}
            </Tooltip>
          ) : (
            theme.map(item => capitalize(item.name))
          )}
        </Text>
      </Flex>

      <Flex maxW="140px" minW="140px" gap={'4px'} flexDir={'column'}>
        <Text
          fontFamily="Mulish"
          fontWeight="600"
          fontSize="14px"
          lineHeight="18px"
          color="#BDBDC7"
        >
          Subtema
        </Text>

        <Text
          fontFamily="Mulish"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          color="#FFFFFF"
        >
          {subTheme &&
          subTheme.map(item => capitalize(item.name)).join(' | ').length >
            15 ? (
            <Tooltip
              label={subTheme.map(item => capitalize(item.name)).join(' | ')}
            >
              {letterCounter(
                subTheme.map(item => capitalize(item.name)).join(' | '),
                15,
              )}
            </Tooltip>
          ) : (
            subTheme.map(item => capitalize(item.name))
          )}
        </Text>
        {(isEmpty(subTheme) || !subTheme) && (
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight={'18px'}
            color={'#FFFFFF'}
          >
            -
          </Text>
        )}
      </Flex>

      <HStack spacing={'10px'}>
        <PopoverDrawerRemovePendingStudies
          id={id}
          updateDeleted={updateDeleted}
        />

        {PrActivatedInfo?.pendingStudiesData?.available_study_minutes &&
        totalHoursConclude + totalHoursProgrammed >=
          PrActivatedInfo?.pendingStudiesData?.available_study_minutes ? (
          <PopoverDrawerAddLimitedHoursPendingStudies
            id={id}
            updateAddRecoveryPlan={updateAddStudy}
          />
        ) : (
          <Icon
            cursor={'pointer'}
            icon={'eva:plus-square-outline'}
            fontSize="24px"
            color="#FFFF"
            onClick={() => handleAddRecovery(id)}
          />
        )}
      </HStack>
    </Flex>
  )
}
