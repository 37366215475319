import { chakra, Flex, Text } from '@chakra-ui/react'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { FieldInputTelephoneController } from 'components/Fields/FieldInputTelephone/FieldInputTelephoneController'
import FieldInputMaskController from 'components/Fields/FieldMask/FieldInputMaskController'
import { useAuth } from 'contexts/auth'
import { countries, SelectCountryProps } from 'helpers/countries'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ValueType } from 'react-select'
import api from 'services/api'
import Swal from 'sweetalert2'
import { schema } from './schemaCompleteProfile'
import * as Yup from 'yup'
import { schemaNot55 } from './schemaCompleteProfileNot55'
import { DefaultButton } from 'components/Buttons/DefaultButton'

interface FormRefreshProfile {
  name: string | undefined
  CPF: string | undefined
  email: string | undefined
  tel: string
}

interface FirstStepProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

export function FirstStep({ setCurrentStep }: FirstStepProps) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormRefreshProfile>({
    /* resolver: yupResolver(schema), */
  })

  const resetForm = {
    name: '',
    CPF: '',
    email: '',
    tel: '',
  }

  const { user } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [telephone, setTelephone] = useState<
    ValueType<SelectCountryProps, false>
  >({
    label: {
      name: 'Brasil',
      iso: 'BR',
      iso3: 'BRA',
      ddi: '+55',
    },
    value: '+55',
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmitForm = async (data: FormRefreshProfile) => {
    if (telephone?.value === '+55') {
      schema
        .validate({
          tel: data.tel,
          email: data.email,
          CPF: data.CPF,
          name: data.name,
        })
        .then(async () => {
          try {
            await api.put('/app/users', {
              name: data.name,
              email: data.email,
              cpf: data.CPF,
              mobilePhone: `${telephone.value}${data.tel
                .replace('(', '')
                .replace(')', '')
                .replace('-', '')
                .replace(' ', '')
                .trim()}`,
              onboardCurrentStep: 1,
            })
            Swal.fire({
              buttonsStyling: false,
              title: 'Deu tudo certo!',
              html:
                '<p style="text-align:center">Atualização realizada com sucesso.</p>',
              icon: 'success',
              didClose: () => {
                setCurrentStep(1)
              },
            })
          } catch (err: any) {
            let errorMessage =
              'A atualização do cadastro não pode ser concluído nesse momento.'

            if (err.response?.data?.errors) {
              errorMessage = err.response.data.errors
                .map((err: { [key: string]: string }) => err.message)
                .join('\n')
            }

            const apiErrorString = get(err, 'response.data')
            if (typeof apiErrorString === 'string') {
              errorMessage = apiErrorString
            }

            Swal.fire({
              buttonsStyling: false,
              title: 'Aviso',
              html: `<p style="text-align:center">${errorMessage}</p>`,
              icon: 'error',
            })
          }
        })
        .catch((validationErrors: Yup.ValidationError) => {
          const errors: { [key: string]: string } = {}

          if (validationErrors.path) {
            errors[validationErrors.path] = validationErrors.message
          }

          if (errors.tel) {
            setError('tel', { message: errors.tel })
          }

          if (errors.CPF) {
            setError('CPF', { message: errors.CPF })
          }

          if (errors.name) {
            setError('name', { message: errors.name })
          }

          if (errors.email) {
            setError('email', { message: errors.email })
          }
        })
    }

    if (telephone?.value !== '+55') {
      schemaNot55
        .validate({
          email: data.email,
          CPF: data.CPF,
          name: data.name,
          tel: data.tel,
        })
        .then(async () => {
          try {
            if (telephone?.value) {
              await api.put('/app/users', {
                name: data.name,
                email: data.email,
                cpf: data.CPF,
                mobilePhone: `${telephone.value}${data.tel
                  .replace('(', '')
                  .replace(')', '')
                  .replace('-', '')
                  .replace(' ', '')
                  .trim()}`,
                onboardCurrentStep: 1,
              })
            }

            Swal.fire({
              buttonsStyling: false,
              title: 'Deu tudo certo!',
              html:
                '<p style="text-align:center">Atualização realizada com sucesso.</p>',
              icon: 'success',
              didClose: () => {
                setCurrentStep(1)
              },
            })
          } catch (err: any) {
            let errorMessage =
              'A atualização do cadastro não pode ser concluído nesse momento.'

            if (err.response?.data?.errors) {
              errorMessage = err.response.data.errors
                .map((err: { [key: string]: string }) => err.message)
                .join('\n')
            }

            const apiErrorString = get(err, 'response.data')
            if (typeof apiErrorString === 'string') {
              errorMessage = apiErrorString
            }

            Swal.fire({
              buttonsStyling: false,
              title: 'Aviso',
              html: `<p style="text-align:center">${errorMessage}</p>`,
              icon: 'error',
            })
          }
        })
        .catch((validationErrors: Yup.ValidationError) => {
          const errors: { [key: string]: string } = {}
          if (validationErrors.path) {
            errors[validationErrors.path] = validationErrors.message
          }

          if (errors.tel) {
            setError('tel', { message: errors.tel })
          }

          if (errors.CPF) {
            setError('CPF', { message: errors.CPF })
          }

          if (errors.name) {
            setError('name', { message: errors.name })
          }

          if (errors.email) {
            setError('email', { message: errors.email })
          }
        })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (user) {
      user.email && setValue('email', user.email)
      user.name && setValue('name', user.name)
      user.cpf && setValue('CPF', user.cpf)
    }

    if (user?.mobile_phone) {
      const filteredPhoneNumbers = countries.find(phoneNumber => {
        return user?.mobile_phone.startsWith(phoneNumber.ddi)
      })

      if (filteredPhoneNumbers) {
        setTelephone({
          label: filteredPhoneNumbers,
          value: filteredPhoneNumbers.ddi,
        })

        const phoneWithoutDDI = user.mobile_phone.replace(
          filteredPhoneNumbers.ddi,
          '',
        )

        const formattedPhoneNumber = `(${
          phoneWithoutDDI[0] ? phoneWithoutDDI[0] : ''
        }${phoneWithoutDDI[1] ? phoneWithoutDDI[1] : ''})${
          phoneWithoutDDI[2] ? phoneWithoutDDI[2] : ''
        }${phoneWithoutDDI[3] ? phoneWithoutDDI[3] : ''}${
          phoneWithoutDDI[4] ? phoneWithoutDDI[4] : ''
        }${phoneWithoutDDI[5] ? phoneWithoutDDI[5] : ''}${
          phoneWithoutDDI[6] ? phoneWithoutDDI[6] + '-' : ''
        }${phoneWithoutDDI[7] ? phoneWithoutDDI[7] : ''}${
          phoneWithoutDDI[8] ? phoneWithoutDDI[8] : ''
        }${phoneWithoutDDI[9] ? phoneWithoutDDI[9] : ''}${
          phoneWithoutDDI[10] ? phoneWithoutDDI[10] : ''
        }`

        setValue('tel', formattedPhoneNumber.trim())
      } else {
        setTelephone({
          label: { name: 'Brasil', iso: 'BR', iso3: 'BRA', ddi: '+55' },
          value: '+55',
        })

        if (user.mobile_phone) {
          const formattedPhoneNumber = `(${
            user.mobile_phone[0] ? user.mobile_phone[0] : ''
          }${user.mobile_phone[1] ? user.mobile_phone[1] : ''})${
            user.mobile_phone[2] ? user.mobile_phone[2] : ''
          }${user.mobile_phone[3] ? user.mobile_phone[3] : ''}${
            user.mobile_phone[4] ? user.mobile_phone[4] : ''
          }${user.mobile_phone[5] ? user.mobile_phone[5] : ''}${
            user.mobile_phone[6] ? user.mobile_phone[6] + '-' : ''
          }${user.mobile_phone[7] ? user.mobile_phone[7] : ''}${
            user.mobile_phone[8] ? user.mobile_phone[8] : ''
          }${user.mobile_phone[9] ? user.mobile_phone[9] : ''}${
            user.mobile_phone[10] ? user.mobile_phone[10] : ''
          }`

          setValue('tel', formattedPhoneNumber.trim())
        }
      }
    }
  }, [setValue, user, setCurrentStep])

  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      maxW="406px"
      w="full"
      as="form"
      onSubmit={handleSubmit(onSubmitForm)}
      flexDir={'column'}
      gap="12px"
    >
      <Text
        textAlign={'left'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="700"
        fontSize="24px"
        lineHeight="30px"
        color="#FFFFFF"
      >
        Atualização de{' '}
        <chakra.span
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize="24px"
          lineHeight="30px"
          color="#E56000"
        >
          dados
        </chakra.span>
      </Text>

      <Flex w="100%" flexDir={'column'} gap="16px" color="#FFFF">
        <FieldInputController
          name="name"
          control={control}
          label="Nome completo"
          placeholder="Escreva seu nome completo"
          error={errors.name}
        />

        <FieldInputMaskController
          label="CPF"
          control={control}
          name="CPF"
          placeholder="000.000.000-00"
          mask="999.999.999-99"
          error={errors.CPF}
          type="tel"
        />

        <FieldInputController
          name="email"
          control={control}
          autoComplete="off"
          label="E-mail"
          placeholder="aluno@gmail.com"
          error={errors.email}
        />

        <FieldInputTelephoneController
          name="tel"
          control={control}
          label="Telefone"
          placeholder="(00) 0000 0000"
          error={errors.tel}
          valueTel={telephone}
          onChangeTel={e => {
            setTelephone(e as SelectCountryProps)
            clearErrors('tel')
          }}
        />

        <Flex
          mt="16px"
          gap="10px"
          justifyContent={'center'}
          alignItems={'center'}
          w="100%"
        >
          <DefaultButton
            onClick={() => {
              reset(resetForm)
            }}
            w="100%"
            label="Limpar"
            variant="ghost"
          />

          <DefaultButton
            isLoading={isSubmitting}
            type="submit"
            w="100%"
            label="Próximo"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
