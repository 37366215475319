import { format } from 'date-fns'
import React, { useMemo } from 'react'

// Hooks
import { usePagination } from 'hooks/usePagination'

// UI
import { List, ListHeader, ListPagination, ListTable } from 'features/ui'
import { TableHeader } from 'features/ui/list/list-table'

// DAILY RECORDS:
import {
  GetDeletedPendingStudy,
  useGetDeletedPendingStudies,
} from 'features/daily-records'

// Styles
import { Text, Tooltip } from '@chakra-ui/react'
import { useUpdateRecoveryPendingStudy } from '../mutations/use-patch-recovery-pending-studies'
import { PopoverDeletedRecoveryPlan } from 'features/agendas/popover-deleted-recovery-pending-studies'

export function ListDeletedPendingStudies() {
  const { page, onChangePage } = usePagination()
  const updatePendingStudyDeleted = useUpdateRecoveryPendingStudy()

  const { data, isLoading, isFetching } = useGetDeletedPendingStudies({
    page,
  })

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'createdAt',
        fn(value) {
          return format(new Date(value), 'dd/MM/yyyy')
        },
      },
      {
        label: 'Área',
        accessor: 'field.name',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={1}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Tema',
        accessor: 'themes',
        fn(value: Array<{ name: string; id: number }>) {
          return (
            <Text noOfLines={1}>
              {value && value.map(item => item.name).join(' | ').length > 11 ? (
                <Tooltip label={value.map(item => item.name).join(' | ')}>
                  {value?.map(item => item.name).join(' | ')}
                </Tooltip>
              ) : (
                value?.map(item => item.name)
              )}
            </Text>
          )
        },
      },
      {
        label: 'Subtema',
        accessor: 'subjects',
        fn(value: Array<{ name: string; id: number }>) {
          return (
            <Text noOfLines={1}>
              {value && value.map(item => item.name).join(' | ').length > 11 ? (
                <Tooltip label={value.map(item => item.name).join(' | ')}>
                  {value?.map(item => item.name).join(' | ')}
                </Tooltip>
              ) : (
                value?.map(item => item.name)
              )}
            </Text>
          )
        },
      },
      {
        label: '',
        fn(_, data) {
          return (
            <PopoverDeletedRecoveryPlan
              id={data.id}
              updateDeleted={updatePendingStudyDeleted}
            />
          )
        },
      },
    ] as TableHeader<GetDeletedPendingStudy>[]
  }, [updatePendingStudyDeleted])

  return (
    <List>
      <ListHeader
        title="Estudos pendentes"
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <ListTable headers={headers} data={data?.items} isLoading={isLoading} />

      <ListPagination
        currentPage={page}
        onChangePage={onChangePage}
        totalCountOfRegisters={data?.meta.total}
      />
    </List>
  )
}
