import React, { useMemo, useState } from 'react'
import { chakra, Circle, HStack, Text, VStack } from '@chakra-ui/react'
import { StepProps, Steps } from './Steps/Steps'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { ModalConfirmProjectX } from './ModalConfirmProjectX/ModalConfirmProjectX'
import { ModalDefault } from 'components/Modal/ModalDefault'
import { HiCheckCircle } from 'react-icons/hi'
import ModalProjectXProfile from './ModalProjectXProfile'
import { ModalConfirmInstitutions } from './ModalConfirmInstitutions'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './ModalProjectXProfile/schema'
import { useActivatedFocusLearning } from './queries/use-mutation-activated-project-x'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { useAuth } from 'contexts/auth'
import { FormTestProfile, FormTestProfileOutput } from './types'

export const Inactivated = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalConfirmProjectX, setModalConfirmProjectX] = useState(false)
  const [modalProjectXProfile, setModalProjectXProfile] = useState(false)
  const [isModalConfirmInstitution, setIsModalConfirmInstitution] = useState(
    false,
  )
  const [selectedInstitutions, setSelectedInstitutions] = useState<string[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const methods = useForm<FormTestProfile>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      alreadyGraduated: 'true',
    },
  })

  const activateProjectX = useActivatedFocusLearning()

  const showToast = useToastMessage()

  const { updateUserData } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const data: StepProps[] = useMemo(() => {
    return [
      {
        title: 'Ative o projeto x',
        description: 'Dê um click no botão abaixo para ativar o projeto x.',
      },
      {
        title: 'Atualização de dados',
        description:
          'Preencha os campos de “Nome completo” e “CPF”. Não se esqueça de incluir em uma ordem de prioridade quais as provas que você deseja ser aprovado.',
      },
      {
        title: 'Gerar revisões inteligentes',
        description:
          'Vá até a página calendário MR e dê um click no botão “gerar revisões” e receberá 3 estudos de maior prevalência para estudar.',
      },
    ]
  }, [])

  const submitForm = async (data: FormTestProfileOutput) => {
    try {
      await activateProjectX.mutateAsync({
        name: `${data.name} ${data.lastName}`,
        documentNumber: data.documentNumber,
        alreadyGraduated: data.alreadyGraduated,
        intendedInstitutions: data.intendedInstitutions,
        prepSchool: data.prepSchool,
      })

      showToast({
        title: 'Sucesso',
        description: 'Projeto-X ativado.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível ativar o Projeto-X.'

      showToast({
        title: 'Não foi possível ativar o Projeto X',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 8000,
        mode: 'dark',
      })
    } finally {
      setIsModalConfirmInstitution(false)
      updateUserData()
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <VStack
        as="form"
        onSubmit={methods.handleSubmit(submitForm)}
        spacing={'0px'}
        align={'flex-start'}
      >
        <HStack my="8px">
          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            color="#BDBDC7"
          >
            Status:
          </Text>

          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            color="#FFFFF"
          >
            desativado
          </Text>

          <Circle size={'8px'} bg={'#60606C'} />
        </HStack>

        <Text
          fontFamily="Mulish"
          fontWeight="400"
          fontSize="16px"
          color="#D3D3D8"
        >
          O Projeto X é um modo de estudo dirigido que vai te ajudar a estudar o
          que é mais relevante para você.
        </Text>

        <Text
          mt={{ base: '26px !important', sm: '34px !important' }}
          fontWeight="700"
          fontSize="24px"
          lineHeight={'30px'}
          color="#D3D3D8"
        >
          Como funciona
        </Text>

        <Steps data={data} marginTop={'1.5rem !important'} />

        <DefaultButton
          maxW={'247px !important'}
          w="full"
          label={'Ativar projeto X'}
          mt="1rem !important"
          onClick={() => setModalConfirmProjectX(true)}
        />

        <ModalConfirmProjectX
          isOpen={modalConfirmProjectX}
          onClose={() => setModalConfirmProjectX(false)}
          onConfirmClick={() => {
            setModalConfirmProjectX(false)
            setModalProjectXProfile(true)
          }}
        />

        <ModalConfirmInstitutions
          isOpen={isModalConfirmInstitution}
          onClose={() => setIsModalConfirmInstitution(false)}
          isConfirmClickLoading={methods.formState.isSubmitting}
          onBackClick={() => {
            setIsModalConfirmInstitution(false)
            setModalProjectXProfile(true)
          }}
          selectedInstitutions={selectedInstitutions}
        />

        <ModalProjectXProfile
          isOpen={modalProjectXProfile}
          onClose={() => setModalProjectXProfile(false)}
          closeModalProjectXAndOpenConfirmInstitutions={isInsitutionSelectedHasData => {
            setSelectedInstitutions(isInsitutionSelectedHasData)
            setModalProjectXProfile(false)
            setIsModalConfirmInstitution(true)
          }}
        />
      </VStack>
    </FormProvider>
  )
}
