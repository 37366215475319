import { useMutation } from '@tanstack/react-query'
import { TestForm } from 'features/dashboard/type'
import { queryClient } from 'index'
import api from 'services/api'

export interface Params {
  data: TestForm
}

export async function mutateTest({ data }: Params) {
  return await api.post('/app/tests', data)
}

export function useMutateTest() {
  return useMutation(mutateTest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardTests'])
    },
  })
}
