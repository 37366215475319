import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export type GetTermsByInstitutionName = string

type Params = {
  institutionName: string | undefined
}

export async function getTermsByInstitutionName(
  params: Params,
): Promise<GetTermsByInstitutionName | null> {
  const { institutionName } = params

  if (!institutionName) {
    return null
  }

  const { data } = await api.get<GetTermsByInstitutionName>(
    `/app/users/institutions/${params.institutionName}/download`,
  )

  return data
}

export function useGetTermsByInstitutionName(params: Params) {
  return useQuery(
    ['get-terms-by-institution-name', params],
    () => getTermsByInstitutionName(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
