import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ModalProps,
  VStack,
  Flex,
  Tooltip,
  Box,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useAuth } from 'contexts/auth'
import React from 'react'
import { BiLock } from 'react-icons/bi'

export type ModalSimulationReviewProps = {
  title?: string
  onFirstClick?: () => void
  onSecondClick?: () => void
  confirmButtonIsLoading?: boolean
} & Omit<ModalProps, 'children'>

export default function ModalSimulationReview(
  props: ModalSimulationReviewProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Constant.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { userContract } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Constant.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    title,
    onFirstClick,
    onSecondClick,
    confirmButtonIsLoading,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isCentered {...rest}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.2)'} />
      <ModalContent
        maxW={'320'}
        w="full"
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalBody maxW={'320'} w="full" p={'2rem'} borderRadius={'8px'}>
          <VStack spacing={'24px'}>
            <Text
              fontWeight={700}
              fontSize={'24px'}
              lineHeight={'30px'}
              textAlign={'center'}
              color={'#FFFFFF'}
            >
              Selecione uma opção
            </Text>

            <Flex
              alignItems={'flex-end'}
              gap="24px"
              justifyContent={'center'}
              flexDir={'column'}
              w="full"
            >
              {userContract?.type === 'TRIAL' && (
                <Tooltip label="Disponível somente para alunos Mentoria Residência. Garanta já seu acesso completo à plataforma!">
                  <Box w="full">
                    <DefaultButton
                      disabled
                      label="Refazer simulado"
                      size="md"
                      w={'100%'}
                      leftIcon={<BiLock />}
                    />
                  </Box>
                </Tooltip>
              )}

              {userContract?.type === 'PURCHASE' && (
                <DefaultButton
                  label="Refazer simulado"
                  name="redo_simulation"
                  size="md"
                  w={'100%'}
                  onClick={onFirstClick}
                  isLoading={confirmButtonIsLoading}
                />
              )}

              {userContract?.type === 'PURCHASE' && (
                <DefaultButton
                  label="Confira o gabarito"
                  name="check_feedback"
                  size="md"
                  variant="ghost"
                  onClick={onSecondClick}
                  w={'100%'}
                />
              )}

              {userContract?.type === 'TRIAL' && (
                <Tooltip label="Disponível somente para alunos Mentoria Residência. Garanta já seu acesso completo à plataforma!">
                  <Box w="full">
                    <DefaultButton
                      disabled
                      label="Confira o gabarito"
                      name="check_feedback"
                      size="md"
                      variant="ghost"
                      w={'100%'}
                      leftIcon={<BiLock />}
                    />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
