import React from 'react'
import { Button } from '@chakra-ui/react'

interface Props {
  number: number
  isCurrent?: boolean
  onChangePage: (page: number) => void
}

export function ListPaginationItem({
  number,
  isCurrent = false,
  onChangePage,
}: Props) {
  if (isCurrent) {
    return (
      <Button
        w="4"
        size="sm"
        fontSize="xs"
        disabled
        colorScheme="brand"
        _disabled={{
          bg: 'brand.500',
          cursor: 'default',
        }}
      >
        {number}
      </Button>
    )
  }

  return (
    <Button
      w="4"
      size="sm"
      fontSize="xs"
      colorScheme="whiteAlpha"
      onClick={() => onChangePage(number)}
    >
      {number}
    </Button>
  )
}
