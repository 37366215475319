import { css } from 'styled-components'

export const Scroll = css`
  &::-webkit-scrollbar {
    background: #2a2a2d;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(223, 224, 235, 0.06);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(223, 224, 235, 0.6);
    border-radius: 16px;
  }
`

export const ResponsiveContainer = css`
  @media (max-width: 800px) {
    padding-left: 16px;
    padding-right: 8px;
  }
`
