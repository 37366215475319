import styled from 'styled-components'
import { ResponsiveContainer } from '../../styles/common'

const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${ResponsiveContainer}
`

const StyledIframe = styled.iframe`
  border: 0;
  box-shadow: none;
  width: 100%;
  height: 100%;
`

export { Container, StyledIframe }
