import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  Text,
  ThemingProps,
  useBreakpointValue,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React, { ReactNode } from 'react'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: ThemingProps<'Modal'>['size']
  children?: ReactNode
  title?: string
  titleNode?: ReactNode
  icon?: ReactNode
  subTitle?: string
  onConfirmClick: () => void
  modalContentProps?: ModalContentProps
}

export const ModalDefault = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size = 'xs',
    title,
    subTitle,
    children,
    icon,
    titleNode,
    onConfirmClick,
    modalContentProps,
  } = props

  const isSm = useBreakpointValue({ base: true, sm: false })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered>
      <ModalOverlay />
      <ModalContent
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        {...modalContentProps}
      >
        <ModalBody p={'1.5rem'} borderRadius={'8px'}>
          <Flex w="100%" justifyContent={'center'} flexDir="column">
            <Flex flexDir="column">
              {icon && (
                <HStack justify={'center'} mb="24px">
                  {icon}
                </HStack>
              )}

              {title && (
                <Text
                  fontFamily="Mulish"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize={'24px'}
                  color={'#FFFFFF'}
                  mb={isSm ? '8px' : '16px'}
                  textAlign={'center'}
                >
                  {title}
                </Text>
              )}

              {titleNode && titleNode}

              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                textAlign={'center'}
                fontSize="14px"
                lineHeight="18px"
                color="#BDBDC7"
              >
                {subTitle}
              </Text>
            </Flex>

            <Flex
              mt="24px"
              alignItems={'flex-end'}
              gap="12px"
              justifyContent={'center'}
            >
              <DefaultButton w="100%" label="Ok" onClick={onConfirmClick} />
            </Flex>
          </Flex>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
