import React from 'react'
import Layout from 'layouts/Logged'
import Header from 'components/Header'
import { Container } from './styles'
import { MyVersion2Template } from 'features/my-version2-videos-sidebar'

export const MyVersion2: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Minha versão 2.0"
          subTitle="Vídeos e conteúdo sobre minha versão 2.0"
        />

        <MyVersion2Template />
      </Container>
    </Layout>
  )
}
