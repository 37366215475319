import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponse } from 'types/response'

interface Params {
  limit?: number
  page?: number
}

export interface PendingTheme {
  id: number
  name: string
  field_id: number
  created_at: string
  updated_at: string
  medcel_exam: string
  field: Field
}

export interface Field {
  id: number
  name: string
  track_id: number
  created_at: string
  updated_at: string
}

interface GetPendingThemesResponde {
  data: PendingTheme[]
  meta: MetaResponse
}

export async function getPendingThemes(params: Params) {
  const { data } = await api.get<GetPendingThemesResponde>(
    '/app/mentoria-exams/pending-themes',
    {
      params: {
        ...params,
      },
    },
  )

  return data
}

export function useGetPendingThemes(params: Params) {
  return useQuery(
    ['get-pending-themes-studies', params],
    () => getPendingThemes(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
