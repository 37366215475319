import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Heading,
  Text,
  HStack,
  ModalCloseButton,
  VStack,
  Stack,
  Box,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldError, useForm } from 'react-hook-form'
import { InputDailyRecord, OutputDailyRecord } from 'features/dashboard/type'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { RadioCardController } from 'components/Fields/FieldCard/RadioCardController'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { pt } from 'date-fns/locale'
import { FieldDateController } from 'components/Fields/FieldDateNew/FieldDateController'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTrack } from 'contexts/track'
import {
  CreateDailyRecordRequest,
  useCreateDailyRecord,
  useGetSubjects,
  useGetThemes,
} from 'features/daily-records'
import { parserOptions } from 'helpers/parserOptions'
import { useAuth } from 'contexts/auth'
import Swal from 'sweetalert2'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import { FieldSwitchController } from 'components/Fields/FieldSwitch/FieldSwitchController'
import { RadioGroupController } from 'components/Fields/FieldRadioGroup/RadioGroupController'
import { FieldRadio } from 'components/Fields/FieldRadio'
import { schema } from './schema'

interface ModalDiaryProps {
  isOpen: boolean
  onClose: () => void
}

export function FormRecordDiary(props: ModalDiaryProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    reset,
    control,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<InputDailyRecord>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date(),
      effortPerception: 5,
      isCreateAgenda: true,
      fieldId: null,
      subjectIds: null,
      numberOfCorrectAnswers: 0,
      numberOfQuestions: 0,
      themeId: null,
      studiedMinutes: 0,
    },
  })

  const selectedFieldId = watch('fieldId')
  const isTheoreticalOnly = watch('isTheoreticalOnly')
  const { track } = useTrack()
  const showToast = useToastMessage()
  const { userContract } = useAuth()
  const createDailyRecord = useCreateDailyRecord()

  const getThemes = useGetThemes(
    { fieldId: selectedFieldId?.value, trackId: track?.id },
    { limit: 9999 },
  )

  const getSubjects = useGetSubjects(
    { fieldId: selectedFieldId?.value, trackId: track?.id },
    { limit: 9999 },
  )

  const themesOptions = useMemo(() => {
    return parserOptions(getThemes.data?.themes, { label: 'name', value: 'id' })
  }, [getThemes.data])

  const subjectsOptions = useMemo(() => {
    return parserOptions(getSubjects.data?.subjects, {
      label: 'name',
      value: 'id',
    })
  }, [getSubjects.data])

  const selectFields = useMemo(() => {
    // duplicated fields - filter by front-end
    if (track?.name === 'R+ CM') {
      return parserOptions(
        track.fields.filter(field => field.name !== 'GASTRO'),
        { label: 'name', value: 'id' },
      )
    }
    if (track?.name === 'R+ CGE') {
      return parserOptions(
        track.fields.filter(field => field.id !== 129),
        { label: 'name', value: 'id' },
      )
    } else {
      return parserOptions(track?.fields, { label: 'name', value: 'id' })
    }
  }, [track])

  useEffect(() => {
    if (isTheoreticalOnly) {
      unregister('isCreateAgenda')
      unregister('numberOfQuestions')
      unregister('numberOfCorrectAnswers')
    }
  }, [isTheoreticalOnly, unregister])

  const resetForm = useMemo(() => {
    return {
      fieldId: null,
      themeId: null,
      subjectIds: [],
      date: new Date(),
      isTheoreticalOnly: 'practical',
      isCreateAgenda: true,
      studiedMinutes: 0,
      effortPerception: 5,
      numberOfQuestions: 0,
      numberOfCorrectAnswers: 0,
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleEffortHelperText = (value: number) => {
    switch (value) {
      case 1:
      case 2:
        return 'Nenhum esforço'
      case 3:
      case 4:
        return 'Pouco esforço'
      case 5:
      case 6:
        return 'Médio esforço'
      case 7:
      case 8:
        return 'Alto esforço'
      case 9:
      case 10:
        return 'Máximo esforço'
      default:
        return ''
    }
  }

  const onSubmit = useCallback(
    async (data: OutputDailyRecord) => {
      if (!track || !userContract) return

      const request: CreateDailyRecordRequest = {
        date: data.date,
        trackId: track.id,
        fieldId: data.fieldId,
        themeIds: [data.themeId],
        subjectIds: data.subjectIds,
        contractId: userContract.id,
        isCreateAgenda: data.isCreateAgenda,
        studiedMinutes: data.studiedMinutes,
        effortPerception: data.effortPerception,
        numberOfQuestions: data.numberOfQuestions,
        isTheoreticalOnly: data.isTheoreticalOnly,
        numberOfCorrectAnswers: data.numberOfCorrectAnswers,
      }

      if (data.isTheoreticalOnly) {
        let firstContactTime = 0

        const result = await Swal.fire({
          title: 'Primeiro contato',
          html:
            '<p style="text-align:center">Deseja ter o primeiro contato desse estudo em:</p>',
          showDenyButton: true,
          denyButtonColor: '#64b450',

          showCancelButton: true,
          cancelButtonText: 'Cancelar',

          confirmButtonText: '24 horas',
          denyButtonText: '48 horas',
        })

        if (result.isConfirmed) {
          firstContactTime = 24
        } else if (result.isDenied) {
          firstContactTime = 48
        }

        request.firstContactTime = firstContactTime
      }

      try {
        await createDailyRecord.mutateAsync(request)

        showToast({
          title: 'Sucesso',
          description: 'Registro diário criado com sucesso!',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
        reset(resetForm)
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível registrar a sua solicitação. Por favor, reveja os dados e tente novamente.'

        showToast({
          title: 'Aviso',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })

        console.trace('Error on creating new daily record: ', error)
      } finally {
        props.onClose()
      }
    },
    [
      createDailyRecord,
      props,
      reset,
      resetForm,
      showToast,
      track,
      userContract,
    ],
  )

  const handleSpecificReset = useCallback(() => {
    if (selectedFieldId) {
      setValue('themeId', null)
      setValue('subjectIds', null)
    }
  }, [selectedFieldId, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    reset(resetForm)
  }, [resetForm, reset, props?.isOpen])

  useEffect(() => {
    handleSpecificReset()
  }, [selectedFieldId, handleSpecificReset])

  useEffect(() => {
    if (isTheoreticalOnly === 'practical') {
      setValue('isCreateAgenda', true)
    }
  }, [isTheoreticalOnly, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <Box background={'#302F37'} color={'#FFFF'} p="12px 16px 12px 0px">
        <HStack mb="8px" justifyContent={'space-between'} alignItems={'center'}>
          <Heading as="h1" fontSize={'24px'} fontWeight={700}>
            Diário
          </Heading>

          <ModalCloseButton
            right={'unset'}
            top={'unset'}
            color={'#FFFF'}
            position={'relative'}
            _focus={{ outline: 'none' }}
          />
        </HStack>

        <Text fontWeight={400} fontSize={'16px'} color={'#FFFF'}>
          Registre suas métricas diariamente.
        </Text>
      </Box>

      <VStack
        as={'form'}
        gap={'8px'}
        flexDir={'column'}
        p="16px 16px 24px 0px"
        color={'#FFFF'}
        background={'#302F37'}
        w="100%"
        spacing={'1rem'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <NewFieldSelectRegistryController
          name="fieldId"
          control={control}
          options={selectFields}
          error={errors.fieldId as any}
          label="Grande Área"
          placeholder="Selecione uma grande área..."
        />

        <NewFieldSelectRegistryController
          name="themeId"
          label="Tema"
          placeholder="Selecione um tema..."
          control={control}
          error={errors.themeId as FieldError}
          options={themesOptions}
          isLoading={getThemes.isLoading}
        />

        <NewFieldSelectRegistryController
          name="subjectIds"
          label="Subtema"
          placeholder="Selecione um subtema..."
          control={control}
          error={errors.subjectIds as any}
          options={subjectsOptions}
          isLoading={getSubjects.isLoading}
          isMulti={true as any}
        />

        <RadioGroupController
          name="isTheoreticalOnly"
          label="Tipo de estudo"
          placeholder="Selecione uma opção"
          defaultValue={'practical'}
          /* isDisabled={watch('isCreateAgenda')} */
          control={control}
          display="flex"
          gap="24px"
          error={errors.isTheoreticalOnly}
          minW={'226px'}
        >
          <FieldRadio name="thereoretical" value={'theoretical'}>
            Teórico
          </FieldRadio>
          <FieldRadio name="practical" value={'practical'}>
            Prático
          </FieldRadio>
        </RadioGroupController>

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          gap={{ base: '16px', sm: '40px' }}
          w="full"
        >
          <Box className="NewDatePicker">
            <FieldDateController
              label="Data"
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
              control={control}
              maxDate={new Date()}
              inputProps={{ _placeholder: { color: 'gray.400' } }}
              error={errors?.date}
              name={'date'}
              dropdownMode="select"
              placeholderText={'00/00/0000'}
            />
          </Box>

          <FieldInputController
            label="Tempo de estudo"
            name="studiedMinutes"
            type="number"
            control={control}
            placeholder="Tempo em minutos"
            onWheel={e => e.currentTarget.blur()}
            error={errors.studiedMinutes}
          />
        </Stack>

        {isTheoreticalOnly === 'practical' && (
          <>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent={'space-between'}
              gap={{ base: '16px', sm: '40px' }}
              w="full"
            >
              <FieldInputController
                label="Questões realizadas"
                name="numberOfQuestions"
                control={control}
                placeholder="00"
                type="number"
                onWheel={e => e.currentTarget.blur()}
                error={errors.numberOfQuestions}
              />

              <FieldInputController
                label="Questões corretas"
                name="numberOfCorrectAnswers"
                control={control}
                placeholder="00"
                type="number"
                onWheel={e => e.currentTarget.blur()}
                error={errors.numberOfCorrectAnswers}
              />
            </Stack>

            <FieldSwitchController
              control={control}
              name="isCreateAgenda"
              label="Criar revisão inteligente?"
              /* error={errors.isCreateAgenda} */
              defaultValue={isTheoreticalOnly === 'practical' && true}
              defaultChecked={true}
            >
              {watch('isCreateAgenda') === true ? 'Sim' : 'Não'}
            </FieldSwitchController>
          </>
        )}

        <RadioCardController
          name="effortPerception"
          label="Percepção de esforço?"
          control={control}
          options={Array.from({ length: 10 }).map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))}
          error={errors.effortPerception}
        />

        <Text w="100%" color={'#E56000'} fontWeight={500} fontSize={'14px'}>
          {handleEffortHelperText(Number(watch('effortPerception')))}
        </Text>

        <Stack w="full" direction={{ base: 'column', sm: 'row' }}>
          <DefaultButton
            onClick={() => reset(resetForm)}
            w="full"
            variant="ghost"
            label="Limpar"
          />

          <DefaultButton
            w="full"
            isLoading={isSubmitting}
            type="submit"
            label="Registrar"
          />
        </Stack>
      </VStack>
    </>
  )
}
