import { Box, BoxProps, ButtonProps, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import PaginationItem from './PaginationItem'

interface PaginationProps extends BoxProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  currentPage?: number
  onPageChange?: (page: number) => void
  shouldBeWide?: boolean
  buttonProps?: ButtonProps
}

const siblingsCount = 1

function geratePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0)
}

export default function Pagination({
  totalCountOfRegisters,
  registersPerPage = 6,
  currentPage = 1,
  onPageChange,
  shouldBeWide,
  buttonProps,
  ...props
}: PaginationProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)
  const previousPage =
    currentPage > 1
      ? geratePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPage =
    currentPage < lastPage
      ? geratePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage),
        )
      : []

  return (
    <Stack
      direction={!shouldBeWide ? ['column', 'row'] : 'row'}
      spacing={3}
      justify={'space-between'}
      align={'center'}
      color="#FFFF"
      {...props}
    >
      <Box>
        <strong>{(currentPage - 1) * registersPerPage}</strong> -{' '}
        <strong>
          {Math.min(currentPage * registersPerPage, totalCountOfRegisters)}
        </strong>{' '}
        de <strong>{totalCountOfRegisters} registros</strong>
      </Box>
      <Stack direction={'row'} spacing={2}>
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem
              buttonProps={buttonProps}
              number={1}
              onPageChange={onPageChange}
            />
            {currentPage > 2 + siblingsCount && (
              <Text color="gray.300" width="8" textAlign={'center'}>
                ...
              </Text>
            )}
            {}
          </>
        )}
        {previousPage.length > 0 &&
          previousPage.map(page => (
            <PaginationItem
              buttonProps={buttonProps}
              key={page}
              number={page}
              onPageChange={onPageChange}
            />
          ))}
        <PaginationItem
          buttonProps={buttonProps}
          number={currentPage}
          isCurrent
          onPageChange={onPageChange}
        />
        {nextPage.length > 0 &&
          nextPage.map(page => (
            <PaginationItem
              buttonProps={buttonProps}
              key={page}
              number={page}
              onPageChange={onPageChange}
            />
          ))}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && (
              <Text color="gray.300" width="8" textAlign={'center'}>
                ...
              </Text>
            )}
            <PaginationItem
              buttonProps={buttonProps}
              number={lastPage}
              onPageChange={onPageChange}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}
