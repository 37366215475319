import * as yup from 'yup'

export const schema = yup.object().shape({
  inicialDate: yup.date().nullable().required('Campo obrigatório'),

  finalDate: yup
    .date()
    .nullable()
    .required('Campo obrigatório')
    .min(
      yup.ref('inicialDate'),
      'A data final não pode ser menor que a data inicial',
    ),
})
