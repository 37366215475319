import { useStep } from 'components/Step/useStep'
import React, { createContext, useCallback, useContext, useState } from 'react'

interface RecoveryDailyContextValue {
  setStep: React.Dispatch<React.SetStateAction<number>>
  currentStep: number
  numberOfSteps: number
}

const RecoveryDailyContext = createContext<RecoveryDailyContextValue>(
  {} as RecoveryDailyContextValue,
)

export const RecoveryDailyProvider: React.FC = ({ children }) => {
  const numberOfSteps = 3
  const [currentStep, { setStep }] = useStep({
    maxStep: numberOfSteps,
    initialStep: 0,
  })

  return (
    <RecoveryDailyContext.Provider
      value={{
        numberOfSteps,
        currentStep,
        setStep,
      }}
    >
      {children}
    </RecoveryDailyContext.Provider>
  )
}

export function useRecoveryDaily() {
  const context = useContext(RecoveryDailyContext)
  return context
}
