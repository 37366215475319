import { Flex, Th, Thead, Text } from '@chakra-ui/react'
import React from 'react'

export const TrTableHeadFirstStepRecordProps = () => {
  return (
    <Thead
      borderBottom="0.5px solid #BDBDC7"
      textAlign={'center'}
      backgroundColor="#393940"
    >
      <Th
        textTransform={'none'}
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        color="#FFFFFF"
        py="14px"
        lineHeight={'20px'}
      >
        <Flex w="100%" justifyContent={'flex-start'} gap="10px">
          <Text>Grande área</Text>
        </Flex>
      </Th>

      <Th
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="14px"
        lineHeight={'20px'}
      >
        <Text textAlign={'start'}>Tema</Text>
      </Th>

      <Th
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="14px"
        lineHeight={'20px'}
      >
        <Text>Subtema</Text>
      </Th>

      <Th
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="14px"
        lineHeight={'20px'}
      >
        <Flex m="0 auto" w="90px !important" justifyContent={'center'}>
          Tipo
        </Flex>
      </Th>

      <Th
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="14px"
        lineHeight={'20px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Remover
        </Flex>
      </Th>
    </Thead>
  )
}
