import React from 'react'
import { Empty } from 'features/ui'

export function withEmptyFeedback(Component: any) {
  return function withEmptyFeedback(props: any) {
    const { data } = props

    return data?.length === 0 ? <Empty /> : <Component {...props} />
  }
}
