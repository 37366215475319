import React, { useCallback, useState } from 'react'
import { Flex, HStack, Link, Text, VStack } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { letterCounter } from 'helpers/charactersCount'
import { useMutateViewedNotification } from 'features/notifications/mutations/use-mutate-viewed-notification'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import {
  Notification,
  useGetNotifications,
} from 'features/notifications/mutations/use-get-notifications'

type NotificationProps = {
  data: Array<Notification>
}

export default function NotificationsSwiper({ data }: NotificationProps) {
  /*
  |-----------------------------------------------------------------------------
  | State.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [currentIndex, setCurrentIndex] = useState(0)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const mutateNotification = useMutateViewedNotification()
  const {
    isLoading: isLoadingNotifications,
    refetch: refetchNotifications,
  } = useGetNotifications()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleNext = useCallback(
    (currentIndex: number) => {
      if (currentIndex === data.length - 1) {
        return
      }

      setCurrentIndex(index => index + 1)
    },
    [data.length],
  )

  const handlePrev = useCallback((currentIndex: number) => {
    if (currentIndex === 0) {
      return
    }
    setCurrentIndex(index => index - 1)
  }, [])

  const handleMarkAsRead = useCallback(
    async (notificationId: number) => {
      if (!data[currentIndex].is_viewed) {
        try {
          await mutateNotification.mutateAsync({ notificationId })
          refetchNotifications()

          showToast({
            title: 'Sucesso',
            description: 'Notificação marcada como lida.',
            type: 'success',
            duration: 2000,
            mode: 'dark',
          })
        } catch (error: any) {
          const message: AxiosError =
            error?.response?.data?.errors
              ?.map((error: AxiosError) => error.message)
              ?.join(', ') ||
            error?.response?.data ||
            'Não foi possível marcar como lida agora, tente novamente mais tarde.'

          showToast({
            title: 'Erro ao marcar como lida',
            description: message.message ? message.message : message,
            type: 'error',
            duration: 2000,
            mode: 'dark',
          })
        }
      }
    },
    [data, mutateNotification, refetchNotifications, currentIndex, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack
      width="100%"
      minH={data.length > 0 ? '160px' : '0px'}
      alignItems={data.length > 0 ? 'flex-start' : 'space-between'}
      justifyContent={'space-between'}
      h="full"
    >
      {data && data.length > 0 ? (
        <>
          <Text fontWeight={600} fontSize={'18px'} color={'#FFFFFF'}>
            {data?.[currentIndex]?.title}
          </Text>

          {data?.[currentIndex]?.message.split('\n').map((message, index) => (
            <Text
              key={index}
              wordBreak={'break-word'}
              mt="14px"
              fontWeight={400}
              fontSize={'14px'}
              color={'#FFFFFF'}
            >
              {message}
            </Text>
          ))}

          {data?.[currentIndex].url_link && (
            <Link
              wordBreak={'break-word'}
              href={
                data?.[currentIndex].url_link.startsWith('http')
                  ? data?.[currentIndex].url_link
                  : `https://${data?.[currentIndex].url_link}`
              }
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              textDecoration="underline"
              textUnderlineOffset={'2px'}
              fontSize="14px"
              color="#ff7715"
            >
              {letterCounter(data?.[currentIndex].url_link, 30)}
            </Link>
          )}

          <HStack w="100%" mt="10px" spacing={0} justify={'space-between'}>
            <Flex>
              <Icon
                icon="eva:arrow-left-fill"
                onClick={() => handlePrev(currentIndex)}
                opacity={currentIndex === 0 ? 0.4 : 1}
                fontSize={'22px'}
                color={'#FFFFFF'}
                cursor={'pointer'}
              />

              <Icon
                icon="eva:arrow-right-fill"
                onClick={() => handleNext(currentIndex)}
                opacity={currentIndex === data.length - 1 ? 0.4 : 1}
                fontSize={'22px'}
                color={'#FFFFFF'}
                cursor={'pointer'}
              />
            </Flex>

            {!data[currentIndex].is_viewed && (
              <Text
                display="inline-flex"
                gap="4px"
                fontSize={'12px'}
                cursor={'pointer'}
                fontWeight={400}
                color={'#E56000'}
                onClick={() => handleMarkAsRead(data[currentIndex].id)}
              >
                <Icon
                  icon="ph:eye"
                  fontSize={'18px'}
                  color={'#E56000'}
                  cursor={'pointer'}
                />
                {isLoadingNotifications ? 'carregando...' : 'marcar como lido'}
              </Text>
            )}
          </HStack>
        </>
      ) : (
        <Text fontWeight={400} fontSize={'14px'} color={'#FFFFFF'}>
          Sem notificações novas.
        </Text>
      )}
    </VStack>
  )
}
