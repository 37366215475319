import api from 'services/api'

import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'index'

async function deleteAgenda({ agendaId }: { agendaId: number }) {
  await api.delete(`/app/agendas/${agendaId}`)
}

export function useDeleteAgenda() {
  return useMutation(deleteAgenda, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'list'])
    },
  })
}
