import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function unconcludeAgenda({ agendaId }: { agendaId: number }) {
  await api.patch(`app/agendas/${agendaId}/unconclude`)
}

export function useUnconcludeAgenda() {
  return useMutation(unconcludeAgenda, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'list'])
    },
  })
}
