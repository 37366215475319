// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react'
/* import { theme as chakraPro } from '@chakra-ui/pro-theme' */

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  /* ...chakraPro.colors, */
  brand: {
    50: '#E9881A',
    100: '#ffd3b3',
    200: '#ffb580',
    300: '#ffa96a',
    400: '#ff9040',
    500: '#ff7715',
    600: '#FF6B00',
    700: '#bf5000',
    800: '#953e00',
    900: '#6a2d00',
    primary: '#E56000',
    secondary: '#393940',
    'secondary-brighter': '#60606c',
  },
  'white-90': 'rgba(255,255,255,0.9)',
  'white-80': 'rgba(255,255,255,0.8)',
  'white-70': 'rgba(255,255,255,0.7)',
  'white-60': 'rgba(255,255,255,0.6)',
  'white-50': 'rgba(255,255,255,0.5)',
  'white-40': 'rgba(255,255,255,0.4)',
  'white-30': 'rgba(255,255,255,0.3)',
  'white-20': 'rgba(255,255,255,0.2)',
  'white-10': 'rgba(255,255,255,0.1)',
  greenProgressBar: { 500: '#0DDF15' },
  yellowProgressBar: { 500: '#E5C009' },
  loadingProgressBar: { 500: '#BB7121' },
}

const fonts = {
  body: 'Mulish',
  heading: 'Mulish',
}

export const theme = extendTheme({ colors, fonts })
