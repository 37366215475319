import { DateTime } from 'luxon'
import { join, map } from 'lodash'
import { FiTrash } from 'react-icons/fi'
import React, { useCallback, useMemo } from 'react'

// Hooks
import { usePagination } from 'hooks/usePagination'

// UI
import { List, ListHeader, ListPagination, ListTable } from 'features/ui'
import { TableHeader } from 'features/ui/list/list-table'

// Styles
import { Text, Tooltip } from '@chakra-ui/react'

// DAILY RECORDS:
import {
  GetDailyRecord,
  useGetDailyRecords,
  useDeleteDailyRecord,
  PopoverDeleteConfirmation,
} from 'features/daily-records'

export function ListDailyRecords() {
  const { page, onChangePage } = usePagination()
  const deleteDailyRecord = useDeleteDailyRecord()
  const { data, isLoading, isFetching } = useGetDailyRecords({
    page,
    limit: 10,
  })

  const handleDeleteDailyRecord = useCallback(
    async (id: number) => {
      try {
        await deleteDailyRecord.mutateAsync({ id })
      } catch (error) {
        console.log(error)
      }
    },
    [deleteDailyRecord],
  )

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'date',
        fn(value) {
          return DateTime.fromISO(value).toFormat('dd/MM/yyyy')
        },
      },
      {
        label: 'Área',
        accessor: 'field.name',
        fn(value) {
          return (
            <Tooltip label={value} hasArrow>
              <Text noOfLines={2}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Tema',
        accessor: 'theme.name',
        fn(value, data) {
          let themeNames = value

          if (!themeNames) {
            themeNames = join(map(data.themes, 'name'), ', ')
          }

          return (
            <Tooltip label={themeNames} hasArrow>
              <Text noOfLines={2}>{themeNames}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Subtema',
        accessor: 'subject.name',
        fn(value, data) {
          let subjectNames = value

          if (!subjectNames) {
            subjectNames = join(map(data.subjects, 'name'), ', ')
          }

          return (
            <Tooltip label={subjectNames} hasArrow>
              <Text noOfLines={2}>{subjectNames || <p>&ndash;</p>} </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Minutos de estudo',
        accessor: 'studiedMinutes',
      },
      {
        label: 'Nro° de questões',
        accessor: 'numberOfQuestions',
      },
      {
        label: 'Nro° de acertos',
        accessor: 'numberOfCorrectAnswers',
      },
      {
        label: '',
        fn(_, data) {
          return (
            <PopoverDeleteConfirmation
              icon={FiTrash}
              isLoading={deleteDailyRecord.isLoading}
              onConfirm={() => handleDeleteDailyRecord(data.id)}
            />
          )
        },
      },
    ] as TableHeader<GetDailyRecord>[]
  }, [deleteDailyRecord.isLoading, handleDeleteDailyRecord])

  return (
    <List>
      <ListHeader
        title="Registros diários"
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <ListTable headers={headers} data={data?.items} isLoading={isLoading} />

      <ListPagination
        currentPage={page}
        onChangePage={onChangePage}
        totalCountOfRegisters={data?.meta.total}
      />
    </List>
  )
}
