import {
  Modal,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  ModalProps,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { FiCheckSquare } from 'react-icons/fi'
import { FormRecordDiary } from './ModalDiary'
import { ModalRecordTest } from './ModalTest/Tests'

interface ModalRecordProps extends Omit<ModalProps, 'children'> {
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
}

export default function ModalRecords(props: ModalRecordProps) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { activeIndex, setActiveIndex, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalRecordTest, setModalRecordTest] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent
        p="0px"
        background={'#302F37'}
        borderRadius={'8px'}
        maxW="406px"
        w="100%"
        overflow={'hidden'}
        transition={'all 0.2s ease-in-out'}
      >
        <Tabs
          onChange={index => setActiveIndex(index)}
          index={activeIndex}
          variant="enclosed"
          w="100%"
          mb="0px !important"
        >
          <TabList borderBottom={'0px !important'}>
            <Tab
              h="52px"
              justifyContent={'center'}
              w="45%"
              fontWeight="400"
              fontSize={'16px'}
              borderRadius="0px"
              lineHeight="20px"
              backgroundColor={'#393940'}
              gap={'10px'}
              border="0px !important"
              boxShadow={'none !important'}
              margin="0px !important"
              color="#BDBDC7 !important"
              _selected={{
                fontWeight: 600,
                borderBottom: '4px solid #E56000 !important',
                color: '#FFFF !important',
              }}
            >
              <FiCheckSquare />
              <Text>Diário</Text>
            </Tab>

            <Tab
              h="52px"
              justifyContent={'flex-start'}
              w="55%"
              fontWeight="400"
              fontSize={'16px'}
              lineHeight="20px"
              backgroundColor={'#393940'}
              borderRadius="0px"
              boxShadow={'none !important'}
              gap={'8px'}
              color="#BDBDC7"
              _selected={{
                fontWeight: 600,
                borderBottom: '4px solid #E56000 !important',
                color: '#FFFF !important',
              }}
            >
              <Icon icon="eva:plus-square-outline" />
              <Text>Provas e simulados</Text>
            </Tab>
          </TabList>

          <TabPanels pl="1rem" pr="8px">
            <TabPanel
              p="0px"
              overflow={'auto'}
              maxH={'800px'}
              sx={{
                position: 'relative',
                '&::-webkit-scrollbar': {
                  width: '6px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                  boxShadow: 'inset 0 0 5px #60606c',
                  borderRadius: '17px',
                  height: '8px',
                  borderLeft: '2px solid transparent',
                  borderRight: '2px solid transparent',
                  marginTop: '10px',
                  marginBottom: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#E56000',
                  borderRadius: '17px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-corner': {
                  background: 'rgba(0,0,0,0)',
                },
              }}
            >
              <FormRecordDiary
                isOpen={modalRecordTest}
                onClose={props.onClose}
              />
            </TabPanel>

            <TabPanel p="0px">
              <ModalRecordTest
                isOpen={modalRecordTest}
                onClose={props.onClose}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  )
}
