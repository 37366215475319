import {
  GetDailyRecord,
  GetDailyRecordResponseApi,
  useDeleteDailyRecord,
  useGetDailyRecords,
} from 'features/daily-records'
import React, { useCallback, useMemo, useState } from 'react'
import Pagination from 'components/Pagination'
import { useToastMessage } from 'components/Toast'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import { HStack, IconButton, Tooltip, VStack, Text } from '@chakra-ui/react'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { AxiosError } from 'axios'
import { BiTrash } from 'react-icons/bi'
import { capitalize } from 'lodash'

export default function DiaryTable() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [page, setPage] = useState(1)
  const [rowSelected, setRowSelected] = useState<GetDailyRecord | null>(null)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDailyRecord = useDeleteDailyRecord()
  const { data, isLoading, isFetching } = useGetDailyRecords({
    page,
    limit: 6,
  })
  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDeleteDailyRecord = useCallback(
    async (id: number | null | undefined) => {
      try {
        if (!id) return

        await deleteDailyRecord.mutateAsync({ id })

        showToast({
          title: 'Sucesso',
          description: 'Registro do diário removido com sucesso!',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        setModalConfirmDelete(false)
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possívevel efetuar a remoção.'

        showToast({
          title: 'Erro ao remover estudo',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [deleteDailyRecord, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: 'Data',
        accessor: 'date',
        fn(value) {
          return (
            <HStack minW={'80px'}>
              <Tooltip label={value ?? ''} hasArrow>
                <Text textAlign={'center'} noOfLines={1}>
                  {value ?? ''}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: 'Área',
        accessor: 'field.name',
        fn(value) {
          return (
            <Tooltip label={capitalize(value ?? '')} hasArrow>
              <Text maxW={'130px'} textAlign={'center'} noOfLines={1}>
                {capitalize(value ?? '')}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Tema',
        fn(_, data: GetDailyRecordResponseApi) {
          return (
            <Tooltip
              label={
                data?.themes?.length === 0
                  ? '-'
                  : data?.themes
                      ?.map(value => capitalize(value?.name))
                      .join('; ')
              }
              hasArrow
            >
              <Text maxW={'200px'} textAlign={'center'} noOfLines={1}>
                {data?.themes?.length === 0
                  ? '-'
                  : data?.themes
                      ?.map(value => capitalize(value?.name))
                      .join('; ')}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Subtema',
        accessor: 'subjects',
        fn(value, data: GetDailyRecordResponseApi) {
          return (
            <Tooltip
              label={
                value?.length === 0
                  ? '-'
                  : data?.subjects
                      ?.map(value => capitalize(value?.name))
                      .join('; ')
              }
              hasArrow
            >
              <Text maxW={'200px'} textAlign={'center'} noOfLines={1}>
                {value?.length === 0
                  ? '-'
                  : data?.subjects
                      ?.map(value => capitalize(value?.name))
                      .join('; ')}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Tempo de estudo',
        accessor: 'studiedMinutes',
        fn(value) {
          return (
            <Tooltip label={value ?? '-'} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value ?? '-'}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Que. realizadas',
        accessor: 'numberOfQuestions',
        fn(value) {
          return (
            <Tooltip label={value ?? '-'} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value ?? '-'}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Que. corretas',
        accessor: 'numberOfCorrectAnswers',
        fn(value) {
          return (
            <Tooltip label={value ?? '-'} hasArrow>
              <Text textAlign={'center'} noOfLines={1}>
                {value ?? '-'}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Ações',
        accessor: 'id',
        fn(value, data) {
          return (
            <HStack justifyContent={'center'}>
              <IconButton
                aria-label={'delete-open-modal'}
                data-qa="diary-delete-open-modal"
                w="24px"
                h="24px"
                minW={'24px'}
                borderRadius={'4px'}
                backgroundColor={'#393940'}
                _active={{ backgroundColor: '#313136' }}
                _hover={{ backgroundColor: '#313136' }}
                onClick={() => {
                  setRowSelected(data)
                  setModalConfirmDelete(true)
                }}
                icon={<BiTrash size={'14px'} color="#FFFFFF" />}
              />
            </HStack>
          )
        },
      },
    ] as TableHeader<GetDailyRecord>[]
  }, [])

  return (
    <VStack align="flex-start" maxW={'100%'}>
      <List
        borderRadius={'0px 4px 4px 4px'}
        w="100%"
        background="#302F37"
        p="0px 16px 16px 16px"
        my="0px"
      >
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={isLoading}
          isFetching={isFetching}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={data?.items}
          isLoading={isLoading}
          tableCellProps={{
            border: 'none !important',
            p: '4px 12px',
            borderRight: '1px solid #60606C !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '14px',
            lineHeight: '15px',
            textTransform: 'none !important',
            p: '16px 12px',
            textAlign: 'center',
            _first: {
              textAlign: 'unset',
            },
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={data?.meta.total ?? 0}
          registersPerPage={6}
          fontSize="12px"
          mt="16px !important"
          px="8px"
        />

        <ModalDefaultTwoButton
          isOpen={modalConfirmDelete}
          onClose={() => setModalConfirmDelete(false)}
          title="Tem certeza que deseja apagar?"
          subTitle="Se continuar, o registro será apagado permanentemente."
          confirmButtonIsLoading={deleteDailyRecord.isLoading}
          onConfirmClick={() => handleDeleteDailyRecord(rowSelected?.id)}
        />
      </List>
    </VStack>
  )
}
