import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Payload {
  chosenAlternative: string
  mockExamQuestionId: number
  answerId: number
}

async function mutateAnswer(payload: Payload) {
  await api.patch(`/app/mock-exam-answers/${payload.answerId}`, {
    chosenAlternative: payload.chosenAlternative,
    mockExamQuestionId: payload.mockExamQuestionId,
  })
}

export function useMutateAnswer() {
  return useMutation(mutateAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams-by-id'])
      queryClient.invalidateQueries(['get-simulation-exams'])
      queryClient.invalidateQueries(['get-simulation-exams-answers'])
    },
  })
}
