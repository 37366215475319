import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react'
import { FAQAccordion } from './components/FAQAccordion'
import homeDash from 'assets/images/home-dash.webp'
import homeTime from 'assets/images/home-time.webp'
import homeCalendario from 'assets/images/home-calendario.webp'
import { Carousel } from '../../components/Swipper/Carousel'
import { useHistory } from 'react-router-dom'
import image1 from '../../assets/images/banner-home01.svg'
import image2 from '../../assets/images/banner-home02.svg'
import image1mobile from '../../assets/images/banner-home-mobile01.svg'
import image2mobile from '../../assets/images/banner-home-mobile02.svg'
import { ModalWelcomeWhatsGroup } from 'components/Modal/ModalWelcomeWhatsGroup'
import { useAuth } from 'contexts/auth'
import { differenceInDays } from 'date-fns'

export const HomeTemplate: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hook
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push } = useHistory()
  const isMobile = useBreakpointValue({ base: true, sm: false })
  const { user } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalWelcomeWhatsGroup, setModalWelcomeWhatsGroup] = useState(false)

  useEffect(() => {
    const ShowModalOnlyThreeDaysAfterOnboarding = (): boolean => {
      if (!user) return false

      if (user.contracts[0]?.initial_date) {
        const userFilledOnboarding = new Date(user.contracts[0]?.initial_date)

        const currentDate = new Date()

        const differenceBetweenDates = differenceInDays(
          currentDate,
          userFilledOnboarding,
        )

        if (differenceBetweenDates <= 3) return true
      }
      return false
    }

    if (!user?.studentMonitor) return

    if (user.studentMonitor) {
      ShowModalOnlyThreeDaysAfterOnboarding() && setModalWelcomeWhatsGroup(true)
    }
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex flexDir={'column'} gap="16px">
      <Flex w="full" flexDir={{ base: 'column', md: 'row' }} gap="16px">
        <Carousel
          imageArraySrc={[
            {
              alt: 'página de vídeos sobre mentoria residência',
              src: isMobile ? image1mobile : image1,
              link: 'https://mentoria-residencia.memberkit.com.br/',
            },
            {
              alt: 'aula magna mentoria residência',
              src: isMobile ? image2mobile : image2,
              link: 'https://www.youtube.com/watch?v=6UqusH7FSe4',
            },
          ]}
          w={{ base: 'full', md: '66%' }}
        />
        <Flex
          as="a"
          target="_blank"
          rel="noreferrer"
          href="https://mentoriaresidencia.notion.site/mentoriaresidencia/Mentoria-Resid-ncia-Nosso-Time-378aaaed54d84e13a8851edf741810f1"
          w={{ base: 'full', md: '33%' }}
          bgColor="#000"
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius="8px"
          _hover={{
            filter: 'brightness(1.1)',
            outline: '1px solid #d9d9d911',
          }}
        >
          <Image
            alt="time mentoria residência"
            src={homeTime}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
              cursor: 'pointer',
            }}
          />
        </Flex>
      </Flex>

      <Flex w="full" flexDir={{ base: 'column', md: 'row' }} gap="16px">
        <Flex
          w={{ base: 'full', md: '66%' }}
          flexDir={{ base: 'column-reverse', md: 'row' }}
          gap="16px"
        >
          <Flex flexDir="column" w={'full'}>
            <Text
              fontSize={{ base: '16px', lg: '18px' }}
              fontWeight="700"
              pl="12px"
              pb="20px"
            >
              FAQ Mentoria
            </Text>

            <Box
              maxH="400px"
              overflowY="auto"
              px="10px"
              sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                  boxShadow: 'inset 0 0 5px #60606c',
                  borderRadius: '17px',
                  height: '8px',
                  borderLeft: '2px solid transparent',
                  borderRight: '2px solid transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#E56000',
                  borderRadius: '17px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-corner': {
                  background: 'rgba(0,0,0,0)',
                },
              }}
            >
              <FAQAccordion />
            </Box>
          </Flex>
        </Flex>

        <Flex
          w={{ base: 'full', md: '33%' }}
          gap="16px"
          flexDir={{ base: 'column', md: 'column' }}
        >
          <Flex
            flexDir="column"
            gap="12px"
            w="full"
            p="12px"
            bgColor="#131313"
            borderRadius="8px"
            _hover={{
              filter: 'brightness(1.1)',
              outline: '1px solid #d9d9d911',
            }}
          >
            <Text fontWeight="700">Acesse sua Dashboard</Text>

            <Image
              src={homeDash}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => push('/dashboard')}
            />
          </Flex>

          <Flex
            as="a"
            target="_blank"
            rel="noreferrer"
            href="https://fluoridated-newsboy-2a5.notion.site/PLANILHA-DA-APROVA-O-2025-ed5547f246d04e3e96c1c016523d8126"
            flexDir="column"
            gap="12px"
            w="full"
            p="12px"
            bgColor="#131313"
            borderRadius="8px"
            _hover={{
              filter: 'brightness(1.1)',
              outline: '1px solid #d9d9d911',
            }}
          >
            <Text fontWeight="700">Confira o Calendário de Editais</Text>

            <Image
              src={homeCalendario}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      {user?.studentMonitor?.[0]?.mentoriaGroup && (
        <ModalWelcomeWhatsGroup
          isOpen={modalWelcomeWhatsGroup}
          onClose={() => setModalWelcomeWhatsGroup(false)}
          onCloseIcon={() => setModalWelcomeWhatsGroup(false)}
          whatsLink={
            user?.studentMonitor?.[0]?.mentoriaGroup?.[0]?.mentoria_group_link
          }
        />
      )}
    </Flex>
  )
}
