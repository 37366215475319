import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface Params {
  themeId: number
}

async function addThemeRevision(params: Params) {
  await api.post('/app/mentoria-exams/add-revision', { ...params })
}

export function useAddThemeRevision() {
  return useMutation(addThemeRevision, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-pending-themes-studies'])
      queryClient.invalidateQueries(['get-pending-themes-progress-bar'])
    },
  })
}
