import { Button, Flex, Text, Link, useBreakpointValue } from '@chakra-ui/react'
import React, { MouseEventHandler, ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { ImYoutube } from 'react-icons/im'
type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'
import './style.css'
import { DefaultButton } from 'components/Buttons/DefaultButton'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size: Size
  children?: ReactNode
  CancelButtonClose: any
  linkVideo: string
  onConfirmClick: () => void
} & ReactModalProps

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '252px',
    sm: '366px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalVideoOrientation = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    CancelButtonClose,
    linkVideo,
    onConfirmClick,
    ...reactModalProps
  } = props

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          padding: '34px',
          position: 'absolute',
          width: '100%',
          maxWidth: getWidthBySize('sm'),
          height: 'fit-content',
          left: '50%',
          top: '50%',
          overflow: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#302F37',
          border: '0px',
        },
        overlay: {
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.4)',
          height: '100%',
          overflowY: 'auto',
        },
      }}
      {...reactModalProps}
    >
      <Flex w="100%" justifyContent={'center'} flexDir="column">
        <Flex justifyContent={'center'}>
          <ImYoutube size={'44px'} color="#E56000" />
        </Flex>

        <Flex mt="24px" flexDir="column">
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize={{ base: '16px', sm: '17.8px' }}
            color={'#FFFFFF'}
            mb="8px"
            textAlign={{ base: 'center', sm: 'center' }}
          >
            Tem certeza que deseja continuar?
          </Text>

          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            textAlign={'center'}
            px={{ base: '0px', sm: '2rem' }}
            fontSize="12px"
            lineHeight="15px"
            color="rgba(255, 255, 255, 0.8)"
          >
            Se continuar, você será direcionado para um vídeo no youtube de
            tutorial.
          </Text>
        </Flex>

        <Flex
          mt="24px"
          alignItems={'flex-end'}
          gap="12px"
          justifyContent={'center'}
        >
          <DefaultButton
            label="Não"
            onClick={CancelButtonClose}
            variant="ghost"
            maxW="116px"
            w={'100%'}
            size="mds"
          />

          <Link
            href={linkVideo}
            textDecoration="none !important"
            target="_blank"
            w={'100%'}
            maxW="116px"
          >
            <DefaultButton
              size="mds"
              label="Sim"
              w={'100%'}
              onClick={onConfirmClick}
            />
          </Link>
        </Flex>
      </Flex>
      {children}
    </ReactModal>
  )
}
