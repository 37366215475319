import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface GetProgressBar {
  percentageOfCompletedThemes: number
  percentageOfPendingThemes: number
  percentageOfThemesInProgress: number
}

export async function getProjectXProgressBar(): Promise<GetProgressBar> {
  const { data } = await api.get<GetProgressBar>('/app/focus_learning/progress')

  return data
}

export function useProjectXProgressBar() {
  return useQuery(['project-x-progress'], () => getProjectXProgressBar(), {
    refetchOnWindowFocus: false,
    retry: false,
  })
}
