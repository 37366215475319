import { Box, Flex, FlexProps, useRadio, UseRadioProps } from '@chakra-ui/react'
import React, { ForwardRefRenderFunction } from 'react'

export type RadioCardProps = UseRadioProps & {
  children: React.ReactNode
  radioProps?: FlexProps
}

const RadioCardBase: ForwardRefRenderFunction<any, RadioCardProps> = (
  props,
  ref,
) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input ref={ref} {...input} />

      <Flex
        {...checkbox}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        width="auto"
        p="10px"
        height="42px"
        fontSize="md"
        mr="4"
        mb="4"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="10px"
        transition="all 0.4s"
        color="white"
        borderColor="#60606c"
        borderStyle="solid"
        _checked={{
          bg: '#ff6b00',
          color: '#ffffff',
          borderColor: '#ff6b00',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _hover={{
          borderColor: 'rgba(255, 107, 0,0.4)',
          bg: '#FF6B00',
          color: 'white',
          transform: 'scale(1.05)',
          transition: 'all 0.8s',
        }}
        {...props.radioProps}
      >
        {props.children}
      </Flex>
    </Box>
  )
}

export const RadioCard = React.forwardRef(RadioCardBase)
