import React, { useMemo } from 'react'
import { Input, InputProps } from '@chakra-ui/react'

type Sizes = 'lg' | 'md' | 'sm'

export interface FieldInputProps extends Omit<InputProps, 'size'> {
  size?: Sizes
}

const FieldInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldInputProps
> = ({ size = 'md', ...props }, ref) => {
  const sizesBySize = useMemo((): Record<Sizes, FieldInputProps> => {
    return {
      sm: {
        height: '32px',
      },
      md: {
        height: '42px',
      },
      lg: {
        height: '48px',
      },
    }
  }, [])

  return (
    <Input
      ref={ref}
      border="0px"
      color="#FFFF"
      backgroundColor={'#393940'}
      borderRadius="8px"
      autoComplete="off"
      transition={'none'}
      data-qa={props.name}
      _focus={{
        boxShadow: 'none !important',
        border: '1px solid #E56000',
      }}
      {...sizesBySize[size]}
      {...props}
    />
  )
}

export default React.forwardRef(FieldInput)
