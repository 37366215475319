import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

// Styles
import Layout from 'layouts/Unlogged'
import { FormContainer } from './styles'

// Schemas
import { ForgotPasswordNewSchema } from './schemas'

// Services
import api from 'services/api'

// Helpers
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Box, Flex, VStack } from '@chakra-ui/react'
import { FieldInputController } from 'components/Fields/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

interface ForgotPasswordResetProps {
  password: string
  passwordConfirmation: string
}

const ForgotPasswordReset: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const location = useLocation<{ token: string }>()
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<ForgotPasswordResetProps>({
    resolver: yupResolver(ForgotPasswordNewSchema),
  })

  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(
    async formData => {
      try {
        const query = new URLSearchParams(location.search)
        const resetPasswordToken = query.get('token')
        if (!resetPasswordToken) return

        await ForgotPasswordNewSchema.validate(formData, { abortEarly: false })

        await api.put('/public/forgot_password', {
          ...formData,
          resetPasswordToken,
        })

        Swal.fire({
          title: 'Sucesso',
          html:
            '<p style="text-align:center">Senha redefinida com sucesso.</p>',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (err) {
        Swal.fire({
          title: 'Aviso',
          text:
            'Falha ao solicitar redefinição de senha. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history, location.search],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Redirect user to login if no token is presented as query param.
     */
    const query = new URLSearchParams(location.search)
    const resetPasswordToken = query.get('token')
    if (!resetPasswordToken) return history.replace('/')
  }, [history, location])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <p>
          Digita a sua <span>nova senha</span>
        </p>
        <div>
          <VStack w="100%" spacing={'1rem'} px={{ base: '1rem', sm: 'none' }}>
            <Box maxW={'380px'} w="100%">
              <FieldInputController
                control={control}
                placeholder="Senha"
                type="password"
                name="password"
                error={errors.password}
              />
            </Box>

            <Box maxW={'380px'} w="100%">
              <FieldInputController
                control={control}
                placeholder="Confirma a senha"
                type="password"
                name="passwordConfirmation"
                error={errors.passwordConfirmation}
              />
            </Box>
          </VStack>
        </div>

        <Flex
          w="100%"
          flexDir={{ base: 'column', sm: 'row' }}
          gap={'1rem'}
          px="1rem"
          justifyContent={{ base: 'center', sm: 'space-between' }}
        >
          <DefaultButton
            label="Redefinir"
            type="submit"
            w={{ base: '100%', sm: '50%' }}
            isLoading={isSubmitting}
          />
          <DefaultButton
            label="Voltar"
            variant="ghost"
            type="button"
            w={{ base: '100%', sm: '50%' }}
            onClick={() => push('/')}
          />
        </Flex>
      </FormContainer>
    </Layout>
  )
}

export default ForgotPasswordReset
