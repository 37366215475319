import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

export type SelectOptionProps<T> = {
  label: string
  value: T
}

export type ButtonPropsElement<T> = {
  options: SelectOptionProps<T>[]
  value?: SelectOptionProps<T>
  onChange?: (option: SelectOptionProps<T>) => void
  labelExtractor: (option: SelectOptionProps<T>) => string | number
} & Omit<ButtonProps, 'onChange' | 'value'>

export const FieldRadioButton = <T extends string | number>({
  options,
  value,
  onChange,
  labelExtractor,
  ...rest
}: ButtonPropsElement<T>) => {
  const handleButtonClick = (option: SelectOptionProps<T>) => {
    if (onChange) {
      onChange(option)
    }
  }

  return (
    <div>
      {options.map(option => (
        <Button
          key={labelExtractor(option)}
          onClick={() => {
            handleButtonClick(option)
          }}
          textAlign="center"
          alignItems="center"
          background={
            value && value.value === option.value ? '#FF6B00' : '#1a202c'
          }
          justifyContent="center"
          width="auto"
          p="10px"
          height="42px"
          fontSize="md"
          mr="4"
          mb="4"
          cursor="pointer"
          borderWidth="1px"
          borderRadius="10px"
          transition="all 0.4s"
          color="white"
          borderColor="#60606c"
          borderStyle="solid"
          _checked={{
            bg: '#ff6b00',
            color: '#ffffff',
            borderColor: '#ff6b00',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          _hover={{
            borderColor: 'rgba(255, 107, 0,0.4)',
            bg: '#FF6B00',
            color: 'white',
            transform: 'scale(1.05)',
            transition: 'all 0.8s',
          }}
          {...rest}
        >
          {labelExtractor(option)}
        </Button>
      ))}
    </div>
  )
}
