import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

interface PaginationItemProps {
  number: number
  isCurrent?: boolean
  onPageChange?: (page: number) => void
  buttonProps?: ButtonProps
}

export default function PaginationItem({
  isCurrent = false,
  number,
  buttonProps,
  onPageChange,
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        width="4"
        {...buttonProps}
        disabled
        data-qa={`pagination-item-${number}`}
        _disabled={{
          bgColor: '#FF6B00',
          cursor: 'default',
        }}
        _hover={{ bg: '#FF6B00' }}
      >
        {number}
      </Button>
    )
  }
  return (
    <Button
      size="sm"
      fontSize="xs"
      bgColor="#393940"
      _hover={{ bg: '#FF6B00' }}
      data-qa={`pagination-item-${number}`}
      onClick={() => onPageChange!(number)}
      {...buttonProps}
    >
      {number}
    </Button>
  )
}
