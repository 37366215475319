import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function generateFocusLearning() {
  await api.post('/app/focus_learning/generate')
}

export function useGenerateFocusLearning() {
  return useMutation(generateFocusLearning, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'list'])
    },
  })
}
