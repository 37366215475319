import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

export interface GetDeletedTestsResponseApi {
  id: number
  year: number
  date: string
  score: number
  user_id: number
  is_deleted: boolean
  studied_minutes: number
  institution_name: string
  effort_perception: number
  number_of_questions: number
  number_of_correct_answers: number
}

export interface GetDeletedTests {
  id: number
  date: string
  score: number
  studiedMinutes: number
  institutionName: string
  effortPerception: number
  numberOfQuestions: number
  numberOfCorrectAnswers: number
}

export interface GetDeletedTestsResponse {
  items: Array<GetDeletedTests>
  meta: MetaResponse
}

export interface Params {
  page: number
}

export async function getDeletedTests(
  params: Params,
): Promise<GetDeletedTestsResponse> {
  const { data: tests } = await api.get<{
    data: GetDeletedTestsResponseApi[]
    meta: MetaResponseApi
  }>('/app/tests/deleteds', { params: { limit: 10, ...params } })

  const output = tests.data.map(test => ({
    id: test.id,
    date: test.date,
    score: test.score,
    studiedMinutes: test.studied_minutes,
    institutionName: test.institution_name,
    effortPerception: test.effort_perception,
    numberOfQuestions: test.number_of_questions,
    numberOfCorrectAnswers: test.number_of_correct_answers,
  }))

  return {
    items: output,
    meta: formatMeta(tests.meta),
  }
}

export function useGetDeletedTests(params: Params) {
  return useQuery(['tests', 'deleted', params], () => getDeletedTests(params))
}
