import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function updateRecoveryPendingStudy({ id }: { id: number }) {
  return await api.patch(`/app/pending_studies/${id}/retrieve`)
}

export function useUpdateRecoveryPendingStudy() {
  return useMutation(updateRecoveryPendingStudy, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pending-studies', 'deleted'])
    },
  })
}
