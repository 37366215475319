import React, { useState } from 'react'

import Layout from 'layouts/Logged'
import { Container, StyledIframe } from './styles'
import { Loading } from 'features/ui'

const CircleCommunity = () => {
  const [loading, setLoading] = useState(true)

  return (
    <Layout>
      <Container>
        {loading && <Loading />}
        <StyledIframe
          src="https://alunomentoriaresidencia.circle.so/c/monitores-2023?iframe=true"
          onLoad={() => setLoading(false)}
        />
      </Container>
    </Layout>
  )
}

export default CircleCommunity
