import styled from 'styled-components'

interface StyleProps {
  isDragging: boolean
  index: number
}

interface DnDMainContainerProps {
  hasScroll: boolean
}

const DnDSelectContainer = styled.div`
  color: 'white';

  .custom__control {
    height: 42px;
  }

  .custom__placeholder {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #bdbdc7;
  }
`

const DnDMainContainer = styled.div<DnDMainContainerProps>`
  display: flex;
  height: fit-content;
  flex-direction: column;
  max-height: 250px;
  overflow: auto;
  margin-top: 10px;
  padding-right: ${(props: DnDMainContainerProps) =>
    props.hasScroll ? '10px' : '0px'};

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    margin-top: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 17px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e56000;
    border-radius: 17px;
    height: 8px;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`

const DnDItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  height: 36px;
  padding: 9px 16px;
  background-color: #393940;
  text-align: center;
  border-radius: 8px;
  margin-top: 10px;
  user-select: none;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #393940;
  border-color: ${(props: StyleProps) =>
    props.isDragging ? '#e56000' : '#393940'};
  margin-top: ${(props: StyleProps) => (props.index === 0 ? '0px' : '8px')};
`

const DnDItemText = styled.div`
  text-transform: uppercase;
  width: 70%;
  padding: 0px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top: none;
  border-bottom: none;
  color: #fff;
`

const DndCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #e56000;
`

export {
  DnDSelectContainer,
  DnDItem,
  DnDMainContainer,
  DnDItemText,
  DndCloseIcon,
}
