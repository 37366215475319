export const theme = {
  background: 'transparent',
  textColor: 'rgba(255, 255, 255, 0.5)',
  fontSize: 12,
  axis: {
    domain: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
    },
  },
  grid: {
    line: {
      stroke: 'rgba(255, 255, 255, 0.08)',
      strokeWidth: 1,
    },
  },
}
