import React, { useCallback, useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  VStack,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import Swal from 'sweetalert2'
import { Power } from 'react-feather'
import { LogOutButton } from 'components/Header/styles'
import Info from '../components/Info'
import { useAuth } from 'contexts/auth'
import { pdfData } from '../data/pdfData'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { LabelStepVideo } from '../components/LabelStepVideo'

interface KillerConceptStepProps {
  currentStep: number
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export function KillerConceptStep(props: KillerConceptStepProps) {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [activePdf, setActivePdf] = useState(pdfData[0])

  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { setStep } = props
  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { logOut } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      html:
        '<p style="text-align:center">Você realmente deseja fazer logout?</p>',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      h="full"
      mx={{ base: '16px', md: '64px', xl: '84px' }}
      pb={'24px'}
      flexDir="column"
    >
      <Flex
        w="full"
        mb="16px"
        justifyContent={'end'}
        pr={{ base: '16px', md: '0px' }}
      >
        <LogOutButton onClick={confirmLogOut}>
          <Power size={20} strokeWidth={1} />
        </LogOutButton>
      </Flex>

      <Info currentStep={props.currentStep} />

      <Box mt={{ base: '44px', sm: '44px' }} mb={{ base: '12px', md: '24px' }}>
        <Heading
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ base: '18px', sm: '32px' }}
          lineHeight={{ base: '23px', sm: '40px' }}
          letterSpacing="0.04em"
          color="#FFFFFF"
        >
          Conceito Matador
        </Heading>
      </Box>

      <Flex
        justifyContent={{ base: 'center', lg: 'unset' }}
        align={{ base: 'center', lg: 'unset' }}
        gap={{ base: '0px', sm: '34px' }}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          maxW={'870px'}
          w="100%"
          maxH={{ base: 'fit-content', sm: '550px' }}
          minH="450px"
          h={{ base: 'fit-content', sm: '100%' }}
          p={{ base: '0px', sm: '24px' }}
          background="#302F37"
          borderRadius="8px"
        >
          <Flex w={'100%'} h="auto" maxW={'870px'}>
            <Flex
              w="100%"
              h="100%"
              flex={1}
              maxH={'full'}
              background="#302F37"
              borderRadius="8px"
            >
              <iframe
                scrolling="auto"
                src={activePdf.value}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          maxW={{ md: 'full', lg: '368px' }}
          maxH={'550px'}
          justifyContent={'space-between'}
          minW="176px"
          w="100%"
          p={'24px'}
          pr="16px"
          flexDir={'column'}
          gap="23px"
          background={isMobile ? 'inherit' : '#302F37'}
          borderRadius="8px"
          m="0px !important"
        >
          <Flex
            flexDir={'column'}
            gap="10px"
            overflowY={'auto'}
            pr="12px"
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#E56000',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
            letterSpacing={'0.5px'}
          >
            {pdfData.map((item, index) => {
              return (
                <LabelStepVideo
                  key={item.id}
                  number={index + 1}
                  videoText={item.title}
                  icon={'codicon:file-pdf'}
                  onClick={() => setActivePdf(item)}
                  isActiveVideo={item.value === activePdf.value}
                />
              )
            })}
          </Flex>

          <DefaultButton
            minH="42px !important"
            label="Voltar"
            size="md"
            variant="ghost"
            w="100%"
            onClick={() => setStep(1)}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
