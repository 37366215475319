import { Text, HStack, Stack, VStack, Tooltip } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { capitalize } from 'lodash'
import React from 'react'

interface DrawerCardProps {
  area: string
  theme: string
  onClickAddButton: () => void
}

export const DrawerCard = (props: DrawerCardProps) => {
  const { area, onClickAddButton, theme } = props
  return (
    <HStack
      borderRadius={'4px'}
      background={'#393940'}
      px="1rem"
      py="10px"
      w="100%"
      justifyContent={'space-between'}
      spacing={'1rem'}
    >
      <HStack
        w="100%"
        maxW={'calc(100% - 34px)'}
        gap={{ base: '25px', sm: '78px' }}
      >
        <VStack
          justifyContent={'flex-start'}
          align={'flex-start'}
          spacing={'0px'}
          minW={'100px'}
        >
          <Text
            fontWeight={600}
            fontSize={'14px'}
            lineHeight={'18px'}
            color="#BDBDC7"
          >
            Grande Área
          </Text>

          <Tooltip fontSize={'16px'} label={capitalize(area)}>
            <Text
              fontWeight={600}
              maxW={'100px'}
              textOverflow="ellipsis"
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              fontSize={'16px'}
              lineHeight={'20px'}
              color="#FFFFFF"
            >
              {capitalize(area)}
            </Text>
          </Tooltip>
        </VStack>

        <VStack
          justifyContent={'flex-start'}
          align={'flex-start'}
          spacing={'0px'}
          overflow={'hidden'}
          w="auto"
        >
          <Text
            fontWeight={600}
            fontSize={'14px'}
            lineHeight={'18px'}
            color="#BDBDC7"
          >
            Tema
          </Text>

          <Tooltip fontSize={'16px'} label={capitalize(theme)}>
            <Text
              fontWeight={600}
              fontSize={'16px'}
              textOverflow="ellipsis"
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              w={'auto'}
              maxW={'100%'}
              flexGrow={'1'}
              lineHeight={'20px'}
              color="#FFFFFF"
            >
              {capitalize(theme)}
            </Text>
          </Tooltip>
        </VStack>
      </HStack>

      <Stack cursor={'pointer'} onClick={onClickAddButton}>
        <Icon
          color="#FFFFFF"
          fontSize={'18px'}
          icon={'eva:plus-square-outline'}
        />
      </Stack>
    </HStack>
  )
}
