import { Flex, IconButton, useToast, Text, Box, HStack } from '@chakra-ui/react'
import { useCallback } from 'react'
import React from 'react'
import { Icon } from '@iconify/react'
import { uniqueId } from 'lodash'

type Status = 'info' | 'warning' | 'success' | 'error'
type Mode = 'dark' | 'light'

type ToastProps = {
  title: string
  description?: any
  descriptionNode?: React.ReactNode
  type: Status
  duration: number
  mode: Mode
}

type ValueGetBackGroundByType = {
  backgroundColor: string
  color: string
  borderTop: string
  icon: React.ReactNode
}

const getBackGroundByType = (mode: Mode, status: Status) => {
  const colors: Record<Status, ValueGetBackGroundByType> = {
    success: {
      backgroundColor: mode === 'dark' ? '#393940' : '#C8F5D6',
      borderTop: mode === 'dark' ? '4px solid #0DDF15' : 'unset',
      color: mode === 'dark' ? '#FFFFFF' : '#2D3748',
      icon: (
        <Icon
          fontSize={'20px'}
          color="#0DDF15"
          icon={'material-symbols:check-circle-rounded'}
        />
      ),
    },
    error: {
      backgroundColor: mode === 'dark' ? '#393940' : '#FED7D7',
      borderTop: mode === 'dark' ? '4px solid #FF6363' : 'unset',
      color: mode === 'dark' ? '#FFFFFF' : '#2D3748',
      icon: (
        <Icon fontSize={'20px'} color="#E53E3E" icon={'mdi:alert-circle'} />
      ),
    },
    info: {
      backgroundColor: mode === 'dark' ? '#393940' : '#FAF3CF',
      borderTop: mode === 'dark' ? '4px solid #E5C009' : 'unset',
      color: mode === 'dark' ? '#FFFFFF' : '#2D3748',
      icon: (
        <Icon fontSize={'20px'} color="#E5C009" icon={'mdi:alert-circle'} />
      ),
    },
    warning: {
      backgroundColor: mode === 'dark' ? '#393940' : '#FEEBCB',
      borderTop: mode === 'dark' ? '4px solid #DD6B20' : 'unset',
      color: mode === 'dark' ? '#FFFFFF' : '#2D3748',
      icon: (
        <Icon fontSize={'20px'} color="#DD6B20" icon={'mdi:alert-circle'} />
      ),
    },
  }

  return colors[status]
}

export const useToastMessage = () => {
  const toast = useToast()

  const showToast = useCallback(
    ({
      description,
      type,
      duration,
      mode,
      title,
      descriptionNode,
    }: ToastProps) => {
      toast({
        status: type,
        duration: duration,
        isClosable: true,
        id: uniqueId(),
        // eslint-disable-next-line react/display-name
        render: ({ onClose }) => (
          <HStack
            className="toast-container"
            backgroundColor={getBackGroundByType(mode, type).backgroundColor}
            color={getBackGroundByType(mode, type).color}
            padding="12px 16px"
            borderRadius="4px"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            gap="10px"
            borderTop={getBackGroundByType(mode, type).borderTop}
            position="relative"
          >
            <Box>{getBackGroundByType(mode, type).icon}</Box>

            <Flex flexDir={'column'}>
              <Text fontWeight="700" fontSize="16px" lineHeight="24px">
                {title}
              </Text>

              {description && (
                <Text
                  fontWeight="700"
                  color={'#BDBDC7'}
                  fontSize="16px"
                  lineHeight="24px"
                >
                  {description}
                </Text>
              )}

              {descriptionNode && descriptionNode}
            </Flex>

            <IconButton
              aria-label="close"
              icon={<Icon icon={'mdi:window-close'} />}
              maxH={'24px'}
              onClick={onClose}
              style={{
                position: 'absolute',
                right: '5px',
                top: '5px',
                background: 'inherit',
              }}
            />
          </HStack>
        ),
      })
    },
    [toast],
  )

  return showToast
}
