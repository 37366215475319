import React, { useMemo } from 'react'
import { Container } from './styles'
import Layout from 'layouts/Logged'
import { Box, Flex } from '@chakra-ui/react'
import { stepsRecoveryPlan } from 'components/Step/data'
import { useRecoveryDaily } from 'contexts/recoveryDaily'
import { StepOne } from './components/Steps/StepOne'
import { StepTwo } from './components/Steps/StepTwo'
import { Step } from 'components/Step/Step'
import { PlanActivated } from './components/PlanActivated'
import { InitialPagePlan } from './components/InitialPagePlan'
import { useGetPrInfo } from './queries/use-get-activated-pr'
import { isAfter, isBefore, isEqual, isSameDay, subDays } from 'date-fns'
import { formatDateAddTimeZoned } from 'helpers/formatTimer'
import { IsLoadingPage } from 'pages/RecoveryPlan/IsLoadingPage'
import { isEmpty } from 'lodash'

export const CreateRecoveryPlan: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { currentStep, setStep } = useRecoveryDaily()
  const { data, isLoading } = useGetPrInfo()

  /*
  |-----------------------------------------------------------------------------
  | useEffect
  |-----------------------------------------------------------------------------
  |
  |
  */

  const shouldRenovateRecoveryPlan = useMemo(() => {
    if (data?.pendingStudiesData?.finishes_at) {
      if (
        isSameDay(
          formatDateAddTimeZoned(data.pendingStudiesData.finishes_at),
          new Date(),
        ) ||
        isAfter(
          new Date(),
          formatDateAddTimeZoned(data.pendingStudiesData.finishes_at),
        )
      ) {
        return true
      }
    }

    return false
  }, [data])

  const beforeFinishesAtAndHasNotPendingStudies = useMemo(() => {
    if (data?.pendingStudiesData?.finishes_at) {
      const subDayFineshesAt = subDays(
        formatDateAddTimeZoned(data.pendingStudiesData.finishes_at),
        1,
      )

      if (
        isEmpty(data?.pendingStudiesData?.pendingStudies) &&
        isBefore(new Date(), subDayFineshesAt)
      ) {
        return true
      }
    }

    return false
  }, [
    data?.pendingStudiesData?.finishes_at,
    data?.pendingStudiesData?.pendingStudies,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (isLoading) {
    return <IsLoadingPage />
  }

  return (
    <Layout>
      <Container>
        {data?.pendingStudiesData?.status === 'ACTIVE' &&
          !shouldRenovateRecoveryPlan &&
          !beforeFinishesAtAndHasNotPendingStudies && <PlanActivated />}

        {(shouldRenovateRecoveryPlan || data?.statusCode === 204) && (
          <InitialPagePlan />
        )}

        {(data?.pendingStudiesData?.status === 'PENDING' ||
          beforeFinishesAtAndHasNotPendingStudies) &&
          !shouldRenovateRecoveryPlan && (
            <Box>
              <Flex mt="" flexDir={{ base: 'row', sm: 'row' }} w="auto">
                {stepsRecoveryPlan.map((step, id) => (
                  <Step
                    key={id}
                    cursor="pointer"
                    title={step.title}
                    // onClick={() => setStep(id)}
                    isActive={currentStep === id}
                    isCompleted={currentStep > id}
                    isFirstStep={id === 0}
                    isLastStep={stepsRecoveryPlan.length === id + 1}
                  />
                ))}
              </Flex>
              {currentStep === 0 && <StepOne />}
              {currentStep === 1 && <StepTwo />}
            </Box>
          )}
      </Container>
    </Layout>
  )
}
