import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

export interface FirstStepVideoProps {
  number: number
  onClick?: any
  videoText: string
  videoTime: string
  activeVideo: number
}

export function FirstStepVideo({
  videoText,
  videoTime,
  number,
  onClick,
  activeVideo,
}: FirstStepVideoProps) {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      borderRadius="8px"
      background={activeVideo === number ? '#484851' : '#393940'}
      maxH="61px"
      w="100%"
      h="100%"
      cursor={'pointer'}
      onClick={onClick}
      padding={'10px 16px 10px 12px'}
      justifyContent="space-between"
      align={'center'}
    >
      <Flex justifyContent={'space-between'}>
        <Flex gap="24px">
          <Flex justifyContent={'center'} w={'30px'} alignItems="center">
            <Text>{number}</Text>
          </Flex>

          <Flex flexDir={'column'}>
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="600"
              fontSize="14px"
              lineHeight="18px"
              w="100%"
              color="#FFFFFF"
            >
              {videoText}
            </Text>

            <Flex>
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="12px"
                lineHeight="15px"
                color="#D1D5D8"
              >
                {videoTime}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
