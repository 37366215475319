import React from 'react'
import {
  chakra,
  CircularProgress,
  CircularProgressLabel,
  GridItem,
  GridItemProps,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { MajorStatsProps } from '..'
import { getISOWeek } from 'date-fns'

type HitPercentage = { currentWeekSelected: number } & Pick<
  MajorStatsProps,
  | 'percentCorrectAnswers'
  | 'percentCorrectQuestionsToReachTheGoal'
  | 'correct_answers_percentage'
> &
  GridItemProps

export default function HitPercentageMobile(props: HitPercentage) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    percentCorrectAnswers,
    percentCorrectQuestionsToReachTheGoal,
    correct_answers_percentage,
    currentWeekSelected,
    ...rest
  } = props

  const currentWeek = getISOWeek(new Date())

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (currentWeekSelected === currentWeek) {
    return (
      <GridItem
        display={'flex'}
        maxH="135px"
        backgroundColor="#302F37"
        p="16px"
        {...rest}
      >
        <HStack spacing={'1rem'} alignItems={'center'} h="full">
          <CircularProgress
            size={'84px'}
            thickness="6px"
            capIsRound
            color={
              percentCorrectAnswers >= correct_answers_percentage
                ? '#0DDF15'
                : '#e5c009'
            }
            trackColor="#616167"
            my={{ base: '0px', sm: '16px' }}
            value={percentCorrectAnswers}
          >
            <CircularProgressLabel fontSize={'18px'}>
              <chakra.span>{percentCorrectAnswers.toFixed(0)}%</chakra.span>

              <Text fontWeight={400} fontSize={'12px'}>
                Acertos
              </Text>
            </CircularProgressLabel>
          </CircularProgress>

          <VStack align={'flex-start'}>
            <Text fontWeight={'700'} fontSize={'16px'}>
              Percentual de acerto
            </Text>

            {percentCorrectAnswers > 100 && (
              <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                <chakra.span color={'#0ddf15'}>Sabe muuuito!! </chakra.span>
                Você ultrapassou a meta de{' '}
                <chakra.span color={'#0ddf15'}>
                  {`${correct_answers_percentage}%}`}{' '}
                </chakra.span>
                de acerto dessa semana.
              </Text>
            )}

            {percentCorrectAnswers > 0 &&
              percentCorrectQuestionsToReachTheGoal === 0 && (
                <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                  <chakra.span color={'#0ddf15'}>Excelente! </chakra.span>
                  Você atingiu a meta de Percentual dessa semana
                </Text>
              )}

            {percentCorrectAnswers === 0 && (
              <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                Atinja{' '}
                <chakra.span color={'#E56000'}>
                  {' '}
                  {`${correct_answers_percentage}% `}{' '}
                </chakra.span>
                de acerto para completar a meta dessa semana.
              </Text>
            )}

            {percentCorrectQuestionsToReachTheGoal > 0 &&
              percentCorrectQuestionsToReachTheGoal < 100 && (
                <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                  Quase lá, atinja a meta de{' '}
                  <chakra.span color={'#E56000'}>
                    {' '}
                    {`${correct_answers_percentage}% `}{' '}
                  </chakra.span>
                  de acertos
                </Text>
              )}
          </VStack>
        </HStack>
      </GridItem>
    )
  }

  return (
    <GridItem
      display={'flex'}
      maxH="135px"
      backgroundColor="#302F37"
      p="16px"
      {...rest}
    >
      <HStack spacing={'1rem'} alignItems={'center'} h="full">
        <CircularProgress
          size={'84px'}
          thickness="6px"
          capIsRound
          color={
            percentCorrectAnswers >= correct_answers_percentage
              ? '#0DDF15'
              : '#e5c009'
          }
          trackColor="#616167"
          my={{ base: '0px', sm: '16px' }}
          value={percentCorrectAnswers}
        >
          <CircularProgressLabel fontSize={'18px'}>
            <chakra.span>{percentCorrectAnswers.toFixed(0)}%</chakra.span>

            <Text fontWeight={400} fontSize={'12px'}>
              Acertos
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        <VStack align={'flex-start'}>
          <Text fontWeight={'700'} fontSize={'16px'}>
            Percentual de acerto
          </Text>

          {percentCorrectAnswers > 100 && (
            <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
              <chakra.span color={'#0ddf15'}>Sabe muuuito!! </chakra.span>
              Você ultrapassou a meta de{' '}
              <chakra.span color={'#0ddf15'}>
                {`${correct_answers_percentage}%}`}{' '}
              </chakra.span>
              de acerto dessa semana.
            </Text>
          )}

          {percentCorrectAnswers > 0 &&
            percentCorrectQuestionsToReachTheGoal === 0 && (
              <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                <chakra.span color={'#0ddf15'}>Excelente! </chakra.span>
                Você atingiu a meta de Percentual dessa semana
              </Text>
            )}

          {percentCorrectAnswers === 0 && (
            <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
              Atinja{' '}
              <chakra.span color={'#E56000'}>
                {' '}
                {`${correct_answers_percentage}% `}{' '}
              </chakra.span>
              de acerto para completar a meta dessa semana.
            </Text>
          )}

          {percentCorrectQuestionsToReachTheGoal > 0 &&
            percentCorrectQuestionsToReachTheGoal < 100 && (
              <Text fontWeight={'600'} fontSize={'13px'} color="#BDBDC7">
                Faltou pouco, a meta para o período selecionado foi de{' '}
                <chakra.span color={'#E56000'}>
                  {' '}
                  {`${correct_answers_percentage}% `}{' '}
                </chakra.span>
                de acertos
              </Text>
            )}
        </VStack>
      </HStack>
    </GridItem>
  )
}
