import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

async function ActivateRecoveryPlan() {
  await api.post('/app/recovery_plan')
}

export function useActivateRecoveryPlan() {
  return useMutation(ActivateRecoveryPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(['activated-recovery-plan'])
    },
  })
}
