import styled from 'styled-components'

import { Scroll, ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  ${ResponsiveContainer};

  overflow-y: scroll;

  ${Scroll}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 16px;

  display: grid;
  grid-template-columns: repeat(4, 182px);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 182px);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 182px);
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 182px);
  }
`

export const Chip = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 166px;
  height: 42px;

  margin-right: 16px;
  margin-bottom: 16px;

  border-radius: 10px;
  border-width: 1px;
  border-color: red;

  background: transparent;

  border: 1px solid rgba(255, 255, 255, 0.2);

  transition: all 0.4s;

  &:hover {
    border: 1px solid rgba(255, 107, 0, 0.4);
    background: rgba(255, 107, 0, 0.05);

    p {
      color: rgba(255, 255, 255, 0.45);
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
    background: #ff6b00;
    border: 1px solid #ff6b00;
    p {
      color: #ffffff;
    }
    &:hover{
      background: #ff6b00;
      border: 1px solid #ff6b00;
      p {
        color: #ffffff;
      }
    };
  `}
`

export const ChipText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);

  transition: all 0.4s;

  user-select: none;
`

export const ChartContainer = styled.div`
  height: 640px;
  min-height: 640px;
  width: 90%;
  position: relative;
`
