import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  overflow: hidden;

  max-width: 1480px;
  width: 100vw;

  max-height: 1024px;
  height: 100vh;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #302f37 0%,
    #1b1b1b 100%,
    #1b1b1b 100%
  );
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);

  /* Mobile */
  @media (max-width: 1100px) {
  }

  /* Latpot */
  @media (min-width: 1100px) {
  }

  /* Extra Large */
  @media (min-width: 1480px) {
    border-radius: 8px;
    max-height: min(calc(100vh - 64px), 1024px);
  }
`
