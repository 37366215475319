import React from 'react'

import { Center, Spinner, Text } from '@chakra-ui/react'

export function Loading() {
  return (
    <Center p="8">
      <Spinner mr="3" />
      <Text>Carregando...</Text>
    </Center>
  )
}
