import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from 'contexts/auth'
import { useTrack } from 'contexts/track'
import IncidenceTable from 'components/Table/IncidenceTable'
import { NewFieldSelect } from 'components/Fields/FieldDashboard'
import api from 'services/api'
import Layout from 'layouts/Logged'
import Header from 'components/Header'
import { useHistory } from 'react-router-dom'
import { useBreakpointValue, Modal, ModalOverlay, Flex } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import {
  TableHeaderSelectWrapper,
  TableHeaderSelect,
  TableHeaderSearch,
  TableHeaderWrapper,
  StyledBiSearch,
  Container,
  StyledModalContent,
  StyledModalHeader,
  StyledModalBody,
  ModalButtonWrapper,
  StyledInputWrapper,
} from './styles'
import { FieldRadio } from 'components/Fields/FieldRadio'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import FieldInput from 'components/Fields/FieldInput'
import { useDebounce } from 'hooks/use-debounce'
import {
  MetaNewIncidencesResponse,
  NewIncidencesApiResponse,
} from './interfaces'

type TableParams = Array<{ examYears: string }>

const FocusLearning = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { track } = useTrack()
  const { user, updateUserData } = useAuth()

  const ref = useRef<HTMLInputElement>(null)
  const [page, setPage] = useState(1)
  const [metaData, setMetadata] = useState<
    MetaNewIncidencesResponse | undefined
  >(undefined)
  const [search, setSearch] = useState('')
  const [fieldId, setFieldId] = useState('')
  const [userTermsRadio, setUserTermsRadio] = useState(false)
  const [userTerms, setUserTerms] = useState(true)
  const [userUpdated, setUserUpdated] = useState(false)
  const [institution, setInstitution] = useState('')
  const [tableData, setTableData] = useState<TableParams | undefined>([
    { examYears: '' },
  ])
  const [errorTrial, setErrorTrial] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()

  const debouncedSearch = useDebounce(search, 800)

  const incidenciesRequests = useCallback(async () => {
    const institutionName = `institution=${institution}`
    const fieldIdUrl = `&fieldId=${fieldId}`
    const searchTearm = `&themeName=${debouncedSearch}`

    try {
      const result = await api.get<NewIncidencesApiResponse>(
        `/app/focus_learning/themes_incidence?${institutionName}${fieldIdUrl}&limit=15&page=${page}${
          debouncedSearch && searchTearm
        }`,
      )

      setTableData(result.data.data)
      setMetadata(result.data.meta)
    } catch (error: any) {
      const message = error?.response?.data

      setTableData(undefined)
      setMetadata(undefined)
      setErrorTrial('')

      if (
        message?.includes(
          'Disponível somente para alunos Mentoria Residência. Garanta já seu acesso completo à plataforma.',
        )
      ) {
        setErrorTrial(message)
      }
    }
  }, [institution, fieldId, page, debouncedSearch])

  useEffect(() => {
    incidenciesRequests()
  }, [institution, fieldId, incidenciesRequests, page])

  useEffect(() => {
    const LoadInfo = async () => {
      await updateUserData()
      setUserUpdated(true)
    }
    if (!userUpdated) LoadInfo()
    setUserTerms(user?.profile?.accepted_incidence_table_terms!)
  }, [user, updateUserData, userUpdated])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getTrackOptions = () => {
    const greaterAreas: any = track?.fields.map(item => {
      return {
        value: item.id,
        label: item.name,
      }
    })

    greaterAreas && greaterAreas?.unshift({ value: '', label: 'Visão Geral' })
    return greaterAreas
  }

  const getIntitutions = () => {
    const institutions = user?.profile?.intended_institutions

    if (institution === '') setInstitution(institutions![0])

    return institutions?.map(item => {
      return {
        value: item,
        label: item,
      }
    })
  }

  const acceptTerms = async () => {
    await api.put('/app/users', {
      acceptedIncidenceTableTerms: true,
    })

    setUserTerms(true)
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header title="Incidência de assuntos" />

        <div>
          Tabela de incidências {track?.name} - {tableData?.[0]?.examYears}
        </div>

        {userTerms === false && (
          <Modal
            isCentered
            isOpen={true}
            size={'xs'}
            onClose={() => console.log(false)}
          >
            <ModalOverlay />
            <StyledModalContent>
              <StyledModalHeader>Atenção!</StyledModalHeader>
              <StyledModalBody>
                <p>
                  Esta obra pertence a plataforma Mentoria Residência. A
                  Violação de Direito Autoral é crime (art. 184 do Código
                  Penal).
                </p>
                <p>
                  Nos termos do inciso I do artigo 29 da Lei 9.610/1998, a
                  reprodução parcial ou total da obra de outros autores requer a
                  autorização expressa do autor ou titular dos direitos
                  autorais, mesmo que para fins didáticos e sem intuito
                  lucrativo.
                </p>
                <FieldRadio onChange={() => setUserTermsRadio(true)}>
                  Eu li e concordo com os termos e condições
                </FieldRadio>

                <ModalButtonWrapper>
                  <DefaultButton
                    variant="ghost"
                    width={'100%'}
                    label="Voltar"
                    onClick={() => history.push('/dashboard')}
                  />
                  <DefaultButton
                    disabled={!userTermsRadio}
                    width={'100%'}
                    label="Aceito"
                    onClick={() => acceptTerms()}
                  />
                </ModalButtonWrapper>
              </StyledModalBody>
            </StyledModalContent>
          </Modal>
        )}

        <TableHeaderWrapper>
          <TableHeaderSelectWrapper>
            <TableHeaderSelect>
              <NewFieldSelect
                options={getIntitutions()}
                placeholder="Digite ou escolha a instituição"
                isSearchable
                classNamePrefix={'custom'}
                defaultValue={getIntitutions()![0]}
                onChange={(option: { label: string; value: string }) => {
                  setPage(1)
                  setInstitution(option!.value)
                }}
              />
            </TableHeaderSelect>

            <TableHeaderSelect>
              <NewFieldSelect
                options={getTrackOptions()}
                placeholder="Digite ou escolha uma grande área"
                isSearchable
                classNamePrefix={'custom'}
                defaultValue={{ value: '', label: 'Visão Geral' }}
                onChange={(option: { label: string; value: string }) => {
                  setPage(1)
                  setFieldId(option!.value)
                }}
              />
            </TableHeaderSelect>
          </TableHeaderSelectWrapper>

          <TableHeaderSearch>
            <StyledInputWrapper>
              <FieldInput
                value={search}
                onChange={event => {
                  setPage(1)
                  setSearch(event.target.value)
                }}
                ref={ref}
                placeholder="Digite ou escolha o tema"
              />
              <StyledBiSearch onClick={() => ref?.current?.focus()} />
            </StyledInputWrapper>
          </TableHeaderSearch>
        </TableHeaderWrapper>

        {metaData?.isDefaultWeights && (
          <Text fontWeight={400} fontSize={'14px'} pt="20px">
            {
              'A instituição não possui classificação de temas. Abaixo prevalência geral das instituições. Dúvidas: +551150262846.'
            }
          </Text>
        )}

        {tableData && tableData?.length > 0 && (
          <IncidenceTable
            data={tableData || []}
            page={page}
            total={metaData?.total ?? 0}
            setNewPage={setPage}
          />
        )}

        {!tableData && !errorTrial && (
          <Text fontWeight={400} fontSize={'14px'} pt="20px">
            {'Nenhuma incidência encontrada.'}
          </Text>
        )}

        {!tableData && errorTrial && (
          <Text fontWeight={400} fontSize={'14px'} pt="20px">
            {errorTrial}
          </Text>
        )}
      </Container>
    </Layout>
  )
}

export default FocusLearning
