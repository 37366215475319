import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Award, Power } from 'react-feather'
import { getYear } from 'date-fns'
import Swal from 'sweetalert2'

// Contexts
import { useAuth } from 'contexts/auth'

import {
  HeaderSubTitle,
  NotificationContainer,
  NotificationText,
  ProfileContainer,
  ProfileAvatar,
  ProfileName,
  LogOutButton,
} from './styles'
import { Link } from 'react-router-dom'
import VideoOrientation from 'components/VideoOrientation'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import PopovoverNotifications from './Popovover'
import { useToastMessage } from 'components/Toast'
import { useGetUnviewedNotificationsCount } from 'features/notifications/mutations/use-get-unviewed-notifications-count'

interface HeaderProps {
  title: string
  subTitle?: string
  hasVideoOrientation?: boolean
  linkVideo?: string
}

const Header: React.FC<HeaderProps> = ({
  title,
  subTitle,
  linkVideo,
  hasVideoOrientation,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { logOut, user } = useAuth()
  const { data: unviewedNotificationCount } = useGetUnviewedNotificationsCount()
  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, lg: false })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      html:
        '<p style="text-align:center">Você realmente deseja fazer logout?</p>',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  /*
  |-----------------------------------------------------------------------------
  | Memo.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const avatarEndpoint = useMemo(() => {
    if (!user) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${user.name.replace(/' '/g, '')}`
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      flexDir={{ base: 'column-reverse', lg: 'row' }}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
      w="full"
      gap="0.5rem"
    >
      <Flex flexDir="column">
        <Flex
          fontStyle="normal"
          fontWeight="bold"
          fontSize="30px"
          opacity="0.9"
          marginBottom="4px"
          maxW="510px"
          w="auto"
          alignItems="flex-start"
        >
          {title}{' '}
          {hasVideoOrientation && (
            <VideoOrientation
              linkVideo={linkVideo}
              ml={hasVideoOrientation ? '10px' : '0px'}
            />
          )}
        </Flex>

        <HeaderSubTitle>{subTitle}</HeaderSubTitle>
      </Flex>

      {user?.profile?.trial_year === getYear(new Date()) - 1 && isMobile && (
        <NotificationContainer as={Link} to="/account?tab=approval">
          <Award size={14} />
          <NotificationText>
            Compartilhe conosco a sua aprovação!
          </NotificationText>
        </NotificationContainer>
      )}

      <Flex
        flexDir="column"
        alignItems="flex-end"
        rowGap="16px"
        alignSelf={{ base: 'end', lg: 'center' }}
      >
        <Flex alignItems={'center'} gap="1rem">
          <>
            <PopovoverNotifications
              popoverContentProps={{
                w: { base: '90vw', sm: '450px' },
                p: '15px',
              }}
              unviewedNotificationCount={
                unviewedNotificationCount?.total_notifications || 0
              }
            />

            {user?.profile?.trial_year === getYear(new Date()) - 1 &&
              !isMobile && (
                <NotificationContainer as={Link} to="/account?tab=approval">
                  <Award size={14} />
                  <NotificationText>
                    Compartilhe conosco a sua aprovação!
                  </NotificationText>
                </NotificationContainer>
              )}

            <ProfileContainer>
              <ProfileAvatar
                src={user?.avatar?.url || avatarEndpoint}
                alt="avatar"
              />
              <ProfileName>{user?.name}</ProfileName>
            </ProfileContainer>

            <LogOutButton onClick={confirmLogOut}>
              <Power size={20} strokeWidth={1} />
            </LogOutButton>
          </>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
