import React from 'react'
import {
  chakra,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  GridItem,
  GridItemProps,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { MajorStatsProps } from '..'

type TheoreticalPracticalProps = Pick<
  MajorStatsProps,
  | 'percentStudiedMinutesPractical'
  | 'percentStudiedMinutesTheorycal'
  | 'totalStudiedHoursPratical'
  | 'totalStudiedHoursTheorycal'
> &
  GridItemProps

export default function TheoreticalPracticalMobile(
  props: TheoreticalPracticalProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    percentStudiedMinutesPractical,
    percentStudiedMinutesTheorycal,
    totalStudiedHoursPratical,
    totalStudiedHoursTheorycal,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <GridItem
      display={'flex'}
      maxH="135px"
      backgroundColor="#302F37"
      p="16px"
      {...rest}
    >
      <HStack spacing={'1rem'}>
        <CircularProgress
          size={'84px'}
          thickness="6px"
          capIsRound
          color={percentStudiedMinutesPractical >= 70 ? '#0DDF15' : '#e5c009'}
          trackColor="#616167"
          my={{ base: '0px', sm: '16px' }}
          value={percentStudiedMinutesPractical}
        >
          <CircularProgressLabel>
            <chakra.span
              fontWeight={600}
              fontSize={'18px'}
            >{`${percentStudiedMinutesPractical.toFixed(0)}%`}</chakra.span>

            <Text fontWeight={400} fontSize={'12px'}>
              Prática
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        <VStack align={'flex-start'}>
          <Text fontWeight={'700'} fontSize={'16px'}>
            Teórico x Prática
          </Text>

          <Flex alignItems="center" gap="8px">
            <Circle
              bg={percentStudiedMinutesPractical >= 70 ? '#0DDF15' : '#e5c009'}
              fill={
                percentStudiedMinutesPractical >= 70 ? '#0DDF15' : '#e5c009'
              }
              size="8px"
            />

            <Flex gap={'4px'}>
              <Text fontWeight="600" fontSize={'12px'} color="#FFFFFF">
                {totalStudiedHoursPratical}h
              </Text>

              <Text fontWeight="400" fontSize={'12px'} color="#BDBDC7">
                prática
              </Text>
            </Flex>
          </Flex>

          <Flex alignItems="center" gap="8px">
            <Circle bg="#BDBDC7" fill="#BDBDC7" size="8px" />

            <Flex gap={'4px'}>
              <Text fontWeight="600" fontSize={'12px'} color="#FFFFFF">
                {totalStudiedHoursTheorycal}h
              </Text>

              <Text fontWeight="400" fontSize={'12px'} color="#BDBDC7">
                teórica
              </Text>
            </Flex>
          </Flex>
        </VStack>
      </HStack>
    </GridItem>
  )
}
