/* eslint-disable quotes */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
  Link,
  Stack,
  VStack,
  HStack,
  IconButton,
  useBreakpointValue,
  Flex,
  ModalFooter,
} from '@chakra-ui/react'
import { capitalize, get } from 'lodash'
import { useUnconcludeAgenda } from '../mutations/use-unconclude-agenda'
import { useDeleteAgenda } from '../mutations/use-delete-agenda'
import { useRescheduleAgenda } from '../mutations/use-reschedule-agenda'
import { useUpdateNotesAgenda } from '../mutations/use-update-notes-agenda'
import { GetAgenda } from '../queries/use-get-agendas'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useToastMessage } from 'components/Toast'
import { ModalConcludeStudies } from '../modal-conclude-studie'
import { TextAreaField } from 'components/Fields/TextArea'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useAuth } from 'contexts/auth'

interface ModalSmartReviewProps {
  isOpen: boolean
  onClose: () => void
  agenda?: GetAgenda
  backOnClickFunc: () => void
}

interface ModalBodyProps {
  agenda: GetAgenda
}

export function ModalSmartReview({
  isOpen,
  agenda,
  backOnClickFunc,
  onClose,
}: ModalSmartReviewProps) {
  // STATES:
  const [notes, setNotes] = useState('')
  const isMd = useBreakpointValue({ base: false, md: true })
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [confirmRescheduledStudies, setConfirmRescheduledStudies] = useState(
    false,
  )
  const [confirmRemoveStudies, setConfirmRemoveStudies] = useState(false)
  const [modalConcludeStudies, setModalConcludeStudies] = useState(false)
  const [modalUnconcludedStudies, setModalUnconcludedStudies] = useState(false)
  // HOOKS:
  const showToast = useToastMessage()

  //CONSTS
  const stageEnv = process.env.REACT_APP_QA
  const productionEnv = process.env.REACT_APP_PROD

  // MUTATIONS:
  const deleteAgenda = useDeleteAgenda()
  const unconcludeAgenda = useUnconcludeAgenda()
  const rescheduleAgenda = useRescheduleAgenda()
  const updateNotesAgenda = useUpdateNotesAgenda()
  const { loadTokenFromStorage } = useAuth()

  // HANDLES:
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleUnconcludeAgenda = useCallback(async () => {
    if (!agenda) return

    handleClose()
    setModalUnconcludedStudies(true)
  }, [agenda, handleClose])

  const UnconcludeAgenda = async () => {
    if (!agenda) return
    try {
      await unconcludeAgenda.mutateAsync({ agendaId: agenda.id })
      showToast({
        title: 'Sucesso',
        description: 'Estudo marcado como não concluído.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error) {
      showToast({
        title: 'Aviso',
        description:
          'Infelizmente não conseguimos marcar o estudo como não concluído.',
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    } finally {
      setModalUnconcludedStudies(false)
    }
  }

  const handleRemoveAgenda = useCallback(async () => {
    if (!agenda) return

    try {
      handleClose()
      await deleteAgenda.mutateAsync({ agendaId: agenda.id })

      showToast({
        title: 'Sucesso',
        description: 'Estudo removido',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch {
      showToast({
        title: 'Aviso',
        description: 'Não foi possível remover a agenda',
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }, [agenda, deleteAgenda, handleClose, showToast])

  const handleRescheduleAgenda = useCallback(async () => {
    if (!agenda) return

    try {
      await rescheduleAgenda.mutateAsync({ agendaId: agenda.id })
      handleClose()
      showToast({
        title: 'Sucesso',
        description: 'Estudo reagendado.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch {
      showToast({
        title: 'Aviso',
        description: 'Não foi possível remover a agenda.',
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    } finally {
      setConfirmRescheduledStudies(false)
    }
  }, [agenda, handleClose, rescheduleAgenda, showToast])

  const handleUpdateNotesAgenda = useCallback(
    async (inputNote: string) => {
      if (!agenda) return

      try {
        await updateNotesAgenda.mutateAsync({
          query: { agendaId: agenda.id },
          data: { notes: inputNote },
        })

        showToast({
          title: 'Sucesso',
          description: 'Anotações salvas.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch {
        showToast({
          title: 'Aviso',
          description: 'Não foi possível salvar as anotaçães.',
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [agenda, showToast, updateNotesAgenda],
  )

  // MEMOS:
  const allSubjectAlreadyDone = useMemo(() => {
    if (!agenda) return false
    if (agenda?.subjects?.length === 0) return false

    return agenda?.subjects?.every(subject => subject.is_already_done)
  }, [agenda])

  // EFFECTS:
  useEffect(() => {
    setNotes(agenda?.notes || '')
  }, [agenda])

  // RENDERS:
  if (!agenda) return null

  const { metadata, type } = agenda

  let modalBodyShow = <></>

  // events feat
  if (type === 'evento') {
    modalBodyShow = <ModalBodyEvents agenda={agenda} />
  }

  // project-x / default
  if (metadata) {
    if (agenda.font === 'projeto-x') {
      modalBodyShow = <ModalBodyProjectX agenda={agenda} />
    } else {
      modalBodyShow = <ModalBodyDefault agenda={agenda} />
    }
  }

  if (agenda.themes?.[0].medcel_exam && agenda?.type === 'estudo-pratico') {
    modalBodyShow = <ModalBodyMedcel agenda={agenda} />
  }

  const revisionNumber = get(agenda.metadata, 'revisionNumber', '')
  const parserRevisionNumber = revisionNumber !== '' ? `${revisionNumber}` : ''

  let HeaderTitle = <></>

  if (type === 'evento') {
    HeaderTitle = <Text>{agenda?.title}</Text>
  }

  if (
    metadata &&
    agenda.type === 'primeiro-contato' &&
    agenda.font === 'estudo-pendente'
  ) {
    HeaderTitle = (
      <Text>{`Primeiro Contato ${
        Number(revisionNumber) >= 1 ? ` - ${revisionNumber}` : ''
      }`}</Text>
    )
  }

  if (agenda.font === 'estudo-diario' && !!agenda?.themes?.[0]?.medcel_exam) {
    HeaderTitle = (
      <Text>{`C-TS - ${capitalize(agenda?.metadata?.field)} ${
        parserRevisionNumber ? ` - ${parserRevisionNumber}` : ''
      }`}</Text>
    )
  }

  if (
    metadata &&
    agenda.type === 'primeiro-contato' &&
    agenda.font === 'estudo-diario' &&
    !agenda?.themes?.[0]?.medcel_exam
  ) {
    HeaderTitle = <Text>{`Revisão Inteligente - ${parserRevisionNumber}`}</Text>
  }

  if (metadata && agenda.type === 'estudo-teorico') {
    HeaderTitle = <Text>{`Estudo Teórico - ${parserRevisionNumber}`}</Text>
  }

  if (
    metadata &&
    agenda.font === 'projeto-x' &&
    agenda.type !== 'primeiro-contato'
  ) {
    HeaderTitle = <Text>{`Revisão X - ${parserRevisionNumber}`}</Text>
  }

  if (
    metadata &&
    agenda.font === 'projeto-x' &&
    agenda.type === 'primeiro-contato'
  ) {
    HeaderTitle = <Text>{'Primeiro Contato X'}</Text>
  }

  // estudo - teorico
  // primeiro - contato

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent
          p="0px"
          background={'#302F37'}
          maxW={isMd ? '544px' : '340px'}
          w="100%"
          borderRadius={'8px'}
          overflow={'hidden'}
          transition={'all 0.2s ease-in-out'}
        >
          <ModalHeader
            background={'#2A2A30'}
            borderBottom={
              agenda.isDone
                ? '2px solid #0DDF15'
                : agenda.type === 'evento'
                ? '2px solid #2985F0'
                : '2px solid #E5C009'
            }
            color={'#FFFF'}
            p="12px 16px"
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {HeaderTitle}
            <ModalCloseButton
              right={'unset'}
              top={'unset'}
              color={'#FFFF'}
              position={'relative'}
              _focus={{ outline: 'none' }}
            />
          </ModalHeader>

          <ModalBody
            color={'#FFFF'}
            background={'#302F37'}
            maxW={isMd ? '544px' : '340px'}
            w="100%"
            p="0px"
          >
            {modalBodyShow}

            {agenda.type !== 'evento' && (
              <>
                <Stack spacing={'0px'}>
                  <VStack alignItems="flex-end" px="1rem">
                    <TextAreaField
                      placeholder="Anotações"
                      value={notes}
                      maxLength={800}
                      defaultValue={notes}
                      onChange={e => setNotes(e.target.value)}
                    />

                    <HStack
                      align={'flex-start'}
                      w="100%"
                      justifyContent={'space-between'}
                    >
                      <Text color={'#BDBDC7'} fontSize={'12px'}>
                        Até 800 caracteres
                      </Text>

                      <DefaultButton
                        size="sm"
                        maxH="31px"
                        fontSize={'12px'}
                        fontWeight={'500'}
                        isLoading={updateNotesAgenda.isLoading}
                        onClick={() => handleUpdateNotesAgenda(notes)}
                        label="Salvar Notas"
                      />
                    </HStack>
                  </VStack>
                </Stack>

                <HStack px="1rem">
                  <IconButton
                    aria-label="Rescheduled"
                    onClick={() => {
                      onClose()
                      setConfirmRescheduledStudies(true)
                    }}
                    background={'#E56000'}
                    borderRadius={'4px'}
                    h="30px"
                    minW="30px"
                    _hover={{
                      background: '#e55f00e1',
                    }}
                    icon={
                      <Icon
                        color="#FFFF"
                        fontSize={'16px'}
                        icon={'eva:calendar-outline'}
                      />
                    }
                  />

                  <IconButton
                    aria-label="remove"
                    onClick={() => {
                      onClose()
                      if (agenda.isDone && agenda.font === 'projeto-x') {
                        return setModalConfirmDelete(true)
                      }

                      if (agenda.isDone) {
                        return setModalConfirmDelete(true)
                      }

                      setConfirmRemoveStudies(true)
                    }}
                    background={'#E56000'}
                    borderRadius={'4px'}
                    h="30px"
                    minW="30px"
                    _hover={{
                      background: '#e55f00e1',
                    }}
                    icon={
                      <Icon color="#FFFF" fontSize={'16px'} icon={'ph:trash'} />
                    }
                  />
                </HStack>
              </>
            )}
          </ModalBody>

          <ModalFooter p="0px" background={'#302F37'}>
            <Flex
              display={'flex'}
              flexDir={'column'}
              gap="10px"
              p="24px 16px 24px 16px"
              w="full"
            >
              {agenda.type !== 'evento' && (
                <>
                  {agenda.isDone ? (
                    <DefaultButton
                      w="full"
                      variant="secondary"
                      onClick={handleUnconcludeAgenda}
                      isLoading={unconcludeAgenda.isLoading}
                      label="Não concluído"
                    />
                  ) : (
                    <DefaultButton
                      w="full"
                      variant="secondary"
                      onClick={() => {
                        handleClose()
                        setModalConcludeStudies(true)
                      }}
                      label="Concluir"
                    />
                  )}
                </>
              )}

              {agenda?.themes?.[0]?.medcel_exam && (
                <DefaultButton
                  w="full"
                  variant="secondary"
                  onClick={() => {
                    function resetNumber(num: number) {
                      // revision number starts at 0
                      let number = num + 1

                      while (number > 5) {
                        number -= 5
                      }
                      return number
                    }

                    const revisionNumber = resetNumber(
                      Number(agenda?.metadata?.revisionNumber),
                    )

                    if (stageEnv) {
                      window.open(
                        `https://staging-areaaluno.medcel.com.br/sso?token=${loadTokenFromStorage()}&partner=alemdamedicina&path=/produto/6388e7ca41256700085062e8&examSlug=${
                          agenda?.themes?.[0]?.medcel_exam
                        }-${revisionNumber}`,
                      )
                      return
                    }

                    if (productionEnv) {
                      window.open(
                        `https://areaaluno.medcel.com.br/sso?token=${loadTokenFromStorage()}&partner=alemdamedicina&path=/produto/6388e7ca41256700085062e8&examSlug=${
                          agenda?.themes?.[0]?.medcel_exam
                        }-${revisionNumber}`,
                      )
                    }
                  }}
                  margin={'0px'}
                  label="Fazer o simulado"
                />
              )}

              {agenda.type === 'evento' && (
                <Link
                  display={agenda?.url_link ? 'inline-flex' : 'none'}
                  target="_blank"
                  textDecoration="none !important"
                  rel="noopener noreferrer"
                  p="0px 16px 24px 16px"
                  w="full"
                  href={agenda?.url_link}
                >
                  <DefaultButton
                    w="full"
                    textDecoration="none !important"
                    variant="secondary"
                    label="Entrar no Evento"
                  />
                </Link>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalDefaultTwoButton
        isOpen={modalConfirmDelete}
        size="sm"
        onClose={() => setModalConfirmDelete(false)}
        onConfirmClick={async () => {
          await handleRemoveAgenda()
          setModalConfirmDelete(false)
        }}
        confirmButtonIsLoading={deleteAgenda.isLoading}
        title={'Tem certeza que deseja remover o estudo da agenda?'}
        subTitle={
          agenda.font === 'projeto-x'
            ? 'Se continuar, essa revisão X já concluída será encaminhada para as revisões X pendentes e as revisões geradas à partir desse estudo serão excluídas.'
            : 'Se continuar, esse estudo concluído será encaminhado para os estudos pendentes e as revisões geradas à partir desse estudo serão excluídas definitivamente.'
        }
      />

      <ModalDefaultTwoButton
        size="sm"
        isOpen={confirmRemoveStudies}
        onClose={() => setConfirmRemoveStudies(false)}
        onConfirmClick={async () => {
          await handleRemoveAgenda()
          setConfirmRemoveStudies(false)
        }}
        confirmButtonIsLoading={deleteAgenda.isLoading}
        title={'Tem certeza que deseja remover o estudo?'}
        subTitle={'Se continuar, o estudo será encaminhado para a lixeira.'}
        isVerticalButton
      />

      <ModalDefaultTwoButton
        size="sm"
        isOpen={confirmRescheduledStudies}
        onClose={() => setConfirmRescheduledStudies(false)}
        onConfirmClick={handleRescheduleAgenda}
        confirmButtonIsLoading={rescheduleAgenda.isLoading}
        title={'Tem certeza que deseja reagendar?'}
        subTitle={'Se continuar, o estudo será reagendado para daqui 15 dias.'}
        isVerticalButton
      />

      <ModalConcludeStudies
        isOpen={modalConcludeStudies}
        onClose={() => setModalConcludeStudies(false)}
        agenda={agenda}
        backOnClickFunc={backOnClickFunc}
      />

      <ModalDefaultTwoButton
        size="sm"
        isOpen={modalUnconcludedStudies}
        onClose={() => setModalUnconcludedStudies(false)}
        onConfirmClick={UnconcludeAgenda}
        confirmButtonIsLoading={unconcludeAgenda.isLoading}
        title={'Tem certeza que deseja colocar o estudo como não concluído?'}
        subTitle={
          'Esta ação poderá remover revisões já realizadas. Deseja continuar?'
        }
        isVerticalButton
      />
    </>
  )
}

function ModalBodyProjectX({ agenda }: ModalBodyProps) {
  const { isDone, metadata, themes, subjects } = agenda

  return (
    <VStack w="100%" p="16px 16px 24px 16px" align={'flex-start'}>
      <Stack spacing={'2px'}>
        <Text color={'#BDBDC7'} fontSize={'16px'}>
          Grande área
        </Text>

        <Text color={isDone ? '#0DDF15' : '#E5C009'} fontSize={'16px'}>
          {capitalize(metadata.field)}
        </Text>
      </Stack>

      {themes && themes.length > 0 && (
        <Stack spacing={'2px'}>
          <Text color={'#BDBDC7'} fontSize={'16px'}>
            Tema
          </Text>

          {themes?.map(item => {
            return (
              <Text
                key={`theme-${item?.id}`}
                color={isDone ? '#0DDF15' : '#E5C009'}
                fontSize={'16px'}
              >
                {capitalize(item?.name)}
              </Text>
            )
          })}
        </Stack>
      )}
    </VStack>
  )
}

function ModalBodyDefault({ agenda }: ModalBodyProps) {
  const { themes, metadata, isDone, subjects } = agenda

  return (
    <VStack spacing={'16px'} p="16px 16px 24px 16px" align={'flex-start'}>
      <Stack spacing={'2px'}>
        <Text color={'#BDBDC7'} fontSize={'16px'}>
          Grande área
        </Text>

        <Text color={isDone ? '#0DDF15' : '#E5C009'} fontSize={'16px'}>
          {capitalize(metadata?.field)}
        </Text>
      </Stack>

      {themes && themes?.length > 0 && (
        <Stack spacing={'2px'}>
          <Text color={'#BDBDC7'} fontSize={'16px'}>
            Tema
          </Text>

          {themes?.map(item => {
            return (
              <Text
                key={`theme-${item?.id}`}
                color={isDone ? '#0DDF15' : '#E5C009'}
                fontSize={'16px'}
              >
                {capitalize(item?.name)}
              </Text>
            )
          })}
        </Stack>
      )}

      {subjects && subjects?.length > 0 && (
        <Stack spacing={'2px'}>
          <Text color={'#BDBDC7'} fontSize={'16px'}>
            Subtema
          </Text>

          {subjects?.map(item => {
            return (
              <Text
                key={`theme-${item?.id}`}
                color={isDone ? '#0DDF15' : '#E5C009'}
                fontSize={'16px'}
              >
                {capitalize(item?.name)}
              </Text>
            )
          })}
        </Stack>
      )}
    </VStack>
  )
}

function ModalBodyEvents({ agenda }: ModalBodyProps) {
  const { start, description, hostUser, recorded_meeting, url_link } = agenda
  return (
    <VStack spacing={'16px'} p="16px 16px 24px 16px" align={'flex-start'}>
      {start && (
        <HStack gap="4px" alignItems="center">
          <Icon icon={'lets-icons:calendar'} fontSize={'12px'} color="white" />
          <Text fontWeight={600}>
            {format(start, "dd/MM/yyyy, 'às' HH:mm.", { locale: ptBR })}
          </Text>
        </HStack>
      )}
      {description && (
        <Text fontSize={'14px'} color="#BDBDC7">
          {description}
        </Text>
      )}
      {hostUser?.name && (
        <VStack alignItems="flex-start" spacing="2px">
          <Text fontWeight={400} color="#BDBDC7">
            Evento por:
          </Text>
          <Text color="#F2B487">{hostUser?.name}</Text>
        </VStack>
      )}
      {recorded_meeting && (
        <VStack alignItems="flex-start" spacing="2px">
          <Text fontWeight={400} color="#BDBDC7">
            Aula será gravada:
          </Text>
          <Text color="#F2B487">{recorded_meeting ? 'Sim' : 'Não'}</Text>
        </VStack>
      )}
      {url_link && (
        <VStack alignItems="flex-start" spacing="2px">
          <Text fontWeight={400} color="#BDBDC7">
            Link do evento:
          </Text>
          <Text color="#F2B487">{url_link}</Text>
        </VStack>
      )}
    </VStack>
  )
}

function ModalBodyMedcel({ agenda }: ModalBodyProps) {
  const { isDone, themes, metadata, subjects } = agenda
  return (
    <VStack spacing={'16px'} p="16px 16px 24px 16px" align={'flex-start'}>
      <Stack spacing={'2px'}>
        <Text color={'#BDBDC7'} fontSize={'16px'}>
          Grande área
        </Text>

        <Text color={isDone ? '#0DDF15' : '#E5C009'} fontSize={'16px'}>
          {capitalize(metadata?.field)}
        </Text>
      </Stack>

      {themes && themes?.length > 0 && (
        <Stack spacing={'2px'}>
          <Text color={'#BDBDC7'} fontSize={'16px'}>
            Tema
          </Text>

          {themes?.map(item => {
            return (
              <Text
                key={`theme-${item?.id}`}
                color={isDone ? '#0DDF15' : '#E5C009'}
                fontSize={'16px'}
              >
                {capitalize(item?.name)}
              </Text>
            )
          })}
        </Stack>
      )}

      {subjects && subjects?.length > 0 && (
        <Stack spacing={'2px'}>
          <Text color={'#BDBDC7'} fontSize={'16px'}>
            Subtema
          </Text>

          {subjects?.map(item => {
            return (
              <Text
                key={`theme-${item?.id}`}
                color={isDone ? '#0DDF15' : '#E5C009'}
                fontSize={'16px'}
              >
                {capitalize(item?.name)}
              </Text>
            )
          })}
        </Stack>
      )}
    </VStack>
  )
}
