import React from 'react'
import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { Icon, IconifyIcon } from '@iconify/react'

export interface LabelStepVideoProps extends FlexProps {
  number?: number
  onClick?: any
  videoText?: string
  isActiveVideo?: boolean
  icon?: IconifyIcon | string
}

export function LabelStepVideo({
  videoText,
  number,
  onClick,
  isActiveVideo,
  icon = 'mdi:video-outline',
  ...rest
}: LabelStepVideoProps) {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      borderRadius="8px"
      background={isActiveVideo ? '#393940' : '#484851'}
      maxH="61px"
      w="100%"
      h="100%"
      cursor={'pointer'}
      onClick={onClick}
      padding={'10px 16px 10px 12px'}
      justifyContent="space-between"
      align={'center'}
      {...rest}
    >
      <Flex justifyContent={'space-between'} w="full">
        <Flex pl="10px" gap="20px" alignItems={'center'} text>
          <Text fontWeight="600" fontSize="12px" lineHeight="15px">
            {number && number < 10 ? `0${number}` : `${number}`}
          </Text>
          <Text
            fontWeight="600"
            fontSize="12px"
            lineHeight="15px"
            color="#FFFFFF"
          >
            {videoText}
          </Text>
        </Flex>

        <Flex w="fit-content">
          <Icon icon={icon} fontSize={'24px'} color="#E56000" />
        </Flex>
      </Flex>
    </Flex>
  )
}
