import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from 'services/api'

export interface GetDashboardInfoResponse {
  mainChartData: MainChartData[]
  trackGoal: TrackGoal
  indicatorsData: IndicatorsData
  isFragmentedWeek: boolean
}

export interface MainChartData {
  date_slice: string
  number_of_questions: string
  number_of_correct_answers: string
  effort_perception: string
  studied_minutes: string
  is_theoretical_only: boolean
}

interface TrackGoal {
  id: number
  correct_answers_percentage: string
  number_of_questions: number
  study_minutes: number
  year: number
  month: number
  track_id: number
  created_at: string
  updated_at: string
  trial_year: number
}

export interface IndicatorsData {
  percentCorrectAnswers: number
  percentCorrectQuestionsToReachTheGoal: number
  percentStudiedHoursReached: number
  percentRemainingHoursToReachTheGoal: number
  percentTotalQuestions: number
  totalQuestions: number
  totalQuestionGoal: number
  percentStudiedMinutesPractical: number
  percentStudiedMinutesTheorycal: number
  totalStudiedHoursPratical: number
  totalStudiedHoursTheorycal: number
  totalMinutesToReachTheGoal: number
  correctAnswersByField: CorrectAnswersByField[]
}

export interface CorrectAnswersByField {
  field_name: string
  number_of_questions: number
  number_of_correct_answers: number
}

type Params = {
  periodType: 'WEEKLY' | 'MONTHLY' | 'YEARLY'
  weekNumber?: number
  monthNumber?: number
  yearNumber?: number
  fieldId?: number
  subjectId?: number
  themeId?: number
}

export async function getDashboardInfo(
  params: Params,
): Promise<GetDashboardInfoResponse> {
  const { data } = await api.get<GetDashboardInfoResponse>('/app/dashboard', {
    params,
  })

  return data
}

export function useGetDashboardInfo(params: Params) {
  return useQuery<GetDashboardInfoResponse, AxiosError>(
    ['get-dashboard-info', params],
    () => getDashboardInfo(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
