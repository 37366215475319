import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;

  border-radius: 10px;

  padding-top: 24px;

  /* Mobile */
  @media (min-width: 600px) {
    align-items: center;
  }
`

export const TrackSection = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormTitle = styled.p`
  margin-bottom: 64px;

  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2px;

  span {
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme: { colors } }) => colors.orange};
  }
`

export const SecondStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  > p {
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    margin-bottom: 36px;

    span {
      font-style: normal;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #ff6b00;
    }
  }

  > div {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
  }

  > div:last-child {
    a {
      color: #fff;
      margin-top: 8px;
    }
  }
`
