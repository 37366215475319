import styled from 'styled-components'
import { ResponsiveContainer, Scroll } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  background: linear-gradient(71.48deg, #1d1d1f 0%, #28272d 98.51%);

  ${ResponsiveContainer};

  overflow-y: auto;

  ${Scroll}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
