import { addMinutes, format, subMinutes } from 'date-fns'

export function formatTimer(minutesRaw: number) {
  const hours = Math.floor(minutesRaw / 60)
  const minutes = minutesRaw % 60

  return `${hours}h ${String(minutes).padStart(2, '0')}m`
}

export function formatDateUTC(
  date: string,
  pattern = 'dd/MM/yyyy',
  locale?: Locale,
): string {
  const dateString = new Date(date)

  const diferrenceBetweenDatesUTC = dateString.getTimezoneOffset()

  const dateUTC = addMinutes(dateString, diferrenceBetweenDatesUTC)

  return format(dateUTC, pattern, { locale: locale })
}

export function formatDateAddTimeZoned(date: string) {
  const dateString = new Date(date)

  const diferrenceBetweenDatesUTC = dateString.getTimezoneOffset()

  const dateUTC = addMinutes(dateString, diferrenceBetweenDatesUTC)

  return dateUTC
}

export function formatDateWithTimeZonedHours(date: Date) {
  const diferrenceBetweenDatesUTC = date.getTimezoneOffset()

  const dateUTC = addMinutes(date, diferrenceBetweenDatesUTC)

  return dateUTC
}

export function formatDateAndRemoveTimeZone(date: Date) {
  const diferrenceBetweenDatesUTC = date.getTimezoneOffset()

  const dateUTC = subMinutes(date, diferrenceBetweenDatesUTC)

  return dateUTC
}
