import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { HttpClient } from '@sentry/integrations'
import AppContextProvider from 'contexts'
import * as SentryBrowser from '@sentry/browser'
import App from 'routes'
import GlobalStyles from 'styles/global'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'styles/chakra-theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SidebarProvider } from 'contexts/sidebar'
import { Breadcrumb } from '@sentry/react'

let ignoreError = false
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://17c2e9189fcb428b83445ecb1ce151cb@o1055602.ingest.sentry.io/6622464',
    integrations: [
      new HttpClient({ failedRequestStatusCodes: [500, 501] }),
      new SentryBrowser.BrowserTracing(),
    ],
    ignoreErrors: ['401', 'Request failed with status code 401'],
    allowUrls: [
      'aluno.mentoriaresidencia.com.br',
      'api.mentoriaresidencia.com.br',
    ],
    beforeBreadcrumb: (breadcrumb: Breadcrumb) => {
      if (
        // eslint-disable-next-line no-constant-condition
        breadcrumb &&
        breadcrumb.data &&
        breadcrumb.data.status_code &&
        breadcrumb.data.status_code == 401
      ) {
        ignoreError = true
      } else {
        ignoreError = false
      }

      return breadcrumb
    },
    beforeSend: (event, hint) => {
      if (ignoreError) {
        return null
      }

      return event
    },
  })
}

export const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <SidebarProvider>
            <AppContextProvider>
              <GlobalStyles />
              <App />
            </AppContextProvider>
          </SidebarProvider>
        </ChakraProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
