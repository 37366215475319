import React, { useMemo, useState } from 'react'
import Layout from 'layouts/Logged'
import { Container } from './styles'
import Header from 'components/Header'
import { Flex, VStack, AspectRatio, useBreakpointValue } from '@chakra-ui/react'
import {
  fifthStepData,
  Tracks,
} from 'features/onboard/Purchase/data/fifthStepData'
import { useAuth } from 'contexts/auth'
import { useTrack } from 'contexts/track'
import { Modules, VideoData } from 'components/VideoPlaylistWithModules/Modules'

export const VideoOnboarding: React.FC = () => {
  /*
 |-----------------------------------------------------------------------------
 | Hooks.
 |-----------------------------------------------------------------------------
 |
 |
 */

  const { user } = useAuth()
  const { track } = useTrack()
  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
 |-----------------------------------------------------------------------------
 | Memo.
 |-----------------------------------------------------------------------------
 |
 |
 */

  const welcomeVideos = useMemo(() => {
    return [
      {
        videoTitle: 'Boas vindas',
        videoUrl:
          'https://www.youtube.com/embed/gKRNmCbHFO4?si=OxemrsspSzpk8myH',
      },
      {
        videoTitle: 'Plataforma de Métricas',
        videoUrl:
          'https://www.youtube.com/embed/_na91MUyO3A?si=Yx8oxyMYb-EgifuR',
      },
      {
        videoTitle: 'Jornada Mentoria e Residência',
        videoUrl:
          'https://www.youtube.com/embed/JU3p1ZzLDs4?si=LQxRo80YYX-2BRl_',
      },
      {
        videoTitle: 'Equipe Mentoria',
        videoUrl:
          'https://www.youtube.com/embed/f5SDdbx35X8?si=ya3jICmuiOCezldE',
      },
      {
        videoTitle: 'Reuniões mentoria',
        videoUrl:
          'https://www.youtube.com/embed/OjbF_dB1sV0?si=abU9Nm0GySa6mmyU',
      },
    ]
  }, [])

  const getVideosByTrack = useMemo(() => {
    const trackId = track?.name
    const selectedYear = user?.profile?.trial_year

    if (!trackId || !selectedYear) {
      return
    }

    const videosByTrackAndSelectedYear = fifthStepData(
      trackId as Tracks,
    ).filter(item => item.year.includes(selectedYear))

    return videosByTrackAndSelectedYear?.[0]?.videos
  }, [track?.name, user?.profile?.trial_year])

  const videoData: VideoData = useMemo(() => {
    return [
      {
        moduleName: 'Boas-vindas',
        videos: welcomeVideos,
        docs: [],
        externalUrls: [],
      },
      {
        moduleName: 'Metodologia de estudo',
        videos:
          getVideosByTrack?.map(video => {
            return {
              videoTitle: video.title,
              videoUrl: video.url,
            }
          }) || [],
        docs: [],
        externalUrls: [],
      },
    ]
  }, [getVideosByTrack, welcomeVideos])

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [activeVideo, setActiveVideo] = useState(welcomeVideos?.[0]?.videoUrl)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getVideos = (videos: string) => {
    setActiveVideo(videos)
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Video Onboarding"
          subTitle="Assista seu vídeo do onboarding de acordo com sua trilha e ano de prova"
        />

        <Flex
          justifyContent={{ base: 'center', lg: 'unset' }}
          my="auto"
          align={{ base: 'center', lg: 'unset' }}
          gap={{ base: '0px', sm: '34px' }}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            maxW={'870px'}
            w="100%"
            maxH={{ base: 'fit-content', sm: '516px' }}
            h={{ base: 'fit-content', sm: '100%' }}
            p={{ base: '0px', sm: '24px' }}
            background="#302F37"
            borderRadius="8px"
          >
            <Flex w={'100%'} h="auto" maxW={'870px'}>
              <VStack flex={'1 1 auto'}>
                <AspectRatio ratio={16 / 9} w="100%" h="100%" maxW="810px">
                  <iframe
                    src={activeVideo ? activeVideo : getVideosByTrack?.[0]?.url}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    height="100%"
                    width="100%"
                    style={
                      !isMobile
                        ? {
                            borderRadius: '8px',
                            border: '1px solid #e56000',
                          }
                        : {}
                    }
                  />
                </AspectRatio>
              </VStack>
            </Flex>
          </Flex>

          <Flex
            maxW={{ md: 'full', lg: '368px' }}
            maxH={'516px'}
            justifyContent={'space-between'}
            minW="176px"
            w="100%"
            p={'24px'}
            pr="16px"
            flexDir={'column'}
            gap="23px"
            background={isMobile ? 'inherit' : '#302F37'}
            borderRadius="8px"
            m="0px !important"
          >
            <Flex
              flexDir={'column'}
              gap="22px"
              overflowY={'auto'}
              pr="12px"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '6px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#D9D9D9',
                  borderRadius: '17px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#E56000',
                  borderRadius: '17px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-corner': {
                  background: 'rgba(0,0,0,0)',
                },
              }}
            >
              <Modules
                // Only pass 2nd module if there are videos to display, else, only pass welcome videos
                videoData={
                  videoData[1].videos.length > 0 ? videoData : [videoData[0]]
                }
                activeVideo={activeVideo}
                getVideos={getVideos}
              />
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}
