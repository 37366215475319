import styled from 'styled-components'
import { ResponsiveContainer, Scroll } from 'styles/common'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  ${ResponsiveContainer};

  overflow-y: auto;

  ${Scroll}

  /* background: #fafafa; */

  color: #222;
`

export const Paper = styled.main`
  margin: 8px auto;
  width: 768px;
  height: 1100px;
  border: 1px solid #eee;
  border-radius: 8px;
  background: #fafafa;
`

export const PageTitle = styled.p`
  font-size: 28px;
  text-align: center;
  margin-top: 16px;
  font-weight: bold;
  letter-spacing: normal;
  text-rendering: geometricPrecision !important;
`

export const PageSubTitle = styled.p`
  font-size: 24px;
  text-align: center;
  color: #ff6b00;
  margin-bottom: 8px;
  letter-spacing: normal;
  text-rendering: geometricPrecision !important;
`

export const Card = styled.div`
  margin: 8px 8px;
  padding-bottom: 8px;
  border: 1px solid lightgray;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const CardTitle = styled.p`
  font-size: 20px;
  text-align: center;
  margin-bottom: 16px;
  padding: 8px 0px;
  background-color: #ff6b00;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: white;
`

export const CardContent = styled.p`
  font-size: 18px;
  margin-left: 16px;

  & + & {
    margin-top: 8px;
  }

  span {
    font-weight: bold;
    margin-left: 8px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
