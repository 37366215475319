import styled from 'styled-components'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  width: 514px;
  max-width: 90vw;
  height: 434px;
  max-height: calc(0.92 * 90vw);
  min-height: 35vh;

  background: #2a2a2d;

  box-shadow: 0px 0px 61px 13px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  padding-top: 24px;
  padding-bottom: 24px;

  > p {
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.2px;

    span {
      font-style: normal;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: ${({ theme: { colors } }) => colors.orange};
    }
  }

  > div {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
  }

  > div:last-child {
    a {
      color: rgba(255, 255, 255, 0.6);
      margin-top: 16px;
      font-size: 13px;
    }
  }
`
