import React, { useState } from 'react'
import { CgClose, CgAddR, CgLock } from 'react-icons/cg'
import { Modal, ModalOverlay, Tooltip } from '@chakra-ui/react'
import api from 'services/api'

import {
  ModalCloseButton,
  ModalDisplayLabel,
  ModalRank,
  ModalSectionWrapper,
  StyledModalBody,
  StyledModalContent,
  StyledModalHeader,
  StyledTd,
  StyledTr,
  TableContainer,
  TableFooterWrapper,
} from './styles'
import Pagination from 'components/Pagination'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { capitalize } from 'lodash'
import { useToastMessage } from 'components/Toast'
import { useAuth } from 'contexts/auth'

interface ThemeTypes {
  rankNumber?: number
  fieldName?: string
  fieldId?: number
  themeName?: string
  themeId?: number
  prevalence?: number
  correctAnswersRate?: number
  numberOfRevisions?: number
  examYears?: string
}

const IncidenceTable = ({
  data,
  page,
  total,
  setNewPage,
}: {
  data: ThemeTypes[]
  page: number
  total: number
  setNewPage: React.Dispatch<React.SetStateAction<number>>
}) => {
  const [theme, setTheme] = useState<ThemeTypes>({
    rankNumber: 0,
    fieldName: '',
    fieldId: 0,
    themeName: '',
    themeId: 0,
    prevalence: 0,
    correctAnswersRate: 0,
    numberOfRevisions: 0,
  })
  const [isOpen, setModal] = useState(false)
  const [themeAvailable, setThemeAvailable] = useState(false)
  const { isFocusLearningActivated } = useAuth()
  const showToast = useToastMessage()

  const handleModal = async (item: ThemeTypes) => {
    const result = await api.get(
      `/app/focus_learning/check_theme/${item.themeId}/today`,
    )

    setThemeAvailable(result.data ? true : false)

    setTheme(item)
    setModal(true)
  }

  const revisionHandle = async (themeId: number) => {
    try {
      await api.post('/app/focus_learning/themes_incidence/add_revision', {
        themeId,
      })

      showToast({
        title: 'Sucesso',
        description: 'Estudo adicionado com sucesso.',
        type: 'success',
        duration: 5000,
        mode: 'dark',
      })

      setThemeAvailable(true)
    } catch (e) {
      showToast({
        title: 'Atenção',
        description:
          'Sua agenda para hoje está na capacidade máxima. Bons estudos e volte amanhã.',
        type: 'error',
        duration: 5000,
        mode: 'dark',
      })
    }
  }

  return (
    <TableContainer>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'xs'}
        onClose={() => setModal(false)}
      >
        <ModalOverlay />
        <StyledModalContent>
          <StyledModalHeader>
            <ModalRank>{theme?.rankNumber}º</ModalRank>
            <div>Tema detalhado</div>
            <ModalCloseButton onClick={() => setModal(false)}>
              <CgClose />
            </ModalCloseButton>
          </StyledModalHeader>
          <StyledModalBody>
            <ModalSectionWrapper style={{ width: '100%' }}>
              <ModalDisplayLabel>Tema</ModalDisplayLabel>
              {theme?.themeName}
            </ModalSectionWrapper>
            <ModalSectionWrapper>
              <ModalDisplayLabel>Prevalência</ModalDisplayLabel>
              {`${theme?.prevalence?.toFixed(2)}%`}
            </ModalSectionWrapper>
            <ModalSectionWrapper success={theme?.correctAnswersRate}>
              <ModalDisplayLabel>% de acerto</ModalDisplayLabel>
              {theme?.correctAnswersRate}%
            </ModalSectionWrapper>
            <ModalSectionWrapper>
              <ModalDisplayLabel>N. Revisões</ModalDisplayLabel>
              {theme?.numberOfRevisions}
            </ModalSectionWrapper>
            <DefaultButton
              leftIcon={themeAvailable ? <CgLock /> : <CgAddR />}
              disabled={themeAvailable}
              w="full"
              onClick={() => revisionHandle(theme?.themeId!)}
              label={
                themeAvailable ? 'Adicionado na agenda' : 'Adicionar na agenda'
              }
            />
          </StyledModalBody>
        </StyledModalContent>
      </Modal>

      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Grande área</th>
            <th>Tema</th>
            <th>Prevalência</th>
            {isFocusLearningActivated && (
              <>
                <th>% de acerto</th>
                <th>N. de revisões</th>
                <th>Ação</th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {data?.map((item, index) => (
            <StyledTr key={index} bgstyle={index}>
              <StyledTd>{item?.rankNumber}</StyledTd>
              <StyledTd>{capitalize(item?.fieldName)}</StyledTd>
              <StyledTd>
                <Tooltip label={capitalize(item?.themeName)}>
                  {capitalize(item?.themeName)}
                </Tooltip>
              </StyledTd>
              <StyledTd>{`${item?.prevalence?.toFixed(2)}%`}</StyledTd>
              {isFocusLearningActivated && (
                <>
                  <StyledTd success={item?.correctAnswersRate}>
                    {item?.correctAnswersRate}%
                  </StyledTd>
                  <StyledTd>{item?.numberOfRevisions}</StyledTd>
                  <StyledTd>
                    <DefaultButton
                      label="Ver mais"
                      height={'26px'}
                      px="8px"
                      fontWeight={'600'}
                      fontSize={'14px'}
                      onClick={() => handleModal(item)}
                    />
                  </StyledTd>
                </>
              )}
            </StyledTr>
          ))}
        </tbody>
      </table>

      <TableFooterWrapper>
        <Pagination
          w="100%"
          my="15px"
          currentPage={page}
          totalCountOfRegisters={total}
          onPageChange={setNewPage}
          registersPerPage={15}
        />
      </TableFooterWrapper>
    </TableContainer>
  )
}

export default IncidenceTable
