import React, { ReactNode } from 'react'
import { ValueType } from 'react-select'
import { BsXLg } from 'react-icons/bs'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableStateSnapshot,
  DraggableProvided,
} from 'react-beautiful-dnd'

import {
  DnDItem,
  DnDItemText,
  DnDMainContainer,
  DnDSelectContainer,
  DndCloseIcon,
} from './styles'
import {
  FieldSelectDashboardProps,
  NewFieldSelect,
} from 'components/Fields/FieldDashboard'
import { Control, Controller, FieldError } from 'react-hook-form'
import {
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from '@chakra-ui/react'

interface TestSelection {
  label: string
  value: string
}

interface SelectDragNDropProps {
  name: string
  label?: string
  adicionalLabel?: string | ReactNode
  shouldLabelRed?: boolean
  error?: FieldError
  control: Control<any>
  restSelectProps?: FieldSelectDashboardProps
  defaultValue?: any
  options: any
  filterInstitutions?: string[]
  adicionalNode?: ReactNode
}

export const SelectDragNDropController = (props: SelectDragNDropProps) => {
  const {
    control,
    shouldLabelRed,
    adicionalLabel,
    label,
    name,
    options,
    defaultValue,
    error,
    restSelectProps,
    filterInstitutions,
    adicionalNode,
  } = props

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <HStack align={'center'} marginBottom={'8px'} spacing={'9px'}>
          <FormLabel
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="600"
            fontSize="16px"
            lineHeight="20px"
            marginRight={'0px'}
            marginBottom={'0px'}
            textAlign="left"
            letterSpacing="0.04em"
            color={shouldLabelRed && error ? '#FF6363' : '#FFFFFF'}
            htmlFor={name}
          >
            {shouldLabelRed && error ? `${label}*` : label}
          </FormLabel>

          {adicionalLabel}
        </HStack>
      )}

      <DnDSelectContainer>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            const reorder = (
              list: string[],
              startIndex: number,
              endIndex: number,
            ): string[] => {
              const result = Array.from(list)
              const [removed] = result.splice(startIndex, 1)
              result.splice(endIndex, 0, removed)

              return result
            }

            const onDragEnd = (result: DropResult): void => {
              if (!result.destination) {
                return
              }

              const items = reorder(
                field?.value ?? [],
                result.source.index,
                result.destination.index,
              )

              field.onChange(items)
            }

            const selectedUpdate = (
              option: ValueType<TestSelection, false>,
            ): void => {
              if (field?.value?.includes(option?.value)) return
              const items = [...(field?.value ?? []), option?.value]

              field.onChange(items)
            }

            const removeOption = (item: string): void => {
              const items = field?.value?.filter((value: any) => value !== item)
              field.onChange(items)
            }

            return (
              <>
                <NewFieldSelect
                  options={options}
                  value={selectedUpdate}
                  onChange={(option: any) => selectedUpdate(option)}
                  placeholder="Escolha ou digite a(s) prova(s)"
                  isSearchable
                  classNamePrefix={'custom'}
                  {...restSelectProps}
                />

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                      <DnDMainContainer
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ display: 'flex' }}
                        hasScroll={field?.value?.length > 5}
                      >
                        {field?.value?.map((item: string, index: number) => (
                          <Draggable
                            key={item}
                            draggableId={item}
                            index={index}
                          >
                            {(
                              provided: DraggableProvided,
                              snapshot: DraggableStateSnapshot,
                            ) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DnDItem
                                  isDragging={snapshot.isDragging}
                                  index={index}
                                >
                                  <div style={{ color: '#fff' }}>
                                    {index + 1}
                                  </div>

                                  <Center height="18px">
                                    <Divider orientation="vertical" />
                                  </Center>

                                  <DnDItemText>{item}</DnDItemText>

                                  {adicionalNode && (
                                    <Center w="22px">
                                      {filterInstitutions?.includes(item) &&
                                        adicionalNode}
                                    </Center>
                                  )}

                                  <Center height="18px">
                                    <Divider orientation="vertical" />
                                  </Center>

                                  <DndCloseIcon
                                    onClick={() => removeOption(item)}
                                  >
                                    <BsXLg />
                                  </DndCloseIcon>
                                </DnDItem>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </DnDMainContainer>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )
          }}
        />
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </DnDSelectContainer>
    </FormControl>
  )
}
