import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'contexts/auth'
import api from 'services/api'

async function resetProjectX() {
  await api.post('/app/focus_learning/reset')
}

export function useResetProjectX() {
  const { updateUserData } = useAuth()

  return useMutation(resetProjectX, {
    onSuccess: () => {
      updateUserData()
    },
  })
}
