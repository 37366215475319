import { Button, ButtonProps, Text, useBreakpointValue } from '@chakra-ui/react'
import { ModalVideoOrientation } from 'components/Modal/ModalVideoOrientation'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'

interface VideoOrientationProps extends ButtonProps {
  linkVideo?: string
}

export default function VideoOrientation({
  linkVideo,
  ...rest
}: VideoOrientationProps) {
  const [isHovered, setIsHovered] = useState(false)
  const [modalVideoOrientation, setModalVideoOrientation] = useState(false)
  const isMobile = useBreakpointValue({ base: true, sm: false })

  return (
    <>
      <Button
        {...rest}
        alignItems="center"
        justifyContent="start"
        gap="4px"
        alignSelf={{ base: 'none', md: 'center' }}
        onClick={() => setModalVideoOrientation(true)}
        minW="28px !important"
        maxW={isHovered ? '173px' : '28px'}
        minH={{ base: '26px', md: '26px !important' }}
        h={'28px !important'}
        background="#393940"
        _hover={{
          backgroundColor: '#393940',
        }}
        borderRadius="35px"
        fontStyle="normal"
        pl="5px"
        fontWeight="700"
        fontSize="12px"
        color="#FFFFFF"
        role="group"
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        transition="all 0.7s"
        leftIcon={
          <Icon
            fontSize={'18px'}
            color="#E56000"
            icon="material-symbols:play-circle"
          />
        }
      >
        <Text
          transition={'opacity 0.6s'}
          transitionDelay={'0.1s'}
          opacity={0}
          _groupHover={{ opacity: 1 }}
        >
          Ver Vídeo explicativo
        </Text>
      </Button>

      <ModalVideoOrientation
        isOpen={modalVideoOrientation}
        onClose={() => setModalVideoOrientation(false)}
        CancelButtonClose={() => setModalVideoOrientation(false)}
        size={isMobile ? 'xsm' : 'sm'}
        linkVideo={linkVideo ?? ''}
        onConfirmClick={() => setModalVideoOrientation(false)}
      />
    </>
  )
}
