import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface UpdateScheduleAgenda {
  query: {
    agendaId: number
  }
  data: {
    startAt: Date
    finishesAt: Date
  }
}

async function updateScheduleAgenda({ query, data }: UpdateScheduleAgenda) {
  await api.put(`/app/agendas/${query.agendaId}`, {
    startsAt: data.startAt,
    finishesAt: data.finishesAt,
  })
}

export function useUpdateScheduleAgenda() {
  return useMutation(updateScheduleAgenda, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'list'])
    },
  })
}
