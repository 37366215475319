import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

export interface GetDeletedPendingStudyResponseApi {
  id: number
  field: Field
  theme: Theme
  user_id: number
  track_id: number
  field_id: number
  theme_id: number
  subject?: Subject
  created_at: string
  updated_at: string
  subject_id?: number
  is_deleted: boolean
  attended_class: boolean
  themes: Theme[]
  subjects: Subject[]
  agenda_was_generated: boolean
}

interface Field {
  name: string
  id: number
}

interface Theme {
  name: string
  id: number
}

interface Subject {
  name: string
  id: number
}

export interface GetDeletedPendingStudy {
  id: number
  field: Field
  theme: Theme
  subject?: Subject
  createdAt: string
  themes: Theme[]
  subjects: Subject[]
}

export interface GetDeletedPendingStudiesResponse {
  items: GetDeletedPendingStudy[]
  meta: MetaResponse
}

interface Params {
  page: number
}

export async function getDeletedPendingStudies(params: Params) {
  const { data: dailyRecords } = await api.get<{
    data: GetDeletedPendingStudyResponseApi[]
    meta: MetaResponseApi
  }>('/app/pending_studies/deleteds', { params: { limit: 10, ...params } })

  const items = dailyRecords.data.map(dailyRecord => ({
    id: dailyRecord.id,
    field: dailyRecord.field,
    theme: dailyRecord.theme,
    subject: dailyRecord.subject,
    createdAt: dailyRecord.created_at,
    themes: dailyRecord.themes,
    subjects: dailyRecord.subjects,
  }))

  return {
    items,
    meta: formatMeta(dailyRecords.meta),
  }
}

export function useGetDeletedPendingStudies(params: Params) {
  return useQuery(['pending-studies', 'deleted', params], () =>
    getDeletedPendingStudies(params),
  )
}
