import styled from 'styled-components'

import { ResponsiveContainer, Scroll } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  ${ResponsiveContainer};

  overflow-y: auto;

  ${Scroll}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 16px;

  flex: 1 0 auto;
`

export const Chip = styled.button<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 166px;
  height: 42px;

  margin-right: 16px;
  margin-bottom: 16px;

  border-radius: 10px;
  border-width: 1px;
  border-color: red;

  background: transparent;

  border: 1px solid rgba(255, 255, 255, 0.2);

  transition: all 0.4s;

  &:hover {
    border: 1px solid rgba(255, 107, 0, 0.4);
    background: rgba(255, 107, 0, 0.05);

    p {
      color: rgba(255, 255, 255, 0.45);
    }
  }
`

export const ChipText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);

  transition: all 0.4s;

  user-select: none;

  padding: 8px;
`
