import {
  Box,
  chakra,
  Flex,
  Table,
  Tbody,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { NewFieldSelectRegistryController } from 'components/Fields/FieldDashboard/FieldSelectController'
import FieldInputMaskController from 'components/Fields/FieldMask/FieldInputMaskController'
import { FieldRadio } from 'components/Fields/FieldRadio'
import { RadioGroupController } from 'components/Fields/FieldRadioGroup/RadioGroupController'
import { ModalDefault } from 'components/Modal/ModalDefault'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { NoDataTableError } from 'components/Table/StepOneRecoveryPlanTable/NoDataTableError'
import { TrTableFirstStepRecord } from 'components/Table/StepOneRecoveryPlanTable/TrTableFirstStepRecord'
import { TrTableHeadFirstStepRecordProps } from 'components/Table/StepOneRecoveryPlanTable/TrTableHeadFirstStepRecordProps'
import { useToastMessage } from 'components/Toast'
import VideoOrientation from 'components/VideoOrientation'
import { useRecoveryDaily } from 'contexts/recoveryDaily'
import { useTrack } from 'contexts/track'
import { useSelectParser } from 'hooks/useSelectParser'
import { isEmpty } from 'lodash'
import ReactGA from 'react-ga4'
import { useDeletePendingStudies } from 'pages/CreateRecoveryPlan/queries/use-delete-pending-studies'
import { useGetPrInfo } from 'pages/CreateRecoveryPlan/queries/use-get-activated-pr'
import {
  ListPendingStudiesData,
  ListPendingStudiesDataResponse,
  useGetListPendingStudies,
} from 'pages/CreateRecoveryPlan/queries/use-get-pending-studies'
import { useMutationRegistryNewRecoveryPlan } from 'pages/CreateRecoveryPlan/queries/use-mutation-pending-studies'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { AdditionalFirstStepText } from '../AdicionalFirstStepText'
import { StepOneSchema } from './StepOneSchema'
import { DefaultButton } from 'components/Buttons/DefaultButton'
interface FormPendingStudies {
  area: {
    label: string
    value: number
  } | null
  themeIds: {
    label: string
    value: number
  } | null
  subjectIds: number[] | null
  isTheoreticalOnly: string
  hours: string
  minutes: string
}

interface FormPendingStudiesOutput {
  trackId: number
  area: number
  themeIds: number[]
  subjectIds: number[] | null
  isTheoreticalOnly: boolean
  studyTimeInMinutes: number
}

ReactGA.initialize('G-1X61WSVDPN')

export function StepOne() {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    setFocus,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormPendingStudies>({
    defaultValues: {
      isTheoreticalOnly: 'theoretical',
      hours: '0',
      minutes: '0',
    },
    resolver: yupResolver(StepOneSchema),
  })

  const selectedFieldId = watch('area')
  const isTheoricalWatch = watch('isTheoreticalOnly')

  const { track } = useTrack()

  const showToast = useToastMessage()

  const { setStep } = useRecoveryDaily()

  const selectAvailableThemes = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint:
      track &&
      selectedFieldId &&
      `/app/tracks/${track.id}/fields/${selectedFieldId?.value}/themes?limit=5000`,
  })

  const selectAvailableSubjects = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint:
      track &&
      selectedFieldId &&
      `/app/tracks/${track.id}/fields/${selectedFieldId.value}/subjects?limit=5000`,
  })

  const { data, isError } = useGetListPendingStudies({
    limit: 9999999,
  })

  const registryNewRecord = useMutationRegistryNewRecoveryPlan()

  const { data: ActivatedPR } = useGetPrInfo()

  const removePendingStudies = useDeletePendingStudies()

  const isSm = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [modalNoDataPendingStudies, setModalNoDataPendingStudies] = useState(
    false,
  )
  const [schemaError, setSchemaError] = useState<any>(null)
  const [
    selectedUser,
    setSelectedUser,
  ] = useState<ListPendingStudiesData | null>(null)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDeleteRegistry = async (id: number) => {
    try {
      await removePendingStudies.mutateAsync({ id: id })

      showToast({
        title: 'Sucesso',
        description: 'Estudo removido.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar a remoção.'
      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    } finally {
      setModalConfirmDelete(false)
    }
  }

  const handleSpecificReset = useCallback(() => {
    if (selectedFieldId) {
      setValue('themeIds', null)
      setValue('subjectIds', null)
    }
  }, [selectedFieldId, setValue])

  const submitForm = async (formData: FormPendingStudiesOutput) => {
    const studyTimeInMinutes = formData.isTheoreticalOnly
      ? Number(getValues('hours')) * 60 + Number(getValues('minutes'))
      : 60

    try {
      await registryNewRecord.mutateAsync({
        payload: {
          trackId: track!.id,
          fieldId: formData.area,
          subjectIds: formData.subjectIds ? formData.subjectIds : undefined,
          themeIds: formData.themeIds,
          isTheoretical: formData.isTheoreticalOnly,
          studyTimeInMinutes: studyTimeInMinutes,
        },
        id: ActivatedPR?.pendingStudiesData.id!,
      })

      showToast({
        title: 'Estudo cadastrado.',
        description: 'Estudo cadastrado com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError | string =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar o registro.'

      if (message === 'Estudo pendente já cadastrado.') {
        setModalMessage(true)
      } else {
        showToast({
          title: 'Erro ao adicionar estudo',
          description: message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    } finally {
      reset({
        themeIds: null,
        area: null,
        subjectIds: null,
        hours: '0',
        minutes: '0',
      })
    }
  }

  const advanceStepTrigger = (
    data: ListPendingStudiesDataResponse | undefined,
  ) => {
    if (data?.data && isEmpty(data.data)) {
      return setModalNoDataPendingStudies(true)
    }
    setStep(1)
    ReactGA.event({
      category: 'recovery_plan',
      action: 'recovery_plan_start',
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (errors) {
      setSchemaError(errors)
    }
  }, [errors])

  useEffect(() => {
    handleSpecificReset()
  }, [selectedFieldId, handleSpecificReset])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <Flex gap={'10px'} flexDir="column">
        <Flex w="100%" gap={'0.5rem'} flexDir={{ base: 'column', sm: 'row' }}>
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize="20px"
            whiteSpace={'nowrap'}
            lineHeight="25px"
            color="#FFFFFF"
          >
            Estudos atrasados
          </Text>

          <Flex
            fontStyle="normal"
            fontWeight="bold"
            fontSize="30px"
            mix-blend-mode="normal"
            opacity="0.9"
            margin-bottom="4px"
            w={!isSm ? '510px' : 'auto'}
            alignItems="flex-start"
          >
            {
              <VideoOrientation
                linkVideo={'https://www.youtube.com/watch?v=dKfgeYNbI6g'}
                // ml={!isSm ? '10px' : '0px'}
              />
            }
          </Flex>
        </Flex>

        <AdditionalFirstStepText />
      </Flex>

      <Flex
        w="100%"
        mt="32px"
        gap={'16px'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          as={'form'}
          onSubmit={handleSubmit(submitForm)}
          gap={'24px'}
          borderRadius={'8px'}
          maxH="520px"
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#D9D9D9',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#60606C',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'rgba(0,0,0,0)',
            },
          }}
          overflow="auto"
          p="1rem"
          minW={{ base: 'unset', sm: '325px' }}
          maxW={{ base: 'unset', lg: '325px' }}
          w={'100%'}
          bg="#302F37"
          flexDir={'column'}
        >
          {track?.fields && (
            <NewFieldSelectRegistryController
              control={control}
              options={track.fields.map(field => {
                return {
                  label: field.name,
                  value: field.id,
                }
              })}
              label="Grande área"
              name="area"
              placeholder="Selecione..."
              error={errors?.area as FieldError}
              shouldLabelRed
            />
          )}

          <NewFieldSelectRegistryController
            control={control}
            options={selectAvailableThemes?.options}
            label="Tema"
            name="themeIds"
            placeholder="Selecione..."
            isLoading={selectAvailableThemes.isLoading}
            error={errors?.themeIds as any}
            shouldLabelRed
          />

          <NewFieldSelectRegistryController
            control={control}
            options={selectAvailableSubjects?.options}
            isLoading={selectAvailableSubjects.isLoading}
            label="Subtema"
            isMulti={true as any}
            name="subjectIds"
            placeholder="Selecione..."
          />

          <RadioGroupController
            name="isTheoreticalOnly"
            label="Tipo de estudo"
            placeholder="Selecione uma opção"
            defaultValue={'theoretical'}
            control={control}
            display="flex"
            gap="24px"
            minW={'226px'}
          >
            <FieldRadio name="thereoretical" value={'theoretical'}>
              Teórico
            </FieldRadio>
            <FieldRadio name="practical" value={'practical'}>
              Prático
            </FieldRadio>
          </RadioGroupController>

          {isTheoricalWatch === 'theoretical' && (
            <>
              <Flex flexDir={'column'} gap="8px">
                <Text
                  fontFamily="Mulish"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  textAlign="left"
                  letterSpacing="0.04em"
                  color={
                    schemaError?.schemaError?.message ? '#FF6363' : '#FFFFFF'
                  }
                >
                  {schemaError?.schemaError?.message
                    ? 'Tempo da aula*'
                    : 'Tempo da aula'}
                </Text>
                <Flex gap="8px">
                  <Box
                    w={'100%'}
                    onClick={() => setFocus('hours')}
                    maxW="100px"
                    position={'relative'}
                  >
                    <chakra.span
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        zIndex: 9,
                        right: 7,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      hora(s)
                    </chakra.span>
                    <FieldInputMaskController
                      mask={'99'}
                      maskPlaceholder=""
                      name="hours"
                      control={control}
                      placeholder={'00'}
                      /* error={errors.name} */
                    />
                  </Box>

                  <Box
                    w={'100%'}
                    onClick={() => setFocus('minutes')}
                    maxW="115px"
                    position={'relative'}
                  >
                    <chakra.span
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        zIndex: 9,
                        right: 7,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      minuto(s)
                    </chakra.span>
                    <FieldInputMaskController
                      mask={'99'}
                      maskPlaceholder=""
                      name="minutes"
                      control={control}
                      placeholder="00"
                      /* error={errors.name} */
                    />
                  </Box>
                </Flex>
                {!!schemaError?.schemaError?.message && (
                  <Text color="#FF6363" fontWeight="500" fontSize="sm">
                    {schemaError?.schemaError?.message}
                  </Text>
                )}
              </Flex>
            </>
          )}

          <Flex w="100%" justifyContent={'center'}>
            <DefaultButton
              label="Salvar"
              isLoading={isSubmitting}
              type="submit"
              minH="42px"
              maxW="325px"
              w="100%"
            />
          </Flex>
        </Flex>

        <Flex
          w="100%"
          p="1rem"
          backgroundColor={'#302F37'}
          gap={'1rem'}
          flexDir="column"
          borderRadius={'8px'}
        >
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize="20px"
            lineHeight="25px"
            color="#FFFFFF"
          >
            Estudos registrados
          </Text>

          <Box
            maxH="444px"
            w="100%"
            borderRadius={'8px 8px 8px 8px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-corner': {
                background: 'rgba(0,0,0,0)',
              },
            }}
            overflow="auto"
          >
            <Table background={'#302F37'}>
              <TrTableHeadFirstStepRecordProps />
              <Tbody borderBottomColor="#302F37">
                {!isEmpty(data?.data) &&
                  data?.data.map((item, index) => {
                    return (
                      <TrTableFirstStepRecord
                        key={`TableSectionData${index}`}
                        backgroundTable={
                          index % 2 == 0 ? 'rgba(57, 57, 64, 0.64)' : '#393940'
                        }
                        area={item.field.name}
                        onClickOpenRemove={() => setModalConfirmDelete(true)}
                        studyType={item.is_theoretical}
                        subTheme={item.subjects}
                        theme={item.themes}
                        onClick={() => {
                          setSelectedUser(item)
                        }}
                      />
                    )
                  })}
              </Tbody>
            </Table>
          </Box>

          {isError && (
            <Flex maxH="350px" h="100%" w="100%" justifyContent={'center'}>
              <NoDataTableError
                w="100%"
                h="100%"
                headerMessage="Nenhum estudo cadastrado!"
                message="Adicione novos estudos no lado esquerdo para visualizá-los."
              />
            </Flex>
          )}

          {isEmpty(data?.data) && !isError && (
            <Flex maxH="350px" h="100%" w="100%" justifyContent={'center'}>
              <NoDataTableError
                w="100%"
                h="100%"
                headerMessage="Nenhum estudo cadastrado!"
                message="Adicione novos estudos no lado esquerdo para visualizá-los."
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex justifyContent={'flex-end'} mt="2rem" w="100%">
        <DefaultButton
          label="Avançar"
          maxW={'152px'}
          w="100%"
          onClick={() => advanceStepTrigger(data)}
        />
      </Flex>

      <ModalDefaultTwoButton
        isOpen={modalConfirmDelete}
        onClose={() => setModalConfirmDelete(false)}
        onConfirmClick={() => handleDeleteRegistry(selectedUser!.id!)}
        title={'Tem certeza que deseja remover?'}
        subTitle={
          'Se continuar, o registro será encaminhado para à página “lixeira.”'
        }
      />

      <ModalDefault
        isOpen={modalMessage}
        onClose={() => setModalMessage(false)}
        onConfirmClick={() => setModalMessage(false)}
        title={'Estudo já cadastrado!'}
        subTitle={
          'Verifique se o estudo está na tabela de estudos registrados. Se precisar de ajuda, entre em contato com o nosso suporte.'
        }
      />

      <ModalDefault
        isOpen={modalNoDataPendingStudies}
        onClose={() => setModalNoDataPendingStudies(false)}
        onConfirmClick={() => setModalNoDataPendingStudies(false)}
        title={'Erro ao avançar'}
        subTitle={'Não é possível avançar sem estudo cadastrado!'}
      />
    </>
  )
}
