import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponse } from 'types/response'

export interface MockExamAnswer {
  id: number
  chosen_alternative: 'a' | 'b' | 'c' | 'd' | 'e'
  mock_exam_question_id: number
  student_id: number
  created_at: string
  updated_at: string
  mockExamQuestion: MockExam
}

export interface MockExam {
  id: number
  statement: string
  alternative_a: string
  alternative_b: string
  alternative_c: string
  alternative_d: string
  alternative_e: string
  correct_alternative: string
  comment: string
  comment_image: string
  image_highlight: string
  essential_concept: string
  image_essential_concept: string
  mock_exam_id: number
  created_at: string
  updated_at: string
}

export type GetExamsResponse = {
  meta: MetaResponse
  data: MockExamAnswer[]
}

type Params = {
  limit: number
  page?: number
}

export async function getAnswers(params: Params): Promise<GetExamsResponse> {
  const { limit, page } = params

  const { data } = await api.get<GetExamsResponse>('/app/mock-exam-answers/', {
    params: {
      limit,
      page,
    },
  })

  return data
}

export function useGetAnswers(params: Params) {
  return useQuery(
    ['get-simulation-exams-answers', params],
    () => getAnswers(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
