import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'index'
import api from 'services/api'

interface InputDateReset {
  startDate: String
  endDate: String
}

async function resetRevision(data: InputDateReset) {
  await api.post('app/agendas/reset', data)
}

export function useResetRevision() {
  return useMutation(resetRevision, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agendas', 'list'])
    },
  })
}
