import { Switch, SwitchProps } from '@chakra-ui/react'
import React from 'react'

interface FieldSwitchProps extends SwitchProps {}

const FieldSwitchComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldSwitchProps
> = (props: FieldSwitchProps, ref) => {
  const { children, ...rest } = props
  return (
    <Switch ref={ref} colorScheme="brand" data-qa={props.name} {...rest}>
      {children}
    </Switch>
  )
}

export const FieldSwitch = React.forwardRef(FieldSwitchComponent)
