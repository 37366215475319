export const hoursAndMinutesConversion = (numberInMinutes: number) => {
  const restInMinutes = numberInMinutes % 60

  if (numberInMinutes >= 60 && restInMinutes > 0) {
    return `${Math.floor(numberInMinutes / 60)}h ${restInMinutes}m`
  }

  if (numberInMinutes >= 60 && restInMinutes === 0) {
    return `${Math.floor(numberInMinutes / 60)}h`
  }

  return `${numberInMinutes}m`
}
