import * as yup from 'yup'

export const StepTwoSchema = yup
  .object()
  .shape({
    hours: yup.string(),
    minutes: yup.string(),
  })
  .test('notEmpty', 'É necessário preencher esse campo', function (value) {
    const { hours, minutes } = value || {}

    if ((!hours && !minutes) || (hours === '0' && minutes === '0')) {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '00' && minutes === '00') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '0' && minutes === '00') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '00' && minutes === '0') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (!hours && minutes === '0') {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (hours === '0' && !minutes) {
      return this.createError({
        path: 'schemaError',
        message: 'É necessário preencher esse campo',
      })
    }

    if (!hours && minutes) {
      return true
    }

    if (hours && !minutes) {
      return true
    }

    return true
  })
