import {
  Circle,
  Flex,
  FlexProps,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'

interface ProgressBarProps extends FlexProps {
  title: string
  concluded: number
  inProgress: number
  rest: number
  total: number
  isLoading: boolean
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    title,
    concluded,
    inProgress,
    rest,
    total,
    isLoading,
    ...FlexProps
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Hook
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isSm = useBreakpointValue({ base: true, sm: false })
  const isMd = useBreakpointValue({ base: true, md: false })

  if (isLoading) {
    return null
  }

  /*
  |-----------------------------------------------------------------------------
  | Render
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex flexDir={'column'} gap={'10px'} {...FlexProps}>
      <HStack mr="8px" justify={'space-between'}>
        <Text
          fontFamily="Mulish"
          fontWeight="600"
          fontSize="18px"
          lineHeight="23px"
        >
          {title}
        </Text>

        <Text
          fontFamily="Mulish"
          fontWeight="500"
          fontSize="16px"
          lineHeight="20px"
        >
          {`${total}%`}
        </Text>
      </HStack>

      <Flex ml="7px">
        <Flex
          background={'#0DDF15'}
          color="#393940"
          borderRadius="4px"
          justifyContent="flex-end"
          alignItems={'center'}
          w={`${concluded}%`}
          h="24px"
          position="relative"
          zIndex="2"
        >
          <Text
            fontFamily="Mulish"
            fontWeight="600"
            fontSize="11px"
            lineHeight="15px"
            mr="5px"
          >
            {isSm && concluded < 10
              ? ''
              : concluded < 7
              ? ''
              : concluded < 10 && !isMd
              ? `${concluded.toFixed(2)}%`
              : isMd
              ? `${concluded.toFixed(0)}%`
              : `${concluded}%`}
          </Text>
        </Flex>

        <Flex
          background={'#E5C009'}
          color="#393940"
          borderRadius="4px"
          justifyContent="flex-end"
          alignItems={'center'}
          w={`${inProgress}%`}
          h="24px"
          position="relative"
          zIndex="1"
          marginLeft="-5px"
          marginRight={'-5px'}
        >
          <Text
            fontFamily="Mulish"
            fontWeight="600"
            fontSize="11px"
            lineHeight="15px"
            mr="5px"
          >
            {isSm && inProgress < 10
              ? ''
              : inProgress < 7
              ? ''
              : inProgress < 10 && !isMd
              ? `${inProgress.toFixed(2)}%`
              : isMd
              ? `${inProgress.toFixed(0)}%`
              : `${inProgress}%`}
          </Text>
        </Flex>

        <Flex
          background={'#BDBDC7'}
          color="#393940"
          borderRadius="4px"
          justifyContent="flex-end"
          alignItems={'center'}
          w={`${rest}%`}
          h="24px"
          position="relative"
        >
          <Text
            fontFamily="Mulish"
            fontWeight="600"
            fontSize="11px"
            lineHeight="15px"
            mr="5px"
          >
            {isSm && rest < 10
              ? ''
              : rest < 7
              ? ''
              : rest < 10 && !isMd
              ? `${rest.toFixed(2)}%`
              : isMd
              ? `${rest.toFixed(0)}%`
              : `${rest}%`}
          </Text>
        </Flex>
      </Flex>

      <Flex gap={'24px'} flexDir={{ base: 'column', sm: 'row' }}>
        <HStack>
          <Circle size="10px" backgroundColor={'#0DDF15'} />
          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
            color={'#FFFFFF'}
          >
            concluídos
          </Text>
        </HStack>

        <HStack>
          <Circle size="10px" backgroundColor={'#E5C009'} />
          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
            color={'#FFFFFF'}
          >
            em andamento
          </Text>
        </HStack>

        <HStack>
          <Circle size="10px" backgroundColor={'#BDBDC7'} />
          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
            color={'#FFFFFF'}
          >
            pendentes
          </Text>
        </HStack>
      </Flex>
    </Flex>
  )
}
