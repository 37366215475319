import { Th, Thead, Text, TableHeadProps } from '@chakra-ui/react'
import React from 'react'

export const TrTableHeadProgramedStudies = (props: TableHeadProps) => {
  return (
    <Thead textAlign={'center'} {...props} backgroundColor="#393940">
      <Th
        textTransform={'none'}
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        color="#FFFFFF"
        py="12px"
        px="16px"
        lineHeight={'20px'}
        whiteSpace="nowrap"
      >
        <Text>Data</Text>
      </Th>

      <Th
        textTransform={'none'}
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        color="#FFFFFF"
        py="12px"
        px="16px"
        lineHeight={'20px'}
        whiteSpace="nowrap"
      >
        <Text>Grande área</Text>
      </Th>

      <Th
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="16px"
        lineHeight={'20px'}
        whiteSpace="nowrap"
      >
        <Text textAlign={'start'}>Tema</Text>
      </Th>

      <Th
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="16px"
        lineHeight={'20px'}
        whiteSpace="nowrap"
      >
        <Text>Subtema</Text>
      </Th>

      <Th
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="16px"
        lineHeight={'20px'}
        whiteSpace="nowrap"
      >
        Tipo de estudo
      </Th>

      <Th
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        border={'0px'}
        py="12px"
        px="16px"
        lineHeight={'20px'}
        whiteSpace="nowrap"
      >
        <Text textAlign={'start'}>Remover</Text>
      </Th>
    </Thead>
  )
}
