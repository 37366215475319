import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import logo from 'assets/images/new_logo.svg'
import { Logo } from 'components/Sidebar/styles'
import { stepsOnboardingPurchase } from '../data/data'
import { Step } from 'components/Step/Step'

interface HeaderOnboardingPurchaseProps {
  currentStep: number
  trackSelected: {
    label: string
    value: number | string
  } | null
}

export default function HeaderOnboardingPurchase({
  currentStep,
  trackSelected,
}: HeaderOnboardingPurchaseProps) {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMobile = useBreakpointValue({ base: true, sm: false })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      justify={'space-between'}
      flexDir={{ base: 'column', md: 'row' }}
      w="100%"
      alignItems={{ base: 'start', md: 'center' }}
      gap="34px"
    >
      {isMobile ? (
        <Text
          fontFamily="Mulish"
          fontSize={{ base: '24px', sm: '42px' }}
          lineHeight={{ base: '30px', sm: '60px' }}
          color="#FFFFFF"
          fontWeight={'bold'}
        >
          Mentoria Residência
        </Text>
      ) : (
        <Logo
          src={logo}
          width="226px"
          height="103px"
          alt="Logo da Mentoria Residência"
          draggable={false}
          shouldSidebarBeWide={true}
        />
      )}

      <Flex maxW="800px" w="auto">
        {(trackSelected?.label === 'PNA PORTUGAL' ||
          trackSelected?.label === 'Revalida') &&
        currentStep > 1
          ? stepsOnboardingPurchase
              .filter(
                item =>
                  item.title !== 'Conheça seu monitor' &&
                  item.title !== 'Metodologia de Estudo',
              )
              .map((step, index) => (
                <Step
                  key={index}
                  cursor="pointer"
                  title={step.title}
                  isActive={currentStep === index}
                  isCompleted={currentStep > index}
                  isFirstStep={index === 0}
                  isLastStep={index === 2}
                />
              ))
          : stepsOnboardingPurchase.map((step, index) => (
              <Step
                key={index}
                cursor="pointer"
                title={step.title}
                isActive={currentStep === index}
                isCompleted={currentStep > index}
                isFirstStep={index === 0}
                isLastStep={stepsOnboardingPurchase.length === index + 1}
              />
            ))}
      </Flex>
    </Flex>
  )
}
