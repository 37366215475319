import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { getYear, isBefore, parseISO } from 'date-fns'
import Swal from 'sweetalert2'
import { get } from 'lodash'

import api from 'services/api'

import {
  AuthContextValues,
  User,
  LogInPayload,
  LogInResponse,
} from './interfaces'

const STORAGE_KEYS = {
  USER: '@mentoria:user',
  TOKEN: '@mentoria:token',
}

export type Token =
  | {
      token: string
      type: string
    }
  | undefined

const AuthContext = createContext({} as AuthContextValues)

export const AuthContextProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const isPreOnboardingRequired = useCallback(() => {
    if (!user) return false

    const endDate = new Date(user?.full_plataform_access_date)
    const startDate = new Date()

    if (isBefore(startDate, endDate)) return true

    return false
  }, [user])

  const saveUserToStorage = useCallback((user: User) => {
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user))
  }, [])

  const saveTokenToStorage = useCallback((token: string) => {
    localStorage.setItem(STORAGE_KEYS.TOKEN, token)
  }, [])

  const loadUserFromStorage = useCallback(() => {
    const userStr = localStorage.getItem(STORAGE_KEYS.USER)
    if (!userStr) return undefined
    return JSON.parse(userStr)
  }, [])

  const loadTokenFromStorage = useCallback(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN)
    if (!token) return
    return token
  }, [])

  const logOut = useCallback(() => {
    localStorage.removeItem(STORAGE_KEYS.USER)
    localStorage.removeItem(STORAGE_KEYS.TOKEN)

    setUser(undefined)
    api.defaults.headers.Authorization = ''
  }, [])

  const logIn = useCallback(
    async ({ email, password }: LogInPayload) => {
      setIsLoading(true)
      try {
        const newSessionPayload = { email, password }
        const {
          data: { token, user },
        } = await api.post<LogInResponse>('/public/sessions', newSessionPayload)

        const userData = user

        if (!userData.roles.some(role => role.slug === 'student')) {
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            html:
              '<p style="text-align:center">Você não tem acesso a essa plataforma. Entre em contato com nosso time para mais detalhes.</p>',
            icon: 'warning',
          })

          setIsLoading(false)

          return
        }

        api.defaults.headers.Authorization = `Bearer ${token.token}`

        // if (!userData.chosen_monitor) {
        //   const { data } = await api.get('/app/onboardings/monitor')
        //   const monitorsFormatted = data
        //     .map((monitor: any) => ({
        //       id: monitor.id,
        //       name: monitor.name,
        //     }))
        //     .reduce((acc: any, curr: any) => {
        //       return {
        //         ...acc,
        //         [curr.id]: curr.name,
        //       }
        //     }, {})

        //   const result = await Swal.fire({
        //     title: 'Selecione um monitor',
        //     allowOutsideClick: false,
        //     text: 'Você precisa selecionar um monitor para continuar.',
        //     icon: 'warning',
        //     confirmButtonText: 'Selecionar',
        //     input: 'select',
        //     inputOptions: monitorsFormatted,
        //     inputPlaceholder: 'Selecione um monitor',
        //     inputValidator: value => {
        //       return new Promise(resolve => {
        //         if (value !== '') {
        //           resolve(null)
        //         } else {
        //           resolve('Por favor, selecione um monitor')
        //         }
        //       })
        //     },
        //   })

        //   if (result) {
        //     await api.patch('/app/onboardings/monitor', {
        //       monitorId: +result.value,
        //     })

        //     userData = {
        //       ...userData,
        //       chosen_monitor: true,
        //     }
        //   }
        // }
        setUser(userData)
        saveUserToStorage(userData)
        saveTokenToStorage(token.token)
        setIsLoading(false)

        if (!userData.contract_accepted_at) {
          const contractResponse = await Swal.fire({
            buttonsStyling: false,
            title: 'Novo contrato',
            html:
              '<p>Estamos readequando o programa Mentoria Residência aos termos atuais da Lei Geral de Proteção de Dados (LGPD), para prosseguir com o uso <a href="/mentoria.pdf" target="_blank" style="font-weight: bold; text-decoration: underline; download">leia o contrato</a> e marque a opção "Concordo e desejo seguir". Caso você não concorde, <a href="https://api.whatsapp.com/send?phone=551150262846" target="_blank" style="font-weight: bold; text-decoration: underline;">entre em contato com o suporte</a>.</p>',
            confirmButtonText: 'Concordo e desejo seguir',
            showCancelButton: true,
            cancelButtonText: 'Não concordo',
          })

          if (contractResponse.isConfirmed) {
            await api.patch('/app/onboardings/contract')
          }

          if (contractResponse.isDismissed) {
            logOut()
          }
        }

        history.replace('/home')
      } catch (error: any) {
        setIsLoading(false)
        if (error?.response?.status === 402) {
          return Swal.fire({
            buttonsStyling: false,
            title: 'O seu acesso expirou',
            html:
              '<p style="text-align:center">Mas você pode garantir uma vaga no mentoria residência agora mesmo com uma condição especial.</p>',
            showConfirmButton: true,
            confirmButtonText: 'Vamos lá!',
          }).then(({ isConfirmed }) => {
            if (isConfirmed) {
              window.open(
                'https://mentoriaresidencia.com.br/',
                '_blank',
                'noopener,noreferrer',
              )
            }
          })
        }

        let errorMessage =
          'Infelizmente houve uma falha no seu login. Reveja os dados e tente novamente.'

        if (error.response?.data?.errors) {
          errorMessage = error.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        const apiErrorString = get(error, 'response.data')
        if (typeof apiErrorString === 'string') {
          errorMessage = apiErrorString
        }

        Swal.fire({
          title: 'Aviso',
          buttonsStyling: false,
          html: `<p style="text-align:center">${errorMessage}</p>`,
        })
      }
    },
    [history, logOut, saveTokenToStorage, saveUserToStorage],
  )

  const updateUserData = useCallback(async () => {
    try {
      const { data: userProfile } = await api.get<User>('/app/profiles')

      setUser(userProfile)
      saveUserToStorage(userProfile)
    } catch (error) {
      console.trace('Erro ao atualizar perfil do usuário', error)
    }
  }, [saveUserToStorage])

  // should incidences, project-x and recovery plan be activated
  const untilLastFinalContractDate2024 = useMemo(() => {
    if (user?.contracts?.[0]?.final_date) {
      const formattedFinalDate = parseISO(
        user?.contracts?.[0]?.final_date,
      ).getFullYear()

      if (formattedFinalDate === 2024) {
        return true
      }
    }

    return false
  }, [user?.contracts])

  const isFocusLearningActivated = useMemo(() => {
    if (user?.focus_learning_activated_at) {
      return true
    }

    return false
  }, [user?.focus_learning_activated_at])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const token = loadTokenFromStorage()
    const user = loadUserFromStorage()

    if (token && user) {
      /* if (!user.chosen_monitor) {
        logOut()
      } */

      setUser(user)
      api.defaults.headers.Authorization = `Bearer ${token}`
    }

    setIsLoading(false)
  }, [loadTokenFromStorage, loadUserFromStorage, logOut])

  useEffect(() => {
    if (!user) return

    if (user.profile?.approved_institutions?.length) return

    if (user.profile?.trial_year === getYear(new Date()) - 1) {
      Swal.fire({
        title: `Olá ${user.name}`,
        buttonsStyling: false,
        html:
          '<p style="text-align:center">Compartilhe conosco a sua aprovação</p>',
        showCancelButton: true,
        confirmButtonText: 'Compartilhar',
        cancelButtonText: 'Agora não',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          history.replace('/account?tab=approval')
        }
      })
    }
  }, [history, logOut, user])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const authContextValue: AuthContextValues = useMemo(
    () => ({
      user,
      hasContract: Boolean(user?.contracts?.length),
      userContract: user?.contracts?.length ? user.contracts[0] : undefined,
      userIsOnboardingRequired: user?.is_onboarding_required
        ? user?.is_onboarding_required
        : undefined,
      isLoggedIn: !!user,
      logIn,
      logOut,
      isLoading,
      updateUserData,
      loadTokenFromStorage,
      isFocusLearningActivated,
      untilLastFinalContractDate2024,
      isPreOnboardingRequired,
    }),
    [
      user,
      logIn,
      logOut,
      isLoading,
      updateUserData,
      loadTokenFromStorage,
      isFocusLearningActivated,
      untilLastFinalContractDate2024,
      isPreOnboardingRequired,
    ],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
