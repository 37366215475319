import React from 'react'

import { Tr, Td, Skeleton } from '@chakra-ui/react'

interface Props {
  columns: number
}

export function ListLoading({ columns }: Props) {
  return (
    <>
      {Array(10)
        .fill(0)
        .map((_, index) => (
          <Tr key={index}>
            {Array(columns)
              .fill(0)
              .map((_, i) => (
                <Td key={i}>
                  <Skeleton height="4" />
                </Td>
              ))}
          </Tr>
        ))}
    </>
  )
}
