import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponseApi } from 'types/response'

export interface Notification {
  id: number
  title: string
  message: string
  url_link: string
  created_at: string
  is_viewed: boolean
}

export interface GetNotificationsResponse {
  meta: MetaResponseApi
  data: Notification[]
}

type QueryParams = {
  limit?: number
  page?: number
}

export async function getNotifications(
  params?: QueryParams,
): Promise<GetNotificationsResponse> {
  const { data } = await api.get<GetNotificationsResponse>(
    '/app/notifications',
    {
      params: {
        limit: params?.limit ? params?.limit : 9999,
        page: params?.page ? params?.page : 1,
      },
    },
  )

  return data
}

export function useGetNotifications(params?: QueryParams) {
  return useQuery(
    ['get-notifications', params],
    () => getNotifications(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    },
  )
}
