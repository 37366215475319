import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Home,
  PieChart,
  CheckSquare,
  Calendar,
  Users,
  Folder,
  Settings,
  MessageCircle,
  Clipboard,
  BarChart2,
  BookOpen,
  Crosshair,
  Trash2,
  Video,
} from 'react-feather'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { FiBook } from 'react-icons/fi'
import logo from 'assets/images/new_logo.svg'
import logo2 from 'assets/images/logo2.png'
import BackMobile from 'assets/images/back.svg'
import NextMobile from 'assets/images/next.svg'

import { useWindowSize } from 'hooks/useWindowSize'
import { useAuth } from 'contexts/auth'
import { useTrack } from 'contexts/track'
import api from 'services/api'
import { Container, Logo, MenuButton } from './styles'
import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react'
import { MenuItem } from 'components/Sidebar/MenuItem'
import { useSideBar } from 'contexts/sidebar'
import { KillerConceptIcon } from 'assets/icons/KillerConceptsIcon'
import { SimulationtIcon } from 'assets/icons/SimulationIcons'
import { PiArrowUpRightBold } from 'react-icons/pi'
import { useToastMessage } from 'components/Toast'
import { Icon } from '@iconify/react'
import { TbCalendarStats } from 'react-icons/tb'

interface SideBarProps {
  hasHeaderResponsiveLayout?: boolean
}

export interface Options {
  isExternal: boolean
  label: string
  path?: string
  icon: any
  hasSubItem?: boolean
  subitens?: Options[]
  shouldRender?: boolean
  shouldShowTrialMenus?: boolean
  executeBeforeOnClick?: () => void
}

const Sidebar = (props: SideBarProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isSidebarSetToWide, setIsSidebarSetToWide } = useSideBar()
  const { pathname } = useLocation()
  const { width } = useWindowSize()
  const {
    user,
    userContract,
    loadTokenFromStorage,
    isFocusLearningActivated,
  } = useAuth()
  const { track } = useTrack()
  const stageEnv = process.env.REACT_APP_QA
  const productionEnv = process.env.REACT_APP_PROD
  const tokenFromStorage = loadTokenFromStorage()
  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [circleUser, setCircleUser] = useState<boolean | null>(null)
  const [specialtyDiagnosticLoading, setSpecialtyDiagnosticLoading] = useState(
    false,
  )

  /*
  |-----------------------------------------------------------------------------
  | useEffect
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const getCircleUser = async () => {
      if (
        userContract?.type !== 'PURCHASE' ||
        user?.contracts?.[0]?.final_date !== '2024-01-31'
      ) {
        return
      }

      const { data } = await api.get<{ exist_profile: boolean }>(
        '/app/circle-community',
      )
      setCircleUser(data.exist_profile)
    }

    getCircleUser()
  }, [user?.contracts, userContract?.type])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const returnRightEnv = useMemo(() => {
    if (productionEnv && tokenFromStorage) {
      return productionEnv.replace('TOKEN_EXEMPLO', tokenFromStorage)
    } else if (stageEnv && tokenFromStorage) {
      return stageEnv.replace('TOKEN_EXEMPLO', tokenFromStorage)
    } else return ''
  }, [productionEnv, stageEnv, tokenFromStorage])

  const CircleLinkContractTrack = useMemo(() => {
    if (!circleUser && user && track) {
      if (
        track.name === 'R1' ||
        track.name === 'PNA PORTUGAL' ||
        track.name === 'Revalida'
      ) {
        return 'https://alunomentoriaresidencia.circle.so/join?invitation_token=c8a824a5f1d05a8c33e224659a8aeb752844ca1a-5a5c5c01-c0b6-4c91-83a8-2b56f28499a5'
      } else {
        return ' https://alunomentoriaresidencia.circle.so/join?invitation_token=8b9441a73cd04412693eea7b7e317cdb6aa8a305-fab86b73-e0b4-4c53-bd39-ed4bd12b8eeb'
      }
    }

    return '/community'
  }, [track, user, circleUser])

  const getDiagnosticLink = useCallback(async () => {
    try {
      setSpecialtyDiagnosticLoading(true)

      const { data } = await api.post<{ url: string }>(
        '/providers/medcel_experience',
      )

      if (!data?.url) {
        return
      }

      const a = document.createElement('a')
      a.href = data.url
      a.target = '_blank'
      a.rel = 'noopener noreferrer'
      a.click()
    } catch (e) {
      showToast({
        title: 'Atenção',
        description:
          'Infelizmente não foi possível redirecional para o link desejado.',
        type: 'error',
        duration: 5000,
        mode: 'dark',
      })
    } finally {
      setSpecialtyDiagnosticLoading(false)
    }
  }, [showToast])

  const sideBarItems: Options[] = useMemo(() => {
    return [
      {
        isExternal: false,
        label: 'Home',
        path: '/home',
        icon: Home,
        hasSubItem: false,
        shouldRender: true,
      },
      {
        isExternal: false,
        label: 'Dashboard',
        path: '/dashboard',
        icon: PieChart,
        hasSubItem: false,
        shouldRender: true,
      },
      {
        isExternal: false,
        label: 'Calendário MR',
        path: '/calendar',
        icon: Calendar,
        hasSubItem: false,
        shouldRender: true,
      },
      {
        isExternal: false,
        label: 'Trilha de Simulados',
        path: '/timeline',
        icon: TbCalendarStats,
        hasSubItem: false,
        shouldRender:
          userContract?.type === 'PURCHASE' &&
          (track?.name === 'R1' ||
            track?.name === 'R+ CGE' ||
            track?.name === 'R+ CM'),
      },
      {
        /* shouldRender: user?.canAccessSeren && userContract?.type === 'PURCHASE', */
        shouldRender: false,
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
        isExternal: true,
        label: specialtyDiagnosticLoading
          ? 'Carregando...'
          : 'Diagnóstico de Especialidade',
        path: undefined,
        executeBeforeOnClick: getDiagnosticLink,
        icon: PiArrowUpRightBold,
        hasSubItem: false,
      },
      {
        /* shouldRender:
          userContract?.type === 'PURCHASE' &&
          user?.profile?.trial_year === 2023 &&
          track?.name !== 'PNA PORTUGAL', */
        shouldRender:
          userContract?.type === 'PURCHASE' &&
          user?.profile?.trial_year === 2024 &&
          track?.name !== 'PNA PORTUGAL',
        isExternal: false,
        label: 'Projeto X',
        path: '/project-x',
        icon: Crosshair,
        hasSubItem: false,
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
      },
      {
        isExternal: false,
        label: 'Plano de Recuperação',
        /* shouldRender:
          userContract?.type === 'PURCHASE' && !isFocusLearningActivated, */
        shouldRender:
          userContract?.type === 'PURCHASE' && !isFocusLearningActivated,
        icon: CheckSquare,
        hasSubItem: true,
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
        subitens: [
          {
            isExternal: false,
            label: 'Criar Plano',
            shouldRender: true,
            path: '/create-plan',
            icon: <AiOutlineFileAdd size={14} color="#F5F5FA" />,
          },
          {
            isExternal: false,
            shouldRender: true,
            label: 'Diário de recuperação',
            path: '/recovery-plan',
            icon: <FiBook size={14} color="#F5F5FA" />,
          },
        ],
      },
      {
        isExternal: false,
        label: 'Relatórios',
        path: '/reports',
        icon: BookOpen,
        hasSubItem: false,
        shouldRender: userContract?.type === 'PURCHASE',
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
      },
      {
        isExternal: false,
        label: 'Simulados Reta Final',
        path: '/simulation',
        icon: SimulationtIcon,
        hasSubItem: false,
        shouldRender: userContract?.type !== 'TRIAL' && track?.name === 'R1',
      },
      {
        isExternal: false,
        label: 'Materiais Complementares',
        path: '/killer-concepts',
        icon: KillerConceptIcon,
        hasSubItem: false,
        shouldRender: true,
      },
      {
        isExternal: false,
        label: 'Top Temas',
        path: '/topthemes',
        icon: Clipboard,
        hasSubItem: false,
        /* shouldRender: userContract?.type === 'PURCHASE', */
        shouldRender:
          userContract?.type === 'PURCHASE' &&
          user?.contracts?.[0]?.final_date === '2058-12-08',
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
      },
      {
        isExternal: false,
        label: 'Registros',
        icon: CheckSquare,
        hasSubItem: true,
        shouldRender: true,
        subitens: [
          {
            isExternal: false,
            shouldRender: true,
            label: 'Registros diários',
            path: '/daily-records',
            icon: <PieChart size={14} color="#F5F5FA" />,
          },
          {
            isExternal: false,
            shouldRender: true,
            label: 'Lixeira',
            path: '/trash',
            icon: <Trash2 size={14} color="#F5F5FA" />,
          },
        ],
      },
      {
        isExternal: false,
        label: 'Vídeos',
        icon: Video,
        hasSubItem: true,
        shouldRender: true,
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
        subitens: [
          {
            shouldRender:
              user?.canAccessSeren && userContract?.type === 'PURCHASE',
            isExternal: false,
            label: 'Minha Versão 2.0',
            path: '/myversion2',
            icon: <PieChart size={14} color="#F5F5FA" />,
          },
          {
            shouldRender: userContract?.type === 'PURCHASE',
            isExternal: false,
            label: 'Boas-vindas',
            path: '/videos-onboarding',
            icon: <Icon icon="mingcute:video-fill" />,
          },
        ],
      },
      {
        isExternal: false,
        label: 'Incidências',
        path: '/incidences',
        icon: BarChart2,
        hasSubItem: false,
        shouldRender: true,
      },
      {
        isExternal: true,
        label: 'Banco de Questões',
        path: returnRightEnv,
        icon: Folder,
        hasSubItem: false,
        shouldRender: userContract?.type === 'PURCHASE',
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
      },
      {
        /* shouldRender: userContract?.type === 'PURCHASE', */
        shouldRender:
          userContract?.type === 'PURCHASE' &&
          user?.contracts?.[0]?.final_date === '2058-12-08',
        shouldShowTrialMenus: userContract?.type === 'TRIAL',
        isExternal: !circleUser,
        label: 'Comunidade (Circle)',
        path: CircleLinkContractTrack ?? '',
        icon: Users,
        hasSubItem: false,
      },
      {
        isExternal: false,
        label: 'Minha Conta',
        icon: Settings,
        hasSubItem: true,
        shouldRender: true,
        subitens: [
          {
            label: 'Meu Perfil',
            path: '/account',
            icon: <Settings size={14} color="#F5F5FA" />,
            isExternal: false,
            shouldRender: true,
          },
          {
            shouldRender: true,
            isExternal: false,
            label: 'Grupo whatsapp',
            path: '/contact',
            icon: <MessageCircle size={14} color="#F5F5FA" />,
          },
        ],
      },
    ]
  }, [
    userContract?.type,
    track?.name,
    user?.canAccessSeren,
    user?.profile?.trial_year,
    user?.contracts,
    specialtyDiagnosticLoading,
    getDiagnosticLink,
    isFocusLearningActivated,
    returnRightEnv,
    circleUser,
    CircleLinkContractTrack,
  ])

  const shouldSidebarBeWide = useMemo(() => {
    if (!width) return true
    /*  if (width >= 800) return true */
    return isSidebarSetToWide
  }, [isSidebarSetToWide, width])

  const showLogoTrack = useMemo(() => {
    if (user && track?.name === 'Revalida') {
      return (
        <Logo
          src={logo2}
          width="198px"
          height={'79px'}
          alt="side bar logo"
          draggable={false}
          shouldSidebarBeWide={shouldSidebarBeWide}
        />
      )
    }

    if (user && track?.name !== undefined && track?.name !== 'Revalida') {
      return (
        <Logo
          src={logo}
          width="198px"
          height={'79px'}
          alt="side bar logo"
          draggable={false}
          shouldSidebarBeWide={shouldSidebarBeWide}
        />
      )
    }

    return null
  }, [shouldSidebarBeWide, track?.name, user])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Container
      hasHeaderResponsiveLayout={props.hasHeaderResponsiveLayout}
      shouldSidebarBeWide={shouldSidebarBeWide}
    >
      {/* <Text
        position="absolute"
        top="12px"
        left="14px"
        fontSize="0.5rem"
        color="white"
        fontFamily={'Mulish'}
      >
        Versão {packageJson.version}
      </Text> */}

      {!shouldSidebarBeWide && (
        <Text
          p="10px"
          w="100%"
          fontWeight="600"
          fontSize="16px"
          my="50px"
          color="#E56000"
        >
          MR
        </Text>
      )}

      {isSidebarSetToWide && <Box my="44px">{showLogoTrack}</Box>}

      <MenuButton onClick={() => setIsSidebarSetToWide(prev => !prev)}>
        {shouldSidebarBeWide ? (
          <HStack w="100%" ml="24px" mb="24px" spacing={'1rem'}>
            <Image src={BackMobile} />
            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="20px"
              color="#F5F5FA"
            >
              Fechar
            </Text>
          </HStack>
        ) : (
          <Flex
            w="100%"
            justifyContent={'center'}
            h="56px"
            alignItems={'center'}
          >
            <Image src={NextMobile} />
          </Flex>
        )}
      </MenuButton>

      <Box
        w="100%"
        overflow={'auto'}
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(223, 224, 235, 0.06)',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(223, 224, 235, 0.6)',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-corner': {
            background: 'rgba(0,0,0,0)',
          },
        }}
      >
        {sideBarItems.map((item, index) => {
          const subItemsPath = item?.subitens?.map(item => item.path)

          return (
            <Box
              alignItems={'center'}
              mx={!shouldSidebarBeWide ? '0px' : '24px'}
              key={`sideBarItem${index}`}
            >
              <MenuItem
                subItemsPath={subItemsPath}
                pathname={pathname}
                isActive={pathname === item.path}
                data={item}
                icon={item.icon}
                isSidebarSetToWide={shouldSidebarBeWide}
                executeBeforeOnClick={item.executeBeforeOnClick}
                shouldShowTrialMenus={item.shouldShowTrialMenus}
              />
            </Box>
          )
        })}
      </Box>
    </Container>
  )
}

export default Sidebar
